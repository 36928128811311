import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { BsTable } from "react-icons/bs";
import { getFundByAmc } from "../../stores/services/funds.service";
import { getAccountByFund } from "../../stores/services/account.service";
import {
  getBankStatement,
  getBankStatementOpeningBalance,
} from "../../stores/services/report.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import {
  getChartOfAccounts,
  getNewChartsOfAccountsByFund,
} from "../../stores/services/chatofaccounts.service";
import { getAllLedgers } from "../../utils/customFunction";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../contexts/ThemeContext";

//api for general ledger reports
import {
  getGeneralLedgerReport,
  getDailyBalance,
} from "../../stores/services/general-ledger-report.service";
import "./table.css";
import "./autocomplete.css";
import Select from "react-select";

const GeneralLegderReport = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const context = useContext(ThemeContext);
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  // const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [chartOfAccounts, setChartOfAccounts] = useState<any>(undefined);

  let date = new Date();
  const [bankStatement, setBankStatement] = useState<any>([]);
  const [bankStatementDataCount, SetBankStatementDataCount] = useState(0);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [type, setType] = useState("");
  const [fundAccount, setFundAccount] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const [closingBalance, setClosingBalance] = useState("");
  const [fromDate, setFromDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  const [resType, setResType] = useState("");
  const [resFundAccount, setResFundAccount] = useState("");
  const [resAccountTitle, setResAccountTitle] = useState("");
  const [resFromDate, setResFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resToDate, setResToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [legders, setLegders] = useState<any>([]);

  //ledger autocomplete
  const [valueSelectedLedger, setValueSelectedLedger] = React.useState<
    string | null
  >("");
  const [inputValueSelectedLedger, setInputValueSelectedLedger] =
    React.useState("");

  const [ledgerOption, setLedgerOption] = useState<any>([]);

  // ledger response data
  const [ledgerResponse, setLedgerResponse] = useState<any>([]);
  const [ledgerTransactions, setLedgerTransactions] = useState<any>([]);
  const [ledgerResponseAccountCode, setLedgerResponseAccountCode] =
    useState<any>("");
  const [ledgerResponseAccountName, setLedgerResponseAccountName] =
    useState<any>("");
  const [ledgerResponseAMC, setLedgerResponseAMC] = useState<any>("");
  const [ledgerResponseBankCode, setLedgerResponseBankCode] = useState<any>("");
  const [ledgerResponseBranch, setLedgerResponseBranch] = useState<any>("");

  const [ledgerResponseMainFamilyName, setLedgerResponseMainFamilyName] =
    useState<any>("");
  const [ledgerResponseFamilyName, setLedgerResponseFamilyName] =
    useState<any>("");
  const [ledgerResponseSubFamilyName, setLedgerResponseSubFamilyName] =
    useState<any>("");

  //opening balance state for reports
  const [reportOpeningBalance, setReportOpeningBalance] = useState<any>([]);
  // const [reportBalance, setReportBalance] = useState<any>("0");
  let reportBalance = 0.0;
  const [reportClosingBalance, setReportClosingBalance] = useState<any>("0");
  const [fetched, setFetched] = useState(false);
  const [ledgerResponseCode, setLedgerResponseCode] = useState<any>("");
  const [ledgerResponseName, setLedgerResponseName] = useState<any>("");

  const getAllAmcFunds = async () => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getAllAmcFunds();
  }, [fund_code]);

  // useEffect(() => {

  //   getAllAmcFunds();
  // }, []);

  const getAllLegders = async () => {
    // const response = await getChartOfAccounts(email);
    // if (response.data.status == 200) {
    // let ledgers = getAllLedgers(response.data.data.main_family);
    let ledgers = getAllLedgers(chartOfAccounts);
    setLegders(ledgers);
    // }
  };
  useEffect(() => {
    if (chartOfAccounts && chartOfAccounts.length > 0) {
      getAllLegders();
    }
  }, [chartOfAccounts]);

  const getChartOfAccountsFromBackend = async () => {
    try {
      setLoading(true);
      let response = await getNewChartsOfAccountsByFund(
        email,
        amc_code,
        fund?.split("-")[fund?.split("-").length - 1].trim()
      );
      if (response.data.status === 200) {
        setChartOfAccounts(response.data.data?.main_family);
        setLoading(false);
      } else {
        toast.error("No associated chart of account!");

        setChartOfAccounts(undefined);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  React.useEffect(() => {
    if (fund) {
      getChartOfAccountsFromBackend();
    }
  }, [fund]);

  useEffect(() => {
    legders.map((item, index) => {
      if (index === 0) {
        if (item.name) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.name}`,
            },
          ]);
        } else if (item.bank) {
          return setLedgerOption([
            {
              label: `${item.code} - ${item.bank}`,
            },
          ]);
        }
      }

      if (item.name) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.name}`,
          },
        ]);
      } else if (item.bank) {
        return setLedgerOption((prevState) => [
          ...prevState,
          {
            label: `${item.code} - ${item.bank}`,
          },
        ]);
      }
    });
  }, [legders]);

  // let txnTypes = JSON.parse(sessionStorage.getItem("txnType") || "");
  let txnTypes = useSelector((state: any) => state.txnTypes);

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  // const renderFundsDropDown = () => {
  //   return allFunds.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  const getFundAccounts = async (fund_code) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getAccountByFund(email, fund_code, amc_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (error) {}
  };

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  };

  const renderLegdersDropDown = () => {
    return legders.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code}>
          {item.code} - {item.name != undefined ? item.name : item.bank}
        </option>
      );
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      bankStatement.length = 0;
      setBankStatement(bankStatement);
      setOpeningBalance("0");
      setClosingBalance("0");
      setLoading(true);
      // let fromMonths = new Date(fromDate);
      // let toMonths = new Date(toDate);
      // var premonths;
      // premonths = (toMonths.getFullYear() - fromMonths.getFullYear()) * 12;
      // premonths -= fromMonths.getMonth();
      // premonths += toMonths.getMonth();
      // if(premonths<6){
      // const response = await getBankStatement(email, fundAccount, fromDate,toDate,type);
      const response = await getBankStatement(
        email,
        fundAccount,
        fromDate,
        toDate,
        "Realized"
      );
      const openingResponse = await getBankStatementOpeningBalance(
        email,
        fundAccount,
        fromDate
      );
      let array = Object.entries(response.data.data);
      let array1 = Object.entries(openingResponse.data.data);
      let data: any = [];
      let balance = 0;
      if (array1.length > 0) {
        let temp = [] as any;
        temp = array1[0][1];

        if (type == "Transaction" || type == "") {
          setOpeningBalance(
            temp.Record.processed_opening_balance == "0"
              ? "0"
              : temp.Record.processed_opening_balance
          );
          balance = parseFloat(
            temp.Record.processed_opening_balance == "0"
              ? "0"
              : temp.Record.processed_opening_balance
          );
        } else {
          setOpeningBalance("0");
          balance = parseFloat("0");
          setOpeningBalance(
            temp.Record.realized_opening_balance == "0"
              ? "0"
              : temp.Record.realized_opening_balance
          );
          balance = parseFloat(
            temp.Record.realized_opening_balance == "0"
              ? "0"
              : temp.Record.realized_opening_balance
          );
        }
      }
      setResAmc(amc_name);
      setResFund(fund);
      setResType(type);
      setResFundAccount(fundAccount);
      setResAccountTitle(accountTitle);
      setResFromDate(fromDate);
      setResToDate(toDate);
      array.forEach(([key, value]) => {
        data = value;
        let record = [];
        record["Record"] = data.Record;
        record["Record"]["ledger_id"] = data.Key;
        balance +=
          parseFloat(data.Record.debit_amount) -
          parseFloat(data.Record.credit_amount);
        record["Record"]["balance"] = balance.toFixed(2);
        bankStatement.push(record);
      });
      setClosingBalance(balance.toFixed(2));
      SetBankStatementDataCount(bankStatement.length);
      setLoading(false);
      // }else{
      //   toast.error('You cannot generate report upto 6 months')
      //   setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
    }
  };

  const getGeneralLedger = async () => {
    setFetched(false);
    let ledger_code = parseInt(
      inputValueSelectedLedger?.split("-")[0]?.replace(/\s+/g, "")
    );
    let start_date = fromDate;
    let end_date = toDate;
    let fund_code = fund?.split("-")[1]?.trim();

    const balance = await getDailyBalance(start_date, ledger_code, fund_code);

    reportBalance = await parseFloat(
      balance?.data?.data[0]?.processed_opening_balance
    );
    if (balance.data.status === 404) {
      setReportOpeningBalance(0);
    } else {
      setReportOpeningBalance([balance.data.data]);
    }

    const response = await getGeneralLedgerReport(
      ledger_code,
      fund_code,
      start_date,
      end_date
    );

    if (response.data.status === 404) {
      return toast.error("No record found for selected Ledger/Date");
    } else {
      // setLedgerResponseAccountCode(response.data.data[0].account_number);
      // setLedgerResponseAccountName(response.data.data[0].account_title);
      // setLedgerResponseAMC(response.data.data[0].amc_code);
      setLedgerResponseCode(response.data.data[0].gl_code);

      setLedgerResponseName(response.data.data[0].gl_name);
      // setLedgerResponseBankCode(response.data.data[0].bank_code);
      // setLedgerResponseBranch(response.data.data[0].branch);
      setLedgerResponseFamilyName(response.data.data[0].family_name);
      setLedgerResponseMainFamilyName(response.data.data[0].main_family_name);
      setLedgerResponseSubFamilyName(response.data.data[0].sub_family_name);

      setLedgerResponse(response.data.data);
      setLedgerTransactions(response.data.transactions);
      setFetched(true);
    }
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund =
      allFunds &&
      allFunds?.find(
        (item) => `${item.fund_name} - ${item.symbol_code}` === fund
      );
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} - ${selectedFund.symbol_code}`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">General Ledger</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <Select
                            value={selectedFundName}
                            label={"Select Fund"}
                            onChange={(e) => {
                              setLoading(true);
                              setLedgerResponse([]);
                              setFund(e?.label);
                              getFundAccounts(e?.value);
                              setLoading(false);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            onChange={(e) => {
                              setLoading(true);
                              setLedgerResponse([]);
                              setFund(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                      .text
                              );
                              getFundAccounts(e.target.value);
                              setLoading(false);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                        </div>
                      </Col>

                      <Col md="3" style={{ marginTop: "1.5rem" }}>
                        <div className="form-group">
                          {ledgerOption.length > 1 ? (
                            <Autocomplete
                              className="autocomplete d-inline-block"
                              // id="combo-box-demo"
                              // id="form-control"
                              options={ledgerOption}
                              noOptionsText={
                                <div
                                  style={{
                                    color:
                                      theme === "light"
                                        ? "black"
                                        : "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  No options
                                </div>
                              }
                              style={{ width: "100%" }}
                              PaperComponent={({ children }) => (
                                <Paper
                                  style={{
                                    background:
                                      theme == "light" ? "" : "#1E1E2F",
                                    color: "white",
                                    position: "absolute",
                                    width: "101%",
                                    left: "-3px",
                                  }}
                                >
                                  {children}
                                </Paper>
                              )}
                              value={valueSelectedLedger}
                              onChange={(
                                event: any,
                                newValue: string | null
                              ) => {
                                setLoading(true);
                                setLedgerResponse([]);
                                setValueSelectedLedger(newValue);
                                setLoading(false);
                              }}
                              inputValue={inputValueSelectedLedger}
                              onInputChange={(event, newInputValue) => {
                                setLoading(true);
                                setLedgerResponse([]);
                                setInputValueSelectedLedger(newInputValue);
                                setLoading(false);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ledger Selection"
                                  variant="outlined"
                                />
                              )}
                              sx={{
                                "& .MuiAutocomplete-input": {
                                  color:
                                    theme == "light"
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                },
                                "& .MuiInputLabel-root": {
                                  color:
                                    theme == "light"
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                },

                                "& .MuiIconButton-root": {
                                  color:
                                    theme == "light"
                                      ? "black !important"
                                      : "rgba(255, 255, 255, 0.5) !important",
                                },
                              }}
                            />
                          ) : !fund ? (
                            <div className="d-flex justify-content-center align-items-center text-center">
                              <label>Select Fund to get ledgers</label>
                            </div>
                          ) : (
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          )}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              setLoading(true);
                              setLedgerResponse([]);
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setFromDate(date);
                              setLoading(false);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              setLoading(true);
                              setLedgerResponse([]);
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setToDate(date);
                              setLoading(false);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              getGeneralLedger();
                            }}
                            disabled={
                              Boolean(loading) || !inputValueSelectedLedger
                            }
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={"Bank-Statement-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {ledgerResponse.length > 0 ? (
                          <>
                            <ReportHeader title="General Ledger" />

                            <Row>
                              <Col md="3" style={{ marginLeft: "40px" }}>
                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {moment(fromDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>

                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Ledger Code:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {ledgerResponseCode}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="3" style={{ marginLeft: "40px" }}>
                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      To Date:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {moment(toDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>

                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Ledger Name:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {ledgerResponseName}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col md="3" style={{ marginLeft: "40px" }}>
                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund Code:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {fund.split("-")[1].trim()}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="3" style={{ marginLeft: "40px" }}>
                                <Row className="d-flex justify-content-between">
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund Name:
                                    </span>
                                  </Col>
                                  <Col md="5">
                                    <span className="text-nowrap">
                                      {fund.split("-")[0].trim()}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <div className="float-right">
                                <b>Opening Balance</b>: <span> </span>
                                {/* {reportOpeningBalance
                                  ? numberWithCommas(
                                      parseFloat(
                                        reportOpeningBalance[0]
                                          .processed_opening_balance
                                      )
                                    )
                                  : "0"} */}
                                {reportOpeningBalance
                                  ? Math.sign(
                                      parseFloat(
                                        reportOpeningBalance[0]
                                          .processed_opening_balance
                                      )
                                    ) === 1
                                    ? `${numberWithCommas(
                                        parseFloat(
                                          reportOpeningBalance[0]
                                            .processed_opening_balance
                                        )
                                      )} Dr.`
                                    : Math.sign(
                                        parseFloat(
                                          reportOpeningBalance[0]
                                            .processed_opening_balance
                                        )
                                      ) === -1
                                    ? `${numberWithCommas(
                                        parseFloat(
                                          reportOpeningBalance[0]
                                            ?.processed_opening_balance
                                        )
                                      )} Cr.`
                                    : // ? `${numberWithCommas(
                                      //     parseFloat(
                                      //       reportOpeningBalance[0]?.processed_opening_balance?.split(
                                      //         "-"
                                      //       )[1]
                                      //     )
                                      //   )} Cr.`
                                      "0"
                                  : "0"}
                              </div>
                              <table
                                className="report border-bottom"
                                id="table-borderless"
                                style={{
                                  color: "black",
                                }}
                              >
                                <thead>
                                  <tr style={{ border: "0" }}>
                                    <th>Vr. No.</th>
                                    <th>Reference No. </th>
                                    <th>Instrument No. </th>
                                    <th>Date</th>

                                    <th style={{ width: "30%" }}>
                                      Description
                                    </th>

                                    <th id="right-align">Debit</th>
                                    <th id="right-align">Credit</th>

                                    <th id="right-align">Balance </th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {ledgerResponse.length > 0
                                    ? ledgerResponse.map(
                                        (item: any, index: number) => {
                                          if (index === 0) {
                                            reportBalance = reportOpeningBalance
                                              ? reportOpeningBalance[0]
                                                  .processed_opening_balance
                                                ? parseFloat(
                                                    reportOpeningBalance[0]
                                                      .processed_opening_balance
                                                  ) !== 0
                                                  ? parseFloat(
                                                      reportOpeningBalance[0]
                                                        .processed_opening_balance
                                                    )
                                                  : 0
                                                : 0
                                              : 0;
                                            reportBalance +=
                                              parseFloat(item.debit_amount) -
                                              parseFloat(item.credit_amount);
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  {item._id.split("_")[1]}
                                                </td>
                                                <td>{item.txn_id}</td>
                                                <td>
                                                  {ledgerTransactions.find(
                                                    (txn) =>
                                                      txn.txn_id == item.txn_id
                                                  )?.instrument_no || ""}{" "}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.created_at
                                                  ).format("DD-MM-YYYY")}
                                                </td>

                                                <td>
                                                  {item.description?.slice(
                                                    0,
                                                    400
                                                  )}{" "}
                                                  {item.description.length >
                                                    400 && "..."}{" "}
                                                  {item.description.length <
                                                    395 &&
                                                  ledgerTransactions
                                                    .find(
                                                      (txn) =>
                                                        txn.txn_id ==
                                                        item.txn_id
                                                    )
                                                    ?.description?.includes(
                                                      " (Reversed)"
                                                    )
                                                    ? " (Reversed)"
                                                    : ""}
                                                </td>

                                                <td align="right">
                                                  {numberWithCommas(
                                                    parseFloat(
                                                      item.debit_amount
                                                    )
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {numberWithCommas(
                                                    parseFloat(
                                                      item.credit_amount
                                                    )
                                                  )}
                                                </td>

                                                <td align="right">
                                                  {reportBalance
                                                    ? Math.sign(
                                                        reportBalance
                                                      ) === 1
                                                      ? `${numberWithCommas(
                                                          reportBalance.toFixed(
                                                            2
                                                          )
                                                        )} Dr.`
                                                      : Math.sign(
                                                          reportBalance
                                                        ) === -1
                                                      ? `${numberWithCommas(
                                                          reportBalance
                                                            .toFixed(2)
                                                            .toString()
                                                            .split("-")[1]
                                                        )} Cr.`
                                                      : "0"
                                                    : "0"}
                                                </td>

                                                {/* <td>{item._id}</td> */}
                                              </tr>
                                            );
                                          } else {
                                            reportBalance +=
                                              parseFloat(item.debit_amount) -
                                              parseFloat(item.credit_amount);

                                            return (
                                              <tr key={index}>
                                                <td>
                                                  {item._id.split("_")[1]}
                                                </td>
                                                <td>{item.txn_id}</td>
                                                <td>
                                                  {ledgerTransactions.find(
                                                    (txn) =>
                                                      txn.txn_id == item.txn_id
                                                  )?.instrument_no || ""}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.created_at
                                                  ).format("DD-MM-YYYY")}
                                                </td>

                                                <td>
                                                  {item.description}{" "}
                                                  {ledgerTransactions
                                                    .find(
                                                      (txn) =>
                                                        txn.txn_id ==
                                                        item.txn_id
                                                    )
                                                    ?.description?.includes(
                                                      " (Reversed)"
                                                    )
                                                    ? " (Reversed)"
                                                    : ""}
                                                </td>

                                                <td align="right">
                                                  {numberWithCommas(
                                                    parseFloat(
                                                      item.debit_amount
                                                    )
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {numberWithCommas(
                                                    parseFloat(
                                                      item.credit_amount
                                                    )
                                                  )}
                                                </td>

                                                <td align="right">
                                                  {reportBalance
                                                    ? Math.sign(
                                                        reportBalance
                                                      ) === 1
                                                      ? `${numberWithCommas(
                                                          reportBalance.toFixed(
                                                            2
                                                          )
                                                        )} Dr.`
                                                      : Math.sign(
                                                          reportBalance
                                                        ) === -1
                                                      ? `${numberWithCommas(
                                                          reportBalance
                                                            .toFixed(2)
                                                            .toString()
                                                            .split("-")[1]
                                                        )} Cr.`
                                                      : "0"
                                                    : "0"}
                                                </td>

                                                {/* <td>{item._id}</td> */}
                                              </tr>
                                            );
                                          }
                                        }
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="float-right">
                              <b>Closing Balance</b>: <span> </span>
                              {reportBalance
                                ? Math.sign(reportBalance) === 1
                                  ? `${numberWithCommas(
                                      reportBalance.toFixed(2)
                                    )} Dr.`
                                  : Math.sign(reportBalance) === -1
                                  ? `${numberWithCommas(
                                      reportBalance
                                        .toFixed(2)
                                        .toString()
                                        .split("-")[1]
                                    )} Cr.`
                                  : "0"
                                : "0"}
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center text-primary empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GeneralLegderReport;

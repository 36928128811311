import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import DCCL from "../../assets/img/DCC-Logo.svg";
import { IoSearchOutline } from "react-icons/io5";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsToggles } from "react-icons/bs";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../utils/customFunction";
import {
  FaCaretUp,
  FaCaretDown,
  FaChevronDown,
  FaChevronUp,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { useHistory } from "react-router-dom";

function Stock() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("My Portfolio");
  const [changeView, setChangeView] = useState("Horizontal");
  const [screen, setScreen] = useState<any>();
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const scrollRef = useRef<any>(null);

  const DummyArr = [
    {
      abbreviation: "ODC",
      company_name: "Oil and Gas Development Company Limited",
      rate: 197,
      ll: 177.36,
      lr: 196.2,
      cr: 197.23,
      ul: 216.78,
      or: 197.23,
      Hr: 198.4,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 123456789,
      yearlyAverage: 145,
      yearlyShares: 12233434,
      investmentCalc: 100000,
      shares: 507,
    },
    {
      abbreviation: "XYZ",
      company_name: "Zeta Industries Inc.",
      rate: 245,
      ll: 220.5,
      lr: 240.0,
      cr: 245.1,
      ul: 260.0,
      or: 245.1,
      Hr: 246.8,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 987654321,
      yearlyAverage: 200,
      yearlyShares: 23456789,
      investmentCalc: 150000,
      shares: 600,
    },
    {
      abbreviation: "ABC",
      company_name: "Alpha Beta Corp.",
      rate: 310,
      ll: 290.0,
      lr: 305.0,
      cr: 310.5,
      ul: 320.0,
      or: 310.5,
      Hr: 311.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 234567890,
      yearlyAverage: 250,
      yearlyShares: 3456789,
      investmentCalc: 200000,
      shares: 800,
    },
    {
      abbreviation: "DEF",
      company_name: "Delta Electronics Ltd.",
      rate: 420,
      ll: 400.0,
      lr: 410.0,
      cr: 420.75,
      ul: 430.0,
      or: 420.75,
      Hr: 421.5,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 345678901,
      yearlyAverage: 380,
      yearlyShares: 4567890,
      investmentCalc: 250000,
      shares: 900,
    },
    {
      abbreviation: "GHI",
      company_name: "Gamma Holdings",
      rate: 575,
      ll: 550.0,
      lr: 570.0,
      cr: 575.5,
      ul: 590.0,
      or: 575.5,
      Hr: 576.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 456789012,
      yearlyAverage: 500,
      yearlyShares: 5678901,
      investmentCalc: 300000,
      shares: 1100,
    },
    {
      abbreviation: "JKL",
      company_name: "Jupiter Technologies",
      rate: 680,
      ll: 650.0,
      lr: 670.0,
      cr: 680.8,
      ul: 700.0,
      or: 680.8,
      Hr: 681.5,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 567890123,
      yearlyAverage: 600,
      yearlyShares: 6789012,
      investmentCalc: 350000,
      shares: 1200,
    },
    {
      abbreviation: "MNO",
      company_name: "Mighty Networks",
      rate: 790,
      ll: 770.0,
      lr: 780.0,
      cr: 790.25,
      ul: 800.0,
      or: 790.25,
      Hr: 791.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 678901234,
      yearlyAverage: 700,
      yearlyShares: 7890123,
      investmentCalc: 400000,
      shares: 1300,
    },
    {
      abbreviation: "PQR",
      company_name: "Pinnacle Resources",
      rate: 830,
      ll: 810.0,
      lr: 820.0,
      cr: 830.3,
      ul: 840.0,
      or: 830.3,
      Hr: 831.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 789012345,
      yearlyAverage: 750,
      yearlyShares: 8901234,
      investmentCalc: 450000,
      shares: 1400,
    },
    {
      abbreviation: "STU",
      company_name: "Star Technologies",
      rate: 950,
      ll: 925.0,
      lr: 940.0,
      cr: 950.5,
      ul: 960.0,
      or: 950.5,
      Hr: 951.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 890123456,
      yearlyAverage: 800,
      yearlyShares: 9012345,
      investmentCalc: 500000,
      shares: 1500,
    },
    {
      abbreviation: "VWX",
      company_name: "Visionary Works",
      rate: 1000,
      ll: 980.0,
      lr: 990.0,
      cr: 1000.75,
      ul: 1010.0,
      or: 1000.75,
      Hr: 1001.0,
      change_val: 12,
      change_per: 12.27,
      totalVolume: 901234567,
      yearlyAverage: 950,
      yearlyShares: 12345678,
      investmentCalc: 550000,
      shares: 1600,
    },
  ];
  const colorArr = ["#05bd64", "#de4f45", "#7ac6e1", "#3fe3c1", "#df955d"];

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      console.log(window, "test----");
      scrollRef.current.scrollLeft -= 200;
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft += 200;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
      if (window?.outerWidth <= 768) {
        setChangeView("Vertical");
      } else {
        setChangeView("Horizontal");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <div
              style={{ borderRadius: "12px", borderTopRightRadius: "12px" }}
              className="card "
            >
              <Col
                md="12"
                sm="12"
                style={{
                  padding: "0rem 1.7rem",
                  borderRadius: "12px",
                }}
                className="border bg-dark text-white py-2 "
              >
                <FaChevronLeft
                  style={{
                    position: "absolute",
                    width: "25px",
                    height: "25px",
                    top: "50%",
                    left: "1px",
                    transform: "translate(0%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleScrollLeft();
                  }}
                />
                <div
                  ref={scrollRef}
                  style={{
                    scrollBehavior: "smooth",
                    width: "auto",
                    minWidth: "150px",
                    overflowX: "auto",
                  }}
                  id="scroll_header"
                >
                  <div
                    style={{
                      flexWrap: "nowrap",
                      minWidth: "1200px",
                      columnGap: "12px",
                    }}
                    className="d-flex align-items-center"
                  >
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1 "
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          OGDC
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Oil and Gas Devopment{" "}
                        </small>
                      </div>
                    </div>

                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(222, 79, 69)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1 "
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>

                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(5, 189, 100)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>

                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(122, 198, 225)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>

                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(222, 79, 69)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>
                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(5, 189, 100)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>
                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(122, 198, 225)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>
                    <div
                      style={{
                        lineHeight: "normal",
                        maxWidth: "110px",
                        minWidth: "110px",
                      }}
                      className="d-flex flex-column align-items-center"
                    >
                      <p className="mb-0 heading_stock">
                        <small className="xSmall">Rs.</small>197{" "}
                        <small className="xSmall">.23</small>
                      </p>
                      <small
                        style={{
                          color: "rgb(222, 79, 69)",
                        }}
                        className="small_heading_price"
                      >
                        Rs 2.43{" "}
                        <span className="ml-1 percentage_val">1.23%</span>
                      </small>
                    </div>
                    <div
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="d-flex align-items-center"
                    >
                      <img src={DCCL} width={50} height={50} />
                      <div
                        style={{ lineHeight: "normal", width: "64%" }}
                        className="d-flex flex-column ml-1"
                      >
                        <p
                          style={{
                            color: "rgba(255, 255, 255, 0.8)",
                          }}
                          className="mb-0 heading_stock"
                        >
                          DCCL
                        </p>
                        <small className="text-truncate small_heading_stock">
                          Digital Custodian Company Limited
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <FaChevronRight
                  style={{
                    position: "absolute",
                    width: "25px",
                    height: "25px",
                    top: "50%",
                    right: "1px",
                    transform: "translate(0%, -50%)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleScrollRight();
                  }}
                />
              </Col>

              <div className="card-body">
                <Row
                  style={{
                    rowGap: screen <= 1199 ? "13px" : "",
                  }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <Col
                    xl="4"
                    md="6"
                    className={`d-flex order-1 ${
                      screen <= 500
                        ? "flex-column justify-content-center align-items-center"
                        : "justify-content-start"
                    } align-items-center`}
                  >
                    <h5 className="text-primary mb-0">Watch</h5>
                    <div
                      style={{
                        maxWidth: "210px",
                      }}
                      className={`input-group ml-2 mb-0 position-relative `}
                    >
                      <input
                        style={{
                          borderRadius: "50px",
                          textAlign: "left",
                          padding: "4px 18px",
                        }}
                        type={"text"}
                        placeholder="Search Company"
                        className="form-control"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                      <div
                        style={{
                          right: "23px",
                          top: "6px",
                          cursor: "pointer",
                        }}
                        className="position-absolute"
                      >
                        <IoSearchOutline fontSize={20} />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xl="4"
                    md="12"
                    className={`d-flex order-${
                      screen <= 1199 ? "3" : "2"
                    } mt-2 align-items-center justify-content-center`}
                  >
                    <span
                      onClick={() => {
                        setSelectedTab("My Portfolio");
                      }}
                      className={`tab${
                        selectedTab == "My Portfolio" ? "-active" : ""
                      } px-2 py-1 text-primary `}
                    >
                      My Portfolio
                    </span>
                    <span
                      onClick={() => {
                        setSelectedTab("My Fav");
                      }}
                      className={`tab${
                        selectedTab == "My Fav" ? "-active" : ""
                      } px-2 py-1 text-primary`}
                    >
                      My Fav
                    </span>
                    <AiOutlinePlusCircle
                      onClick={() => history.replace("/admin/profile")}
                      className="ml-2"
                      style={{
                        cursor: "pointer",
                        height: "30px",
                        width: "30px",
                      }}
                    />
                  </Col>
                  <Col
                    xl="4"
                    md="6"
                    className={`d-flex order-${
                      screen <= 1199 ? "2" : "3"
                    } justify-content-${screen <= 500 ? "center" : "end"}`}
                  >
                    <BsToggles
                      onClick={() => setChangeView("Horizontal")}
                      className={`${
                        changeView == "Horizontal" ? "text-info" : ""
                      }`}
                      style={{
                        cursor: "pointer",
                        height: "23px",
                        width: "23px",
                      }}
                    />
                    <BsToggles
                      className={`ml-2 ${
                        changeView == "Vertical" ? "text-info" : ""
                      }`}
                      onClick={() => setChangeView("Vertical")}
                      style={{
                        cursor: "pointer",
                        height: "25px",
                        width: "25px",
                        transform: "rotate(90deg)",
                      }}
                    />
                  </Col>
                </Row>
                {changeView == "Horizontal" ? (
                  <div className="table-responsive">
                    <table
                      style={{
                        minWidth: "1200px",
                      }}
                      className="table mt-0"
                    >
                      <tbody>
                        {DummyArr?.map((item, index) => (
                          <tr>
                            <td
                              style={{
                                width: "120px",
                                maxWidth: "180px",
                              }}
                              className="border-y"
                            >
                              <div
                                style={{ lineHeight: "normal" }}
                                className="d-flex align-items-center w-75"
                              >
                                <img
                                  className="mr-2"
                                  src={DCCL}
                                  width={50}
                                  height={50}
                                />
                                <div
                                  style={{ width: "inherit" }}
                                  className="d-flex flex-column"
                                >
                                  <small style={{ opacity: "0.4" }}>
                                    Security
                                  </small>
                                  <p
                                    style={{ fontWeight: 600 }}
                                    className="mb-0 text-primary table_heading_large"
                                  >
                                    {" "}
                                    {item?.abbreviation}
                                  </p>
                                  <small className="text-info small_heading text-truncate">
                                    {item?.company_name}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td className="border-y">
                              <div
                                style={{ lineHeight: "normal" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <small style={{ opacity: "0.4" }}>Rate</small>
                                <p
                                  style={{ fontWeight: 600 }}
                                  className="mb-0 text-primary table_heading_large"
                                >
                                  <small className="xSmall">Rs. </small>
                                  {item?.rate}
                                  <small className="xSmall"> .23</small>
                                </p>
                                <small className="text-info small_heading">
                                  Per Share
                                </small>
                              </div>
                            </td>
                            <td
                              style={{
                                minWidth: "200px",
                              }}
                              className="border-y "
                            >
                              <div className="d-flex justify-content-between mb-1 position-relative w-100">
                                <small id="progreesBar">
                                  <span className="span_progress_val">LL</span>{" "}
                                  {item?.ll}
                                </small>
                                <small id="progreesBar">
                                  <span className="span_progress_val">UL </span>
                                  {item?.ul}
                                </small>
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "-11%",
                                    left:
                                      index == 0
                                        ? "63%"
                                        : index == 1
                                        ? "14%"
                                        : index == 2
                                        ? "30%"
                                        : index == 3
                                        ? "38%"
                                        : index == 4
                                        ? "12%"
                                        : "14%",
                                    // lineHeight: "3px",
                                    zIndex: 34,
                                  }}
                                  className="d-flex flex-column align-items-center"
                                >
                                  <small
                                    id="progreesBar"
                                    style={{
                                      lineHeight: "normal",
                                      backgroundColor:
                                        theme == "dark"
                                          ? "#27293da6"
                                          : "#ffffff70",
                                    }}
                                  >
                                    <span className="span_progress_val">
                                      CR{" "}
                                    </span>
                                    <small
                                      style={{
                                        color:
                                          index == 0 ? "#15c46f" : "#0a4eb8",
                                      }}
                                      className="color_valu"
                                    >
                                      {item?.cr}
                                    </small>
                                    <FaCaretDown
                                      style={{
                                        position: "absolute",
                                        bottom: "-10px",
                                        left: "50%",
                                        transform: "translate(-50%, 0%)",
                                        // color: "#0251b9"
                                      }}
                                    />
                                  </small>
                                </div>
                              </div>
                              <div
                                style={{
                                  background:
                                    "linear-gradient(90deg, rgba(252,232,221,1) 0%, rgba(198,252,210,1) 100%)",
                                }}
                                className="progress position-relative w-100"
                              >
                                <div
                                  className="progress-bar position-absolute"
                                  role="progressbar"
                                  style={{
                                    width: "35%",
                                    height: "100%",
                                    border: "1px solid",
                                    borderRadius: "12px",
                                    left:
                                      index == 0
                                        ? "65%"
                                        : index == 1
                                        ? "0%"
                                        : index == 2
                                        ? "30%"
                                        : index == 3
                                        ? "38%"
                                        : index == 4
                                        ? "12%"
                                        : "0%",
                                    backgroundColor:
                                      colorArr[index % colorArr?.length],
                                  }}
                                ></div>
                              </div>

                              <div className="d-flex justify-content-between mt-1 position-relative w-100">
                                <small id="progreesBar">
                                  <span className="span_progress_val">LR</span>{" "}
                                  <small
                                    className="color_valu"
                                    style={{ color: "#f8a774" }}
                                  >
                                    {item?.lr}
                                  </small>
                                </small>
                                <small id="progreesBar">
                                  <span className="span_progress_val">HR</span>{" "}
                                  <small
                                    className="color_valu"
                                    style={{ color: "#12c36d" }}
                                  >
                                    {item?.Hr}
                                  </small>
                                </small>
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-11%",
                                    left:
                                      index == 0
                                        ? "69%"
                                        : index == 1
                                        ? "20%"
                                        : index == 2
                                        ? "36%"
                                        : index == 3
                                        ? "44%"
                                        : index == 4
                                        ? "18%"
                                        : "20%",
                                    zIndex: 34,
                                  }}
                                  className="d-flex flex-column align-items-center"
                                >
                                  <small
                                    id="progreesBar"
                                    style={{
                                      lineHeight: "normal",
                                      backgroundColor:
                                        theme == "dark"
                                          ? "#27293da6"
                                          : "#ffffff70",
                                    }}
                                  >
                                    <span className="span_progress_val">
                                      OR{" "}
                                    </span>
                                    <small className="color_valu">
                                      {item?.or}
                                    </small>
                                    <FaCaretUp
                                      style={{
                                        position: "absolute",
                                        top: "-11px",
                                        left: "50%",
                                        transform: "translate(-50%, 0%)",
                                        // color: "#999999"
                                      }}
                                    />
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td className="border-y">
                              <div
                                style={{ lineHeight: "normal" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <small style={{ opacity: "0.4" }}>Change</small>
                                <p
                                  style={{
                                    fontWeight: 600,
                                    color: colorArr[index % colorArr?.length],
                                  }}
                                  className="mb-0 table_heading_large"
                                >
                                  <small className="xSmall">Rs. </small>
                                  {item?.change_val}
                                  <small className="xSmall"> .23</small>
                                </p>
                                <small
                                  style={{
                                    color: colorArr[index % colorArr?.length],
                                  }}
                                  className="small_heading"
                                >
                                  {item?.change_per}%
                                </small>
                              </div>
                            </td>
                            <td className="border-y">
                              <div
                                style={{ lineHeight: "normal" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <small style={{ opacity: "0.4" }}>
                                  Total Volume
                                </small>
                                <p
                                  style={{ fontWeight: 600 }}
                                  className="mb-0 table_heading_large text-primary"
                                >
                                  {numberWithCommas(item?.totalVolume)}
                                </p>
                                <small className="text-info small_heading">
                                  Shares
                                </small>
                              </div>
                            </td>
                            <td className="border-y">
                              <div
                                style={{ lineHeight: "normal" }}
                                className="d-flex flex-column align-items-center"
                              >
                                <small style={{ opacity: "0.4" }}>
                                  Yearly Average
                                </small>
                                <p
                                  style={{ fontWeight: 600 }}
                                  className="mb-0 table_heading_large text-primary"
                                >
                                  <small className="xSmall">Rs. </small>
                                  {item?.yearlyAverage}
                                  <small className="xSmall"> .23</small>
                                </p>
                                <small className="text-info small_heading">
                                  {numberWithCommas(item?.yearlyShares)} Shares
                                </small>
                              </div>
                            </td>
                            <td style={{ width: "215px" }} className="border-y">
                              <div className="d-flex flex-column align-items-center mt-1">
                                <small style={{ opacity: "0.4" }}>
                                  Investment Calc
                                </small>
                                <div className="input-group mb-0">
                                  <span
                                    style={{
                                      maxHeight: "29px",
                                    }}
                                    className={`custom-input-group-${theme} py-1`}
                                  >
                                    Rs.
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control customInput-${theme} py-1`}
                                  />
                                  <span
                                    style={{
                                      maxHeight: "29px",
                                    }}
                                    className={`custom-input-group-right-${theme} py-1`}
                                  >
                                    <FaChevronUp
                                      style={{
                                        cursor: "pointer",
                                        height: "12px",
                                        width: "12px",
                                      }}
                                    />
                                    <FaChevronDown
                                      style={{
                                        cursor: "pointer",
                                        height: "12px",
                                        width: "12px",
                                      }}
                                    />
                                  </span>
                                </div>
                                <div className="input-group mb-0">
                                  <span
                                    style={{
                                      maxHeight: "29px",
                                    }}
                                    className={`custom-input-group-${theme} py-1`}
                                  >
                                    Shares
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control customInput-${theme} py-1`}
                                  />
                                  <span
                                    style={{
                                      maxHeight: "29px",
                                    }}
                                    className={`custom-input-group-right-${theme} py-1`}
                                  >
                                    <FaChevronUp
                                      style={{
                                        cursor: "pointer",
                                        height: "12px",
                                        width: "12px",
                                      }}
                                    />
                                    <FaChevronDown
                                      style={{
                                        cursor: "pointer",
                                        height: "12px",
                                        width: "12px",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Col
                    style={{
                      borderTop: "1px solid",
                    }}
                    className="col-12"
                  >
                    <Row className="pt-3">
                      {DummyArr?.map((item, index) => (
                        <Col
                          lg="4"
                          xl="2"
                          md="4"
                          className={`mb-5 ${
                            screen <= 500 ? "pb-3 border-bottom" : ""
                          }`}
                        >
                          <div
                            style={{
                              rowGap: "10px",
                              borderRadius: "15px",
                              border: "1px solid #1d8cf8 ",
                            }}
                            className="d-flex flex-column align-items-center text-center py-2 shadow"
                          >
                            <img src={DCCL} width={60} height={60} />
                            <div
                              style={{ lineHeight: "normal" }}
                              className="d-flex flex-column w-100"
                            >
                              <p
                                style={{ fontWeight: 600 }}
                                className="mb-0 text-primary table_heading_large"
                              >
                                {" "}
                                {item?.abbreviation}
                              </p>
                              <small
                                style={{ whiteSpace: "break-spaces" }}
                                className="text-info"
                              >
                                {item?.company_name}
                              </small>
                            </div>
                            <div
                              style={{ lineHeight: "normal" }}
                              className="d-flex flex-column align-items-center w-100"
                            >
                              <small
                                style={{ opacity: "0.4" }}
                                className={`smallTagColor-${theme}`}
                              >
                                Rate
                              </small>
                              <p
                                style={{
                                  fontWeight: 600,
                                  whiteSpace: "break-spaces",
                                }}
                                className="mb-0 text-primary table_heading_large"
                              >
                                <small className="xSmall">Rs. </small>
                                {item?.rate}
                                <small className="xSmall"> .23</small>
                              </p>
                              <small className="text-info">Per Share</small>
                            </div>

                            <div
                              style={{ minHeight: "210px", minWidth: "112px" }}
                              className="d-flex my-2"
                            >
                              <div className="d-flex flex-column justify-content-between mr-1 position-relative w-100">
                                <small
                                  id="progreesBar"
                                  className={`smallTagColor-${theme}`}
                                >
                                  <span className="span_progress_val">LL</span>{" "}
                                  {item?.ll}
                                </small>
                                <small
                                  id="progreesBar"
                                  className={`smallTagColor-${theme}`}
                                >
                                  <span className="span_progress_val">UL </span>
                                  {item?.ul}
                                </small>
                                <div
                                  style={{
                                    position: "absolute",
                                    right: "-19%",
                                    top:
                                      index == 0
                                        ? "63%"
                                        : index == 1
                                        ? "14%"
                                        : index == 2
                                        ? "30%"
                                        : index == 3
                                        ? "38%"
                                        : index == 4
                                        ? "12%"
                                        : "14%",
                                    lineHeight: "15px",
                                    zIndex: 34,
                                  }}
                                  className="d-flex align-items-center"
                                >
                                  <small
                                    id="progreesBar"
                                    className={`smallTagColor-${theme}`}
                                    style={{ minWidth: "54px" }}
                                  >
                                    <span className="span_progress_val">
                                      CR{" "}
                                    </span>
                                    <small className="color_valu">
                                      {item?.cr}
                                    </small>
                                    {/* CR {item?.cr} */}
                                  </small>
                                  <FaCaretDown
                                    className={`smallTagColor-${theme}`}
                                    style={{
                                      transform: "rotate(270deg)",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  height: "inherit",
                                  maxWidth: "9px",
                                  background:
                                    "linear-gradient(90deg, rgba(198,252,210,1) 0%, rgba(252,232,221,1) 100%)",
                                }}
                                className="progress position-relative"
                              >
                                <div
                                  className="progress-bar position-absolute"
                                  role="progressbar"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    border: "1px solid",
                                    borderRadius: "12px",
                                    left: "0%",
                                    top:
                                      index == 0
                                        ? "65%"
                                        : index == 1
                                        ? "0%"
                                        : index == 2
                                        ? "30%"
                                        : index == 3
                                        ? "38%"
                                        : index == 4
                                        ? "12%"
                                        : "0%",
                                    backgroundColor:
                                      colorArr[index % colorArr?.length],
                                  }}
                                ></div>
                              </div>

                              <div className="d-flex flex-column justify-content-between ml-1 position-relative w-100">
                                <small
                                  id="progreesBar"
                                  className={`smallTagColor-${theme}`}
                                >
                                  <span className="span_progress_val">LR</span>{" "}
                                  <small
                                    className="color_valu"
                                    style={{ color: "#12c36d" }}
                                  >
                                    {item?.lr}
                                  </small>
                                </small>
                                <small
                                  id="progreesBar"
                                  className={`smallTagColor-${theme}`}
                                >
                                  <span className="span_progress_val">HR</span>{" "}
                                  <small
                                    className="color_valu"
                                    style={{ color: "#f8a774" }}
                                  >
                                    {item?.Hr}
                                  </small>
                                </small>
                                <div
                                  style={{
                                    position: "absolute",
                                    top:
                                      index == 0
                                        ? "69%"
                                        : index == 1
                                        ? "20%"
                                        : index == 2
                                        ? "36%"
                                        : index == 3
                                        ? "44%"
                                        : index == 4
                                        ? "18%"
                                        : "20%",
                                    left: "-19%",
                                    lineHeight: "15px",
                                    zIndex: 34,
                                  }}
                                  className="d-flex align-items-center"
                                >
                                  <FaCaretUp
                                    className={`smallTagColor-${theme}`}
                                    style={{
                                      transform: "rotate(270deg)",
                                    }}
                                  />
                                  <small
                                    id="progreesBar"
                                    className={`smallTagColor-${theme}`}
                                    style={{ minWidth: "54px" }}
                                  >
                                    <span className="span_progress_val">
                                      OR{" "}
                                    </span>
                                    <small
                                      style={{
                                        color:
                                          index == 0 ? "#15c46f" : "#f8a774",
                                      }}
                                      className="color_valu"
                                    >
                                      {item?.or}
                                    </small>
                                    {/* OR {item?.or} */}
                                  </small>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{ lineHeight: "normal" }}
                              className="d-flex flex-column align-items-center w-100"
                            >
                              <small
                                style={{ opacity: "0.4" }}
                                className={`smallTagColor-${theme}`}
                              >
                                Change
                              </small>
                              <p
                                style={{
                                  fontWeight: 600,
                                  whiteSpace: "break-spaces",
                                  color: colorArr[index % colorArr?.length],
                                }}
                                className="mb-0 table_heading_large"
                              >
                                <small className="xSmall">Rs. </small>
                                {item?.change_val}
                                <small className="xSmall"> .23</small>
                              </p>
                              <small
                                style={{
                                  color: colorArr[index % colorArr?.length],
                                }}
                              >
                                {item?.change_per}%
                              </small>
                            </div>
                            <div
                              style={{ lineHeight: "normal" }}
                              className="d-flex flex-column align-items-center w-100"
                            >
                              <small
                                style={{ opacity: "0.4" }}
                                className={`smallTagColor-${theme}`}
                              >
                                Total Volume
                              </small>
                              <p
                                style={{
                                  fontWeight: 600,
                                  whiteSpace: "break-spaces",
                                }}
                                className="mb-0 table_heading_large text-primary"
                              >
                                {numberWithCommas(item?.totalVolume)}
                              </p>
                              <small className="text-info">Shares</small>
                            </div>
                            <div
                              style={{ lineHeight: "normal" }}
                              className="d-flex flex-column align-items-center w-100"
                            >
                              <small
                                style={{ opacity: "0.4" }}
                                className={`smallTagColor-${theme}`}
                              >
                                Yearly Average
                              </small>
                              <p
                                style={{
                                  fontWeight: 600,
                                  whiteSpace: "break-spaces",
                                }}
                                className="mb-0 table_heading_large text-primary"
                              >
                                <small className="xSmall">Rs. </small>
                                {item?.yearlyAverage}
                                <small className="xSmall"> .23</small>
                              </p>
                              <small className="text-info">
                                {numberWithCommas(item?.yearlyShares)} Shares
                              </small>
                            </div>

                            <div className="d-flex flex-column align-items-center mx-3 mt-1">
                              <small
                                style={{ opacity: "0.4" }}
                                className={`smallTagColor-${theme}`}
                              >
                                Investment Calc
                              </small>
                              <div className="input-group mb-1">
                                <span
                                  style={{
                                    maxHeight: "29px",
                                  }}
                                  className={`custom-input-group-${theme} py-1`}
                                >
                                  Rs.
                                </span>
                                <input
                                  type="text"
                                  className={`form-control customInput-${theme} py-1`}
                                />
                                <span
                                  style={{
                                    maxHeight: "29px",
                                  }}
                                  className={`custom-input-group-right-${theme} py-1`}
                                >
                                  <FaChevronUp
                                    style={{
                                      cursor: "pointer",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                  <FaChevronDown
                                    style={{
                                      cursor: "pointer",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                </span>
                              </div>
                              <div className="input-group mb-0">
                                <span
                                  style={{
                                    maxHeight: "29px",
                                  }}
                                  className={`custom-input-group-${theme} py-1`}
                                >
                                  Shares
                                </span>
                                <input
                                  type="text"
                                  className={`form-control customInput-${theme} py-1`}
                                />
                                <span
                                  style={{
                                    maxHeight: "29px",
                                  }}
                                  className={`custom-input-group-right-${theme} py-1`}
                                >
                                  <FaChevronUp
                                    style={{
                                      cursor: "pointer",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                  <FaChevronDown
                                    style={{
                                      cursor: "pointer",
                                      height: "12px",
                                      width: "12px",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                )}
              </div>
            </div>
          </Container>
        </Col>
      </Row>
    </div>
  );
}

export default Stock;


import React, { useState, useRef } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { getAllRoles, getAssignTransaction } from '../../stores/services/role.service';
import { editUser } from '../../stores/services/user.service';
import { toast, ToastContainer } from 'react-toastify';
import { getFeatures } from '../../stores/services/role.service';
import CheckboxTree from 'react-checkbox-tree';
import { getFundByAmc } from '../../stores/services/funds.service';
import Select from "react-select";
import { useSelector } from 'react-redux';

const EditUser = () => {


    const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
    const history = useHistory();
    const email = sessionStorage.getItem('email') || '';
    const amc_code = sessionStorage.getItem("amc_code") || "";
    const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]") || [];
    const department = sessionStorage.getItem("department") || "";
    const allDefaultDepartments =
        JSON.parse(sessionStorage.getItem("defaultDepartments") || "[]") || [];
    const [selectedFund, setSelectedFund] = useState<any>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>({});
    const [selectedDepartmentRole, setSelectedDepartmentRole] = useState<any>({});
    const [selectedRole, setSelectedRole] = useState<any>({});
    const [allFund, setAllFund] = useState<any>([]);
    const [Loading, setLoading] = useState(false);
    const [user_name, setUserName] = useState('');
    const [user_email, setUserEmail] = useState('');
    //   const [role, setRole] = useState('-1');
    const [roles, setRoles] = useState<any>([]);
    const [additionalAccess, setAdditionalAccess] = useState<any>([]);
    const [deniedAccess, setDeniedAccess] = useState<any>([]);
    let [nameError, setNameError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [invalidEmailError, setInvalidEmailError] = useState(false);
    let [roleError, setRoleError] = useState(false);
    let [hideBtn, setHideBtn] = useState(false);
    let [labelValue, setLabelValue] = useState('');
    let alreadyAssignTxn: any;

    const [fileName, setFileName] = useState('');
    const [fileError, setFileError] = useState('');
    const [file, setFile] = useState('');
    const [base64SpicemenImg, setBase64SpicemenImg] = useState<any | null>(null);
    const myRef1 = useRef<HTMLInputElement>(null);

    const [roleAssignedFeatures, setRoleAssignedFeatures] = useState<any>([]);
    const [features, setFeatures] = useState([]);
    let [checked, setChecked] = useState<any>([]);
    let [expanded, setExpanded] = useState<any>([-1]);
    let [nodes, setNodes] = useState<any>([]);
    let [roleHistory, setRoleHistory] = useState<any>([]);

    const [loadingRole, setLoadingRole] = useState(false);
    const [checkboxTreeEnable, setCheckboxTreeEnable] = useState(false);
    const [roleDdl, setRoleDdl] = useState(false);

    let [txnChecked, setTxnChecked] = useState<any>([]);
    let [txnExpanded, setTxnExpanded] = useState<any>([-1]);
    let [txnNodes, setTxnNodes] = useState<any>([]);
    let [txnAssignCategory, setTxnAssignCategory] = useState<any>([]);




    const EditData = async () => {
        setLoadingRole(true);
        let additional_Access: any = [];
        let denied_Access: any = [];
        const obj = JSON.parse(sessionStorage.getItem('userObj') || '');
        setUserName(obj.name);
        setUserEmail(obj.email);
        setBase64SpicemenImg(obj.signature_specimen);
        if (obj.role_history !== undefined) {
            let role_histroy = JSON.parse(obj.role_history || '[]');
            setRoleHistory(role_histroy);
        }
        const allTxnTypes: any = JSON.parse(sessionStorage.getItem('allTxnTypes') || '[]');
        let data: any = [];
        if (obj.denied_transaction_access) {
            data = JSON.parse(obj.denied_transaction_access || '[]').map(elem => parseInt(elem.id));


        }
        alreadyAssignTxn = allTxnTypes.filter(x => !data.includes(x.id));

        setTxnChecked(alreadyAssignTxn.map(elem => parseInt(elem.id)));
        if (obj.denied_access !== '' && obj.denied_access !== '[]') {
            setDeniedAccess(JSON.parse(obj.denied_access));
            denied_Access.push(...JSON.parse(obj.denied_access));
        }
        if (obj.additional_access !== '' && obj.additional_access !== '[]') {
            setAdditionalAccess(JSON.parse(obj.additional_access));
            additional_Access.push(...JSON.parse(obj.additional_access));
        }
        if (obj.department || department) {
            let find = allDefaultDepartments.find(item => item.department == department || item.department == obj.department)
            if (find) {
                setSelectedDepartment({
                    value: find.department,
                    label: find.department,
                    departmentRoles: find.departmentRoles
                })

                if (obj.department_role) {
                    let findRole = find.departmentRoles.find(item => item == obj.department_role)
                    if (findRole) {
                        setSelectedDepartmentRole({ value: findRole, label: findRole })
                    }

                }
            }
        }


        if (sessionStorage.getItem('userPageType') === 'view') {
            setLabelValue('View');
            setCheckboxTreeEnable(true);
            setHideBtn(true);
        } else {
            setLabelValue('Edit');
        } // end of else

        getAllFunds(obj.fund_code)
        await getAllRoles(sessionStorage.getItem("email") || '', amc_code)
            .then((response) => {
                setRoles(response.data.data.filter(item => item.status == "active"));
                let find = response.data.data.find(item => item.role_name == obj.role)
                setSelectedRole({ value: find.role_name, label: find.role_name, status: find.status, features: find.features })
                // selected features
                let finalcheckedresult: any;
                let deniedresult: any;
                let additionalresult: any;

                if (denied_Access.length > 0) {
                    deniedresult = denied_Access.map((a) => {
                        return a.id;
                    });
                }

                if (additional_Access.length > 0) {
                    additionalresult = additional_Access.map((a) => {
                        return a.id;
                    });
                }

                // get role default features.
                const filterRole = response.data.data.filter((item: any) => {
                    return item.role_name === obj.role;
                })
                let selectedRoleFeatures = JSON.parse(filterRole[0].features);
                let defaultRoleFeatures = selectedRoleFeatures.map((a) => {
                    return a.id;
                });
                let roleDefFeatures = [...defaultRoleFeatures];
                // filter denied features from default role features.
                let d_access: any;
                if (deniedresult !== undefined && deniedresult.length > 0) {
                    d_access = defaultRoleFeatures.filter(val => !deniedresult.includes(val));
                    roleDefFeatures = [...d_access];
                }


                if (additionalresult !== undefined && additionalresult.length > 0) {
                    finalcheckedresult = [...roleDefFeatures, ...additionalresult]
                } else {
                    finalcheckedresult = [...roleDefFeatures]
                }
                finalcheckedresult = finalcheckedresult.map(function (e) { return e.toString() });
                setRoleAssignedFeatures(defaultRoleFeatures);
                setChecked(finalcheckedresult);
                // end selected features

            })
            .catch((err) => {
                setLoadingRole(false);
            })
        setLoadingRole(false);

        // get all features
        await getFeatures(sessionStorage.getItem('email') || '')
            .then((response) => {
                setFeatures(response.data.features);
                response.data.features.unshift({
                    id: -1,
                    feature: 'Assign All',
                    parent_id: null,
                });
                for (let index = 0; index < response.data.features.length; index++) {
                    if (response.data.features[index].parent_id === 0) {
                        response.data.features[index].parent_id = -1;
                    }
                    response.data.features[index].value =
                        response.data.features[index].id;
                    response.data.features[index].label =
                        response.data.features[index].feature;
                }
                var data = response.data.features;
                var root: any;
                const idMapping = data.reduce((acc: any, el: any, i: any) => {
                    acc[el.id] = i;
                    return acc;
                }, {});
                data.forEach((el: any) => {
                    // Handle the root element
                    if (el.parent_id === null) {
                        root = el;
                        return;
                    }
                    // Use our mapping to locate the parent element in our data array
                    const parentEl = data[idMapping[el.parent_id]];
                    // Add our current el to its parent's `children` array
                    parentEl.children = [...(parentEl.children || []), el];
                });
                setNodes([root]);
            })
            .catch((err) => {
            })
        let temp: any = []
        await getAssignTransaction(email)
            .then((response) => {
                setTxnAssignCategory(response.data.txn_assign_category);
                // const obj = JSON.parse(sessionStorage.getItem('userObj') || '');

                response.data.txn_assign_category.unshift({
                    id: -1,
                    feature: 'Transaction',
                    parent_id: null,
                });
                for (let index = 0; index < response.data.txn_assign_category.length; index++) {

                    if (response.data.txn_assign_category[index].parent_id === 0) {
                        response.data.txn_assign_category[index].parent_id = -1;
                    }
                    temp.push(response.data.txn_assign_category[index].id)
                    response.data.txn_assign_category[index].value = response.data.txn_assign_category[index].id;
                    response.data.txn_assign_category[index].label = response.data.txn_assign_category[index].feature;
                }
                if (alreadyAssignTxn.length === 0) {
                    setTxnChecked([...temp]);
                }
                var data = response.data.txn_assign_category;





                var root: any;
                const idMapping = data.reduce((acc: any, el: any, i: any) => {
                    acc[el.id] = i;
                    return acc;
                }, {});
                data.forEach((el: any) => {
                    // Handle the root element

                    if (el.parent_id === null) {
                        root = el;

                        return;
                    }
                    // Use our mapping to locate the parent element in our data array
                    const parentEl = data[idMapping[el.parent_id]];
                    // Add our current el to its parent's `children` array
                    parentEl.children = [...(parentEl.children || []), el];
                });
                setTxnNodes([root]);
            })
            .catch((err) => {
                // setLoadingRole(false);
            })
    }


    const getAllFunds = async (fund_code: any = []) => {
        try {
            const response = await getFundByAmc(email, amc_code, fund_code?.length > 0 ? fund_code : "");
            setAllFund(response.data.data);
            if (fund_code?.length > 0) {
                let find = response.data.data.filter(item => fund_code.find(item2 => item.symbol_code == item2))
                if (find.length > 0) {
                    setSelectedFund(find.map(item => {

                        return {
                            value: item.symbol_code,
                            label: item.fund_name
                        }
                    }))
                }
            }

        } catch (err: any) {
            if (err.response !== undefined) {
                toast.error(err.response.data.message);
            }
        }
    };


    React.useEffect(() => {
        EditData();
    }, [])
    const ValidateEmail = (email: string) => {
        if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            )
        ) {
            return true;
        }
        return false;
    };

    const upload = (e) => {
        let file = e?.target.files[0];
        if (file) {
            let filesize = parseInt(file.size);
            if (filesize <= 500000) {
                let type = file.name.substring(file.name.lastIndexOf('.') + 1);

                if (type === 'png' || type === 'jpg' || type === 'jpeg') {
                    //code here
                    setFile(file);
                    setFileName(file.name)
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = function () {
                        setBase64SpicemenImg(reader.result);
                    };
                } else {
                    toast.error('Invalid Format')
                }
            } else {
                toast.error("File size should be less than 500kb");
            }
        }

    };

    const addUserBtn = async () => {
        setNameError(false);
        setEmailError(false);
        setRoleError(false);
        setInvalidEmailError(false);
        setLoading(true);
        if (!user_name) {
            setNameError(true);
            setLoading(false);
            return;
        }
        else if (!user_email) {
            setEmailError(true);
            setLoading(false);
            return;
        }
        else if (!ValidateEmail(user_email)) {
            setInvalidEmailError(true);
            setLoading(false);
            return;
        }
        else if (!selectedRole?.value) {
            setRoleError(true);
            setLoading(false);
            return;
        } else if (fund_code?.length > 0 && selectedFund?.length == 0) {
            toast.error("Fund Selection is Required");
            setLoading(false);
            return;
        }
        let additionalaccess: any = [];
        additionalaccess = checked.filter(val => !roleAssignedFeatures.includes(parseFloat(val)));
        let selected_features: any = [];
        for (let index = 0; index < additionalaccess.length; index++) {
            const feature = features.find((x: any) => x.id === parseInt(additionalaccess[index]));
            if (feature) {
                selected_features.push(feature);
            }
        }

        let deniedaccess = roleAssignedFeatures.filter(val => !checked.includes(val.toString()));
        let denied_selected_features: any = [];
        if (roleAssignedFeatures.sort().join(',') !== checked.sort().join(',')) {
            for (let index = 0; index < deniedaccess.length; index++) {
                const feature = features.find((x: any) => x.id === parseInt(deniedaccess[index]));
                if (feature) {
                    denied_selected_features.push(feature);
                }
            }
        }
        txnChecked = txnChecked.map(function (e) { return e.toString() });
        const deniedTxn = await txnAssignCategory.filter((val: any) => !txnChecked.includes(val.id.toString()));
        deniedTxn.shift();


        editUser(email,
            JSON.stringify(selected_features),
            amc_code,
            fund_code?.length > 0 ? fund_code : selectedFund?.length > 0 ? selectedFund?.map(item => item.value) : [],
            JSON.stringify(denied_selected_features),
            user_name,
            selectedRole?.value,
            selectedRole?.status,
            base64SpicemenImg == null ? "" : base64SpicemenImg.toString(),
            JSON.stringify(deniedTxn),
            selectedRole.features,
            department
                ? department
                : selectedDepartment?.value
                    ? selectedDepartment?.value
                    : "",
            selectedDepartmentRole?.value ? selectedDepartmentRole?.value : "",
            user_email)
            .then((response) => {
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(function () {
                        history.replace('/admin/user-management');
                    }, 3000);
                } else {
                    toast.error(response.data.message?.toString());
                    setLoading(false);
                }
                // setLoading(false);	
            })
            .catch((err) => {
                setLoading(false);
                if (err.response !== undefined) {
                    toast.error(err.response.data.message?.toString());
                } else {
                    toast.error('Request Failed!');
                }
            });
        // setLoading(false);	
    }


    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? 'hsla(291, 64%, 42%, 0.5)'
                : isSelected
                    ? 'hsla(291, 64%, 42%, 1)'
                    : undefined,
            zIndex: 1
        }),
        menuList: styles => ({
            ...styles,
            background: theme == "dark" ? "#3b3b3b" : "#ffffff",
            color: theme == "dark" ? "white" : ""
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        control: (base, state) => ({
            ...base,
            background: theme == "dark" ? "#25273a" : "#f2f2f2",
            borderRadius: "0.4285rem",
            borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "" : "#ba54f5"
            },
            '& .react-select__input input[type="color"]': {
                color: theme === 'dark' ? 'red' : 'black'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme == "dark" ? "white" : "black",
        }),
        input: base => ({
            ...base,
            color: theme == "dark" ? "white" : "black"
        })
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer limit={1} />
                            <div className="card mt-4">
                                <div className="card-header">
                                    <h4 className="card-title">{labelValue} User</h4>
                                </div>
                                <div className="card-body">
                                    <div className="title-row">
                                        <h3 className="mb-1">User Info</h3>
                                        <Link to="/admin/user-management" className="t-3 btn btn-primary btn-sm" replace> <i className="fa fa-eye mr-2"></i> View All</Link>
                                    </div>
                                    {hideBtn === true ? (
                                        ''
                                    ) : (
                                        <p className="t-3 mb-2">
                                            Please enter the following information to edit a new admin or
                                            user for trustee system
                                        </p>
                                    )}
                                    <div className="card-body">
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input className="form-control" readOnly={labelValue === 'View' ? true : false} defaultValue={user_name} onChange={(e) => {
                                                        setUserName(e.target.value);
                                                    }} />
                                                </div>
                                                {nameError === true ? (
                                                    <p className="error-labels">Name is Required.</p>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input className={"form-control " + (labelValue === 'View' ? '' : 'disable')} defaultValue={user_email} onChange={(e) => {
                                                        setUserEmail(e.target.value);
                                                    }} disabled={true} />
                                                </div>
                                                {emailError === true ? (
                                                    <p className="error-labels">Email is Required.</p>
                                                ) : (
                                                    ''
                                                )}
                                                {invalidEmailError === true ? (
                                                    <p className="error-labels">Email is Invalid.</p>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>

                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Select Role</label>
                                                    {loadingRole ? (
                                                        <div className="form-control w-100">
                                                            <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                                        </div>
                                                    ) : (
                                                        <Select
                                                            className="react-select info "
                                                            classNamePrefix="react-select"
                                                            label={"Select"}
                                                            // isDisabled={false}
                                                            onChange={(e) => {
                                                                //e.preventDefault();
                                                                setSelectedRole(e?.value ? e : {});
                                                                if (e?.value) {
                                                                    const result = JSON.parse(
                                                                        e.features
                                                                    ).map((a: any) => {
                                                                        return a.id;
                                                                    });

                                                                    setRoleAssignedFeatures(result);
                                                                    setChecked(result);
                                                                } else {
                                                                    setChecked([]);
                                                                }
                                                            }}

                                                            isDisabled={labelValue === 'View' ? true : false}

                                                            value={selectedRole?.value ? [selectedRole] : []}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={roles?.map((item) => {
                                                                return {
                                                                    value: item.role_name,
                                                                    label: item.role_name,
                                                                    status: item.status,
                                                                    features: item.features
                                                                };
                                                            })}
                                                            menuPortalTarget={document.body}
                                                            menuShouldBlockScroll={true}
                                                            styles={customStyles}
                                                        />

                                                    )}
                                                </div>
                                                {roleError === true ? (
                                                    <p className="error-labels">Role is Required.</p>
                                                ) : (
                                                    ''
                                                )}
                                            </Col>

                                            <Col md="6">
                                                <div className="form-group  ">
                                                    <label>Select Fund</label>
                                                    <Select
                                                        className="react-select info "
                                                        classNamePrefix="react-select"
                                                        label={"Select"}
                                                        isMulti={true}
                                                        isDisabled={labelValue === 'View' ? true : false}
                                                        onChange={(e) => {

                                                            setSelectedFund(e?.length > 0 ? e : []);

                                                        }}
                                                        value={selectedFund}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={allFund.map((item) => {
                                                            return {
                                                                value: item.symbol_code,
                                                                label: item.fund_name,
                                                            };
                                                        })}
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>

                                            {!department && (
                                                <Col md="6">
                                                    <div className="form-group  ">
                                                        <label>Select Department</label>
                                                        <Select
                                                            className="react-select info "
                                                            classNamePrefix="react-select"
                                                            label={"Select"}
                                                            isDisabled={labelValue === 'View' ? true : false}
                                                            onChange={(e) => {
                                                                //e.preventDefault();
                                                                setSelectedDepartment(e?.value ? e : {});
                                                                setSelectedDepartmentRole({})
                                                            }}
                                                            value={
                                                                selectedDepartment?.value
                                                                    ? [selectedDepartment]
                                                                    : []
                                                            }
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={allDefaultDepartments.map((item) => {
                                                                return {
                                                                    value: item.department,
                                                                    label: item.department,
                                                                    departmentRoles: item.departmentRoles,
                                                                };
                                                            })}
                                                            menuPortalTarget={document.body}
                                                            menuShouldBlockScroll={true}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </Col>
                                            )}

                                            <Col md="6">
                                                <div className="form-group  ">
                                                    <label>Select Department Role</label>
                                                    <Select
                                                        className="react-select info "
                                                        classNamePrefix="react-select"
                                                        placeholder={
                                                            selectedDepartment?.value
                                                                ? "Select"
                                                                : "First Select Department"
                                                        }
                                                        isDisabled={labelValue === 'View' ? true : false}
                                                        onChange={(e) => {
                                                            //e.preventDefault();
                                                            setSelectedDepartmentRole(e?.value ? e : {});
                                                        }}
                                                        value={
                                                            selectedDepartmentRole?.value
                                                                ? [selectedDepartmentRole]
                                                                : []
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={
                                                            selectedDepartment?.value
                                                                ? selectedDepartment?.departmentRoles?.map(
                                                                    (item) => {
                                                                        return { value: item, label: item };
                                                                    }
                                                                )
                                                                : []
                                                        }
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label> {hideBtn === true && base64SpicemenImg == '' ? ('') : ('Signature Specimen')} {hideBtn === false && (<small className="color-amber">(PNG or JPG of upto 500KB)</small>)}</label>
                                                    <div
                                                        className="multi-input pointer"
                                                        onClick={() => myRef1?.current?.click()}
                                                    >
                                                        <div className="form-group">
                                                            {hideBtn === false ? (
                                                                <>
                                                                    <div className="form-control">
                                                                        {fileName === '' ? 'Upload File' : fileName}
                                                                    </div>
                                                                    <input
                                                                        type="file"
                                                                        ref={myRef1}
                                                                        style={{ display: 'none' }}
                                                                        multiple={false}
                                                                        accept="image/png, image/jpeg"
                                                                        onChange={(e) => {
                                                                            upload(e);
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                ''
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>

                                                {base64SpicemenImg ? (
                                                    <img src={base64SpicemenImg} className="rounded" alt="spicemen" width="100px" height="100px" />
                                                ) : (
                                                    ''
                                                )}

                                            </Col>
                                        </Row>

                                        {loadingRole ? (
                                            <i className="fa fa-spinner fa-spin fa-1x" ></i>
                                        ) : (
                                            <>
                                                <div className="line"> </div>{" "}
                                                <Row>
                                                    <Col md='6'>
                                                        <Row className="mt-3 ml-2">
                                                            <Col md='12'>
                                                                <h3 className="mb-1"> Assign Additional Features </h3>{" "}
                                                                <p className="t-3 mb-2"> Features </p>
                                                            </Col>
                                                            <CheckboxTree
                                                                nodes={nodes}
                                                                checked={checked}
                                                                expanded={expanded}
                                                                disabled={labelValue === 'View' ? true : false}
                                                                onCheck={(checked) => {
                                                                    setChecked(checked);
                                                                }}
                                                                onExpand={(expanded) => {
                                                                    setExpanded(expanded)
                                                                }}
                                                                iconsClass="fa5"
                                                            />
                                                        </Row>
                                                    </Col>

                                                    <Col md='6'>
                                                        <Row className="mt-3 ml-2">
                                                            <Col md='12'>
                                                                <h3 className="mb-1"> Assign Transaction Types </h3>{" "}
                                                                <p className="t-3 mb-2"> Transaction Types </p>
                                                            </Col>
                                                            <CheckboxTree
                                                                nodes={txnNodes}
                                                                checked={txnChecked}
                                                                expanded={txnExpanded}
                                                                disabled={labelValue === 'View' ? true : false}

                                                                onCheck={(txnChecked, node) => {
                                                                    setTxnChecked(txnChecked);
                                                                }}
                                                                onExpand={(txnExpanded) => {
                                                                    setTxnExpanded(txnExpanded)
                                                                }}
                                                                iconsClass="fa5"
                                                            />
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </>
                                        )}
                                    </div>


                                    {hideBtn === false ? (
                                        <div>
                                            <button
                                                className="btn btn-primary"
                                                onClick={addUserBtn}
                                                disabled={Boolean(Loading)}
                                            >
                                                {Loading ? (
                                                    <>
                                                        <span
                                                            className="spinner-border login-txt spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <span className="login-txt"> Loading...</span>
                                                    </>
                                                ) : (
                                                    <span>Update User</span>
                                                )}
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {/* <div className="line"> </div>{" "} */}
                                    {
                                        roleHistory.length > 0 && (
                                            <div className="card-body">
                                                <div className="title-row">
                                                    <h3 className="mb-1">History</h3>
                                                </div>
                                                <div >
                                                    <table className="table mt-3 ">
                                                        <thead>
                                                            <th>From Role</th>
                                                            <th>To Role</th>
                                                            <th>Time Duration</th>
                                                            <th>Updated By</th>
                                                        </thead>
                                                        <tbody>
                                                            {roleHistory.map((item, index) => {
                                                                if (roleHistory.length === index + 1) {
                                                                    return (
                                                                        <tr className="highlight-row-success">
                                                                            <td>{item.from_role}</td>
                                                                            <td>{item.to_role}</td>
                                                                            <td>{(new Date(item.time).toLocaleString()).replaceAll('/', '-')}</td>
                                                                            <td>{item.email ? item.email : " "}</td>
                                                                        </tr>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.from_role}</td>
                                                                            <td>{item.to_role}</td>
                                                                            <td>{(new Date(item.time).toLocaleString()).replaceAll('/', '-')}</td>
                                                                            <td>{item.email ? item.email : " "}</td>
                                                                        </tr>
                                                                    )
                                                                }

                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default EditUser;
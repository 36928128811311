import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import { getAllRoles, updateRoleStatus } from "../../stores/services/role.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useSelector } from 'react-redux';
const RoleManage = () => {

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);

  const history = useHistory();
  const department = sessionStorage.getItem("department") || ""
  const allDefaultDepartments = JSON.parse(sessionStorage.getItem("defaultDepartments") || "[]") || []
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [roles, setRoles] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>({})
  const [searchName, setSearchName] = useState("")
  const [selectedStatus, setSelectedStatus] = useState<any>({})
  const [filteredArray, setFilteredArray] = useState([])
  const [filter, setFilter] = useState(false)
  React.useEffect(() => {
    const getRolesList = async () => {
      setLoading(true);
      try {
        const response = await getAllRoles(email, amc_code);
        setRoles(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!")
        }
      }
      setLoading(false);
    };
    getRolesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getRoleListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAllRoles(email, amc_code);
      setRoles(response.data.data)
    } catch (error: any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (roles) {
      let i;
      res.length = 0;
      setRes(res)
      for (i = 0; i < roles.length; i++) {
        res.push(roles[i]);
        setRes(res)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);
  const [res, setRes] = useState<any>([]);

  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < roles.length; i++) {
      td = roles[i].role_name;
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(roles[i]);
          setRes(res)
        } else {

        }
      }
    }
  }
  const email = sessionStorage.getItem('email') || '';
  const updateStatus = async () => {
    setbankLoading(true);
    let status = '';
    if (data.status === 'active') {
      status = 'deactive';
    } else {
      status = 'active';
    }
    try {
      await updateRoleStatus(email, data.role_name, data.description, data.features, status)
      toast.success('Status Updated Successfully');
      setStatusSetPopup(false);
      getRoleListUpdated();
    } catch (error) { }
    setbankLoading(false);
  }
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [bankLoading, setbankLoading] = useState(false);
  const renderUpdateStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
            enforceFocus={false}
          >
            <div className="modal-header justify-content-center">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => { setStatusSetPopup(false) }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Action</h6>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {data.status === 'active' ? (<img src="assets/ban.svg" alt="" width="70" />) : (<img src="assets/check.svg" alt="" width="70" />)}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to {data.status === 'active' ? 'Deactivate' : 'Activate'} this Role?
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col >
                    <div >
                      <button className="btn btn-primary" disabled={Boolean(bankLoading)} onClick={() => { updateStatus(); }}>
                        {bankLoading ? (<><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="login-txt"> Loading...</span></>) : ('Yes')}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button className="btn btn-default" onClick={() => { setStatusSetPopup(false) }}>
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal >
        );
      default:
        return '';
    }
  };
  const renderData = (dataTable) => {
    return dataTable.map((role: any, index: any) => {
      return (
        <tr key={index}>
          <td>{role.role_name.replace('ROLE_', '')}</td>
          <td>{role.description}</td>
          <td className="captilize">{role.status}</td>
          <td>
            <div className="multi-icons pointer">

              <button type="button" className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={() => {
                  sessionStorage.setItem('roleObj', JSON.stringify(role));
                  sessionStorage.setItem('rolePageType', 'view');
                  history.replace('/admin/edit-role');
                }}
              >
                <i className="fas fa-eye"></i>
              </button>
              <button type="button" className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem('roleObj', JSON.stringify(role));
                  sessionStorage.setItem('rolePageType', 'edit');
                  history.replace('/admin/edit-role');
                }
                }>
                <i className="tim-icons icon-pencil"></i></button>


              {role.status === 'active' ? (
                <button type="button" className="btn-icon btn-link like btn btn-success btn-sm"

                  onClick={() => {
                    setStatusSetPopup(true);
                    setData(role);
                  }}>
                  <i className="fas fa-check"></i>
                </button>

              ) : (

                <button type="button" className="btn-icon btn-link like btn btn-danger btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setData(role);
                  }} >
                  <i className="tim-icons icon-simple-remove"></i>
                </button>


              )}
            </div>
          </td>
        </tr>
      );
    })
  }

  const filterData = () => {
    setFilter(true)
    let filteredObject = {
      role_name: searchName?.toLowerCase().trim(),
      status: selectedStatus?.value == "all" ? "" : selectedStatus?.value ? selectedStatus?.value : "",
      role_type: selectedDepartment?.value || "",
    };

    let filtered = Object.fromEntries(
      Object.entries(filteredObject).filter(([_, v]) => v !== undefined && v !== "")
    )

    setFilteredArray(roles.filter(item => {
      const match = Object.entries(filtered).every(([key, value]) => {
        if (key === "status") {
          return item[key] === value;
        }
        else if (key === "role_type") {
          return item[key] === value;
        } else if (key === "role_name") {
          return item[key]?.toLowerCase()?.includes(value?.toLowerCase()?.trim())
        }
        // return item[key]?.toLowerCase() === value;
      })



      return match

    }))
  }


  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h4 className="card-title">Role Management</h4>
                  <button className="btn btn-primary btn-sm ml-4" onClick={
                    () => {
                      history.replace("/admin/add-role");
                    }
                  }>
                    <i className="fa fa-plus mr-2"></i> Add New
                  </button>
                </div>
                {!Loading ? (
                  <div className="card-body">
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>Name</label>
                          <ReactTooltip textColor='white' backgroundColor='black' effect="float" />
                          <input
                            style={{
                              paddingTop: '6px',
                              paddingBottom: "6px",
                            }}
                            type="search" id="myInput" className="form-control" data-tip="Search User" placeholder="Search Name" value={searchName}
                            onChange={(e) => {
                              setSearchName(e.target.value)

                            }} />
                        </div>

                      </Col>




                      {!department &&
                        <Col md="4">
                          <div className="form-group  ">
                            <label>Select Role Type</label>
                            <Select
                              label={'Select'}
                              onChange={(e) => {
                                setSelectedDepartment(e?.value ? e : {})
                              }}
                              value={selectedDepartment?.value ? [selectedDepartment] : []}

                              options={allDefaultDepartments.map(item => {
                                return { value: item.department?.replace(" ", "_"), label: item.department }
                              })}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              styles={customStyles}
                            />
                          </div>
                        </Col>}
                      <Col md="4">
                        <div className="form-group  ">
                          <label>Select Status</label>
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={'Select'}

                            // isDisabled={false}
                            onChange={(e) => {
                              //e.preventDefault();
                              setSelectedStatus(e?.value ? e : {})

                            }}
                            value={selectedStatus?.value ? [selectedStatus] : []}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={[{ label: "All", value: "all" }, { label: "Active", value: "active" }, { label: "Inactive", value: "deactive" }]}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            styles={customStyles}
                          />
                        </div>
                      </Col>



                      <Col md="4">
                        <div className=" inline-box">
                          <button
                            className="btn btn-primary mt-4 ml-2"
                            disabled={Boolean(Loading)}
                            onClick={() => {
                              filterData()

                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Searching...</span>
                              </>
                            ) : (
                              "Search"
                            )}
                          </button>

                          <button
                            className="btn btn-default ml-2 mt-4 ml-2"
                            disabled={Boolean(Loading)}
                            style={{
                              width: "148px",
                            }}
                            onClick={() => {
                              setFilteredArray([])
                              setFilter(false)

                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Clear</span>
                              </>
                            ) : (
                              "Clear"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>status</th>
                            <th >Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            renderData(filter ? filteredArray : res)
                          }
                        </tbody>
                      </table>

                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="fa fa-spinner fa-spin fa-3x mt-5 mb-5" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>)}
                {renderUpdateStatusPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RoleManage;
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import {
  getFundByAmc,
  getFundExpenses,
  validateFundNav,
} from "../stores/services/funds.service";
import { getNewChartsOfAccountsByFund } from "../stores/services/chatofaccounts.service";
import { getAllLedgers, getLedgerAccounts } from "../utils/customFunction";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { numberWithCommas } from "./../utils/customFunction";

import { useSelector } from "react-redux";
import Select from "react-select";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyExpense = (props: any) => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const myRef = React.useRef<any>(null);
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [chartOfAccounts, setChartOfAccounts] = useState<any>(undefined);

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  let [ledgerTableData, setLedgerTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");
  const [deleting, setDeleting] = useState<any>(false);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "dailyexpenseaccrual",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: 0,
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  const getChartOfAccountsFromBackend = async () => {
    try {
      setLoading(true);
      let response = await getNewChartsOfAccountsByFund(email, amc_code, fund);
      if (response.data.status === 200) {
        setChartOfAccounts(response.data.data?.main_family);
        setLoading(false);
      } else {
        toast.error("No associated chart of account!");

        setChartOfAccounts(undefined);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  useEffect(() => {
    if (fund) {
      getChartOfAccountsFromBackend();
    }
  }, [fund]);

  React.useEffect(() => {
    setHeadLabel("dailyexpenseaccrual");
    fields["txnr_type"] = "dailyexpenseaccrual";
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    getFundByAMcCode(amc_code);
  }, []);

  const getBankAccounts = async () => {
    try {
      let ledgersacc = getLedgerAccounts(
        chartOfAccounts[3].family[0].sub_family
      );

      setLegderAccounts(ledgersacc);

      let allLedgers = await getAllLedgers(chartOfAccounts);

      setAllLedgers(allLedgers);
    } catch (error) {}
  };

  useEffect(() => {
    if (chartOfAccounts && chartOfAccounts.length > 0) {
      getBankAccounts();
    }
  }, [chartOfAccounts]);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code}) - SMA`,
        };
      } else {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code})`,
        };
      }
    });
  };

  const handleGetAccounts = async () => {
    setTableData([]);
    setLedgerTableData([]);
    setLoading(true);
    setDisableProfit(false);
    setProfitRateConfirmed(false);

    if (fund === "") {
      setLoading(false);
      return toast.error("Fund is required");
    }

    if (amc_code === "") {
      setLoading(false);
      return toast.error("Invalid user. Login Again");
    }

    const response = await getFundExpenses(
      sessionStorage.getItem("email") || "",
      sessionStorage.getItem("amc_code") || "",
      fund
    );

    if (response.status === 200 && response.data.status === 200) {
      if (response?.data?.data && response?.data?.data?.length > 0) {
        let temp: any[] = [];
        for (let i = 0; i < response.data.data.length; i++) {
          let item = response.data.data[i];
          let yearDays =
            response.data.data[i]?.yearly_days ||
            response.data.data[i]?.yearly_days === 0
              ? response.data.data[i]?.yearly_days
              : 365;
          let halfYearlyDays =
            response.data.data[i]?.half_yearly_days ||
            response.data.data[i]?.half_yearly_days === 0
              ? response.data.data[i]?.half_yearly_days
              : 365;
          let quarterlyDays =
            response.data.data[i]?.quarterly_days ||
            response.data.data[i]?.quarterly_days === 0
              ? response.data.data[i]?.quarterly_days
              : 365;

          let dailyQuarter = (
            parseFloat(item.quarterly_fee) / quarterlyDays
          ).toFixed(2);
          let dailyHalf = (
            parseFloat(item.half_yearly_fee) / halfYearlyDays
          ).toFixed(2);
          let dailyYearly = (parseFloat(item.yearly_fee) / yearDays).toFixed(2);
          if (
            parseFloat(
              dailyQuarter &&
                dailyQuarter !== "NaN" &&
                dailyQuarter !== "undefined" &&
                dailyQuarter !== "Infinity"
                ? dailyQuarter
                : "0"
            ) === 0 &&
            parseFloat(
              dailyHalf &&
                dailyHalf !== "NaN" &&
                dailyHalf !== "undefined" &&
                dailyHalf !== "Infinity"
                ? dailyHalf
                : "0"
            ) === 0 &&
            parseFloat(
              dailyYearly &&
                dailyYearly !== "NaN" &&
                dailyYearly !== "undefined" &&
                dailyYearly !== "Infinity"
                ? dailyYearly
                : "0"
            ) === 0
          ) {
            continue;
          }

          let totalExpense =
            parseFloat(
              dailyQuarter &&
                dailyQuarter !== "NaN" &&
                dailyQuarter !== "undefined" &&
                dailyQuarter !== "Infinity"
                ? dailyQuarter
                : "0"
            ) +
            parseFloat(
              dailyHalf &&
                dailyHalf !== "NaN" &&
                dailyHalf !== "undefined" &&
                dailyHalf !== "Infinity"
                ? dailyHalf
                : "0"
            ) +
            parseFloat(
              dailyYearly &&
                dailyYearly !== "NaN" &&
                dailyYearly !== "undefined" &&
                dailyYearly !== "Infinity"
                ? dailyYearly
                : "0"
            );

          temp.push({
            id: i,
            fund_code: fund,
            amc_code: amc_code,
            gl_code: item.ledger_code,
            gl_name: item.ledger_name,
            quarterly_fee: item.quarterly_fee,
            half_yearly_fee: item.half_yearly_fee,
            yearly_fee: item.yearly_fee,
            daily_quarterly_expense:
              dailyQuarter &&
              dailyQuarter !== "NaN" &&
              dailyQuarter !== "undefined" &&
              dailyQuarter !== "Infinity"
                ? dailyQuarter
                : "0",
            daily_half_yearly_expense:
              dailyHalf &&
              dailyHalf !== "NaN" &&
              dailyHalf !== "undefined" &&
              dailyHalf !== "Infinity"
                ? dailyHalf
                : "0",
            daily_yearly_expense:
              dailyYearly &&
              dailyYearly !== "NaN" &&
              dailyYearly !== "undefined" &&
              dailyYearly !== "Infinity"
                ? dailyYearly
                : "0",
            total_expense:
              totalExpense || totalExpense === 0
                ? totalExpense.toFixed(2)
                : "0",
            associated_ledgers: item.related_ledger_code,
          });
        }

        let tableTemp: any[] = [];
        for (let i = 0; i < temp.length; i++) {
          let data = temp[i];
          const responseMain = await getLedgerInfo(email, data.gl_code, fund);
          if (data.associated_ledgers) {
            const responseAssociated = await getLedgerInfo(
              email,
              data.associated_ledgers,
              fund
            );
            if (responseMain && responseAssociated) {
              tableTemp.push(
                {
                  account_number: "",
                  account_title: "",
                  amc_code: amc_code,
                  balance: "",
                  bank_code: "",
                  branch: "",
                  child_family_code: responseMain.child_family_code,
                  child_family_name: responseMain.child_family_code,
                  counter_account_number: "",
                  counter_account_title: "",
                  counter_bank: "",
                  counter_branch: "",
                  created_at: "",
                  credit_amount: "0",
                  debit_amount: data.total_expense,
                  description: "Daily Recurring Expense",
                  doc_type: "",
                  entered_by: "",
                  evidence: "",
                  family_code: responseMain.family_code,
                  family_name: responseMain.family_name,
                  fund_code: fund,
                  gl_code: responseMain.gl_code,
                  gl_name: responseMain.gl_name,
                  instruction_date: "",
                  instrument_by: "",
                  instrument_date: "",
                  instrument_no: "",
                  instrument_type: "",
                  main_family_code: responseMain.main_family_code,
                  main_family_name: responseMain.main_family_name,
                  realized: "true",
                  realized_by: "",
                  realized_date: "",
                  sub_family_code: responseMain.sub_family_code,
                  sub_family_name: responseMain.sub_family_name,
                  period: sessionStorage.getItem("active_period") || "",
                  associated_ledger_code: responseAssociated.gl_code,
                },
                {
                  account_number: "",
                  account_title: "",
                  amc_code: amc_code,
                  balance: "",
                  bank_code: "",
                  branch: "",
                  child_family_code: responseAssociated.child_family_code,
                  child_family_name: responseAssociated.child_family_code,
                  counter_account_number: "",
                  counter_account_title: "",
                  counter_bank: "",
                  counter_branch: "",
                  created_at: "",
                  credit_amount: data.total_expense,
                  debit_amount: "0",
                  description: "Daily Recurring Expense",
                  doc_type: "",
                  entered_by: "",
                  evidence: "",
                  family_code: responseAssociated.family_code,
                  family_name: responseAssociated.family_name,
                  fund_code: fund,
                  gl_code: responseAssociated.gl_code,
                  gl_name: responseAssociated.gl_name,
                  instruction_date: "",
                  instrument_by: "",
                  instrument_date: "",
                  instrument_no: "",
                  instrument_type: "",
                  main_family_code: responseAssociated.main_family_code,
                  main_family_name: responseAssociated.main_family_name,
                  realized: "true",
                  realized_by: "",
                  realized_date: "",
                  sub_family_code: responseAssociated.sub_family_code,
                  sub_family_name: responseAssociated.sub_family_name,
                  period: sessionStorage.getItem("active_period") || "",
                  associated_ledger_code: responseMain.gl_code,
                }
              );
            }
          } else {
            toast.error(`Ledger details not found for ledger ${data.gl_code}`);
            setLoading(false);
            break;
          }
        }

        setTableData(temp);
        setLedgerTableData(tableTemp);
      } else {
        toast.error("NO EXPENSE ASSOCIATED WITH SELECTED FUND!");
        setLoading(false);

        return;
      }
    } else {
      toast.error("NO EXPENSE ASSOCIATED WITH SELECTED FUND!");
      setLoading(false);

      return;
    }

    setLoading(false);
  };

  const scrollToTop = () => {
    return myRef?.current?.scrollIntoView();
  };

  const handleDailyExpense = async () => {
    sendingLedgers = [];

    setLoading(true);
    let email = sessionStorage.getItem("email");
    if (!tableData || tableData.length === 0) {
      toast.error("NO DATA!");
      return;
    }

    // for (let i = 0; i < tableData.length; i++) {
    //   let data = tableData[i];
    //   const responseMain = await getLedgerInfo(email, data.gl_code, fund);
    //   if (data.associated_ledgers) {
    //     const responseAssociated = await getLedgerInfo(
    //       email,
    //       data.associated_ledgers,
    //       fund
    //     );
    //     if (responseMain && responseAssociated) {
    //       sendingLedgers.push(
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: responseMain.child_family_code,
    //           child_family_name: responseMain.child_family_code,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: "0",
    //           debit_amount: data.total_expense,
    //           description: "Daily Recurring Expense",
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: responseMain.family_code,
    //           family_name: responseMain.family_name,
    //           fund_code: fund,
    //           gl_code: responseMain.gl_code,
    //           gl_name: responseMain.gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: responseMain.main_family_code,
    //           main_family_name: responseMain.main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: responseMain.sub_family_code,
    //           sub_family_name: responseMain.sub_family_name,
    //           period: sessionStorage.getItem("active_period") || "",
    //         },
    //         {
    //           account_number: "",
    //           account_title: "",
    //           amc_code: amc_code,
    //           balance: "",
    //           bank_code: "",
    //           branch: "",
    //           child_family_code: responseAssociated.child_family_code,
    //           child_family_name: responseAssociated.child_family_code,
    //           counter_account_number: "",
    //           counter_account_title: "",
    //           counter_bank: "",
    //           counter_branch: "",
    //           created_at: "",
    //           credit_amount: data.total_expense,
    //           debit_amount: "0",
    //           description: "Daily Recurring Expense",
    //           doc_type: "",
    //           entered_by: "",
    //           evidence: "",
    //           family_code: responseAssociated.family_code,
    //           family_name: responseAssociated.family_name,
    //           fund_code: fund,
    //           gl_code: responseAssociated.gl_code,
    //           gl_name: responseAssociated.gl_name,
    //           instruction_date: "",
    //           instrument_by: "",
    //           instrument_date: "",
    //           instrument_no: "",
    //           instrument_type: "",
    //           main_family_code: responseAssociated.main_family_code,
    //           main_family_name: responseAssociated.main_family_name,
    //           realized: "true",
    //           realized_by: "",
    //           realized_date: "",
    //           sub_family_code: responseAssociated.sub_family_code,
    //           sub_family_name: responseAssociated.sub_family_name,
    //           period: sessionStorage.getItem("active_period") || "",
    //         }
    //       );
    //       fields.execution_date = moment(date).format("YYYY-MM-DD");
    //       fields.instruction_date = moment(date).format("YYYY-MM-DD");
    //       fields.executed_date = moment(date).format("YYYY-MM-DD");
    //       fields.instrument_date = moment(date).format("YYYY-MM-DD");
    //       fields.realized_date = moment(date).format("YYYY-MM-DD");
    //       fields.sale_date = moment(date).format("YYYY-MM-DD");
    //       fields.issue_date = moment(date).format("YYYY-MM-DD");
    //       fields.conversion_date = moment(date).format("YYYY-MM-DD");
    //       fields.maturity_date = moment(date).format("YYYY-MM-DD");
    //       fields.redemption_date = moment(date).format("YYYY-MM-DD");
    //       fields.settlement_date = moment(date).format("YYYY-MM-DD");
    //       fields.last_coupon_date = moment(date).format("YYYY-MM-DD");
    //       fields.next_coupon_date = moment(date).format("YYYY-MM-DD");
    //       fields["net_amount"] = (
    //         parseFloat(fields.net_amount) + parseFloat(data.total_expense)
    //       ).toFixed(2);
    //     }
    //   } else {
    //     toast.error(`Ledger details not found for ledger ${data.gl_code}`);
    //     setLoading(false);
    //     break;
    //   }
    // }

    for (let i = 0; i < ledgerTableData.length; i++) {
      let data = ledgerTableData[i];
      sendingLedgers.push(data);
      fields.execution_date = moment(date).format("YYYY-MM-DD");
      fields.instruction_date = moment(date).format("YYYY-MM-DD");
      fields.executed_date = moment(date).format("YYYY-MM-DD");
      fields.instrument_date = moment(date).format("YYYY-MM-DD");
      fields.realized_date = moment(date).format("YYYY-MM-DD");
      fields.sale_date = moment(date).format("YYYY-MM-DD");
      fields.issue_date = moment(date).format("YYYY-MM-DD");
      fields.conversion_date = moment(date).format("YYYY-MM-DD");
      fields.maturity_date = moment(date).format("YYYY-MM-DD");
      fields.redemption_date = moment(date).format("YYYY-MM-DD");
      fields.settlement_date = moment(date).format("YYYY-MM-DD");
      fields.last_coupon_date = moment(date).format("YYYY-MM-DD");
      fields.next_coupon_date = moment(date).format("YYYY-MM-DD");
      if (data.debit_amount && data.debit_amount > 0)
        fields["net_amount"] = (
          parseFloat(fields.net_amount) + parseFloat(data.debit_amount)
        ).toFixed(2);
    }

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "dailyexpenseaccrual";
      fields.net_amount = fields.net_amount.toString();
      fields.payment_type = "Payment";
      if (fields.ledgers.length > 1) {
        try {
          const navCheck = await validateFundNav(
            sessionStorage.getItem("email") || "",
            amc_code,
            fields?.fund_code,
            fields?.settlement_date
          );

          if (navCheck?.status === 200 && navCheck?.data?.status !== 200) {
            toast.error(navCheck?.data?.message);
            setLoading(false);
            return;
          }
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`Data added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              // history.push("/admin/create-transaction");
              // history.replace(`/admin/transactions-accounts`);
              history.goBack();
            }, 3000);
            // toast.success(response.data.message);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    }

    setTableData([]);
    setLoading(false);

    setProfitRateConfirmed(true);
    // setLoading(false);
  };

  const handleDelete = (gl_code) => {
    setDeleting(true);
    setLedgerTableData(
      ledgerTableData.filter((data) => {
        return (
          gl_code !== data.gl_code && gl_code !== data.associated_ledger_code
        );
      })
    );
    setTableData(
      tableData.filter((data) => {
        return data.gl_code !== gl_code;
      })
    );

    toast.info("Expense Removed!");
    setTimeout(() => setDeleting(false), 100);
  };

  const getLedgerInfo = async (email, ledger_code, fund_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code, fund_code);
    return result.data.data;
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund && selectedFund.nature === "Special Managed Account"
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code}) - SMA`,
        }
      : selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <ToastContainer limit={1} />

      <div className="content" ref={myRef}>
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>
                    Daily Expense Accrual
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Daily Expense Accrual</h4>
                    </div>
                    <div className="card-body d-flex align-items-center row">
                      <div
                        className="form-group col-md-4"
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <Select
                            disabled={false}
                            value={selectedFundName}
                            label={fundCheck ? "No Fund Found" : "Select Fund"}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setSendingLedgers([]);
                              fields.fund_code = e?.value;
                              fields.execution_date =
                                moment().format("YYYY-MM-DD");
                              fields.instruction_date =
                                moment().format("YYYY-MM-DD");
                              fields.executed_date =
                                moment().format("YYYY-MM-DD");
                              fields.instrument_date =
                                moment().format("YYYY-MM-DD");
                              fields.realized_date =
                                moment().format("YYYY-MM-DD");
                              fields.sale_date = moment().format("YYYY-MM-DD");
                              fields.issue_date = moment().format("YYYY-MM-DD");
                              fields.conversion_date =
                                moment().format("YYYY-MM-DD");
                              fields.maturity_date =
                                moment().format("YYYY-MM-DD");
                              fields.redemption_date =
                                moment().format("YYYY-MM-DD");
                              fields.settlement_date =
                                moment().format("YYYY-MM-DD");
                              fields.last_coupon_date =
                                moment().format("YYYY-MM-DD");
                              fields.next_coupon_date =
                                moment().format("YYYY-MM-DD");
                              fields.ledgers = "";

                              fields["net_amount"] = 0;

                              setFund(e?.value);
                              setLoading(false);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropdown()}
                            styles={customStyles}
                          />
                        )}
                      </div>

                      <div
                        className="form-group col-md-4"
                        data-tip="Select Date"
                      >
                        Select Date
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <input
                            id="input_Data"
                            className="form-control input_date"
                            type="date"
                            value={date}
                            defaultValue={date}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setSendingLedgers([]);
                              fields.execution_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.instruction_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.executed_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.instrument_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.realized_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.sale_date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              fields.issue_date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              fields.conversion_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.maturity_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.redemption_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.settlement_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.last_coupon_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.next_coupon_date = moment(
                                e.target.value
                              ).format("YYYY-MM-DD");
                              fields.ledgers = "";
                              fields["net_amount"] = 0;
                              setDate(e.target.value);
                              setLoading(false);
                            }}
                          />
                        )}
                      </div>

                      <div className="col-md-6 mt-2">
                        <button
                          className="btn btn-primary"
                          disabled={
                            Boolean(Loading) || !fund || !chartOfAccounts
                          }
                          onClick={() => handleGetAccounts()}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Get Expenses</span>
                          )}
                        </button>
                      </div>
                      {/* </Row> */}
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : tableData && tableData.length !== 0 && !Loading ? (
                <>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title text-primary">Expense</h4>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <td colSpan={4}>
                                    Fund Code: &nbsp; &nbsp;
                                    {tableData[0]?.fund_code}
                                  </td>
                                  <td colSpan={5}>
                                    AMC Code: &nbsp; &nbsp;{" "}
                                    {tableData[0]?.amc_code}
                                  </td>
                                </tr>
                                <tr>
                                  {/* <th>Sr.</th> */}
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th className="text-right">Quarterly Fee</th>
                                  <th className="text-right">
                                    Daily Expense (Quarterly)
                                  </th>
                                  <th className="text-right">
                                    Half Yearly Fee
                                  </th>
                                  <th className="text-right">
                                    Daily Expense (Half Yearly)
                                  </th>
                                  <th className="text-right">Annual Fee</th>
                                  <th className="text-right">
                                    Daily Expense (Annual)
                                  </th>
                                  <th className="text-right">Total Expense</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((data, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={
                                        deleting
                                          ? {
                                              background:
                                                theme == "light"
                                                  ? ""
                                                  : "rgba(76,88,119,0.1)",
                                            }
                                          : {
                                              background:
                                                theme == "light"
                                                  ? ""
                                                  : "rgba(37,41,61,1)",
                                            }
                                      }
                                    >
                                      <td>{data.gl_code}</td>
                                      <td>{data.gl_name}</td>
                                      <td align="right">
                                        {numberWithCommas(data.quarterly_fee)}
                                      </td>

                                      <td align="right">
                                        {numberWithCommas(
                                          data.daily_quarterly_expense
                                        )}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(data.half_yearly_fee)}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(
                                          data.daily_half_yearly_expense
                                        )}
                                      </td>

                                      <td align="right">
                                        {numberWithCommas(data.yearly_fee)}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(
                                          data.daily_yearly_expense
                                        )}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(data.total_expense)}
                                      </td>
                                      <td align="center">
                                        <i
                                          className="tim-icons icon-simple-remove"
                                          style={{
                                            color: "red",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDelete(data.gl_code)
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {ledgerTableData && ledgerTableData.length > 0 ? (
                    <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title text-primary">Ledgers</h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Code</th>
                                    <th>Ledger</th>
                                    <th className="text-right">Debit Amount</th>
                                    <th className="text-right">
                                      Credit Amount
                                    </th>
                                    <th>Type</th>
                                    <th>Ledger Family</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ledgerTableData.map((data, index) => (
                                    <tr key={data.gl_code}>
                                      <td>{data.gl_code}</td>
                                      <td>{data.gl_name}</td>
                                      <td align="right">
                                        {numberWithCommas(data.debit_amount)}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(data.credit_amount)}
                                      </td>
                                      <td>{data.type}</td>

                                      <td>{data.main_family_name}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  {tableData &&
                  tableData.length > 0 &&
                  ledgerTableData &&
                  ledgerTableData.length > 0 ? (
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          className="btn btn-primary"
                          disabled={Boolean(Loading)}
                          onClick={() => {
                            scrollToTop();
                            handleDailyExpense();
                          }}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            <span>Save Daily Expense</span>
                          )}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyExpense;

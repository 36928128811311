import React from "react";
// import Header from './../../components/Header';
import { useState } from "react";
import { getAllBanks, getCities } from "./../../stores/services/bank.service";
import { getBranchByBankName } from "./../../stores/services/branch.service";
import { editUnitHolder } from "./../../stores/services/unit-holder.service";
import { getFundByAmc } from "./../../stores/services/funds.service";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import InputMask from "react-input-mask";
import {
  accountTitle_AccountNumber_Regex,
  numberWithCommas,
} from "./../../utils/customFunction";
import { getSalesCompanyByAMCCode } from "../../stores/services/sales.service";
import { getInvestorByID } from "../../stores/services/potential-investor.service";
import moment from "moment";
import { useSelector } from "react-redux";
import Select from "react-select";

const EditClients = () => {

  const renderTypeDropdown = [
    { label: "Individual", value: "Individual" },
    { label: "Corporate", value: "Corporate" }
  ];

  const portfolioArr = [
    { label: "Fund", value: "fund" },
    { label: "SMA", value: "sma" }
  ];
  const taxStatusArr = [
    { label: "Filer", value: "Filer" },
    { label: "Non-Filer", value: "Non-Filer" },
    { label: "Exempted", value: "Exempted" }
  ];

  const territoryArr = [
    { label: "Islamabad", value: "Islamabad" },
    { label: "Punjab", value: "Punjab" },
    { label: "Khyber Pakhtunkhwa", value: "Khyber Pakhtunkhwa" },
    { label: "Balochistan", value: "Balochistan" },
    { label: "Sindh", value: "Sindh" },
    { label: "Gilgit Baltistan", value: "Gilgit Baltistan" },
    { label: "Azad Kashmir", value: "Azad Kashmir" }
  ];

  const incomeTaxStatusArr = [
    { label: "Filer", value: "Filer" },
    { label: "Non-Filer", value: "Non-Filer" }
  ];

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  //hooks for getting all inputs from user
  const [amcName, setAmcName] = useState("");
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const [name, setName] = useState("");
  const [folioNo, setFolioNo] = useState("");
  const [cnic, setCnic] = useState("");
  const [cnicExpiry, setCnicExpiry] = useState("");
  const [ntn, setNtn] = useState("");
  const [mainFolioNo, setMainFolioNo] = useState("");
  const [taxStatus, setTaxStatus] = useState("");
  //
  const [clientEmail, setClientEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  // const [filer, setFiler] = useState(false)	;
  // const updateFiler = () => setFiler(!filer)
  const [province, setProvince] = useState("");
  //
  const [accTitle, setAccTitle] = useState("");
  const [accNo, setAccNo] = useState("");
  const [bankname, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  //
  const [blnUpdate, setBlnUpdate] = useState<any>();
  const [reinvestCheck, setReinvestCheck] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  const [father, setFather] = useState("");
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(false);
  const [type, setType] = useState("");
  // guardian
  const [guardian_name, setGuardian_name] = useState("");
  const [guardian_relation, setGuardian_relation] = useState("");
  const [guardian_cnic, setGuardian_cnic] = useState("");
  // nominee
  const [nominee_name, setNomineeName] = useState("");
  const [nominee_relation, setNomineeRelation] = useState("");
  const [nominee_cnic, setNomineeCnic] = useState("");
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState("");
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState("");
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState("");
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState("");
  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState("");
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState("");
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState("");
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState("");
  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState("");
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState("");
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState("");
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState("");

  const [portfolioManagement, setPortfolioManagement] = useState("");

  const [cdc_IAS, setCDCIAS] = useState("");
  const [cdc_subAccount, setCDCSubAccount] = useState("");
  const [cdc_participantID, setCDCParticipantID] = useState("");

  const [allSalesCompany, setAllSalesCompany] = useState<any>([]);

  const getAllSalesCompany = async (code: string) => {
    try {
      const response = await getSalesCompanyByAMCCode(email, code);
      if (response.data.status == 200) {
        setAllSalesCompany(response.data.data);
      } else {
        setAllSalesCompany([]);
      }
    } catch (error) {
      setAllSalesCompany([]);
    }
  };

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("clientObj") || "");
    if (obj.balance_unit) {
      setTotalFunds(JSON.parse(obj.balance_unit));
    }
    setAccTitle(obj.account_title);
    setAccNo(obj.account_no);
    setType(obj.type);
    setFather(obj.father_spouse);
    if (obj.tax_exempt === "true") {
      setTax_exempt(true);
    } else {
      setTax_exempt(false);
    }
    if (obj.zakat_exempt === "true") {
      setZakat_exempt(true);
    } else {
      setZakat_exempt(false);
    }
    setPhone(obj.phone);
    setCnicExpiry(obj.cnic_expiry);
    setClientEmail(obj.email);
    setMainFolioNo(obj.main_folio_no);
    setBankName(obj.bank_name);
    getBranchByName(obj.bank_name);
    setBranchName(obj.branch_name);
    setAmcName(obj.amc_code);
    setBlnUpdate(obj.balance_unit);
    setFolioNo(obj.folio_no.replace("UNITHOLDER_", ""));
    setCnic(obj.cnic);
    setName(obj.name);
    setNtn(obj.ntn);
    // setClientEmail(obj.client_email);
    setMobile(obj.mobile);
    setGuardian_name(obj.guardian_name);
    setGuardian_relation(obj.guardian_relation);
    setGuardian_cnic(obj.guardian_cnic);
    setNomineeName(obj.nominee_name);
    setNomineeRelation(obj.nominee_relation);
    setNomineeCnic(obj.nominee_cnic);
    setJointHolderOneName(obj.joint_h_1_name);
    setJointHolderOneFather(obj.joint_h_1_father_spouse);
    setJointHolderOneCnic(obj.joint_h_1_cnic);
    setJointHolderTwoName(obj.joint_h_2_name);
    setJointHolderTwoFather(obj.joint_h_2_father_spouse);
    setJointHolderTwoCnic(obj.joint_h_2_cnic);
    setJointHolderThreeName(obj.joint_h_3_name);
    setJointHolderThreeFather(obj.joint_h_3_father_spouse);
    setJointHolderThreeCnic(obj.joint_h_3_cnic);
    setPortfolioManagement(obj.portfolio_management);
    // obj.reinvest === "true" ? setReinvestCheck(true) : setReinvestCheck(false);
    setReinvestCheck(obj.reinvest);
    setAddress(obj.address);
    setCity(obj.city);
    // setFiler(obj.filer);
    // obj.filer === 'true' ? setFiler(true) : setFiler(false);
    setProvince(obj.province);
    setTaxStatus(obj.tax_status);
    setJointHolderOneTaxStatus(obj.joint_h1_tax_status);
    setJointHolderTwoTaxStatus(obj.joint_h2_tax_status);
    setJointHolderThreeTaxStatus(obj.joint_h3_tax_status);
    setCDCIAS(obj.cdc_ias);
    setCDCSubAccount(obj.cdc_sub_account);
    setCDCParticipantID(obj.cdc_participant_id);
    setSalesInHouse(obj.in_house_sale == true ? true : false);
    setSalesCompany(obj.sales_company || "");
    setSalesPerson(obj.sales_person || "");
    // const fetchAmcFirst = async () => {
    //   try {
    //     const amcResponse = await getAmc(email);
    //     setAmcdata(amcResponse.data.data);
    //     amcResponse.data.data.map((amc: any) => {
    //       if (amc.name === obj.amc_name) {
    //         getfundByAmcCode(amc.amc_code);
    //       }
    //     });
    //   } catch (error) {}
    // };
    // fetchAmcFirst();
  }, []);
  const getfundByAmcCode = async (code: string) => {
    allFunds.length = 0;
    setAllFunds(allFunds);
    //get funds by amc for dropdown
    try {
      const response = await getFundByAmc(
        email,
        amcCode,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
      if (response.data.data.length > 0) {
        setFundLoading(false);
      } else {
        setFundLoading(true);
      }
    } catch (error) { }
  };
  //error getting hooks
  const [accTitleError, setAccTitleError] = useState("");
  const [accNoError, setAccNoError] = useState("");
  const [bankNameError, setBankNameError] = useState("");
  const [branchNameError, setBranchNameError] = useState("");
  const [amcNameError, setAmcError] = useState("");
  const [blnUpdateError, setBlnUpdateError] = useState("");
  const [folioNoError, setFolioNoError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");
  const [cnicExpiryError, setCnicExpiryError] = useState("");
  const [cnicError, setCnicError] = useState("");
  const [ntnError, setNtnError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [provinceError, setProvinceError] = useState("");
  const [nameError, setNameError] = useState("");
  const [Loading, setLoading] = useState(false);
  //for dropdown data
  const [amcdata, setAmcdata] = useState<any>([]);
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allTxData, setAllTxData] = useState<any>([]);
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [allCitiesData, setAllCitiesData] = useState<any>([]);
  const [totalFunds, setTotalFunds] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [salesCompany, setSalesCompany] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [salesInHouse, setSalesInHouse] = useState(false);
  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown
    try {
      const response = await getBranchByBankName(email, name, amcCode);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
    setBranchLoading(false);
  };
  React.useEffect(() => {
    const fetchAmc = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown
      try {
        const response = await getAllBanks(email, "", "", amc_code);
        setBankNameData(response.data.data);
      } catch (error) { }
      //get tx nature data for dropdown
      try {
        const response = await getCities();
        setAllCitiesData(response.data.cities);
      } catch (error) { }
    };
    fetchAmc();
    getAllSalesCompany(amcCode);
  }, []);

  const borderRadiusStyle = { borderRadius: 2 };

  //render dropdown for bank name data
  // const renderBankNameDropdown = () => {
  //   return bankNameData.map((item: any, index: string) => {
  //     return (
  //       <option key={index} value={item.bank_code.replace("BANK_", "")}>
  //         {item.bank_code.replace("BANK_", "")}-{item.bank_name}
  //       </option>
  //     );
  //   });
  // };

  const renderBankNameDropdown = () => {
    const options = bankNameData?.map((item: any) => {
      return {
        value: item.bank_code.replace("BANK_", ""),
        label: `${item.bank_code.replace("BANK_", "")}-${item.bank_name}`
      };
    }) || [];
    options.push({ label: "Other", value: "Other" });
    return options;
  };

  //render dropdown for branch name data
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_code}-{item.branch_name}
        </option>
      );
    });
  };
  // const renderFundsDropdown = () => {
  //   return allFunds.map((item: any, index: string) => {
  //     return (
  //       <option key={index} value={item.fund_name}>
  //         {item.fund_name}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return {
        value: item.fund_name,
        label: item.fund_name
      }
    });
  };

  const folioRegex = new RegExp("^[A-Z]{1,3}-[A-Z]{1,3}-[0-9]{1,8}$");
  // const renderCitiesDropdown = () => {
  //   return allCitiesData.map((item: any) => {
  //     return (
  //       <option key={item} value={item}>
  //         {item}
  //       </option>
  //     );
  //   });
  // };

  const renderCitiesDropdown = () => {
    return allCitiesData.map((item: any) => {
      return {
        value: item,
        label: item
      }
    });
  };

  const renderSalesCompany = () => {
    return allSalesCompany?.map((item: any) => {
      return {
        value: item?.company_code,
        label: item?.company_name
      }
    });
  };

  const renderSalesPerson = () => {
    return allSalesCompany
      .find((item) => item?.company_code == salesCompany)
      ?.persons?.map((item: any) => {
        return {
          value: item?.id,
          label: `${item.name}(${item.email})`
        }
      });
  };

  //processing
  const validate = () => {
    let accTitleErr,
      nameErr,
      accNoErr,
      banknameErr,
      branchNameErr,
      amcErr,
      cnicExpErr,
      phoneErr = "";
    let folioErr,
      cnicErr,
      ntnErr,
      mobileErr,
      addressErr,
      cityErr = "",
      provinceErr = "";
    accTitle.trim() === "" ? (accTitleErr = "Required") : (accTitleErr = "");
    accNo.trim() === "" ? (accNoErr = "Required") : (accNoErr = "");
    bankname.trim() === "" ? (banknameErr = "Required") : (banknameErr = "");
    branchName.trim() === ""
      ? (branchNameErr = "Required")
      : (branchNameErr = "");
    amcName.trim() === "" ? (amcErr = "Required") : (amcErr = "");
    folioNo.trim() === "" ? (folioErr = "Required") : (folioErr = "");

    if (
      accTitleErr ||
      cnicExpErr ||
      phoneErr ||
      accNoErr ||
      nameErr ||
      branchNameErr ||
      amcErr ||
      folioErr ||
      cnicErr ||
      ntnErr ||
      mobileErr ||
      addressErr ||
      cityErr ||
      provinceErr
    ) {
      setAccTitleError(accTitleErr);
      setPhoneError(phoneErr);
      setCnicError(cnicExpErr);
      setAccNoError(accNoErr);
      setBankNameError(banknameErr);
      setBranchNameError(branchNameErr);
      setAmcError(amcErr);
      setFolioNoError(folioErr);
      setCnicError(cnicErr);
      setNtnError(ntnErr);
      setMobileError(mobileErr);
      setAddressError(addressErr);
      setCityError(cityErr);
      setProvinceError(provinceErr);
      return false;
    } else {
      return true;
    }
  };
  const addClients = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await editUnitHolder(
          email,
          folioNo,
          accNo,
          bankname,
          blnUpdate,
          cnic,
          mobile,
          city,
          accTitle,
          branchName,
          amcName,
          ntn,
          address,
          name,
          mainFolioNo,
          phone,
          cnicExpiry,
          JSON.stringify(reinvestCheck),
          clientEmail,
          father,
          tax_exempt.toString(),
          zakat_exempt.toString(),
          type,
          guardian_name,
          guardian_relation,
          guardian_cnic,
          nominee_name,
          nominee_relation,
          nominee_cnic,
          joint_h_1_name,
          joint_h_1_father_spouse,
          joint_h_1_cnic,
          joint_h_2_name,
          joint_h_2_father_spouse,
          joint_h_2_cnic,
          joint_h_3_name,
          joint_h_3_father_spouse,
          joint_h_3_cnic,
          portfolioManagement,
          // JSON.stringify(filer),
          province,
          taxStatus,
          joint_h_1_taxStatus,
          joint_h_2_taxStatus,
          joint_h_2_taxStatus,
          cdc_IAS,
          cdc_subAccount,
          cdc_participantID,
          salesInHouse,
          salesCompany,
          salesPerson
        );
        if (response.data.status == 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/unit-holders`);
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response.data.message?.toString());
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response) {
          toast.error(error.response.data.message?.toString());
        } else {
          toast.error("Request Failed");
        }
      }
    }
  };
  //render Fund table data
  const renderFundDataInTable = () => {
    return totalFunds.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.fund_name ? items.fund_name : items.fund_code}</td>
          <td>
            {items.fund_unit.toString().split(".")[1]
              ? numberWithCommas(items.fund_unit.toString().split(".")[0]) +
              "." +
              items.fund_unit.toString().split(".")[1]
              : numberWithCommas(items.fund_unit)}
          </td>
        </tr>
      );
    });
  };

  const selectedCity = React.useMemo(() => {
    return city
      ? {
        value: city,
        label: city
      }
      :
      null;
  }, [city]);

  const selectedInvestorType = React.useMemo(() => {
    return type
      ? {
        value: type,
        label: type
      }
      :
      null;
  }, [type]);

  const selectedPortfolio = React.useMemo(() => {
    const selectedFund = portfolioArr && portfolioArr?.find(item => item?.value === portfolioManagement);
    return selectedFund
      ? {
        value: selectedFund.value,
        label: selectedFund.label,
      }
      :
      null;
  }, [portfolioArr, portfolioManagement]);

  const selectedTaxStatus = React.useMemo(() => {
    return taxStatus
      ? {
        value: taxStatus,
        label: taxStatus
      }
      :
      null;
  }, [taxStatus]);

  const selectedTerritory = React.useMemo(() => {
    return province
      ? {
        value: province,
        label: province
      }
      :
      null;
  }, [province]);

  const selectedBankName = React.useMemo(() => {
    const selectedFund = bankNameData && bankNameData?.find(item => item.bank_code.replace("BANK_", "") === bankname);
    if (selectedFund) {
      return {
        value: selectedFund.bank_code.replace("BANK_", ""),
        label: `${selectedFund.bank_name} (${selectedFund.bank_code.replace("BANK_", "")})`
      };
    } else if (bankname === "Other") {
      return { label: "Other", value: "Other" };
    } else {
      return null;
    }
  }, [bankNameData, bankname]);

  const selectedIncomeTaxStatus = React.useMemo(() => {
    return joint_h_1_taxStatus
      ? {
        value: joint_h_1_taxStatus,
        label: joint_h_1_taxStatus
      }
      :
      null;
  }, [joint_h_1_taxStatus]);

  const selectedIncomeTax2Status = React.useMemo(() => {
    return joint_h_2_taxStatus
      ? {
        value: joint_h_2_taxStatus,
        label: joint_h_2_taxStatus
      }
      :
      null;
  }, [joint_h_2_taxStatus]);

  const selectedIncomeTax3Status = React.useMemo(() => {
    return joint_h_3_taxStatus
      ? {
        value: joint_h_3_taxStatus,
        label: joint_h_3_taxStatus
      }
      :
      null;
  }, [joint_h_3_taxStatus]);

  const selectedSalesCompany = React.useMemo(() => {
    const selectedFund = allSalesCompany && allSalesCompany?.find(item => item?.company_code === salesCompany);
    return selectedFund
      ? {
        value: selectedFund.company_code,
        label: selectedFund.company_name,
      }
      :
      null;
  }, [allSalesCompany, salesCompany]);

  const selectedSalesPerson = React.useMemo(() => {
    const selectedFund = allSalesCompany && allSalesCompany?.find(item => item?.id === salesPerson);
    return selectedFund
      ? {
        value: selectedFund.id,
        label: `${selectedFund.name}(${selectedFund.email})`
      }
      :
      null;
  }, [allSalesCompany, salesPerson]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };


  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header />	 */}
              <div className="input-holder">
                <h4 className="card-title">Edit - Unit Holder</h4>
                <Link
                  to="/admin/unit-holders"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Registration</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Folio No</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${folioNoError ? "required-border" : ""
                              }`}
                            value={folioNo}
                            readOnly
                            onChange={(e) => {
                              setFolioNo(e.target.value.toUpperCase());
                              setFolioNoError("");
                            }}
                          />
                          {/* {folioNoError ? <p className="error-labels error-message2">{folioNoError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>Investor Type</label>
                            <div>
                              <Select
                                value={selectedInvestorType}
                                label={"Select Type"}
                                isDisabled
                                onChange={(e) => {
                                  setType(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderTypeDropdown}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100`}
                                value={type}
                                onChange={(e) => {
                                  setType(e.target.value);
                                }}
                                disabled
                              >
                                <option value="" defaultChecked hidden>
                                  Select Type
                                </option>
                                <option value="Individual">Individual</option>
                                <option value="Corporate">Corporate</option>
                              </select> */}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label>Portfolio Management</label>
                            <div>
                              <Select
                                value={selectedPortfolio}
                                label={"Select Fund"}
                                onChange={(e) => {
                                  setPortfolioManagement(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={portfolioArr}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100`}
                                value={portfolioManagement}
                                onChange={(e) => {
                                  setPortfolioManagement(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Fund
                                </option>
                                <option value="fund">Fund</option>
                                <option value="sma">SMA</option>
                              </select> */}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group d-none">
                        <label>Main Folio No (Optional)</label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={mainFolioNo}
                            onChange={(e) => {
                              setMainFolioNo(e.target.value.toUpperCase());
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tax Status</label>
                        <Select
                          value={selectedTaxStatus}
                          label={"Select Tax Type"}
                          onChange={(e) => {
                            setTaxStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={taxStatusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control"
                          value={taxStatus}
                          onChange={(e) => {
                            setTaxStatus(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {" "}
                            Select Tax Type
                          </option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                          <option value="Exempted">Exempted</option>
                        </select> */}
                      </div>

                      <div className="d-flex justify-content-between mt-4 pb-3">
                        {/* <div className="form-group d-flex">		
                      <ToggleButton
                        value={tax_exempt}
                        thumbStyle={borderRadiusStyle}
                        trackStyle={borderRadiusStyle}
                        onToggle={() => {
                          if (tax_exempt) {
                            setTax_exempt(false);
                          } else {
                            setTax_exempt(true);
                          }
                        }}
                      />
                      <label className="mt-2 ml-2">Tax Exempt</label>
                  </div> */}

                        <div className="form-group d-flex">
                          <ToggleButton
                            inactiveLabel=""
                            activeLabel=""
                            value={zakat_exempt}
                            thumbStyle={borderRadiusStyle}
                            trackStyle={borderRadiusStyle}
                            onToggle={() => {
                              if (zakat_exempt) {
                                setZakat_exempt(false);
                              } else {
                                setZakat_exempt(true);
                              }
                            }}
                          />
                          <label className="ml-2 mt-2">Zakat Exempt</label>
                        </div>

                        <div className="form-group d-flex">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={reinvestCheck}
                                onChange={(e) => {
                                  setReinvestCheck(!reinvestCheck);
                                }}
                              />
                              <span className="form-check-sign" />
                              Fund Dividend Reinvest
                            </Label>
                          </FormGroup>
                        </div>
                      </div>

                      {JSON.parse(sessionStorage.getItem("clientObj") || "{}")
                        ?.investor_id && (
                          <button
                            className="t-3 ml-auto btn btn-primary btn-sm"
                            onClick={async () => {
                              try {
                                const response = await getInvestorByID(
                                  email,
                                  amcName,
                                  JSON.parse(
                                    sessionStorage.getItem("clientObj") || "{}"
                                  )?.investor_id
                                );
                                if (response.data.status == 200) {
                                  sessionStorage.setItem(
                                    "investorObj",
                                    JSON.stringify(response.data.data)
                                  );
                                  history.replace(
                                    "/admin/view-potential-investors"
                                  );
                                } else {
                                  toast.error(response.data.message);
                                }
                              } catch (error: any) {
                                toast.error(
                                  error.response.data.message?.toString() ||
                                  error?.message ||
                                  "Request Failed"
                                );
                              }
                            }}
                          >
                            <i className="fa fa-eye mr-2"></i> View Potential
                            Investor
                          </button>
                        )}
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Personal Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Client Name</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={name}
                            readOnly
                            onChange={(e) => {
                              setName(e.target.value);
                              setNameError("");
                            }}
                          />
                          {/* {nameError ? <p className="error-labels error-message">{nameError}</p> : ''} */}
                        </div>
                      </div>
                      {type === "Individual" && (
                        <div className="form-group">
                          <label>Father/Spouse Name</label>
                          <div className="">
                            <input
                              type="text"
                              className={"form-control  w-100"}
                              value={father}
                              onChange={(e) => {
                                setFather(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {type === "Individual" && (
                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>CNIC</label>
                              <div>
                                {/* <input	
                          type="text"	
                          className={`form-control  w-100 ${	
                            cnicError ? 'required-border' : ''	
                          }`}	
                          value={cnic}	
                          onChange={(e) => {	
                            setCnic(e.target.value);	
                            setCnicError('');	
                          }}	
                        />	 */}

                                <InputMask
                                  className={`form-control  w-100 ${cnicError ? "required-border" : ""
                                    }`}
                                  mask="99999-9999999-9"
                                  value={cnic}
                                  readOnly
                                  onChange={(e) => {
                                    setCnic(e.target.value);
                                    setCnicError("");
                                  }}
                                ></InputMask>
                                {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}
                              </div>
                            </div>
                          </Col>

                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div>
                                <input
                                  style={{
                                    colorScheme: theme == "light" ? "light" : "dark",
                                  }}
                                  type="date"
                                  className={`form-control  w-100 ${cnicExpiryError ? "required-border" : ""
                                    }`}
                                  value={moment(cnicExpiry).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => {
                                    setCnicExpiry(e.target.value);
                                    setCnicExpiryError("");
                                  }}
                                />
                                {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {type === "Corporate" && (
                        <div className="form-group  ">
                          <label>NTN</label>
                          <div>
                            <input
                              type="number"
                              className={`form-control  w-100 ${ntnError ? "required-border" : ""
                                }`}
                              value={ntn}
                              readOnly
                              onChange={(e) => {
                                setNtn(e.target.value);
                                setNtnError("");
                              }}
                            />
                            {/* {ntnError ? <p className="error-labels error-message">{ntnError}</p> : ''} */}
                          </div>
                        </div>
                      )}

                      {/* <div className="form-group ">		
                        <label>Filer</label>		
                        <div  >	 */}
                      {/* <FormGroup check>
                          <Label check>	
                          <input		
                            type="checkbox"		
                            // className={`form-control  w-100 ${		
                            //   ntnError ? 'required-border' : ''		
                            // }`}		
                            checked={filer}		
                            onChange={updateFiler}		
                          />
                           <span className="form-check-sign" />
                           Income Tax Return Filer
                          </Label>
                          </FormGroup>		 */}
                      {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}
                      {/* </div>		
                      </div> */}
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Contact</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Email</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${clientEmailError ? "required-border" : ""
                              }`}
                            value={clientEmail}
                            onChange={(e) => {
                              setClientEmail(e.target.value);
                              setClientEmailError("");
                            }}
                          />
                          {/* {clientEmailError ? <p className="error-labels error-message2">{clientEmailError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Mobile</label>
                            <div>
                              {/* <input	
                        type="number"	
                        className={`form-control  w-100 ${	
                          mobileError ? 'required-border' : ''	
                        }`}	
                        value={mobile}	
                        onChange={(e) => {	
                          setMobile(e.target.value);	
                          setMobileError('');	
                        }}	
                      />	 */}
                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${mobileError ? "required-border" : ""
                                  }`}
                                country={"pk"}
                                placeholder="+92 123-1234567"
                                value={mobile}
                                onChange={(e) => {
                                  setMobile(e);
                                  setMobileError("");
                                }}
                              />

                              {/* {mobileError ? <p className="error-labels error-message">{mobileError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="form-group  ">
                            <label>Phone</label>
                            <div>
                              {/* <input	
                        type="number"	
                        className={`form-control  w-100 ${	
                          phoneError ? 'required-border' : ''	
                        }`}	
                        value={phone}	
                        onChange={(e) => {	
                          setPhone(e.target.value);	
                          setPhoneError('');	
                        }}	
                      />	 */}
                              <PhoneInput
                                specialLabel=""
                                inputClass={`form-control  w-100 ${phoneError ? "required-border" : ""
                                  }`}
                                country={"pk"}
                                placeholder="+92 123-1234567"
                                value={phone}
                                onChange={(e) => {
                                  setPhone(e);
                                  setPhoneError("");
                                }}
                              />
                              {/* {phoneError ? <p className="error-labels error-message2">{phoneError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group  ">
                        <label>Address</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${addressError ? "required-border" : ""
                              }`}
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                              setAddressError("");
                            }}
                          />
                          {/* {addressError ? <p className="error-labels error-message">{addressError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col>
                          <div className="form-group  ">
                            <label>City</label>
                            <div>
                              <Select
                                className={`${cityError ? "required-border" : ""
                                  }`}
                                value={selectedCity}
                                label={"Select City"}
                                onChange={(e) => {
                                  setCityError('');
                                  setCity(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderCitiesDropdown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100 ${cityError ? "required-border" : ""
                                  }`}
                                value={city}
                                onChange={(e) => {
                                  setCityError("");
                                  setCity(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select City
                                </option>
                                {renderCitiesDropdown()}
                              </select> */}
                              {/* {cityError ? <p className="error-labels error-message2">{cityError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label>Territories</label>
                            <div>
                              <Select
                                className={`${provinceError ? "required-border" : ""
                                  }`}
                                value={selectedTerritory}
                                label={"Select Territory"}
                                onChange={(e) => {
                                  setProvince(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderCitiesDropdown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100 ${provinceError ? "required-border" : ""
                                  }`}
                                value={province}
                                onChange={(e) => {
                                  setProvince(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  Select
                                </option>
                                <option value="Islamabad">Islamabad</option>
                                <option value="Punjab">Punjab</option>
                                <option value="Khyber Pakhtunkhwa">
                                  Khyber Pakhtunkhwa
                                </option>
                                <option value="Balochistan">Balochistan</option>
                                <option value="Sindh">Sindh</option>
                                <option value="Gilgit Baltistan">
                                  Gilgit Baltistan
                                </option>
                                <option value="Azad Jammu and Kashmir">
                                  Azad Jammu and Kashmir
                                </option>
                              </select> */}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Bank Account Detail</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Account Title</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${accTitleError ? "required-border" : ""
                              }`}
                            value={accTitle}
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setAccTitle(e.target.value);
                                setAccTitleError("");
                              }
                            }}
                          />
                          {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Account No</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${accNoError ? "required-border" : ""
                              }`}
                            value={accNo}
                            onChange={(e) => {
                              if (
                                e.target.value.match(
                                  accountTitle_AccountNumber_Regex
                                ) ||
                                e.target.value == ""
                              ) {
                                setAccNo(e.target.value);
                                setAccNoError("");
                              }
                            }}
                          />
                          {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Bank Name</label>
                            <div>
                              <Select
                                className={`${bankNameError ? "required-border" : ""
                                  }`}
                                value={selectedBankName}
                                label={"Select Bank"}
                                onChange={(e) => {
                                  setBankNameError("");
                                  setBankName(e?.value);
                                  getBranchByName(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderBankNameDropdown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100 ${bankNameError ? "required-border" : ""
                                  }`}
                                value={bankname}
                                onChange={(e) => {
                                  setBankNameError("");
                                  setBankName(e.target.value);
                                  getBranchByName(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select Bank
                                </option>
                                {renderBankNameDropdown()}
                                <option value="Other">Other</option>
                              </select> */}
                              {/* {bankNameError ? <p className="error-labels error-message2">{bankNameError}</p> : ''} */}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Branch Name</label>

                            <input
                              type="text"
                              className={`form-control  w-100 ${bankNameError ? "required-border" : ""
                                }`}
                              value={branchName}
                              onChange={(e) => {
                                setBankNameError("");
                                setBranchName(e.target.value);
                              }}
                            />

                            {/* <ReactTooltip	
                      textColor="white"	
                      backgroundColor="black"	
                      multiline={true}	
                      effect="float"	
                    />	
                    <div	
                      className=" "	
                      id="x"	
                      data-tip={`First Select Bank`}	
                    >	
                      {branchLoading ? (	
                         <div className="form-group">	
                          <div className="ml-2 form-control" >Branch Loading	
                           <i className="fa fa-spinner fa-spin fa-1x ml-3" aria-hidden="true"></i> 	
                          </div> 	
                         </div>	
                      ) : (	
                        <select	
                          className={`form-control  w-100 ${	
                            branchNameError ? 'required-border' : ''	
                          }`}	
                          value={branchName}	
                          onChange={(e) => {	
                            setBankNameError('');	
                            setBranchName(e.target.value);	
                          }}	
                        >	
                          <option value="" defaultChecked hidden>	
                            {branchCheckFlag	
                              ? 'No Branch Found'	
                              : 'Select Branch Name'}	
                          </option>	
                          {renderBranchsDropdown()}	
                        </select>	
                      )}	
                     </div>	 */}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {/*add new cards  */}
                {type === "Individual" && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Guardian Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <label>Guardian Name </label>
                          <div>
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              value={guardian_name}
                              onChange={(e) => {
                                setGuardian_name(e.target.value);
                                // setNameError('');
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian Relation </label>
                          <div>
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              value={guardian_relation}
                              onChange={(e) => {
                                setGuardian_relation(e.target.value);
                                // setNameError('');
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Guardian CNIC </label>
                          <div>
                            {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={guardian_cnic}		
                        onChange={(e) => {		
                          setGuardian_cnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                            <InputMask
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              mask="99999-9999999-9"
                              value={guardian_cnic}
                              onChange={(e) => {
                                setGuardian_cnic(e.target.value);
                              }}
                            ></InputMask>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
                {type === "Individual" && (
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Nominee Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="form-group">
                          <label>Nominee Name </label>
                          <div>
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              value={nominee_name}
                              onChange={(e) => {
                                setNomineeName(e.target.value);
                                setNameError("");
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee Relation </label>
                          <div>
                            <input
                              type="text"
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              value={nominee_relation}
                              onChange={(e) => {
                                setNomineeRelation(e.target.value);
                                // setNameError('');
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Nominee CNIC </label>
                          <div>
                            {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={nominee_cnic}		
                        onChange={(e) => {		
                          setNomineeCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}

                            <InputMask
                              className={`form-control  w-100 ${nameError ? "required-border" : ""
                                }`}
                              mask="99999-9999999-9"
                              value={nominee_cnic}
                              onChange={(e) => {
                                setNomineeCnic(e.target.value);
                              }}
                            ></InputMask>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Joint Holder 1</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label> Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_1_name}
                            onChange={(e) => {
                              setJointHolderOneName(e.target.value);
                              // setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_1_father_spouse}
                            onChange={(e) => {
                              setJointHolderOneFather(e.target.value);
                              setNameError("");
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CNIC </label>
                        <div>
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_1_cnic}		
                        onChange={(e) => {		
                          setJointHolderOneCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_1_cnic}
                            onChange={(e) => {
                              setJointHolderOneCnic(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tax Status</label>
                        <Select
                          value={selectedIncomeTaxStatus}
                          label={"Select Tax Type"}
                          onChange={(e) => {
                            setJointHolderOneTaxStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={incomeTaxStatusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control"
                          value={joint_h_1_taxStatus}
                          onChange={(e) => {
                            setJointHolderOneTaxStatus(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {" "}
                            Select Tax Type
                          </option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Joint Holder 2</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_2_name}
                            onChange={(e) => {
                              setJointHolderTwoName(e.target.value);
                              // setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_2_father_spouse}
                            onChange={(e) => {
                              setJointHolderTwoFather(e.target.value);
                              // setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CNIC </label>
                        <div>
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_2_cnic}		
                        onChange={(e) => {		
                          setJointHolderTwoCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}
                          <InputMask
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_2_cnic}
                            onChange={(e) => {
                              setJointHolderTwoCnic(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tax Status</label>
                        <Select
                          value={selectedIncomeTax2Status}
                          label={"Select Tax Type"}
                          onChange={(e) => {
                            setJointHolderTwoTaxStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={incomeTaxStatusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control"
                          value={joint_h_2_taxStatus}
                          onChange={(e) => {
                            setJointHolderTwoTaxStatus(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {" "}
                            Select Tax Type
                          </option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Joint Holder 3</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_3_name}
                            onChange={(e) => {
                              setJointHolderThreeName(e.target.value);
                              setNameError("");
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Father/Spouse Name </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            value={joint_h_3_father_spouse}
                            onChange={(e) => {
                              setJointHolderThreeFather(e.target.value);
                              // setNameError('');
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CNIC </label>
                        <div>
                          {/* <input		
                        type="number"		
                        className={`form-control  w-100 ${		
                          nameError ? 'required-border' : ''		
                        }`}		
                        value={joint_h_3_cnic}		
                        onChange={(e) => {		
                          setJointHolderThreeCnic(e.target.value);		
                          // setNameError('');		
                        }}		
                      />		 	 */}

                          <InputMask
                            className={`form-control  w-100 ${nameError ? "required-border" : ""
                              }`}
                            mask="99999-9999999-9"
                            value={joint_h_3_cnic}
                            onChange={(e) => {
                              setJointHolderThreeCnic(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Tax Status</label>
                        <Select
                          value={selectedIncomeTax3Status}
                          label={"Select Tax Type"}
                          onChange={(e) => {
                            setJointHolderThreeTaxStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={incomeTaxStatusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control"
                          value={joint_h_3_taxStatus}
                          onChange={(e) => {
                            setJointHolderThreeTaxStatus(e.target.value);
                          }}
                        >
                          <option value="" hidden>
                            {" "}
                            Select Tax Type
                          </option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">CDC</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Investment Account Service</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_IAS}
                            onChange={(e) => {
                              setCDCIAS(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label> CDC Subaccount </label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_subAccount}
                            onChange={(e) => {
                              setCDCSubAccount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>CDC participant ID</label>
                        <div>
                          <input
                            type="text"
                            className={`form-control  w-100 `}
                            value={cdc_participantID}
                            onChange={(e) => {
                              setCDCParticipantID(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Balance Units</CardTitle>
                    </CardHeader>
                    <CardBody>
                      {totalFunds.length > 0 && (
                        <div className="input-size-pos w-100 ml-auto mb-3 mx-3">
                          <table className="table ">
                            <thead>
                              <tr>
                                <th>Fund Name</th>
                                <th>Fund Unit</th>
                              </tr>
                            </thead>
                            <tbody>{renderFundDataInTable()}</tbody>
                          </table>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Sales Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={salesInHouse}
                              onChange={(e) => {
                                setSalesInHouse(!salesInHouse);
                              }}
                            />
                            <span className="form-check-sign" />
                            In house sale
                          </Label>
                        </FormGroup>
                      </div>

                      <div className="form-group">
                        <label>Sales Company</label>

                        <div>
                          <Select
                            value={selectedSalesCompany}
                            label={"Select Sales Company"}
                            onChange={(e) => {
                              setSalesCompany(e?.value);
                              setSalesInHouse(
                                allSalesCompany?.find(
                                  (item) => item?.company_code === e?.value
                                )?.in_house == true
                                  ? true
                                  : false
                              );
                              setSalesPerson("");
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderSalesCompany()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            value={salesCompany}
                            onChange={(e) => {
                              setSalesCompany(e.target.value);
                              setSalesInHouse(
                                allSalesCompany.find(
                                  (item) => item.company_code === e.target.value
                                ).in_house == true
                                  ? true
                                  : false
                              );
                              setSalesPerson("");
                            }}
                          >
                            <option value="" hidden>
                              {" "}
                              Select Sales Company
                            </option>
                            {allSalesCompany?.map((item, index) => {
                              return (
                                <option
                                  key={item.company_code}
                                  value={item.company_code}
                                >
                                  {item.company_name}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Sales Person</label>
                        <div>
                          <Select
                            value={selectedSalesPerson}
                            label={"Select Sales Person"}
                            onChange={(e) => {
                              setSalesPerson(e?.value)
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderSalesPerson()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            value={salesPerson}
                            onChange={(e) => {
                              setSalesPerson(e.target.value);
                            }}
                          >
                            <option value="" hidden>
                              {" "}
                              Select Sales Person
                            </option>
                            {allSalesCompany
                              .find((item) => item.company_code == salesCompany)
                              ?.persons?.map((item, index) => {
                                return (
                                  <option key={item.email} value={item.id}>
                                    {`${item.name}(${item.email})`}
                                  </option>
                                );
                              })}
                          </select> */}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <button
                className="btn btn-primary mr-2"
                onClick={addClients}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Update</span>
                )}
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  history.replace("/admin/unit-holders");
                }}
              >
                Cancel
              </button>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditClients;

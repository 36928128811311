import { ActionTypes } from "../contants/action-types";

const initialState = {
    stringValue: ""
};

export const stringReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_STRING_VALUE:
            return { ...state, stringValue: payload };
        default:
            return state;
    }
};
import React, { useState } from "react";
// import Header from './../components/Header';
import { Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { getAllPendingTransactions } from "./../stores/services/transactions.service";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsTable } from "react-icons/bs";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { getChecklist } from "./../stores/services/checklist.service";
import CheckListItem from "./../components/checklist-item";
import { updateBulkTransactionStatus } from "./../stores/services/transactions.service";
import SignatoryA from "./signatory-a";
import { useSelector } from "react-redux";

const ComplianceSignatoryTransactions = (props: any) => {
  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  let txnTypes = useSelector((state: any) => state.txnTypes);
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  let [transactions, setTransactions] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState("");
  let [status, setStatus] = useState("");
  let [activePage, setActivePage] = useState("");
  let [paginationArr, setPaginationArr] = useState([""]);
  // let [selectAll, setSelectAll]=useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);
  let getUrlPath = (window.location.pathname.split("/").pop() || "").toString();

  const [resLoading, setResLoading] = useState(false);

  /// popup view
  const [viewTxnModal, setViewTxnModal] = useState(false);
  const [propsState, setPropsState] = useState({
    transaction: "",
    headLabel: headLabel,
  });

  React.useEffect(() => {
    // setPropsState({
    //   transaction: "",
    //   headLabel: headLabel
    // });
    if (propsState.transaction == "Done") {
      setViewTxnModal(false);
    }
  }, [propsState]);

  async function getTransactions(resetData: string = "") {
    setResLoading(true);
    setHeadLabel(getUrlPath.toUpperCase());
    await getAllPendingTransactions(
      sessionStorage.getItem("email") || "",
      // getUrlPath.toUpperCase() === 'AUTHORIZER'
      //   ? 'CREATED,AUTHORIZER-A'
      //   : 'CREATED,AUTHORIZER-B',
      getUrlPath.toUpperCase(),
      searchValue,
      "",
      activePage,
      "",
      fund_code?.length > 0 ? fund_code : "",
      amc_code
    )
      .then((response) => {
        if (response.data.data.length > 0) {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10
            // &&
            // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          // setTransactions(response.data.data);
          setTransactions(
            resetData == "true"
              ? [...response.data.data]
              : [...transactions, ...response.data.data]
          );
        }
        setResLoading(false);
      })
      .catch((err) => {
        setResLoading(false);
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      });
    setResLoading(false);
  }

  React.useEffect(() => {
    activePage ? getTransactions("") : getTransactions("true");
  }, [activePage]);

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getTransactions("true");
  }, [fund_code]);

  const searchFilters = async (
    statusVar = undefined as any,
    searchVar = undefined as any,
    pageNumber: string = ""
  ) => {
    let flag = true;
    if (searchVar === "") {
      flag = false;
    }
    return new Promise(async (resolve, reject) => {
      setResLoading(true);
      setSearchLoading(flag);
      await getAllPendingTransactions(
        sessionStorage.getItem("email") || "",
        // getUrlPath.toUpperCase() === 'AUTHORIZER-A'
        //   ? 'CREATED,AUTHORIZER-A'
        //   : 'CREATED,AUTHORIZER-B',
        getUrlPath.toUpperCase(),
        // 'CREATED',
        searchVar === undefined ? searchValue : searchVar,
        "",
        "",
        "",
        fund_code?.length > 0 ? fund_code : "",
        amc_code
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            let pageArr = paginationArr;
            if (response.data.page_info) {
              const existingPageIndex = paginationArr.findIndex(
                (x) => x === response.data.page_info.next_page_number
              );
              if (
                existingPageIndex === -1 &&
                response.data.data.length === 10
                // &&
                // 10 * pageArr.length !==
                // parseInt(response.data.page_info.total_count)
              ) {
                pageArr.push(response.data.page_info.next_page_number);
              }
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          resolve("Success");
          setSearchLoading(false);
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!");
          }
          reject("Error");
        });
      setResLoading(false);
    });
  };

  const clearFilters = async (
    statusVar = undefined as any,
    searchVar = undefined as any,
    pageNumber: string = ""
  ) => {
    return new Promise(async (resolve, reject) => {
      setResLoading(true);
      setClearLoading(true);
      await getAllPendingTransactions(
        sessionStorage.getItem("email") || "",
        // getUrlPath.toUpperCase() === 'AUTHORIZER-A'
        //   ? 'CREATED,AUTHORIZER-A'
        //   : 'CREATED,AUTHORIZER-B',
        getUrlPath.toUpperCase(),
        searchVar === undefined ? searchValue : searchVar,
        "",
        "",
        "",
        fund_code?.length > 0 ? fund_code : "",
        amc_code
      )
        .then((response) => {
          // let pageArr = [''];
          if (response.data.data.length > 0) {
            let pageArr = paginationArr;
            if (response.data.page_info) {
              const existingPageIndex = paginationArr.findIndex(
                (x) => x === response.data.page_info.next_page_number
              );
              if (
                existingPageIndex === -1 &&
                response.data.data.length === 10
                // &&
                // 10 * pageArr.length !==
                // parseInt(response.data.page_info.total_count)
              ) {
                pageArr.push(response.data.page_info.next_page_number);
              }
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          resolve("Success");
          setClearLoading(false);
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!");
          }
          reject("Error");
        });
      setResLoading(false);
    });
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  let [bulkProcessingData, setBulkProcessingData] = useState<any>([]);
  const [Loading, setLoading] = useState(false);
  const [checklistSetPopup, setCheckListSetPopup] = useState(false);
  const [checklistData, setCheckListData] = useState<any>([]);
  const role_name = sessionStorage.getItem("role") || "";
  const email = sessionStorage.getItem("email") || "";
  const userDepartment =
    JSON.parse(sessionStorage.getItem("loggedInUser") || "{}")?.department ||
    "";
  const [reasonText, setReasonText] = useState("");
  const [reasonTextError, setReasonTextError] = useState("");
  const [rejectLoading, setRejectLoading] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [changeCheck, setChangeCheck] = useState("");
  let [count, setCount] = useState(0);

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const addTxnForBulk = (txnObj) => {
    count = count + 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id === txnObj.txn_id;
    });
    if (check.length === 0) {
      let temp = {
        id: txnObj.txn_id || "",
        status: txnObj.txn_status || "",
        made_by: txnObj.made_by || "",
      };
      bulkProcessingData.push(temp);
      setBulkProcessingData(bulkProcessingData);
      setChangeCheck(`${txnObj.txn_id}-${count}`);
    }
    setCount(count);
  };

  const removeTxnFromBulk = (txnObj) => {
    count = count - 1;
    const check = bulkProcessingData.filter((item) => {
      return item.id.replace("TXN_", "") !== txnObj.txn_id.replace("TXN_", "");
    });
    bulkProcessingData = check;
    setBulkProcessingData(bulkProcessingData);
    setCount(count);
    setChangeCheck(`${txnObj.txn_id}-${count}`);
  };
  React.useEffect(() => {
    const getAllChecklists = async () => {
      try {
        const response = await getChecklist(email, role_name, "", "", amc_code);
        setCheckListData(
          response.data.data.sort((a, b) => a.serial_no - b.serial_no)
        );
      } catch (err: any) {
        // toast.error(error.response.data.message[0]);
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Request Failed!");
        }
      }
    };
    getAllChecklists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormData = (obj, id) => {
    let temp = checklistData;
    temp[id] = obj;
    setCheckListData(temp);
  };

  const checkBoolean = (txnId) => {
    let tx = false;
    bulkProcessingData.map((item, index) => {
      if (item.id.replace("TXN_", "") === txnId) {
        tx = true;
      }
    });
    return tx;
  };

  const validate = () => {
    let statusErr = "";
    status.trim() === "" ? (statusErr = "Required") : (statusErr = "");
    if (statusErr) {
      setStatusError(statusErr);
      return false;
    } else {
      return true;
    }
  };

  const updateBulkTxStatus = async () => {
    const isValid = validate();
    if (isValid) {
      if (status === "accept") {
        status = "Accept";
        setReasonTextError("");
        setLoading(true);
        let data = JSON.stringify(bulkProcessingData);
        try {
          const response = await updateBulkTransactionStatus(
            email,
            role_name,
            status,
            reasonText,
            checklistData,
            data
          );
          if (response.data.message === "Transactions updated successfully") {
            setCheckListSetPopup(false);
            setTimeout(() => {
              searchFilters("", "").then(() => {
                setSearchValue("");
                setActivePage("");
              });
            }, 3000);

            bulkProcessingData.length = 0;
            setBulkProcessingData(bulkProcessingData);
            setStatus("");
            setReasonText("");
            toast.success(response.data.message);
          } else {
            setLoading(false);
            toast.error(response.data.message);
          }
        } catch (error: any) {
          setLoading(false);
          if (error.response !== undefined) {
            toast.error(error.response.data.message || "Error is Processing");
          } else {
            toast.error("Request Failed!");
          }
        }
        setCount(0);
        // setLoading(false);
      } else {
        //toast.success("reject")
        setStatusError("");
        if (reasonText.trim() === "") {
          setReasonTextError("Required");
        } else {
          setReasonTextError("");
          setLoading(true);
          let data = JSON.stringify(bulkProcessingData);
          try {
            const response = await updateBulkTransactionStatus(
              email,
              role_name,
              status,
              reasonText,
              checklistData,
              data
            );
            if (response.data.message === "Transactions updated successfully") {
              setCheckListSetPopup(false);
              setTimeout(() => {
                searchFilters("", "").then(() => {
                  setSearchValue("");
                  setActivePage("");
                });
              }, 3000);

              bulkProcessingData.length = 0;
              setBulkProcessingData(bulkProcessingData);
              setStatus("");
              setReasonText("");
              toast.success(response.data.message);
            } else {
              setLoading(false);
              toast.error(response.data.message);
            }
          } catch (error: any) {
            setLoading(false);
            if (error.response !== undefined) {
              toast.error(error.response.data.message || "Error is Processing");
            }
          }
          setCount(0);
        }
      }
    } // end of isvalid
  };

  const renderCheckListPopup = () => {
    switch (checklistSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            onHide={() => {
              setCheckListSetPopup(false);
              setStatus("");
              setStatusError("");
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setCheckListSetPopup(false);
                  setStatus("");
                  setStatusError("");
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approval Check List </h6>
            </div>
            <div className="modal-body">
              <div className=" ">
                <div className=" ">
                  <div className={"checlist-scroll"}>
                    {checklistData.map((val: any, ind: any) => {
                      return (
                        <CheckListItem
                          updateFormData={updateFormData}
                          val={val}
                          ind={ind}
                        />
                      );
                    })}
                  </div>
                  <div>
                    {" "}
                    Transactions Selected:{" "}
                    <span className="text-primary">
                      {bulkProcessingData.length}
                    </span>{" "}
                  </div>
                  <form action="">
                    <div className="mt-2 row">
                      <div className="col-sm-2">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              value="accept"
                              checked={status === "accept" ? true : false}
                              id="accept"
                              onChange={(e) => {
                                setStatusError("");
                                if (e.target.checked) {
                                  setStatus(e.target.value);
                                } else {
                                  setStatus("");
                                }
                                // setStatus(e.target.value);
                              }}
                            />
                            <span className="form-check-sign" />
                            Accept
                          </Label>
                        </FormGroup>
                      </div>
                      <div className="col-sm-2">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={status === "reject" ? true : false}
                              value="reject"
                              id="reject"
                              onChange={(e) => {
                                setStatusError("");
                                setReasonTextError("");
                                if (e.target.checked) {
                                  setStatus(e.target.value);
                                } else {
                                  setStatus("");
                                }
                              }}
                            />
                            <span className="form-check-sign" />
                            Reject
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                  </form>
                  <Row>
                    {statusError ? (
                      <text className="error-labels ml-3 mt-2">
                        {statusError}
                      </text>
                    ) : (
                      ""
                    )}
                  </Row>
                  {status === "reject" && (
                    <Row>
                      <div className="col-md-12">
                        <div className="form-group mt-1">
                          <textarea
                            placeholder="Reason"
                            className={
                              "form-control w-100 " +
                              (reasonTextError ? "required-border" : "")
                            }
                            value={reasonText}
                            onChange={(e) => {
                              setReasonText(e.target.value);
                              setReasonTextError("");
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </Row>
                  )}
                  <Row className="">
                    <Col>
                      <div className="mt-3">
                        <button
                          className="btn btn-primary"
                          disabled={Boolean(Loading)}
                          onClick={() => {
                            updateBulkTxStatus();
                          }}
                        >
                          {Loading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Loading...</span>
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <button
                          className="btn btn-default ml-3"
                          onClick={() => {
                            setStatusError("");
                            setStatus("");
                            setCheckListSetPopup(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderViewTxnModalPopup = () => {
    switch (viewTxnModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewTxnModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transaction </h6>
            </div>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"></h6>
            </div>
            <div className="modal-body">
              {/* <SignatoryA data={headLabel}/> */}
              <SignatoryA setPropState={setPropsState} data={headLabel} />
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              {/* <Header />	 */}
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title text-primary">
                    {headLabel.toUpperCase()} Transactions
                  </h4>
                </div>
                <div className="card-body">
                  {/* <div className="input-holder"> */}
                  {/* <div className="d-flex">	
                <div className="form-group inline-box">	 */}
                  <Row>
                    <Col md="4">
                      <div className="form-group">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <input
                          type="search"
                          id="myInput"
                          placeholder="Transaction Id"
                          data-tip="Search Transaction"
                          className="form-control"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md="6">
                      <button
                        className="btn btn-primary mt-a ml-2"
                        disabled={Boolean(searchLoading)}
                        onClick={() => {
                          if (searchValue) {
                            setChangeCheck(searchValue);
                            bulkProcessingData.length = 0;
                            setChangeCheck(searchValue);
                            searchFilters(searchValue).then(() => {
                              setActivePage("");
                            });
                          }
                        }}
                      >
                        {searchLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Searching...</span>
                          </>
                        ) : (
                          "Search"
                        )}
                        {/* Search	 */}
                      </button>
                      <button
                        className="btn btn-default ml-2 mt-a"
                        disabled={Boolean(clearLoading)}
                        onClick={() => {
                          setChangeCheck("");
                          bulkProcessingData.length = 0;
                          setChangeCheck("");
                          clearFilters("", "").then(() => {
                            setActivePage("");
                          });
                        }}
                      >
                        {clearLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Clear</span>
                          </>
                        ) : (
                          "Clear"
                        )}
                        {/* Search	 */}
                      </button>
                    </Col>
                  </Row>

                  {/* </div>	
              </div> 	 */}
                  {/* </div> */}
                  {resLoading ? (
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  ) : (
                    <div className="">
                      {transactions.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table mt-2">
                            <thead>
                              <tr>
                                <th>Select</th>
                                <th>Date</th>
                                <th>Transaction</th>
                                <th>Fund</th>
                                <th>Amount</th>
                                <th>Bank</th>
                                <th>Counter Acc</th>
                                <th>Instrument</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Act</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map(
                                (transaction: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td
                                        onClick={() => {
                                          if (
                                            userDepartment == "ADMIN" ||
                                            email != transaction.created_by
                                          ) {
                                            if (
                                              checkBoolean(
                                                transaction.txn_id.replace(
                                                  "TXN_",
                                                  ""
                                                )
                                              ) == false
                                            ) {
                                              addTxnForBulk(transaction);
                                            } else {
                                              removeTxnFromBulk(transaction);
                                            }
                                          }
                                        }}
                                      >
                                        <FormGroup check>
                                          <Label check>
                                            <Input
                                              type="checkbox"
                                              checked={checkBoolean(
                                                transaction.txn_id.replace(
                                                  "TXN_",
                                                  ""
                                                )
                                              )}
                                              disabled={
                                                userDepartment != "ADMIN" &&
                                                email == transaction.created_by
                                              }
                                              onChange={(e) => {
                                                // if (e.target.checked === true) {
                                                //   addTxnForBulk(transaction);
                                                // } else {
                                                //   removeTxnFromBulk(transaction);
                                                // }
                                                if (
                                                  userDepartment == "ADMIN" ||
                                                  email !=
                                                    transaction.created_by
                                                ) {
                                                  if (
                                                    checkBoolean(
                                                      transaction.txn_id.replace(
                                                        "TXN_",
                                                        ""
                                                      )
                                                    ) == false
                                                  ) {
                                                    addTxnForBulk(transaction);
                                                  } else {
                                                    removeTxnFromBulk(
                                                      transaction
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                            <span className="form-check-sign" />
                                          </Label>
                                        </FormGroup>
                                      </td>
                                      <td>
                                        {moment(
                                          transaction.instruction_date
                                        ).format("DD-MM-YYYY")}{" "}
                                        <br />
                                        <span className="sub-content">
                                          Exe:{" "}
                                          {moment(
                                            transaction.execution_date
                                          ).format("DD-MM-YYYY")}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.txnr_type
                                          ? `${CapTxnType(
                                              transaction.txnr_type ===
                                                "dailytrusteefee"
                                                ? "postnavpayments"
                                                : transaction.txnr_type
                                            )}	${
                                              transaction.detail?.includes(
                                                "Reversed"
                                              )
                                                ? " (Reversed)"
                                                : ""
                                            }`
                                          : "N/A"}
                                        <br />{" "}
                                        <span className="sub-content">
                                          {transaction.txn_id.replace(
                                            "TXN_",
                                            ""
                                          )}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.fund_code} <br />
                                        {/* <span className="sub-content">
                                          {transaction.amc_code
                                            ? transaction.amc_code
                                            : "N/A"}{" "}
                                        </span> */}
                                      </td>

                                      <td className="text-left pr-3">
                                        {transaction?.net_amount
                                          ? numberWithCommas(
                                              transaction.net_amount
                                            )
                                          : ""}{" "}
                                        <br />
                                        <span className="sub-content">
                                          {transaction.gross_amount
                                            ? numberWithCommas(
                                                transaction.gross_amount
                                              )
                                            : ""}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.bank}
                                        <br />
                                        <span className="sub-content">
                                          {transaction.account_number}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.counter_bank} <br />
                                        <span className="sub-content">
                                          {transaction.counter_account_number}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.mode_of_payment} <br />
                                        <span className="sub-content">
                                          {transaction.instrument_type}{" "}
                                          {transaction.instrument_no !== ""
                                            ? transaction.instrument_no + ","
                                            : ""}{" "}
                                          {moment(
                                            transaction.instrument_date
                                          ).format("DD-MM-YYYY")}
                                        </span>
                                      </td>

                                      <td>
                                        {moment(transaction.created_at).format(
                                          "DD-MM-YYYY"
                                        )}{" "}
                                        <br />
                                        <span className="sub-content">
                                          {transaction.created_by.split("@")[0]}
                                        </span>
                                      </td>
                                      <td>{transaction.txn_status}</td>
                                      <td className=" ">
                                        <div className="multi-icons pointer">
                                          <button
                                            type="button"
                                            className="btn-icon btn-link like btn btn-info btn-sm"
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "csab-txn",
                                                JSON.stringify(transaction)
                                              );
                                              // history.replace(`/admin/signatory-a/${headLabel}`);
                                              history.replace(
                                                `/admin/details/${headLabel}`
                                              );
                                            }}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </button>

                                          {/* <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {		
	                            sessionStorage.setItem('csab-txn',JSON.stringify(transaction));	
                              setPropsState({
                              transaction: "",
                              headLabel: headLabel
                            });
                              setViewTxnModal(true);
                            }}>
                                  <i className="fa fa-eye">
                              </i>
                            </button> */}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">
                            No record found
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row pointer justify-content-center">
                    {/* {activePage !== paginationArr[0] ? (
                      <i
                        className="fa fa-arrow-left"
                        onClick={() => {
                          let pageArr = paginationArr;
                          let index = pageArr.findIndex(
                            (x) => x === activePage
                          );
                          if (index !== -1) {
                            setActivePage(paginationArr[index - 1]);
                          }
                        }}
                      ></i>
                    ) : (
                      ""
                    )} */}
                    {/* NEXT BUTTON */}
                    {/* {activePage !== paginationArr[paginationArr.length - 1] ? (
                      <i
                        className="fa ml-3 fa-arrow-right"
                        onClick={() => {
                          let pageArr = paginationArr;
                          let index = pageArr.findIndex(
                            (x) => x === activePage
                          );
                          if (index !== -1) {
                            setActivePage(pageArr[index + 1]);

                            //   var i;
                            //   for(i=0;i<transactions.length;i++){
                            //   if((transactions[i].id !== bulkProcessingData[i].txn_id) || bulkProcessingData[i].txn_id==null)
                            //   {
                            //     setSelectAll(false);
                            //     break;
                            //   }else{
                            //     setSelectAll(true);
                            //   }
                            // }
                          }
                        }}
                      ></i>
                    ) : (
                      ""
                    )} */}
                    <button
                      aria-hidden={true}
                      className="btn btn-primary btn-sm mt-2 py-2 px-3"
                      disabled={
                        activePage == paginationArr[paginationArr.length - 1] ||
                        resLoading
                      }
                      type="button"
                      onClick={() => {
                        let pageArr = paginationArr;
                        let index = pageArr.findIndex((x) => x === activePage);
                        if (index !== -1) {
                          setActivePage(paginationArr[index + 1]);
                        }
                      }}
                    >
                      {resLoading ? (
                        <span>
                          Loading...{" "}
                          <i
                            className="fa fa-spinner fa-spin  "
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : (
                        "Show More"
                      )}
                    </button>
                  </div>

                  {bulkProcessingData.length === 0 ? (
                    ""
                  ) : (
                    <div className="mb-2">
                      {" "}
                      <span className="text-primary">
                        {bulkProcessingData.length}
                      </span>{" "}
                      transactions selected. &nbsp;{" "}
                      <a
                        onClick={() => {
                          bulkProcessingData.length = 0;
                          setBulkProcessingData(bulkProcessingData);
                          // setCount(0);
                          setChangeCheck(`${count}`);
                        }}
                      >
                        Clear Selection
                      </a>{" "}
                    </div>
                  )}

                  {bulkProcessingData.length > 0 && (
                    <Row>
                      <Col md="6">
                        <div className="d-flex">
                          <div className="">
                            <button
                              className="btn btn-primary"
                              disabled={Boolean(Loading)}
                              onClick={() => {
                                setCheckListSetPopup(true);
                              }}
                            >
                              Action
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
              {renderCheckListPopup()}
              {renderViewTxnModalPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ComplianceSignatoryTransactions;

import { ActionTypes } from "../contants/action-types";

const initialState = {
    Theme: ""
};

export const ThemeModeReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_THEME_MODE:
            return { ...state, Theme: payload };
        default:
            return state;
    }
};
import React from 'react';
import {
  addBroker,
  getBrokerType,
} from '../../../stores/services/broker.service';
import { getAllBanks } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardHeader, CardTitle, CardBody, Card } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { accountTitle_AccountNumber_Regex } from '../../../utils/customFunction';
import ToggleButton from 'react-toggle-button';
import Select from "react-select";
import { useSelector } from 'react-redux';


const BrokerComponent = () => {

  const revenueAuthorityArr = [
    { label: "FBR - Federal Board Of Revenue", value: "FBR" },
    { label: "SRB - Sindh Revenue Board", value: "SRB" },
    { label: "PRA - Punjab Revenue Authority", value: "PRA" },
    { label: "BRA - Balochistan Revenue Authority", value: "BRA" },
    { label: "KPRA - Khyber Pakhtunkhwa Revenue Authority", value: "KPRA" }
  ];

  const incomeTaxStatusArr = [
    { label: "Filer", value: "Filer" },
    { label: "Non-Filer", value: "Non-Filer" }
  ];

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  //hooks for getting all inputs from user	
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [bankname, setBankName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [brokerName, setBrokerName] = useState('');
  const [brokerType, setBrokerType] = useState('');
  const [revenueAuthority, setRevenueAuthority] = useState('');
  const [iban, setIban] = useState('');
  const [acc_title, setAccTitle] = useState('');
  const [code, setCode] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const [companyName, setCompanyName] = useState('');
  //error getting hooks	
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [brokerNameError, setBrokerError] = useState('');
  const [brokerTypeError, setBrokerTypeError] = useState('');
  const [revenueAuthorityError, setRevenueAuthorityError] = useState('');
  const [ibanError, setIbanError] = useState('');
  const [acc_titleError, setAccTitleError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [companyNameError, setCompanyNameError] = useState('');
  const [brokerTypeData, setBrokerTypeData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);
  const [taxStatus, setTaxStatus] = useState('');

  const [active, setActive] = useState("active");
  //	
  const codeRegex = new RegExp('^[A-Z]{1,3}-[0-9]{1,3}$');
  //for dropdown data	
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const getBranchByName = async (name: string) => {
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    setBranchName('');
    setBranchNameError('');
    //get all branches for selected bank for dropdown	
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false)
      } else {
        setBranchCheckFlag(true)
      }
    } catch (error) { }
  };
  React.useEffect(() => {
    const fetchBank = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);
      //get all banks list for dropdown	
      try {
        const response = await getAllBanks(email, "", "", amc_code);
        setBankNameData(response.data.data);
      } catch (error) { }
      try {
        const res = await getBrokerType(email);
        setBrokerTypeData(res.data.company_names);
      } catch (error) { }
      //get tx nature data for dropdown	
    };
    fetchBank();
  }, []);
  const changeCodeValue = (value: any) => {
    setCode(value);
    if (value.trim() === '') {
      setCodeError('Required');
    } else {
      codeRegex.test(value) !== true
        ? setCodeError('Invalid')
        : setCodeError('');
    }
  };
  //render dropdown for bank name data	
  // const renderBankNameDropdown = () => {
  //   return bankNameData.map((item: any, index: string) => {
  //     return (
  //       <option key={index} value={item.bank_code.replace('BANK_', '')}>
  //         {item.bank_name} ({item.bank_code.replace('BANK_', '')})
  //       </option>
  //     );
  //   });
  // };
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return {
        value: item.bank_code.replace('BANK_', ''),
        label: `${item.bank_name} (${item.bank_code.replace('BANK_', '')})`
      }
    });
  };
  //render dropdown for bank name data	
  // const renderBrokerTypeDropdown = () => {
  //   return brokerTypeData.map((item: any, index: string) => {
  //     return (
  //       <option key={index} value={item}>
  //         {item}
  //       </option>
  //     );
  //   });
  // };

  const renderBrokerTypeDropdown = () => {
    return brokerTypeData.map((item: any, index: string) => {
      return {
        value: item,
        label: item
      }
    });
  };
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };
  //render dropdown for nature of transaction data	
  const validate = () => {
    let bankErr,
      branchErr,
      brokerErr,
      brokerTypeErr,
      revenueAuthorityErr,
      ibanErr,
      titleErr,
      codeErr = '';
    bankname.trim() === '' ? (bankErr = 'Required') : (bankErr = '');
    branchName.trim() === '' ? (branchErr = 'Required') : (branchErr = '');
    brokerType.trim() === ''
      ? (brokerTypeErr = 'Required')
      : (brokerTypeErr = '');
    revenueAuthority.trim() === ''
      ? (revenueAuthorityErr = 'Required')
      : (revenueAuthorityErr = '');
    iban.trim() === '' ? (ibanErr = 'Required') : (ibanErr = '');
    acc_title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    code.trim() === '' ? (codeErr = 'Required') : (codeErr = '');
    companyName.trim() === '' ? (brokerErr = 'Required') : (brokerErr = '');
    if (
      bankErr ||
      branchErr ||
      brokerErr ||
      brokerTypeErr ||
      revenueAuthorityErr ||
      ibanErr ||
      titleErr ||
      codeErr
    ) {
      setBankNameError(bankErr);
      setAccTitleError(titleErr);
      setCodeError(codeErr);
      setCompanyNameError(brokerErr);
      setBranchNameError(branchErr);
      setBrokerTypeError(brokerTypeErr);
      setRevenueAuthorityError(revenueAuthorityErr);
      setIbanError(ibanErr);
      return false;
    } else {
      return true;
    }
  };
  const addBrokerProcess = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addBroker(
          email,
          brokerType,
          bankname,
          branchName,
          iban,
          code,
          acc_title,
          companyName,
          revenueAuthority,
          active,
          amc_code,
          taxStatus
        );
        if (response.data.status === 200) {

          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/associated-beneficiaries`);
          }, 3000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message[0]);
        } else {
          toast.error(error.response.data.message);
        }
      }

    }
  };
  const borderRadiusStyle = { borderRadius: 2 };

  const selectedRevenueAuthority = React.useMemo(() => {
    const selectedFund = revenueAuthorityArr?.find(item => item?.value === revenueAuthority);
    return selectedFund
      ? {
        value: selectedFund.value,
        label: selectedFund.label
      }
      :
      null;
  }, [revenueAuthority]);

  const selectedCompanyType = React.useMemo(() => {
    return brokerType
      ? {
        value: brokerType,
        label: brokerType
      }
      :
      null;
  }, [brokerType]);

  const selectedIncomeTaxStatus = React.useMemo(() => {
    return taxStatus
      ? {
        value: taxStatus,
        label: taxStatus
      }
      :
      null;
  }, [taxStatus]);

  const selectedBankName = React.useMemo(() => {
    const selectedFund = bankNameData?.find(item => item.bank_code.replace('BANK_', '') === bankname);
    return selectedFund
      ? {
        value: selectedFund.bank_code.replace('BANK_', ''),
        label: `${selectedFund.bank_name} (${selectedFund.bank_code.replace('BANK_', '')})`
      }
      :
      null;
  }, [bankNameData, bankname]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="input-holder">
                <h4 className="card-title">
                  <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/associated-beneficiaries')
                  }}></i>Associated Beneficiary</h4>
                <Link to="/admin/associated-beneficiaries" className="t-3 ml-auto btn btn-primary btn-sm" replace>
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Associated Beneficiary
                      </CardTitle>
                    </CardHeader>
                    <CardBody>

                      <div className="form-group">
                        <label>Code: <span className="color-amber">*</span></label>
                        <div>
                          <div className="">
                            <input
                              type="text"
                              className={`form-control  w-100 ${codeError ? 'required-border' : ''
                                }`}
                              value={code}
                              onChange={(e) => {

                                var cleaned = e.target.value;
                                if (cleaned.length == 3 && !cleaned.includes("-")) {
                                  cleaned = cleaned + "-";
                                }
                                else if (cleaned.length > 7) {
                                  cleaned = cleaned.substring(0, 7);
                                }
                                changeCodeValue(cleaned.toUpperCase())
                              }} />


                          </div>
                          <div className="d-flex">
                            <p style={{ fontSize: '11px' }}>
                              Code Pattern (AAA-999)
                            </p>
                            {codeError ? (
                              <span
                                className="ml-auto"
                                style={{ fontSize: '11px' }}
                              >
                                {codeError}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>

                        </div>
                      </div>

                      <div className="form-group">
                        <label>Company Name: <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${companyNameError ? 'required-border' : ''
                              }`}
                            value={companyName}
                            onChange={(e) => {
                              // eslint-disable-next-line 
                              setCompanyName(e.target.value),
                                setCompanyNameError('');
                            }}
                          />
                          {/* {companyNameError ? <p className="error-labels error-message">{companyNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Company Type: <span className="color-amber">*</span></label>
                        <div className="">
                          <Select
                            className={
                              brokerTypeError ? 'required-border' : ''
                            }
                            value={selectedCompanyType}
                            label={"Select Broker Type"}
                            onChange={(e) => {
                              // eslint-disable-next-line 
                              setBrokerType(e?.value), setBrokerTypeError('');
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderBrokerTypeDropdown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className={`form-control  w-100 ${brokerTypeError ? 'required-border' : ''
                              }`}
                            value={brokerType}
                            onChange={(e) => {
                              setBrokerType(e.target.value);
                              setBrokerTypeError('');
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Broker Type
                            </option>
                            {renderBrokerTypeDropdown()}
                          </select> */}
                          {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Revenue Authority: <span className="color-amber">*</span></label>
                        <div className="">
                          <Select
                            className={
                              revenueAuthorityError ? 'required-border' : ''
                            }
                            value={selectedRevenueAuthority}
                            label={"Select Revenue Authority"}
                            onChange={(e) => {
                              // eslint-disable-next-line 
                              setRevenueAuthority(e?.value);
                              setRevenueAuthorityError('');
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={revenueAuthorityArr}
                            styles={customStyles}
                          />
                          {/* <select	
                        className={`form-control  w-100 ${	
                          revenueAuthorityError ? 'required-border' : ''	
                        }`}	
                        value={revenueAuthority}	
                        onChange={(e) => {	
                          // eslint-disable-next-line 
                          setRevenueAuthority(e.target.value);
                          setRevenueAuthorityError('');	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                          Select Revenue Authority
                        </option>	
                        <option value="FBR">FBR - Federal Board Of Revenue</option>	
                        <option value="SRB">SRB - Sindh Revenue Board</option>	
                        <option value="PRA">PRA - Punjab Revenue Authority</option>	
                        <option value="BRA">BRA - Balochistan Revenue Authority</option>	
                        <option value="KPRA">KPRA - Khyber Pakhtunkhwa Revenue Authority</option>	
                      </select>	 */}
                          {/* {brokerTypeError ? <p className="error-labels error-message2">{brokerTypeError}</p> : ''} */}
                        </div>
                      </div>


                      <div className="form-group">

                        <label>Income Tax Status</label>
                        <Select

                          value={selectedIncomeTaxStatus}
                          label={"Select Tax Type"}
                          onChange={(e) => {
                            setTaxStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={incomeTaxStatusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control"
                          value={taxStatus}
                          onChange={(e) => {
                            setTaxStatus(e.target.value)
                          }}>
                          <option value="" hidden> Select Tax Type</option>
                          <option value="Filer">Filer</option>
                          <option value="Non-Filer">Non-Filer</option>
                        </select> */}
                      </div>

                      <div className="form-group d-flex mt-3">
                        <ToggleButton
                          inactiveLabel={""}
                          activeLabel={""}
                          value={active == "active" ? true : false}
                          thumbStyle={borderRadiusStyle}
                          trackStyle={borderRadiusStyle}
                          onToggle={() => {
                            if (active == "active") {
                              setActive("deactive");
                            } else {
                              setActive("active");
                            }
                          }}
                        />
                        <label className="mt-2 ml-1">Status</label>
                      </div>


                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">

                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Bank Account Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group">
                        <label>Account Title: <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${acc_titleError ? 'required-border' : ''
                              }`}
                            value={acc_title}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setAccTitle(e.target.value);
                                setAccTitleError('');
                              }
                            }}
                          />
                          {/* {acc_titleError ? <p className="error-labels error-message2">{acc_titleError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>IBAN / Account No: <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            type="text"
                            className={`form-control  w-100 ${ibanError ? 'required-border' : ''
                              }`}
                            value={iban}
                            onChange={(e) => {
                              if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                setIban(e.target.value);
                                setIbanError('');
                              }
                            }}
                          />
                          {/* {ibanError ? <p className="error-labels error-message">{ibanError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Bank Name: <span className="color-amber">*</span></label>
                        <div className="">
                          <Select
                            className={bankNameError ? 'required-border' : ''}
                            value={selectedBankName}
                            label={"Select Bank"}
                            onChange={(e) => {
                              setBankNameError('');
                              setBankName(e?.value);
                              getBranchByName(e?.value);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderBankNameDropdown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                              }`}
                            value={bankname}
                            onChange={(e) => {
                              setBankNameError('');
                              setBankName(e.target.value);
                              getBranchByName(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              {' '}
                              Select Bank
                            </option>
                            {renderBankNameDropdown()}
                          </select> */}
                          {/* {bankNameError ? <p className="error-labels error-message">{bankNameError}</p> : ''} */}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Branch Name: <span className="color-amber">*</span></label>

                        <input type="text"
                          className={`form-control  w-100 ${branchNameError ? 'required-border' : ''
                            }`}
                          value={branchName}
                          onChange={(e) => {
                            setBankNameError('');
                            setBranchName(e.target.value);
                          }}
                        />
                        {/* <div className="">	
                      <select	
                        className={`form-control  w-100 ${	
                          bankNameError ? 'required-border' : ''	
                        }`}	
                        value={branchName}	
                        onChange={(e) => {	
                          setBankNameError('');	
                          setBranchName(e.target.value);	
                        }}	
                      >	
                        <option value="" defaultChecked hidden>	
                        {branchCheckFlag? 'No Branch Found' :'Select Branch Name'}	
                        </option>	
                        {renderBranchsDropdown()}	
                      </select>	
                     </div>	 */}
                      </div>



                    </CardBody>
                  </Card>


                </Col>
              </Row>

              <button
                className="btn btn-primary"
                onClick={addBrokerProcess}
                disabled={Boolean(Loading)}
              >
                {Loading ? (
                  <>
                    <span
                      className="spinner-border login-txt spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="login-txt"> Loading...</span>
                  </>
                ) : (
                  <span>Create</span>
                )}
              </button>

            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default BrokerComponent;
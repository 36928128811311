import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { getAllBanks, getCities } from '../../../stores/services/bank.service';
import { addBranch } from '../../../stores/services/branch.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { useSelector } from 'react-redux';

const SetupBranch = () => {
  //hooks for getting values
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const [branchName, setBranchName] = useState('');
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [branchCode, setBranchCode] = useState('');
  const [branchPrefix, setBranchPrefix] = useState('');
  const [bankName, setBankName] = useState('');
  const [address, setAddress] = useState('');
  const [area, setArea] = useState('');
  const [city, setCity] = useState('');
  const email = sessionStorage.getItem('email') || '';
  //hooks for errors	
  const [branchNameError, setBranchNameError] = useState('');
  const [branchCodeError, setBranchCodeError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [cityError, setCityError] = useState('');
  const [Loading, setLoading] = useState(false);
  //	
  const [allBanks, setAllBanks] = useState<any>([]);
  const [allCitiesData, setAllCitiesData] = useState<any>([]);
  const codeRegex = new RegExp('^[A-Z]{1,4}-[0-9]{1,4}$');
  React.useEffect(() => {
    const fetchAmc = async () => {
      try {
        const amcResponse = await getAllBanks(email, "", "", amc_code);
        setAllBanks(amcResponse.data.data);
      } catch (error) { }
      try {
        const response = await getCities();
        setAllCitiesData(response.data.cities);
      } catch (error) { }
    };
    fetchAmc();
  }, []);
  // const renderBanksDropdown = () => {
  //   return allBanks.map((item: any) => {
  //     return (
  //       <option key={item.bank_code} value={item.bank_code.replace('BANK_', '')}>
  //         {item.bank_name} ({item.bank_code.replace('BANK_', '')})
  //       </option>
  //     );
  //   });
  // };
  const renderBanksDropdown = () => {
    return allBanks.map((item: any) => {
      return {
        value: item.bank_code.replace('BANK_', ''),
        label: `${item.bank_name} (${item.bank_code.replace('BANK_', '')})`
      }
    });
  };
  // const renderCitiesDropdown = () => {
  //   return allCitiesData.map((item: any) => {
  //     return (
  //       <option key={item} value={item}>
  //         {item}
  //       </option>
  //     );
  //   });
  // };
  const renderCitiesDropdown = () => {
    return allCitiesData.map((item: any) => {
      return {
        value: item,
        label: item
      }
    });
  };
  const validate = () => {
    let branchError = '';
    let BcodeError = '';
    let nameErr = '';
    let addressErr = '';
    let areaErr = '';
    let cityErr = '';
    branchName?.trim() === '' ? (branchError = 'Required') : (branchError = '');
    branchCode?.trim() === ''
      ? (BcodeError = 'Required')
      : codeRegex.test(branchCode) !== true
        ? (BcodeError = 'Invalid')
        : (BcodeError = '');
    bankName?.trim() === '' ? (nameErr = 'Required') : (nameErr = '');

    address?.trim() === '' ? (addressErr = 'Required') : (addressErr = '');
    city?.trim() === '' ? (cityErr = 'Required') : (cityErr = '');
    area?.trim() === '' ? (areaErr = 'Required') : (areaErr = '');
    if (
      branchError ||
      BcodeError ||
      nameErr ||
      addressErr ||
      areaErr ||
      cityErr
    ) {
      setBranchNameError(branchError);
      setBranchCodeError(BcodeError);
      setBankNameError(nameErr);
      setAddressError(addressErr);
      setAreaError(areaErr);
      setCityError(cityErr);
      return false;
    } else {
      return true;
    }
  };
  const AddBranch = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addBranch(
          email,
          bankName,
          branchName,
          city,
          area,
          branchCode,
          address,
          amc_code
        );
        if (response.data.status === 200) {

          toast.success(response.data.message);

          setTimeout(() => {
            history.replace(`/admin/bank-branches`);
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response.data.message?.toString() || error?.message || "Something went to wrong");
      }

    } else {
      toast.error("Kindly Fill All The Fields.")
    }
  };
  const changeCodeValue = (value: string) => {
    setBranchCode(value);
    if (value.trim() === '') {
      setBranchCodeError('Required');
    } else {
      codeRegex.test(value) !== true
        ? setBranchCodeError('Invalid')
        : setBranchCodeError('');
    }
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allBanks && allBanks?.find(item => item?.bank_code.replace('BANK_', '') === bankName);
    return selectedFund
      ? {
        value: selectedFund.bank_code.replace('BANK_', ''),
        label: `${selectedFund.bank_name} (${selectedFund.bank_code.replace('BANK_', '')})`
      }
      :
      null;
  }, [allBanks, bankName]);

  const selectedCity = React.useMemo(() => {
    return city
      ? {
        value: city,
        label: city
      }
      :
      null;
  }, [city]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/bank-branches')
                  }}></i>Setup - Branch
                    <Link
                      to="/admin/bank-branches"
                      className="btn btn-primary btn-sm float-right" replace>
                      <i className="fa fa-eye mr-2"></i>View All
                    </Link>	</h4>
                </div>
                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Bank Code  <span className="color-amber">*</span></label>
                        <Select
                          className={`${bankNameError ? 'required-border' : ''
                            }`}
                          value={selectedFundName}
                          label={"Select Bank Name"}
                          onChange={(e) => {
                            if (e?.value) {
                              setBankNameError('');
                              setBankName(e?.value);
                              setBranchPrefix(e?.value + '-');
                              setBranchCode(e?.value + '-');
                            }
                            else {
                              setBankName("");
                              setBranchPrefix("");
                              setBranchCode("");
                            }

                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderBanksDropdown()}
                          styles={customStyles}
                        />
                        {/* <select
                          className={`form-control  w-100 ${bankNameError ? 'required-border' : ''
                            }`}
                          value={bankName}
                          onChange={(e) => {
                            setBankNameError('');
                            setBankName(e.target.value);
                            setBranchPrefix(e.target.value + '-');
                            setBranchCode(e.target.value + '-');
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select Bank Name
                          </option>
                          {renderBanksDropdown()}
                        </select> */}
                        {/* {bankNameError ? <p className="error-labels error-message">{bankNameError}</p> : ''} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Branch Name <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Branch Name"
                          className={`form-control  w-100 ${branchNameError ? 'required-border' : ''
                            }`}
                          value={branchName}
                          onChange={(e) => {
                            setBranchName(e.target.value);
                            setBranchNameError('');
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddBranch();
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Branch Code <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Branch Code"
                          className={`form-control  w-100 ${branchCodeError ? 'required-border' : ''
                            }`}
                          value={branchCode}
                          onKeyDown={() => {
                            if (
                              branchCode.indexOf(branchPrefix) != 0 ||
                              !branchCode.includes(branchPrefix)
                            ) {
                              changeCodeValue(branchPrefix);
                            }
                          }}
                          onChange={(e) => {
                            var cleaned = e.target.value;
                            if (cleaned.length == 4 && !cleaned.includes('-')) {
                              cleaned = cleaned + '-';
                            } else if (cleaned.length > 9) {
                              cleaned = cleaned.substring(0, 9);
                            }
                            changeCodeValue(cleaned.toUpperCase());
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddBranch();
                            }
                          }}
                        />
                      </div>
                      <div className="d-flex">
                        <p style={{ fontSize: '11px' }}>
                          Code Pattern (AAAA-9999)
                        </p>
                        {branchCodeError ? (
                          <span
                            className="ml-auto"
                            style={{ fontSize: '11px' }}
                          >
                            {branchCodeError}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Branch Address <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Branch Address"
                          className={`form-control  w-100 ${addressError ? 'required-border' : ''
                            }`}
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            setAddressError('');
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddBranch();
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>City <span className="color-amber">*</span></label>
                        <Select
                          className={`${cityError ? 'required-border' : ''
                            }`}
                          value={selectedCity}
                          label={"Select City"}
                          onChange={(e) => {
                            setCityError('');
                            setCity(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderCitiesDropdown()}
                          styles={customStyles}
                        />
                        {/* <select
                          className={`form-control w-100 ${cityError ? 'required-border' : ''
                            }`}
                          value={city}
                          onChange={(e) => {
                            setCityError('');
                            setCity(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            {' '}
                            Select City
                          </option>
                          {renderCitiesDropdown()}
                        </select> */}
                        {/* {cityError ? <p className="error-labels error-message2">{cityError}</p> : ''} */}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Area <span className="color-amber">*</span></label>
                        <input
                          type="text"
                          placeholder="Area"
                          className={`form-control  w-100 ${areaError ? 'required-border' : ''
                            }`}
                          value={area}
                          onChange={(e) => {
                            setArea(e.target.value);
                            setAreaError('');
                          }}
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              AddBranch();
                            }
                          }}
                        />
                        {/* {areaError ? <p className="error-labels error-message">{areaError}</p> : ''} */}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary ml-3"
                    onClick={AddBranch}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SetupBranch;
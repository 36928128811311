import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useRef,
} from "react";
import moment from "moment";
// import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
// import { useSelector } from "react-redux";
import "./Sidebar.css";
import { IoCaretDown } from "react-icons/io5";
import { IoCaretUp } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { env } from "../../env";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { headerTxnSearchFunctionality } from "../../stores/services/transactions.service";
import { getAllBanks } from "../../stores/services/bank.service";
import { Col, Row } from "reactstrap";
import ViewTransaction from "../../containers/view-beta-txn";
import { Modal } from "react-bootstrap";

const SearchbarHeader = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const layout = "/admin";
  const [showDialog, setShowDialog] = useState<any>(false);
  const [selectedOption, setSelectedOption] = useState<any>("Transaction");
  const [searchValue, setsearchValue] = useState<any>();
  const mainmenu: any = JSON.parse(
    sessionStorage.getItem("allFeatures") || "[]"
  );
  const allroutes: any = JSON.parse(
    sessionStorage.getItem("allRoutes") || "[]"
  );
  const [searchOpen, setsearchOpen] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>("");
  const [mapItem, setMapItem] = useState<any>([]);
  const dropdownRef1 = useRef<any>(null);
  const dropdownRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [searchedData, setSearchedData] = useState<any>([]);

  const handleClickOutside = (event: any) => {
    if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
      setShowDialog(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchOpen(false);
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items: any = [];
    mainmenu.filter((menuItems: any) => {
      if (
        menuItems.label.toLowerCase().includes(keyword) &&
        (menuItems.submenu?.length === 0 || !menuItems.submenu)
      ) {
        items.push(menuItems);
      }
      if (!menuItems.submenu) return false;
      menuItems.submenu.filter((subItems) => {
        if (
          subItems.label.toLowerCase().includes(keyword) &&
          (subItems.submenu?.length === 0 || !subItems.submenu)
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
        }
        if (!subItems.submenu) return false;
        subItems.submenu.filter((suSubItems) => {
          if (suSubItems.label.toLowerCase().includes(keyword)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });
    });

    const updatedItems = items.map((ele) => {
      let foundRoute;
      foundRoute = allroutes?.find((main) => main?.name === ele?.label);
      if (!foundRoute) {
        const allViews =
          allroutes?.flatMap((main) => {
            if (!Array.isArray(main.views)) return [];
            return main.views
              .map((sub) => {
                if (!sub) return null;
                const parentName = main.name;
                let label = sub.name;
                if (
                  sub.name?.includes("Dashboard") ||
                  sub.name?.includes("Transactions") ||
                  sub.name?.includes("Create Transactions")
                ) {
                  label = `${sub.name} ${parentName}`;
                }
                return { ...sub, label };
              })
              .filter(Boolean);
          }) || [];
        foundRoute = allViews.find((sub) => sub?.label === ele?.label);
      }

      return {
        ...ele,
        path: foundRoute?.path,
      };
    });

    setMapItem([...updatedItems]);
  };

  const addFix = () => {
    const typeaheadMenu = document.querySelector(".Typeahead-menu-new");
    if (typeaheadMenu) {
      typeaheadMenu.classList.add("is-open");
    }
  };

  const removeFix = () => {
    setsearchValue("");
    const typeaheadMenu = document.querySelector(".Typeahead-menu-new");
    if (typeaheadMenu) {
      typeaheadMenu.classList.remove("is-open");
    }
  };

  const [screen, setScreen] = useState<any>();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        const typeHead = document.querySelector(".Typeahead-menu-new");
        if (typeHead) {
          typeHead.classList.remove("is-open");
        }
        const emptyMenu = document.querySelector(".empty-menu");
        if (emptyMenu) {
          emptyMenu.classList.remove("is-open");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const borderStylingSymbolSpan = {
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
  };
  const borderStylingSymbolInput = {
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
  };
  const backgroundSearchArea = theme == "light" ? "#ffffff" : "#27293d";
  const colorSearchArea = theme == "light" ? "#9A9A9A" : "";

  let txnTypeCatagory: any = useSelector(
    (state: any) => state.txnTypeCategories
  );
  const [finalTxns, setFinalTxns] = useState<any>([]);
  const [viewTxnModal, setViewTxnModal] = useState(false);
  const [trx_history, setTrxHistory] = useState([]);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [viewChecklist, setViewCheckList] = useState(false);

  const [note, setNote] = useState("");
  const [trx_checklist, setTrxCheckList] = useState([]);

  const [bankData, setBankData] = useState([]);

  React.useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(
          sessionStorage.getItem("email") || "",
          "",
          "",
          sessionStorage.getItem("amc_code") || ""
        );
        setBankData(bankResponse.data.data);
      } catch (error) {}
    };
    fetchBank();
  }, []);

  useEffect(() => {
    if (txnTypeCatagory?.length > 0) {
      let temp: any = txnTypeCatagory;
      let txns: any = [];
      let mainTxns: any = [];
      let txnsAgain: any = [];
      for (let i = 0; i < temp.length; i++) {
        txns.push(...Object.values(temp[i]));
        mainTxns.push(...Object.keys(temp[i]));
        // txnTypeCatagory.push(...Object.values(temp[i]));
      }

      for (let j = 0; j < txns.length; j++) {
        txns[j] = txns[j].map((item) => {
          return {
            ...item,
            parent: mainTxns[j],
          };
        });
        txnsAgain.push(...txns[j]);
      }

      setFinalTxns(txnsAgain);
    }
  }, [txnTypeCatagory]);

  const handleSearch = async (e) => {
    if (
      selectedOption === "Transaction" &&
      e.target.value &&
      e.target.value.trim() !== ""
    ) {
      if (isNaN(e.target.value)) {
        toast.error("Only numbers allowed");
        return;
      }
      try {
        const response = await headerTxnSearchFunctionality(
          sessionStorage.getItem("email") || "",
          sessionStorage.getItem("amc_code") || "",
          e.target.value.toString(),
          sessionStorage.getItem("mainSelectedFund")
            ? JSON.parse(sessionStorage.getItem("mainSelectedFund") || "[]")
                ?.symbol_code
            : ""
        );

        if (response.status === 200 && response.data.status === 200) {
          setSearchedData(response.data.data);
        } else {
          toast.error("No record found!");
        }
      } catch (error: any) {
        toast.error("Error fetching data!");
        console.log("ERROR FETCHING TXN DATA => ", error);
        console.log("ERROR Message => ", error?.message);
      }
    }
  };

  const bankName = (bankcode: any) => {
    const bankdata: any = bankData.filter((item: any, index) => {
      return item.bank_code === bankcode;
    });
    if (bankdata.length > 0) return bankdata[0].bank_name;
    else return bankcode;
  };

  const renderViewTxnModalPopup = () => {
    switch (viewTxnModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewTxnModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transaction </h6>
            </div>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"></h6>
            </div>
            <div className="modal-body">
              <div className="">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-2 pointer"
                    onClick={() => {
                      setView(false);
                      setViewTxnModal(false);
                    }}
                  ></i>
                  View Transaction
                </h4>
                <ViewTransaction data={data} />

                <div className="line"></div>

                <Row className=" pb-5">
                  {trx_history.map((hist: any, index: number) => {
                    return (
                      <Col
                        md="3"
                        onClick={() => {
                          if (hist.action === "REJECTED-AMC") {
                            setNote(hist.note);
                          } else {
                            setNote("");
                          }
                          if (hist.action === "CREATED") {
                            return;
                          }
                          if (!hist.check_list) return;
                          setTrxCheckList(hist.check_list);
                          setViewCheckList(true);
                        }}
                      >
                        <div className="card process-card pointer">
                          <div className="card-header">
                            <h4 className="card-title">
                              <i className="tim-icons icon-single-02 mr-1"></i>{" "}
                              <span className="card-number">{index + 1}</span>
                              {hist.updated_by_role}{" "}
                            </h4>
                          </div>
                          <div className="card-body text-center">
                            <p className="small mt-2">Status:</p>
                            <h3 className="accepted text-primary">
                              {" "}
                              {hist.action}{" "}
                            </h3>
                            {hist.updated_at !== "" ? (
                              <>
                                <p className="small mt-2">by</p>
                                <h4 className="comment text-primary">
                                  {hist.updated_by}
                                </h4>
                                <p className="small mt-2">at</p>
                                <h4 className="comment text-primary">
                                  {moment(hist.updated_at).format(
                                    "DD-MM-YYYY, h:mm a"
                                  )}
                                </h4>
                              </>
                            ) : (
                              ""
                            )}
                            {hist.note !== "" ? (
                              <>
                                <p className="small mt-2">Comment</p>
                                <small
                                  className="comment dot-text"
                                  title={hist.note}
                                >
                                  {hist.note}
                                </small>
                              </>
                            ) : (
                              ""
                            )}
                            {hist.created_by !== "" ? (
                              <>
                                <p className="small mt-2">by</p>
                                <h4 className="text-primary">
                                  {hist.created_by}
                                </h4>
                                <p className="small mt-2"> at</p>
                                <h4 className="comment text-primary">
                                  {" "}
                                  {moment(hist.created_at).format(
                                    "DD-MM-YYYY, h:mm a"
                                  )}{" "}
                                </h4>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  return (
    <Fragment>
      <div
        className={
          localStorage.getItem("wrapper") === "horizontal_sidebar"
            ? "col-12 px-0 pt-3"
            : "col-12 px-0"
        }
      >
        <div className="input-group mb-0 position-relative">
          <div
            ref={dropdownRef1}
            onClick={() => setShowDialog(!showDialog)}
            className="input-group-prepend align-items-center "
          >
            <span
              style={borderStylingSymbolSpan}
              className="input-group-text"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              // onClick={() => setSelectedOption("")}
            >
              {/* {!selectedOption && <span>Select Criteria</span>} */}
              {selectedOption && <span>{selectedOption}</span>}
              <div className="d-flex flex-column pl-5">
                <IoCaretUp fontSize={8} />
                <IoCaretDown fontSize={8} />
              </div>
            </span>
            {showDialog && (
              <div className="position-absolute dropdownStyling">
                <span
                  className={"dropdown-item custom_style_item"}
                  // value="transaction"
                  onClick={() => setSelectedOption("Transaction")}
                  style={
                    selectedOption === "Transaction"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Transaction
                </span>
                <span
                  className="dropdown-item custom_style_item"
                  // value="ledgers"
                  onClick={() => setSelectedOption("Ledgers")}
                  style={
                    selectedOption === "Ledgers"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Ledgers
                </span>
                <span
                  className="dropdown-item custom_style_item"
                  // value="unitholders"
                  onClick={() => setSelectedOption("Unitholders")}
                  style={
                    selectedOption === "Unitholders"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Unitholders
                </span>
                <span
                  className="dropdown-item custom_style_item"
                  // value="navigation"
                  onClick={() => setSelectedOption("Navigation")}
                  style={
                    selectedOption === "Navigation"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Navigation
                </span>
                <span
                  className="dropdown-item custom_style_item"
                  // value="bank_accounts"
                  onClick={() => setSelectedOption("Bank Accounts")}
                  style={
                    selectedOption === "Bank Accounts"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Bank Accounts
                </span>
                <span
                  className="dropdown-item custom_style_item"
                  // value="funds"
                  onClick={() => setSelectedOption("Funds")}
                  style={
                    selectedOption === "Funds"
                      ? { backgroundColor: "#e14eca" }
                      : {}
                  }
                >
                  Funds
                </span>
              </div>
            )}
          </div>
          <input
            ref={inputRef}
            id={"onhover"}
            style={{ ...borderStylingSymbolInput, textAlign: "left" }}
            type={selectedOption === "Transaction" ? "number" : "text"}
            className="form-control"
            placeholder={
              selectedOption === "Navigation"
                ? "Search by Page Name"
                : selectedOption === "Transaction"
                ? "Search by TXN ID"
                : selectedOption === "Ledgers"
                ? "Search by Ledger Code"
                : selectedOption === "Unitholders"
                ? "Search by Folio"
                : selectedOption === "Bank Accounts"
                ? "Search by Account Number"
                : selectedOption === "Funds"
                ? "Search by Fund Code"
                : "Search..."
            }
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={selectedItem}
            onClick={() => {
              if (selectedOption == "Navigation") {
                if (selectedItem && !searchValue?.length) {
                  const emptyMenu = document.querySelector(".empty-menu");
                  if (emptyMenu) {
                    emptyMenu.classList.add("is-open");
                  }
                }
                selectedItem && addFix();
              }
            }}
            onChange={(e) => {
              setSelectedItem(e.target.value);
              setMapItem([]);
              setSearchedData([]);
              if (selectedOption == "Navigation") {
                handleSearchKeyword(e.target.value?.toLowerCase());
                setsearchValue(e.target.value?.toLowerCase());
              }
              if (selectedOption != "Navigation") {
                addFix();
                setsearchValue(e.target.value);
                handleSearch(e);
              }
            }}
          />
          <div
            style={{
              right: "23px",
              top: "12px",
              cursor: "pointer",
            }}
            className="position-absolute"
          >
            <IoSearchOutline fontSize={20} />
          </div>

          {selectedOption === "Navigation" && searchValue ? (
            <div
              ref={dropdownRef}
              style={{
                border: "",
                width: "98%",
                top: screen <= 575 ? "115%" : "",
                background: backgroundSearchArea,
                color: colorSearchArea,
              }}
              className="Typeahead-menu-new custom-scrollbar"
              id="search-outer"
            >
              {mapItem?.length > 0 ? (
                mapItem?.map((data: any, index) => {
                  return (
                    <>
                      <div id={""} className="d-flex" key={index}>
                        <div className="ProfileCard-avatar ml-2">
                          <i
                            style={{ color: colorSearchArea }}
                            className={data.icon}
                          ></i>
                        </div>
                        <div className="ProfileCard-details ml-2">
                          <div className="ProfileCard-realName">
                            <Link
                              id="text-onHover"
                              style={{ color: colorSearchArea }}
                              // @ts-ignore
                              to={`${env.PUBLIC_URL}${layout}${data?.path}`}
                              className={
                                selectedItem == data?.label
                                  ? "realname active-Menu"
                                  : "realname"
                              }
                              onClick={(e) => {
                                handleSearchKeyword(data.label?.toLowerCase());
                                setSelectedItem(data.label);
                                // @ts-ignore: Object is possibly 'null'.
                                document
                                  .querySelector(".Typeahead-menu-new")
                                  .classList.remove("is-open");
                              }}
                            >
                              {data.label}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {mapItem?.length - 1 !== index && (
                        <hr
                          style={{
                            margin: "3px",
                          }}
                        ></hr>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="EmptyMessage">{"No result found."}</div>
              )}
            </div>
          ) : (
            ""
          )}
          {selectedOption !== "Navigation" && searchValue && (
            <div
              ref={dropdownRef}
              style={{
                border: "",
                width: "98%",
                top: screen <= 575 ? "115%" : "",
                background: backgroundSearchArea,
                color: colorSearchArea,
              }}
              className="Typeahead-menu-new custom-scrollbar"
              id="search-outer"
            >
              {/* MAP HERE */}

              {searchedData?.length > 0 ? (
                searchedData?.map((transaction, index) => {
                  let txnr_type = finalTxns.find(
                    (item: any) => item.api_name === transaction.txnr_type
                  )?.dis_name;

                  return (
                    <>
                      <div id={""} className="d-flex" key={index}>
                        {/* <div className="ProfileCard-avatar ml-2">
                          <i
                            style={{ color: colorSearchArea }}
                            className={data.icon}
                          ></i>
                        </div> */}
                        <div className="ProfileCard-details ml-2">
                          <div className="ProfileCard-realName">
                            <div
                              id="text-onHover"
                              style={{
                                color: colorSearchArea,
                                cursor: "pointer",
                              }}
                              className="realname"
                              onClick={(e) => {
                                let objArr = Object.entries(transaction);
                                let bankname = bankName(
                                  transaction.bank.toString()
                                );
                                transaction.bank = bankname;
                                let counterbankname = bankName(
                                  transaction.counter_bank.toString()
                                );
                                transaction.counter_bank = counterbankname;
                                setTrxHistory(transaction.txn_history);
                                setData(transaction);
                                // toast.success(transaction.txn_history)
                                // setView(true);
                                setViewTxnModal(true);
                              }}
                            >
                              <span>{transaction.txn_id} | </span>
                              <span>{txnr_type} | </span>
                              <span>{transaction.fund_code} </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {searchedData?.length - 1 !== index && (
                        <hr
                          style={{
                            margin: "3px",
                          }}
                        ></hr>
                      )}
                    </>
                  );
                })
              ) : (
                <div className="EmptyMessage">{"No result found."}</div>
              )}
            </div>
          )}
        </div>
      </div>
      {renderViewTxnModalPopup()}
    </Fragment>
  );
};

export default SearchbarHeader;

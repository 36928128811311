import React, { useEffect, useState } from "react";
// import Header from './../components/Header';
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import moment from "moment";
import {
  getAllPendingTransactions,
  searchTransactions,
  getCorporateAnnouncemetTxns,
} from "../../stores/services/transactions.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import { saveCorporateAnouncement } from "../../stores/services/corporate-announcement.service";
import ViewTransaction from "../view-beta-txn";
import { toast, ToastContainer } from "react-toastify";
import { BsTable } from "react-icons/bs";
import {
  RiAddLine,
  RiPrinterLine,
  RiCheckboxMultipleLine,
  RiCheckboxLine,
  RiMailSendFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { IoIosSend } from "react-icons/io";
import {
  getLedgerAccounts,
  getAllLedgers,
  getLedgersByTxn,
} from "../../utils/customFunction";
import {
  getChartOfAccounts,
  getTransactionsLedgers,
  getLedgerInfoByCode,
} from "../../stores/services/chatofaccounts.service";
import { useSelector } from "react-redux";
import Select from "react-select";

const CorporateAnnouncementTxnList = () => {
  const statusArr = [
    { label: "Created", value: "CREATED" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Rejected", value: "REJECTED" },
    { label: "Rejected-Amc", value: "REJECTED-AMC" },
  ];

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  let [transactions, setTransactions] = useState<any>([]);
  let [status, setStatus] = useState("");
  // let [lastPageNum, setLastPageNum] = useState('');
  let [paginationArr, setPaginationArr] = useState([""]);
  let [currentPage, setCurrentPage] = useState("");
  let [activePage, setActivePage] = useState("");

  const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);

  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [resLoading, setResLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState("");

  const [togglePagination, setTogglePagination] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<any>([]);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [percentage, setPercentage] = useState("");
  const [dividendTaxAmount, setDividendTaxAmount] = useState("");
  const [txn_id, setTxnId] = useState("");
  const [bankGL, setBankGL] = useState("");
  const [loading, setLoading] = useState(false);

  async function getTransactions(resetData: string = "") {
    // setResLoading(true);
    await getCorporateAnnouncemetTxns(
      sessionStorage.getItem("email") || "",
      amc_code,
      activePage,
      fund_code?.length > 0 ? fund_code : ""
    )
      .then((response) => {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response?.data?.page_info?.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response?.data?.page_info?.next_page_number);
        }
        setPaginationArr(pageArr);
        // setTransactions(response.data.data);
        setTransactions(
          resetData == "true"
            ? [...response.data.data]
            : [...transactions, ...response.data.data]
        );

        setResLoading(false);
      })
      .catch((err) => {
        setResLoading(false);
      });
    setResLoading(false);
  }

  React.useEffect(() => {
    setResLoading(true);
    activePage ? getTransactions("") : getTransactions("true");
    setResLoading(false);
  }, [activePage]);

  React.useEffect(() => {
    getFunds();
    getTransactions("true");
  }, [fund_code]);

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const searchTransactionsByAMCAndFund = async (
    statusVar = "",
    searchVar = "",
    amcCodeVar = "",
    fundCodeVar = ""
  ) => {
    setTogglePagination(true);
    return new Promise(async (resolve, reject) => {
      if (searchVar !== "") {
        searchVar = "TXN_" + searchVar;
      }
      setResLoading(true);
      setSearchLoading(true);
      await searchTransactions(
        sessionStorage.getItem("email") || "",
        amc_code,
        fundCodeVar ? fundCodeVar : fund_code?.length > 0 ? fund_code : "",
        statusVar === "-1" ? "" : statusVar,
        searchVar,
        activePage
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            // let pageArr = paginationArr

            paginationArr = paginationArr.filter(function (item) {
              return item == "";
            });
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions(response.data.data);
          } else {
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);

          resolve("Success");
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          reject("Error");
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };

  const clearFilters = async (
    statusVar = undefined as any,
    searchVar = undefined as any,
    pageNumber: string = ""
  ) => {
    return new Promise(async (resolve, reject) => {
      // setLoading(true);
      setResLoading(true);
      setClearLoading(true);
      await getAllPendingTransactions(
        sessionStorage.getItem("email") || "",
        statusVar === undefined ? status : statusVar,
        searchVar === undefined ? searchValue : searchVar,
        "",
        activePage,
        "",
        fund_code?.length > 0 ? fund_code : "",
        amc_code
      )
        .then((response) => {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10 &&
            10 * pageArr.length !==
              parseInt(response.data.page_info.total_count)
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          setTransactions(response.data.data);
          setResLoading(false);
          setClearLoading(false);
          resolve("Success");
          setTogglePagination(false); // false search pagination.
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!");
          }
          reject("Error");
        });
      setResLoading(false);
      setClearLoading(false);
    });
  };

  const getFunds = async () => {
    try {
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  // useEffect(() => {

  //   getFunds();
  // }, []);

  const [transactionLedgers, setTransactionLedgers] = useState<any>([]);
  const [totalDebit, setTotalDebit] = useState("0");
  const [totalCredit, setTotalCredit] = useState("0");
  const [balance, setBalance] = useState("0");

  const onLoadData = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      // if(response.data.status==200){
      let ledgersacc = await getLedgerAccounts(
        chartOfAccounts[0].family[3].sub_family
      );
      setLegderAccounts(ledgersacc);
      let allLedgers = await getAllLedgers(chartOfAccounts);
      setAllLedgers(allLedgers);
      // }else{
      //   toast.error(response.data.message);
      // }
    } catch (error) {}

    // get transactions ledgers
    try {
      const response = await getTransactionsLedgers(email);
      if (response.data.status == 200) {
        let investmentTxn = response.data.data.txn_catagory[2].investmentTxn;
        let txnLedgers = await getLedgersByTxn(
          investmentTxn,
          "corporate_announcements"
        );
        let firstLegLedgers = txnLedgers.filter((items: any) => {
          return (
            (items.gl_name == "DIVIDEND RECEIVABLE ON EQUITY SCRIPTS" &&
              items.type == "Credit") ||
            (items.gl_name == "WHT ON DIVIDEND INCOME" &&
              items.type == "Debit") ||
            (items.family_name == "BANK BALANCES" && items.type == "Debit")
          );
        });
        setTransactionLedgers(firstLegLedgers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    chartOfAccounts?.length > 0 && onLoadData();
  }, [chartOfAccounts]);

  // Fund Type Drop Down
  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let [searchValue, setSearchValue] = useState("");

  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  let txnTypes = useSelector((state: any) => state.txnTypes);
  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  // const renderFundAccounts = (type) => {
  //   return (
  //     ledgerAccounts &&
  //     ledgerAccounts
  //       .filter((item) => item.plsName == type)
  //       .map((item: any, index: number) => {
  //         return (
  //           <option key={index} value={item.code}>
  //             {item.account_title} ({item.account_no})
  //           </option>
  //         );
  //       })
  //   );
  // };

  const renderFundAccounts = () => {
    let PLS_ACCOUNT = ledgerAccounts
      ? ledgerAccounts
          .filter((item) => item.plsName == "PLS ACCOUNTS")
          .map((item: any, index: number) => {
            return {
              value: item?.code,
              label: `${item.account_title} (${item.account_no})`,
            };
          })
      : null;
    let CURRENT_ACCOUNTS = ledgerAccounts
      ? ledgerAccounts
          .filter((item) => item.plsName == "CURRENT ACCOUNTS")
          .map((item: any, index: number) => {
            return {
              value: item?.code,
              label: `${item.account_title} (${item.account_no})`,
            };
          })
      : null;
    return PLS_ACCOUNT && CURRENT_ACCOUNTS
      ? [
          {
            label: "PLS ACCOUNTS",
            options: PLS_ACCOUNT,
          },
          {
            label: "CURRENT ACCOUNTS",
            options: CURRENT_ACCOUNTS,
          },
        ]
      : PLS_ACCOUNT
      ? [
          {
            label: "PLS ACCOUNTS",
            options: PLS_ACCOUNT,
          },
        ]
      : CURRENT_ACCOUNTS
      ? [
          {
            label: "CURRENT ACCOUNTS",
            options: CURRENT_ACCOUNTS,
          },
        ]
      : null;
  };

  const setFundAccountFieldsData = (selected) => {
    ledgerAccounts?.map((item, index) => {
      if (item?.code?.toString() === selected?.toString()) {
        transactionLedgers.map((items: any) => {
          if (
            items.amount_type == "Net Amount" &&
            items.family_name == "BANK BALANCES"
          ) {
            setBankGL(item.code.toString());
            items.gl_code = +item.code;
            items.gl_name = item.bank.toString();
            items.sub_family_code = +item.plsCode;
            items.sub_family_name = item.plsName.toString();
            items.fund_account = selected;
            items.account_title = item.account_title;
            items.account_number = item.account_no;
            items.bank_code = item.bank.toString();
            items.branch = item.branch;
          }
          items.fund_code = selectedTransaction.fund_code;
          items.amc_code = selectedTransaction.amc_code;
          items.description =
            items.amount_type +
            " " +
            selectedTransaction.symbol +
            " " +
            selectedTransaction.detail;

          items.instruction_date = selectedTransaction.instruction_date;
          items.instrument_by = "";
          items.instrument_date = selectedTransaction.instrument_date;
          items.instrument_no = selectedTransaction.instrument_no;
          items.instrument_type = selectedTransaction.instrument_type;
          items.period = "2021-2022";
        });
      }
    });
  };

  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);

  const [gl_code, setGlCode] = useState("");

  const [actionModal, setActionModal] = useState(false);

  const renderAnnouncementDividend = () => {
    switch (actionModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              // setViewAddModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  clearFields();
                  setActionModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Corporate Action </h6>
            </div>

            <div className="modal-body">
              <div className="">
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">
                          Corporate Action
                        </h4>
                      </div>
                      <div className="card-body">
                        <Row>
                          <Col>
                            <div className="form-group">
                              Bank Account*
                              <Select
                                value={selectedFundAmount}
                                label={"Select Bank Account"}
                                onChange={(e) => {
                                  setGlCode(e?.value);
                                  setFundAccountFieldsData(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderFundAccounts()}
                                styles={customStyles}
                              />
                              {/* <select
                                className="form-control"
                                value={gl_code}
                                onChange={(e) => {
                                  setGlCode(e.target.value);
                                  setFundAccountFieldsData(e.target.value);
                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {" "}
                                  Select Bank Account
                                </option>
                                <optgroup
                                  style={{ color: "black" }}
                                  label="PLS Account"
                                ></optgroup>
                                {renderFundAccounts("PLS ACCOUNTS")}
                                <optgroup
                                  style={{ color: "black" }}
                                  label="Current Account"
                                ></optgroup>
                                {renderFundAccounts("CURRENT ACCOUNTS")}
                              </select> */}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Ledger</th>
                                  <th></th>
                                  <th className="text-right">Debit Amount</th>
                                  <th className="text-right">Credit Amount</th>
                                  <th>Type</th>
                                  <th>Ledger Family</th>
                                </tr>
                              </thead>
                              <tbody>
                                {transactionLedgers.map((item: any) => {
                                  if (
                                    item.gl_name == "WHT ON DIVIDEND INCOME"
                                  ) {
                                    return (
                                      <tr>
                                        <td>
                                          <label>
                                            {item.gl_name} <br />
                                            {item.gl_code == "" ? (
                                              ""
                                            ) : (
                                              <small className="text-muted">
                                                ({item.gl_code})
                                              </small>
                                            )}
                                          </label>
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            placeholder="%"
                                            value={percentage}
                                            className="form-control w-50"
                                            onChange={(e) => {
                                              setPercentage(e.target.value);
                                              updateValue(e.target.value);
                                            }}
                                          />{" "}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.debit_amount)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.credit_amount)}
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.main_family_name}</td>
                                      </tr>
                                    );
                                  } else {
                                    return (
                                      <tr>
                                        <td>
                                          <label>
                                            {item.gl_name} <br />
                                            {item.gl_code == "" ? (
                                              ""
                                            ) : (
                                              <small className="text-muted">
                                                ({item.gl_code})
                                              </small>
                                            )}
                                          </label>
                                        </td>
                                        <td></td>
                                        <td className="text-right">
                                          {numberWithCommas(item.debit_amount)}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(item.credit_amount)}
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.main_family_name}</td>
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td
                                    style={{
                                      color: theme == "light" ? "black" : "",
                                    }}
                                  >
                                    {" "}
                                    Total Debit:{" "}
                                    <span className="text-primary">
                                      {totalDebit}
                                    </span>
                                  </td>
                                  <td></td>
                                  <td
                                    style={{
                                      color: theme == "light" ? "black" : "",
                                    }}
                                  >
                                    {" "}
                                    Total Credit:{" "}
                                    <span className="text-primary">
                                      {totalCredit}
                                    </span>
                                  </td>
                                  <td></td>
                                  <td
                                    style={{
                                      color: theme == "light" ? "black" : "",
                                    }}
                                  >
                                    {" "}
                                    Difference:{" "}
                                    <span className="text-primary">
                                      {balance}
                                    </span>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className=" mt-3">
                  <button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      saveLedgers();
                    }}
                    disabled={Boolean(loading)}
                  >
                    {loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const setDataFunction = (transaction) => {
    let totaldebit = 0,
      totalcredit = 0;
    transactionLedgers.map((itm: any) => {
      if (
        itm.gl_name == "DIVIDEND RECEIVABLE ON EQUITY SCRIPTS" &&
        itm.type == "Credit" &&
        itm.amount_type == "Gross Amount"
      ) {
        itm.debit_amount = "0";
        itm.credit_amount = transaction.gross_amount;
      }
      if (
        itm.family_name == "BANK BALANCES" &&
        itm.type == "Debit" &&
        itm.amount_type == "Net Amount"
      ) {
        itm.debit_amount = transaction.net_amount;
        itm.credit_amount = "0";
      }
      if (itm.type == "Debit") {
        totaldebit += +itm.debit_amount;
      }
      if (itm.type == "Credit") {
        totalcredit += +itm.credit_amount;
      }
    });

    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
  };

  const updateValue = (value) => {
    value = value == "" ? "0" : value;
    let totaldebit = 0,
      totalcredit = 0;
    let grossAmount = selectedTransaction.gross_amount;
    let debitAmount = "0";
    transactionLedgers.map((itm: any) => {
      if (itm.gl_name == "WHT ON DIVIDEND INCOME" && itm.type == "Debit") {
        debitAmount = ((parseFloat(value) / 100) * parseFloat(grossAmount))
          .toFixed(2)
          .toString();
        setDividendTaxAmount(debitAmount);
        itm.debit_amount = debitAmount;
        itm.credit_amount = "0";
      }
      if (
        itm.family_name == "BANK BALANCES" &&
        itm.type == "Debit" &&
        itm.amount_type == "Net Amount"
      ) {
        itm.debit_amount = (parseFloat(grossAmount) - parseFloat(debitAmount))
          .toFixed(2)
          .toString();
        itm.credit_amount = "0";
      }
      if (itm.type == "Debit") {
        totaldebit += +itm.debit_amount;
      }
      if (itm.type == "Credit") {
        totalcredit += +itm.credit_amount;
      }
    });

    setTotalDebit(totaldebit.toFixed(2).toString());
    setTotalCredit(totalcredit.toFixed(2).toString());
    setBalance((+totaldebit - +totalcredit).toFixed(2).toString());
  };

  const saveLedgers = async () => {
    if (percentage == "") {
      return toast.error(`Wht on Dividend Income % is Empty`);
    }
    if (+balance != 0) {
      return toast.error(`Balance is not {0}, you can't save`);
    }
    if (bankGL == "") {
      return toast.error(`Bank Account Ledger is not Selected!`);
    }
    try {
      transactionLedgers.map((item: any) => {
        return (item.period = sessionStorage.getItem("active_period") || "");
      });
      setLoading(true);
      let obj = {
        email: email,
        txn_id: txn_id.replace("TXN_", ""),
        tax_on_dvidend: percentage,
        tax_wht_it: dividendTaxAmount,
        ledgers: transactionLedgers,
      };
      const response = await saveCorporateAnouncement(email, obj);
      if (response.data.status == 200) {
        toast.success(response.data.message);
        setActionModal(false);
        getTransactions("true");
        setPercentage("");
        setDividendTaxAmount("");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const clearFields = () => {
    setBankGL("");
    setGlCode("");
    setPercentage("");
    setDividendTaxAmount("");
    setTxnId("");
    setDividendTaxAmount("");
    setSelectedTransaction([]);
    transactionLedgers.map((items: any) => {
      if (
        items.amount_type == "Net Amount" &&
        items.family_name == "BANK BALANCES"
      ) {
        items.gl_code = 0;
        items.gl_name = "";
        items.sub_family_code = 0;
        items.sub_family_name = "";
        items.fund_account = "";
        items.account_title = "";
        items.account_number = "";
        items.bank_code = "";
        items.branch = "";
        //  items.debit_amount='0';
      }
    });
  };

  const selectedStatus = React.useMemo(() => {
    const selectedFund = statusArr?.find((item) => item?.value === status);
    return selectedFund
      ? {
          value: selectedFund.value,
          label: selectedFund.label,
        }
      : null;
  }, [status]);

  const selectedFund = React.useMemo(() => {
    const selectedFund = allFunds?.find((item) => item?.symbol_code === fund);
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} - ${selectedFund.symbol_code}`,
        }
      : null;
  }, [allFunds, fund]);

  const selectedFundAmount = React.useMemo(() => {
    const selectedFund =
      ledgerAccounts && ledgerAccounts?.find((item) => item?.code === gl_code);
    return selectedFund
      ? {
          value: selectedFund.code,
          label: `${selectedFund.account_title} (${selectedFund.account_no})`,
        }
      : null;
  }, [ledgerAccounts, gl_code]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="card ">
                <div className="card-header">
                  <h4 className="card-title text-primary">Corporate Actions</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2">
                      <ReactTooltip
                        textColor="white"
                        backgroundColor="black"
                        effect="float"
                      />
                      <div className="form-group">
                        <label>Transaction ID</label>
                        <input
                          type="search"
                          id="myInput"
                          placeholder="Transaction Id"
                          data-tip="Search Transaction"
                          className="form-control"
                          value={searchValue}
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="form-group ml-2 mr-2">
                        <label>Select Fund</label>
                        <Select
                          value={selectedFund}
                          label={"Select Fund"}
                          onChange={(e) => {
                            setFund(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderFundsDropDown()}
                          styles={customStyles}
                        />
                        {/* <select
                          className={"form-control"}
                          value={fund}
                          onChange={(e) => {
                            setFund(e.target.value);
                          }}
                        >
                          <option value="" defaultChecked hidden>
                            Select Fund
                          </option>
                          {renderFundsDropDown()}
                        </select> */}
                      </div>
                    </div>

                    <div className="col-md-2 ">
                      <div className="form-group">
                        <label>Select Status</label>
                        <Select
                          value={selectedStatus}
                          label={"Select type"}
                          onChange={(e) => {
                            setStatus(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={statusArr}
                          styles={customStyles}
                        />
                        {/* <select
                          className="form-control ml-2"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                          value={status}
                        >
                          <option value="-1">Select type</option>
                          <option value="CREATED">Created</option>
                          <option value="COMPLETED">Completed</option>
                          <option value="REJECTED">Rejected</option>
                          <option value="REJECTED-AMC">Rejected-Amc</option> */}
                        {/* <option value="COMPLIANCE">Compliance</option>
                          <option value="AUTHORIZER-A">Authorizer A</option>
                          <option value="AUTHORIZER-B">Authorizer B</option>
                          <option value="INREVIEW">In Review</option>
                          <option value="SIGNATORY-A">Signatory A</option>
                          <option value="SIGNATORY-B">Signatory B</option>
                          <option value="COMPLETED">Completed</option>
                          <option value="REJECTED">Rejected</option> */}
                        {/* </select> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className=" inline-box">
                        <button
                          className="btn btn-primary mt-4 ml-2"
                          disabled={Boolean(searchLoading)}
                          onClick={() => {
                            if (
                              (searchValue || status !== "-1" || fund) !== ""
                            ) {
                              searchTransactionsByAMCAndFund(
                                status,
                                searchValue,
                                "",
                                fund
                              ).then(() => {
                                setActivePage("");
                              });
                            }
                          }}
                        >
                          {searchLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Searching...</span>
                            </>
                          ) : (
                            "Search"
                          )}
                        </button>

                        <button
                          className="btn btn-default ml-2 mt-4 ml-2"
                          disabled={Boolean(clearLoading)}
                          style={{
                            width: "148px",
                          }}
                          onClick={() => {
                            clearFilters("", "").then(() => {
                              setStatus("-1");
                              setSearchValue("");
                              setActivePage("");
                              setFund("");
                            });
                          }}
                        >
                          {clearLoading ? (
                            <>
                              <span
                                className="spinner-border login-txt spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="login-txt"> Clear</span>
                            </>
                          ) : (
                            "Clear"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    {resLoading ? (
                      <div className="d-flex justify-content-center my-5">
                        <i
                          className="fa fa-spinner fa-spin fa-3x"
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : transactions.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table mt-2">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Transaction</th>
                              <th>Fund</th>
                              <th>Amount</th>
                              <th>Bank</th>
                              <th>Counter Acc</th>
                              <th>Instrument</th>
                              <th>Created</th>
                              <th>Status</th>
                              <th>Act</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map(
                              (transaction: any, index: number) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {moment(
                                        transaction.instruction_date
                                      ).format("DD-MM-YYYY")}{" "}
                                      <br />
                                      <span className="sub-content">
                                        Exe:{" "}
                                        {moment(
                                          transaction.execution_date
                                        ).format("DD-MM-YYYY")}
                                      </span>
                                    </td>

                                    <td>
                                      {transaction.txnr_type
                                        ? `${CapTxnType(
                                            transaction.txnr_type
                                          )}	${
                                            transaction.detail?.includes(
                                              "Reversed"
                                            )
                                              ? " (Reversed)"
                                              : ""
                                          }`
                                        : "N/A"}
                                      <br />{" "}
                                      <span className="sub-content">
                                        {transaction.txn_id.replace("TXN_", "")}
                                      </span>
                                    </td>

                                    <td>
                                      {transaction.fund_code} <br />
                                      {/* <span className="sub-content">
                                        {transaction.amc_code
                                          ? transaction.amc_code
                                          : "N/A"}{" "}
                                      </span> */}
                                    </td>

                                    <td className="text-left pr-3">
                                      {numberWithCommas(transaction.net_amount)}{" "}
                                      <br />
                                      <span className="sub-content">
                                        {numberWithCommas(
                                          transaction.gross_amount
                                        )}
                                      </span>
                                    </td>

                                    <td>
                                      {transaction.bank}
                                      <br />
                                      <span className="sub-content">
                                        {transaction.account_number}
                                      </span>
                                    </td>

                                    <td>
                                      {transaction.counter_bank} <br />
                                      <span className="sub-content">
                                        {transaction.counter_account_number}
                                      </span>
                                    </td>

                                    <td>
                                      {transaction.mode_of_payment} <br />
                                      <span className="sub-content">
                                        {transaction.instrument_type}{" "}
                                        {transaction.instrument_no !== ""
                                          ? transaction.instrument_no + ","
                                          : ""}{" "}
                                        {moment(
                                          transaction.instrument_date
                                        ).format("DD-MM-YYYY")}
                                      </span>
                                    </td>

                                    <td>
                                      {moment(transaction.created_at).format(
                                        "DD-MM-YYYY"
                                      )}{" "}
                                      <br />
                                      <span className="sub-content">
                                        {transaction.created_by.split("@")[0]}
                                      </span>
                                    </td>
                                    <td>{transaction.txn_status}</td>
                                    <td className="">
                                      <ReactTooltip
                                        textColor="white"
                                        backgroundColor="black"
                                        effect="float"
                                      />
                                      <RiCheckboxMultipleLine
                                        data-tip={"Realized In Bank"}
                                        className={"ml-1 text-primary"}
                                        onClick={() => {
                                          // setledgerId(item.Record.ledger_id.replace('ID_',''));
                                          // setRealized(item.Record.realized);
                                          // if(item.Record.realized=='true'){
                                          //   setRealize(true);
                                          // }else{
                                          //   setRealize(false);
                                          // }
                                          setDataFunction(transaction);
                                          setTxnId(transaction.txn_id);
                                          setSelectedTransaction(transaction);

                                          setActionModal(true);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="table-empty">
                        <BsTable className="icon" />
                        <p className="text-center empty-text">
                          No record found
                        </p>
                      </div>
                    )}

                    {/* {togglePagination===true ? ( */}
                    <div className="row justify-content-center pointer">
                      {/* PREVIOUS BUTTON */}
                      {/* {activePage !== paginationArr[0] ? (
                        <i
                          className="fa fa-arrow-left"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex(
                              (x) => x === activePage
                            );
                            if (index !== -1) {
                              setActivePage(paginationArr[index - 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ""
                      )} */}
                      {/* NEXT BUTTON */}
                      {/* {activePage !==
                      paginationArr[paginationArr.length - 1] ? (
                        <i
                          className="fa ml-3 fa-arrow-right"
                          aria-hidden="true"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex(
                              (x) => x === activePage
                            );
                            if (index !== -1) {
                              setActivePage(paginationArr[index + 1]);
                            }
                          }}
                        ></i>
                      ) : (
                        ""
                      )} */}
                      <button
                        aria-hidden={true}
                        className="btn btn-primary btn-sm mt-2 py-2 px-3"
                        disabled={
                          activePage ==
                            paginationArr[paginationArr.length - 1] ||
                          resLoading
                        }
                        type="button"
                        onClick={() => {
                          let pageArr = paginationArr;
                          let index = pageArr.findIndex(
                            (x) => x === activePage
                          );
                          if (index !== -1) {
                            setActivePage(paginationArr[index + 1]);
                          }
                        }}
                      >
                        {resLoading ? (
                          <span>
                            Loading...{" "}
                            <i
                              className="fa fa-spinner fa-spin  "
                              aria-hidden="true"
                            ></i>
                          </span>
                        ) : (
                          "Show More"
                        )}
                      </button>
                    </div>
                    {/* ):(
                  ''
                )}	 */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
        {renderAnnouncementDividend()}
      </div>
    </>
  );
};
export default CorporateAnnouncementTxnList;

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Button,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemText } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

interface View {
  name: string;
}

interface Feature {
  views?: View[];
}

const HomePage = () => {
  const theme: any = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const features = JSON.parse(sessionStorage.getItem("allRoutes") || "[]");
  const history = useHistory();
  const context = useContext(ThemeContext);

  const txnTypeCatagory = useSelector((state: any) => state.txnTypeCategories);
  const [createTransactionModal, setCreateTransactionModal] = useState(false);
  const [PATH, setPATH] = useState("");
  const [searchVal, setSearchVal] = useState<string>("");
  const [allfeatures, setAllfeatures] = useState<any>(features);
  const [expandCollapseCard, setExpandCollapseCard] = useState(() => {
    const initialState = {};
    features.forEach((ele) => {
      if (ele.name !== "Home") {
        initialState[ele.name] = false;
      }
    });
    return [initialState];
  });

  const borderStylingSymbolInput = {
    borderRadius: "50px",
  };
  const borderHeading = {
    borderBottom: `1px solid ${
      theme == "dark" ? "rgba(238, 234, 234, 0.1)" : "rgba(0,0,0,0.1)"
    }`,
  };

  const IconsArray = {
    "Funds Management": {
      Dashboard: "tim-icons icon-app ",
      Transactions: "tim-icons icon-bank ",
      "Create Transactions": "tim-icons icon-coins ",
      Funds: "tim-icons icon-chart-pie-36 ",
      "Bank Accounts": "tim-icons icon-bank ",
      "Bank Statement": "tim-icons icon-attach-87 ",
    },
    "Unit Management": {
      Dashboard: "tim-icons icon-app ",
      Transactions: "tim-icons icon-bank ",
      "Create Transactions": "tim-icons icon-coins ",
      "Unit Holders": "tim-icons icon-atom",
      "Unit Holder Statement": "tim-icons icon-attach-87",
      "Potential Investors": "tim-icons icon-single-02",
    },
    "Trade Settlement": {
      Dashboard: "tim-icons icon-app ",
      Transactions: "tim-icons icon-bank ",
      "Create Transactions": "tim-icons icon-coins ",
      "Daily Settlements": "tim-icons icon-chart-bar-32",
      "Transactions Report": "tim-icons icon-single-copy-04",
    },
    Portfolios: {
      Dashboard: "tim-icons icon-app ",
      "Portfolio Summary": "tim-icons icon-single-copy-04",
      Securities: "tim-icons icon-key-25",
      "Corporate Actions": "tim-icons icon-paper ",
      "Dividend Announcements": "tim-icons icon-alert-circle-exc ",
      "Capital Gain Tax": "tim-icons icon-money-coins",
    },
    Accounts: {
      Dashboard: "tim-icons icon-app ",
      Transactions: "tim-icons icon-bank ",
      "Create Transactions": "tim-icons icon-coins ",
      "Chart of Accounts": "tim-icons icon-chart-bar-32",
      "Financial Periods": "tim-icons icon-compass-05",
      "Payment Beneficiaries": "tim-icons icon-money-coins",
      "Payment Receipt Register": "tim-icons icon-bank ",
      "Trial balance": "tim-icons icon-money-coins",
      "Voucher Report": "tim-icons icon-paper",
      "Trial Balance": "tim-icons icon-money-coins",
      "Bank Accounts": "tim-icons icon-money-coins",
      Banks: "tim-icons icon-bank ",
      "Bank Branches": "tim-icons icon-bell-55 ",
    },
    Transactions: {
      Dashboard: "tim-icons icon-app ",
      "Create Transactions": "tim-icons icon-bank ",
      Authorizer: "tim-icons icon-check-2",
      "Pending Approval": "tim-icons icon-bell-55",
      "Recent Transactions": "tim-icons icon-badge",
      Adjustments: "tim-icons icon-coins",
    },
    "Net Asset Value": {
      Dashboard: "tim-icons icon-app ",
      "Daily Nav Update": "tim-icons icon-chart-bar-32",
      "Daily MM Securities": "tim-icons icon-key-25",
      "Daily Fund Portfolio": "tim-icons icon-chart-pie-36",
      "Fund Portfolio Report": "tim-icons icon-paper",
      "NAV Analysis Report": "tim-icons icon-notes",
      "Daily Market Summary": "tim-icons icon-paper ",
    },
    Configurations: {
      Banks: "tim-icons icon-bank",
      Sales: "tim-icons icon-money-coins",
      "Tax Rates": "tim-icons  icon-coins",
      "Approving Checklists": "tim-icons icon-check-2",
      "Role Management": "tim-icons icon-pencil",
      "User Management": "tim-icons icon-single-02",
    },
  };

  const renderTransactionTypes = () => {
    switch (createTransactionModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            onHide={() => {
              setCreateTransactionModal(false);
              setPATH("");
            }}
          >
            <div className={context.theme}>
              <div className="modal-header ">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setCreateTransactionModal(false);
                    setPATH("");
                  }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Create new transaction</h6>
              </div>
              <Row className="mt-4">
                <Col md="12">
                  <Container fluid>
                    {txnTypeCatagory.length > 0 ? (
                      PATH == "transactions-unit-management" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                                  Unit Management
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[0].unitManagementTxn
                                  ?.filter(
                                    (item) =>
                                      item.dis_name != "Conversion In" &&
                                      item.dis_name != "Conversion Out"
                                  )
                                  .map((item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                          if (
                                            item.api_name === "unitconversion"
                                          ) {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              "Payment"
                                            );
                                          } else {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                          }
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/unit-management-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-accounts" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                                  Accounting
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[4].vouchersTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/vouchers/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-trade-settlement" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                                  Investments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[2].investmentTxn.map(
                                  (item: any, index: number) => {
                                    if (
                                      item.api_name ==
                                      "right_share_subscription"
                                    ) {
                                      return (
                                        <MenuItem
                                          style={{
                                            minHeight: "0px", // mobile view
                                          }}
                                          key={index}
                                          className="box-element"
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                            clearTransactiionHistory();
                                            history.push(
                                              `/admin/right-share-subscription/${item.api_name}`
                                            );
                                          }}
                                        >
                                          <span className="text-hover text-silver">
                                            <span
                                              className={item.api_name + " ico"}
                                            ></span>
                                            <i className="fa fa-angle-right"></i>{" "}
                                            {item.dis_name}
                                          </span>
                                        </MenuItem>
                                      );
                                    } else {
                                      return (
                                        <MenuItem
                                          style={{
                                            minHeight: "0px", // mobile view
                                          }}
                                          key={index}
                                          className="box-element"
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                            clearTransactiionHistory();
                                            history.push(
                                              `/admin/investment-transaction/${item.api_name}`
                                            );
                                          }}
                                        >
                                          <span className="text-hover text-silver">
                                            <span
                                              className={item.api_name + " ico"}
                                            ></span>
                                            <i className="fa fa-angle-right"></i>{" "}
                                            {item.dis_name}
                                          </span>
                                        </MenuItem>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-funds-management" ? (
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                                      Fund Management
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[1].fundManagementTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/fund-management-transaction/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    {/* <MenuItem
                            style={{
                              minHeight: "0px", // mobile view
                            }}
                            className="box-element"
                            key="4"
                            onClick={() => {
                              history.push(`/admin/fund-dividend-payout`);
                              // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                              // clearTransactiionHistory();
                            }}
                          >
                            <span className="text-hover text-silver">
                              <span className={" ico"}></span>
                              <i className="fa fa-angle-right"></i>{" "}
                              {"Fund Dividend Payout"}
                            </span>
                          </MenuItem> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                                      Portfolio
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[5].portfolio.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/portfolio/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                                  Fee Payments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[3].feePaymentTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/fee-payment-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-transactions" ? (
                        <Row className="col-12 pl-2 pr-0">
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                                  Unit Management
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[0].unitManagementTxn
                                  ?.filter(
                                    (item) =>
                                      item.dis_name != "Conversion In" &&
                                      item.dis_name != "Conversion Out"
                                  )
                                  .map((item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                          if (
                                            item.api_name === "unitconversion"
                                          ) {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              "Payment"
                                            );
                                          } else {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                          }
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/unit-management-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                                      Investments
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[2].investmentTxn.map(
                                      (item: any, index: number) => {
                                        if (
                                          item.api_name ==
                                          "right_share_subscription"
                                        ) {
                                          return (
                                            <MenuItem
                                              style={{
                                                minHeight: "0px", // mobile view
                                              }}
                                              key={index}
                                              className="box-element"
                                              onClick={() => {
                                                sessionStorage.setItem(
                                                  "payment_type",
                                                  `${item.payment_type}`
                                                );
                                                clearTransactiionHistory();
                                                history.push(
                                                  `/admin/right-share-subscription/${item.api_name}`
                                                );
                                              }}
                                            >
                                              <span className="text-hover text-silver">
                                                <span
                                                  className={
                                                    item.api_name + " ico"
                                                  }
                                                ></span>
                                                <i className="fa fa-angle-right"></i>{" "}
                                                {item.dis_name}
                                              </span>
                                            </MenuItem>
                                          );
                                        } else {
                                          return (
                                            <MenuItem
                                              style={{
                                                minHeight: "0px", // mobile view
                                              }}
                                              key={index}
                                              className="box-element"
                                              onClick={() => {
                                                sessionStorage.setItem(
                                                  "payment_type",
                                                  `${item.payment_type}`
                                                );
                                                clearTransactiionHistory();
                                                history.push(
                                                  `/admin/investment-transaction/${item.api_name}`
                                                );
                                              }}
                                            >
                                              <span className="text-hover text-silver">
                                                <span
                                                  className={
                                                    item.api_name + " ico"
                                                  }
                                                ></span>
                                                <i className="fa fa-angle-right"></i>{" "}
                                                {item.dis_name}
                                              </span>
                                            </MenuItem>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                                      Fund Management
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[1].fundManagementTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/fund-management-transaction/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    {/* <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.push(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                                  Fee Payments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[3].feePaymentTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/fee-payment-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <Row>
                              <Col>
                                <div
                                  className="card"
                                  style={{ minWidth: "105%" }}
                                >
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                                      Accounting
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[4].vouchersTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/vouchers/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div
                                  className="card"
                                  style={{ minWidth: "105%" }}
                                >
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                                      Portfolio
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[5].portfolio.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/portfolio/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row></Row>
                      )
                    ) : (
                      ""
                    )}
                  </Container>
                </Col>
              </Row>
            </div>
          </Modal>
        );
      default:
        return null;
    }
  };

  const clearTransactiionHistory = () => {
    sessionStorage.removeItem("rejectedTxObj");
    sessionStorage.removeItem("rejectedTxName");
    sessionStorage.removeItem("rejectedFlag");
    sessionStorage.removeItem("rejectedAmc");
  };

  const [screen, setScreen] = useState<any>();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Container fluid>
            <ToastContainer limit={1} />
            <div className="card">
              <div
                style={{ gap: "5px" }}
                className="card-body d-flex justify-content-between flex-wrap"
              >
                <div className="input-group mb-0 position-relative col-lg-4 col-md-5 col-sm-12 col-12 px-0">
                  <input
                    // id={"onhover"}
                    style={borderStylingSymbolInput}
                    type="text"
                    value={searchVal}
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => {
                      let value = e.target.value?.trim();
                      setSearchVal(e.target.value);
                      if (value) {
                        const filteredFeatures: Feature[] = [];
                        features.forEach((ele) => {
                          const matchedViews =
                            ele?.views?.filter((ele1) => {
                              return ele1?.name
                                ?.toLowerCase()
                                .includes(value.toLowerCase());
                            }) || [];

                          if (matchedViews.length > 0) {
                            filteredFeatures.push({
                              ...ele,
                              views: matchedViews,
                            });
                          }
                        });
                        setAllfeatures(filteredFeatures);
                      } else {
                        setAllfeatures(features);
                      }
                    }}
                  />
                  <div
                    style={{
                      right: "31px",
                      top: "11px",
                      cursor: "pointer",
                    }}
                    className="position-absolute"
                  >
                    <IoSearchOutline fontSize={20}> </IoSearchOutline>
                  </div>
                </div>
                <div className={screen <= 500 ? "col-12 px-0" : ""}>
                  {Object.keys(expandCollapseCard[0]).filter(
                    (key) => expandCollapseCard[0][key] === true
                  )?.length > 0 && (
                    <button
                      className={
                        screen <= 500
                          ? "btn btn-primary col-12 px-0"
                          : "btn btn-primary px-3"
                      }
                      onClick={() => {
                        setExpandCollapseCard(() => {
                          const initialState = {};
                          features.forEach((ele) => {
                            if (ele.name !== "Home") {
                              initialState[ele.name] = false;
                            }
                          });
                          return [initialState];
                        });
                      }}
                    >
                      <IoIosArrowDown /> All
                      {/* Expand All */}
                    </button>
                  )}
                  {Object.keys(expandCollapseCard[0]).filter(
                    (key) => expandCollapseCard[0][key] === false
                  )?.length > 0 && (
                    <button
                      className={
                        screen <= 500
                          ? "btn btn-primary col-12 px-0 ml-0"
                          : "btn btn-primary px-3"
                      }
                      onClick={() => {
                        setExpandCollapseCard(() => {
                          const initialState = {};
                          features.forEach((ele) => {
                            if (ele.name !== "Home") {
                              initialState[ele.name] = true;
                            }
                          });
                          return [initialState];
                        });
                      }}
                    >
                      <IoIosArrowUp /> All
                      {/* Collapse All */}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {allfeatures
              ?.filter((item) => item?.name != "Home")
              ?.map((item, index) => {
                return (
                  <Row key={index}>
                    <Col>
                      <div className="card">
                        <div
                          style={borderHeading}
                          className="card-header d-flex justify-content-between"
                        >
                          <h4 className="card-title homepage-card-heading">
                            <i className={item.icon + " mr-2 text-primary"}></i>{" "}
                            {item.name}
                          </h4>
                          <div>
                            {expandCollapseCard[0]?.[item?.name] ? (
                              <IoIosArrowDown
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Expand"
                                size={25}
                                className="text-primary"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setExpandCollapseCard((prev) => [
                                    {
                                      ...prev[0],
                                      [item?.name]: false,
                                    },
                                  ]);
                                }}
                              />
                            ) : (
                              <IoIosArrowUp
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Collapse"
                                className="text-primary"
                                size={25}
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setExpandCollapseCard((prev) => [
                                    {
                                      ...prev[0],
                                      [item?.name]: true,
                                    },
                                  ]);
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {!expandCollapseCard[0]?.[item?.name] && (
                          <div
                            className={
                              screen <= 467
                                ? `card-body row justify-content-center `
                                : "card-body row justify-content-start"
                            }
                          >
                            {item?.views?.map((item2, index2) => {
                              return (
                                <div
                                  style={{ width: "150px" }}
                                  key={index2}
                                  className="mx-3"
                                >
                                  <ListItemText
                                    onClick={() => {
                                      if (item2.name == "Create Transactions") {
                                        sessionStorage.removeItem("Txn1");
                                        sessionStorage.removeItem("Txn2");
                                        setPATH(
                                          item2.path?.replace("/create-", "")
                                        );
                                        setCreateTransactionModal(true);
                                      } else {
                                        history.push(`/admin${item2.path}`);
                                      }
                                    }}
                                    primary={
                                      <i
                                        className={
                                          " text-primary mb-2 iconSizeFix " +
                                          (IconsArray?.[item.name]?.[
                                            item2.name
                                          ] || " tim-icons icon-notes")
                                        }
                                      />
                                    }
                                    secondary={
                                      <span style={{ wordBreak: "break-word" }}>
                                        {item2.name}
                                      </span>
                                    }
                                    sx={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </Container>
        </Col>
      </Row>

      {renderTransactionTypes()}
    </div>
  );
};

export default HomePage;

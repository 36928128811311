import React from 'react';
import { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, Link } from 'react-router-dom'
import { addChecklist } from '../../../stores/services/checklist.service';
import { getAllRoles } from '../../../stores/services/role.service';
import { useSelector } from 'react-redux';
import Select from "react-select";

const SetupCheckList = () => {

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  //hooks for getting all inputs from user 
  const [serialNo, setSerialNo] = useState('');
  const [title, setTitle] = useState('');
  const [role, setRole] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  //error getting hooks 
  const [serialNoError, setSerialNoError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [roleError, setRoleError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState<any>([]);

  React.useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await getAllRoles(email, amc_code);
        setRoleData(response.data.data);
      } catch (error) { }
    };
    fetchRole()
  }, [])

  // const renderRoleDropdown = () => {
  //   return (
  //     roleData &&
  //     roleData.map((item, index) => {
  //       return (
  //         <option key={index} value={item.role_name.replace('ROLE_','')}>
  //           {item.role_name.replace('ROLE_','')}
  //         </option>
  //       );
  //     })
  //   );
  // };
  const renderRoleDropdown = () => {
    return (
      roleData &&
      roleData.map((item, index) => {
        return {
          value: item.role_name.replace('ROLE_', ''),
          label: item.role_name.replace('ROLE_', '')
        }
      })
    );
  };


  const validate = () => {
    let serialErr = '';
    let titleErr,
      roleErr = '';
    serialNo.trim() === '' ? (serialErr = 'Required') : (serialErr = '');
    title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    role.trim() === '' ? (roleErr = 'Required') : (roleErr = '');
    if (serialErr || titleErr || roleErr) {
      setSerialNoError(serialErr);
      setTitleError(titleErr);
      setRoleError(roleErr);
      return false;
    } else {
      return true;
    }
  };
  const AddChecklist = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await addChecklist(email, serialNo, title, role, amc_code);
        if (response.data.status === 200) {
          // setSerialNo('');
          // setTitle('');
          // setRole('')
          toast.success(response.data.message);

          setTimeout(() => {
            history.replace(`/admin/check-list`);
          }, 2000);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response.data.message);
      }

    }
  };

  const selectedRole = React.useMemo(() => {
    return role
      ? {
        value: role,
        label: role
      }
      :
      null;
  }, [role]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header title-row">
                  <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                    history.replace('/admin/check-list')
                  }}></i>Setup - Check List</h4>

                  <Link to="/admin/check-list" className="btn btn-primary btn-sm ml-auto" replace>
                    <i className="fa fa-eye mr-2"></i>View All
                  </Link>

                </div>

                <div className="card-body">
                  <Row >
                    <Col md="6">
                      <div className="form-group">
                        <label>Title <span className="color-amber">*</span></label>
                        <input type="text" className={`form-control  w-100 ${titleError ? 'required-border' : ''}`} value={title} onChange={(e) => {
                          setTitle(e.target.value);
                          setTitleError('');
                        }}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Role <span className="color-amber">*</span></label>
                        <Select
                          className={`${roleError ? 'required-border' : ''}`}
                          value={selectedRole}
                          label={"Select Role"}
                          onChange={(e) => {
                            setRole(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderRoleDropdown()}
                          styles={customStyles}
                        />
                        {/* <select className={`form-control  w-100 ${roleError ? 'required-border' : ''}`}
                    value={role} onChange={(e) => {
                      setRole(e.target.value)
                    }}
                  >
                    <option value="" defaultChecked hidden> Select Role</option>
                    {renderRoleDropdown()}
                  </select> */}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Serial.# / Sequence # (Number) <span className="color-amber">*</span></label>
                        <input type="text" className={`form-control  w-100 ${serialNoError ? 'required-border' : ''}`} value={serialNo} onChange={(e) => {
                          setSerialNo(e.target.value);
                          setSerialNoError('');
                        }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

                <div>
                  <button
                    className="btn btn-primary"
                    onClick={AddChecklist}
                    disabled={Boolean(Loading)}
                  >
                    {Loading ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default SetupCheckList;
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { getFundByAmc } from "../stores/services/funds.service";
import { getNewChartsOfAccountsByFund } from "../stores/services/chatofaccounts.service";
import { getAllLedgers, getLedgerAccounts } from "../utils/customFunction";

import { getDailyBankBalance } from "../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { numberWithCommas } from "./../utils/customFunction";
import { ThemeContext } from "../contexts/ThemeContext";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getNavDataForCalculation } from "../stores/services/nav.service";
import { getAccountByAmcGlCode } from "../stores/services/account.service";
import NumberFormat from "react-number-format";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyTrusteeFeePayment = (props: any) => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const context = useContext(ThemeContext);
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [selectedBank, setSelectedBank] = useState("");

  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  let [ledgerTableData, setLedgerTableData] = useState<any>([]);

  let [managementTableData, setManagementTableData] = useState<any>([]);

  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);
  const [settlementDate, setSettlementDate] = useState<any>(
    moment(today, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD")
  );
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");
  const [deleting, setDeleting] = useState<any>(false);
  const [netAssets, setNetAssets] = useState<any>("");
  const [trusteeFeeLedgers, setTrusteeFeeLedgers] = useState<any>([]);
  const [trusteeSpecificLedger, setTrusteeSpecificLedger] = useState<any>([]);
  const [trusteeTaxLedger, setTrusteeTaxLedger] = useState<any>([]);

  const [managementSpecificLedgerDebit, setManagementSpecificLedgerDebit] =
    useState<any>([]);
  const [managementSpecificLedgerCredit, setManagementSpecificLedgerCredit] =
    useState<any>([]);
  const [managementTaxLedgerDebit, setManagementTaxLedgerDebit] = useState<any>(
    []
  );
  const [managementTaxLedgerCredit, setManagementTaxLedgerCredit] =
    useState<any>([]);

  const [
    accountingOperationalSpecificLedgerDebit,
    setAccountingOperationalSpecificLedgerDebit,
  ] = useState<any>([]);
  const [
    accountingOperationalSpecificLedgerCredit,
    setAccountingOperationalSpecificLedgerCredit,
  ] = useState<any>([]);

  const [accountingOperationalTableData, setAccountingOperationalTableData] =
    useState<any>([]);

  const [trusteeFee, setTrusteeFee] = useState<any>("");
  let [trusteeFeeTax, setTrusteeFeeTax] = useState<any>(0.0);

  const [managementFee, setManagementFee] = useState<any>("");
  let [managementFeeTax, setManagementFeeTax] = useState<any>(0.0);

  const [accountingOperationalFee, setAccountingOperationalFee] =
    useState<any>("");

  let [taxRate, setTaxRate] = useState<any>(0.0);
  let [managementTaxRate, setManagementTaxRate] = useState<any>(0.0);

  let [secpFee, setSecpFee] = useState<any>(0);
  const [secpLedgerDebit, setSecpLedgerDebit] = useState<any>([]);
  const [secpLedgerCredit, setSecpLedgerCredit] = useState<any>([]);
  const [secpTableData, setSecpTableData] = useState<any>([]);
  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "postnavpayments",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "",
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(settlementDate).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {
        console.log("ERROR => ", error);
      }
    };

    getFundByAMcCode(amc_code);
  }, []);

  const renderFundAccounts = () => {
    let PLS_ACCOUNT = ledgerAccounts
      ? ledgerAccounts
          .filter((item) => item.plsName == "PLS ACCOUNTS")
          .map((item: any, index: number) => {
            return {
              value: item?.code,
              label: `${item.account_title} (${item.account_no})`,
            };
          })
      : null;
    let CURRENT_ACCOUNTS = ledgerAccounts
      ? ledgerAccounts
          .filter((item) => item.plsName == "CURRENT ACCOUNTS")
          .map((item: any, index: number) => {
            return {
              value: item?.code,
              label: `${item.account_title} (${item.account_no})`,
            };
          })
      : null;

    return PLS_ACCOUNT && CURRENT_ACCOUNTS
      ? [
          {
            label: "PLS ACCOUNTS",
            options: PLS_ACCOUNT,
          },
          {
            label: "CURRENT ACCOUNTS",
            options: CURRENT_ACCOUNTS,
          },
        ]
      : PLS_ACCOUNT
      ? [
          {
            label: "PLS ACCOUNTS",
            options: PLS_ACCOUNT,
          },
        ]
      : CURRENT_ACCOUNTS
      ? [
          {
            label: "CURRENT ACCOUNTS",
            options: CURRENT_ACCOUNTS,
          },
        ]
      : null;
  };

  const getBankAccounts = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      const response = await getNewChartsOfAccountsByFund(
        email,
        sessionStorage.getItem("amc_code") || "",
        fund
      );
      if (response.data.status == 200) {
        let ledgersacc = getLedgerAccounts(
          response.data.data.main_family[3].family[0].sub_family
        );
        let ledgersacc2 = getLedgerAccounts(
          response.data.data.main_family[2].family[1].sub_family
        );

        setManagementSpecificLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010101;
          })
        );

        setManagementTaxLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010102;
          })
        );

        setSecpLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010501;
          })
        );

        setAccountingOperationalSpecificLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401011001;
          })
        );

        let trusteeLedger = ledgersacc.filter((item) => {
          return item.plsCode === 4010102;
        });

        setTrusteeSpecificLedger(
          trusteeLedger.filter((item) => {
            return item.name === "TRUSTEE REMUNERATION EXPENSE";
          })
        );

        setTrusteeTaxLedger(
          trusteeLedger.filter((item) => {
            return item.name === "SALES TAX ON TRUSTEE REMUNERATION EXPENSE";
          })
        );
        let banksacc = getLedgerAccounts(
          response.data.data.main_family[0].family[3].sub_family
        );

        setLegderAccounts([...ledgersacc, ...ledgersacc2, ...banksacc]);

        let allLedgers = await getAllLedgers(response.data.data.main_family);
        setAllLedgers(allLedgers);
        // await ledgerDropDown(allLedgers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  useEffect(() => {
    if (fund) {
      getBankAccounts();
    }
  }, [fund]);

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code}) - SMA`,
        };
      } else {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code})`,
        };
      }
    });
  };

  const handleLoadSlabAsset = async () => {
    try {
      setTableData([]);
      setLedgerTableData([]);

      let temp: any = [];

      let selectedBankLedgerDetails = await getLedgerInfo(email, bank, fund);

      const trusteeLedgerCodeResponse = await getLedgerInfoByCode(
        email,
        trusteeSpecificLedger[0].code,
        fund
      );
      let trusteeLedgerAccounts = await getAccountByAmcGlCode(
        email,
        amc_code,
        trusteeSpecificLedger[0].code,
        fund
      );

      const newTrusteeTableData = {
        ...trusteeLedgerAccounts.data.data[0],
        gl_code: trusteeLedgerCodeResponse.data.data.gl_code,
        gl_name: trusteeLedgerCodeResponse.data.data.gl_name,
      };

      temp.push(newTrusteeTableData);

      const trusteeTaxLedgerCodeResponse = await getLedgerInfoByCode(
        email,
        trusteeTaxLedger[0].code,
        fund
      );

      let trusteeTaxLedgerAccounts = await getAccountByAmcGlCode(
        email,
        amc_code,
        trusteeTaxLedger[0].code,
        fund
      );

      const newTrusteeTaxData = {
        ...trusteeTaxLedgerAccounts.data.data[0],
        gl_code: trusteeTaxLedgerAccounts.data.data.gl_code,
        gl_name: trusteeTaxLedgerAccounts.data.data.gl_name,
      };

      temp.push(newTrusteeTaxData);

      let managementSpecificLedgerDebitDetails = await getAccountByAmcGlCode(
        email,
        amc_code,
        managementSpecificLedgerDebit.code,
        fund
      );
      let managementTaxLedgerDebitDetails = await getAccountByAmcGlCode(
        email,
        amc_code,
        managementSpecificLedgerDebit.code,
        fund
      );

      temp.push({
        ...managementSpecificLedgerDebitDetails.data.data[0],
        gl_code: managementSpecificLedgerDebit.code,
        gl_name: managementSpecificLedgerDebit.name,
      });
      temp.push({
        ...managementTaxLedgerDebitDetails.data.data[0],
        gl_code: managementTaxLedgerDebit.code,
        gl_name: managementTaxLedgerDebit.name,
      });

      let secpDebitLedgerDetails = await getAccountByAmcGlCode(
        email,
        amc_code,
        secpLedgerDebit.code,
        fund
      );

      temp.push({
        ...secpDebitLedgerDetails.data.data,
        gl_code: secpLedgerDebit.code,
        gl_name: secpLedgerDebit.name,
      });

      let accountingOperationalTableData = await getAccountByAmcGlCode(
        email,
        amc_code,
        secpLedgerDebit.code,
        fund
      );
      temp.push({
        ...accountingOperationalTableData.data.data,
        gl_code: accountingOperationalSpecificLedgerDebit.code,
        gl_name: accountingOperationalSpecificLedgerDebit.name,
      });

      let ledgerTableDataTemp: any = [];

      for (let i = 0; i < temp.length; i++) {
        ledgerTableDataTemp.push(
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: temp[i].child_family_code,
            child_family_name: temp[i].child_family_code,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            debit_amount: "0",
            credit_amount: temp[i].balance_amount,
            description: "Expense Payment",
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: temp[i].family_code,
            family_name: temp[i].family_name,
            fund_code: fund,
            gl_code: temp[i].gl_code,
            gl_name: temp[i].gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: temp[i].main_family_code,
            main_family_name: temp[i].main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: temp[i].sub_family_code,
            sub_family_name: temp[i].sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
            associated_ledger_code: "",
            type: "Credit",
          },
          {
            account_number: "",
            account_title: "",
            amc_code: amc_code,
            balance: "",
            bank_code: "",
            branch: "",
            child_family_code: selectedBankLedgerDetails.child_family_code,
            child_family_name: selectedBankLedgerDetails.child_family_code,
            counter_account_number: "",
            counter_account_title: "",
            counter_bank: "",
            counter_branch: "",
            created_at: "",
            debit_amount: temp[i].balance_amount,
            credit_amount: "0",
            description: "Expense Payment",
            doc_type: "",
            entered_by: "",
            evidence: "",
            family_code: selectedBankLedgerDetails.family_code,
            family_name: selectedBankLedgerDetails.family_name,
            fund_code: fund,
            gl_code: selectedBankLedgerDetails.gl_code,
            gl_name: selectedBankLedgerDetails.gl_name,
            instruction_date: "",
            instrument_by: "",
            instrument_date: "",
            instrument_no: "",
            instrument_type: "",
            main_family_code: selectedBankLedgerDetails.main_family_code,
            main_family_name: selectedBankLedgerDetails.main_family_name,
            realized: "true",
            realized_by: "",
            realized_date: "",
            sub_family_code: selectedBankLedgerDetails.sub_family_code,
            sub_family_name: selectedBankLedgerDetails.sub_family_name,
            period: sessionStorage.getItem("active_period") || "",
            associated_ledger_code: temp[i].gl_code,
            type: "Debit",
          }
        );
      }
      setLedgerTableData(ledgerTableDataTemp);
      setTableData(temp);
      setLoading(false);
    } catch (error: any) {
      console.log("ERROR => ", error);
      toast.error(error.message);
      setTableData([]);
      setLoading(false);
    }
  };

  const handleDailyTrusteeFee = async () => {
    setLoading(true);
    let email = sessionStorage.getItem("email");
    for (let i = 0; i < ledgerTableData.length; i++) {
      let data = ledgerTableData[i];

      if (
        (data.debit_amount === 0 || parseFloat(data.debit_amount) === 0) &&
        (data.credit_amount === 0 || parseFloat(data.credit_amount) === 0)
      ) {
        continue;
      }
      sendingLedgers.push(data);
      fields.execution_date = moment(date).format("YYYY-MM-DD");
      fields.instruction_date = moment(date).format("YYYY-MM-DD");
      fields.executed_date = moment(date).format("YYYY-MM-DD");
      fields.instrument_date = moment(date).format("YYYY-MM-DD");
      fields.realized_date = moment(date).format("YYYY-MM-DD");
      fields.sale_date = moment(date).format("YYYY-MM-DD");
      fields.issue_date = moment(date).format("YYYY-MM-DD");
      fields.conversion_date = moment(date).format("YYYY-MM-DD");
      fields.maturity_date = moment(date).format("YYYY-MM-DD");
      fields.redemption_date = moment(date).format("YYYY-MM-DD");
      fields.settlement_date = moment(date).format("YYYY-MM-DD");
      fields.last_coupon_date = moment(date).format("YYYY-MM-DD");
      fields.next_coupon_date = moment(date).format("YYYY-MM-DD");
      if (data.debit_amount && data.debit_amount > 0)
        fields["net_amount"] = (
          parseFloat(fields.net_amount ? fields.net_amount : "0") +
          parseFloat(data.debit_amount ? data.debit_amount : "0")
        ).toFixed(2);
    }

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "postnavpayments";
      fields.net_amount = fields.net_amount.toString();
      fields.payment_type = "Payment";
      if (fields.ledgers.length > 1) {
        try {
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            toast.success(`Transaction added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              history.goBack();
            }, 1000);
          }
        } catch (error) {
          console.log("ERROR => ", error);
          setLoading(false);
        }
      }
    }

    setProfitRateConfirmed(true);
  };

  const handleDelete = (id) => {
    setDeleting(true);
    setTableData(
      tableData.filter((data) => {
        return data.id !== id;
      })
    );

    toast.info("Expense Removed!!!");
    setTimeout(() => setDeleting(false), 100);
  };

  const getLedgerInfo = async (email, ledger_code, fund_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code, fund_code);
    return result.data.data;
  };

  const handleTaxChange = () => {
    trusteeFeeTax = parseFloat(trusteeFee) * (parseFloat(taxRate) / 100);
    setTrusteeFeeTax(trusteeFeeTax);
  };

  const handleManagementTaxChange = () => {
    let manFeeTax =
      parseFloat(managementFee) * (parseFloat(managementTaxRate) / 100);
    setManagementFeeTax(manFeeTax);
  };

  useEffect(() => {
    if (trusteeFee && taxRate) {
      handleTaxChange();
    } else {
      setTrusteeFeeTax(0);
    }
  }, [taxRate, trusteeFee]);

  useEffect(() => {
    if (managementFee && managementTaxRate) {
      handleTaxChange();
    } else {
      setManagementFeeTax(0);
    }
  }, [managementTaxRate, managementFee]);

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund && selectedFund.nature === "Special Managed Account"
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code}) - SMA`,
        }
      : selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <ToastContainer limit={1} />
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        history.goBack();
                      }}
                    ></i>
                    Post NAV payments
                  </h4>
                </div>
              </div>
              <Row>
                <Col md="12">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fund Details</h4>
                    </div>
                    <div className="card-body d-flex align-items-center row">
                      <div
                        className="form-group col-md-4"
                        data-tip="Select Fund"
                      >
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <Select
                            disabled={false}
                            value={selectedFundName}
                            label={fundCheck ? "No Fund Found" : "Select Fund"}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setFund(e?.value);
                              setLoading(false);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropdown()}
                            styles={customStyles}
                          />
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <div>Fund Account*</div>

                        <Select
                          value={selectedBank}
                          label={"Select Fund Account"}
                          onChange={(e) => {
                            fields["fund_account"] = e?.value.toString();
                            setFields(fields);
                            setTableData([]);
                            setLedgerTableData([]);
                            setBank(e?.value);
                            setSelectedBank(e);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderFundAccounts()}
                          styles={customStyles}
                        />
                      </div>
                      <div
                        className="form-group col-md-4"
                        data-tip="Select Date"
                      >
                        Date
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <input
                            id="input_Data"
                            className="form-control input_date"
                            type="date"
                            value={date}
                            defaultValue={date}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setDate(e.target.value);
                              setSettlementDate(e.target.value);

                              setLoading(false);
                            }}
                            disabled={!fund}
                          />
                        )}
                      </div>

                      <Row>
                        <div className="col-md-6 mx-2 w-full">
                          <button
                            className="btn btn-default"
                            disabled={Boolean(Loading) || !fund || !date}
                            onClick={() => {
                              setLoading(true);
                              handleLoadSlabAsset();
                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Load</span>
                            )}
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : tableData && tableData.length !== 0 ? (
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">
                          Affected Ledgers
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th className="text-right">Payable Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((data, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={
                                      deleting
                                        ? {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(76,88,119,0.1)",
                                          }
                                        : {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(37,41,61,1)",
                                          }
                                    }
                                  >
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>

                                    <td align="right">
                                      <NumberFormat
                                        thousandSeparator={true}
                                        inputMode="numeric"
                                        placeholder="Accrued Payments"
                                        value={data.balance_amount}
                                        className="form-control w-75"
                                        style={{ textAlign: "right" }}
                                        onChange={(e) => {
                                          let temp = [...tableData];
                                          let ledgerTemp = [...ledgerTableData];
                                          ledgerTemp.map((it, ind) => {
                                            if (
                                              it.gl_code ===
                                                temp[index].gl_code ||
                                              it.associated_ledger_code ===
                                                temp[index].gl_code
                                            ) {
                                              if (it.type === "Debit") {
                                                it.debit_amount =
                                                  e.target.value.replaceAll(
                                                    ",",
                                                    ""
                                                  );
                                              } else {
                                                it.credit_amount =
                                                  e.target.value.replaceAll(
                                                    ",",
                                                    ""
                                                  );
                                              }
                                            }
                                          });
                                          temp[index].balance_amount =
                                            e.target.value;
                                          setTableData(temp);
                                          // setLedgerTableData(ledgerTemp);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {!Loading && ledgerTableData && ledgerTableData.length > 0 ? (
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">Ledgers</h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Code</th>
                                <th>Ledger</th>
                                <th className="text-right">Debit Amount</th>
                                <th className="text-right">Credit Amount</th>
                                <th>Type</th>
                                <th>Ledger Family</th>
                              </tr>
                            </thead>
                            <tbody>
                              {ledgerTableData.map((data, index) => (
                                <tr key={data.gl_code}>
                                  <td>{data.gl_code}</td>
                                  <td>{data.gl_name}</td>
                                  <td align="right">
                                    {numberWithCommas(data.debit_amount)}
                                  </td>
                                  <td align="right">
                                    {numberWithCommas(data.credit_amount)}
                                  </td>
                                  <td>{data.type}</td>

                                  <td>{data.main_family_name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}

              {!Loading &&
              tableData &&
              tableData.length > 0 &&
              ledgerTableData &&
              ledgerTableData.length > 0 ? (
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(Loading)}
                      onClick={() => handleDailyTrusteeFee()}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Pay Fees</span>
                      )}
                    </button>
                  </div>
                </div>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyTrusteeFeePayment;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { Container, Row, Col } from "reactstrap";
import ToggleButton from "react-toggle-button";
import { useHistory } from "react-router-dom";
import {
  enableDisableTwoFaAdmin,
  getAllUsersPaginate,
  updateUserStatus,
} from "../../stores/services/user.service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { getFundByAmc } from "../../stores/services/funds.service";
import Select from "react-select";
import {
  getAllRoles,
  getAssignTransaction,
} from "../../stores/services/role.service";
import { useSelector } from "react-redux";

const UsersList = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  let [users, setUsers] = useState<any>([]);
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const department = sessionStorage.getItem("department") || "";
  const allDefaultDepartments =
    JSON.parse(sessionStorage.getItem("defaultDepartments") || "[]") || [];
  const email = sessionStorage.getItem("email") || "";
  const [Loading, setLoading] = useState(false);
  const [selectedFund, setSelectedFund] = useState<any>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<any>({});
  const [selectedDepartmentRole, setSelectedDepartmentRole] = useState<any>({});
  const [searchName, setSearchName] = useState("");
  const [selectedRole, setSelectedRole] = useState<any>({});
  const [searchEmail, setSearchEmail] = useState("");
  const [allFund, setAllFund] = useState<any>([]);
  const [allRole, setAllRole] = useState<any>([]);

  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [currentUserStatus, setCurrentUserStatus] = useState("");
  let [activePage, setActivePage] = useState("");
  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");
  const borderRadiusStyle = { borderRadius: 2 };

  const getAllFunds = async () => {
    try {
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFund(response.data.data);
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      }
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await getAllRoles(email, amc_code);
        setAllRole(response.data.data);
      } catch (err: any) {
        if (err.response !== undefined) {
          toast.error(err.response.data.message);
        }
      }
    };

    getRoles();
    // getAllFunds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getUserList();
    activePage ? getUserList("") : getUserList("true");
  }, [activePage]);

  React.useEffect(() => {
    getAllFunds();
    getUserList("true");
  }, [fund_code]);

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const getUserList = async (resetData: string = "") => {
    setLoading(true);

    try {
      const response = await getAllUsersPaginate(
        email,
        activePage,
        pageSize,
        amc_code,
        selectedFund?.length > 0
          ? selectedFund
          : fund_code?.length > 0
          ? fund_code
          : "",
        department
          ? department
          : selectedDepartment?.value
          ? selectedDepartment?.value
          : "",
        selectedDepartmentRole?.value ? selectedDepartmentRole?.value : "",
        searchName?.trim(),
        selectedRole?.value ? selectedRole?.value : "",
        searchEmail
      );
      if (response.data.status == 200) {
        // setUsers(response.data.data);
        setUsers(
          resetData == "true"
            ? [...response.data.data]
            : [...users, ...response.data.data]
        );

        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.nextPage
        );
        if (existingPageIndex === -1 && response.data.data.length === 10) {
          pageArr.push(response.data.page_info.nextPage);
        }
        setPaginationArr(pageArr);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      setUsers([]);
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message);
      }
    }
  };

  const getUserListUpdated = async (resetData: string = "") => {
    setLoading(true);
    setSearchEmail("");
    setSearchName("");
    setSelectedFund({});
    setSelectedDepartment({});
    setSelectedDepartmentRole({});
    setSelectedRole({});
    // setActivePage("1")
    try {
      // const response = await getAllUsersPaginate(email, activePage, pageSize, amc_code, fund_code ? fund_code : "", department ? department : "", "", "", "", "");
      const response = await getAllUsersPaginate(
        email,
        "1",
        pageSize,
        amc_code,
        fund_code ? fund_code : "",
        department ? department : "",
        "",
        "",
        "",
        ""
      );
      if (response.data.status == 200) {
        // setUsers(response.data.data);
        setUsers(
          resetData == "true"
            ? [...response.data.data]
            : [...users, ...response.data.data]
        );

        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.nextPage
        );
        if (existingPageIndex === -1 && response.data.data.length === 10) {
          pageArr.push(response.data.page_info.nextPage);
        }
        setPaginationArr(pageArr);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message);
      }
    }
  };

  const updateStatus = async () => {
    setUpdateStatusLoading(true);
    let status = "";
    if (currentUserStatus === "active") {
      status = "deactive";
    } else {
      status = "active";
    }
    try {
      const response = await updateUserStatus(email, userEmail, status);
      toast.success(response.data.message);
      setStatusSetPopup(false);
      setUpdateStatusLoading(false);
      setTimeout(() => {
        getUserListUpdated("true");
      }, 3000);
    } catch (error: any) {
      toast.error(error?.response?.data.message);
      setUpdateStatusLoading(false);
    }
  };

  const renderUpdateStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal60w"
            show={true}
            enforceFocus={false}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setStatusSetPopup(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Action</h6>
            </div>

            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentUserStatus === "active" ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{" "}
                    {currentUserStatus === "active"
                      ? "Deactivate"
                      : "Activate"}{" "}
                    this User?
                  </div>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(updateStatusLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {updateStatusLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderData = () => {
    return users.map((user: any, index: any) => {
      return (
        <tr key={index}>
          <td>{user.name}</td>
          <td>{user.role.replace("ROLE_", "")}</td>
          <td>{user.email}</td>
          <td>{moment(user.created_at).format("DD-MM-YYYY")}</td>
          <td className="captilize">{user.status}</td>
          <td className=" ">
            <div className="multi-icons pointer">
              <button
                type="button"
                className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={() => {
                  sessionStorage.setItem("userObj", JSON.stringify(user));

                  sessionStorage.setItem("userPageType", "view");
                  history.replace("/admin/edit-user");
                }}
              >
                <i className="fas fa-eye"></i>
              </button>

              <button
                type="button"
                className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem("userObj", JSON.stringify(user));

                  sessionStorage.setItem("userPageType", "edit");
                  history.replace("/admin/edit-user");
                }}
              >
                <i className="tim-icons icon-pencil"></i>
              </button>

              {user.status !== "active" ? (
                <button
                  type="button"
                  className="btn-icon btn-link like btn btn-danger btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setCurrentUserStatus(user.status);
                    setUserEmail(user.email);
                  }}
                >
                  <i className="tim-icons icon-simple-remove"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-icon btn-link like btn btn-success btn-sm"
                  onClick={() => {
                    setStatusSetPopup(true);
                    setCurrentUserStatus(user.status);
                    setUserEmail(user.email);
                  }}
                >
                  <i className="fas fa-check"></i>
                </button>
              )}
            </div>
          </td>
          <td>
            <ToggleButton
              inactiveLabel=""
              activeLabel=""
              value={user.two_fa_enabled === "true" ? true : false}
              thumbStyle={borderRadiusStyle}
              trackStyle={borderRadiusStyle}
              onToggle={() => {
                onClickToggle(
                  user.email,
                  user.two_fa_enabled == "true" ? "false" : "true"
                );
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const onClickToggle = (user_email: string, isTwoFaEnabled: string) => {
    enableDisableTwoFaAdmin(email, user_email, isTwoFaEnabled)
      .then((response: any) => {
        toast.success(response.data.message);
        const index = users.findIndex((i: any) => i.email === user_email);
        const list = [...users];
        if (index >= 0) {
          list[index].two_fa_enabled = isTwoFaEnabled;
          setUsers(list);
        }
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  };

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    User Management
                    <button
                      className="btn btn-primary btn-sm float-right"
                      onClick={() => {
                        history.replace("/admin/add-user");
                      }}
                    >
                      <i className="fa fa-plus mr-2"></i> Add New
                    </button>
                  </h4>
                </div>
                {!Loading ? (
                  <div className="card-body">
                    <Row>
                      <Col md="4">
                        <div className="form-group">
                          <label>Name</label>
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          <input
                            style={{
                              paddingTop: "6px",
                              paddingBottom: "6px",
                            }}
                            type="search"
                            id="myInput"
                            className="form-control"
                            data-tip="Search User"
                            placeholder="Search Name"
                            value={searchName}
                            onChange={(e) => {
                              setSearchName(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group">
                          <label>Email</label>
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          <input
                            style={{
                              paddingTop: "6px",
                              paddingBottom: "6px",
                            }}
                            type="search"
                            id="myInput"
                            className="form-control"
                            data-tip="Search User"
                            placeholder="Search Email"
                            value={searchEmail}
                            onChange={(e) => {
                              setSearchEmail(e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className="form-group  ">
                          <label>Select Fund</label>
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={"Select"}
                            isMulti={true}
                            onChange={(e) => {
                              setSelectedFund(e);
                            }}
                            value={selectedFund}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={allFund.map((item) => {
                              return {
                                value: item.symbol_code,
                                label: item.fund_name,
                              };
                            })}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            styles={customStyles}
                          />
                        </div>
                      </Col>

                      {!department && (
                        <Col md="4">
                          <div className="form-group  ">
                            <label>Select Department</label>
                            <Select
                              className="react-select info "
                              classNamePrefix="react-select"
                              label={"Select"}
                              // isDisabled={false}
                              onChange={(e) => {
                                //e.preventDefault();
                                setSelectedDepartment(e?.value ? e : {});
                              }}
                              value={
                                selectedDepartment?.value
                                  ? [selectedDepartment]
                                  : []
                              }
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={allDefaultDepartments.map((item) => {
                                return {
                                  value: item.department,
                                  label: item.department,
                                  departmentRoles: item.departmentRoles,
                                };
                              })}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              styles={customStyles}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="4">
                        <div className="form-group  ">
                          <label>Select Department Role</label>
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={"Select"}
                            // isDisabled={false}
                            onChange={(e) => {
                              //e.preventDefault();
                              setSelectedDepartmentRole(e?.value ? e : {});
                            }}
                            value={
                              selectedDepartmentRole?.value
                                ? [selectedDepartmentRole]
                                : []
                            }
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={
                              selectedDepartment?.value
                                ? selectedDepartment?.departmentRoles?.map(
                                    (item) => {
                                      return { value: item, label: item };
                                    }
                                  )
                                : []
                            }
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            styles={customStyles}
                          />
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="form-group  ">
                          <label>Select Role</label>
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={"Select"}
                            // isDisabled={false}
                            onChange={(e) => {
                              //e.preventDefault();
                              setSelectedRole(e?.value ? e : {});
                            }}
                            value={selectedRole?.value ? [selectedRole] : []}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={allRole?.map((item) => {
                              return {
                                value: item.role_name,
                                label: item.role_name,
                              };
                            })}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            styles={customStyles}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div className=" inline-box">
                          <button
                            className="btn btn-primary mt-4 ml-2"
                            disabled={Boolean(Loading)}
                            onClick={() => {
                              if (activePage) {
                                setActivePage("");
                              } else {
                                setActivePage("1");
                              }
                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Searching...</span>
                              </>
                            ) : (
                              "Search"
                            )}
                          </button>

                          <button
                            className="btn btn-default ml-2 mt-4 ml-2"
                            disabled={Boolean(Loading)}
                            style={{
                              width: "148px",
                            }}
                            onClick={() => {
                              getUserListUpdated("true");
                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Clear</span>
                              </>
                            ) : (
                              "Clear"
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <table className="table mt-3 ">
                        <thead>
                          <th>Name</th>
                          <th>Roles</th>
                          <th>Email</th>
                          <th>Created At</th>
                          <th>Status</th>
                          <th>Actions</th>
                          <th>2FA Authentication</th>
                        </thead>
                        <tbody>{renderData()}</tbody>
                      </table>

                      <div className="row justify-content-center pointer">
                        {/* PREVIOUS BUTTON */}
                        {/* {activePage !== paginationArr[0] ? (
                                                    <i
                                                        className="fa fa-arrow-left"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            let pageArr = paginationArr;
                                                            let index = pageArr.findIndex((x) => x === activePage);
                                                            if (index !== -1) {
                                                                setActivePage(paginationArr[index - 1]);
                                                            }
                                                        }}
                                                    ></i>
                                                ) : (
                                                    ''
                                                )} */}
                        {/* NEXT BUTTON */}
                        {/* {activePage !== paginationArr[paginationArr.length - 1] ? (
                                                    <i
                                                        className="fa ml-3 fa-arrow-right"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            let pageArr = paginationArr;
                                                            let index = pageArr.findIndex((x) => x === activePage);
                                                            if (index !== -1) {
                                                                setActivePage(paginationArr[index + 1]);
                                                            }
                                                        }}
                                                    ></i>
                                                ) : (
                                                    ''
                                                )} */}
                        <button
                          aria-hidden={true}
                          className="btn btn-primary btn-sm mt-2 py-2 px-3"
                          disabled={
                            activePage ==
                              paginationArr[paginationArr.length - 1] || Loading
                          }
                          type="button"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex(
                              (x) => x === activePage
                            );
                            if (index !== -1) {
                              setActivePage(paginationArr[index + 1]);
                            }
                          }}
                        >
                          {Loading ? (
                            <span>
                              Loading...{" "}
                              <i
                                className="fa fa-spinner fa-spin  "
                                aria-hidden="true"
                              ></i>
                            </span>
                          ) : (
                            "Show More"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div
                      className="fa fa-spinner fa-spin fa-3x mt-5 mb-5"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
                {renderUpdateStatusPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UsersList;

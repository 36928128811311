/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useEffect } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { IoSearchOutline } from "react-icons/io5";
import { IoCaretUp } from "react-icons/io5";
import { IoCaretDown } from "react-icons/io5";
import { useDispatch } from 'react-redux';

// reactstrap components
import { Nav, Collapse, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";

import {
  BackgroundColorContext,
  backgroundColors,
} from "../../contexts/BackgroundColorContext";
import "./Sidebar.css";
import Searchbar from "./Searchbar";
import { setStringValue } from "../../redux/actions/chartOfAccount-Actions";
import { env } from "../../env";

var ps;

const Sidebar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sidebarwidth, setSidebarWidth] = React.useState(0);
  const [screen, setScreen] = React.useState(0);
  const [state, setState] = React.useState({});
  const [sidebarView, setSidebarView] = React.useState(localStorage.getItem("sidebar-view") || "vertical");
  const [showSearchbar, setShowSearchbar] = React.useState(false);
  const [showSubMenu, setShowSubMenu] = React.useState({});
  const sidebarRef = React.useRef(null);
  const location = useLocation();
  React.useEffect(() => {
    setState(getCollapseStates(props.routes));
  }, []);
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const { rtlActive } = props;
    const toggleSubMenu = (name) => {
      const updatedState = { ...state };

      if (updatedState[name]) {
        updatedState[name] = false;
      } else {
        Object.keys(updatedState).forEach(key => {
          updatedState[key] = false;
        });
        updatedState[name] = true;
      }

      setState(updatedState);
    };
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        if (sidebarView == "vertical") {
          var st = {};
          st[prop["state"]] = !state[prop.state];
        }
        return (
          <>
            <li
              style={{
                borderColor: sidebarView == "vertical" ? "rgba(255, 255, 255, 0.5)" : ""
              }}
              className={
                getCollapseInitialState(prop.views) && sidebarView == "horizontal" ?
                  "active" :
                  sidebarView == "horizontal" ? "" :
                    getCollapseInitialState(prop.views) && sidebarView == "vertical" ?
                      "active" :
                      ""}
              key={key}
            >
              {sidebarView == "horizontal" ?
                <div style={{ width: "fit-content" }} className="d-flex position-relative">
                  <div>
                    <a
                      className={sidebarView == "horizontal" ? "horizontal-sidebar-aTag " : ""}
                      href="#pablo"
                      data-toggle="collapse"
                      aria-expanded={state[prop.state]}
                      onClick={(e) => {
                        e.preventDefault();
                        if (sidebarView == "vertical") {
                          setState({ ...state, ...st });
                        }
                        else {
                          toggleSubMenu(prop.name);
                        }
                      }}
                    >
                      {prop.icon !== undefined ? (
                        <>
                          <i className={prop.icon} />
                          <p className={sidebarView == "horizontal" ? "heading_text" : ""}>
                            {rtlActive ? prop.rtlName : prop.name}
                            <b
                              style={{
                                marginTop: sidebarView == "horizontal" ? "1px" : ""
                              }}
                              className="caret" />
                          </p>


                        </>
                      ) : (
                        <>
                          {sidebarView == "vertical" &&
                            <span className="sidebar-mini-icon">
                              {rtlActive ? prop.rtlMini : prop.mini}
                            </span>}
                          <span className="sidebar-normal">
                            {rtlActive ? prop.rtlName : prop.name}
                            <b className="caret" />
                          </span>
                        </>
                      )}
                    </a>
                  </div>
                  {/* {routes?.length - 1 != key && <div className="vertical-divider" />} */}
                </div>
                :
                <a
                  className={sidebarView == "horizontal" ? "horizontal-sidebar-aTag " : ""}
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={state[prop.state]}
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, ...st });
                  }}
                >
                  {prop.icon !== undefined ? (
                    <>
                      <i className={prop.icon} />
                      <p className={sidebarView == "horizontal" ? "text-nowrap " : ""}>
                        {rtlActive ? prop.rtlName : prop.name}
                        <b
                          style={{
                            marginTop: sidebarView == "horizontal" ? "1px" : ""
                          }}
                          className="caret" />
                      </p>

                    </>
                  ) : (
                    <>
                      {sidebarView == "vertical" &&
                        <span className="sidebar-mini-icon">
                          {rtlActive ? prop.rtlMini : prop.mini}
                        </span>}
                      <span className="sidebar-normal">
                        {rtlActive ? prop.rtlName : prop.name}
                        <b className="caret" />
                      </span>
                    </>
                  )}
                </a>
              }

              <Collapse
                style={{
                  position: sidebarView == "horizontal" ?
                    "absolute" :
                    ""
                }}
                isOpen={state[prop.state]}>
                <ul 
                style={BgAccortoThemeHorizontal}
                className={sidebarView == "horizontal" ? "horizontal-subMenuDesign nav" : "nav"}>{createLinks(prop.views)}</ul>
              </Collapse>

            </li>
            {routes?.length - 1 != key && sidebarView == "horizontal" &&
              <li>
                <div className="vertical-divider" />
              </li>}

          </>
        );
      }
      return (
        <>
          <li
            style={{
              borderColor: sidebarView == "vertical" ? "rgba(255, 255, 255, 0.5)" : ""
            }}
            className={sidebarView == "horizontal" ? `${prop.name != "Home" ? "px-2 horizontal-children-li" : ""}` :
              activeRoute(prop.layout + prop.path)
            } key={key}>
            <NavLink
              to={prop.layout + prop.path}
              activeClassName=""
              onClick={props.closeSidebar}
              className={sidebarView == "horizontal" && prop.icon !== undefined ? "horizontal-sidebar-aTag" : ""}
            >
              {prop.icon !== undefined ? (
                <>
                  {
                    sidebarView == "horizontal" ?
                      <div style={{ width: "fit-content" }} className="d-flex position-relative">
                        {/* <div className="vertical-divider_right" /> */}
                        <div className="d-flex flex-column">
                          <i className={prop.icon} />
                          <p className={sidebarView == "horizontal" ? "heading_text" : ""} >{rtlActive ? prop.rtlName : prop.name}</p>
                        </div>
                        {/* <div className="vertical-divider" /> */}
                      </div>
                      :
                      <>
                        <i className={prop.icon} />
                        <p>{rtlActive ? prop.rtlName : prop.name}</p>
                      </>
                  }

                </>
              ) : (
                <>
                  {sidebarView == "vertical" && <span className="sidebar-mini-icon">
                    {rtlActive ? prop.rtlMini : prop.mini}
                  </span>}
                  <span
                    id={sidebarView == "horizontal" && activeRoute(prop.layout + prop.path) == "active" ? "activeStyle" : ""}
                    className={sidebarView == "horizontal" ? "sidebar-normal SubMenuTest" : "sidebar-normal "}>
                    {rtlActive ? prop.rtlName : prop.name}
                  </span>
                </>
              )}
            </NavLink>
          </li>
          {prop.icon !== undefined && sidebarView == "horizontal" &&
            <div className="vertical-divider" />}
        </>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  const { activeColor, logo } = props;
  let logoImg = null;
  let logoText = null;
  if (logo !== undefined) {
    if (logo.outterLink !== undefined) {
      logoImg = (
        <a
          href={logo.outterLink}
          className="simple-text logo-mini"
          target="_blank"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </a>
      );
      logoText = (
        <a
          href={logo.outterLink}
          className="simple-text logo-normal"
          target="_blank"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </a>
      );
    } else {
      logoImg = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-mini"
          onClick={props.closeSidebar}
        >
          <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div>
        </NavLink>
      );
      logoText = (
        <NavLink
          to={logo.innerLink}
          className="simple-text logo-normal"
          onClick={props.closeSidebar}
        >
          {logo.text}
        </NavLink>
      );
    }
  }

  useEffect(() => {
    if (sidebarView == "horizontal") {
      const typeaheadMenus = document.querySelector(".content");
      if (typeaheadMenus) {
        typeaheadMenus.classList.add("Horizontal-Container-Styling");
      }
    }
    else {
      const typeaheadMenus = document.querySelector(".content");
      if (typeaheadMenus) {
        typeaheadMenus.classList.remove("Horizontal-Container-Styling");
      }
    }
    dispatch(setStringValue(sidebarView));
    localStorage.setItem("sidebar-view", sidebarView)
    setState({})
  }, [window.location.href, sidebarView])

  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth)
      if (window?.outerWidth < 1304) {
        setSidebarView("vertical")
        dispatch(setStringValue("vertical"))
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const updateWidth = () => {
      if (sidebarRef.current) {
        setSidebarWidth(sidebarRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(updateWidth);

    if (sidebarRef.current) {
      resizeObserver.observe(sidebarRef.current);
      updateWidth();
    }

    return () => {
      if (sidebarRef.current) {
        resizeObserver.unobserve(sidebarRef.current);
      }
    };
  }, []);


  let themeColor =
    env.REACT_APP_TITLE?.toUpperCase()?.includes("UAT") ?
      "redish" :
      env.REACT_APP_TITLE?.toUpperCase()?.includes("DEV") ?
      "brownish" : ""
  const BgAccortoThemeHorizontal = {
    background: themeColor == "redish" ? "linear-gradient(rgba(102, 51, 0, 1) 0%, rgba(255, 51, 153, 1) 100%)" :
      themeColor == "brownish" ? "linear-gradient(to right, rgb(120 108 95) 0%, rgb(48, 67, 82) 100%)" : ""
  }

  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor }) => (
        <div
          style={BgAccortoThemeHorizontal}
          className={sidebarView == "horizontal" ? "Horizontal-Sidebar" : "sidebar"} data={color}>
          <div className="sidebar-wrapper" ref={sidebarRef}>
            {sidebarView == "vertical" &&
              <div
                className={sidebarwidth <= 80 ? "d-flex justify-content-center " : ""}>
                {screen >= 1304 &&
                  <div
                    className={sidebarView == "vertical" ? "logo" : ""} data-toggle="tooltip" data-placement="top" title={`Switch to ${sidebarView == "vertical" ? "horizontal" : "vertical"}`}
                  >
                    <svg
                      onClick={() => {
                        setSidebarView(prev => prev == "horizontal" ? "vertical" : "horizontal")
                        const typeaheadMenus = document.querySelector(".content");
                        if (typeaheadMenus) {
                          typeaheadMenus.classList.add("Horizontal-Container-Styling");
                        }
                        dispatch(setStringValue(sidebarView));
                      }}
                      className={sidebarView == "horizontal" ? `sidebar-view${themeColor} rotate ` : `sidebar-view${themeColor}`}
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 128 128"
                    >
                      <path
                        fill={themeColor == "redish" ? "white" : "#e14eca"}
                        d="M8 127h112c1.7 0 3-1.3 3-3s-1.3-3-3-3h-7V14c0-1.7-1.3-3-3-3H90c-1.7 0-3 1.3-3 3v107H77V74c0-1.7-1.3-3-3-3H54c-1.7 0-3 1.3-3 3v47H41V64c0-1.7-1.3-3-3-3H18c-1.7 0-3 1.3-3 3v57H8c-1.7 0-3 1.3-3 3s1.3 3 3 3zM93 17h14v104H93V17zM57 77h14v44H57V77zM21 67h14v54H21V67z"
                      ></path>
                    </svg>
                  </div>}
              </div>}
            {sidebarView == "vertical" &&
              <>
                {sidebarwidth <= 80 ?
                  <li className="d-flex justify-content-center mt-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 50 50"
                      style={{
                        cursor: "pointer"
                      }}
                    >
                      <path
                        fill="white"
                        d="M21 3C11.621 3 4 10.621 4 20s7.621 17 17 17c3.71 0 7.14-1.195 9.938-3.219l13.156 13.125 2.812-2.812-13-13.032A16.923 16.923 0 0038 20c0-9.379-7.621-17-17-17zm0 2c8.297 0 15 6.703 15 15s-6.703 15-15 15S6 28.297 6 20 12.703 5 21 5z"
                      ></path>
                    </svg>
                  </li>
                  :
                  <div className="mt-2">
                    <Searchbar view={"sidebar"} />
                  </div>}
              </>
            }

            <Nav

              // style={{ fontFamily: "Roboto Condensed, sans-serif !important" }}
              className={sidebarView == "horizontal" ? "horizontal_ul pr-3" : `${sidebarwidth <= 80 ? "" : "paddingOnVertical"}`}
            >
              {sidebarView == "horizontal" &&
                <>
                  <div
                    style={{
                      height: "fit-content",
                      marginTop: "0.44rem"
                    }}
                    className="d-flex align-items-center">
                    <div
                      data-toggle="tooltip" data-placement="top" title={`Switch to ${sidebarView == "vertical" ? "horizontal" : "vertical"}`}
                    >
                      <svg
                        onClick={() => {
                          setSidebarView(prev => prev == "horizontal" ? "vertical" : "horizontal")
                          if (sidebarView == "horizontal") {
                            const typeaheadMenus = document.querySelector(".content");
                            if (typeaheadMenus) {
                              typeaheadMenus.classList.remove("Horizontal-Container-Styling");
                            }
                          }
                        }}
                        className={sidebarView == "horizontal" ? `sidebar-view${themeColor} rotate mr-3` : `sidebar-view${themeColor}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 128 128"
                      >
                        <path
                          fill={themeColor == "redish" ? "white" : "#e14eca"}
                          d="M8 127h112c1.7 0 3-1.3 3-3s-1.3-3-3-3h-7V14c0-1.7-1.3-3-3-3H90c-1.7 0-3 1.3-3 3v107H77V74c0-1.7-1.3-3-3-3H54c-1.7 0-3 1.3-3 3v47H41V64c0-1.7-1.3-3-3-3H18c-1.7 0-3 1.3-3 3v57H8c-1.7 0-3 1.3-3 3s1.3 3 3 3zM93 17h14v104H93V17zM57 77h14v44H57V77zM21 67h14v54H21V67z"
                        ></path>
                      </svg>
                    </div>
                    <div
                      data-toggle="tooltip" data-placement="top" title="Click to Search"
                    >
                      <svg
                        id="PopoverLegacy"
                        onClick={() => setShowSearchbar(!showSearchbar)}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 50 50"
                        style={{
                          cursor: "pointer"
                        }}
                      >
                        <path
                          fill="white"
                          d="M21 3C11.621 3 4 10.621 4 20s7.621 17 17 17c3.71 0 7.14-1.195 9.938-3.219l13.156 13.125 2.812-2.812-13-13.032A16.923 16.923 0 0038 20c0-9.379-7.621-17-17-17zm0 2c8.297 0 15 6.703 15 15s-6.703 15-15 15S6 28.297 6 20 12.703 5 21 5z"
                        ></path>
                      </svg>
                    </div>
                    <UncontrolledPopover
                      placement="bottom"
                      target="PopoverLegacy"
                      trigger="legacy"
                    >
                      <PopoverBody 
                      className={`px-0 popOver-Style${themeColor}`}>
                        <Searchbar />
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                  <div className="vertical-divider" />
                </>
              }
              {createLinks(props.routes)}
              {sidebarView == "vertical" &&
                <>
                  <div style={{ marginTop: "6rem" }}>
                    <div
                      onClick={() => {
                        history.replace("/admin/home");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {(logoImg !== null || logoText !== null) ? (
                        <div
                          id="logo-After" className="logo">
                          <div
                            className={sidebarwidth <= 80 ? "" : " ml-3 d-flex align-items-center"}>
                            {logoImg}
                            <div style={{ marginBottom: "0.2rem" }}>{logoText}</div>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  </div>
                  <div className="d-flex justify-content-center w-100">
                    <small className={sidebarwidth <= 80 ? "text-white" : "text-white mr-3"}>
                      {env.REACT_APP_VERSION}
                    </small>
                  </div>
                </>
              }
            </Nav>
          </div>
        </div>
      )}
    </BackgroundColorContext.Consumer>
  );
};

Sidebar.propTypes = {
  activeColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  rtlActive: PropTypes.bool,
  routes: PropTypes.array.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.shape({
      innerLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      outterLink: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ]),
  // this is used on responsive to close the sidebar on route navigation
  closeSidebar: PropTypes.func,
};

export default Sidebar;

import React from "react";
import { useState } from "react";
// import Header from './../components/Header';
import { Container, Row, Col, Label, FormGroup, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  fieldData,
  transactionObj,
  fieldValue,
  chechFieldDisplay,
  getCounterAccount,
  checkTaxBoolean,
} from "./field";
import { inWords } from "./../stores/services/template.service";
import { getLedger } from "./../stores/services/transactions.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";

const ViewTxnFields = (props: any) => {
  const history = useHistory();
  const [headLabel, setHeadLabel] = React.useState("");
  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');
  let txnTypes = useSelector((state: any) => state.txnTypes);

  const [systemTaxFlag, setSystemTaxFlag] = useState(false);
  const [systemTax, setSystemTax] = useState<any>();
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [checkChanges, setCheckChanges] = useState("");

  const [realizedCheck, setRealizedCheck] = useState(false);

  const requiredFields = transactionObj(headLabel);
  let counterAccounter = getCounterAccount(headLabel);
  const email = sessionStorage.getItem("email") || "";
  const role_name = sessionStorage.getItem("role") || "";
  const [ledgerData, setLedgerData] = useState<any>([]);
  const [countledgerData, setCountLedgerData] = useState(0);
  const [ledgerLoading, setledgerLoading] = useState(false);
  const [flagForTransferessData, setFlagForTransferessData] = useState(false);
  const [transferessData, setTransferessData] = useState<any>([]);

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  React.useEffect(() => {
    fields["realized"] === "true" || fields["realized"] === true
      ? setRealizedCheck(true)
      : setRealizedCheck(false);

    if (props.data) {
      if (props.flag) {
        const obj = JSON.parse(sessionStorage.getItem("csab-txn") || "");
        setHeadLabel(obj.txnr_type);
        counterAccounter = getCounterAccount(obj.txnr_type);
        setFields(obj);
        if (props.data.txnr_type === "termdeposit") {
          // debugger;
          setTermDeposit(JSON.parse(fields.securities));
        }
      } else {
        setHeadLabel(props.data.txnr_type);
        counterAccounter = getCounterAccount(props.data.txnr_type);
        if (props.data.txnr_type === "termdeposit") {
          setTermDeposit(JSON.parse(fields.securities));
        }
      }
      // if(props.data.txn_status==='COMPLETED'){

      //   const getLederByTxnId = async()=>{
      //     try{
      //     setledgerLoading(true);
      //   const response= await getLedger(email,props.data.txn_id);
      //   ledgerData.length=0;
      //   setLedgerData(ledgerData);
      //   let array = Object.entries(response.data.data);

      //   let data:any=[]
      //   array.forEach(([key, value]) => {
      //     let record:[]=[]
      //     data=value;
      //     record['Record'] = data.Record
      //     record['Record']['ledger_id'] =data.Key
      //     ledgerData.push(record)
      //   })
      //   setCountLedgerData(ledgerData.length)
      //   setledgerLoading(false);
      // }catch(err){
      //   setledgerLoading(false);
      // }
      //   };
      //   getLederByTxnId();
      // }
      if (
        props.data.ledgers == undefined ||
        props.data.ledgers == "" ||
        props.data.ledgers == "[]"
      ) {
        setLedgerData([]);
      } else {
        setLedgerData(JSON.parse(props.data.ledgers));
      }
    }
  }, [props]);

  const [fields, setFields] = useState(props.data);
  // React.useEffect(()=>{
  //   setLedgerData(JSON.parse(props.data.ledgers));
  //  },[])

  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setFlagForSecutities(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Security </h6>
            </div>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Security</h6>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 && (
                  <div className="form-group">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Inv Type</th>
                          <th>Mkt</th>
                          <th>Symbol</th>
                          <th className="text-center">Quantity </th>
                          <th className="text-center">Avg Rate</th>
                          <th className="text-center">Broker </th>
                          <th className="text-center">Comm Rate</th>
                          <th className="text-center">SST On Comm</th>
                          {/* <th className="text-center">Capital Value Tax</th> */}
                          <th className="text-center">Net Rate</th>
                          <th className="text-center">Avg Cost Amt</th>
                          <th className="text-center">Gain/Loss Per Units</th>
                          <th className="text-center">Gain/Loss Amount</th>
                          <th className="text-center">Gross Amount</th>
                          <th className="text-center">Commission</th>
                          <th className="text-center">Net Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderPsxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const [securites, setSecurites] = useState<any>([]);
  const renderPsxData = () => {
    if (securites === "[]") {
      return;
    }
    if (securites === "") {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.investment_type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td className="text-center">
            {numberWithCommas(items.quantity || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(parseFloat(items.avg_rate || "0").toFixed(2))}
          </td>
          <td className="text-center">{items.broker}</td>
          <td className="text-center">
            {numberWithCommas(items.commission_rate || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(items.sst_on_commission || 0)}
          </td>
          {/* <td className="text-center">{items.capital_value_tax}</td> */}
          <td className="text-center">
            {numberWithCommas(items.net_rate || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(items.avg_cost_amount || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(items.gain_loss_per_unit || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(items.gain_loss_amount || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(parseFloat(items.gross_amount).toFixed(2))}
          </td>
          <td className="text-center">
            {numberWithCommas(items.commission_charges || 0)}
          </td>
          <td className="text-center">
            {numberWithCommas(parseFloat(items.net_amount).toFixed(2))}
          </td>
        </tr>
      );
    });
  };

  const [flagForMoneyMarketSecutities, setFlagForMoneyMarketSecutities] =
    useState(false);
  const renderMoneyMarketSecuritiesModalPopup = () => {
    switch (flagForMoneyMarketSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setFlagForMoneyMarketSecutities(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForMoneyMarketSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Money Market Security </h6>
            </div>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForMoneyMarketSecutities(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              {/* <h6 className="title title-up">View Security  </h6> */}
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                {securites.length > 0 && (
                  <div className="form-group">
                    <table className="table transform">
                      <thead>
                        <tr>
                          <th>Security Type</th>
                          <th>Investment Type</th>
                          <th>Type</th>
                          <th>Symbol</th>
                          <th>Name</th>
                          <th>Issue Date</th>
                          <th>Maturity Date</th>
                          <th>Face Value</th>
                          <th>Price</th>
                          <th>Units</th>
                          <th>Yield</th>
                          <th>Coupon Rate</th>
                          <th>Next Coupon Date</th>
                          <th>Last Coupon date</th>
                          <th>Primary Dealer</th>
                          <th>Fund IPS Account</th>
                          <th>Counter Party IPS Account</th>
                          <th>Broker Name</th>
                          <th>Settlement Amount</th>
                          <th>Total Face Value</th>
                          <th>Accrued amount</th>
                          <th>Brokerage</th>
                          <th>Premium Discount</th>
                          <th>Accrued Days</th>
                          <th>Counter Party Name</th>
                          <th>Detail</th>
                          <th>Principal Amount</th>
                          <th>UnRedeem Value</th>
                          <th>Copoun Payment/Year</th>
                          <th>Days of Maturity</th>
                          <th>Avg Cost/Unit</th>
                          <th>Avg Cost Amt</th>
                          <th>Gain/Loss Per Unit</th>
                          <th>Gain/Loss Amt</th>
                        </tr>
                      </thead>
                      <tbody>{renderMoneyMarketSecurityData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const decimalValue = (value, len) => {
    // value===undefined ? value='0' : value;
    if (value === undefined) {
      value = "0";
    }
    let startingValue = value.toString().split(".")[0];
    let decimalVal = value.toString().split(".")[1];
    let temp = "";
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + "." + decimalString;
      } else {
        temp = startingValue + "." + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const renderMoneyMarketSecurityData = () => {
    if (securites === "[]") {
      return;
    }
    if (securites === "") {
      return;
    }
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.security_type}</td>
          <td>{items.investment_type}</td>
          <td>{items.type}</td>
          <td>{items.symbol}</td>
          <td>{items.name}</td>
          <td>{moment(items.issue_date).format("DD-MM-YYYY")}</td>
          <td>{moment(items.maturity_date).format("DD-MM-YYYY")}</td>
          <td>{numberWithCommas(items.money_market_face_value || 0)}</td>
          <td>
            {items.price === undefined ? "0" : numberWithCommas(items.price)}
          </td>
          <td>
            {items.units === undefined ? "0" : numberWithCommas(items.units)}
          </td>
          <td>
            {items.yield === undefined ? "0" : numberWithCommas(items.yield)}
          </td>
          <td>
            {items.coupon_rate === undefined
              ? "0"
              : numberWithCommas(items.coupon_rate)}
          </td>
          <td>
            {items.next_coupon_date === ""
              ? ""
              : moment(items.next_coupon_date).format("DD-MM-YYYY")}
          </td>
          <td>
            {items.last_coupon_date === ""
              ? ""
              : moment(items.last_coupon_date).format("DD-MM-YYYY")}
          </td>
          <td>{items.primary_dealer}</td>
          <td>{items.fund_ips_account}</td>
          <td>{items.counter_party_ips_account}</td>
          <td>{items.broker_name}</td>
          <td>
            {items.settlement_amount === undefined
              ? "0"
              : numberWithCommas(items.settlement_amount)}
          </td>
          <td>{numberWithCommas(items.total_face_value || 0)}</td>
          <td>
            {items.accrued_amount === undefined
              ? "0"
              : numberWithCommas(items.accrued_amount)}
          </td>
          <td>{items.brokerage}</td>
          <td>{numberWithCommas(items.premium_discount || 0)}</td>
          <td>{items.accrued_days}</td>
          <td>{items.counter_party_name}</td>
          <td>{items.detail}</td>
          <td>
            {items.principal_amount === undefined
              ? "0"
              : numberWithCommas(items.principal_amount)}
          </td>
          <td>{numberWithCommas(items.unredeem_value || 0)}</td>
          <td>{numberWithCommas(items.coupon_payment || 0)}</td>
          <td>{items.days_of_maturity}</td>
          <td>{numberWithCommas(items.average_cost_per_unit || 0)}</td>
          <td>{numberWithCommas(items.average_cost_amount || 0)}</td>
          <td>{numberWithCommas(items.gain_loss_per_unit || 0)}</td>
          <td>{numberWithCommas(items.gain_loss_amount || 0)}</td>
        </tr>
      );
    });
  };

  const renderSystemTaxData = () => {
    return (
      <tr>
        <td>{parseFloat(systemTax.amount).toFixed(2)}</td>
        <td>{systemTax.sst_rate}%</td>
        <td>{parseFloat(systemTax.sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.total_amount).toFixed(2)}</td>
        <td>{systemTax.wht_it_rate}%</td>
        <td>{parseFloat(systemTax.wht_it_amount).toFixed(2)}</td>
        <td>{systemTax.wht_sst_rate}%</td>
        <td>{parseFloat(systemTax.wht_sst_amount).toFixed(2)}</td>
        <td>{parseFloat(systemTax.net_amount).toFixed(2)}</td>
      </tr>
    );
  };
  const [viewSystemTaxModal, setSystemTaxModal] = useState(false);
  const renderSystemTaxPopup = () => {
    switch (viewSystemTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setSystemTaxModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setSystemTaxModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Tax Details </h6>
            </div>
            <div className="modal-body">
              {checkTaxBoolean(headLabel) && fields.system_tax !== "" && (
                <>
                  <h4 className="text-primary text-center">System Tax</h4>
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Rate (SST)</th>
                        <th>Amount (SST)</th>
                        <th>Total Amount</th>
                        <th>Rate (WHT IT)</th>
                        <th>WHT IT AMOUNT</th>
                        <th>Rate WHT SST</th>
                        <th>SST WHT IT AMOUNT</th>
                        <th>Net Amount</th>
                      </tr>
                    </thead>
                    <tbody>{renderSystemTaxData()}</tbody>
                  </table>
                </>
              )}

              {totalTax.length > 0 ? (
                <>
                  <h4 className="text-primary text-center"> Tax Charges</h4>
                  <div className="form-group">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                </>
              ) : (
                "Taxes/charges were not enterred !"
              )}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const [totalTax, setTotalTax] = useState<any>([]);
  //render Tax table data
  const renderTaxData = () => {
    if (totalTax === "[]") {
      return;
    }
    if (totalTax === "") {
      return;
    }
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{parseFloat(items.amount).toFixed(2)}</td>
          <td>{parseFloat(items.calculatedAmount).toFixed(2)}</td>
        </tr>
      );
    });
  };

  const [viewTaxModal, setTaxModal] = useState(false);
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
          <Modal
            className=" "
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTaxModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTaxModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Txn Charges </h6>
            </div>

            <div className="modal-body">
              <div className="">
                {totalTax.length > 0 && (
                  <div className=" ">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderTranfereesData = (tempData) => {
    // if (securites === '[]') {
    //   return;
    // }
    // if (securites === '') {
    //   return;
    // }
    return tempData.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.folio_no}</td>
          <td>{items.unitholder_name}</td>
          <td className="text-right">
            {numberWithCommas(parseFloat(items.transferees_unit_transfer))}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="">
      {/* <div className="">
        <h4 className="card-title">{CapTxnType(headLabel)} Transaction</h4>
      </div> */}
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Instruction</h4>
            </div>
            <div className="card-body">
              <div>
                {/* fund dropdown , itx value depend on amc value  */}
                <div className="form-group">
                  Fund Name
                  <div className="form-control  w-100">
                    {fields["fund_code"]}
                  </div>
                </div>
                <div className="form-group">
                  Instruction Date
                  <div className="form-control w-100">
                    {fields["instruction_date"]
                      ? moment(fields["instruction_date"]).format("DD-MM-YYYY")
                      : ""}
                  </div>
                </div>
                <div className="form-group">
                  Execution Date
                  <div className="form-control w-100">
                    {fields["execution_date"]
                      ? moment(fields["execution_date"]).format("DD-MM-YYYY")
                      : ""}
                  </div>
                </div>
                <div className="form-group">
                  Mode of Payment
                  <div className="form-control w-100">
                    {fields["mode_of_payment"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        {headLabel === "transferofunits" && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transferor</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  <div>Folio Number</div>
                  <div className="form-control    w-100">
                    {fields["folio_no"]}
                  </div>
                </div>
                <div className="form-group">
                  <div>Name</div>
                  <div className="form-control    w-100">
                    {fields["unitholder_name"]}
                  </div>
                </div>
                <div className="form-group">
                  <div>Current Holding</div>
                  <div className="form-control    w-100 text-right">
                    {numberWithCommas(parseFloat(fields["current_holding"]))}
                  </div>
                </div>
                <div className="form-group">
                  <div>Remaining Holding</div>
                  <div className="form-control    w-100 text-right">
                    {numberWithCommas(parseFloat(fields["remain_holding"]))}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
        {headLabel === "transferofunits" && (
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transferees</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <div>View Transferees</div>
                      <div className="form-group mb-4">
                        <div className="table-responsive">
                          {JSON.parse(fields.transfrees || "[]")?.length >
                            0 && (
                            <div className="form-group">
                              <table className="table ">
                                <thead>
                                  <tr>
                                    <th>Folio No</th>
                                    <th>Name</th>
                                    <th className="text-right">
                                      Transfer Units
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {renderTranfereesData(
                                    JSON.parse(fields.transfrees || "[]")
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {(headLabel === "pledgedin" || headLabel === "pledgedout") && (
          <Col>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{CapTxnType(headLabel)}</h4>
              </div>
              <div className="card-body">
                <div>
                  <div className="form-group">
                    Symbol
                    <div className="form-control   w-100">
                      {fields["symbol"]}
                    </div>
                  </div>
                  <div className="form-group">
                    Shares to {CapTxnType(headLabel)}
                    <div className="form-control    w-100">
                      {fields["volume"]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}

        {headLabel !== "pledgedin" &&
          headLabel !== "pledgedout" &&
          headLabel !== "corporate_announcements" &&
          headLabel != "transferofunits" &&
          headLabel != "vouchers" &&
          headLabel != "dailytrusteefee" &&
          headLabel != "postnavpayments" &&
          headLabel != "postnavpaymentsaccrual" &&
          headLabel != "dailybankprofitaccrual" &&
          headLabel != "bankprofit" &&
          headLabel != "dailyexpenseaccrual" &&
          headLabel != "expensepayment" && (
            <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {headLabel === "fundtransfer"
                      ? "From Account"
                      : "Fund Account"}
                  </h4>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group">
                      Fund Account
                      <div className="form-control w-100">
                        {fields["fund_account"]}
                      </div>
                    </div>
                    <div className="form-group">
                      Account Title
                      <div className="form-control w-100">
                        {fields["account_title"]}
                      </div>
                    </div>
                    <div className="form-group">
                      Account Number
                      <div className="form-control w-100 ">
                        {fields["account_number"]}
                      </div>
                    </div>
                    <div className="form-group w-100 row">
                      <div className="col-6 flex">
                        <div>Bank</div>
                        <div className="form-control w-100  ">
                          {fields["bank"]}
                        </div>
                      </div>
                      <div className="col-6 flex">
                        <div>Branch</div>
                        <div className="form-control w-100  ">
                          {fields["branch"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}

        {headLabel !== "pledgedin" &&
          headLabel !== "pledgedout" &&
          headLabel !== "corporate_announcements" &&
          headLabel != "transferofunits" &&
          headLabel != "vouchers" &&
          headLabel != "dailytrusteefee" &&
          headLabel != "dailybankprofitaccrual" &&
          headLabel != "dailyexpenseaccrual" &&
          headLabel != "expensepayment" && (
            <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    {headLabel === "fundtransfer"
                      ? "To Account"
                      : "Counter Account"}
                  </h4>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group">
                      <div className="text-capitalize">{counterAccounter}</div>
                      {/* <div className="form-control  w-100">
                    {fields['counter_account_type']}
                  </div> */}
                      <div className="form-control    w-100">
                        {fields["folio_no"] !== ""
                          ? fields["folio_no"]
                          : headLabel === "moneymarketpurchase"
                          ? fields["counter_type"]
                          : fields["counter_account_type"]}
                        {fields["counter_type"] === "Other" &&
                        fields["counter_account_type"] !== "Other" ? (
                          <span className="text-primary">
                            {" "}
                            ({fields["counter_type"]}){" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      Account Title
                      <div className="form-control  w-100">
                        {fields["counter_account_title"]}
                      </div>
                    </div>
                    <div className="form-group">
                      Account Number
                      <div className="form-control w-100">
                        {fields["counter_account_number"]}
                      </div>
                    </div>
                    <div className="form-group w-100 row">
                      <div className="col-6 flex">
                        <div>Bank</div>
                        <div className="form-control w-100  ">
                          {fields["counter_bank"]}
                        </div>
                      </div>
                      <div className="col-6 flex">
                        <div>Branch</div>
                        <div className="form-control w-100  ">
                          {fields["counter_branch"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          )}
      </Row>
      {headLabel !== "pledgedin" &&
        headLabel !== "pledgedout" &&
        headLabel != "transferofunits" && (
          // headLabel != "dailybankprofit" &&
          <Row>
            {headLabel !== "corporate_announcements" &&
              headLabel != "dailytrusteefee" &&
              headLabel != "dailyexpenseaccrual" &&
              headLabel != "expensepayment" &&
              headLabel != "dailybankprofitaccrual" && (
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Instrument</h4>
                    </div>
                    <div className="card-body">
                      <div>
                        <div className="form-group">
                          Mode of Payment
                          <div className="form-control   w-100">
                            {fields["mode_of_payment"]}
                          </div>
                        </div>
                        <div className="form-group">
                          Instrument Type
                          <div className="form-control   w-100">
                            {fields["instrument_type"]}
                          </div>
                        </div>
                        <div className="form-group">
                          Instrument No./Reference No
                          <div className="form-control    w-100">
                            {fields["instrument_no"]}
                          </div>
                        </div>
                        {fields.payment_type !== "Receipt" &&
                        fields.txn_status === "COMPLETED" ? (
                          <>
                            {/* <div className="form-group">
                      Instrument No./Reference No
                      <div className="form-control    w-100">
                        {fields['instrument_no']}
                      </div>
                    </div> */}
                            <div className="form-group">
                              Instrument Date / Deposit Date
                              <div className="form-control   w-100">
                                {fields["instrument_date"] === ""
                                  ? ""
                                  : moment(fields["instrument_date"]).format(
                                      "DD/MM/YYYY"
                                    )}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Transaction Processing</h4>
                    </div>
                    <div className="card-body">
                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <div>Transaction Type</div>
                            <div className="form-control   w-100">
                              {CapTxnType(fields["txnr_type"])}
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <div>Transaction ID</div>
                            <div className="form-control   w-100 ">
                              {fields["txn_id"] != undefined
                                ? fields["txn_id"].replace("TXN_", "")
                                : ""}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group">
                        <div> Created By</div>
                        <div className="form-control   w-100">
                          {fields["created_by"]}
                        </div>
                      </div>
                      <div className="form-group">
                        <div> Status</div>
                        <div className="form-control   w-100">
                          {fields["txn_status"]}
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <FormGroup check className="mt-3">
                            <Input
                              type="checkbox"
                              checked={realizedCheck}
                              readOnly
                            />
                            <span className="form-check-sign" />
                            <span className="ml-4 mt-minus6">
                              {" "}
                              Realized In Bank{" "}
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <div>Realized Date</div>
                            <div className="form-control   w-100">
                              {fields["realized_date"] == ""
                                ? ""
                                : moment(fields["realized_date"]).format(
                                    "DD-MM-YYYY"
                                  )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* <div className="card">
          <div className="card-header">
            <h4 className="card-title">
              Transaction Processing
            </h4>
          </div>
          <div className="card-body">

            <Row>
              <Col md="6">
              <div className="form-group">
                <div>Transaction Type</div>
                  <div className="form-control   w-100">
                  { CapTxnType( fields['txnr_type'] )}
                  </div>
              </div> 

              </Col>
              <Col md="6">
              <div className="form-group">
                <div>Transaction ID</div>
                  <div className="form-control   w-100">
                  {fields['txn_id']}
                  </div>
              </div>
              </Col>
            </Row> 
          

              <div className="form-group">
                <div> Created By</div>
                  <div className="form-control   w-100">
                  {fields['created_by']}
                </div>
              </div> 
              <div className="form-group">
                <div> Status</div>
                  <div className="form-control   w-100">
                  {fields['txn_status']}
                </div>
              </div> 

              <Row>
              <Col md="6"> 

                  <FormGroup check className="mt-3">
                        
                    <Input type="checkbox"
                      checked={realizedCheck} readOnly />
                    <span className="form-check-sign" />
                    <span className="ml-4 mt-minus6">  Realized In Bank </span>
                         
                  </FormGroup> 
              </Col>
              <Col md="6">
              <div className="form-group">
                  <div>Realized Date</div>
                  <div className="form-control   w-100">
                  {fields['realized_date']==''? '' : moment(fields['realized_date']).format('DD-MM-YYYY')}
                  </div>
                </div>
              </Col>
            </Row>
              
          </div>
          </div> */}
                </Col>
              )}

            {headLabel !== "vouchers" &&
              headLabel != "dailytrusteefee" &&
              headLabel != "dailybankprofitaccrual" &&
              headLabel != "dailyexpenseaccrual" &&
              headLabel != "expensepayment" && (
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Transaction Details</h4>
                    </div>
                    <div className="card-body">
                      <div>
                        {chechFieldDisplay(headLabel, "folio_no") && (
                          <>
                            <Row>
                              <Col md="6">
                                <div className="form-group">
                                  Folio Number
                                  <div className="form-control    w-100">
                                    {fields["folio_no"]}
                                  </div>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  UnitHolder Name
                                  <div className="form-control    w-100">
                                    {fields["unitholder_name"]}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                        {chechFieldDisplay(headLabel, "units") && (
                          <div className="form-group">
                            Units{" "}
                            {headLabel === "redemptionofunits"
                              ? "Redeemed"
                              : headLabel === "saleofunit" ||
                                headLabel === "conversionin"
                              ? "Sold"
                              : headLabel === "salereturn"
                              ? "Returned"
                              : ""}
                            <div className="form-control  w-100 text-right">
                              {parseFloat(fields["units"]).toFixed(4)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "nav") && (
                          <div className="form-group">
                            Nav
                            <div className="form-control w-100 text-right">
                              {parseFloat(fields["nav"]).toFixed(4)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "type") && (
                          <div className="form-group">
                            Type
                            <div className="form-control w-100">
                              {fields["type"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "sale_date") && (
                          <div className="form-group">
                            Sale Date
                            <div className="form-control w-100">
                              {fields["sale_date"]
                                ? moment(fields["sale_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "dividend_date") && (
                          <div className="form-group">
                            <div>Dividend Date</div>
                            <div className="form-control   w-100">
                              {moment(fields["dividend_date"]).format(
                                "DD-MM-YYYY"
                              )}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "dividend_rate") && (
                          <div className="form-group">
                            <div>Dividend Rate</div>
                            <div className="form-control   w-100">
                              {fields["dividend_rate"]}
                            </div>
                          </div>
                        )}

                        {chechFieldDisplay(headLabel, "period") && (
                          <div className="form-group">
                            <div>Select Period</div>
                            <div className="form-control   w-100">
                              {fields["period"]}
                            </div>
                          </div>
                        )}

                        {chechFieldDisplay(headLabel, "reinvest") && (
                          <div className="form-group">
                            <div>Reinvest</div>
                            <input
                              type="checkbox"
                              checked={
                                fields["reinvest"].toUpperCase() === "YES"
                                  ? true
                                  : false
                              }
                              readOnly
                            />
                          </div>
                        )}

                        {chechFieldDisplay(headLabel, "refund_of_capital") && (
                          <div className="form-group">
                            Refund of Capital
                            <div className="form-control   w-100">
                              {numberWithCommas(fields["refund_of_capital"])}
                            </div>
                          </div>
                        )}

                        {chechFieldDisplay(headLabel, "sale_txn_no") && (
                          <div className="form-group">
                            Sale Txn No
                            <div className="form-control w-100">
                              {fields["sale_txn_no"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "return_date") && (
                          <div className="form-group">
                            Return Date
                            <div className="form-control w-100">
                              {fields["return_date"]
                                ? moment(fields["return_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}

                        {headLabel === "conversionout" &&
                        fields.txn_status === "COMPLETED" ? (
                          <>
                            {/* {chechFieldDisplay(headLabel, 'total_holding') && ( */}
                            <div className="form-group">
                              Total Holding
                              <div className="form-control w-100 text-right">
                                0
                              </div>
                            </div>
                            {/* )} */}
                          </>
                        ) : (
                          <>
                            {/* {chechFieldDisplay(headLabel, 'current_holding') && (
                    <div className="form-group">
                      Current Holding
                      <div className="form-control w-100">
                        {fields['current_holding']
                        ? parseFloat(fields['current_holding']).toFixed(4)
                        : '0.0000'}
                      </div>
                    </div>
                  )} */}
                            {chechFieldDisplay(
                              headLabel,
                              "current_holding"
                            ) && (
                              <div className="form-group">
                                Previous Holding
                                <div className="form-control w-100 text-right">
                                  {fields["current_holding"]
                                    ? parseFloat(
                                        fields["current_holding"]
                                      ).toFixed(4)
                                    : "0.0000"}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {/* {chechFieldDisplay(headLabel, 'current_holding') && (
                  <div className="form-group">
                    Current Holding
                    <div className="form-control w-100">
                      {parseFloat(fields['current_holding']).toFixed(4)}
                    </div>
                  </div>
                )} */}

                        {chechFieldDisplay(headLabel, "symbol") && (
                          <div className="form-group">
                            Symbol
                            <div className="form-control w-100 text-right">
                              {fields["symbol"]}
                            </div>
                          </div>
                        )}
                        {/* {chechFieldDisplay(headLabel, 'dividend_percentage') && (
                  <div className="form-group">
                    Dividend Percentage
                    <div className="form-control w-100">
                      {fields['dividend_percentage']}
                    </div>
                  </div>
                )} */}
                        {headLabel === "corporate_announcements" && (
                          <>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  Volume
                                  <div className="form-control   w-100 text-right">
                                    {fields["volume"]}
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="form-group">
                                  Par Value
                                  <div className="form-control   w-100 text-right">
                                    {fields["par_value"]}
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <div className="form-group">
                              Announcement Date
                              <div className="form-control w-100">
                                {fields["announcement_date"] == ""
                                  ? ""
                                  : moment(fields["announcement_date"]).format(
                                      "DD-MM-YYYY"
                                    )}
                              </div>
                            </div>
                          </>
                        )}
                        {chechFieldDisplay(headLabel, "maturity_type") && (
                          <div className="form-group">
                            Maturity Type
                            <div className="form-control w-100">
                              {fields["maturity_type"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "security_type") && (
                          <div className="form-group">
                            Security Type
                            <div className="form-control  w-100">
                              {fields["security_type"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "primary_dealer") && (
                          <div className="form-group">
                            Primary Dealer
                            <div className="form-control    w-100">
                              {fields["primary_dealer"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "issue_date") && (
                          <div className="form-group">
                            Issue Date
                            <div className="form-control w-100">
                              {fields["issue_date"]
                                ? moment(fields["issue_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "conversion_date") && (
                          <div className="form-group">
                            Conversion Date
                            <div className="form-control w-100">
                              {fields["conversion_date"]
                                ? moment(fields["coversion_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(
                          headLabel,
                          "associated_transaction"
                        ) && (
                          <div className="form-group">
                            Associated Txn No
                            <div className="form-control w-100">
                              {fields["associated_transaction"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "maturity_date") && (
                          <div className="form-group">
                            Maturity Date
                            <div className="form-control w-100">
                              {fields["maturity_date"]
                                ? moment(fields["maturity_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "coupon_rate") && (
                          <div className="form-group">
                            Coupon Rate / Yield
                            <div className="form-control w-100 text-right">
                              {parseFloat(fields["coupon_rate"]).toFixed(2)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "price") && (
                          <div className="form-group">
                            Price
                            <div className="form-control w-100 text-right">
                              {parseFloat(fields["price"]).toFixed(2)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "face_value") && (
                          <div className="form-group">
                            Face Value
                            <div className="form-control  w-100 text-right">
                              {parseFloat(fields["face_value"]).toFixed(2)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(
                          headLabel,
                          "money_market_face_value"
                        ) && (
                          <div className="form-group">
                            Money Market Face Value
                            <div className="form-control  w-100 text-right">
                              {parseFloat(
                                fields["money_market_face_value"]
                              ).toFixed(2)}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "detail") && (
                          <div className="form-group">
                            Detail
                            <div className="form-control w-100">
                              {fields["detail"]}
                            </div>
                          </div>
                        )}

                        {(headLabel === "equityinvestment" ||
                          headLabel === "advisoryfee" ||
                          headLabel === "auditorsfee" ||
                          headLabel === "bankcharges" ||
                          headLabel === "managementfee" ||
                          headLabel === "trusteefee" ||
                          headLabel === "custodyfee" ||
                          headLabel === "shariahadvisoryfee" ||
                          headLabel === "cdcfee" ||
                          headLabel === "listingfee" ||
                          headLabel === "brokagefee" ||
                          headLabel === "printingfee" ||
                          headLabel === "professionalfee" ||
                          headLabel === "ratingfee" ||
                          headLabel === "taxpayment" ||
                          headLabel === "secpfee" ||
                          headLabel === "otherpayment") && (
                          <div className="form-group">
                            Sales Tax Collector
                            <div className="form-control   w-100">
                              {fields["sales_tax_collector"]}
                            </div>
                          </div>
                        )}

                        {headLabel === "termdeposit" &&
                        termDeposit.length > 0 ? (
                          <>
                            <div className="form-group">
                              Type
                              <div className="form-control w-100">
                                {termDeposit[0]["name"]}
                              </div>
                            </div>
                            <div className="form-group text-right">
                              Principal Amount
                              <div className="form-control w-100">
                                {termDeposit[0]["principal_amount"]}
                              </div>
                            </div>
                            <div className="form-group">
                              Interest Rate
                              <div className="form-control w-100">
                                {termDeposit[0]["interest_rate"]}
                              </div>
                            </div>

                            <div className="form-group">
                              From Date
                              <div className="form-control w-100">
                                {termDeposit[0]["from_date"]}
                              </div>
                            </div>

                            <div className="form-group">
                              To Date
                              <div className="form-control w-100">
                                {termDeposit[0]["to_date"]}
                              </div>
                            </div>
                          </>
                        ) : (
                          //   <>
                          //  <div className="form-group">
                          //    Type
                          //   <div className="form-control w-100">{termDeposit['name']}</div>
                          // </div>
                          //    <div className="form-group">
                          //    Principal Amount
                          //   <div className="form-control w-100">{termDeposit['principal_amount']}</div>
                          // </div>
                          // <div className="form-group">
                          //    Interest Rate
                          //   <div className="form-control w-100">{termDeposit['interest_rate']}</div>
                          // </div>

                          // <div className="form-group">
                          //   From Date
                          //  <div className="form-control w-100">{termDeposit['from_date']}</div>
                          // </div>

                          // <div className="form-group">
                          //   To Date
                          //   <div className="form-control w-100">{termDeposit['to_date']}</div>
                          // </div>
                          //   </>
                          ""
                        )}

                        {chechFieldDisplay(headLabel, "redemption_date") && (
                          <div className="form-group">
                            Redemption Date
                            <div className="form-control w-100">
                              {fields["redemption_date"]
                                ? moment(fields["redemption_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {/* {chechFieldDisplay(headLabel, 'remain_holding') && (
                  <div className="form-group">
                    Remaining Holding
                    <div className="form-control w-100">
                      {parseFloat(fields['remain_holding']).toFixed(4)}
                    </div>
                  </div>
                )} */}
                        {chechFieldDisplay(headLabel, "settlement_by") && (
                          <div className="form-group">
                            Settlement By*
                            <div className="form-control w-100">
                              {fields["settlement_by"]}
                            </div>
                          </div>
                        )}
                        {chechFieldDisplay(headLabel, "settlement_date") && (
                          <div className="form-group">
                            Settlement Date
                            <div className="form-control w-100">
                              {fields["settlement_date"]
                                ? moment(fields["settlement_date"]).format(
                                    "YYYY-MM-DD"
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                        {/* {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Fund IPS Account</div>
                    <div className="form-control w-100">
                      {fields['fund_ips_account']}
                    </div>
                  </div>
                )}
                {headLabel === 'moneymarketpurchase' && (
                  <div className="form-group">
                    <div>Counter Party IPS Account</div>
                    <div className="form-control w-100">
                      {fields['counter_party_ips_account']}
                    </div>
                  </div>
                )} */}
                        {chechFieldDisplay(headLabel, "tax_type") && (
                          <div className="form-group">
                            Tax Type
                            <div className="form-control w-100">
                              {fields["tax_type"]}
                            </div>
                          </div>
                        )}
                        {headLabel === "equityinvestment" && (
                          <div className="form-group mb-4">
                            {fields.securities !== "[]" &&
                              fields.securities !== "" && (
                                <div
                                  className="btn btn-success"
                                  onClick={() => {
                                    setFlagForSecutities(true);
                                    setSecurites(JSON.parse(fields.securities));
                                  }}
                                >
                                  View Securities
                                </div>
                              )}
                          </div>
                        )}
                        {headLabel === "debtmarketinvestment" && (
                          <div className="form-group mb-4">
                            {fields.securities !== "[]" &&
                              fields.securities !== "" && (
                                <div
                                  className="btn btn-success"
                                  onClick={() => {
                                    setFlagForMoneyMarketSecutities(true);
                                    setSecurites(JSON.parse(fields.securities));
                                  }}
                                >
                                  View Money Market Securities
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              )}

            {headLabel === "corporate_announcements" && (
              <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Cash Dividend</div>
                  </div>
                  <div className="card-body">
                    {chechFieldDisplay(headLabel, "dividend_percentage") && (
                      <div className="form-group">
                        <div>Dividend Percentage</div>
                        <div className="form-control w-100 text-right">
                          {fields["dividend_percentage"]}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "credit_date") && (
                      <div className="form-group">
                        <div>Dividend Credit Date</div>
                        <div className="form-control w-100">
                          {moment(fields["credit_date"]).format("DD-MM-YYYY")}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "gross_amount") && (
                      <div className="form-group">
                        <div>Dividend Amount</div>
                        <div className="form-control w-100 text-right">
                          {fields["gross_amount"]}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}

            {headLabel === "corporate_announcements" && (
              <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Bonus Shares</div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <div>Bonus Percentage</div>
                      <div className="form-control w-100 text-right">
                        {fields["bonus_percentage"]}
                      </div>
                    </div>

                    <div className="form-group">
                      <div>Gross Bonus Share</div>
                      <div className="form-control w-100 text-right">
                        {fields["bonus_gross_volume"]}
                      </div>
                    </div>

                    {/* <Row>
              <Col>
              <div className="form-group">
                <div>Tax on Bonus (%)*</div>
                <div className="form-control w-100">
                {fields['tax_on_bonus']}
                </div>
               </div>
              </Col>
              <Col>
               <div className="form-group">
                <div>Tax on Bonus Shares</div>
                <div className="form-control w-100">
                {fields['tax_it_bonus_amount']}
                </div>
               </div>
               </Col>
               </Row>

               <div className="form-group">
                <div>Net Bonus Shares</div>
                <div className="form-control w-100">
                {fields['net_bonus_volume']}
                </div>
               </div> */}

                    <div className="form-group">
                      <div>Bonus Credit Date</div>
                      <div className="form-control w-100">
                        {fields["bonus_credit_date"] == ""
                          ? ""
                          : moment(fields["bonus_credit_date"]).format(
                              "DD-MM-YYYY"
                            )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            )}

            {headLabel === "corporate_announcements" && (
              <Col md="4">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">Right Shares</div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <div>Right Share Percentage</div>
                      <div className="form-control w-100 text-right">
                        {fields["right_share_percentage"]}
                      </div>
                    </div>

                    <div className="form-group">
                      <div>Gross Right Share</div>
                      <div className="form-control w-100 text-right">
                        {fields["right_share_gross_volume"]}
                      </div>
                    </div>

                    {/* <Row>
              <Col>
               <div className="form-group">
                <div>Tax on Right (%)*</div>
                <div className="form-control w-100">
                {fields['tax_on_right']}
                </div>
               </div>
                </Col>

                <Col>
               <div className="form-group">
                <div>Tax on Right Shares</div>
                <div className="form-control w-100">
                {fields['tax_it_right_share_amount']}
                </div>
               </div>
               </Col>
              </Row>

               <div className="form-group">
                <div>Net Right Shares</div>
                <div className="form-control w-100">
                {fields['net_right_share_volume']}
                </div>
               </div> */}

                    <div className="form-group">
                      <div>Right Share Credit Date</div>
                      <div className="form-control w-100">
                        {fields["right_share_credit_date"] == ""
                          ? ""
                          : moment(fields["right_share_credit_date"]).format(
                              "DD-MM-YYYY"
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Right Share Subscription</h4>
                  </div>
                  <div className="card-body">
                    <div>
                      <div className="form-group">
                        <div>Right Share Symbol</div>
                        <div className="form-control w-100 text-right">
                          {fields["right_share_symbol"]}
                        </div>
                      </div>
                      <Row>
                        <Col md="12">
                          {chechFieldDisplay(
                            headLabel,
                            "total_market_value"
                          ) && (
                            <div className="form-group">
                              <div>Total Market Value</div>
                              <div className="form-control w-100 text-right">
                                {fields["total_market_value"]}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md="12">
                          {chechFieldDisplay(
                            headLabel,
                            "average_cost_before_right"
                          ) && (
                            <div className="form-group">
                              <div>Average Cost Before Right</div>
                              <div className="form-control w-100 text-right">
                                {fields["average_cost_before_right"]}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          {chechFieldDisplay(headLabel, "premium") && (
                            <div className="form-group">
                              <div>Premium</div>
                              <div className="form-control w-100 text-right">
                                {fields["premium"]}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          {chechFieldDisplay(headLabel, "cost_after_right") && (
                            <div className="form-group">
                              <div>Cost After Right</div>
                              <div className="form-control w-100 text-right">
                                {fields["cost_after_right"]}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md="12">
                          {chechFieldDisplay(
                            headLabel,
                            "difference_right_price"
                          ) && (
                            <div className="form-group">
                              <div>Difference Between Right Price</div>
                              <div className="form-control w-100 text-right">
                                {fields["difference_right_price"]}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          {chechFieldDisplay(
                            headLabel,
                            "cost_right_entitled"
                          ) && (
                            <div className="form-group">
                              <div>Cost of Right Entitled</div>
                              <div className="form-control w-100 text-right">
                                {fields["cost_right_entitled"]}
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md="12">
                          {chechFieldDisplay(headLabel, "cost_right") && (
                            <div className="form-group">
                              <div>Cost of Right</div>
                              <div className="form-control w-100 text-right">
                                {fields["cost_right"]}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            )}

            <Col md="4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Amount</h4>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-group">
                      <div>Gross Amount</div>
                      <div
                        className="form-control w-100 text-right"
                        title={inWords(
                          parseFloat(fields["gross_amount"]).toFixed(2)
                        )}
                      >
                        {numberWithCommas(
                          decimalValue(fields["gross_amount"], 2)
                        )}
                      </div>
                    </div>

                    {chechFieldDisplay(headLabel, "tax_sst") && (
                      <div className="form-group">
                        Sale Tax (SST/PST)
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["tax_sst"])}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "tax_wht_it") && (
                      <div className="form-group">
                        WHT IT
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["tax_wht_it"])}
                        </div>
                      </div>
                    )}

                    {headLabel === "funddividendpayout" && (
                      <div className="form-group">
                        WHT IT
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["tax_wht_it"])}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "tax_wht_it") && (
                      <div className="form-group">
                        WHT Sale Tax
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["tax_wht_sst"])}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "tax_cgt") && (
                      <div className="form-group">
                        CGT
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["tax_cgt"])}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "sale_load") && (
                      <div className="form-group">
                        Sale Load
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["sale_load"])}
                        </div>
                      </div>
                    )}

                    {chechFieldDisplay(headLabel, "redemption_load") && (
                      <div className="form-group">
                        Redemption Load
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["redemption_load"])}
                        </div>
                      </div>
                    )}

                    {(headLabel === "redemptionofunits" ||
                      headLabel === "conversionout") && (
                      <div className="form-group">
                        Zakat
                        <div className="form-control w-100 text-right">
                          {numberWithCommas(fields["zakat_amount"])}
                        </div>
                      </div>
                    )}

                    {/* <div className="form-group">
                  <div className="d-flex space-between">
                  <div>Charges</div>
                      <div className=" ">	
                      {fields.txn_charges !== '[]' && fields.txn_charges !== '' && ( 
                          <a className=" "  	
                          onClick={() => {	
                              // setTaxModal(true);	
                              setTotalTax(JSON.parse(fields.txn_charges))	
                               // system
                               setSystemTaxModal(true);
                               let temp = JSON.parse(fields.system_tax);
                               setSystemTax(temp);
                               setCheckChanges(temp.amount);
                          }}	
                          >	
                          View Tax	
                          </a>

                       )} 
                      </div>
                   </div>  
                  <div className="form-control w-100">
                    {parseFloat(fields['total_charges']).toFixed(2)}
                  </div>
                </div>  */}
                    {chechFieldDisplay(headLabel, "total_charges") && (
                      <div className="form-group">
                        <div>Total Amount</div>
                        <div
                          className="form-control  w-100 text-right"
                          title={inWords(
                            parseFloat(fields["total_charges"]).toFixed(2)
                          )}
                        >
                          {numberWithCommas(
                            decimalValue(fields["total_charges"], 2)
                          )}
                        </div>
                      </div>
                    )}

                    {(headLabel == "equityinvestment" ||
                      headLabel == "debtmarketinvestment") && (
                      <>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <div>Average Cost Amount</div>
                              <div
                                className="form-control  w-100 text-right"
                                title={fields["average_cost_amount"]}
                              >
                                {fields["average_cost_amount"]}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div>Gain Loss Amount</div>
                              <div
                                className="form-control  w-100 text-right"
                                title={fields["gain_loss_amount"]}
                              >
                                {fields["gain_loss_amount"]}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    {headLabel == "debtmarketinvestment" && (
                      <>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <div>Total Face Value</div>
                              <div
                                className="form-control w-100 text-right"
                                title={fields["total_face_value"]}
                              >
                                {fields["total_face_value"]}
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <div>Accrued Amount</div>
                              <div
                                className="form-control w-100 text-right"
                                title={fields["accrued_amount"]}
                              >
                                {fields["accrued_amount"]}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="4">
                            <div className="form-group">
                              <div>Premium Discount</div>
                              <div
                                className="form-control w-100 text-right"
                                title={fields["premium_discount"]}
                              >
                                {fields["premium_discount"]}
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <div>Brokerage</div>
                              <div
                                className="form-control w-100 text-right"
                                title={fields["brokerage"]}
                              >
                                {fields["brokerage"]}
                              </div>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="form-group">
                              <div>Principal Amount</div>
                              <div
                                className="form-control w-100 text-right"
                                title={fields["principal_amount"]}
                              >
                                {fields["principal_amount"]}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    {headLabel === "equityinvestment" && (
                      <>
                        <div className="form-group">
                          <div>Brokerage Commission</div>
                          <div className="form-control   w-100 text-right">
                            {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                            {numberWithCommas(
                              decimalValue(fields["broker_commission"], 2)
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <div>SST on Commission</div>
                          <div className="form-control   w-100 text-right">
                            {/* { parseFloat(fields['net_amount']).toLocaleString()} */}
                            {numberWithCommas(
                              decimalValue(fields["tax_sst"], 2)
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {headLabel === "corporate_announcements" && (
                      <>
                        <Row>
                          <Col>
                            <div className="form-group">
                              <div>Tax Percentage on Dividend</div>
                              <div className="form-control   w-100 text-right">
                                {fields["tax_on_dvidend"]}
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="form-group">
                              <div>Tax Amount on Dividend</div>
                              <div className="form-control   w-100 text-right">
                                {fields["tax_wht_it"]}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}

                    <div className="form-group">
                      <div>Net Amount</div>
                      <div className="form-control text-right w-100">
                        {numberWithCommas(
                          decimalValue(fields["net_amount"], 2)
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div>Net Amount In Words</div>
                      <div className="form-control  w-100">
                        {inWords(
                          parseFloat(fields["net_amount"]).toFixed(2)
                        ) === "Number Too Large and Zero Paisa Only"
                          ? "Number Too Large"
                          : inWords(
                              parseFloat(fields["net_amount"]).toFixed(2)
                            )}
                      </div>
                    </div>

                    {/* <div className="form-group">
                      <div> Type</div>
                      <div className="form-control   w-100">
                        {fields["payment_type"]}
                      </div>
                    </div> */}

                    {/* <div className="form-group">
                  {fields.txn_charges !== '[]' && fields.txn_charges !== '' && (
                    <div
                      className="btn-1 ml-auto flex justify-content-center"
                      style={{
                        background: '#0e4646',
                        color: 'white',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setTaxModal(true);
                        setTotalTax(JSON.parse(fields.txn_charges));
                      }}
                    >
                      View Tax
                    </div>
                  )}
                </div> */}

                    <div className="input-size-pos mt-2">
                      {/* {checkTaxBoolean(headLabel) && fields.system_tax !== '' && (
                    <button
                      className="btn btn-primary flex ml-auto justify-content-center" 
                      onClick={() => {
                        setSystemTaxModal(true);
                        let temp = JSON.parse(fields.system_tax);
                        setSystemTax(temp);
                        setCheckChanges(temp.amount);
                      }}
                    >
                      System Tax
                    </button>
                  )} */}
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            {/* {fields['txn_status'] === 'COMPLETED' && (   */}
            <Col>
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Ledgers</h4>
                </div>
                <div className="card-body">
                  {!ledgerLoading ? (
                    ledgerData.length > 0 && (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Ledger</th>
                              <th>Description</th>
                              <th>Debit Amount</th>
                              <th>Credit Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ledgerData.map((item: any, index: string) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {item.gl_name}
                                    <br />
                                    <small className="text-muted">
                                      {item.gl_code}
                                    </small>
                                  </td>
                                  <td>{item.description}</td>
                                  <td className="text-center">
                                    {numberWithCommas(item.debit_amount)}
                                  </td>
                                  <td className="text-center">
                                    {numberWithCommas(item.credit_amount)}
                                  </td>
                                  {/* <td> {item.counter_account_number}
                      <br /> <small><span className="text-muted"> {item.counter_account_title} </span></small>
                      </td> */}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  ) : (
                    <div className="d-flex justify-content-center my-5">
                      <i className="fa fa-spinner fa-spin fa-3x"></i>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* )} */}
          </Row>
        )}

      {headLabel === "transferofunits" && (
        <Row>
          <Col md="4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Transaction Details</h4>
              </div>
              <div className="card-body">
                <div className="form-group">
                  Description
                  <div className="form-control   w-100">{fields["detail"]}</div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}

      <Row></Row>

      {renderSecuritiesModalPopup()}
      {renderMoneyMarketSecuritiesModalPopup()}
      {renderTaxPopup()}
      {renderSystemTaxPopup()}
    </div>
  );
};

export default ViewTxnFields;

import React, { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { BsTable } from "react-icons/bs";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { getPortfolioByDate } from "../../stores/services/daily-unrealized-portfolio.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import { numberWithCommas } from "../../utils/customFunction";
import { useSelector } from "react-redux";
import Select from "react-select";

const DailyPortfolioReport = () => {
  const portfolioArr = [
    { label: "Money Market", value: "Money Market" },
    { label: "Stock Market", value: "Stock Market" },
  ];
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);

  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  let date = new Date();

  const [funds, setFunds] = useState<any>([]);

  const [fundPortfolio, setFundPortfolio] = useState<any>([]);
  const [moneyMarketPortfolio, setMoneyMarketPortfolio] = useState<any>([]);
  const [stockMarketPortfolio, setStockMarketPortfolio] = useState<any>([]);
  const [fundCode, setFundCode] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState("");
  const [createdDate, setCreatedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [resFundCode, setResFundCode] = useState("");
  const [resSelectedPortfolio, setResSelectedPortfolio] = useState("");
  const [resCreatedDate, setResCreatedDate] = useState("");

  const [funCodeErr, setFundCodeErr] = useState("");
  const [selectedPortfolioErr, setSelectedPortfolioErr] = useState("");
  const [createdDateErr, setCreatedDateErr] = useState("");

  const getFundsByAmcCode = async () => {
    try {
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      // sort data
      let sortedData: any = [];
      sortedData = response.data.data.sort(function (a, b) {
        let x = a.nature.toLowerCase();
        let y = b.nature.toLowerCase();
        if (x > y) {
          return 1;
        }
        if (x < y) {
          return -1;
        }
        return 0;
      });
      // setAllFunds(response.data.data);
      setFunds(sortedData);
    } catch (error) {}
  };

  // useEffect(() => {
  //   getFundsByAmcCode();
  // }, []);

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getFundsByAmcCode();
  }, [fund_code]);

  // const renderFundsDropDown = () => {
  //   return funds.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // }

  const renderFundsDropDown = () => {
    return funds.map((item: any, index: number) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  const validate = () => {
    let fundError = "";
    let portfolioError = "";
    let dateErr = "";
    fundCode?.trim() === ""
      ? (fundError = "required-border")
      : (fundError = "");
    selectedPortfolio?.trim() === ""
      ? (portfolioError = "required-border")
      : (portfolioError = "");
    createdDate?.trim() === "" ? (dateErr = "required-border") : (dateErr = "");
    if (fundError || portfolioError || dateErr) {
      setFundCodeErr(fundError);
      setSelectedPortfolioErr(portfolioError);
      setCreatedDateErr(dateErr);
      return false;
    } else {
      return true;
    }
  };

  //code for search data for Reports

  const searchData = async () => {
    const isValid = validate();
    if (isValid) {
      try {
        setLoading(true);
        setResFundCode("");
        setResSelectedPortfolio("");
        setResCreatedDate("");
        const response = await getPortfolioByDate(
          email,
          amc_code,
          fundCode,
          createdDate
        );
        if (response.data.data.length > 0) {
          setFundPortfolio(response.data.data);
          if (selectedPortfolio == "Money Market") {
            // let sortedData:any=[];
            //  sortedData = JSON.parse(response.data.data[0].money_market).sort(function(a,b){
            //    let x = a.code.toLowerCase();
            //    let y = b.code.toLowerCase();
            //    if(x>y){return 1;}
            //    if(x<y){return -1;}
            //    return 0;
            //  });
            //  moneyMarketScripWise(sortedData);
            //  setMoneyMarketPortfolio(moneyMarketScripWise(sortedData));
            setMoneyMarketPortfolio(
              JSON.parse(response.data.data[0].money_market)
            );
          } else {
            //     let sortedData:any=[];
            //     sortedData = JSON.parse(response.data.data[0].stock_market).sort(function(a,b){
            //       let x = a.code.toLowerCase();
            //       let y = b.code.toLowerCase();
            //       if(x>y){return 1;}
            //       if(x<y){return -1;}
            //       return 0;
            //     });

            //  setStockMarketPortfolio(stockMarketScripWise(sortedData));
            setStockMarketPortfolio(
              JSON.parse(response.data.data[0].stock_market)
            );
          }
          setResFundCode(fundCode);
          setResSelectedPortfolio(selectedPortfolio);
          setResCreatedDate(createdDate);
        } else {
          moneyMarketPortfolio.length = 0;
          setMoneyMarketPortfolio(moneyMarketPortfolio);
          stockMarketPortfolio.length = 0;
          setStockMarketPortfolio(stockMarketPortfolio);
          toast.error(response.data.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  // money market scrip wise
  // const moneyMarketScripWise=(portfolio:any)=>{
  //   let uniqueSymbols = Array.from(new Set(portfolio.map(x=>x.security_type)));
  //          let allArraysFiltered = uniqueSymbols.map((uniqueKey :any) =>{
  //           let filterData = portfolio.filter(item=>{
  //           return uniqueKey==item.security_type;
  //           });
  //           return filterData;
  //          }); // end map

  //         let filteredArraysWithTotalRowAdded: any = [];
  //           allArraysFiltered.forEach(filterArray => {
  //           if(filterArray.length > 1) {
  //          let rowAdded : any =  filterArray.reduce((row1 , row2) => {
  //            return {
  //              code:'~~~'+row1.security_type+' Total:',
  //              coupon_rate : (+row1.coupon_rate + +row2.coupon_rate).toFixed(2),
  //                               yield : (+row1.yield + +row2.yield).toFixed(2),
  //                               mmface_value : (+row1.mmface_value + +row2.mmface_value).toFixed(4),
  //                               total_face_value : (+row1.total_face_value + +row2.total_face_value).toFixed(4),
  //                               units : (+row1.units + +row2.units).toFixed(4),
  //                               total_amount : (+row1.total_amount + +row2.total_amount).toFixed(2),
  //                               market_value : (+row1.market_value + +row2.market_value).toFixed(2),
  //                               unrealized_gain_loss : (+row1.unrealized_gain_loss + +row2.unrealized_gain_loss).toFixed(2),
  //                               investment_percentage_market_value : (+row1.investment_percentage_market_value + +row2.investment_percentage_market_value).toFixed(2),
  //            }
  //          })
  //          filteredArraysWithTotalRowAdded.push([...filterArray , rowAdded , {}])
  //        }
  //        else {
  //          let objToShowInSingle = {
  //           code:'~~~'+filterArray[0].security_type+' Total:',
  //           // type:'Scrip wise Total:',
  //           coupon_rate : (parseFloat(filterArray[0].coupon_rate || '0')).toFixed(2) ,
  //           yield : (parseFloat(filterArray[0].yield || '0')).toFixed(2),
  //           mmface_value : (parseFloat(filterArray[0].mmface_value || '0')).toFixed(2),
  //           units : (parseFloat(filterArray[0].units || '0')).toFixed(2),
  //          total_face_value : (parseFloat(filterArray[0].total_face_value || '0')).toFixed(2),
  //          //  commission_rate : (parseFloat(row1.commission_rate || '0') + parseFloat(row2.commission_rate || '0')).toFixed(2),
  //          //  avg_rate : (parseFloat(row1.avg_rate || '0') + parseFloat(row2.avg_rate || '0')).toFixed(2),
  //          total_amount : (parseFloat(filterArray[0].total_amount || '0')),
  //          market_value : (parseFloat(filterArray[0].market_value || '0')),
  //          unrealized_gain_loss : (parseFloat(filterArray[0].unrealized_gain_loss || '0')),
  //          investment_percentage_market_value : (parseFloat(filterArray[0].investment_percentage_market_value || '0')),
  //          }
  //          filteredArraysWithTotalRowAdded.push([...filterArray ,objToShowInSingle, {}])
  //        }
  //      });

  //      let seperatedArray :any = [];
  //      filteredArraysWithTotalRowAdded.forEach(item => {
  //         item.forEach(objItem => {
  //         seperatedArray.push(objItem)
  //         })

  //       })
  //       return seperatedArray;

  // }

  // const stockMarketScripWise=(portfolio:any)=>{
  //   let uniqueSymbols = Array.from(new Set(portfolio.map(x=>x.code)));
  //          let allArraysFiltered = uniqueSymbols.map((uniqueKey :any) =>{
  //           let filterData = portfolio.filter(item=>{
  //           return uniqueKey==item.code;
  //           });
  //           return filterData;
  //          }); // end map

  //         let filteredArraysWithTotalRowAdded: any = [];
  //           allArraysFiltered.forEach(filterArray => {
  //           if(filterArray.length > 1) {
  //          let rowAdded : any =  filterArray.reduce((row1 , row2) => {
  //            return {
  //              code:'~~~'+row1.code+' Total:',
  //              unit : (+row1.unit + +row2.unit).toFixed(4),
  //             total_units : (+row1.total_units + +row2.total_units).toFixed(4),
  //             amount : (+row1.total_amount + +row2.amount).toFixed(2),
  //             market_value : (+row1.market_value + +row2.market_value).toFixed(2),
  //             unrealized_gain_loss : (+row1.unrealized_gain_loss + +row2.unrealized_gain_loss).toFixed(2),
  //             investment_percentage_market_value : (+row1.investment_percentage_market_value + +row2.investment_percentage_market_value).toFixed(2),
  //            }
  //          })
  //          filteredArraysWithTotalRowAdded.push([...filterArray , rowAdded , {}])
  //        }else {
  //          let objToShowInSingle = {
  //           code:'~~~'+filterArray[0].code+' Total:',
  //           unit : (+filterArray[0].unit).toFixed(4),
  //             total_units : (+filterArray[0].total_units).toFixed(4),
  //             amount : (+filterArray[0].amount).toFixed(2),
  //             market_value : (+filterArray[0].market_value).toFixed(2),
  //             unrealized_gain_loss : (+filterArray[0].unrealized_gain_loss).toFixed(2),
  //             investment_percentage_market_value : (+filterArray[0].investment_percentage_market_value).toFixed(2),
  //          }
  //          filteredArraysWithTotalRowAdded.push([...filterArray ,objToShowInSingle, {}])
  //        }
  //      });

  //      let seperatedArray :any = [];
  //      filteredArraysWithTotalRowAdded.forEach(item => {
  //         item.forEach(objItem => {
  //         seperatedArray.push(objItem)
  //         })

  //       })
  //       return seperatedArray;

  // }

  let coupon_rate = "0",
    yields = "0",
    mmface_value = "0",
    total_face_value = "0",
    units = "0",
    total_units = "0",
    total_amount = "0",
    market_value = "0",
    unrealized_gain_loss = "0",
    investment_percentage_market_value = "0";

  const selectedFundName = React.useMemo(() => {
    const selectedFund = funds.find((item) => item?.symbol_code === fundCode);
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [funds, fundCode]);

  const selectedPortfolioVal = React.useMemo(() => {
    return selectedPortfolio
      ? {
          value: selectedPortfolio,
          label: selectedPortfolio,
        }
      : null;
  }, [selectedPortfolio]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Daily Fund Portfolio Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <Select
                            className={funCodeErr}
                            value={selectedFundName}
                            label={"Select Fund"}
                            onChange={(e) => {
                              setFundCode(e?.value);
                              setFundCodeErr("");
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className={"form-control " + funCodeErr}
                            value={fundCode}
                            onChange={(e) => {
                              setFundCode(e.target.value);
                              setFundCodeErr('');

                            }}>
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Portfolio</label>
                          <Select
                            className={selectedPortfolioErr}
                            value={selectedPortfolioVal}
                            label={"Select Portfolio"}
                            onChange={(e) => {
                              setSelectedPortfolio(e?.value);
                              setSelectedPortfolioErr("");
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={portfolioArr}
                            styles={customStyles}
                          />
                          {/* <select
                            className={"form-control " + selectedPortfolioErr}
                            value={selectedPortfolio}
                            onChange={(e) => {
                              setSelectedPortfolio(e.target.value);
                              setSelectedPortfolioErr('');

                            }}>
                            <option value="" defaultChecked hidden>
                              Select Portfolio
                            </option>
                            <option value="Money Market">Money Market</option>
                            <option value="Stock Market">Stock Market</option>
                          </select> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            value={createdDate}
                            className={"form-control " + createdDateErr}
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setCreatedDate(date);
                              setCreatedDateErr("");
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={"Daily-Fund-Portfolio-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {fundPortfolio.length > 0 ? (
                          <>
                            <ReportHeader title="Daily Fund Portfolio" />

                            <Row>
                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFundCode}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Portfolio:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resSelectedPortfolio}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              {/* <Col md="4" style={{ marginLeft: '70px' }}>
                                <Row>
                                  <Col md="5">
                                    <span className="text-nowrap" style={{ fontWeight: 'bold' }}>
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{amc_name}</span>
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Date Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resCreatedDate).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Report Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(date).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              {resSelectedPortfolio == "Money Market" ? (
                                <table
                                  className="report"
                                  style={{ color: "black" }}
                                >
                                  <thead style={{ alignSelf: "center" }}>
                                    <tr className="header-tr">
                                      <th>Symbol</th>
                                      <th>Security Type</th>
                                      <th>Name</th>
                                      <th>Issue Date</th>
                                      <th>Maturity Date</th>
                                      <th>Coupon Rate %</th>
                                      <th>Yield %</th>
                                      <th>Money Market Face Value/Unit</th>
                                      <th>Total Face Value</th>
                                      <th>Units</th>
                                      <th>Avg Cost/Unit</th>
                                      <th>Total Cost / Amount</th>
                                      <th>Market Rate/Unit</th>
                                      <th>Market Value</th>
                                      <th>Unrealized Gain/(Loss)</th>
                                      <th>Investment as % of Market Value</th>
                                    </tr>
                                  </thead>
                                  <tbody className="report-table-body">
                                    {moneyMarketPortfolio.map(
                                      (items: any, index: number) => {
                                        //  if(items.code!==undefined && items.code.startsWith('~~~')==false){
                                        coupon_rate = (
                                          +coupon_rate + +items.coupon_rate
                                        ).toFixed(2);
                                        yields = (
                                          +yields + +items.yield
                                        ).toFixed(2);
                                        mmface_value = (
                                          +mmface_value + +items.mmface_value
                                        ).toFixed(4);
                                        total_face_value = (
                                          +total_face_value +
                                          +items.total_face_value
                                        ).toFixed(4);
                                        units = (+units + +items.units).toFixed(
                                          4
                                        );
                                        total_amount = (
                                          +total_amount + +items.total_amount
                                        ).toFixed(2);
                                        market_value = (
                                          +market_value + +items.market_value
                                        ).toFixed(2);
                                        unrealized_gain_loss = (
                                          +unrealized_gain_loss +
                                          +items.unrealized_gain_loss
                                        ).toFixed(2);
                                        investment_percentage_market_value = (
                                          +investment_percentage_market_value +
                                          +items.investment_percentage_market_value
                                        ).toFixed(2);
                                        //  }
                                        //    if(items.code!==undefined && items.code.startsWith('~~~')){
                                        //     return (
                                        //     <>
                                        //     <tr className='footer-tr' key={index}>
                                        //     <td>{items.code}</td>
                                        //     <td>{items.security_type}</td>
                                        //     <td>{items.name}</td>
                                        //     <td>{items.issue_date}</td>
                                        //     <td>{items.maturity_date}</td>
                                        //     <td>{numberWithCommas(items.coupon_rate || '')}</td>
                                        //     <td>{numberWithCommas(items.yield || '')}</td>
                                        //     <td>{numberWithCommas(items.mmface_value || '')}</td>
                                        //     <td>{numberWithCommas(items.total_face_value || '')}</td>
                                        //     {/* <td>{numberWithCommas(items.price || '')}</td> */}
                                        //     <td>{numberWithCommas(items.units || '')}</td>
                                        //     <td>{numberWithCommas(items.average_cost_per_unit || '')}</td>
                                        //     <td> {numberWithCommas(items.total_amount || '') && numberWithCommas(parseFloat(items.total_amount).toFixed(2))} </td>
                                        //     <td> {numberWithCommas(items.market_rate_per_unit || '')} </td>
                                        //     <td> {numberWithCommas(items.market_value || '')} </td>
                                        //     <td> {numberWithCommas(items.unrealized_gain_loss || '')} </td>
                                        //     <td> {numberWithCommas(items.investment_percentage_market_value || '')} </td>
                                        //     </tr>
                                        //     </>
                                        // );
                                        //     }else{
                                        return (
                                          <>
                                            <tr key={index}>
                                              <td>{items.code}</td>
                                              <td>{items.security_type}</td>
                                              <td>{items.name}</td>
                                              <td>{items.issue_date}</td>
                                              <td>{items.maturity_date}</td>
                                              <td>
                                                {+items.coupon_rate < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.coupon_rate
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.coupon_rate || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.yield < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.yield
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.yield || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.mmface_value < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.mmface_value
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.mmface_value || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.total_face_value < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.total_face_value
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.total_face_value ||
                                                        ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.units < 0
                                                  ? numberWithCommas(
                                                      items.units || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.units || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.average_cost_per_unit <
                                                0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.average_cost_per_unit
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.average_cost_per_unit ||
                                                        ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.total_amount < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.total_amount
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.total_amount || ""
                                                    )}{" "}
                                              </td>
                                              <td>
                                                {+items.market_rate_per_unit < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.market_rate_per_unit
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.market_rate_per_unit ||
                                                        ""
                                                    )}{" "}
                                              </td>
                                              <td>
                                                {+items.market_value < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.market_value
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.market_value || ""
                                                    )}{" "}
                                              </td>
                                              <td>
                                                {+items.unrealized_gain_loss < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.unrealized_gain_loss
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.unrealized_gain_loss ||
                                                        ""
                                                    )}{" "}
                                              </td>
                                              <td>
                                                {+items.investment_percentage_market_value <
                                                0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.investment_percentage_market_value
                                                          .toString()
                                                          .replace("-", "") +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.investment_percentage_market_value ||
                                                        ""
                                                    )}{" "}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                        // }
                                      }
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr style={{ backgroundColor: "#7eb4d3" }}>
                                      <th colSpan={2}>Grand Total</th>
                                      {/* <td></td> */}
                                      <th></th>
                                      <th></th>
                                      <th></th>
                                      <th>
                                        {+coupon_rate < 0
                                          ? numberWithCommas(
                                              "(" +
                                                coupon_rate.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(coupon_rate || "")}
                                      </th>
                                      <th>
                                        {+yields < 0
                                          ? numberWithCommas(
                                              "(" +
                                                yields.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(yields || "")}
                                      </th>
                                      <th>
                                        {+mmface_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                mmface_value.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              mmface_value || ""
                                            )}
                                      </th>
                                      <th>
                                        {+total_face_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                total_face_value.replace(
                                                  "-",
                                                  ""
                                                ) +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              total_face_value || ""
                                            )}
                                      </th>
                                      <th>
                                        {+units < 0
                                          ? numberWithCommas(
                                              "(" +
                                                units.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(units || "")}
                                      </th>
                                      <th></th>
                                      <th>
                                        {+total_amount < 0
                                          ? numberWithCommas(
                                              "(" +
                                                total_amount.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              total_amount || ""
                                            )}{" "}
                                      </th>
                                      <th></th>
                                      <th>
                                        {+market_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                market_value.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              market_value || ""
                                            )}
                                      </th>
                                      <th>
                                        {+unrealized_gain_loss < 0
                                          ? numberWithCommas(
                                              "(" +
                                                unrealized_gain_loss.replace(
                                                  "-",
                                                  ""
                                                ) +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              unrealized_gain_loss || ""
                                            )}{" "}
                                      </th>
                                      <th>
                                        {+investment_percentage_market_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                investment_percentage_market_value.replace(
                                                  "-",
                                                  ""
                                                ) +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              investment_percentage_market_value ||
                                                ""
                                            )}{" "}
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                              ) : (
                                <table
                                  className="report"
                                  style={{ color: "black" }}
                                >
                                  <thead style={{ alignSelf: "center" }}>
                                    <tr className="header-tr">
                                      <th>Symbol</th>
                                      <th>Market</th>
                                      <th>Total Units</th>
                                      <th>Available Units</th>
                                      <th>Average Cost / Unit</th>
                                      <th>Total Cost / Amount</th>
                                      <th>Market Rate/Unit</th>
                                      <th>Market Value</th>
                                      <th>Unrealzed Gain/(Loss)</th>
                                      <th>Investment as % Market Value</th>
                                    </tr>
                                  </thead>
                                  <tbody className="report-table-body">
                                    {stockMarketPortfolio.map(
                                      (items: any, index: number) => {
                                        //  if(items.code!==undefined && items.code.startsWith('~~~')==false){
                                        units = (+units + +items.unit).toFixed(
                                          4
                                        );
                                        total_units = (
                                          +total_units + +items.total_units
                                        ).toFixed(4);
                                        total_amount = (
                                          +total_amount + +items.amount
                                        ).toFixed(2);
                                        market_value = (
                                          +market_value + +items.market_value
                                        ).toFixed(2);
                                        unrealized_gain_loss = (
                                          +unrealized_gain_loss +
                                          +items.unrealized_gain_loss
                                        ).toFixed(2);
                                        investment_percentage_market_value = (
                                          +investment_percentage_market_value +
                                          +items.investment_percentage_market_value
                                        ).toFixed(2);
                                        //  }
                                        //  if(items.code!==undefined && items.code.startsWith('~~~')){
                                        //    return (
                                        // <>
                                        // <tr className='footer-tr' key={index}>
                                        // <td>{items.code}</td>
                                        // <td>{items.market}</td>
                                        // <td>{numberWithCommas(items.total_units || '') && numberWithCommas(items.total_units || '')}</td>
                                        // <td>{numberWithCommas(items.unit || '') && numberWithCommas(items.unit || '')}</td>
                                        // <td>{numberWithCommas(items.average_cost_per_unit)}</td>
                                        // <td>{numberWithCommas(items.amount || '') && numberWithCommas(parseFloat(items.amount).toFixed(2))}</td>
                                        // <td>{numberWithCommas(items.market_rate_per_unit)}</td>
                                        // <td>{numberWithCommas(items.market_value)}</td>
                                        // <td>{numberWithCommas(items.unrealized_gain_loss)}</td>
                                        // <td>{numberWithCommas(items.investment_percentage_market_value)}</td>
                                        // </tr>
                                        //   </>
                                        // );
                                        //    }else{
                                        return (
                                          <>
                                            <tr key={index}>
                                              <td>{items.code}</td>
                                              <td>{items.market}</td>
                                              <td>
                                                {+items.total_units < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.total_units.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.total_units || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.unit < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.unit.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.unit || ""
                                                    )}
                                              </td>
                                              <td>
                                                {+items.average_cost_per_unit <
                                                0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.average_cost_per_unit.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")"
                                                    )
                                                  : numberWithCommas(
                                                      items.average_cost_per_unit
                                                    )}
                                              </td>
                                              <td>
                                                {+items.amount < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.amount.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")" || ""
                                                    )
                                                  : numberWithCommas(
                                                      items.amount
                                                    )}
                                              </td>
                                              <td>
                                                {+items.market_rate_per_unit < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.market_rate_per_unit.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")"
                                                    )
                                                  : numberWithCommas(
                                                      items.market_rate_per_unit
                                                    )}
                                              </td>
                                              <td>
                                                {+items.market_value < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.market_value.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")"
                                                    )
                                                  : numberWithCommas(
                                                      items.market_value
                                                    )}
                                              </td>
                                              <td>
                                                {+items.unrealized_gain_loss < 0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.unrealized_gain_loss.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")"
                                                    )
                                                  : numberWithCommas(
                                                      items.unrealized_gain_loss
                                                    )}
                                              </td>
                                              <td>
                                                {+items.investment_percentage_market_value <
                                                0
                                                  ? numberWithCommas(
                                                      "(" +
                                                        items.investment_percentage_market_value.replace(
                                                          "-",
                                                          ""
                                                        ) +
                                                        ")"
                                                    )
                                                  : numberWithCommas(
                                                      items.investment_percentage_market_value
                                                    )}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                        //  }
                                      }
                                    )}
                                  </tbody>
                                  <tfoot>
                                    <tr style={{ backgroundColor: "#7eb4d3" }}>
                                      <th>Grand Total</th>
                                      <th></th>
                                      <th>
                                        {+total_units < 0
                                          ? numberWithCommas(
                                              "(" +
                                                total_units.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(total_units || "")}
                                      </th>
                                      <th>
                                        {+units < 0
                                          ? numberWithCommas(
                                              "(" +
                                                units.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(units || "")}
                                      </th>
                                      <th></th>
                                      <th>
                                        {+total_amount < 0
                                          ? numberWithCommas(
                                              "(" +
                                                total_amount.replace("-", "") +
                                                ")" || ""
                                            )
                                          : numberWithCommas(
                                              total_amount || ""
                                            )}
                                      </th>
                                      <th></th>
                                      <th>
                                        {+market_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                market_value.replace("-", "") +
                                                ")"
                                            )
                                          : numberWithCommas(market_value)}
                                      </th>
                                      <th>
                                        {+unrealized_gain_loss < 0
                                          ? numberWithCommas(
                                              "(" +
                                                unrealized_gain_loss.replace(
                                                  "-",
                                                  ""
                                                ) +
                                                ")"
                                            )
                                          : numberWithCommas(
                                              unrealized_gain_loss
                                            )}
                                      </th>
                                      <th>
                                        {+investment_percentage_market_value < 0
                                          ? numberWithCommas(
                                              "(" +
                                                investment_percentage_market_value.replace(
                                                  "-",
                                                  ""
                                                ) +
                                                ")".replace("-", "")
                                            )
                                          : numberWithCommas(
                                              investment_percentage_market_value
                                            )}
                                      </th>
                                    </tr>
                                  </tfoot>
                                </table>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center text-primary empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyPortfolioReport;

import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import {
  getFundByAmc,
  validateFundNav,
} from "../stores/services/funds.service";
import {
  getChartOfAccounts,
  getTransactionsLedgers,
} from "../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
  getLedgersByTxn,
} from "../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { numberWithCommas } from "./../utils/customFunction";
import { getPortfolioByDate } from "../stores/services/daily-unrealized-portfolio.service";
import { useSelector } from "react-redux";
import Select from "react-select";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyUnrealizedPortfolio = (props: any) => {
  const marketArr = [
    {
      value: "money_market",
      label: "Money Market",
    },
    {
      value: "stock_market",
      label: " Stock Market",
    },
  ];

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCheck, setFundCheck] = useState<any>(false);
  const [fund, setFund] = useState("");
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [tableData, setTableData] = useState<any>([]);
  let [todayData, setTodayData] = useState<any>([]);
  let [yesterdayData, setYesterdayData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [marketSelection, setMarketSelection] = useState<any>("");
  const [marketSelectionOption, setMarketSelectionOption] =
    useState<any>("Select Market Type");
  const [marketSelectionError, setMarketSelectionError] = useState<any>(false);

  let [yesterdayPIBTotal, setYesterdayPIBTotal] = useState<any>(0);
  let [yesterdayTBILLTotal, setYesterdayTBILLTotal] = useState<any>(0);
  let [yesterdayTFCTotal, setYesterdayTFCTotal] = useState<any>(0);
  let [yesterdaySUKUKTotal, setYesterdaySUKUKTotal] = useState<any>(0);

  let [todayPIBTotal, setTodayPIBTotal] = useState<any>(0);
  let [todayTBILLTotal, setTodayTBILLTotal] = useState<any>(0);
  let [todayTFCTotal, setTodayTFCTotal] = useState<any>(0);
  let [todaySUKUKTotal, setTodaySUKUKTotal] = useState<any>(0);

  let [finalPIB, setFinalPIB] = useState<any>(0);
  let [finalTBILL, setFinalTBILL] = useState<any>(0);
  let [finalTFC, setFinalTFC] = useState<any>(0);
  let [finalSUKUK, setFinalSUKUK] = useState<any>(0);

  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [todayDate, setTodayDate] = useState<any>(today);
  const [date, setDate] = useState<any>(today);

  let timeStamp = new Date().getTime();
  let yesterdayTimeStamp = timeStamp - 24 * 60 * 60 * 1000;
  let yesterday: any = new Date(yesterdayTimeStamp);
  let ddY = String(yesterday.getDate()).padStart(2, "0");
  let mmY = String(yesterday.getMonth() + 1).padStart(2, "0");
  let yyyyY = yesterday.getFullYear();
  yesterday = yyyyY + "-" + mmY + "-" + ddY;

  const [yesterdayDate, setYesterdayDate] = useState<any>(yesterday);

  const [disabled, setDisabled] = useState<any>(false);
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [transactionLedgers, setTransactionLedgers] = useState<any>("");

  const [pibLedgerPayment, setPibLedgerPayment] = useState<any>();
  const [pibLedgerRecipt, setPibLedgerRecipt] = useState<any>();

  let [affectedLedgerData, setAffectedLedgerData] = useState<any>([]);

  const myRef = useRef<any>(null);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "dailyunrealizedportfolio",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: 0,
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  useEffect(() => {
    fetchTransactionLedgers();
  }, []);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    getFundByAMcCode(amc_code);
  }, []);

  const scrollToTop = () => {
    return myRef?.current?.scrollIntoView();
  };
  const fetchTransactionLedgers = async () => {
    try {
      const response = await getTransactionsLedgers(email);
      if (response.data.status === 200) {
        let unitManagement = response.data.data.txn_catagory[4].portfolioTxn;
        // let txnLegders = await getLedgersByTxn(
        //   unitManagement,
        //   "dailyunrealizedportfolio"
        // );
        let txnLegders = unitManagement.filter((item, index) => {
          return item.api_name === "dailyunrealizedportfolio";
        });
        setTransactionLedgers(txnLegders);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {}
  };

  // const renderFundsDropdown = () => {
  //   return allFunds.map((item: any, index: string) => {
  //     if (item.nature === "Special Managed Account") {
  //       return (
  //         <>
  //           <option key={index} value={item.symbol_code}>
  //             {item.fund_name} ({item.symbol_code}) - {"SMA"}
  //           </option>
  //         </>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.symbol_code}>
  //           {item.fund_name} ({item.symbol_code})
  //         </option>
  //       );
  //     }
  //   });
  // };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code}) - {"SMA"}`,
        };
      } else {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} ${item.symbol_code}`,
        };
      }
    });
  };

  const handleMarketChange = async (e) => {
    setLoading(true);
    setTableData([]);
    setTodayData([]);
    setYesterdayData([]);
    setAffectedLedgerData([]);
    setMarketSelection("");
    setSendingLedgers([]);
    setYesterdayPIBTotal(0);
    setYesterdayTBILLTotal(0);
    setYesterdayTFCTotal(0);
    setYesterdaySUKUKTotal(0);
    setTodayPIBTotal(0);
    setTodayTBILLTotal(0);
    setTodayTFCTotal(0);
    setTodaySUKUKTotal(0);
    setFinalPIB(0);
    setFinalTBILL(0);
    setFinalTFC(0);
    setFinalSUKUK(0);
    // setMarketSelection(e.target.value);
    setMarketSelection(e?.value);
    // setMarketSelectionOption(e.target.selectedOptions[0].text);
    setMarketSelectionOption(e?.value);
    setLoading(false);
  };

  const handleGet = async () => {
    setLoading(true);
    setTableData([]);
    setTodayData([]);
    setYesterdayData([]);
    setAffectedLedgerData([]);
    setSendingLedgers([]);
    setYesterdayPIBTotal(0);
    setYesterdayTBILLTotal(0);
    setYesterdayTFCTotal(0);
    setYesterdaySUKUKTotal(0);
    setTodayPIBTotal(0);
    setTodayTBILLTotal(0);
    setTodayTFCTotal(0);
    setTodaySUKUKTotal(0);
    setFinalPIB(0);
    setFinalTBILL(0);
    setFinalTFC(0);
    setFinalSUKUK(0);

    let selectedFund = allFunds.filter((item) => {
      return item.symbol_code === fund;
    });

    const todayResponse = await getPortfolioByDate(
      email,
      amc_code,
      fund,
      todayDate
    );

    if (todayResponse.data.status === 404) {
      setLoading(false);
      setDisabled(true);

      return toast.error("No record found today for selected fund!");
    } else {
      setDisabled(false);
    }

    const yesterdayResponse = await getPortfolioByDate(
      email,
      amc_code,
      fund,
      yesterdayDate
    );

    selectedFund.map((item, index) => {
      if (marketSelection === "money_market") {
        if (JSON.parse(item.money_market).length === 0) {
          setLoading(false);

          return toast.error(
            "No record available for money market in selected fund"
          );
        } else {
          if (todayResponse.data.status === 404) {
            setLoading(false);

            return toast.error("No record found for Today");
          } else {
            JSON.parse(todayResponse?.data?.data[0]?.money_market).map(
              (item, index) => {
                if (item.security_type === "PIB") {
                  todayPIBTotal =
                    parseInt(todayPIBTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayPIBTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TBILL") {
                  todayTBILLTotal =
                    parseInt(todayTBILLTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayTBILLTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TFC") {
                  todayTFCTotal =
                    parseInt(todayTFCTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayTFCTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "SUKUK") {
                  todaySUKUKTotal =
                    parseInt(todaySUKUKTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodaySUKUKTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }
              }
            );
            todayData = JSON.parse(todayResponse?.data?.data[0]?.money_market);
            setTodayData(todayData);
          }

          if (yesterdayResponse.data.status === 404) {
            setLoading(false);

            return toast.error("No record found for yesterday!");
          } else {
            JSON.parse(yesterdayResponse?.data?.data[0]?.money_market).map(
              (item, index) => {
                if (item.security_type === "PIB") {
                  yesterdayPIBTotal =
                    parseInt(yesterdayPIBTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayPIBTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TBILL") {
                  yesterdayTBILLTotal =
                    parseInt(yesterdayTBILLTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayTBILLTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TFC") {
                  yesterdayTFCTotal =
                    parseInt(yesterdayTFCTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayTFCTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "SUKUK") {
                  yesterdaySUKUKTotal =
                    parseInt(yesterdaySUKUKTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdaySUKUKTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }
              }
            );

            yesterdayData = JSON.parse(
              yesterdayResponse?.data?.data[0]?.money_market
            );

            setYesterdayData(yesterdayData);
          }
        }
        setTableData(JSON.parse(item.money_market));
      } else if (marketSelection === "stock_market") {
        if (JSON.parse(item.stock_market).length === 0) {
          setLoading(false);

          return toast.error(
            "No record available for stock market in selected fund"
          );
        } else {
          if (todayResponse.data.status === 404) {
            setLoading(false);

            return toast.error("No record found for Today");
          } else {
            JSON.parse(todayResponse.data.data[0].stock_market).map(
              (item, index) => {
                if (item.security_type === "PIB") {
                  todayPIBTotal =
                    parseInt(todayPIBTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayPIBTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TBILL") {
                  todayTBILLTotal =
                    parseInt(todayTBILLTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayTBILLTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TFC") {
                  todayTFCTotal =
                    parseInt(todayTFCTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodayTFCTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "SUKUK") {
                  todaySUKUKTotal =
                    parseInt(todaySUKUKTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setTodaySUKUKTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }
              }
            );
            todayData = JSON.parse(todayResponse.data.data[0].stock_market);
            setTodayData(todayData);
          }

          if (yesterdayResponse.data.status === 404) {
            setLoading(false);

            return toast.error("No record found for yesterday!");
          } else {
            JSON.parse(yesterdayResponse?.data?.data[0]?.stock_market).map(
              (item, index) => {
                if (item.security_type === "PIB") {
                  yesterdayPIBTotal =
                    parseInt(yesterdayPIBTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayPIBTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TBILL") {
                  yesterdayTBILLTotal =
                    parseInt(yesterdayTBILLTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayTBILLTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "TFC") {
                  yesterdayTFCTotal =
                    parseInt(yesterdayTFCTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdayTFCTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }

                if (item.security_type === "SUKUK") {
                  yesterdaySUKUKTotal =
                    parseInt(yesterdaySUKUKTotal || "0") +
                    parseInt(item.unrealized_gain_loss || "0");
                  // setYesterdaySUKUKTotal(
                  //   (prevState) =>
                  //     parseInt(prevState) + parseInt(item.unrealized_gain_loss)
                  // );
                }
              }
            );
            yesterdayData = JSON.parse(
              yesterdayResponse?.data?.data[0]?.stock_market
            );
            setYesterdayData(yesterdayData);
          }
        }
        tableData = JSON.parse(item.stock_market);
        setTableData(tableData);
      }

      return tableData;
    });

    if (yesterdayData.length === 0) {
      setLoading(false);
      return toast.error("No data for yesterday");
    }
    if (todayData.length === 0) {
      setLoading(false);
      return toast.error("No data for today");
    }

    // sendingLedgers = [];
    finalPIB = parseInt(todayPIBTotal) - parseInt(yesterdayPIBTotal);
    finalTBILL = parseInt(todayTBILLTotal) - parseInt(yesterdayTBILLTotal);
    finalTFC = parseInt(todayTFCTotal) - parseInt(yesterdayTFCTotal);
    finalSUKUK = parseInt(todaySUKUKTotal) - parseInt(yesterdaySUKUKTotal);

    if (
      finalPIB > 0 &&
      !isNaN(finalPIB) &&
      finalPIB !== null &&
      finalPIB !== undefined &&
      finalPIB !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "PIB" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "PIB Debit",
          debit: finalPIB,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "PIB Credit",
          debit: 0,
          credit: finalPIB,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: finalPIB,
          description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: finalPIB,
          debit_amount: "0",
          description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) + parseFloat(finalPIB)
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalPIB,
      //         description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalPIB,
      //         debit_amount: "0",
      //         description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];

      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalPIB,
      //         description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalPIB,
      //         debit_amount: "0",
      //         description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];

      // }
    }

    if (
      finalPIB < 0 &&
      !isNaN(finalPIB) &&
      finalPIB !== null &&
      finalPIB !== undefined &&
      finalPIB !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "PIB" && item.payment_type === "Payment";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "PIB Debit",
          debit: finalPIB,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "PIB Credit",
          debit: 0,
          credit: finalPIB,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "PIB Debit",
      //       debit: finalPIB,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "PIB Credit",
      //       debit: 0,
      //       credit: finalPIB,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "PIB Debit",
      //       debit: finalPIB,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "PIB Credit",
      //       debit: 0,
      //       credit: finalPIB,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: Math.abs(finalPIB),
          description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: Math.abs(finalPIB),
          debit_amount: "0",
          description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) +
        parseFloat(Math.abs(finalPIB).toString())
      ).toFixed(2);
      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalPIB),
      //         description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalPIB),
      //         debit_amount: "0",
      //         description: `PIB Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalPIB),
      //         description: `PIB Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalPIB),
      //         debit_amount: "0",
      //         description: `Credit Ledger ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    //3rd
    if (
      finalTBILL > 0 &&
      !isNaN(finalTBILL) &&
      finalTBILL !== null &&
      finalTBILL !== undefined &&
      finalTBILL !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TBILL" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TBILL Debit",
          debit: finalTBILL,
          credit: 0,
        },
        //credit
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TBILL Credit",
          debit: 0,
          credit: finalTBILL,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Debit",
      //       debit: finalTBILL,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Credit",
      //       debit: 0,
      //       credit: finalTBILL,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Debit",
      //       debit: finalTBILL,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Credit",
      //       debit: 0,
      //       credit: finalTBILL,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: finalTBILL,
          description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: finalTBILL,
          debit_amount: "0",
          description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) + parseFloat(finalTBILL)
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalTBILL,
      //         description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalTBILL,
      //         debit_amount: "0",
      //         description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalTBILL,
      //         description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalTBILL,
      //         debit_amount: "0",
      //         description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    //4th
    if (
      finalTBILL < 0 &&
      !isNaN(finalTBILL) &&
      finalTBILL !== null &&
      finalTBILL !== undefined &&
      finalTBILL !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TBILL" && item.payment_type === "Payment";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TBILL Debit",
          debit: finalTBILL,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TBILL Credit",
          debit: 0,
          credit: finalTBILL,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Debit",
      //       debit: finalTBILL,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Credit",
      //       debit: 0,
      //       credit: finalTBILL,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Debit",
      //       debit: finalTBILL,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TBILL Credit",
      //       debit: 0,
      //       credit: finalTBILL,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: Math.abs(finalTBILL),
          description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        //credit
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: Math.abs(finalTBILL),
          debit_amount: "0",
          description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) +
        parseFloat(Math.abs(finalTBILL).toString())
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalTBILL),
      //         description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalTBILL),
      //         debit_amount: "0",
      //         description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalTBILL),
      //         description: `TBILL Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalTBILL),
      //         debit_amount: "0",
      //         description: `TBILL Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];

      // }
    }

    //5th
    if (
      finalTFC > 0 &&
      !isNaN(finalTFC) &&
      finalTFC !== null &&
      finalTFC !== undefined &&
      finalTFC !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TFC" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TFC Debit",
          debit: finalTFC,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TFC Credit",
          debit: 0,
          credit: finalTFC,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Debit",
      //       debit: finalTFC,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Credit",
      //       debit: 0,
      //       credit: finalTFC,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Debit",
      //       debit: finalTFC,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Credit",
      //       debit: 0,
      //       credit: finalTFC,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: finalTFC,
          description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        //credit
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: finalTFC,
          debit_amount: "0",
          description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) + parseFloat(finalTFC)
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalTFC,
      //         description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalTFC,
      //         debit_amount: "0",
      //         description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalTFC,
      //         description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalTFC,
      //         debit_amount: "0",
      //         description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    //6th
    if (
      finalTFC < 0 &&
      !isNaN(finalTFC) &&
      finalTFC !== null &&
      finalTFC !== undefined &&
      finalTFC !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "TFC" && item.payment_type === "Payment";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "TFC Debit",
          debit: finalTFC,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "TFC Credit",
          debit: 0,
          credit: finalTFC,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Debit",
      //       debit: finalTFC,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Credit",
      //       debit: 0,
      //       credit: finalTFC,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Debit",
      //       debit: finalTFC,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "TFC Credit",
      //       debit: 0,
      //       credit: finalTFC,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: Math.abs(finalTFC),
          description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: Math.abs(finalTFC),
          debit_amount: "0",
          description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) +
        parseFloat(Math.abs(finalTFC).toString())
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalTFC),
      //         description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalTFC),
      //         debit_amount: "0",
      //         description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalTFC),
      //         description: `TFC Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalTFC),
      //         debit_amount: "0",
      //         description: `TFC Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    //7th
    if (
      finalSUKUK > 0 &&
      !isNaN(finalSUKUK) &&
      finalSUKUK !== null &&
      finalSUKUK !== undefined &&
      finalSUKUK !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "SUKUK" && item.payment_type === "Receipt";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "SUKUK Debit",
          debit: finalSUKUK,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "SUKUK Credit",
          debit: 0,
          credit: finalSUKUK,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Debit",
      //       debit: finalSUKUK,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Credit",
      //       debit: 0,
      //       credit: finalSUKUK,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUKDebit",
      //       debit: finalSUKUK,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Credit",
      //       debit: 0,
      //       credit: finalSUKUK,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: finalSUKUK,
          description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        //credit
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: finalSUKUK,
          debit_amount: "0",
          description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      fields["net_amount"] = (
        parseFloat(fields.net_amount) + parseFloat(finalSUKUK)
      ).toFixed(2);

      // if (sendingLedgers.length > 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalSUKUK,
      //         description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalSUKUK,
      //         debit_amount: "0",
      //         description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: finalSUKUK,
      //         description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: finalSUKUK,
      //         debit_amount: "0",
      //         description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    //8th
    if (
      finalSUKUK < 0 &&
      !isNaN(finalSUKUK) &&
      finalSUKUK !== null &&
      finalSUKUK !== undefined &&
      finalSUKUK !== ""
    ) {
      let affectedLedger = transactionLedgers.filter((item, index) => {
        return item.security === "SUKUK" && item.payment_type === "Payment";
      });

      let debitLedger: any = [];
      let creditLedger: any = [];
      affectedLedger[0].ledgers.map((ledger, index) => {
        if (ledger.type === "Debit") {
          debitLedger = ledger;
        }
        if (ledger.type === "Credit") {
          creditLedger = ledger;
        }
      });

      affectedLedgerData.push(
        {
          glCode: debitLedger.ledger_root[0].gl_code,
          glName: debitLedger.ledger_root[0].gl_name,
          familyName: debitLedger.ledger_root[0].family_name,
          description: "SUKUK Debit",
          debit: finalSUKUK,
          credit: 0,
        },
        {
          glCode: creditLedger.ledger_root[0].gl_code,
          glName: creditLedger.ledger_root[0].gl_name,
          familyName: creditLedger.ledger_root[0].family_name,
          description: "SUKUK Credit",
          debit: 0,
          credit: finalSUKUK,
        }
      );

      setAffectedLedgerData(affectedLedgerData);

      // if (affectedLedgerData?.length > 0) {
      //   affectedLedgerData = [
      //     //debit
      //     ...affectedLedgerData,
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Debit",
      //       debit: finalSUKUK,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Credit",
      //       debit: 0,
      //       credit: finalSUKUK,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // } else {
      //   affectedLedgerData = [
      //     //debit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUKDebit",
      //       debit: finalSUKUK,
      //       credit: 0,
      //     },
      //     //credit
      //     {
      //       glCode: debitLedger.ledger_root[0].gl_code,
      //       glName: debitLedger.ledger_root[0].gl_name,
      //       familyName: debitLedger.ledger_root[0].family_name,
      //       description: "SUKUK Credit",
      //       debit: 0,
      //       credit: finalSUKUK,
      //     },
      //   ];
      //   setAffectedLedgerData(affectedLedgerData);
      // }

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: debitLedger.ledger_root[0].child_family_code,
          child_family_name: debitLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: Math.abs(finalSUKUK),
          description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: debitLedger.ledger_root[0].family_code,
          family_name: debitLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: debitLedger.ledger_root[0].gl_code,
          gl_name: debitLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: debitLedger.ledger_root[0].main_family_code,
          main_family_name: debitLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: debitLedger.ledger_root[0].sub_family_code,
          sub_family_name: debitLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: creditLedger.ledger_root[0].child_family_code,
          child_family_name: creditLedger.ledger_root[0].child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: Math.abs(finalSUKUK),
          debit_amount: "0",
          description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: creditLedger.ledger_root[0].family_code,
          family_name: creditLedger.ledger_root[0].family_name,
          fund_code: fund,
          gl_code: creditLedger.ledger_root[0].gl_code,
          gl_name: creditLedger.ledger_root[0].gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: creditLedger.ledger_root[0].main_family_code,
          main_family_name: creditLedger.ledger_root[0].main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: creditLedger.ledger_root[0].sub_family_code,
          sub_family_name: creditLedger.ledger_root[0].sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );

      setSendingLedgers(sendingLedgers);
      let cal = Math.abs(finalSUKUK);
      fields["net_amount"] = (parseFloat(fields.net_amount) + cal).toFixed(2);

      // if (sendingLedgers.length !== 0) {

      //     sendingLedgers = [
      //       ...sendingLedgers,
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalSUKUK),
      //         description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalSUKUK),
      //         debit_amount: "0",
      //         description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // } else {

      //     sendingLedgers = [
      //       //debit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: debitLedger.ledger_root[0].child_family_code,
      //         child_family_name: debitLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: "0",
      //         debit_amount: Math.abs(finalSUKUK),
      //         description: `SUKUK Debit ${debitLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: debitLedger.ledger_root[0].family_code,
      //         family_name: debitLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: debitLedger.ledger_root[0].gl_code,
      //         gl_name: debitLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: debitLedger.ledger_root[0].main_family_code,
      //         main_family_name: debitLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: debitLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: debitLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //       //credit
      //       {
      //         account_number: "",
      //         account_title: "",
      //         amc_code: amc_code,
      //         balance: "",
      //         bank_code: "",
      //         branch: "",
      //         child_family_code: creditLedger.ledger_root[0].child_family_code,
      //         child_family_name: creditLedger.ledger_root[0].child_family_name,
      //         counter_account_number: "",
      //         counter_account_title: "",
      //         counter_bank: "",
      //         counter_branch: "",
      //         created_at: "",
      //         credit_amount: Math.abs(finalSUKUK),
      //         debit_amount: "0",
      //         description: `SUKUK Credit ${creditLedger.ledger_root[0].gl_name}`,
      //         doc_type: "",
      //         entered_by: "",
      //         evidence: "",
      //         family_code: creditLedger.ledger_root[0].family_code,
      //         family_name: creditLedger.ledger_root[0].family_name,
      //         fund_code: fund,
      //         gl_code: creditLedger.ledger_root[0].gl_code,
      //         gl_name: creditLedger.ledger_root[0].gl_name,
      //         instruction_date: "",
      //         instrument_by: "",
      //         instrument_date: "",
      //         instrument_no: "",
      //         instrument_type: "",
      //         main_family_code: creditLedger.ledger_root[0].main_family_code,
      //         main_family_name: creditLedger.ledger_root[0].main_family_name,
      //         realized: "true",
      //         realized_by: "",
      //         realized_date: "",
      //         sub_family_code: creditLedger.ledger_root[0].sub_family_code,
      //         sub_family_name: creditLedger.ledger_root[0].sub_family_name,
      //         period: sessionStorage.getItem("active_period") || "",
      //       },
      //     ];
      // }
    }

    setLoading(false);
  };

  // handle realized (move sending ledger logic to handle get function )

  const handleRealized = async () => {
    setLoading(true);
    scrollToTop();

    if (sendingLedgers.length !== 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.txnr_type = "dailyunrealizedportfolio";
      fields.net_amount = fields.net_amount.toString();

      if (fields.ledgers.length > 0) {
        try {
          const navCheck = await validateFundNav(
            sessionStorage.getItem("email") || "",
            amc_code,
            fields?.fund_code,
            fields?.settlement_date
          );

          if (navCheck?.status === 200 && navCheck?.data?.status !== 200) {
            toast.error(navCheck?.data?.message);
            setLoading(false);
            return;
          }
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            // setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            // setSendingLedgers([]);
          } else if (result.data.status === 200) {
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`Data added. ID: ${result.data.txn_id}`);

            setTimeout(() => {
              toast.success("");
              // history.push("/admin/create-transaction");
              //history.replace(`/admin/transactions-funds-management`);
              history.goBack();
            }, 1500);
            // toast.success(response.data.message);
          } else {
            toast.error(result.data.message);
          }
        } catch (error: any) {
          setLoading(false);
          toast.error(
            error.response
              ? error.response.data.message?.toString()
              : error.message
              ? error.message
              : "Request Faild"
          );
        }
      }
    } else {
      setLoading(false);
      return toast.error("No changes made in yesterday & today!");
    }

    // setLoading(false);
  };

  const sumTotalColData = (dataTemp, col) => {
    let temp = 0;
    dataTemp.map((item, index) => {
      temp += parseFloat(item[col] || "0");
    });

    return numberWithCommas(temp);
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund && selectedFund.nature === "Special Managed Account"
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code}) - {"SMA"}`,
        }
      : selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fund]);

  const selectedMarketName = React.useMemo(() => {
    const selectedFund = marketArr?.find(
      (item) => item?.value === marketSelection
    );
    return selectedFund
      ? {
          value: selectedFund.value,
          label: `${selectedFund.label} `,
        }
      : null;
  }, [marketArr, marketSelection]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };
  return (
    <>
      {/* <ToastContainer limit={1} /> */}
      <ToastContainer />

      <div className="content" ref={myRef}>
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        // history.replace("/admin/create-transaction");
                        // history.replace(`/admin/transactions-funds-management`);
                        history.goBack();
                      }}
                    ></i>
                    Daily Unrealized Portfolio
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Daily Unrealized Portfolio</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div
                          className="form-group col-md-4"
                          data-tip="Select Fund"
                        >
                          Fund Name*
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          {accFundLoading ? (
                            <div className="form-control w-100">
                              <i className="fa fa-spinner fa-spin fa-1x"></i>
                            </div>
                          ) : (
                            <Select
                              value={selectedFundName}
                              label={
                                fundCheck ? "No Fund Found" : "Select Fund"
                              }
                              onChange={(e) => {
                                setLoading(true);
                                setTableData([]);
                                setFund(e?.value);
                                setLoading(false);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderFundsDropdown()}
                              styles={customStyles}
                            />
                            // <select
                            //   className={"form-control w-100 "}
                            //   value={fund}
                            //   disabled={false}
                            //   onChange={(e) => {
                            //     setLoading(true);
                            //     setTableData([]);
                            //     setFund(e.target.value);
                            //     setLoading(false);
                            //   }}
                            // >
                            //   <option value="" defaultChecked hidden>
                            //     {fundCheck ? "No Fund Found" : "Select Fund"}
                            //   </option>
                            //   {renderFundsDropdown()}
                            // </select>
                          )}
                        </div>

                        <div
                          className="form-group col-md-4"
                          data-tip="Select Market"
                        >
                          Market*
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          {accFundLoading ? (
                            <div className="form-control w-100">
                              <i className="fa fa-spinner fa-spin fa-1x"></i>
                            </div>
                          ) : (
                            <Select
                              class={
                                marketSelectionError ? " required-border" : ""
                              }
                              value={selectedMarketName}
                              label={
                                fundCheck ? "No Fund Found" : "Select Fund"
                              }
                              onChange={(e) => {
                                setMarketSelectionError(false);
                                handleMarketChange(e);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={marketArr}
                              styles={customStyles}
                            />
                            // <select
                            //   className={`form-control mx-1  w-100 ${marketSelectionError ? " required-border" : ""
                            //     }`}
                            //   onChange={(e) => {
                            //     setMarketSelectionError(false);
                            //     handleMarketChange(e);
                            //   }}
                            // >
                            //   <option value="" selected hidden>
                            //     {marketSelectionOption}
                            //   </option>

                            //   <option value="money_market" label="Money Market">
                            //     Money Market
                            //   </option>
                            //   <option value="stock_market" label="Stock Market">
                            //     Stock Market
                            //   </option>
                            // </select>
                          )}
                        </div>
                      </div>

                      <Row>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary"
                            disabled={
                              Boolean(Loading) ||
                              !fund ||
                              marketSelectionOption === "Select Market Type"
                            }
                            onClick={() => {
                              handleGet();
                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Get Daily Unrealized Portfolio</span>
                            )}
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : todayData.length !== 0 ? (
                <>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title text-primary">
                            Today {todayDate}
                          </h4>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                {marketSelection === "stock_market" ? (
                                  <>
                                    <tr>
                                      <td colSpan={4}>
                                        Fund Code: &nbsp; &nbsp;
                                        {fund}
                                      </td>
                                      <td colSpan={4}>
                                        AMC Code: &nbsp; &nbsp; {amc_code}
                                      </td>
                                      <td colSpan={4}>
                                        Market: &nbsp; &nbsp;{" "}
                                        {marketSelectionOption}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Symbol</th>
                                      <th>Market</th>
                                      <th className="text-right">
                                        Total Units
                                      </th>
                                      <th className="text-right">
                                        Available Units
                                      </th>
                                      <th className="text-right">
                                        Average Cost / Unit
                                      </th>
                                      <th className="text-right">Total Cost</th>
                                      <th className="text-right">
                                        Market Rate / Unit
                                      </th>
                                      <th className="text-right">
                                        Market Value
                                      </th>
                                      <th className="text-right">
                                        Unrealized Gain/Loss
                                      </th>
                                      <th className="text-right">
                                        Investment as % of total market value
                                      </th>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={4}>
                                        Fund Code: &nbsp; &nbsp;
                                        {fund}
                                      </td>
                                      <td colSpan={4}>
                                        AMC Code: &nbsp; &nbsp; {amc_code}
                                      </td>
                                      <td colSpan={4}>
                                        Market: &nbsp; &nbsp;{" "}
                                        {marketSelectionOption}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Symbol</th>
                                      <th>Security Type</th>
                                      <th>Name</th>
                                      <th>Issue Date</th>
                                      <th>Maturity Date</th>
                                      <th className="text-right">
                                        Coupon Rate %
                                      </th>
                                      <th className="text-right">Yield %</th>
                                      <th className="text-right">
                                        Money market face value / unit
                                      </th>
                                      <th className="text-right">
                                        Total Face Value
                                      </th>
                                      <th className="text-right">Units</th>
                                      <th className="text-right">
                                        Avg cost / unit
                                      </th>
                                      <th className="text-right">Total cost</th>
                                      <th className="text-right">
                                        Market rate / unit
                                      </th>
                                      <th className="text-right">
                                        Market Value
                                      </th>
                                      <th className="text-right">
                                        Unrealized Gain / (Loss)
                                      </th>
                                      <th className="text-right">
                                        Investment as % of toal market value
                                      </th>
                                    </tr>
                                  </>
                                )}
                              </thead>
                              <tbody>
                                {marketSelection === "stock_market" ? (
                                  <>
                                    {todayData.map((data, index) => {
                                      if (data.length !== 0) {
                                        return (
                                          <tr key={index}>
                                            {/* <td>{data.id}</td> */}
                                            <td>{data.code}</td>
                                            <td>
                                              {numberWithCommas(data.market)}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.total_units
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(data.unit)}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.average_cost_per_unit
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(data.amount)}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.market_rate_per_unit
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.market_value
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.unrealized_gain_loss
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.investment_percentage_market_value
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                    <tr>
                                      <td colSpan={2}>Total:</td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "total_units"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(todayData, "unit")}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "average_cost_per_unit"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(todayData, "amount")}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "market_rate_per_unit"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "market_value"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "unrealized_gain_loss"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "investment_percentage_market_value"
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ) : marketSelection === "money_market" ? (
                                  <>
                                    {todayData.map((data, index) => {
                                      if (data.length !== 0) {
                                        return (
                                          <tr key={index}>
                                            <td>{data.code}</td>
                                            <td>{data.security_type}</td>
                                            <td>{data.name}</td>
                                            <td>{data.issue_date}</td>
                                            <td>{data.maturity_date}</td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.coupon_rate
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(data.yield)}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.mmface_value
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.total_face_value
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(data.units)}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.average_cost_per_unit
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.total_amount
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.market_rate_per_unit
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.market_value
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.unrealized_gain_loss
                                              )}
                                            </td>
                                            <td align="right">
                                              {numberWithCommas(
                                                data.investment_percentage_market_value
                                              )}
                                            </td>
                                          </tr>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                    <tr>
                                      <td colSpan={5}> Total:</td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "coupon_rate"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(todayData, "yield")}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "mmface_value"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "total_face_value"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(todayData, "units")}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "average_cost_per_unit"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "total_amount"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "market_rate_per_unit"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "market_value"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "unrealized_gain_loss"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {sumTotalColData(
                                          todayData,
                                          "investment_percentage_market_value"
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                ) : null}
                              </tbody>
                              {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {yesterdayData.length !== 0 ? (
                    <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title text-primary">
                              Yesterday {yesterdayDate}
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  {marketSelection === "stock_market" ? (
                                    <>
                                      <tr>
                                        <td colSpan={4}>
                                          Fund Code: &nbsp; &nbsp;
                                          {fund}
                                        </td>
                                        <td colSpan={4}>
                                          AMC Code: &nbsp; &nbsp; {amc_code}
                                        </td>
                                        <td colSpan={4}>
                                          Market: &nbsp; &nbsp;{" "}
                                          {marketSelectionOption}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Code</th>
                                        <th>Market</th>
                                        <th className="text-right">
                                          Market Rate Per Unit
                                        </th>
                                        <th className="text-right">
                                          Market Value
                                        </th>
                                        <th className="text-right">Unit</th>
                                        <th className="text-right">
                                          Total Units
                                        </th>
                                        <th className="text-right">
                                          Average Cost Per Unit
                                        </th>
                                        <th className="text-right">
                                          Investment Percentage Market Value
                                        </th>
                                        <th className="text-right">
                                          Unrealized Gain/Loss
                                        </th>
                                        <th className="text-right">Amount</th>
                                      </tr>
                                    </>
                                  ) : (
                                    <>
                                      <tr>
                                        <td colSpan={4}>
                                          Fund Code: &nbsp; &nbsp;
                                          {fund}
                                        </td>
                                        <td colSpan={4}>
                                          AMC Code: &nbsp; &nbsp; {amc_code}
                                        </td>
                                        <td colSpan={4}>
                                          Market: &nbsp; &nbsp;{" "}
                                          {marketSelectionOption}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>Security Type</th>
                                        <th>Issue Date</th>
                                        <th>Maturity Date</th>
                                        <th className="text-right">
                                          Coupon Rate
                                        </th>
                                        <th className="text-right">
                                          Market Value
                                        </th>
                                        <th className="text-right">
                                          Market Rate per Unit
                                        </th>
                                        <th className="text-right">
                                          Investment Percentage Market Value
                                        </th>
                                        <th className="text-right">
                                          Average Cost Per Unit
                                        </th>
                                        <th className="text-right">Units</th>
                                        <th className="text-right">
                                          Face Value
                                        </th>
                                        <th className="text-right">
                                          Total Face Value
                                        </th>
                                        <th className="text-right">
                                          Unrealized Gain/Loss
                                        </th>
                                        <th className="text-right">Yield</th>
                                        <th className="text-right">
                                          Total Amount
                                        </th>
                                      </tr>
                                    </>
                                  )}
                                </thead>
                                <tbody>
                                  {marketSelection === "stock_market" ? (
                                    <>
                                      {" "}
                                      {yesterdayData.map((data, index) => {
                                        if (data.length !== 0) {
                                          return (
                                            <tr key={index}>
                                              {/* <td>{data.id}</td> */}
                                              <td>{data.code}</td>
                                              <td>{data.market}</td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.market_rate_per_unit
                                                )}
                                              </td>

                                              <td align="right">
                                                {numberWithCommas(
                                                  data.market_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(data.unit)}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.total_units
                                                )}
                                              </td>

                                              <td align="right">
                                                {numberWithCommas(
                                                  data.average_cost_per_unit
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.investment_percentage_market_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.unrealized_gain_loss
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(data.amount)}
                                              </td>
                                            </tr>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                      <tr>
                                        <td colSpan={2}>Total:</td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "total_units"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(todayData, "unit")}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "average_cost_per_unit"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(todayData, "amount")}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "market_rate_per_unit"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "market_value"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "unrealized_gain_loss"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            todayData,
                                            "investment_percentage_market_value"
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ) : marketSelection === "money_market" ? (
                                    <>
                                      {yesterdayData.map((data, index) => {
                                        if (data.length !== 0) {
                                          return (
                                            <tr key={index}>
                                              <td>{data.code}</td>
                                              <td>{data.name}</td>
                                              <td>{data.security_type}</td>

                                              <td>{data.issue_date}</td>
                                              <td>{data.maturity_date}</td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.coupon_rate
                                                )}
                                              </td>

                                              <td align="right">
                                                {numberWithCommas(
                                                  data.market_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.market_rate_per_unit
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.investment_percentage_market_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.average_cost_per_unit
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(data.units)}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.mmface_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.total_face_value
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.unrealized_gain_loss
                                                )}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(data.yield)}
                                              </td>
                                              <td align="right">
                                                {numberWithCommas(
                                                  data.total_amount
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                      <tr>
                                        <td colSpan={5}> Total:</td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "coupon_rate"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "yield"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "mmface_value"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "total_face_value"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "units"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "average_cost_per_unit"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "total_amount"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "market_rate_per_unit"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "market_value"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "unrealized_gain_loss"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {sumTotalColData(
                                            yesterdayData,
                                            "investment_percentage_market_value"
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  ) : null}
                                </tbody>
                                {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  {/* affected ledger table */}
                  {affectedLedgerData.length > 0 ? (
                    <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title text-primary">
                              Affected Ledgers
                            </h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Ledger Code</th>
                                    <th>Ledger Name</th>
                                    <th>Family Name</th>
                                    <th>Description</th>
                                    <th align="right">Debit</th>
                                    <th align="right">Credit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {affectedLedgerData.map((data, index) => {
                                    if (data.length !== 0) {
                                      return (
                                        <tr key={index}>
                                          <td>{data.glCode}</td>
                                          <td>{data.glName}</td>
                                          <td>{data.familyName}</td>

                                          <td>{data.description}</td>
                                          <td align="right">
                                            {numberWithCommas(data.debit)}
                                          </td>
                                          <td align="right">
                                            {numberWithCommas(data.credit)}
                                          </td>
                                        </tr>
                                      );
                                    } else {
                                      return null;
                                    }
                                  })}
                                </tbody>
                                {/* <tfoot>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Total Amount</th>
                      <th>{totalBalance}</th>
                    </tr>
                  </tfoot> */}
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <Col>
                      <div className="row">
                        <div
                          className="col-md-6"
                          data-tip={
                            yesterdayData.length !== 0
                              ? todayData.length !== 0
                                ? "Realize"
                                : "No today record found!"
                              : "No record for yesterday!"
                          }
                        >
                          <button
                            className="btn btn-primary"
                            disabled={
                              Boolean(Loading) ||
                              disabled ||
                              yesterdayData.length === 0 ||
                              todayData.length === 0
                            }
                            // onClick={() => handleCreate()}
                            onClick={() => handleRealized()}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Calculate realization</span>
                            )}
                          </button>
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyUnrealizedPortfolio;

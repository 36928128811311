
import React, { useState, useRef, useEffect } from 'react';
// import Header from './../../components/Header';	
import { getAllBanks, getCities } from '../../../stores/services/bank.service';
import { getBranchByBankName } from '../../../stores/services/branch.service';
import ReactTooltip from 'react-tooltip';
import { getFundByAmc } from '../../../stores/services/funds.service';
import { BsPlusCircleFill } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import { FaExclamationCircle, FaRegCheckCircle } from "react-icons/fa";
import { Container, Row, Col, FormGroup, Label, Input, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ToggleButton from 'react-toggle-button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputMask from 'react-input-mask';
import { accountTitle_AccountNumber_Regex, numberWithCommas } from '../../../utils/customFunction';
import NumberFormat from "react-number-format";
import { useHistory } from 'react-router-dom';
import { getSalesCompanyByAMCCode } from '../../../stores/services/sales.service';
import { CITIES, COUNTRIES, E_WALLET_PROVIDERS, FilesByInvestorType, GENDER, MARITAL_STATUS, OCCUPATION, RELATION, SALUTATIONS, ImagesUploadType, PROVINCES } from './potential-custom-functions';
import { generateEmailOTPInvestor, generateMobileOTPInvestor, updateInvestor, verifyEmailOTPInvestor, verifyMobileOTPInvestor } from '../../../stores/services/potential-investor.service';
import Select from 'react-select';
import { set } from 'mobx';
import { inWords } from '../../../stores/services/template.service';
import { RiArrowGoForwardFill } from "react-icons/ri";
import { useSelector } from "react-redux";

const EditPotentialInvestor = () => {
  //hooks for getting all inputs from user	
  const investorTypeArr = [
    { label: "Individuals", value: "Individuals" },
    { label: "Joint Account", value: "Joint Account" },
    { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Partnership", value: "Partnership" },
    { label: "Limited Liability Partnership (LLP)", value: "Limited Liability Partnership (LLP)" },
    { label: "Limited Companies / Corporations", value: "Limited Companies / Corporations" },
    { label: "Branch Office or Liaison Office of Foreign Companies", value: "Branch Office or Liaison Office of Foreign Companies" },
    { label: "Trust, Clubs, Societies & Associations etc", value: "Trust, Clubs, Societies & Associations etc" },
    { label: "NGOs/NPOs/Charities", value: "NGOs/NPOs/Charities" },
    { label: "Agents", value: "Agents" },
    { label: "Executors & Administrators", value: "Executors & Administrators" },
    { label: "Minor Accounts", value: "Minor Accounts" }
  ];

  const accountTypeArr = [
    { label: "Normal", value: "Normal" },
    { label: "Sahulat", value: "Sahulat" }
  ];

  const taxStatusArr = [
    { label: "Filer", value: "Filer" },
    { label: "Non-Filer", value: "Non-Filer" },
    { label: "Exempted", value: "Exempted" }
  ];

  const portfolioArr = [
    { label: "Fund", value: "fund" },
    { label: "SMA", value: "sma" }
  ];

  const addressBillTypeArr = [
    { label: "Electric", value: "Electric" },
    { label: "Gas", value: "Gas" },
    { label: "Water", value: "Water" },
    { label: "Internet", value: "Internet" },
    { label: "Phone", value: "Phone" }
  ];

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);

  const data = JSON.parse(sessionStorage.getItem('investorObj') || '{}');
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  const history = useHistory();
  const [name, setName] = useState(data.name || '');
  const [cnic, setCnic] = useState(data.cnic || '');
  const [cnicExpiry, setCnicExpiry] = useState(data.cnic_expiry ? data.cnic_expiry?.split("T")[0] : '');
  const [passport, setPassport] = useState(data.passport_no || '');
  const [passportExpiry, setPassportExpiry] = useState(data.passport_expiry ? data.passport_expiry?.split("T")[0] : '');
  const [ntn, setNtn] = useState(data.ntn || '');
  const [taxStatus, setTaxStatus] = useState(data.tax_status || '');
  //	
  const [clientEmail, setClientEmail] = useState(data.email || '');
  const [mobile, setMobile] = useState(data.mobile || '');
  const [phone, setPhone] = useState(data.phone || '');
  const [address, setAddress] = useState(data.address || '');
  const [city, setCity] = useState({ label: "", value: "" });
  const [province, setProvince] = useState("");

  useEffect(() => {
    if (data.city) {
      setCity(CITIES.findIndex(item => item.name == data.city) == -1 ? { label: "Other", value: "Other" } : { label: data.city?.slice(data.city.indexOf(" ") + 1), value: data.city })
      setCityInputData(CITIES.findIndex(item => item.name == data.city) == -1 ? data.city : "")

    }
    if (data.province) {
      setProvince(PROVINCES.findIndex(item => item.value == data.province) == -1 ? "Other" : data.province)
      setProvinceInputData(PROVINCES.findIndex(item => item.value == data.province) == -1 ? data.province : "")
    }
  }, [])

  const [bankInputData, setBankInputData] = useState("");
  const [provinceInputData, setProvinceInputData] = useState("");
  const [cityInputData, setCityInputData] = useState("");
  //	
  const [accTitle, setAccTitle] = useState(data.account_title || '');
  const [accNo, setAccNo] = useState(data.account_no || '');
  const [bankname, setBankName] = useState(data.bank_name || '');
  const [branchName, setBranchName] = useState(data.branch_name || '');
  //	
  const [reinvestCheck, setReinvestCheck] = useState(data.reinvest == true ? true : false);
  const [fundLoading, setFundLoading] = useState<boolean>(false);
  const [disableAddBtn, setDisableAddBtn] = useState<boolean>(false);
  // guardian
  const [guardian_name, setGuardian_name] = useState(data.guardian_name || '');
  const [guardian_relation, setGuardian_relation] = useState(data.guardian_relation || '');
  const [guardian_cnic, setGuardian_cnic] = useState(data.guardian_cnic || '');
  // nominee
  const [nominee_name, setNomineeName] = useState(data.nominee_name || '');
  const [nominee_relation, setNomineeRelation] = useState(data.nominee_relation || '');
  const [nominee_cnic, setNomineeCnic] = useState(data.nominee_cnic || '');
  // joint_holder One
  const [joint_h_1_name, setJointHolderOneName] = useState(data.joint_h_1_name || '');
  const [joint_h_1_father_spouse, setJointHolderOneFather] = useState(data.joint_h_1_father_spouse || '');
  const [joint_h_1_cnic, setJointHolderOneCnic] = useState(data.joint_h_1_cnic || '');
  const [joint_h_1_taxStatus, setJointHolderOneTaxStatus] = useState(data.joint_h1_tax_status || '');
  const [joint_h_1_ntn, setJointHolderOneNTN] = useState(data.joint_h_1_ntn || '');
  const [joint_h_1_address_bill, setJointHolderOneAddressBill] = useState(data.joint_h_1_address_bill || '');
  const [joint_h_1_address_bill_type, setJointHolderOneAddressBillType] = useState(data.joint_h_1_address_bill_type || '');

  // joint_holder two
  const [joint_h_2_name, setJointHolderTwoName] = useState(data.joint_h_2_name || '');
  const [joint_h_2_father_spouse, setJointHolderTwoFather] = useState(data.joint_h_2_father_spouse || '');
  const [joint_h_2_cnic, setJointHolderTwoCnic] = useState(data.joint_h_2_cnic || '');
  const [joint_h_2_taxStatus, setJointHolderTwoTaxStatus] = useState(data.joint_h2_tax_status || '');
  const [joint_h_2_ntn, setJointHolderTwoNTN] = useState(data.joint_h_2_ntn || '');
  const [joint_h_2_address_bill, setJointHolderTwoAddressBill] = useState(data.joint_h_2_address_bill || '');
  const [joint_h_2_address_bill_type, setJointHolderTwoAddressBillType] = useState(data.joint_h_2_address_bill_type || '');

  // joint_holder Three
  const [joint_h_3_name, setJointHolderThreeName] = useState(data.joint_h_3_name || '');
  const [joint_h_3_father_spouse, setJointHolderThreeFather] = useState(data.joint_h_3_father_spouse || '');
  const [joint_h_3_cnic, setJointHolderThreeCnic] = useState(data.joint_h_3_cnic || '');
  const [joint_h_3_taxStatus, setJointHolderThreeTaxStatus] = useState(data.joint_h3_tax_status || '');
  const [joint_h_3_ntn, setJointHolderThreeNTN] = useState(data.joint_h_3_ntn || '');
  const [joint_h_3_address_bill, setJointHolderThreeAddressBill] = useState(data.joint_h_3_address_bill || '');
  const [joint_h_3_address_bill_type, setJointHolderThreeAddressBillType] = useState(data.joint_h_3_address_bill_type || '');

  const [portfolioManagement, setPortfolioManagement] = useState(data.portfolio_management || '');

  const [cdc_IAS, setCDCIAS] = useState(data.cdc_ias || '');
  const [cdc_subAccount, setCDCSubAccount] = useState(data.cdc_sub_account || '');
  const [cdc_participantID, setCDCParticipantID] = useState(data.cdc_participant_id || '');

  const [authorized_person, setAuthorizedPerson] = useState(data.authorized_person || '');
  const [filer, setFiler] = useState(data.filer == true ? true : false);
  const [registered_business_address, setRegisteredBusinessAddress] = useState(data.registered_business_address || '');
  const [partnership_registered, setPartnershipRegistered] = useState(data.partnership_registered == true ? true : false);

  const [allSalesCompany, setAllSalesCompany] = useState<any>([]);
  const [type, setType] = useState(data.type || '');
  const [totalFunds, setTotalFunds] = useState<any>([]);
  const [fundEntity, setFundEntity] = useState<any>({
    fund_code: '',
    fund_name: '',
    nav: '',
    fund_unit: '',
  });
  const [father, setFather] = useState(data.father_spouse || '');
  const [tax_exempt, setTax_exempt] = useState(false);
  const [zakat_exempt, setZakat_exempt] = useState(data.zakat_exempt == true ? true : false);

  const [modalView, setModalView] = useState(false);
  const [salesCompany, setSalesCompany] = useState(data.sales_company || "")
  const [salesPerson, setSalesPerson] = useState(data.sales_person || "")
  const [salesInHouse, setSalesInHouse] = useState(data.in_house_sale == true ? true : false)

  const filesRef = useRef({})
  const [files, setFiles] = useState<any>({})
  const [filesLink, setFilesLink] = useState<any>({})
  const borderRadiusStyle = { borderRadius: 2 };

  const [accountType, setAccountType] = useState(data.account_type || "")
  const [jointOneRelation, setJointOneRelation] = useState(data.joint_h1_relation || "")
  const [jointTwoRelation, setJointTwoRelation] = useState(data.joint_h2_relation || "")
  const [jointThreeRelation, setJointThreeRelation] = useState(data.joint_h3_relation || "")
  const [cnicLifeTime, setCNICLifetime] = useState(data.lifetime_cnic == true ? true : false)
  const [guardianCNICLifeTime, setGuadianCNICLifeTime] = useState(data.guardian_lifetime_cnic == true ? true : false)
  const [nomineeCNICLifeTime, setNomineeCNICLifeTime] = useState(data.nominee_lifetime_cnic == true ? true : false)
  const [jointOneCNICLifeTime, setJointOneCNICLifeTime] = useState(data.joint_h1_lifetime_cnic == true ? true : false)
  const [jointTwoCNICLifeTime, setJointTwoCNICLifeTime] = useState(data.joint_h2_lifetime_cnic == true ? true : false)
  const [jointThreeCNICLifeTime, setJointThreeCNICLifeTime] = useState(data.joint_h3_lifetime_cnic == true ? true : false)
  const [guardianCNICExpiry, setGuardianCNICExpiry] = useState(data.guardian_cnic_expiry ? data.guardian_cnic_expiry?.split("T")[0] : '')
  const [nomineeCNICExpiry, setNomineeCNICExpiry] = useState(data.nominee_cnic_expiry ? data.nominee_cnic_expiry?.split("T")[0] : '')
  const [jointOneCNICExpiry, setJointOneCNICExpiry] = useState(data.joint_h1_cnic_expiry ? data.joint_h1_cnic_expiry?.split("T")[0] : '')
  const [jointTwoCNICExpiry, setJointTwoCNICExpiry] = useState(data.joint_h2_cnic_expiry ? data.joint_h2_cnic_expiry?.split("T")[0] : '')
  const [jointThreeCNICExpiry, setJointThreeCNICExpiry] = useState(data.joint_h3_cnic_expiry ? data.joint_h3_cnic_expiry?.split("T")[0] : '')
  const [country, setCountry] = useState({ value: data.country || "", label: data.country?.slice(data.country.indexOf(" ") + 1) || "" })
  const [occupation, setOccupation] = useState(data.occupation || "")
  const [guardianOccupation, setGuardianOccupation] = useState(data.guardian_occupation || "")
  const [nomineeOccupation, setNomineeOccupation] = useState(data.nominee_occupation || "")
  const [jointOneOccupation, setJointOneOccupation] = useState(data.joint_h1_occupation || "")
  const [jointTwoOccupation, setJointTwoOccupation] = useState(data.joint_h2_occupation || "")
  const [jointThreeOccupation, setJointThreeOccupation] = useState(data.joint_h3_occupation || "")
  const [annualIncome, setAnnualIncome] = useState(data.annual_income || "")
  const [guardianAnnualIncome, setGuardianAnnualIncome] = useState(data.guardian_annual_income || "")
  const [nomineeAnnualIncome, setNomineeAnnualIncome] = useState(data.nominee_annual_income || "")
  const [jointOneAnnualIncome, setJointOneAnnualIncome] = useState(data.joint_h1_annual_income || "")
  const [jointTwoAnnualIncome, setJointTwoAnnualIncome] = useState(data.joint_h2_annual_income || "")
  const [jointThreeAnnualIncome, setJointThreeAnnualIncome] = useState(data.joint_h3_annual_income || "")
  const [motherMaidenName, setMotherMaidenname] = useState(data.mother_maiden_name || "")
  const [guardianMotherMaidenName, setGuardianMotherMaidenname] = useState(data.guardian_mother_maiden_name || "")
  const [nomineeMotherMaidenName, setNomineeMotherMaidenname] = useState(data.nominee_mother_maiden_name || "")
  const [jointOneMotherMaidenName, setJointOneMotherMaidenname] = useState(data.joint_h1_mother_maiden_name || "")
  const [jointTwoMotherMaidenName, setJointTwoMotherMaidenname] = useState(data.joint_h2_mother_maiden_name || "")
  const [jointThreeMotherMaidenName, setJointThreeMotherMaidenname] = useState(data.joint_h3_mother_maiden_name || "")
  const [placeOfBith, setPlaceOfBirth] = useState(data.place_of_birth || "")
  const [guardianPlaceOfBith, setGuardianPlaceOfBirth] = useState(data.guardian_place_of_birth || "")
  const [nomineePlaceOfBith, setNomineePlaceOfBirth] = useState(data.nominee_place_of_birth || "")
  const [jointOnePlaceOfBith, setJointOnePlaceOfBirth] = useState(data.joint_h1_place_of_birth || "")
  const [jointTwoPlaceOfBith, setJointTwoPlaceOfBirth] = useState(data.joint_h2_place_of_birth || "")
  const [jointThreePlaceOfBith, setJointThreePlaceOfBirth] = useState(data.joint_h3_place_of_birth || "")
  const [gender, setGender] = useState(data.gender || "")
  const [guardianGender, setGuardianGender] = useState(data.guardian_gender || '')
  const [nomineeGender, setNomineeGender] = useState(data.nominee_gender || '')
  const [jointOneGender, setJointOneGender] = useState(data.joint_h1_gender || "")
  const [jointTwoGender, setJointTwoGender] = useState(data.joint_h2_gender || "")
  const [jointThreeGender, setJointThreeGender] = useState(data.joint_h3_gender || "")
  const [maritalStatus, setMaritalStatus] = useState(data.marital_status || "")
  const [guardianMaritalStatus, setGuardianMaritalStatus] = useState(data.guardian_marital_status || "")
  const [nomineeMaritalStatus, setNomineeMaritalStatus] = useState(data.nominee_marital_status || "")
  const [jointOneMaritalStatus, setJointOneMaritalStatus] = useState(data.joint_h1_marital_status || "")
  const [jointTwoMaritalStatus, setJointTwoMaritalStatus] = useState(data.joint_h2_marital_status || "")
  const [jointThreeMaritalStatus, setJointThreeMaritalStatus] = useState(data.joint_h3_marital_status || "")
  const [eWalletProvider, setEWalletProvider] = useState(data.e_wallet_provider || "")
  const [eWalletProviderNumber, setEWalletProviderNumber] = useState(data.e_wallet_provider_number || "")



  const [mobileEmailVerification, setMobileEmailVerification] = useState({
    modal: false,
    type: "",
    code: "",
    status: "otppending",
    buttonStatus: "otppending",
  })

  const [verifiedDocuments, setVerifiedDocuments] = useState<any>({})

  const [verifiedDocumentModal, setVerifiedDocumentModal] = useState<any>({})

  const [openCloseCards, setOpenCloseCards] = useState({
    registration: true,
    personal_details: false,
    contact: false,
    bank_account_details: false,
    guardian_details: false,
    nominee_details: false,
    joint_one: false,
    joint_two: false,
    joint_three: false,
    cdc: false,
    sales_details: false,
    documents: false,

  })



  //error getting hooks	
  const [accTitleError, setAccTitleError] = useState('');
  const [accNoError, setAccNoError] = useState('');
  const [bankNameError, setBankNameError] = useState('');
  const [branchNameError, setBranchNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [clientEmailError, setClientEmailError] = useState('');
  const [cnicExpiryError, setCnicExpiryError] = useState('');
  const [cnicError, setCnicError] = useState('');
  const [passportExpiryError, setPassportExpiryError] = useState('');
  const [passportError, setPassportError] = useState('');
  const [ntnError, setNtnError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [provinceError, setProvinceError] = useState('');
  const [nameError, setNameError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [fatherError, setFatherError] = useState("")
  const [occupationError, setOccupationError] = useState("")
  const [annualIncomeError, setAnnualIncomeError] = useState("")
  const [genderError, setGenderError] = useState("")
  //for dropdown data	
  const [bankNameData, setBankNameData] = useState<any>([]);
  const [allbranchesData, setAllBranchesData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [branchLoading, setBranchLoading] = useState<boolean>(false);
  const [allCitiesData, setAllCitiesData] = useState<any>([]);
  const [branchCheckFlag, setBranchCheckFlag] = useState(false);

  const getAllSalesCompany = async (code: string) => {

    try {
      const response = await getSalesCompanyByAMCCode(email, code);
      if (response.data.status == 200) {
        setAllSalesCompany(response.data.data)
      } else {
        setAllSalesCompany([])
      }

    } catch (error) {
      setAllSalesCompany([])
    }
  };

  const getfundByAmcCode = async (code: string) => {

    //get funds by amc for dropdown	
    try {
      const response = await getFundByAmc(email, code, fund_code?.length > 0 ? fund_code : "");
      setAllFunds(response.data.data);
      if (response.data.data.length > 0) {
        setFundLoading(false);
      } else {
        setFundLoading(true);
      }
    } catch (error) { }
  };
  useEffect(() => {
    getfundByAmcCode(amc_code);
    getAllSalesCompany(amc_code)
  }, [])

  const getBranchByName = async (name: string) => {
    setBranchLoading(true);
    allbranchesData.length = 0;
    setAllBranchesData(allbranchesData);
    //get all branches for selected bank for dropdown	
    try {
      const response = await getBranchByBankName(email, name, amc_code);
      setAllBranchesData(response.data.data);
      if (response.data.data.length > 0) {
        setBranchCheckFlag(false);
      } else {
        setBranchCheckFlag(true);
      }
    } catch (error) { }
    setBranchLoading(false);
  };


  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };


  useEffect(() => {
    const fetchAmc = async () => {
      bankNameData.length = 0;
      setBankNameData(bankNameData);


      //get all banks list for dropdown	
      try {
        const response = await getAllBanks(email, "", "", amc_code);
        setBankNameData(response.data.data);

        if (data.bank_name && response?.data?.data?.findIndex((item: any) => item.bank_code.replace('BANK_', '') == data.bank_name) == -1) {
          setBankName("Other")
          setBankInputData(data.bank_name)
        }
      } catch (error) {
        if (data.bank_name) {
          setBankName("Other")
          setBankInputData(data.bank_name)
        }
      }
      try {
        const response = await getCities();
        setAllCitiesData(response.data.cities);
      } catch (error) { }
    };
    fetchAmc();
  }, []);

  //render dropdown for bank name data	
  const renderBankNameDropdown = () => {
    return bankNameData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.bank_code.replace('BANK_', '')}>
          {item.bank_name} ({item.bank_code.replace('BANK_', '')})
        </option>
      );
    });
  };
  //render dropdown for branch name data	
  const renderBranchsDropdown = () => {
    return allbranchesData.map((item: any, index: string) => {
      return (
        <option key={index} value={item.branch_code}>
          {item.branch_name} ({item.branch_code})
        </option>
      );
    });
  };
  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      return (
        <option key={index} value={item.symbol_code}>
          {item.fund_name} ({item.symbol_code})
        </option>
      );
    });
  };
  const renderCitiesDropdown = () => {
    return allCitiesData.map((item: any) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });
  };

  const renderSalutationDropDown = () => {
    return SALUTATIONS.map(item => {
      return {
        value: item,
        label: item
      }
    }
    )
  }

  const renderGenderDropDown = () => {
    return GENDER.map(item => {
      return {
        value: item,
        label: item
      }
    }
    )
  }

  const renderMaritalStatusDropDown = () => {
    return MARITAL_STATUS.map(item => {
      return {
        value: item,
        label: item
      }
    }
    )
  }

  const renderOccupationDropDown = () => {
    return OCCUPATION.map(item => {
      return {
        value: item,
        label: item.slice(item.indexOf(" ") + 1)
      }
    }
    )
  }

  const renderEWalletProviderDropdown = () => {
    return E_WALLET_PROVIDERS.map(item => {
      return {
        value: item,
        label: item
      }
    }
    )
  }

  const renderRelationDropDown = () => {
    return RELATION.map(item => {
      return {
        value: item,
        label: item
      }
    }
    )
  }

  const renderallSalesCompanyDropDown = () => {
    return allSalesCompany.map(item => {
      return {
        value: item.company_code,
        label: item.company_name
      }
    }
    )
  }

  const renderSalesPersonDropDown = () => {
    return allSalesCompany.find(item => item.company_code == salesCompany)?.persons?.map(item => {
      return {
        value: item.id,
        label: `${item.name}(${item.email})`
      }
    }
    )
  }


  const validate = () => {
    let accTitleErr,
      nameErr,
      accNoErr,
      banknameErr,
      branchNameErr,
      fatherErr,
      occupationErr,
      genderErr,
      annualIncomeErr,
      addressErr,
      cnicExpErr,
      phoneErr = '';
    let
      cnicErr,
      ntnErr,
      mobileErr,
      passportErr = '',
      passportExpiryErr = '',
      clientEmailErr = '';

    accTitle.trim() === '' ? (accTitleErr = 'required-border') : (accTitleErr = '');
    accNo.trim() === '' ? (accNoErr = 'required-border') : (accNoErr = '');
    (bankname.trim() === '' || (bankname == "Other" && bankInputData?.trim() == "")) ? (banknameErr = 'required-border') : (banknameErr = '');
    branchName.trim() === ''
      ? (branchNameErr = 'required-border')
      : (branchNameErr = '');

    name.trim() === '' ? (nameErr = 'required-border') : (nameErr = '');
    father?.split("–")?.[1] == "" || father.trim() === '' ? (fatherErr = 'required-border') : (fatherErr = '');
    occupation.trim() === '' ? (occupationErr = 'required-border') : (occupationErr = '');
    gender.trim() === '' ? (genderErr = 'required-border') : (genderErr = '');
    annualIncome?.toString().trim() === '' ? (annualIncomeErr = 'required-border') : (annualIncomeErr = '');
    address.trim() === '' ? (addressErr = 'required-border') : (addressErr = '');

    (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')
      ? (cnicErr = 'required-border')
      : (cnicErr = '');
    (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')
      ? (ntnErr = 'required-border')
      : (ntnErr = '');
    (cnic.trim() === '' && passport.trim() == '' && ntn.trim() == '')
      ? (passportErr = 'required-border')
      : (passportErr = '');
    ((cnic.trim() && cnicExpiry.trim() === '' && !cnicLifeTime) || (!passport.trim() && !ntn?.trim() && !cnicExpiry.trim() && !cnicLifeTime))
      ? (cnicExpErr = 'required-border')
      : (cnicExpErr = '');
    ((passport.trim() && passportExpiry.trim() === '') || (!cnic.trim() && !ntn?.trim() && !passportExpiry.trim()))
      ? (passportExpiryErr = 'required-border')
      : (passportExpiryErr = '');
    clientEmail.trim() === '' ? (clientEmailErr = 'required-border') : (clientEmailErr = '');
    mobile.trim() === '' ? (mobileErr = 'required-border') : (mobileErr = '');




    if (
      accTitleErr ||
      cnicExpErr ||
      phoneErr ||
      accNoErr ||
      nameErr ||
      branchNameErr ||
      cnicErr ||
      ntnErr ||
      mobileErr ||
      passportErr ||
      passportExpiryErr ||
      clientEmailErr ||
      fatherErr ||
      occupationErr ||
      genderErr ||
      annualIncomeErr ||
      addressErr ||
      banknameErr

    ) {

      setClientEmailError(clientEmailErr);
      setMobileError(mobileErr)
      setAccTitleError(accTitleErr);
      setPhoneError(phoneErr);
      setCnicExpiryError(cnicExpErr);
      setAccNoError(accNoErr);
      setBankNameError(banknameErr);
      setBranchNameError(branchNameErr);
      setCnicError(cnicErr);
      setNtnError(ntnErr);
      setMobileError(mobileErr);
      setPassportError(passportErr)
      setPassportExpiryError(passportExpiryErr)
      setNameError(nameErr);
      setFatherError(fatherErr);
      setOccupationError(occupationErr);
      setGenderError(genderErr);
      setAnnualIncomeError(annualIncomeErr);
      setAddressError(addressErr);
      return false;
    } else {
      return true;
    }
  };

  const makeDocumentStructure = (fileName, file = "") => {
    try {
      return (
        file && files[fileName]?.name ?
          files[fileName]
          : file && !files[fileName]?.name
            ? ""
            : verifiedDocuments[fileName]?.verified
              ? verifiedDocuments[fileName]
              : (filesLink[fileName])
                ?
                {
                  file: filesLink[fileName] || "",
                  verified: false,
                  verified_by: "",
                  verified_at_time: 0
                }
                : data[fileName] || {
                  file: "",
                  verified: false,
                  verified_by: "",
                  verified_at_time: 0
                }
      )
    } catch (err: any) {
      console.log("Error2", err)
    }

  }

  const updateInvestorData = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      let application_status = FilesByInvestorType[type].every(item => data[item.value]?.verified == true || verifiedDocuments[item.value]?.verified == true) ? "VERIFIED" :
        FilesByInvestorType[type].some(item => data[item.value]?.verified == true || verifiedDocuments[item.value]?.verified == true) ? "PROCESSING" : "DRAFT"
      try {
        let dataTemp = {
          email,
          amc_code,
          investor_id: data.investor_id,
          investor_email: clientEmail,
          investor_type: type,
          name,
          cnic,
          cnic_expiry: cnicLifeTime ? "" : cnicExpiry,
          ntn,
          passport_no: passport,
          passport_expiry: passportExpiry,
          mobile,
          phone,
          address,
          city: city?.value == "Other" ? cityInputData : city?.value ? city.value : "",
          province: province == "Other" ? provinceInputData : province ? province : "",
          account_title: accTitle,
          account_no: accNo,
          bank_name: bankname == "Other" ? bankInputData : bankname,
          branch_name: branchName,
          balance_unit: JSON.stringify(totalFunds),
          status: true,
          reinvest: reinvestCheck,
          father_spouse: father,
          tax_exempt: tax_exempt.toString(),
          zakat_exempt: zakat_exempt,
          type,
          guardian_name,
          guardian_relation,
          guardian_cnic,
          nominee_name,
          nominee_relation,
          nominee_cnic,
          joint_h_1_name: type == "Joint Account" ? joint_h_1_name : "",
          joint_h_1_father_spouse: type == "Joint Account" ? joint_h_1_father_spouse : "",
          joint_h_1_cnic: type == "Joint Account" ? joint_h_1_cnic : "",
          joint_h_1_ntn: type == "Joint Account" ? joint_h_1_ntn : "",
          joint_h_1_address_bill: type == "Joint Account" ? joint_h_1_address_bill : "",
          joint_h_1_address_bill_type: type == "Joint Account" ? joint_h_1_address_bill_type : "",
          joint_h1_relation: type == "Joint Account" ? jointOneRelation : "",
          joint_h1_cnic_expiry: type == "Joint Account" || jointOneCNICLifeTime ? "" : jointOneCNICExpiry,
          joint_h1_lifetime_cnic: type == "Joint Account" ? jointOneCNICLifeTime : false,
          joint_h1_occupation: type == "Joint Account" ? jointOneOccupation : "",
          joint_h1_annual_income: type == "Joint Account" ? jointOneAnnualIncome : "",
          joint_h1_mother_maiden_name: type == "Joint Account" ? jointOneMotherMaidenName : "",
          joint_h1_place_of_birth: type == "Joint Account" ? jointOnePlaceOfBith : "",
          joint_h1_gender: type == "Joint Account" ? jointOneGender : "",
          joint_h1_marital_status: type == "Joint Account" ? jointOneMaritalStatus : "",
          joint_h_2_name: type == "Joint Account" ? joint_h_2_name : "",
          joint_h_2_father_spouse: type == "Joint Account" ? joint_h_2_father_spouse : "",
          joint_h_2_cnic: type == "Joint Account" ? joint_h_2_cnic : "",
          joint_h_2_ntn: type == "Joint Account" ? joint_h_2_ntn : "",
          joint_h_2_address_bill: type == "Joint Account" ? joint_h_2_address_bill : "",
          joint_h_2_address_bill_type: type == "Joint Account" ? joint_h_2_address_bill_type : "",
          joint_h2_relation: type == "Joint Account" ? jointTwoRelation : "",
          joint_h2_cnic_expiry: type == "Joint Account" || jointTwoCNICLifeTime ? "" : jointTwoCNICExpiry,
          joint_h2_lifetime_cnic: type == "Joint Account" ? jointTwoCNICLifeTime : false,
          joint_h2_occupation: type == "Joint Account" ? jointTwoOccupation : "",
          joint_h2_annual_income: type == "Joint Account" ? jointTwoAnnualIncome : "",
          joint_h2_mother_maiden_name: type == "Joint Account" ? jointTwoMotherMaidenName : "",
          joint_h2_place_of_birth: type == "Joint Account" ? jointTwoPlaceOfBith : "",
          joint_h2_gender: type == "Joint Account" ? jointTwoGender : "",
          joint_h2_marital_status: type == "Joint Account" ? jointTwoMaritalStatus : "",
          joint_h_3_name: type == "Joint Account" ? joint_h_3_name : "",
          joint_h_3_father_spouse: type == "Joint Account" ? joint_h_3_father_spouse : "",
          joint_h_3_cnic: type == "Joint Account" ? joint_h_3_cnic : "",
          joint_h_3_ntn: type == "Joint Account" ? joint_h_3_ntn : "",
          joint_h_3_address_bill: type == "Joint Account" ? joint_h_3_address_bill : "",
          joint_h_3_address_bill_type: type == "Joint Account" ? joint_h_3_address_bill_type : "",
          joint_h3_relation: type == "Joint Account" ? jointThreeRelation : "",
          joint_h3_cnic_expiry: type == "Joint Account" || jointThreeCNICLifeTime ? "" : jointThreeCNICExpiry,
          joint_h3_lifetime_cnic: type == "Joint Account" ? jointThreeCNICLifeTime : false,
          joint_h3_occupation: type == "Joint Account" ? jointThreeOccupation : "",
          joint_h3_annual_income: type == "Joint Account" ? jointThreeAnnualIncome : "",
          joint_h3_mother_maiden_name: type == "Joint Account" ? jointThreeMotherMaidenName : "",
          joint_h3_place_of_birth: type == "Joint Account" ? jointThreePlaceOfBith : "",
          joint_h3_gender: type == "Joint Account" ? jointThreeGender : "",
          joint_h3_marital_status: type == "Joint Account" ? jointThreeMaritalStatus : "",
          portfolio_management: portfolioManagement,
          filer,
          joint_h1_tax_status: type == "Joint Account" ? joint_h_1_taxStatus : "",
          joint_h2_tax_status: type == "Joint Account" ? joint_h_2_taxStatus : "",
          joint_h3_tax_status: type == "Joint Account" ? joint_h_3_taxStatus : "",
          cdc_ias: cdc_IAS,
          cdc_sub_account: cdc_subAccount,
          cdc_participant_id: cdc_participantID,
          tax_status: taxStatus,
          sales_company: salesCompany,
          sales_person: salesPerson,
          in_house_sale: salesInHouse,
          registered_business_address,
          partnership_registered,
          authorized_person,
          verified: data.verified,
          folio_no: data.folio_no,
          country: country?.value ? country?.value : "",
          application_status,
          account_type: accountType,
          nominee_cnic_expiry: nomineeCNICLifeTime ? "" : nomineeCNICExpiry,
          guardian_cnic_expiry: guardianCNICLifeTime ? "" : guardianCNICExpiry,
          lifetime_cnic: cnicLifeTime,
          nominee_lifetime_cnic: nomineeCNICLifeTime,
          guardian_lifetime_cnic: guardianCNICLifeTime,
          occupation: occupation,
          guardian_occupation: guardianOccupation,
          nominee_occupation: nomineeOccupation,
          annual_income: annualIncome,
          guardian_annual_income: guardianAnnualIncome,
          nominee_annual_income: nomineeAnnualIncome,
          mother_maiden_name: motherMaidenName,
          guardian_mother_maiden_name: guardianMotherMaidenName,
          nominee_mother_maiden_name: nomineeMotherMaidenName,
          place_of_birth: placeOfBith,
          guardian_place_of_birth: guardianPlaceOfBith,
          nominee_place_of_birth: nomineePlaceOfBith,
          gender: gender,
          nominee_gender: nomineeGender,
          guardian_gender: guardianGender,
          marital_status: maritalStatus,
          guardian_marital_status: guardianMaritalStatus,
          nominee_marital_status: nomineeMaritalStatus,
          e_wallet_provider: eWalletProvider,
          e_wallet_provider_number: eWalletProviderNumber,
          zakat_exempt_document: makeDocumentStructure("zakat_exempt_document"),
          joint_h_1_cnic_copy: makeDocumentStructure("joint_h_1_cnic_copy"),
          joint_h_1_cnic_back_copy: makeDocumentStructure("joint_h_1_cnic_back_copy"),
          joint_h_1_ntn_document: makeDocumentStructure("joint_h_1_ntn_document"),
          joint_h_1_passport_copy: makeDocumentStructure("joint_h_1_passport_copy"),
          joint_h_1_address_bill_copy: makeDocumentStructure("joint_h_1_address_bill_copy"),
          joint_h_1_pay_slip: makeDocumentStructure("joint_h_1_pay_slip"),
          joint_h_1_employment_letter: makeDocumentStructure("joint_h_1_employment_letter"),
          joint_h_2_cnic_copy: makeDocumentStructure("joint_h_2_cnic_copy"),
          joint_h_2_cnic_back_copy: makeDocumentStructure("joint_h_2_cnic_back_copy"),
          joint_h_2_ntn_document: makeDocumentStructure("joint_h_2_ntn_document"),
          joint_h_2_passport_copy: makeDocumentStructure("joint_h_2_passport_copy"),
          joint_h_2_address_bill_copy: makeDocumentStructure("joint_h_2_address_bill_copy"),
          joint_h_2_pay_slip: makeDocumentStructure("joint_h_2_pay_slip"),
          joint_h_2_employment_letter: makeDocumentStructure("joint_h_2_employment_letter"),
          joint_h_3_cnic_copy: makeDocumentStructure("joint_h_3_cnic_copy"),
          joint_h_3_cnic_back_copy: makeDocumentStructure("joint_h_3_cnic_back_copy"),
          joint_h_3_ntn_document: makeDocumentStructure("joint_h_3_ntn_document"),
          joint_h_3_passport_copy: makeDocumentStructure("joint_h_3_passport_copy"),
          joint_h_3_address_bill_copy: makeDocumentStructure("joint_h_3_address_bill_copy"),
          joint_h_3_pay_slip: makeDocumentStructure("joint_h_3_pay_slip"),
          joint_h_3_employment_letter: makeDocumentStructure("joint_h_3_employment_letter"),
          cnic_copy: makeDocumentStructure("cnic_copy"),
          cnic_back_copy: makeDocumentStructure("cnic_back_copy"),
          ntn_verification: makeDocumentStructure("ntn_verification"),
          passport_copy: makeDocumentStructure("passport_copy"),
          address_bill: makeDocumentStructure("address_bill"),
          pay_slip: makeDocumentStructure("pay_slip"),
          employment_letter: makeDocumentStructure("employment_letter"),
          registration_certificate: makeDocumentStructure("registration_certificate"),
          account_opening_request: makeDocumentStructure("account_opening_request"),
          registered_business_address_evidence: makeDocumentStructure("registered_business_address_evidence"),
          all_partners_documents: makeDocumentStructure("all_partners_documents"),
          partnership_deed: makeDocumentStructure("partnership_deed"),
          partnership_registeration_document: makeDocumentStructure("partnership_registeration_document"),
          partnership_authority_letter: makeDocumentStructure("partnership_authority_letter"),
          certified_llp_agreement: makeDocumentStructure("certified_llp_agreement"),
          llp_form_5: makeDocumentStructure("llp_form_5"),
          llp_form_3: makeDocumentStructure("llp_form_3"),
          llp_authority_letter: makeDocumentStructure("llp_authority_letter"),
          board_resolution_copy: makeDocumentStructure("board_resolution_copy"),
          memorendum_articles: makeDocumentStructure("memorendum_articles"),
          certified_form_a: makeDocumentStructure("certified_form_a"),
          certified_form_b: makeDocumentStructure("certified_form_b"),
          certified_form_2: makeDocumentStructure("certified_form_2"),
          certified_form_c: makeDocumentStructure("certified_form_c"),
          certified_form_29: makeDocumentStructure("certified_form_29"),
          all_director_documents: makeDocumentStructure("all_director_documents"),
          all_beneficial_documents: makeDocumentStructure("all_beneficial_documents"),
          board_of_investment_letter: makeDocumentStructure("board_of_investment_letter"),
          signatories_passport_documents: makeDocumentStructure("signatories_passport_documents"),
          list_of_directors: makeDocumentStructure("list_of_directors"),
          certified_form_3: makeDocumentStructure("certified_form_3"),
          authorization_letter: makeDocumentStructure("authorization_letter"),
          certificate_of_registration: makeDocumentStructure("certificate_of_registration"),
          by_laws_regulations: makeDocumentStructure("by_laws_regulations"),
          resolutions: makeDocumentStructure("resolutions"),
          annual_accounts_documents: makeDocumentStructure("annual_accounts_documents"),
          power_of_attorney: makeDocumentStructure("power_of_attorney"),
          letter_administration_probate: makeDocumentStructure("letter_administration_probate"),
          guardian_documents: makeDocumentStructure("guardian_documents"),
          undertaking: makeDocumentStructure("undertaking"),
          signature_specimen: makeDocumentStructure("signature_specimen"),
          email_verification: verifiedDocuments["email_verification"] || data.email_verification || {
            otp: "",
            verified: false,
            verified_at: "",
            verified_at_time: 0,
            verified_by: ""
          },
          mobile_verification: verifiedDocuments["mobile_verification"] || data.mobile_verification || {
            otp: "",
            verified: false,
            verified_at: "",
            verified_at_time: 0,
            verified_by: ""
          },
          zakat_exempt_document_file: makeDocumentStructure("zakat_exempt_document", "file"),
          joint_h_1_cnic_file: makeDocumentStructure("joint_h_1_cnic_copy", "file"),
          joint_h_1_cnic_back_copy_file: makeDocumentStructure("joint_h_1_cnic_back_copy", "file"),
          joint_h_1_ntn_document_file: makeDocumentStructure("joint_h_1_ntn_document", "file"),
          joint_h_1_passport_copy_file: makeDocumentStructure("joint_h_1_passport_copy", "file"),
          joint_h_1_address_bill_copy_file: makeDocumentStructure("joint_h_1_address_bill_copy", "file"),
          joint_h_1_pay_slip_file: makeDocumentStructure("joint_h_1_pay_slip", "file"),
          joint_h_1_employment_letter_file: makeDocumentStructure("joint_h_1_employment_letter", "file"),
          joint_h_2_cnic_file: makeDocumentStructure("joint_h_2_cnic_copy", "file"),
          joint_h_2_cnic_back_copy_file: makeDocumentStructure("joint_h_2_cnic_back_copy", "file"),
          joint_h_2_ntn_document_file: makeDocumentStructure("joint_h_2_ntn_document", "file"),
          joint_h_2_passport_copy_file: makeDocumentStructure("joint_h_2_passport_copy", "file"),
          joint_h_2_address_bill_copy_file: makeDocumentStructure("joint_h_2_address_bill_copy", "file"),
          joint_h_2_pay_slip_file: makeDocumentStructure("joint_h_2_pay_slip", "file"),
          joint_h_2_employment_letter_file: makeDocumentStructure("joint_h_2_employment_letter", "file"),
          joint_h_3_cnic_file: makeDocumentStructure("joint_h_3_cnic_copy", "file"),
          joint_h_3_cnic_back_copy_file: makeDocumentStructure("joint_h_3_cnic_back_copy", "file"),
          joint_h_3_ntn_document_file: makeDocumentStructure("joint_h_3_ntn_document", "file"),
          joint_h_3_passport_copy_file: makeDocumentStructure("joint_h_3_passport_copy", "file"),
          joint_h_3_address_bill_copy_file: makeDocumentStructure("joint_h_3_address_bill_copy", "file"),
          joint_h_3_pay_slip_file: makeDocumentStructure("joint_h_3_pay_slip", "file"),
          joint_h_3_employment_letter_file: makeDocumentStructure("joint_h_3_employment_letter", "file"),
          cnic_copy_file: makeDocumentStructure("cnic_copy", "file"),
          cnic_back_copy_file: makeDocumentStructure("cnic_back_copy", "file"),
          ntn_verification_file: makeDocumentStructure("ntn_verification", "file"),
          passport_copy_file: makeDocumentStructure("passport_copy", "file"),
          address_bill_file: makeDocumentStructure("address_bill", "file"),
          pay_slip_file: makeDocumentStructure("pay_slip", "file"),
          employment_letter_file: makeDocumentStructure("employment_letter", "file"),
          registration_certificate_file: makeDocumentStructure("registration_certificate", "file"),
          account_opening_request_file: makeDocumentStructure("account_opening_request", "file"),
          registered_business_address_evidence_file: makeDocumentStructure("registered_business_address_evidence", "file"),
          all_partners_documents_file: makeDocumentStructure("all_partners_documents", "file"),
          partnership_deed_file: makeDocumentStructure("partnership_deed", "file"),
          partnership_registeration_document_file: makeDocumentStructure("partnership_registeration_document", "file"),
          partnership_authority_letter_file: makeDocumentStructure("partnership_authority_letter", "file"),
          certified_llp_agreement_file: makeDocumentStructure("certified_llp_agreement", "file"),
          llp_form_5_file: makeDocumentStructure("llp_form_5", "file"),
          llp_form_3_file: makeDocumentStructure("llp_form_3", "file"),
          llp_authority_letter_file: makeDocumentStructure("llp_authority_letter", "file"),
          board_resolution_copy_file: makeDocumentStructure("board_resolution_copy", "file"),
          memorendum_articles_file: makeDocumentStructure("memorendum_articles", "file"),
          certified_form_a_file: makeDocumentStructure("certified_form_a", "file"),
          certified_form_b_file: makeDocumentStructure("certified_form_b", "file"),
          certified_form_2_file: makeDocumentStructure("certified_form_2", "file"),
          certified_form_c_file: makeDocumentStructure("certified_form_c", "file"),
          certified_form_29_file: makeDocumentStructure("certified_form_29", "file"),
          all_director_documents_file: makeDocumentStructure("all_director_documents", "file"),
          all_beneficial_documents_file: makeDocumentStructure("all_beneficial_documents", "file"),
          board_of_investment_letter_file: makeDocumentStructure("board_of_investment_letter", "file"),
          signatories_passport_documents_file: makeDocumentStructure("signatories_passport_documents", "file"),
          list_of_directors_file: makeDocumentStructure("list_of_directors", "file"),
          certified_form_3_file: makeDocumentStructure("certified_form_3", "file"),
          authorization_letter_file: makeDocumentStructure("authorization_letter", "file"),
          certificate_of_registration_file: makeDocumentStructure("certificate_of_registration", "file"),
          by_laws_regulations_file: makeDocumentStructure("by_laws_regulations", "file"),
          resolutions_file: makeDocumentStructure("resolutions", "file"),
          annual_accounts_documents_file: makeDocumentStructure("annual_accounts_documents", "file"),
          power_of_attorney_file: makeDocumentStructure("power_of_attorney", "file"),
          letter_administration_probate_file: makeDocumentStructure("letter_administration_probate", "file"),
          guardian_documents_file: makeDocumentStructure("guardian_documents", "file"),
          undertaking_file: makeDocumentStructure("undertaking", "file"),
          signature_specimen_file: makeDocumentStructure("signature_specimen", "file"),

        };
        const response = await updateInvestor(dataTemp)
        if (response.data.status == 200) {

          toast.success(response.data.message);
          setTimeout(() => {
            history.replace(`/admin/potential-investors`);
          }, 2000);
        }
        else {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error: any) {
        setLoading(false);

        console.log("Error", error)
        if (error.response) {
          toast.error(error.response.data.message?.toString());
        } else {
          toast.error(error.message ? error.message : "Request Failed")
        }
      }

    } else {
      toast.error("Kindly Fill All Required Fields")
    }
  };


  // ADD TAX Entity inside tax Array	
  const addFundInArray = async () => {
    setDisableAddBtn(true);
    let count = 0;
    //push data inside array	
    for (let i in fundEntity) {
      if (fundEntity[i] === '') {
        count += 1;
      }
    }
    if (count > 0) {
      toast.error('Kindly Fill All Fields');
      setDisableAddBtn(false);
    } else {
      setModalView(false);
      const check = totalFunds.filter((item) => {
        return item.symbol_code === fundEntity['fund_code'];
      });
      if (check.length > 0) {
        totalFunds.map((item, index) => {
          if (item.symbol_code === fundEntity['fund_code']) {
            item.fund_unit = (
              +item.fund_unit + +fundEntity['fund_unit']
            ).toString();
            fundEntity['fund_name'] = item.fund_name;
          }
        });
      } else {
        totalFunds.push(fundEntity);
        setTotalFunds(totalFunds);
      }
      const filedEntirty = {
        fund_code: '',
        fund_name: '',
        nav: '',
        fund_unit: '',
      };
      setFundEntity(filedEntirty);
      setDisableAddBtn(false);
    }
  };
  //render Fund table data	
  const renderFundDataInTable = () => {
    return totalFunds.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.fund_code}</td>
          <td>{items.fund_name}</td>
          <td>{numberWithCommas(items.nav || "0")}</td>
          <td>{numberWithCommas(items.fund_unit || "0")}</td>
          {/* <td>{items.calculatedAmount}</td> */}
          <td>
            <IoIosClose
              style={{ fontSize: '25px', color: 'red' }}
              onClick={() => {
                let array = [...totalFunds];
                array.splice(index, 1);
                setTotalFunds(array);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds && allFunds?.find(item => item?.symbol_code === fundEntity['fund_code']);
    return selectedFund
      ? {
        value: selectedFund.symbol_code,
        label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`
      }
      :
      null;
  }, [allFunds, fundEntity['fund_code']]);

  // render Model for adding Fund		
  const renderModalPopup = () => {
    switch (modalView) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setModalView(false);
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setModalView(false);
                  // clearTaxobject();		
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Add Fund</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label>Fund Selection</label>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    <Select
                      value={selectedFundName}
                      label={fundLoading ? 'No Amc Fund Found' : 'Select Fund'}
                      onChange={(e) => {
                        let value = allFunds?.filter((item: any) => {
                          return item?.symbol_code === e?.value;
                        })
                        setFundEntity({
                          ...fundEntity,
                          fund_code: e?.value,
                          fund_name: value[0]?.fund_name,
                          nav: value[0]?.nav,
                        });
                      }}
                      menuPortalTarget={document.body}
                      menuShouldBlockScroll={true}
                      isClearable={true}
                      isSearchable={true}
                      name="color"
                      options={renderFundsDropdown()}
                      styles={customStyles}
                    />
                    {/* <select
                      className="form-control"
                      value={fundEntity['fund_code']}
                      onChange={(e) => {
                        let value = allFunds.filter((item: any) => {
                          return item.symbol_code === e.target.value;
                        })
                        setFundEntity({
                          ...fundEntity,
                          fund_code: e.target.value,
                          fund_name: value[0].fund_name,
                          nav: value[0].nav,
                        });
                      }}
                    >
                      <option value="" defaultChecked hidden>
                        {fundLoading ? 'No Amc Fund Found' : 'Select Fund'}
                      </option>
                      {renderFundsDropdown()}
                    </select> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div>NAV</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      className="form-control w-100 text-right"
                      value={fundEntity['nav']}
                      // value={nav}		
                      onValueChange={(e) => {
                        //setNav(e.target.value);
                        setFundEntity({
                          ...fundEntity,
                          nav: e.value,
                        });
                      }}

                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <div>Units</div>
                    <NumberFormat
                      thousandSeparator={true}
                      inputMode="numeric"
                      decimalScale={4}
                      className="form-control w-100 text-right"
                      value={fundEntity['fund_unit']}
                      onValueChange={(e) => {
                        let value = decimalValue(e.value, 4);
                        setFundEntity({
                          ...fundEntity,
                          fund_unit: value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <button
                    className="btn btn-primary btn-block mt-2"
                    onClick={() => {
                      addFundInArray();
                    }}
                    disabled={Boolean(disableAddBtn)}
                  >
                    {disableAddBtn ? (
                      <>
                        <span
                          className="spinner-border login-txt spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="login-txt"> Loading...</span>
                      </>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };

  const sendotp = async () => {
    try {
      let response
      setMobileEmailVerification({ ...mobileEmailVerification, status: 'otploading' })
      if (mobileEmailVerification.type == "email") {
        response = await generateEmailOTPInvestor(email, amc_code, clientEmail)

      } else if (mobileEmailVerification.type == "mobile") {
        response = await generateMobileOTPInvestor(email, amc_code, clientEmail)

      }

      if (response?.data?.status == 200) {
        setMobileEmailVerification({ ...mobileEmailVerification, status: 'otpsent', buttonStatus: 'otpsent' })

        toast.success(response.data.message)
      } else {
        setMobileEmailVerification({ ...mobileEmailVerification, status: 'otppending', buttonStatus: "otppending" })

        toast.error(response.data.message)
      }

    } catch (err: any) {
      setMobileEmailVerification({ ...mobileEmailVerification, status: 'otppending', buttonStatus: "otppending" })
      toast.error(err?.response?.data?.message?.toString() || err?.message || "Request Failed")

    }
  }


  const verifyOTP = async () => {
    try {
      setMobileEmailVerification({ ...mobileEmailVerification, status: 'verificationloading' })
      let response
      if (mobileEmailVerification.type == "email") {
        response = await verifyEmailOTPInvestor(email, amc_code, mobileEmailVerification.code, clientEmail)

      } else if (mobileEmailVerification.type == "mobile") {
        response = await verifyMobileOTPInvestor(email, amc_code, mobileEmailVerification.code, clientEmail)

      }

      if (response?.data?.status == 200) {
        if (mobileEmailVerification.type == "email") {
          setVerifiedDocuments({
            ...verifiedDocuments,
            email_verification: {
              otp: mobileEmailVerification.code,
              verified: true,
              verified_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              verified_at_time: new Date().getTime(),
              verified_by: email
            }
          })

          await updateInvestor({
            email,
            investor_id: data.investor_id,
            amc_code,
            email_verification: {
              otp: mobileEmailVerification.code,
              verified: true,
              verified_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              verified_at_time: new Date().getTime(),
              verified_by: email
            }
          })

        } else if (mobileEmailVerification.type == "mobile") {
          setVerifiedDocuments({
            ...verifiedDocuments,
            mobile_verification: {
              otp: mobileEmailVerification.code,
              verified: true,
              verified_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              verified_at_time: new Date().getTime(),
              verified_by: email
            }
          })

          await updateInvestor({
            email,
            investor_id: data.investor_id,
            amc_code,
            mobile_verification: {
              otp: mobileEmailVerification.code,
              verified: true,
              verified_at: moment().format("YYYY-MM-DD HH:mm:ss"),
              verified_at_time: new Date().getTime(),
              verified_by: email
            }
          })

        }

        setMobileEmailVerification({ ...mobileEmailVerification, code: "", modal: false, status: '', buttonStatus: '' })

        toast.success(response.data.message)
      } else {
        setMobileEmailVerification({ ...mobileEmailVerification, status: 'otpsent', buttonStatus: "otpsent" })

        toast.error(response.data.message)
      }

    } catch (err: any) {
      setMobileEmailVerification({ ...mobileEmailVerification, status: 'otpsent', buttonStatus: "otpsent" })
      toast.error(err?.response?.data?.message?.toString() || err?.message || "Request Failed")

    }
  }


  // render Model for Verification		
  const renderVerificationModalPopup = () => {
    switch (mobileEmailVerification.modal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false}
            onHide={() => {
              setMobileEmailVerification({ modal: false, type: "", status: "otppending", code: "", buttonStatus: "otppending" });
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setMobileEmailVerification({ modal: false, type: "", status: "otppending", code: "", buttonStatus: 'otppending' });
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{mobileEmailVerification.type} Verification</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div>OTP</div>
                    <input
                      type="text"
                      className="form-control w-100 "
                      value={mobileEmailVerification.code}
                      disabled={mobileEmailVerification.status == "otppending"}
                      onChange={(e) => {
                        setMobileEmailVerification({ ...mobileEmailVerification, code: e.target.value })

                      }}

                    />
                  </div>
                </div>

                <div>
                  {(mobileEmailVerification.buttonStatus == "otppending" || mobileEmailVerification.buttonStatus == "otploading") &&
                    <button
                      className="btn btn-primary "
                      onClick={sendotp}
                      disabled={mobileEmailVerification.status == "otploading"}	>
                      Send OTP
                    </button>}

                  {(mobileEmailVerification.buttonStatus == "otpsent" || mobileEmailVerification.buttonStatus == "verificationloading") &&
                    <button
                      className="btn btn-primary "
                      onClick={verifyOTP}
                      disabled={mobileEmailVerification.status == "verificationloading" || !mobileEmailVerification.code}	>
                      Verify
                    </button>}
                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const renderVerificationDocumentModalPopup = () => {
    switch (verifiedDocumentModal.modal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={false}
            onHide={() => {
              setVerifiedDocumentModal({ modal: false, document: {} })
            }}
          >
            <div className="modal-header">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setVerifiedDocumentModal({ modal: false, document: {} })
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">{verifiedDocumentModal.document.label} Verification</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  Please make sure the document is valid, up to date and follows compliance guidelines.
                </div>

                <button
                  className="btn btn-primary mt-3"
                  onClick={() => {
                    setVerifiedDocuments({
                      ...verifiedDocuments,
                      [verifiedDocumentModal.document.value]: {
                        file: files[verifiedDocumentModal.document.value] || filesLink[verifiedDocumentModal.document.value] || data[verifiedDocumentModal.document.value]?.file || "",
                        verified: true,
                        verified_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        verified_at_time: new Date().getTime(), verified_by: email
                      }
                    })
                    setVerifiedDocumentModal({ modal: false, document: {} })
                  }}
                >
                  Verify
                </button>

                <div>

                </div>

              </div>
            </div>
          </Modal>
        );
      default:
        return '';
    }
  };


  const upload = (e, item) => {
    let file = e?.target.files[0];
    if (file) {


      let type = file.name.substring(file.name.lastIndexOf(".") + 1)?.toLowerCase();
      if (type === "png" || type === "jpg" || type === "jpeg" || type === "pdf" || type == "zip") {
        //code here
        setFiles({ ...files, [item]: file })

      } else {
        toast.error("Invalid Format");
      }

    }
  };

  const updateSalutationFields = (value, setField, fieldValue, type) => {
    if (type == "salutation") {

      if (value) {
        setField(value + "–" + (fieldValue?.includes("–") ? fieldValue.split("–")[1] : fieldValue))


      } else {
        setField(fieldValue?.includes("–") ? fieldValue.split("–")[1] : fieldValue)
      }
    } else {

      setField(fieldValue?.includes("–") ? fieldValue?.split("–")[0] + "–" + value : value)
    }


  }

  const selectedAccountType = React.useMemo(() => {
    return accountType
      ? {
        value: accountType,
        label: accountType
      }
      :
      null;
  }, [accountType]);

  const selectedTaxStatus = React.useMemo(() => {
    return taxStatus
      ? {
        value: taxStatus,
        label: taxStatus
      }
      :
      null;
  }, [taxStatus]);

  const selectedTax_h_2_Status = React.useMemo(() => {
    return joint_h_2_taxStatus
      ? {
        value: joint_h_2_taxStatus,
        label: joint_h_2_taxStatus
      }
      :
      null;
  }, [joint_h_2_taxStatus]);

  const selectedTaxStatus_h_1 = React.useMemo(() => {
    return joint_h_1_taxStatus
      ? {
        value: joint_h_1_taxStatus,
        label: joint_h_1_taxStatus
      }
      :
      null;
  }, [joint_h_1_taxStatus]);

  const selectedPortfolio = React.useMemo(() => {
    const selectedFund = portfolioArr && portfolioArr?.find(item => item?.value === portfolioManagement);
    return selectedFund
      ? {
        value: selectedFund.value,
        label: selectedFund.label,
      }
      :
      null;
  }, [portfolioArr, portfolioManagement]);

  const selectedSalutation = React.useMemo(() => {
    return name.split("–")[0]
      ? {
        value: name.split("–")[0],
        label: name.split("–")[0]
      }
      :
      null;
  }, [name.split("–")[0]]);

  const selectedSalutationFather = React.useMemo(() => {
    return father.split("–")[0]
      ? {
        value: father.split("–")[0],
        label: father.split("–")[0]
      }
      :
      null;
  }, [father.split("–")[0]]);

  const selectedSalutationMotherMaiden = React.useMemo(() => {
    return motherMaidenName.split("–")[0]
      ? {
        value: motherMaidenName.split("–")[0],
        label: motherMaidenName.split("–")[0]
      }
      :
      null;
  }, [motherMaidenName.split("–")[0]]);

  const selectedSalutationGuardian = React.useMemo(() => {
    return guardian_name.split("–")[0]
      ? {
        value: guardian_name.split("–")[0],
        label: guardian_name.split("–")[0]
      }
      :
      null;
  }, [guardian_name.split("–")[0]]);

  const selectedNomineeSalutation = React.useMemo(() => {
    return nominee_name.split("–")[0]
      ? {
        value: nominee_name.split("–")[0],
        label: nominee_name.split("–")[0]
      }
      :
      null;
  }, [nominee_name.split("–")[0]]);

  const selectedSalutationJoint = React.useMemo(() => {
    return joint_h_1_name.split("–")[0]
      ? {
        value: joint_h_1_name.split("–")[0],
        label: joint_h_1_name.split("–")[0]
      }
      :
      null;
  }, [joint_h_1_name.split("–")[0]]);

  const selectedSalutation_h_1_father = React.useMemo(() => {
    return joint_h_1_father_spouse.split("–")[0]
      ? {
        value: joint_h_1_father_spouse.split("–")[0],
        label: joint_h_1_father_spouse.split("–")[0]
      }
      :
      null;
  }, [joint_h_1_father_spouse.split("–")[0]]);

  const selectedSalutationjointOneMotherMaidenName = React.useMemo(() => {
    return jointOneMotherMaidenName.split("–")[0]
      ? {
        value: jointOneMotherMaidenName.split("–")[0],
        label: jointOneMotherMaidenName.split("–")[0]
      }
      :
      null;
  }, [jointOneMotherMaidenName.split("–")[0]]);

  const selectedSalutation_h_2_name = React.useMemo(() => {
    return joint_h_2_name.split("–")[0]
      ? {
        value: joint_h_2_name.split("–")[0],
        label: joint_h_2_name.split("–")[0]
      }
      :
      null;
  }, [joint_h_2_name.split("–")[0]]);

  const selectedSalutation_h_2_father_spouse = React.useMemo(() => {
    return joint_h_2_father_spouse.split("–")[0]
      ? {
        value: joint_h_2_father_spouse.split("–")[0],
        label: joint_h_2_father_spouse.split("–")[0]
      }
      :
      null;
  }, [joint_h_2_father_spouse.split("–")[0]]);

  const selectedSalutationTwoMotherMaidenName = React.useMemo(() => {
    return jointTwoMotherMaidenName.split("–")[0]
      ? {
        value: jointTwoMotherMaidenName.split("–")[0],
        label: jointTwoMotherMaidenName.split("–")[0]
      }
      :
      null;
  }, [jointTwoMotherMaidenName.split("–")[0]]);

  const selected_h_3_Salutation = React.useMemo(() => {
    return joint_h_3_name.split("–")[0]
      ? {
        value: joint_h_3_name.split("–")[0],
        label: joint_h_3_name.split("–")[0]
      }
      :
      null;
  }, [joint_h_3_name.split("–")[0]]);

  const selected_h_3_father_spouse_Salutation = React.useMemo(() => {
    return joint_h_3_father_spouse.split("–")[0]
      ? {
        value: joint_h_3_father_spouse.split("–")[0],
        label: joint_h_3_father_spouse.split("–")[0]
      }
      :
      null;
  }, [joint_h_3_father_spouse.split("–")[0]]);

  const selectedjointThreeMotherMaidenNameSalutation = React.useMemo(() => {
    return jointThreeMotherMaidenName.split("–")[0]
      ? {
        value: jointThreeMotherMaidenName.split("–")[0],
        label: jointThreeMotherMaidenName.split("–")[0]
      }
      :
      null;
  }, [jointThreeMotherMaidenName.split("–")[0]]);

  const selectedGender = React.useMemo(() => {
    return gender
      ? {
        value: gender,
        label: gender
      }
      :
      null;
  }, [gender]);

  const selectedGuardianGender = React.useMemo(() => {
    return guardianGender
      ? {
        value: guardianGender,
        label: guardianGender
      }
      :
      null;
  }, [guardianGender]);

  const selectedNomineeGender = React.useMemo(() => {
    return nomineeGender
      ? {
        value: nomineeGender,
        label: nomineeGender
      }
      :
      null;
  }, [nomineeGender]);

  const selectedjointOneGender = React.useMemo(() => {
    return jointOneGender
      ? {
        value: jointOneGender,
        label: jointOneGender
      }
      :
      null;
  }, [jointOneGender]);

  const selectedjointTwoGender = React.useMemo(() => {
    return jointTwoGender
      ? {
        value: jointTwoGender,
        label: jointTwoGender
      }
      :
      null;
  }, [jointTwoGender]);

  const selectedjointThreeGender = React.useMemo(() => {
    return jointThreeGender
      ? {
        value: jointThreeGender,
        label: jointThreeGender
      }
      :
      null;
  }, [jointThreeGender]);

  const selectedMaritalStatus = React.useMemo(() => {
    return maritalStatus
      ? {
        value: maritalStatus,
        label: maritalStatus
      }
      :
      null;
  }, [maritalStatus]);

  const selectedjointThreeMaritalStatus = React.useMemo(() => {
    return jointThreeMaritalStatus
      ? {
        value: jointThreeMaritalStatus,
        label: jointThreeMaritalStatus
      }
      :
      null;
  }, [jointThreeMaritalStatus]);


  const selectedGuardianMaritalStatus = React.useMemo(() => {
    return guardianMaritalStatus
      ? {
        value: guardianMaritalStatus,
        label: guardianMaritalStatus
      }
      :
      null;
  }, [guardianMaritalStatus]);

  const selectedNomineeMaritalStatus = React.useMemo(() => {
    return nomineeMaritalStatus
      ? {
        value: nomineeMaritalStatus,
        label: nomineeMaritalStatus
      }
      :
      null;
  }, [nomineeMaritalStatus]);

  const selectedjointOneMaritalStatus = React.useMemo(() => {
    return jointOneMaritalStatus
      ? {
        value: jointOneMaritalStatus,
        label: jointOneMaritalStatus
      }
      :
      null;
  }, [jointOneMaritalStatus]);

  const selectedjointTwoMaritalStatus = React.useMemo(() => {
    return jointTwoMaritalStatus
      ? {
        value: jointTwoMaritalStatus,
        label: jointTwoMaritalStatus
      }
      :
      null;
  }, [jointTwoMaritalStatus]);

  const selectedjoint_h_3_taxStatus = React.useMemo(() => {
    return joint_h_3_taxStatus
      ? {
        value: joint_h_3_taxStatus,
        label: joint_h_3_taxStatus
      }
      :
      null;
  }, [joint_h_3_taxStatus]);

  const selectedOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === occupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, occupation]);

  const selectedGuardianOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === guardianOccupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, guardianOccupation]);

  const selectedNomineeOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === nomineeOccupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, nomineeOccupation]);

  const selectedjointOneOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === jointOneOccupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, jointOneOccupation]);

  const selectedjointTwoOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === jointTwoOccupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, jointTwoOccupation]);

  const selectedjointThreeOccupation = React.useMemo(() => {
    const selectedFund = OCCUPATION && OCCUPATION?.find(item => item === jointThreeOccupation);
    return selectedFund
      ? {
        value: selectedFund,
        label: selectedFund.slice(selectedFund.indexOf(" ") + 1)
      }
      :
      null;
  }, [OCCUPATION, jointThreeOccupation]);

  const selectedBankName = React.useMemo(() => {
    const selectedFund = bankNameData && bankNameData?.find(item => item.bank_code.replace("BANK_", "") === bankname);
    if (selectedFund) {
      return {
        value: selectedFund.bank_code.replace("BANK_", ""),
        label: `${selectedFund.bank_name} (${selectedFund.bank_code.replace("BANK_", "")})`
      };
    } else if (bankname === "Other") {
      return { label: "Other", value: "Other" };
    } else {
      return null;
    }
  }, [bankNameData, bankname]);

  const selectedEWalletProvider = React.useMemo(() => {
    return eWalletProvider
      ? {
        value: eWalletProvider,
        label: eWalletProvider
      }
      :
      null;
  }, [eWalletProvider]);

  const selectedGuardianRelation = React.useMemo(() => {
    return guardian_relation
      ? {
        value: guardian_relation,
        label: guardian_relation
      }
      :
      null;
  }, [guardian_relation]);

  const selectedNomineeRelation = React.useMemo(() => {
    return nominee_relation
      ? {
        value: nominee_relation,
        label: nominee_relation
      }
      :
      null;
  }, [nominee_relation]);

  const selectedJointOneRelation = React.useMemo(() => {
    return jointOneRelation
      ? {
        value: jointOneRelation,
        label: jointOneRelation
      }
      :
      null;
  }, [jointOneRelation]);

  const selectedjointTwoRelation = React.useMemo(() => {
    return jointTwoRelation
      ? {
        value: jointTwoRelation,
        label: jointTwoRelation
      }
      :
      null;
  }, [jointTwoRelation]);

  const selectedjointThreeRelation = React.useMemo(() => {
    return jointThreeRelation
      ? {
        value: jointThreeRelation,
        label: jointThreeRelation
      }
      :
      null;
  }, [jointThreeRelation]);

  const selectedAddressBillType = React.useMemo(() => {
    return joint_h_1_address_bill_type
      ? {
        value: joint_h_1_address_bill_type,
        label: joint_h_1_address_bill_type
      }
      :
      null;
  }, [joint_h_1_address_bill_type]);

  const selectedAddress_h_2_address_bill_type = React.useMemo(() => {
    return joint_h_2_address_bill_type
      ? {
        value: joint_h_2_address_bill_type,
        label: joint_h_2_address_bill_type
      }
      :
      null;
  }, [joint_h_2_address_bill_type]);

  const selectedjoint_h_3_address_bill_type = React.useMemo(() => {
    return joint_h_3_address_bill_type
      ? {
        value: joint_h_3_address_bill_type,
        label: joint_h_3_address_bill_type
      }
      :
      null;
  }, [joint_h_3_address_bill_type]);

  const selectedSalesCompany = React.useMemo(() => {
    const selectedFund = allSalesCompany && allSalesCompany?.find(item => item?.company_code === salesCompany);
    return selectedFund
      ? {
        value: selectedFund?.company_code,
        label: selectedFund?.company_name
      }
      :
      null;
  }, [allSalesCompany, salesCompany]);

  const selectedSalesPerson = React.useMemo(() => {
    const selectedFund = allSalesCompany && allSalesCompany.find(item => item.company_code == salesCompany)?.persons?.find(item => item?.id === salesPerson);
    return selectedFund
      ? {
        value: selectedFund?.id,
        label: `${selectedFund.name}(${selectedFund.email})`
      }
      :
      null;
  }, [allSalesCompany, salesPerson]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              {/* <Header />		 */}

              <div className="input-holder">
                <h4 className="card-title"><i className="fa fa-angle-left mr-1 pointer"
                  onClick={() => {
                    history.replace('/admin/potential-investors')
                  }}></i>Edit - Potential Investor</h4>
                <Link
                  to="/admin/potential-investors"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Registration
                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, registration: !openCloseCards.registration })}>
                          <i className={"tim-icons " + (openCloseCards.registration ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.registration &&
                      <CardBody>

                        <div className="form-group  ">
                          <label>Folio No</label>
                          <div className=" ">
                            <input
                              type="text"
                              className={`form-control  w-100 `}
                              value={data.folio_no}

                            />
                          </div>
                        </div>
                        <Row>
                          <Col md="6">
                            <div className="form-group">
                              <label>Investor Type</label>
                              <div>
                                <input
                                  type="text"
                                  className={`form-control  w-100 `}
                                  value={type}

                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">

                              <label>Account Type</label>
                              <Select
                                value={selectedAccountType}
                                label={"Select Account Type"}
                                onChange={(e) => {
                                  setAccountType(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={accountTypeArr}
                                styles={customStyles}
                              />
                              <div>
                                {/* <select
                                  className={`form-control  w-100`}
                                  value={accountType}
                                  onChange={(e) => {
                                    setAccountType(e.target.value);
                                  }}

                                >
                                  <option value="" defaultChecked hidden>
                                    Select Account Type
                                  </option>
                                  <option value="Normal">Normal</option>
                                  <option value="Sahulat">Sahulat</option>
                                </select> */}
                              </div>
                            </div>

                          </Col>
                        </Row>


                        <Row>
                          <Col md="6">
                            <div className="form-group">

                              <label>Tax Status</label>
                              <Select
                                value={selectedTaxStatus}
                                label={"Select Tax Type"}
                                onChange={(e) => {
                                  setTaxStatus(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={taxStatusArr}
                                styles={customStyles}
                              />
                              {/* <select
                                className="form-control"
                                value={taxStatus}
                                onChange={(e) => {
                                  setTaxStatus(e.target.value)
                                }}>
                                <option value="" hidden> Select Tax Type</option>
                                <option value="Filer">Filer</option>
                                <option value="Non-Filer">Non-Filer</option>
                                <option value="Exempted">Exempted</option>
                              </select> */}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group">
                              <label>Portfolio Management</label>
                              <div>
                                <Select
                                  value={selectedPortfolio}
                                  label={"Select Fund"}
                                  onChange={(e) => {
                                    setPortfolioManagement(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={portfolioArr}
                                  styles={customStyles}
                                />
                                {/* <select
                                  className={`form-control  w-100`}
                                  value={portfolioManagement}
                                  onChange={(e) => {
                                    setPortfolioManagement(e.target.value);
                                  }}

                                >
                                  <option value="" defaultChecked hidden>
                                    Fund
                                  </option>
                                  <option value="fund">Fund</option>
                                  <option value="sma">SMA</option>
                                </select> */}
                              </div>
                            </div>

                          </Col>
                        </Row>

                        <div className="d-flex justify-content-between allign-flex"  >

                          <div className="form-group d-flex mt-3">
                            <ToggleButton
                              inactiveLabel=""
                              activeLabel=""
                              value={zakat_exempt}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (zakat_exempt) {
                                  setZakat_exempt(false);
                                } else {
                                  setZakat_exempt(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Zakat Exempt</label>
                          </div>
                          <div className="mt-3">
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"
                                  checked={reinvestCheck}
                                  onChange={(e) => {
                                    setReinvestCheck(!reinvestCheck);
                                  }} />
                                <span className="form-check-sign" />
                                Fund Dividend Reinvest
                              </Label>
                            </FormGroup>
                          </div>


                        </div>
                        <div className="d-flex justify-content-between allign-flex"  >


                          <div className="form-group d-flex mt-3">
                            <ToggleButton
                              inactiveLabel=""
                              activeLabel=""
                              value={filer}
                              thumbStyle={borderRadiusStyle}
                              trackStyle={borderRadiusStyle}
                              onToggle={() => {
                                if (filer) {
                                  setFiler(false);
                                } else {
                                  setFiler(true);
                                }
                              }}
                            />
                            <label className="mt-2 ml-1">Filer</label>
                          </div>

                          {type == "Partnership" &&
                            <div className="form-group d-flex mt-3">
                              <ToggleButton
                                inactiveLabel=""
                                activeLabel=""
                                value={partnership_registered}
                                thumbStyle={borderRadiusStyle}
                                trackStyle={borderRadiusStyle}
                                onToggle={() => {
                                  if (partnership_registered) {
                                    setPartnershipRegistered(false);
                                  } else {
                                    setPartnershipRegistered(true);
                                  }
                                }}
                              />
                              <label className="mt-2 ml-1">Partnership Registered</label>
                            </div>}

                        </div>

                      </CardBody>}
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Personal Details

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, personal_details: !openCloseCards.personal_details })}>
                          <i className={"tim-icons " + (openCloseCards.personal_details ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.personal_details &&
                      <CardBody>
                        <Row>
                          <Col md="12" lg="6" xl="4" >
                            <div className="form-group">
                              <label>Salutation</label>
                              <div>
                                <Select
                                  value={selectedSalutation}
                                  label={"Select Salutation"}
                                  onChange={(e) => {
                                    updateSalutationFields(e?.value, setName, name, "salutation")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderSalutationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={name.split("–")[0]}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setName, name, "salutation")

                                  }}
                                >
                                  <option value="" defaultChecked hidden>

                                    Select Salutation
                                  </option>
                                  {SALUTATIONS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  )}
                                </select> */}

                              </div>
                            </div>

                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Client Name <span className="color-amber">*</span> </label>
                              <div>
                                <input
                                  type="text"
                                  className={`form-control  w-100 ${nameError ? 'required-border' : ''
                                    }`}
                                  value={name.includes("–") ? name?.split("–")[1] : name}
                                  onChange={(e) => {
                                    // setName(name?.includes("–") ?name?.split("–")[0] + "–"+e.target.value : e.target.value);	
                                    updateSalutationFields(e.target.value, setName, name, "value")
                                    setNameError('');
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" lg="6" xl="4" >
                            <div className="form-group">
                              <label>Salutation</label>
                              <div>
                                <Select
                                  value={selectedSalutationFather}
                                  label={"Select Salutation"}
                                  onChange={(e) => {
                                    updateSalutationFields(e?.value, setFather, father, "salutation")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderSalutationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={father.split("–")[0]}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setFather, father, "salutation")

                                  }}
                                >
                                  <option value="" defaultChecked hidden>

                                    Select Salutation
                                  </option>
                                  {SALUTATIONS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  )}
                                </select> */}

                              </div>
                            </div>

                          </Col>

                          <Col>

                            <div className="form-group ">
                              <label>Father/Spouse Name <span className="color-amber">*</span></label>
                              <div>
                                <input
                                  type="text"
                                  className={`form-control  w-100 ${fatherError ? 'required-border' : ''
                                    }`}
                                  value={father.includes("–") ? father?.split("–")[1] : father}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setFather, father, "value")
                                    setFatherError("");
                                  }}
                                />
                              </div>
                            </div>
                          </Col>

                        </Row>

                        <Row>
                          <Col md="12" lg="6" xl="4" >
                            <div className="form-group">
                              <label>Salutation</label>
                              <div>
                                <Select
                                  value={selectedSalutationMotherMaiden}
                                  label={"Select Salutation"}
                                  onChange={(e) => {
                                    updateSalutationFields(e?.value, setMotherMaidenname, motherMaidenName, "salutation")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderSalutationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={motherMaidenName.split("–")[0]}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setMotherMaidenname, motherMaidenName, "salutation")

                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select Salutation
                                  </option>
                                  {SALUTATIONS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  )}
                                </select> */}

                              </div>
                            </div>

                          </Col>

                          <Col>
                            <div className="form-group ">
                              <label>Mother Maiden Name</label>
                              <div>
                                <input
                                  type="text"
                                  className={'form-control  w-100'}
                                  value={motherMaidenName.includes("–") ? motherMaidenName?.split("–")[1] : motherMaidenName}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setMotherMaidenname, motherMaidenName, "value")
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="form-group ">
                          <label>Authroized Person</label>
                          <div>
                            <input
                              type="text"
                              className={'form-control  w-100'}
                              value={authorized_person}
                              onChange={(e) => {
                                setAuthorizedPerson(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Gender <span className="color-amber">*</span></label>
                              <div  >
                                <Select
                                  className={`${genderError
                                    }`}
                                  value={selectedGender}
                                  label={"Select Gender"}
                                  onChange={(e) => {
                                    setGender(e?.value)
                                    setGenderError("")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderGenderDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 ${genderError}`}
                                  value={gender}
                                  onChange={(e) => {
                                    setGender(e.target.value)
                                    setGenderError('')
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Gender
                                  </option>
                                  {GENDER.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Martial Status</label>
                              <div  >
                                <Select

                                  value={selectedMaritalStatus}
                                  label={" Select Marital Status"}
                                  onChange={(e) => {
                                    setMaritalStatus(e?.value)
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderMaritalStatusDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={maritalStatus}
                                  onChange={(e) => {
                                    setMaritalStatus(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Marital Status
                                  </option>
                                  {MARITAL_STATUS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Occupation <span className="color-amber">*</span></label>
                              <div  >
                                <Select
                                  className={`${occupationError
                                    }`}
                                  value={selectedOccupation}
                                  label={"Select Occupation"}
                                  onChange={(e) => {
                                    setOccupation(e?.value)
                                    setOccupationError("")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderOccupationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 ${occupationError}`}
                                  value={occupation}
                                  onChange={(e) => {
                                    setOccupation(e.target.value)
                                    setOccupationError("")
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Occupation
                                  </option>
                                  {OCCUPATION.map(item =>
                                    <option value={item} key={item}>
                                      {item.slice(item.indexOf(" ") + 1)}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Annual Income <span className="color-amber">*</span></label>
                              <div  >
                                <NumberFormat
                                  thousandSeparator={true}
                                  style={{ textAlign: 'right' }}
                                  className={`form-control  w-100 ${annualIncomeError}`}
                                  value={annualIncome}
                                  onValueChange={(e) => {
                                    setAnnualIncome(e.value);
                                    setAnnualIncomeError("")

                                  }}
                                />



                              </div>

                            </div>
                          </Col>
                        </Row>

                        <div className="form-group  ">
                          <label>Place Of Birth</label>
                          <div  >
                            <input
                              type="number"
                              className={`form-control  w-100 `}
                              value={placeOfBith}
                              onChange={(e) => {
                                setPlaceOfBirth(e.target.value);

                              }}
                            />
                            {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}
                          </div>
                        </div>




                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group ">
                              <div className='d-flex justify-content-between '>
                                <label>CNIC</label>
                                <FormGroup check className='mt-0'>
                                  <Label check>
                                    <Input type="checkbox"
                                      checked={cnicLifeTime}
                                      onChange={(e) => {
                                        setCNICLifetime(!cnicLifeTime);
                                        setCnicExpiryError("")
                                        setPassportExpiryError("")
                                      }} />
                                    <span className="form-check-sign" />
                                    Life Time
                                  </Label>
                                </FormGroup>
                              </div>
                              <div  >
                                <InputMask
                                  className={`form-control  w-100 ${cnicError ? 'required-border' : ''
                                    }`}
                                  mask="99999-9999999-9"
                                  value={cnic}

                                  onChange={(e) => {
                                    setCnic(e.target.value.toUpperCase());
                                    setCnicError('');
                                    setPassportError('');
                                    setNtnError("")
                                  }}></InputMask>

                                {/* {cnicError ? <p className="error-labels error-message">{cnicError}</p> : ''} */}
                              </div>
                            </div>

                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div  >
                                <input
                                  type="date"
                                  readOnly={cnicLifeTime}
                                  className={`form-control  w-100 ${cnicExpiryError ? ' required-border' : ''
                                    }`}
                                  value={cnicExpiry}
                                  onChange={(e) => {
                                    setCnicExpiry(e.target.value);
                                    setCnicExpiryError('');
                                    setPassportExpiryError('');
                                  }}
                                />
                                {/* {cnicExpiryError ? <p className="error-labels error-message2">{cnicExpiryError}</p> : ''} */}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Passport</label>
                              <div  >
                                <input
                                  type="text"
                                  className={`form-control  w-100 ${passportError ? ' required-border' : ''
                                    }`}
                                  value={passport}
                                  onChange={(e) => {
                                    setPassport(e.target.value.toUpperCase());
                                    setCnicError('');
                                    setPassportError('');
                                    setNtnError("")
                                  }}
                                />


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Passport Expiry</label>
                              <div  >
                                <input
                                  type="date"
                                  className={`form-control  w-100 ${passportExpiryError ? 'required-border' : ''
                                    }`}
                                  value={passportExpiry}
                                  onChange={(e) => {
                                    setPassportExpiry(e.target.value);
                                    setCnicExpiryError('');
                                    setPassportExpiryError('');
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>



                        <div className="form-group  ">
                          <label>NTN</label>
                          <div  >
                            <input
                              type="number"
                              className={`form-control  w-100 ${ntnError ? 'required-border' : ''
                                }`}
                              value={ntn}
                              onChange={(e) => {
                                setNtn(e.target.value);
                                setNtnError('');
                                setPassportError('');
                                setCnicError("")
                                setPassportExpiryError("")
                                setCnicExpiryError("")
                              }}
                            />
                            {/* {ntnError ? <p className="error-labels error-message2">{ntnError}</p> : ''} */}
                          </div>
                        </div>




                      </CardBody>}
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Contact

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, contact: !openCloseCards.contact })}>
                          <i className={"tim-icons " + (openCloseCards.contact ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.contact &&
                      <CardBody>
                        <div className="form-group  ">
                          <label>Email <span className="color-amber">*</span></label>
                          <div className='d-flex'>

                            <input
                              type="text"
                              className={`form-control   w-100 ${clientEmailError ? 'required-border' : ''
                                }`}
                              value={clientEmail}
                              readOnly={data?.email_verification?.verified || verifiedDocuments["email_verification"]?.verified}
                              onChange={(e) => {
                                setClientEmail(e.target.value);
                                setClientEmailError('');
                              }}
                            />



                            {(data?.email_verification?.verified || verifiedDocuments["email_verification"]?.verified)
                              ? <FaRegCheckCircle color='#00ff00' className='ml-1' fontSize="xx-large" />
                              :
                              <FaExclamationCircle color='#ff5932' className='pointer ml-1' fontSize="xx-large"
                                onClick={() => {
                                  setMobileEmailVerification({ modal: true, type: "email", status: "otppending", code: "", buttonStatus: "otppending" });
                                }}
                              />}

                          </div>
                        </div>

                        <Row>
                          <Col md="12">
                            <div className="form-group  ">
                              <label>Mobile <span className="color-amber">*</span></label>
                              <div className='d-flex'>

                                <PhoneInput
                                  specialLabel=""
                                  inputClass={`form-control  w-100 ${mobileError ? 'required-border' : ''
                                    }`}
                                  country={'pk'}
                                  placeholder="+92 123-1234567"
                                  value={mobile}
                                  disabled={data?.mobile_verification?.verified || verifiedDocuments["mobile_verification"]?.verified}
                                  onChange={(e) => {
                                    setMobile(e);
                                    setMobileError('');
                                  }}
                                />

                                {(data?.mobile_verification?.verified || verifiedDocuments["mobile_verification"]?.verified)
                                  ? <FaRegCheckCircle color='#00ff00' className='ml-1' fontSize="xx-large" />
                                  : data.mobile == mobile
                                    ?
                                    <FaExclamationCircle color='#ff5932' className='pointer ml-1' fontSize="xx-large"
                                      onClick={() => {
                                        setMobileEmailVerification({ modal: true, type: "mobile", status: "otppending", code: "", buttonStatus: "otppending" });
                                      }}
                                    /> : null}
                              </div>
                            </div>
                          </Col>

                        </Row>
                        <div className="form-group  ">
                          <label>Phone</label>
                          <div  >

                            <PhoneInput
                              specialLabel=""
                              inputClass={`form-control  w-100 ${phoneError ? 'required-border' : ''
                                }`}
                              country={'pk'}
                              placeholder="+92 123-1234567"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e);
                                setPhoneError('');
                              }}
                            />

                            {/* {phoneError ? <p className="error-labels error-message2">{phoneError}</p> : ''} */}
                          </div>
                        </div>

                        <div className="form-group  ">
                          <label>Address <span className="color-amber">*</span></label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${addressError ? ' required-border ' : ''
                                }`}
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                                setAddressError('');
                              }}
                            />
                            {/* {addressError ? <p className="error-labels error-message2">{addressError}</p> : ''} */}
                          </div>
                        </div>
                        <div className="form-group  ">
                          <label>Registered Business Address</label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 `}
                              value={registered_business_address}
                              onChange={(e) => {
                                setRegisteredBusinessAddress(e.target.value);

                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group  ">
                          <label>Country</label>
                          <Select
                            className="react-select info "
                            classNamePrefix="react-select"
                            label={'Select'}
                            isMulti={false}

                            onChange={(e) => {
                              //e.preventDefault();

                              setCountry(e?.value ? e : { label: "", value: "" });
                              setProvince("")
                              setCity({ label: "", value: "" })

                            }}
                            value={country?.value ? [country] : []}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={COUNTRIES.map(item => {
                              return { value: item, label: item.slice(item.indexOf(" ") + 1) }
                            })}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                          />
                        </div>

                        <Row>

                          <Col>
                            <div className="form-group">
                              <label>Province</label>

                              {province == "Other" &&
                                <div className="d-flex align-items-center ">
                                  <input
                                    type="text"
                                    value={provinceInputData}
                                    className={`form-control  w-100 ${provinceError}`}
                                    onChange={(e) => {
                                      setProvinceInputData(e.target.value);
                                      setProvinceError("")
                                      setCity({ label: "", value: "" })

                                    }}
                                  />
                                  <RiArrowGoForwardFill
                                    className="btn-primary pointer ml-1"
                                    onClick={() => {
                                      setProvince("")
                                      setProvinceInputData("")
                                    }}
                                  />
                                </div>}

                              {province != "Other" && <div>
                                <select
                                  className={`form-control w-100 ${provinceError}`}
                                  value={province}
                                  onChange={(e) => {
                                    setProvince(e.target.value);
                                    setCity({ label: "", value: "" })
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select
                                  </option>
                                  {country.value == "PAK Pakistan" ? [...PROVINCES, { value: "Other", label: "Other" }].map(item => {
                                    return <option value={item.value} key={item.value}>{item.label}</option>
                                  }) :
                                    [{ value: "Other", label: "Other" }].map(item => {
                                      return <option value={item.value} key={item.value}>{item.label}</option>
                                    })}
                                </select>
                              </div>}

                            </div>
                          </Col>
                          <Col>
                            <div className="form-group  ">
                              <label>City</label>

                              {/* {city.value == "Other" && 
                    <div className="d-flex align-items-center ">
                   <input
                     type="text"
                     value={cityInputData}
                     className={`form-control  w-100 `}
                     onChange={(e) => {
                       setCityInputData(e.target.value);
                       
                       setCity({label:"",value:""})
                       
                     }}
                   />
                   <RiArrowGoForwardFill
                     className="btn-primary pointer ml-1"
                     onClick={() => {
                      setCity({label:"",value:""})
                       setCityInputData("")
                     }}
                   />
                   </div>
                  }  
                   */}

                              {city.value != "Other" ? <div>
                                <Select
                                  className="react-select info "
                                  classNamePrefix="react-select"
                                  label={'Select'}
                                  isMulti={false}

                                  onChange={(e) => {
                                    //e.preventDefault();

                                    setCityError('');
                                    setCity(e?.value ? e : { label: "", value: "" });

                                  }}
                                  value={city?.value ? [city] : []}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={[...CITIES, { name: "Other", province: "province" }].filter(item => item.province === province?.split(" ")[0] || item.province == "province").map(item => {
                                    return { value: item.name, label: item.name.slice(item.name.indexOf(" ") + 1) }
                                  })}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  styles={customStyles}
                                />

                              </div> :
                                <div className="d-flex align-items-center ">
                                  <input
                                    type="text"
                                    value={cityInputData}
                                    className="form-control  w-100"
                                    onChange={(e) => {
                                      setCityInputData(e.target.value);

                                    }}
                                  />
                                  <RiArrowGoForwardFill
                                    className="btn-primary pointer ml-1"
                                    onClick={() => {
                                      setCity({ label: "", value: "" })
                                      setCityInputData("")
                                    }}
                                  />
                                </div>}
                            </div>
                          </Col>


                        </Row>
                      </CardBody>}
                  </Card>
                </Col>

                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Bank Account Detail

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, bank_account_details: !openCloseCards.bank_account_details })}>
                          <i className={"tim-icons " + (openCloseCards.bank_account_details ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.bank_account_details &&
                      <CardBody>
                        <div className="form-group  ">
                          <label>Account Title <span className="color-amber">*</span></label>
                          <div>
                            <input
                              type="text"
                              className={`form-control  w-100 ${accTitleError ? 'required-border' : ''
                                }`}
                              value={accTitle}
                              onChange={(e) => {
                                if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                  setAccTitle(e.target.value);
                                  setAccTitleError('');
                                }
                              }}
                            />
                            {/* {accTitleError ? <p className="error-labels error-message2">{accTitleError}</p> : ''} */}
                          </div>
                        </div>

                        <div className="form-group  ">
                          <label>Account No <span className="color-amber">*</span></label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 ${accNoError ? 'required-border' : ''
                                }`}
                              value={accNo}
                              onChange={(e) => {
                                if (e.target.value.match(accountTitle_AccountNumber_Regex) || e.target.value == '') {
                                  setAccNo(e.target.value);
                                  setAccNoError('');
                                }
                              }}
                            />
                            {/* {accNoError ? <p className="error-labels error-message">{accNoError}</p> : ''} */}
                          </div>
                        </div>

                        <Row>
                          <Col md="6">


                            <div className="form-group  ">
                              <label>Bank Name <span className="color-amber">*</span></label>
                              {bankname == "Other" ? <div className="d-flex align-items-center ">
                                <input
                                  type="text"
                                  value={bankInputData}
                                  className={`form-control  w-100 ${bankNameError}`}
                                  onChange={(e) => {
                                    setBankInputData(e.target.value);
                                    setBankNameError("")

                                  }}
                                />
                                <RiArrowGoForwardFill
                                  className="btn-primary pointer ml-1"
                                  onClick={() => {
                                    setBankName("")
                                    setBankInputData("")
                                  }}
                                />
                              </div> :
                                <div >
                                  <Select
                                    className={`${bankNameError
                                      }`}
                                    value={selectedBankName}
                                    label={"Select Bank"}
                                    onChange={(e) => {
                                      setBankNameError("");
                                      setBankName(e?.value);
                                      getBranchByName(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderBankNameDropdown()}
                                    styles={customStyles}
                                  />
                                  {/* <select
                                    className={`form-control  w-100 ${bankNameError}`}
                                    value={bankname}
                                    onChange={(e) => {
                                      setBankNameError('');
                                      setBankName(e.target.value);
                                      getBranchByName(e.target.value);
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Bank
                                    </option>
                                    {renderBankNameDropdown()}
                                    <option value="Other">Other</option>
                                  </select> */}
                                </div>}
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="form-group  ">
                              <label>Branch Name <span className="color-amber">*</span></label>
                              <input type="text" className={`form-control  w-100 ${branchNameError ? 'required-border' : ''
                                }`}
                                value={branchName}
                                onChange={(e) => {
                                  setBranchNameError('');
                                  setBranchName(e.target.value);
                                }}
                              />

                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>E Wallet Provider</label>
                              <div  >
                                <Select
                                  value={selectedEWalletProvider}
                                  label={"Select E Wallet Provider"}
                                  onChange={(e) => {
                                    setEWalletProvider(e?.value)
                                    setEWalletProviderNumber("")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderEWalletProviderDropdown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={eWalletProvider}
                                  onChange={(e) => {
                                    setEWalletProvider(e.target.value)
                                    setEWalletProviderNumber("")
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select E Wallet Provider
                                  </option>
                                  {E_WALLET_PROVIDERS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          {eWalletProvider &&
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>E Wallet Provider Number</label>
                                <div  >
                                  <input
                                    type="number"
                                    className={`form-control  w-100`}
                                    value={eWalletProviderNumber}
                                    onChange={(e) => {
                                      setEWalletProviderNumber(e.target.value);

                                    }}
                                  />


                                </div>
                              </div>
                            </Col>}
                        </Row>


                      </CardBody>}
                  </Card>
                </Col>

                {/*add new cards  */}

                <Col md="4"  >
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Guardian Details
                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, guardian_details: !openCloseCards.guardian_details })}>
                          <i className={"tim-icons " + (openCloseCards.guardian_details ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.guardian_details &&
                      <CardBody>

                        <Row>
                          <Col md="12" lg="6" xl="4" >
                            <div className="form-group">
                              <label>Salutation</label>
                              <div>
                                <Select
                                  value={selectedSalutationGuardian}
                                  label={"Select Salutation"}
                                  onChange={(e) => {
                                    updateSalutationFields(e?.value, setGuardian_name, guardian_name, "salutation")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderSalutationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={guardian_name.split("–")[0]}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setGuardian_name, guardian_name, "salutation")

                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select Salutation
                                  </option>
                                  {SALUTATIONS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  )}
                                </select> */}

                              </div>
                            </div>

                          </Col>

                          <Col>

                            <div className="form-group">
                              <label>Guardian  Name   </label>
                              <div  >
                                <input
                                  type="text"
                                  className={`form-control  w-100 `}
                                  value={guardian_name.includes("–") ? guardian_name?.split("–")[1] : guardian_name}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setGuardian_name, guardian_name, "value")

                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="form-group">
                          <label>Guardian Relation   </label>
                          <div  >
                            <Select
                              value={selectedGuardianRelation}
                              label={"Select Relation"}
                              onChange={(e) => {
                                setGuardian_relation(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderRelationDropDown()}
                              styles={customStyles}
                            />
                            {/* <select
                              className={`form-control  w-100`}
                              value={guardian_relation}
                              onChange={(e) => {
                                setGuardian_relation(e.target.value);

                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {' '}
                                Select Relation
                              </option>
                              {RELATION.map((item, index) => {
                                return (
                                  <option value={item} key={item}>{item}</option>
                                )

                              })}

                            </select> */}
                          </div>
                        </div>

                        <div className="form-group ">
                          <label>Mother Maiden Name</label>
                          <div>
                            <input
                              type="text"
                              className={'form-control  w-100'}
                              value={guardianMotherMaidenName}
                              onChange={(e) => {
                                setGuardianMotherMaidenname(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Gender</label>
                              <div  >
                                <Select
                                  value={selectedGuardianGender}
                                  label={"Select Gender"}
                                  onChange={(e) => {
                                    setGuardianGender(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderGenderDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={guardianGender}
                                  onChange={(e) => {
                                    setGuardianGender(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Gender
                                  </option>
                                  {GENDER.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Martial Status</label>
                              <div  >
                                <Select
                                  value={selectedGuardianMaritalStatus}
                                  label={"Select Marital Status"}
                                  onChange={(e) => {
                                    setGuardianMaritalStatus(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderMaritalStatusDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={guardianMaritalStatus}
                                  onChange={(e) => {
                                    setGuardianMaritalStatus(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Marital Status
                                  </option>
                                  {MARITAL_STATUS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Occupation</label>
                              <div  >
                                <Select
                                  value={selectedGuardianOccupation}
                                  label={"Select Occupation"}
                                  onChange={(e) => {
                                    setGuardianOccupation(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderOccupationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={guardianOccupation}
                                  onChange={(e) => {
                                    setGuardianOccupation(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Occupation
                                  </option>
                                  {OCCUPATION.map(item =>
                                    <option value={item} key={item}>
                                      {item.slice(item.indexOf(" ") + 1)}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Annual Income</label>
                              <div  >
                                <NumberFormat
                                  thousandSeparator={true}
                                  style={{ textAlign: 'right' }}
                                  className={`form-control  w-100`}
                                  value={guardianAnnualIncome}
                                  onValueChange={(e) => {
                                    setGuardianAnnualIncome(e.value);

                                  }}
                                />


                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="form-group  ">
                          <label>Place Of Birth</label>
                          <div  >
                            <input
                              type="number"
                              className={`form-control  w-100 `}
                              value={guardianPlaceOfBith}
                              onChange={(e) => {
                                setGuardianPlaceOfBirth(e.target.value);

                              }}
                            />
                          </div>
                        </div>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <div className='d-flex justify-content-between '>
                                <label>CNIC</label>
                                <FormGroup check className='mt-0'>
                                  <Label check>
                                    <Input type="checkbox"
                                      checked={guardianCNICLifeTime}
                                      onChange={(e) => {
                                        setGuadianCNICLifeTime(!guardianCNICLifeTime);
                                      }} />
                                    <span className="form-check-sign" />
                                    Life Time
                                  </Label>
                                </FormGroup>
                              </div>
                              <div  >


                                <InputMask
                                  className={`form-control  w-100 `}
                                  mask="99999-9999999-9"
                                  value={guardian_cnic}
                                  onChange={(e) => {
                                    setGuardian_cnic(e.target.value);
                                  }}></InputMask>

                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div  >
                                <input
                                  type="date"
                                  className={`form-control  w-100 `}
                                  readOnly={guardianCNICLifeTime}
                                  value={guardianCNICExpiry}
                                  onChange={(e) => {
                                    setGuardianCNICExpiry(e.target.value);

                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </CardBody>}
                  </Card>
                </Col>


                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Nominee Details

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, nominee_details: !openCloseCards.nominee_details })}>
                          <i className={"tim-icons " + (openCloseCards.nominee_details ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.nominee_details &&
                      <CardBody>
                        <Row>
                          <Col md="12" lg="6" xl="4" >
                            <div className="form-group">
                              <label>Salutation</label>
                              <div>
                                <Select
                                  value={selectedNomineeSalutation}
                                  label={" Select Salutation"}
                                  onChange={(e) => {
                                    updateSalutationFields(e?.value, setNomineeName, nominee_name, "salutation")
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderSalutationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={nominee_name.split("–")[0]}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setNomineeName, nominee_name, "salutation")

                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    Select Salutation
                                  </option>
                                  {SALUTATIONS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>
                                  )}
                                </select> */}

                              </div>
                            </div>

                          </Col>
                          <Col>
                            <div className="form-group">
                              <label>Nominee   Name   </label>
                              <div  >
                                <input
                                  type="text"
                                  className={`form-control  w-100 `}
                                  value={nominee_name.includes("–") ? nominee_name?.split("–")[1] : nominee_name}
                                  onChange={(e) => {
                                    updateSalutationFields(e.target.value, setNomineeName, nominee_name, "value")

                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <div className="form-group">
                          <label>Nominee  Relation   </label>
                          <div  >
                            <Select
                              value={selectedNomineeRelation}
                              label={"Select Relation"}
                              onChange={(e) => {
                                setNomineeRelation(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderRelationDropDown()}
                              styles={customStyles}
                            />
                            {/* <select
                              className={`form-control  w-100`}
                              value={nominee_relation}
                              onChange={(e) => {
                                setNomineeRelation(e.target.value);

                              }}
                            >
                              <option value="" defaultChecked hidden>
                                {' '}
                                Select Relation
                              </option>
                              {RELATION.map((item, index) => {
                                return (
                                  <option value={item} key={item}>{item}</option>
                                )

                              })}

                            </select> */}
                          </div>
                        </div>

                        <div className="form-group ">
                          <label>Mother Maiden Name</label>
                          <div>
                            <input
                              type="text"
                              className={'form-control  w-100'}
                              value={nomineeMotherMaidenName}
                              onChange={(e) => {
                                setNomineeMotherMaidenname(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Gender</label>
                              <div  >
                                <Select
                                  value={selectedNomineeGender}
                                  label={"Select Gender"}
                                  onChange={(e) => {
                                    setNomineeGender(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderGenderDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={nomineeGender}
                                  onChange={(e) => {
                                    setNomineeGender(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Gender
                                  </option>
                                  {GENDER.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Martial Status</label>
                              <div  >
                                <Select
                                  value={selectedNomineeMaritalStatus}
                                  label={"Select Marital Status"}
                                  onChange={(e) => {
                                    setNomineeMaritalStatus(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderMaritalStatusDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={nomineeMaritalStatus}
                                  onChange={(e) => {
                                    setNomineeMaritalStatus(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Marital Status
                                  </option>
                                  {MARITAL_STATUS.map(item =>
                                    <option value={item} key={item}>
                                      {item}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Occupation</label>
                              <div  >
                                <Select
                                  value={selectedNomineeOccupation}
                                  label={"Select Occupation"}
                                  onChange={(e) => {
                                    setNomineeOccupation(e?.value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldBlockScroll={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  name="color"
                                  options={renderOccupationDropDown()}
                                  styles={customStyles}
                                />
                                {/* <select

                                  className={`form-control  w-100 `}
                                  value={nomineeOccupation}
                                  onChange={(e) => {
                                    setNomineeOccupation(e.target.value)
                                  }}
                                >
                                  <option value="" defaultChecked hidden>
                                    {' '}
                                    Select Occupation
                                  </option>
                                  {OCCUPATION.map(item =>
                                    <option value={item} key={item}>
                                      {item.slice(item.indexOf(" ") + 1)}
                                    </option>)}
                                </select> */}


                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>Annual Income</label>
                              <div  >
                                <NumberFormat
                                  thousandSeparator={true}
                                  style={{ textAlign: 'right' }}
                                  className={`form-control  w-100`}
                                  value={nomineeAnnualIncome}
                                  onValueChange={(e) => {
                                    setNomineeAnnualIncome(e.value);

                                  }}
                                />


                              </div>
                            </div>
                          </Col>
                        </Row>

                        <div className="form-group  ">
                          <label>Place Of Birth</label>
                          <div  >
                            <input
                              type="number"
                              className={`form-control  w-100 `}
                              value={nomineePlaceOfBith}
                              onChange={(e) => {
                                setNomineePlaceOfBirth(e.target.value);

                              }}
                            />
                          </div>
                        </div>

                        <Row>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <div className='d-flex justify-content-between '>
                                <label>CNIC</label>
                                <FormGroup check className='mt-0'>
                                  <Label check>
                                    <Input type="checkbox"
                                      checked={nomineeCNICLifeTime}
                                      onChange={(e) => {
                                        setNomineeCNICLifeTime(!nomineeCNICLifeTime);
                                      }} />
                                    <span className="form-check-sign" />
                                    Life Time
                                  </Label>
                                </FormGroup>
                              </div>
                              <div  >


                                <InputMask
                                  className={`form-control  w-100 `}
                                  mask="99999-9999999-9"
                                  value={nominee_cnic}
                                  onChange={(e) => {
                                    setNomineeCnic(e.target.value);
                                  }}></InputMask>

                              </div>
                            </div>
                          </Col>
                          <Col md="12" xl="6">
                            <div className="form-group  ">
                              <label>CNIC Expiry</label>
                              <div  >
                                <input
                                  type="date"
                                  className={`form-control  w-100 `}
                                  readOnly={nomineeCNICLifeTime}
                                  value={nomineeCNICExpiry}
                                  onChange={(e) => {
                                    setNomineeCNICExpiry(e.target.value);

                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>

                      </CardBody>}
                  </Card>
                </Col>



                {type == "Joint Account" &&
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle className='d-flex justify-content-between' tag="h4">
                          Joint Holder 1

                          <button
                            className='btn-round btn-icon btn-sm btn btn-primary'
                            onClick={() => setOpenCloseCards({ ...openCloseCards, joint_one: !openCloseCards.joint_one })}>
                            <i className={"tim-icons " + (openCloseCards.joint_one ? "icon-minimal-up" : "icon-minimal-down")} />
                          </button>
                        </CardTitle>
                      </CardHeader>
                      {openCloseCards.joint_one &&
                        <CardBody>

                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutationJoint}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderOneName, joint_h_1_name, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_1_name.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderOneName, joint_h_1_name, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>  Name   </label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_1_name.includes("–") ? joint_h_1_name?.split("–")[1] : joint_h_1_name}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderOneName, joint_h_1_name, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutation_h_1_father}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderOneFather, joint_h_1_father_spouse, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_1_father_spouse.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderOneFather, joint_h_1_father_spouse, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label> Father/Spouse Name </label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_1_father_spouse.includes("–") ? joint_h_1_father_spouse?.split("–")[1] : joint_h_1_father_spouse}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderOneFather, joint_h_1_father_spouse, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutationjointOneMotherMaidenName}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointOneMotherMaidenname, jointOneMotherMaidenName, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointOneMotherMaidenName.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointOneMotherMaidenname, jointOneMotherMaidenName, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>Mother Maiden Name</label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={jointOneMotherMaidenName.includes("–") ? jointOneMotherMaidenName?.split("–")[1] : jointOneMotherMaidenName}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointOneMotherMaidenname, jointOneMotherMaidenName, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group">
                            <label>Relation </label>
                            <div  >
                              <Select
                                value={selectedJointOneRelation}
                                label={"Select Relation"}
                                onChange={(e) => {
                                  setJointOneRelation(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderRelationDropDown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100`}
                                value={jointOneRelation}
                                onChange={(e) => {
                                  setJointOneRelation(e.target.value);

                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Relation
                                </option>
                                {RELATION.map((item, index) => {
                                  return (
                                    <option value={item} key={item}>{item}</option>
                                  )

                                })}

                              </select> */}
                            </div>
                          </div>



                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Gender</label>
                                <div  >
                                  <Select
                                    value={selectedjointOneGender}
                                    label={"Select Gender"}
                                    onChange={(e) => {
                                      setJointOneGender(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderGenderDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointOneGender}
                                    onChange={(e) => {
                                      setJointOneGender(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Gender
                                    </option>
                                    {GENDER.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Martial Status</label>
                                <div  >
                                  <Select
                                    value={selectedjointOneMaritalStatus}
                                    label={"Select Marital Status"}
                                    onChange={(e) => {
                                      setJointOneMaritalStatus(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderMaritalStatusDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointOneMaritalStatus}
                                    onChange={(e) => {
                                      setJointOneMaritalStatus(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Marital Status
                                    </option>
                                    {MARITAL_STATUS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Occupation</label>
                                <div  >
                                  <Select
                                    value={selectedjointOneOccupation}
                                    label={"Select Occupation"}
                                    onChange={(e) => {
                                      setJointOneOccupation(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderOccupationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointOneOccupation}
                                    onChange={(e) => {
                                      setJointOneOccupation(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Occupation
                                    </option>
                                    {OCCUPATION.map(item =>
                                      <option value={item} key={item}>
                                        {item.slice(item.indexOf(" ") + 1)}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Annual Income</label>
                                <div  >
                                  <NumberFormat
                                    thousandSeparator={true}
                                    style={{ textAlign: 'right' }}
                                    className={`form-control  w-100`}
                                    value={jointOneAnnualIncome}
                                    onValueChange={(e) => {
                                      setJointOneAnnualIncome(e.value);

                                    }}
                                  />


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group  ">
                            <label>Place Of Birth</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={jointOnePlaceOfBith}
                                onChange={(e) => {
                                  setJointOnePlaceOfBirth(e.target.value);

                                }}
                              />
                            </div>
                          </div>


                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <div className='d-flex justify-content-between '>
                                  <label>CNIC</label>
                                  <FormGroup check className='mt-0'>
                                    <Label check>
                                      <Input type="checkbox"
                                        checked={jointOneCNICLifeTime}
                                        onChange={(e) => {
                                          setJointOneCNICLifeTime(!jointOneCNICLifeTime);
                                        }} />
                                      <span className="form-check-sign" />
                                      Life Time
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div  >


                                  <InputMask
                                    className={`form-control  w-100 `}
                                    mask="99999-9999999-9"
                                    value={joint_h_1_cnic}
                                    onChange={(e) => {
                                      setJointHolderOneCnic(e.target.value);
                                    }}></InputMask>

                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>CNIC Expiry</label>
                                <div  >
                                  <input
                                    type="date"
                                    className={`form-control  w-100 `}
                                    readOnly={jointOneCNICLifeTime}
                                    value={jointOneCNICExpiry}
                                    onChange={(e) => {
                                      setJointOneCNICExpiry(e.target.value);

                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>


                          <div className="form-group">
                            <label> NTN   </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_1_ntn}
                                onChange={(e) => {
                                  setJointHolderOneNTN(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">
                            <label>Address Bill </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_1_address_bill}
                                onChange={(e) => {
                                  setJointHolderOneAddressBill(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">

                            <label>Address Bill Type</label>
                            <Select
                              value={selectedAddressBillType}
                              label={"Select Address Bill Type"}
                              onChange={(e) => {
                                setJointHolderOneAddressBillType(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={addressBillTypeArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_1_address_bill_type}
                              onChange={(e) => {
                                setJointHolderOneAddressBillType(e.target.value)
                              }}
                            >
                              <option value="" hidden> Select Address Bill Type</option>
                              <option value="Electric">Electric</option>
                              <option value="Gas">Gas</option>
                              <option value="Water">Water</option>
                              <option value="Internet">Internet</option>
                              <option value="Phone">Phone</option>
                            </select> */}
                          </div>

                          <div className="form-group">

                            <label>Tax Status</label>
                            <Select
                              value={selectedTaxStatus_h_1}
                              label={"Select Tax Type"}
                              onChange={(e) => {
                                setJointHolderOneTaxStatus(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={taxStatusArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_1_taxStatus}
                              onChange={(e) => {
                                setJointHolderOneTaxStatus(e.target.value)
                              }}
                            >
                              <option value="" hidden> Select Tax Type</option>
                              <option value="Filer">Filer</option>
                              <option value="Non-Filer">Non-Filer</option>
                            </select> */}
                          </div>

                        </CardBody>}
                    </Card>
                  </Col>}

                {type == "Joint Account" &&
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle className='d-flex justify-content-between' tag="h4">
                          Joint Holder 2
                          <button
                            className='btn-round btn-icon btn-sm btn btn-primary'
                            onClick={() => setOpenCloseCards({ ...openCloseCards, joint_two: !openCloseCards.joint_two })}>
                            <i className={"tim-icons " + (openCloseCards.joint_two ? "icon-minimal-up" : "icon-minimal-down")} />
                          </button>
                        </CardTitle>
                      </CardHeader>
                      {openCloseCards.joint_two &&
                        <CardBody>
                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutation_h_2_name}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderTwoName, joint_h_2_name, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_2_name.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderTwoName, joint_h_2_name, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>  Name   </label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_2_name.includes("–") ? joint_h_2_name?.split("–")[1] : joint_h_2_name}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderTwoName, joint_h_2_name, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutation_h_2_father_spouse}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderTwoFather, joint_h_2_father_spouse, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_2_father_spouse.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderTwoFather, joint_h_2_father_spouse, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>Father/Spouse Name</label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_2_father_spouse.includes("–") ? joint_h_2_father_spouse?.split("–")[1] : joint_h_2_father_spouse}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderTwoFather, joint_h_2_father_spouse, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedSalutationTwoMotherMaidenName}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointTwoMotherMaidenname, jointTwoMotherMaidenName, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointTwoMotherMaidenName.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointTwoMotherMaidenname, jointTwoMotherMaidenName, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>Mother Maiden Name</label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={jointTwoMotherMaidenName.includes("–") ? jointTwoMotherMaidenName?.split("–")[1] : jointTwoMotherMaidenName}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointTwoMotherMaidenname, jointTwoMotherMaidenName, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>





                          <div className="form-group">
                            <label> Relation   </label>
                            <div  >
                              <Select
                                value={selectedjointTwoRelation}
                                label={"Select Relation"}
                                onChange={(e) => {
                                  setJointTwoRelation(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderRelationDropDown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100`}
                                value={jointTwoRelation}
                                onChange={(e) => {
                                  setJointTwoRelation(e.target.value);

                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Relation
                                </option>
                                {RELATION.map((item, index) => {
                                  return (
                                    <option value={item} key={item}>{item}</option>
                                  )

                                })}

                              </select> */}
                            </div>
                          </div>

                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Gender</label>
                                <div  >
                                  <Select
                                    value={selectedjointTwoGender}
                                    label={"Select Gender"}
                                    onChange={(e) => {
                                      setJointTwoGender(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderGenderDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointTwoGender}
                                    onChange={(e) => {
                                      setJointTwoGender(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Gender
                                    </option>
                                    {GENDER.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Martial Status</label>
                                <div  >
                                  <Select
                                    value={selectedjointTwoMaritalStatus}
                                    label={"Select Marital Status"}
                                    onChange={(e) => {
                                      setJointTwoMaritalStatus(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderMaritalStatusDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointTwoMaritalStatus}
                                    onChange={(e) => {
                                      setJointTwoMaritalStatus(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Marital Status
                                    </option>
                                    {MARITAL_STATUS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Occupation</label>
                                <div  >
                                  <Select
                                    value={selectedjointTwoOccupation}
                                    label={"Select Occupation"}
                                    onChange={(e) => {
                                      setJointTwoOccupation(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderOccupationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointTwoOccupation}
                                    onChange={(e) => {
                                      setJointTwoOccupation(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Occupation
                                    </option>
                                    {OCCUPATION.map(item =>
                                      <option value={item} key={item}>
                                        {item.slice(item.indexOf(" ") + 1)}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Annual Income</label>
                                <div  >
                                  <NumberFormat
                                    thousandSeparator={true}
                                    style={{ textAlign: 'right' }}
                                    className={`form-control  w-100`}
                                    value={jointTwoAnnualIncome}
                                    onValueChange={(e) => {
                                      setJointTwoAnnualIncome(e.value);

                                    }}
                                  />


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group  ">
                            <label>Place Of Birth</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={jointTwoPlaceOfBith}
                                onChange={(e) => {
                                  setJointTwoPlaceOfBirth(e.target.value);

                                }}
                              />
                            </div>
                          </div>


                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <div className='d-flex justify-content-between '>
                                  <label>CNIC</label>
                                  <FormGroup check className='mt-0'>
                                    <Label check>
                                      <Input type="checkbox"
                                        checked={jointTwoCNICLifeTime}
                                        onChange={(e) => {
                                          setJointTwoCNICLifeTime(!jointTwoCNICLifeTime);
                                        }} />
                                      <span className="form-check-sign" />
                                      Life Time
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div  >


                                  <InputMask
                                    className={`form-control  w-100 `}
                                    mask="99999-9999999-9"
                                    value={joint_h_2_cnic}
                                    onChange={(e) => {
                                      setJointHolderTwoCnic(e.target.value);
                                    }}></InputMask>

                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>CNIC Expiry</label>
                                <div  >
                                  <input
                                    type="date"
                                    className={`form-control  w-100 `}
                                    readOnly={jointTwoCNICLifeTime}
                                    value={jointTwoCNICExpiry}
                                    onChange={(e) => {
                                      setJointTwoCNICExpiry(e.target.value);

                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <div className="form-group">
                            <label> NTN   </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_2_ntn}
                                onChange={(e) => {
                                  setJointHolderTwoNTN(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">
                            <label>Address Bill </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_2_address_bill}
                                onChange={(e) => {
                                  setJointHolderTwoAddressBill(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">

                            <label>Address Bill Type</label>
                            <Select
                              value={selectedAddress_h_2_address_bill_type}
                              label={"Select Address Bill Type"}
                              onChange={(e) => {
                                setJointHolderTwoAddressBillType(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={addressBillTypeArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_2_address_bill_type}
                              onChange={(e) => {
                                setJointHolderTwoAddressBillType(e.target.value)
                              }}
                            >
                              <option value="" hidden> Select Address Bill Type</option>
                              <option value="Electric">Electric</option>
                              <option value="Gas">Gas</option>
                              <option value="Water">Water</option>
                              <option value="Internet">Internet</option>
                              <option value="Phone">Phone</option>
                            </select> */}
                          </div>

                          <div className="form-group">

                            <label>Tax Status</label>
                            <Select
                              value={selectedTax_h_2_Status}
                              label={"Select Tax Type"}
                              onChange={(e) => {
                                setJointHolderTwoAddressBillType(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={taxStatusArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_2_taxStatus}
                              onChange={(e) => {
                                setJointHolderTwoTaxStatus(e.target.value)
                              }}>
                              <option value="" hidden> Select Tax Type</option>
                              <option value="Filer">Filer</option>
                              <option value="Non-Filer">Non-Filer</option>
                            </select> */}
                          </div>

                        </CardBody>}
                    </Card>
                  </Col>}

                {type == "Joint Account" &&
                  <Col md="4">
                    <Card>
                      <CardHeader>
                        <CardTitle className='d-flex justify-content-between' tag="h4">
                          Joint Holder 3

                          <button
                            className='btn-round btn-icon btn-sm btn btn-primary'
                            onClick={() => setOpenCloseCards({ ...openCloseCards, joint_three: !openCloseCards.joint_three })}>
                            <i className={"tim-icons " + (openCloseCards.joint_three ? "icon-minimal-up" : "icon-minimal-down")} />
                          </button>
                        </CardTitle>
                      </CardHeader>
                      {openCloseCards.joint_three &&
                        <CardBody>

                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selected_h_3_Salutation}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderThreeName, joint_h_3_name, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_3_name.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderThreeName, joint_h_3_name, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>  Name   </label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_3_name.includes("–") ? joint_h_3_name?.split("–")[1] : joint_h_3_name}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderThreeName, joint_h_3_name, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selected_h_3_father_spouse_Salutation}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointHolderThreeFather, joint_h_3_father_spouse, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={joint_h_3_father_spouse.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderThreeFather, joint_h_3_father_spouse, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>Father/Spouse Name</label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={joint_h_3_father_spouse.includes("–") ? joint_h_3_father_spouse?.split("–")[1] : joint_h_3_father_spouse}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointHolderThreeFather, joint_h_3_father_spouse, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" lg="6" xl="4" >
                              <div className="form-group">
                                <label>Salutation</label>
                                <div>
                                  <Select
                                    value={selectedjointThreeMotherMaidenNameSalutation}
                                    label={"Select Salutation"}
                                    onChange={(e) => {
                                      updateSalutationFields(e?.value, setJointThreeMotherMaidenname, jointThreeMotherMaidenName, "salutation")
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderSalutationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointThreeMotherMaidenName.split("–")[0]}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointThreeMotherMaidenname, jointThreeMotherMaidenName, "salutation")

                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      Select Salutation
                                    </option>
                                    {SALUTATIONS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>
                                    )}
                                  </select> */}

                                </div>
                              </div>

                            </Col>
                            <Col>
                              <div className="form-group">
                                <label>Mother Maiden Name</label>
                                <div  >
                                  <input
                                    type="text"
                                    className={`form-control  w-100 `}
                                    value={jointThreeMotherMaidenName.includes("–") ? jointThreeMotherMaidenName?.split("–")[1] : jointThreeMotherMaidenName}
                                    onChange={(e) => {
                                      updateSalutationFields(e.target.value, setJointThreeMotherMaidenname, jointThreeMotherMaidenName, "value")


                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>


                          <div className="form-group">
                            <label>Relation   </label>
                            <div  >
                              <Select
                                value={selectedjointThreeRelation}
                                label={"Select Relation"}
                                onChange={(e) => {
                                  setJointThreeRelation(e?.value);
                                }}
                                menuPortalTarget={document.body}
                                menuShouldBlockScroll={true}
                                isClearable={true}
                                isSearchable={true}
                                name="color"
                                options={renderRelationDropDown()}
                                styles={customStyles}
                              />
                              {/* <select
                                className={`form-control  w-100`}
                                value={jointThreeRelation}
                                onChange={(e) => {
                                  setJointThreeRelation(e.target.value);

                                }}
                              >
                                <option value="" defaultChecked hidden>
                                  {' '}
                                  Select Relation
                                </option>
                                {RELATION.map((item, index) => {
                                  return (
                                    <option value={item} key={item}>{item}</option>
                                  )

                                })}

                              </select> */}
                            </div>
                          </div>


                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Gender</label>
                                <div  >
                                  <Select
                                    value={selectedjointThreeGender}
                                    label={"Select Gender"}
                                    onChange={(e) => {
                                      setJointThreeGender(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderGenderDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointThreeGender}
                                    onChange={(e) => {
                                      setJointThreeGender(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Gender
                                    </option>
                                    {GENDER.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Martial Status</label>
                                <div  >
                                  <Select
                                    value={selectedjointThreeMaritalStatus}
                                    label={"Select Marital Status"}
                                    onChange={(e) => {
                                      setJointThreeMaritalStatus(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderMaritalStatusDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointThreeMaritalStatus}
                                    onChange={(e) => {
                                      setJointThreeMaritalStatus(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Marital Status
                                    </option>
                                    {MARITAL_STATUS.map(item =>
                                      <option value={item} key={item}>
                                        {item}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Occupation</label>
                                <div  >
                                  <Select
                                    value={selectedjointThreeOccupation}
                                    label={"Select Occupation"}
                                    onChange={(e) => {
                                      setJointThreeOccupation(e?.value);
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={renderOccupationDropDown()}
                                    styles={customStyles}
                                  />
                                  {/* <select

                                    className={`form-control  w-100 `}
                                    value={jointThreeOccupation}
                                    onChange={(e) => {
                                      setJointThreeOccupation(e.target.value)
                                    }}
                                  >
                                    <option value="" defaultChecked hidden>
                                      {' '}
                                      Select Occupation
                                    </option>
                                    {OCCUPATION.map(item =>
                                      <option value={item} key={item}>
                                        {item.slice(item.indexOf(" ") + 1)}
                                      </option>)}
                                  </select> */}


                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>Annual Income</label>
                                <div  >
                                  <NumberFormat
                                    thousandSeparator={true}
                                    style={{ textAlign: 'right' }}
                                    className={`form-control  w-100`}
                                    value={jointThreeAnnualIncome}
                                    onValueChange={(e) => {
                                      setJointThreeAnnualIncome(e.value);

                                    }}
                                  />
                                  {/* <small>
                                {(jointThreeAnnualIncome?.length > 0 || jointThreeAnnualIncome?.length < 17) ?inWords(jointThreeAnnualIncome): "" }
                              </small> */}


                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group  ">
                            <label>Place Of Birth</label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={jointThreePlaceOfBith}
                                onChange={(e) => {
                                  setJointThreePlaceOfBirth(e.target.value);

                                }}
                              />
                            </div>
                          </div>


                          <Row>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <div className='d-flex justify-content-between '>
                                  <label>CNIC</label>
                                  <FormGroup check className='mt-0'>
                                    <Label check>
                                      <Input type="checkbox"
                                        checked={jointThreeCNICLifeTime}
                                        onChange={(e) => {
                                          setJointThreeCNICLifeTime(!jointThreeCNICLifeTime);
                                        }} />
                                      <span className="form-check-sign" />
                                      Life Time
                                    </Label>
                                  </FormGroup>
                                </div>
                                <div  >


                                  <InputMask
                                    className={`form-control  w-100 `}
                                    mask="99999-9999999-9"
                                    value={joint_h_3_cnic}
                                    onChange={(e) => {
                                      setJointHolderThreeCnic(e.target.value);
                                    }}></InputMask>

                                </div>
                              </div>
                            </Col>
                            <Col md="12" xl="6">
                              <div className="form-group  ">
                                <label>CNIC Expiry</label>
                                <div  >
                                  <input
                                    type="date"
                                    className={`form-control  w-100 `}
                                    readOnly={jointThreeCNICLifeTime}
                                    value={jointThreeCNICExpiry}
                                    onChange={(e) => {
                                      setJointThreeCNICExpiry(e.target.value);

                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>



                          <div className="form-group">
                            <label> NTN   </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_3_ntn}
                                onChange={(e) => {
                                  setJointHolderThreeNTN(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">
                            <label>Address Bill </label>
                            <div  >
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={joint_h_3_address_bill}
                                onChange={(e) => {
                                  setJointHolderThreeAddressBill(e.target.value);
                                }}
                              />



                            </div>
                          </div>
                          <div className="form-group">

                            <label>Address Bill Type</label>
                            <Select
                              value={selectedjoint_h_3_address_bill_type}
                              label={"Select Occupation"}
                              onChange={(e) => {
                                setJointHolderThreeAddressBillType(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={addressBillTypeArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_3_address_bill_type}
                              onChange={(e) => {
                                setJointHolderThreeAddressBillType(e.target.value)
                              }}
                            >
                              <option value="" hidden> Select Address Bill Type</option>
                              <option value="Electric">Electric</option>
                              <option value="Gas">Gas</option>
                              <option value="Water">Water</option>
                              <option value="Internet">Internet</option>
                              <option value="Phone">Phone</option>
                            </select> */}
                          </div>

                          <div className="form-group">

                            <label>Tax Status</label>
                            <Select
                              value={selectedjoint_h_3_taxStatus}
                              label={"Select Tax Type"}
                              onChange={(e) => {
                                setJointHolderThreeTaxStatus(e?.value);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={taxStatusArr}
                              styles={customStyles}
                            />
                            {/* <select
                              className="form-control"
                              value={joint_h_3_taxStatus}
                              onChange={(e) => {
                                setJointHolderThreeTaxStatus(e.target.value)
                              }}>
                              <option value="" hidden> Select Tax Type</option>
                              <option value="Filer">Filer</option>
                              <option value="Non-Filer">Non-Filer</option>
                            </select> */}
                          </div>

                        </CardBody>}
                    </Card>
                  </Col>}



                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        CDC

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, cdc: !openCloseCards.cdc })}>
                          <i className={"tim-icons " + (openCloseCards.cdc ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.cdc &&
                      <CardBody>

                        <div className="form-group">
                          <label>Investment Account Service</label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 `}
                              value={cdc_IAS}
                              onChange={(e) => {
                                setCDCIAS(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label> CDC Subaccount </label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 `}
                              value={cdc_subAccount}
                              onChange={(e) => {
                                setCDCSubAccount(e.target.value);
                              }}
                            />

                          </div>
                        </div>
                        <div className="form-group">
                          <label>CDC participant ID</label>
                          <div  >
                            <input
                              type="text"
                              className={`form-control  w-100 `}
                              value={cdc_participantID}
                              onChange={(e) => {
                                setCDCParticipantID(e.target.value);
                              }}
                            />

                          </div>
                        </div>


                      </CardBody>}
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Sales Details

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, sales_details: !openCloseCards.sales_details })}>
                          <i className={"tim-icons " + (openCloseCards.sales_details ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>
                    </CardHeader>
                    {openCloseCards.sales_details &&
                      <CardBody>
                        <div className="form-group">
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox"
                                checked={salesInHouse}
                                onChange={(e) => {
                                  setSalesInHouse(!salesInHouse);
                                }} />
                              <span className="form-check-sign" />
                              In house sale
                            </Label>
                          </FormGroup>
                        </div>

                        <div className="form-group">
                          <label>Sales Company</label>

                          <div  >
                            {/* <Select
                              value={selectedSalesCompany}
                              label={"Select Sales Company"}
                              onChange={(e) => {
                                setSalesCompany(e?.value)
                                setSalesInHouse(allSalesCompany.find(item => item?.company_code === e?.value)?.in_house == true ? true : false)
                                setSalesPerson('')
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderallSalesCompanyDropDown()}
                              styles={customStyles}
                            /> */}
                            <select
                              className="form-control"
                              value={salesCompany}
                              onChange={(e) => {
                                setSalesCompany(e.target.value)
                                setSalesInHouse(allSalesCompany.find(item => item.company_code === e.target.value).in_house == true ? true : false)
                                setSalesPerson('')
                              }}

                            >
                              <option value="" hidden> Select Sales Company</option>
                              {allSalesCompany?.map((item, index) => {
                                return (
                                  <option key={item.company_code} value={item.company_code}>
                                    {item.company_name}
                                  </option>
                                );
                              })
                              }
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Sales Person</label>
                          <div  >
                            {/* <Select
                              value={selectedSalesPerson}
                              label={"Select Sales Person"}
                              onChange={(e) => {
                                setSalesPerson(e?.value)
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderSalesPersonDropDown()}
                              styles={customStyles}
                            /> */}
                            <select
                              className="form-control"
                              value={salesPerson}
                              onChange={(e) => {
                                setSalesPerson(e.target.value)
                              }}

                            >
                              <option value="" hidden> Select Sales Person</option>
                              {allSalesCompany.find(item => item.company_code == salesCompany)?.persons?.map((item, index) => {
                                return (
                                  <option key={item.email} value={item.id}>
                                    {`${item.name}(${item.email})`}
                                  </option>
                                );
                              })
                              }

                            </select>

                          </div>
                        </div>



                      </CardBody>}
                  </Card>
                </Col>

                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">
                        Funds Portfolio
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <ReactTooltip
                        textColor="white"
                        backgroundColor="black"
                        effect="float"
                      />
                      <button
                        data-tip="Add Fund"
                        className="btn-round btn-icon btn btn-primary"
                        onClick={() => {
                          setModalView(!modalView);
                          setDisableAddBtn(false);
                        }}
                      > <i className="fa fa-plus"></i> </button>


                      {totalFunds.length > 0 && (
                        <div className="input-size-pos mb-3 mx-3">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Fund code</th>
                                <th>Fund Name</th>
                                <th>NAV</th>
                                <th>Fund Unit</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>{renderFundDataInTable()}</tbody>
                          </table>
                        </div>
                      )}

                    </CardBody>


                  </Card>



                </Col>
                <Col md="12">

                  <Card>
                    <CardHeader>
                      <CardTitle className='d-flex justify-content-between' tag="h4">
                        Document

                        <button
                          className='btn-round btn-icon btn-sm btn btn-primary'
                          onClick={() => setOpenCloseCards({ ...openCloseCards, documents: !openCloseCards.documents })}>
                          <i className={"tim-icons " + (openCloseCards.documents ? "icon-minimal-up" : "icon-minimal-down")} />
                        </button>
                      </CardTitle>

                    </CardHeader>


                    {openCloseCards.documents &&
                      <CardBody className='row'>
                        {type && FilesByInvestorType[type]?.map((item, index) => {
                          return (
                            <div className="form-group col-md-6" key={item.value}>
                              <label >{item.label} &nbsp; &nbsp;
                                <small className="color-amber">
                                  (PNG, JPG, JPEG, PDF, ZIP)
                                </small></label>
                              <div className='row'>
                                <input
                                  type="file"
                                  ref={el => filesRef.current[item.value] = el}
                                  style={{ display: "none" }}
                                  multiple={false}
                                  //accept="image/png, image/jpeg"
                                  accept=".jpg,.jpeg,.png,.pdf,.zip"
                                  onChange={(e) => {
                                    upload(e, item.value);
                                  }}
                                />
                                <div className="col-md-3 "
                                  onClick={() => {
                                    if (!verifiedDocuments[item.value] && !data[item.value]?.verified) {
                                      filesRef.current[item.value]?.click()
                                    }
                                  }}
                                >
                                  <input
                                    type="text"
                                    className={`form-control w-100 multi-input ` + (!verifiedDocuments[item.value] && !data[item.value]?.verified && " pointer")}

                                    value={files[item.value]?.name ? files[item.value]?.name : "Upload File"}
                                    readOnly={verifiedDocuments[item.value]?.verified || data[item.value]?.verified}

                                  />


                                </div>
                                <div className='col-md-1 text-center'>OR</div>

                                <div className='col-md-6'>

                                  <input
                                    type="text"
                                    className={`form-control w-100 `}
                                    placeholder='Enter Link'
                                    readOnly={verifiedDocuments[item.value]?.verified || data[item.value]?.verified}
                                    value={filesLink[item.value] || data[item.value]?.file || ""}
                                    onChange={(e) => {
                                      setFilesLink({ ...filesLink, [item.value]: e.target.value });
                                    }}
                                  />

                                </div>
                                <div className='col-md-1'>
                                  {(data[item.value]?.verified || verifiedDocuments[item.value]?.verified)
                                    ? <FaRegCheckCircle color='#00ff00' className='ml-1' fontSize="xx-large" />
                                    :
                                    <FaExclamationCircle color='#ff5932'
                                      className={'ml-1 pointer'}
                                      fontSize="xx-large"
                                      onClick={() => {
                                        if (data[item.value]?.file) {
                                          setVerifiedDocumentModal({ modal: true, document: item })
                                        }
                                      }}
                                    />}

                                </div>
                              </div>
                              {data[item.value]?.file &&
                                <div className='d-flex '>
                                  <div className='mr-3'>
                                    <a href={data[item.value]?.file} target="_blank" >Download</a>
                                  </div>
                                  {!files[item.value]?.name && !filesLink[item.value] && ImagesUploadType.includes(data[item.value]?.file?.substring(data[item.value]?.file.lastIndexOf(".") + 1)?.toLowerCase()) &&
                                    <div className='mt-2' ><img src={data[item.value]?.file} style={{ maxWidth: '200px', width: 'auto', height: 'auto', maxHeight: '100px' }} /> </div>}

                                </div>}
                            </div>
                          )
                        })}
                      </CardBody>}
                  </Card>
                </Col>
              </Row>

              <div className='mb-2' >
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                      checked={true}
                    />
                    <span className="form-check-sign" />
                    Terms and Conditions agreed by investor
                  </Label>
                </FormGroup>
              </div>

              <div className=" ">
                <button
                  className="btn btn-primary "
                  onClick={updateInvestorData}
                  disabled={Boolean(Loading)}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border login-txt spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="login-txt"> Loading...</span>
                    </>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>



              {renderModalPopup()}
              {renderVerificationModalPopup()}
              {renderVerificationDocumentModalPopup()}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default EditPotentialInvestor;
import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import {
    getAllRoles,
    getAssignTransaction,
} from "../../stores/services/role.service";
import { addUser } from "../../stores/services/user.service";
import { toast, ToastContainer } from "react-toastify";
import { getFeatures } from "../../stores/services/role.service";
import CheckboxTree from "react-checkbox-tree";
import { Modal } from "react-bootstrap";
import { getFundByAmc } from "../../stores/services/funds.service";
import Select from "react-select";
import { useSelector } from "react-redux";
const AddUser = () => {

    const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
    const history = useHistory();
    const email = sessionStorage.getItem("email") || "";
    const amc_code = sessionStorage.getItem("amc_code") || "";
    const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]") || [];
    const department = sessionStorage.getItem("department") || "";
    const allDefaultDepartments =
        JSON.parse(sessionStorage.getItem("defaultDepartments") || "[]") || [];
    const [selectedFund, setSelectedFund] = useState<any>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<any>({});
    const [selectedDepartmentRole, setSelectedDepartmentRole] = useState<any>({});
    const [selectedRole, setSelectedRole] = useState<any>({});
    const [allFund, setAllFund] = useState<any>([]);
    const [Loading, setLoading] = useState(false);
    const [user_name, setUserName] = useState("");
    const [user_email, setUserEmail] = useState("");

    const [roles, setRoles] = useState<any>([]);
    let [nameError, setNameError] = useState(false);
    let [emailError, setEmailError] = useState(false);
    let [invalidEmailError, setInvalidEmailError] = useState(false);
    let [roleError, setRoleError] = useState(false);

    const [fileName, setFileName] = useState("");
    const [fileError, setFileError] = useState("");
    const [file, setFile] = useState("");
    const [base64SpicemenImg, setBase64SpicemenImg] = useState<any | null>(null);
    const myRef1 = useRef<HTMLInputElement>(null);

    const [roleAssignedFeatures, setRoleAssignedFeatures] = useState<any>([]);
    const [features, setFeatures] = useState<any>([]);
    const [roleTxnAssign, setRoleTxnAssign] = useState<any>([]);
    const [txnAssignCategory, setTxnAssignCategory] = useState([]);
    let [checked, setChecked] = useState<any>([]);
    let [expanded, setExpanded] = useState<any>([-1]);
    let [nodes, setNodes] = useState<any>([]);

    const [showLargeImage, setShowLargeImage] = useState(false);
    const [modalView, setModalView] = useState(false);

    let [txnChecked, setTxnChecked] = useState<any>([]);
    let [txnExpanded, setTxnExpanded] = useState<any>([-1]);
    let [txnNodes, setTxnNodes] = useState<any>([]);

    useEffect(() => {
        getFeatures(sessionStorage.getItem("email") || "")
            .then((response) => {
                setFeatures(response.data.features);
                response.data.features.unshift({
                    id: -1,
                    feature: "Assign All",
                    parent_id: null,
                });
                for (let index = 0; index < response.data.features.length; index++) {
                    if (response.data.features[index].parent_id === 0) {
                        response.data.features[index].parent_id = -1;
                    }
                    response.data.features[index].value =
                        response.data.features[index].id;
                    response.data.features[index].label =
                        response.data.features[index].feature;
                }
                var data = response.data.features;
                var root: any;
                const idMapping = data.reduce((acc: any, el: any, i: any) => {
                    acc[el.id] = i;
                    return acc;
                }, {});
                data.forEach((el: any) => {
                    // Handle the root element
                    if (el.parent_id === null) {
                        root = el;
                        return;
                    }
                    // Use our mapping to locate the parent element in our data array
                    const parentEl = data[idMapping[el.parent_id]];
                    // Add our current el to its parent's `children` array
                    parentEl.children = [...(parentEl.children || []), el];
                });
                setNodes([root]);
            })
            .catch((err) => { });
        const getAllFunds = async () => {
            try {
                const response = await getFundByAmc(email, amc_code, fund_code?.length > 0 ? fund_code : "");
                setAllFund(response.data.data);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                }
            }
        };
        const getRoles = async () => {
            try {
                const response = await getAllRoles(email, amc_code);
                setRoles(response.data.data.filter(item => item.status == "active"));
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                }
            }
        };
        const assignTxnToRole = async () => {
            try {
                let temp: any = [];
                const response = await getAssignTransaction(email);
                setTxnAssignCategory(response.data.txn_assign_category);
                response.data.txn_assign_category.unshift({
                    id: -1,
                    feature: "Assign All",
                    parent_id: null,
                });
                for (
                    let index = 0;
                    index < response.data.txn_assign_category.length;
                    index++
                ) {
                    if (response.data.txn_assign_category[index].parent_id === 0) {
                        response.data.txn_assign_category[index].parent_id = -1;
                    }
                    temp.push(response.data.txn_assign_category[index].id);
                    response.data.txn_assign_category[index].value =
                        response.data.txn_assign_category[index].id;
                    response.data.txn_assign_category[index].label =
                        response.data.txn_assign_category[index].feature;
                }

                setTxnChecked([...temp]);
                var data = response.data.txn_assign_category;
                var root: any;
                const idMapping = data.reduce((acc: any, el: any, i: any) => {
                    acc[el.id] = i;
                    return acc;
                }, {});
                data.forEach((el: any) => {
                    // Handle the root element
                    if (el.parent_id === null) {
                        root = el;
                        return;
                    }
                    // Use our mapping to locate the parent element in our data array
                    const parentEl = data[idMapping[el.parent_id]];
                    // Add our current el to its parent's `children` array
                    parentEl.children = [...(parentEl.children || []), el];
                });
                setTxnNodes([root]);
            } catch (err: any) {
                if (err.response !== undefined) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error(err.message);
                }
            }
        };
        assignTxnToRole();

        getRoles();
        getAllFunds();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const ValidateEmail = (email: string) => {
        if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                email
            )
        ) {
            return true;
        }
        return false;
    };

    const upload = (e) => {
        let file = e?.target.files[0];
        if (file) {
            let filesize = parseInt(file.size);
            if (filesize <= 50000) {
                let type = file.name.substring(file.name.lastIndexOf(".") + 1);

                if (type === "png" || type === "jpg" || type === "jpeg") {
                    //code here
                    setFile(file);
                    setFileName(file.name);
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = function () {
                        setBase64SpicemenImg(reader.result);
                    };
                } else {
                    toast.error("Invalid Format");
                }
            } else {
                toast.error("File size should be less than 50kb");
            }
        }
    };

    const registerTheUser = async () => {
        try {
            setNameError(false);
            setEmailError(false);
            setRoleError(false);
            setInvalidEmailError(false);

            if (!user_name || user_name.trim() === "") {
                toast.error("Name is Required");
                setLoading(false);
                return;
            }
            else if (!user_email || user_email.trim() === "") {
                toast.error("Email is Required");
                setLoading(false);
                return;
            }
            else if (!ValidateEmail(user_email)) {
                toast.error("Email is Invalid");
                setLoading(false);
                return;
            }
            else if (!selectedRole?.value) {
                toast.error("Role Selection is Required");
                setLoading(false);
                return;
            }
            else if (!selectedDepartment?.value) {
                toast.error("Department Selection is Required");
                setLoading(false);
                return;
            }
            else if (!selectedDepartmentRole?.value) {
                toast.error("Department Role Selection is Required");
                setLoading(false);
                return;
            } else if (fund_code?.length > 0 && selectedFund?.length == 0) {
                toast.error("Fund Selection is Required");
                setLoading(false);
                return;
            }
            setLoading(true);
            await addUserBtn();
        } catch (error: any) {
            setLoading(false);
            if (error.response != undefined) {
                toast.error(error.response.data.message);
            } else {
                toast.error(error.message);
            }
        }
    };

    const addUserBtn = async () => {
        let additionalaccess: any = [];

        additionalaccess = checked.filter(
            (val) => !roleAssignedFeatures.includes(parseFloat(val))
        );

        const additional_selected_features: any = [];
        for (let index = 0; index < additionalaccess.length; index++) {
            const feature = features.find(
                (x: any) => x.id === parseInt(additionalaccess[index])
            );
            if (feature) {
                additional_selected_features.push(feature);
            }
        }
        let denied_selected_features: any = [];
        let deniedaccess = roleAssignedFeatures.filter(
            (val) => !checked.includes(val.toString())
        );

        if (roleAssignedFeatures.sort().join(",") !== checked.sort().join(",")) {
            for (let index = 0; index < deniedaccess.length; index++) {
                const feature = features.find(
                    (x: any) => x.id === parseInt(deniedaccess[index])
                );
                if (feature) {
                    denied_selected_features.push(feature);
                }
            }
        }
        txnChecked = txnChecked.map(function (e) {
            return e.toString();
        });

        const deniedTxn = await txnAssignCategory.filter(
            (val: any) => !txnChecked.includes(val.id.toString())
        );

        try {
            const response = await addUser(
                email,
                JSON.stringify(additional_selected_features),
                amc_code,
                fund_code?.length > 0 ? fund_code : selectedFund?.length > 0 ? selectedFund?.map(item => item.value) : [],
                JSON.stringify(denied_selected_features),
                user_name,
                selectedRole?.value,
                selectedRole?.status,
                base64SpicemenImg == null ? "" : base64SpicemenImg.toString(),
                JSON.stringify(deniedTxn),
                selectedRole.features,
                department
                    ? department
                    : selectedDepartment?.value
                        ? selectedDepartment?.value
                        : "",
                selectedDepartmentRole?.value ? selectedDepartmentRole?.value : "",
                user_email
            );
            if (response.data.status === 200) {
                toast.success(response.data.message);
                setTimeout(function () {
                    history.replace("/admin/user-management");
                    setLoading(false);
                }, 3000);
            } else {
                setLoading(false);
                toast.error(response.data.message?.toString());
            }
        } catch (err: any) {
            setLoading(false);
            if (err.response !== undefined) {
                toast.error(err.response.data.message?.toString());
            } else {
                toast.error("Request Failed!");
            }
        }
    };

    const renderModalPopup = () => {
        switch (showLargeImage) {
            case true:
                return (
                    <Modal
                        className=""
                        dialogClassName="modal90w"
                        show={true}
                        size="lg"
                        backdrop={true}
                        enforceFocus={false}
                        onHide={() => {
                            setShowLargeImage(false);
                        }}
                    >
                        <div className="modal-header ">
                            <button
                                aria-hidden={true}
                                className="close"
                                data-dismiss="modal"
                                type="button"
                                onClick={() => {
                                    setShowLargeImage(false);
                                }}
                            >
                                <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h6 className="title title-up"> View </h6>
                        </div>

                        <div className="modal-body">
                            <img
                                src={base64SpicemenImg}
                                className="rounded"
                                alt="spicemen"
                                width="800px"
                                height="500px"
                            />
                        </div>
                    </Modal>
                );
            default:
                return "";
        }
    };

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? 'hsla(291, 64%, 42%, 0.5)'
                : isSelected
                    ? 'hsla(291, 64%, 42%, 1)'
                    : undefined,
            zIndex: 1
        }),
        menuList: styles => ({
            ...styles,
            background: theme == "dark" ? "#3b3b3b" : "#ffffff",
            color: theme == "dark" ? "white" : ""
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        }),
        control: (base, state) => ({
            ...base,
            background: theme == "dark" ? "#25273a" : "#f2f2f2",
            borderRadius: "0.4285rem",
            borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? "" : "#ba54f5"
            },
            '& .react-select__input input[type="color"]': {
                color: theme === 'dark' ? 'red' : 'black'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: theme == "dark" ? "white" : "black",
        }),
        input: base => ({
            ...base,
            color: theme == "dark" ? "white" : "black"
        })
    };

    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <Container fluid>
                            <ToastContainer />
                            <div className="card mt-4">
                                <div className="card-header">
                                    <h4 className="card-title">Add User</h4>
                                </div>
                                <div className="card-body">
                                    <div className="title-row">
                                        <h3 className="mb-1">User Info</h3>
                                        <Link
                                            to="/admin/user-management"
                                            className="t-3 btn btn-primary btn-sm"
                                            replace
                                        >
                                            {" "}
                                            <i className="fa fa-eye mr-2"></i> View All
                                        </Link>
                                    </div>
                                    <p className="t-3 mb-2">
                                        Please enter the following information to add a new admin or
                                        user for trustee system
                                    </p>
                                    <div className="">
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input
                                                        className="form-control"
                                                        value={user_name}
                                                        onChange={(e) => {
                                                            setUserName(e.target.value);
                                                        }}
                                                    />
                                                </div>
                                                {nameError === true ? (
                                                    <p className="error-labels">Name is Required.</p>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        className="form-control"
                                                        value={user_email}
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            setUserEmail(value.toLowerCase());
                                                        }}
                                                    />
                                                </div>
                                                {emailError === true ? (
                                                    <p className="error-labels">Email is Required.</p>
                                                ) : (
                                                    ""
                                                )}
                                                {invalidEmailError === true ? (
                                                    <p className="error-labels">Email is Invalid.</p>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>Select Role</label>
                                                    <Select
                                                        className="react-select info "
                                                        classNamePrefix="react-select"
                                                        label={"Select"}
                                                        // isDisabled={false}
                                                        onChange={(e) => {
                                                            //e.preventDefault();
                                                            setSelectedRole(e?.value ? e : {});
                                                            if (e?.value) {


                                                                const result = JSON.parse(
                                                                    e.features
                                                                ).map((a: any) => {
                                                                    return a.id;
                                                                });

                                                                setRoleAssignedFeatures(result);
                                                                setChecked(result);
                                                            } else {
                                                                setChecked([]);
                                                            }
                                                        }}
                                                        value={selectedRole?.value ? [selectedRole] : []}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={roles?.map((item) => {
                                                            return {
                                                                value: item.role_name,
                                                                label: item.role_name,
                                                                status: item.status,
                                                                features: item.features
                                                            };
                                                        })}
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                {roleError === true ? (
                                                    <p className="error-labels">Role is Required.</p>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>

                                            <Col md="6">
                                                <div className="form-group  ">
                                                    <label>Select Fund</label>
                                                    <Select
                                                        className="react-select info "
                                                        classNamePrefix="react-select"
                                                        isMulti={true}
                                                        label={"Select"}
                                                        // isDisabled={false}
                                                        onChange={(e) => {
                                                            //e.preventDefault();
                                                            setSelectedFund(e);
                                                        }}
                                                        value={selectedFund}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={allFund.map((item) => {
                                                            return {
                                                                value: item.symbol_code,
                                                                label: item.fund_name,
                                                            };
                                                        })}
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>

                                            {!department && (
                                                <Col md="6">
                                                    <div className="form-group  ">
                                                        <label>Select Department</label>
                                                        <Select
                                                            className="react-select info "
                                                            classNamePrefix="react-select"
                                                            label={"Select"}
                                                            // isDisabled={false}
                                                            onChange={(e) => {
                                                                //e.preventDefault();
                                                                setSelectedDepartment(e?.value ? e : {});
                                                                setSelectedDepartmentRole({})
                                                            }}
                                                            value={
                                                                selectedDepartment?.value
                                                                    ? [selectedDepartment]
                                                                    : []
                                                            }
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            name="color"
                                                            options={allDefaultDepartments.map((item) => {
                                                                return {
                                                                    value: item.department,
                                                                    label: item.department,
                                                                    departmentRoles: item.departmentRoles,
                                                                };
                                                            })}
                                                            menuPortalTarget={document.body}
                                                            menuShouldBlockScroll={true}
                                                            styles={customStyles}
                                                        />
                                                    </div>
                                                </Col>
                                            )}

                                            <Col md="6">
                                                <div className="form-group  ">
                                                    <label>Select Department Role</label>
                                                    <Select
                                                        className="react-select info "
                                                        classNamePrefix="react-select"
                                                        placeholder={
                                                            selectedDepartment?.value
                                                                ? "Select"
                                                                : "First Select Department"
                                                        }
                                                        // isDisabled={false}
                                                        onChange={(e) => {
                                                            //e.preventDefault();
                                                            setSelectedDepartmentRole(e?.value ? e : {});
                                                        }}
                                                        value={
                                                            selectedDepartmentRole?.value
                                                                ? [selectedDepartmentRole]
                                                                : []
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={
                                                            selectedDepartment?.value
                                                                ? selectedDepartment?.departmentRoles?.map(
                                                                    (item) => {
                                                                        return { value: item, label: item };
                                                                    }
                                                                )
                                                                : []
                                                        }
                                                        menuPortalTarget={document.body}
                                                        menuShouldBlockScroll={true}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <div className="form-group">
                                                    <label>
                                                        Signature Specimen{" "}
                                                        <small className="color-amber">
                                                            (PNG or JPG of upto 50KB)
                                                        </small>
                                                    </label>
                                                    <div
                                                        className="multi-input pointer"
                                                        onClick={() => myRef1?.current?.click()}
                                                    >
                                                        <div className="form-group">
                                                            <div className="form-control">
                                                                {fileName === "" ? "Upload File" : fileName}
                                                            </div>
                                                            <input
                                                                type="file"
                                                                ref={myRef1}
                                                                style={{ display: "none" }}
                                                                multiple={false}
                                                                accept="image/png, image/jpeg"
                                                                onChange={(e) => {
                                                                    upload(e);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {base64SpicemenImg && showLargeImage === false ? (
                                                    <img
                                                        src={base64SpicemenImg}
                                                        className="rounded"
                                                        onClick={() => setShowLargeImage(!showLargeImage)}
                                                        alt="spicemen"
                                                        width="100px"
                                                        height="100px"
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                        </Row>

                                        <div className="line"> </div>
                                        <Row>
                                            <Col md="6">
                                                <Row className="mt-3 ml-2">
                                                    <Col md="12">
                                                        <h3 className="mb-1">
                                                            {" "}
                                                            Assign Additional Features
                                                        </h3>
                                                        <p className="t-3 mb-2"> Features </p>
                                                    </Col>
                                                    <CheckboxTree
                                                        nodes={nodes}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={(checked) => {
                                                            setChecked(checked);
                                                            if (
                                                                features.filter(
                                                                    (item) =>
                                                                        (item.parent_id && item.parent_id != -1) ||
                                                                        !item.children
                                                                )?.length == checked.length
                                                            ) {
                                                                setNodes([
                                                                    {
                                                                        ...nodes[0],
                                                                        feature: "Unassign All",
                                                                        label: "Unassign All",
                                                                    },
                                                                ]);
                                                            } else if (nodes[0].feature != "Assign All") {
                                                                setNodes([
                                                                    {
                                                                        ...nodes[0],
                                                                        feature: "Assign All",
                                                                        label: "Assign All",
                                                                    },
                                                                ]);
                                                            }
                                                        }}
                                                        onExpand={(expanded) => {
                                                            setExpanded(expanded);
                                                        }}
                                                        iconsClass="fa5"
                                                    />
                                                </Row>
                                            </Col>

                                            <Col md="6">
                                                <Row className="mt-3 ml-2">
                                                    <Col md="12">
                                                        <h3 className="mb-1">
                                                            {" "}
                                                            Assign Transaction Types{" "}
                                                        </h3>{" "}
                                                        <p className="t-3 mb-2"> Transaction Types </p>
                                                    </Col>
                                                    <CheckboxTree
                                                        nodes={txnNodes}
                                                        checked={txnChecked}
                                                        expanded={txnExpanded}
                                                        onCheck={(txnChecked, node) => {
                                                            setTxnChecked(txnChecked);
                                                        }}
                                                        onExpand={(txnExpanded) => {
                                                            setTxnExpanded(txnExpanded);
                                                        }}
                                                        iconsClass="fa5"
                                                    />
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mt-3">
                                        <button
                                            className="btn btn-primary"
                                            onClick={registerTheUser}
                                            disabled={Boolean(Loading)}
                                        >
                                            {Loading ? (
                                                <>
                                                    <span
                                                        className="spinner-border login-txt spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="login-txt"> Loading...</span>
                                                </>
                                            ) : (
                                                <span>Add User</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>
                {renderModalPopup()}
            </div>
        </>
    );
};
export default AddUser;

import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useRef,
} from "react";
// import { MENUITEMS } from "../../../components/common/sidebar-component/menu";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
// import { useSelector } from "react-redux";
import "./Sidebar.css";
import { env } from "../../env";

const Searchbar = () => {
  const layout = "/admin";
  const mainmenu = JSON.parse(sessionStorage.getItem("allFeatures" || ""));
  const allroutes = JSON.parse(sessionStorage.getItem("allRoutes" || ""));
  const [searchValue, setsearchValue] = useState();
  const [searchOpen, setsearchOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [mapItem, setMapItem] = useState([]);
  // const mixLayout = useSelector((state) => state?.Customizer?.mix_layout);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      setsearchOpen(false);
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    // eslint-disable-next-line
    mainmenu.filter((menuItems) => {
      if (
        menuItems.label.toLowerCase().includes(keyword) &&
        (menuItems.submenu?.length === 0 || !menuItems.submenu)
      ) {
        items.push(menuItems);
      }
      if (!menuItems.submenu) return false;
      // eslint-disable-next-line
      menuItems.submenu.filter((subItems) => {
        // eslint-disable-next-line
        if (
          subItems.label.toLowerCase().includes(keyword) &&
          (subItems.submenu?.length === 0 || !subItems.submenu)
        ) {
          subItems.icon = menuItems.icon;
          items.push(subItems);
          // eslint-disable-next-line
        }
        if (!subItems.submenu) return false;
        // eslint-disable-next-line
        subItems.submenu.filter((suSubItems) => {
          if (suSubItems.label.toLowerCase().includes(keyword)) {
            suSubItems.icon = menuItems.icon;
            items.push(suSubItems);
          }
        });
      });

      // checkSearchResultEmpty(items);
      // setsearchValue(items);
    });

    // const updatedItems = items.map((ele) => {
    //     // let foundRoute = allroutes?.find((main) => {
    //     //     console.log("main---", main)
    //     //     return (
    //     //         main?.name === ele?.label
    //     //     )
    //     // });
    //     // if (!foundRoute) {
    //     //     console.log("it runds")
    //     //     const allViews = allroutes?.flatMap(main => main.views || []).filter(view => view);
    //     //     // console.log("flatmap", allViews)
    //     //     foundRoute = allViews.find(sub => sub?.name === ele?.label);
    //     // }
    //     let foundRoute
    //     allroutes?.map((main) => {
    //         console.log("main---", main)
    //         foundRoute = allroutes.find((main1) => main1?.name === ele?.label)
    //         let parentName = main?.name
    //         if (!foundRoute) {
    //             const allViews = allroutes?.flatMap(main => main.views || []).filter(view => view);
    //             foundRoute = allViews.find(sub => {
    //                 let label
    //                 if (sub.name?.includes("Dashboard") || sub.name?.includes("Transaction") || sub.name?.includes("Create")) {
    //                     label = sub.name + " " + parentName
    //                     console.log(ele?.label, "first---", label)
    //                     console.log("matached---", label == ele?.label)
    //                 }
    //                 return (
    //                     label === ele?.label
    //                 )
    //             });
    //         }

    //     });
    //     console.log("found route---", foundRoute)

    //     return {
    //         ...ele,
    //         path: foundRoute?.path
    //     };
    // });

    const updatedItems = items.map((ele) => {
      let foundRoute;
      foundRoute = allroutes?.find((main) => main?.name === ele?.label);
      if (!foundRoute) {
        const allViews =
          allroutes?.flatMap((main) => {
            if (!Array.isArray(main.views)) return [];
            return main.views
              .map((sub) => {
                if (!sub) return null;
                const parentName = main.name;
                let label = sub.name;
                if (
                  sub.name?.includes("Dashboard") ||
                  sub.name?.includes("Transactions") ||
                  sub.name?.includes("Create Transactions")
                ) {
                  label = `${sub.name} ${parentName}`;
                }
                return { ...sub, label };
              })
              .filter(Boolean);
          }) || [];
        foundRoute = allViews.find((sub) => sub?.label === ele?.label);
      }

      return {
        ...ele,
        path: foundRoute?.path, // Use path from foundRoute if found
      };
    });

    setMapItem([...updatedItems]);
    // setsearchValue([...updatedItems])
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      document.querySelector(".empty-menu").classList.add("is-open");
    } else {
      document.querySelector(".empty-menu").classList.remove("is-open");
    }
  };

  const addFix = () => {
    const typeaheadMenu = document.querySelector(".Typeahead-menu");
    if (typeaheadMenu) {
      typeaheadMenu.classList.add("is-open");
    }
  };

  const removeFix = () => {
    setsearchValue("");
    const typeaheadMenu = document.querySelector(".Typeahead-menu");
    if (typeaheadMenu) {
      typeaheadMenu.classList.remove("is-open");
    }
  };

  const toggleBtn = () => {
    if (searchOpen) {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.add("open");
    } else {
      setsearchOpen(!searchOpen);
      document.querySelector(".searchIcon").classList.remove("open");
    }
  };

  const [screen, setScreen] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreen(window?.outerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        const typeHead = document.querySelector(".Typeahead-menu");
        if (typeHead) {
          typeHead.classList.remove("is-open");
        }
        const emptyMenu = document.querySelector(".empty-menu");
        if (emptyMenu) {
          emptyMenu.classList.remove("is-open");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let themeColor = env.REACT_APP_TITLE?.toUpperCase()?.includes("UAT")
    ? "redish"
    : env.REACT_APP_TITLE?.toUpperCase()?.includes("DEV")
    ? "brownish"
    : "";
  const BgAccortoThemeHorizontal =
    themeColor == "redish"
      ? "linear-gradient(rgba(102, 51, 0, 1) 0%, rgba(255, 51, 153, 1) 100%)"
      : themeColor == "brownish"
      ? "linear-gradient(to right, rgb(120 108 95) 0%, rgb(48, 67, 82) 100%)"
      : "";

  return (
    <Fragment>
      <div
        className={
          localStorage.getItem("wrapper") === "horizontal_sidebar"
            ? "col-12 px-0 pt-3"
            : "col-12 px-0"
        }
      >
        <form className="col-12 form-inline search-form">
          <div style={{ width: "inherit" }} className="position-relative">
            <input
              ref={inputRef}
              style={{
                width:
                  localStorage.getItem("wrapper") === "horizontal_sidebar"
                    ? "99%"
                    : "inherit",
                backgroundColor: "",
                borderColor: "",
                color:
                  themeColor == "redish" || themeColor == "brownish"
                    ? "white"
                    : "",
              }}
              className="form-control-plaintext searchIcon"
              type="text"
              placeholder="search"
              value={selectedItem}
              onClick={() => {
                if (selectedItem && !searchValue?.length) {
                  document
                    .querySelector(".empty-menu")
                    .classList.add("is-open");
                }
                selectedItem && addFix();
              }}
              onChange={(e) => {
                setsearchValue(e.target.value?.toLowerCase());
                handleSearchKeyword(e.target.value?.toLowerCase());
                setSelectedItem(e.target.value);
              }}
            />
            <div
              style={{ top: "12px", left: "12px" }}
              className="position-absolute"
            >
              <IoSearchOutline
                style={{ marginBottom: "6px" }}
                width={18}
                height={18}
                color="#8e8e8e"
              />
              <hr
                style={{
                  position: "absolute",
                  top: "-15px",
                  left: "30px",
                  border: "none",
                  height: "20px",
                  borderRight: "1px solid",
                  borderColor: "",
                }}
              ></hr>
            </div>

            {searchValue ? (
              <div
                ref={dropdownRef}
                style={{
                  border: "",
                  width: "98%",
                  top: screen <= 575 ? "115%" : "",
                  background: BgAccortoThemeHorizontal,
                  color: "",
                }}
                className="Typeahead-menu custom-scrollbar"
                id="search-outer"
              >
                {mapItem?.length > 0 ? (
                  mapItem?.map((data, index) => {
                    return (
                      <>
                        <div
                          id={""}
                          // className="ProfileCard u-cf"
                          className="d-flex"
                          key={index}
                        >
                          <div className="ProfileCard-avatar ml-2">
                            <i className={data.icon}></i>
                          </div>
                          <div className="ProfileCard-details ml-2">
                            <div className="ProfileCard-realName">
                              <Link
                                style={{ color: "" }}
                                to={`${env.PUBLIC_URL}${layout}${data?.path}`}
                                className="realname"
                                onClick={(e) => {
                                  handleSearchKeyword(
                                    data.label?.toLowerCase()
                                  );
                                  setSelectedItem(data.label);
                                  document
                                    .querySelector(".Typeahead-menu")
                                    .classList.remove("is-open");
                                }}
                              >
                                {data.label}
                              </Link>
                            </div>
                          </div>
                        </div>
                        {mapItem?.length - 1 !== index && (
                          <hr
                            style={{
                              margin: "3px",
                            }}
                          ></hr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="EmptyMessage">{"No result found."}</div>
                )}
              </div>
            ) : (
              ""
            )}

            {/* <div
                            style={{
                                border: "",
                                backgroundColor: "",
                                color: "",
                            }}
                            id="NoResultFound"
                            className="Typeahead-menu empty-menu"
                        >
                            <div className="tt-dataset tt-dataset-0">
                                <div className="EmptyMessage">{"No result found."}</div>
                            </div>
                        </div> */}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Searchbar;

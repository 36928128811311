import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { BsTable } from "react-icons/bs";
import { getFundByAmc } from "../../stores/services/funds.service";
import { getAccountByFund } from "../../stores/services/account.service";
import {
  getBankStatement,
  getBankStatementOpeningBalance,
} from "../../stores/services/report.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { getChartOfAccounts } from "../../stores/services/chatofaccounts.service";
import { getAllLedgers, numberWithCommas } from "../../utils/customFunction";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { getTrialBalanceReportData } from "../../stores/services/trial-balance-report.service";
import { array } from "prop-types";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import Select from "react-select";

//for tree
import "./table.css";

function SimpleRows(props: {
  simplerows: any;
  period: any;
  count: number;
  setCount: any;
  expandall: boolean;
  setExpandAll: any;
  setlevelCheck: any;
  levelCheck: any;
  setMainFamilyCode: any;
  mainFamilyCode: any;
}) {
  const [simpleOpen, setSimpleOpen] = useState(false);
  const {
    simplerows,
    period,
    count,
    setCount,
    expandall,
    setExpandAll,
    setlevelCheck,
    levelCheck,
    setMainFamilyCode,
    mainFamilyCode,
  } = props;

  let calculation: any = "";

  if (period) {
    calculation =
      parseFloat(simplerows.total_debit) +
      parseFloat(period.total_debit) -
      (parseFloat(simplerows.total_credit) + parseFloat(period.total_credit));
  } else {
    calculation =
      parseFloat(simplerows.total_debit) +
      0 -
      (parseFloat(simplerows.total_credit) + 0);
  }

  let reportDebit: any = "";
  let reportCredit: any = "";

  if (calculation > 0) {
    reportDebit = calculation.toFixed(2);
    reportCredit = 0;
  } else if (calculation < 0) {
    reportDebit = 0;
    reportCredit = (calculation * -1).toFixed(2);
  }

  // let reportDebit =
  // parseFloat(simplerows.total_debit) +
  // parseFloat(period.total_debit);
  // let reportCredit =
  // parseFloat(simplerows.total_credit) +
  // parseFloat(period.total_credit);

  useEffect(() => {
    if (count > 0) {
      if (expandall === true) {
        setSimpleOpen(true);
      } else if (expandall === false) {
        setSimpleOpen(false);
      }
    }
  }, [expandall, levelCheck]);

  if (simplerows.main_family_code !== "0") {
    return (
      <>
        <tr
          className="border-bottom"
          style={{ backgroundColor: "rgba(96, 130, 182, 1.0)" }}
        >
          <td style={{ width: "5%" }}>
            <small>
              <b>{simplerows.main_family_code}</b>
            </small>
          </td>
          <td style={{ width: "30%" }}>
            <b>
              {simplerows.level2.length > 0 ? (
                simpleOpen ? (
                  <IoIosArrowDown
                    onClick={() => {
                      setSimpleOpen(!simpleOpen);
                      setCount(0);
                      setExpandAll(false);
                      if (mainFamilyCode?.length <= 1) {
                        setlevelCheck({
                          simple_rows: false,
                          sub_rows: false,
                          sub_sub_rows: false,
                          sub_sub_sub_rows: false,
                        });
                        setMainFamilyCode([]);
                      } else {
                        setMainFamilyCode((prevCodes) =>
                          prevCodes?.filter(
                            (code) => code !== simplerows?.main_family_code
                          )
                        );
                      }
                    }}
                  />
                ) : (
                  <IoIosArrowForward
                    onClick={() => {
                      setSimpleOpen(!simpleOpen);
                      setCount(0);
                      setExpandAll(false);
                      setlevelCheck((prev) => {
                        return {
                          ...prev,
                          simple_rows: true,
                        };
                      });
                      let temp: any = [...mainFamilyCode];
                      if (mainFamilyCode?.length > 0) {
                        if (
                          !mainFamilyCode?.includes(
                            simplerows?.main_family_code
                          )
                        ) {
                          temp?.push(simplerows?.main_family_code);
                        }
                      } else {
                        temp?.push(simplerows?.main_family_code);
                      }
                      setMainFamilyCode([...temp]);
                    }}
                  />
                )
              ) : null}
              &nbsp;
              {simplerows.main_family_name}
            </b>
          </td>

          <td align="right">
            <b>
              {period?.total_debit
                ? numberWithCommas(parseFloat(period?.total_debit).toFixed(2))
                : 0}
            </b>
          </td>
          <td align="right">
            <b>
              {period?.total_credit
                ? numberWithCommas(parseFloat(period?.total_credit).toFixed(2))
                : 0}
            </b>
          </td>

          <td align="right">
            <b> {numberWithCommas(simplerows.total_debit.toFixed(2))} </b>
          </td>
          <td align="right">
            <b> {numberWithCommas(simplerows.total_credit.toFixed(2))} </b>
          </td>

          <td align="right">
            <b> {numberWithCommas(reportDebit)} </b>
          </td>
          <td align="right">
            <b> {numberWithCommas(reportCredit)} </b>
          </td>
        </tr>

        {simpleOpen &&
          simplerows?.level2.map((subItem: any, subIndex: number) => {
            let periodItem: any = "";
            if (period.level2) {
              period?.level2.map((periodItemSend, periodItemIndex) => {
                if (periodItemSend.family_code === subItem.family_code) {
                  periodItem = periodItemSend;
                }
              });
            }
            return (
              <SubRow
                key={subIndex}
                subrows={subItem}
                perioditem={periodItem}
                count={count}
                setCount={setCount}
                expandall={expandall}
                setExpandAll={setExpandAll}
                setlevelCheck={setlevelCheck}
                levelCheck={levelCheck}
                setMainFamilyCode={setMainFamilyCode}
                mainFamilyCode={mainFamilyCode}
              />
            );
          })}
      </>
    );
  } else if (simplerows?.level2) {
    return (
      <>
        {simplerows?.level2.map((subItem: any, subIndex: number) => {
          let periodItem: any = "";
          if (period.level2) {
            period?.level2.map((periodItemSend, periodItemIndex) => {
              if (periodItemSend.family_code === subItem.family_code) {
                periodItem = periodItemSend;
              }
            });
          }

          return (
            <>
              <SubRow
                key={subIndex}
                subrows={subItem}
                perioditem={periodItem}
                count={count}
                setCount={setCount}
                expandall={expandall}
                setExpandAll={setExpandAll}
                setlevelCheck={setlevelCheck}
                levelCheck={levelCheck}
                setMainFamilyCode={setMainFamilyCode}
                mainFamilyCode={mainFamilyCode}
              />
            </>
          );
        })}
      </>
    );
  } else {
    return null;
  }
}

function SubRow(props: {
  subrows: any;
  perioditem: any;
  count: number;
  setCount: any;
  expandall: boolean;
  setExpandAll: any;
  setlevelCheck: any;
  levelCheck: any;
  setMainFamilyCode: any;
  mainFamilyCode: any;
}) {
  const [simpleOpen, setSimpleOpen] = useState(false);
  const {
    subrows,
    perioditem,
    count,
    setCount,
    expandall,
    setExpandAll,
    setlevelCheck,
    levelCheck,
    setMainFamilyCode,
    mainFamilyCode,
  } = props;

  let calculation: any = "";
  console.log("sadasdasd", mainFamilyCode);

  if (perioditem) {
    calculation =
      parseFloat(subrows.total_debit) +
      parseFloat(perioditem.total_debit) -
      (parseFloat(subrows.total_credit) + parseFloat(perioditem.total_credit));
  } else {
    calculation =
      parseFloat(subrows.total_debit) +
      0 -
      (parseFloat(subrows.total_credit) + 0);
  }

  let reportDebit: any = "";
  let reportCredit: any = "";

  if (calculation > 0) {
    reportDebit = calculation.toFixed(2);
    reportCredit = 0;
  } else if (calculation < 0) {
    reportDebit = 0;
    reportCredit = (calculation * -1).toFixed(2);
  }

  // let reportDebit =
  //   parseFloat(subrows?.total_debit) +
  //   parseFloat(perioditem?.total_debit);
  // let reportCredit =
  //   parseFloat(subrows?.total_credit) +
  //   parseFloat(perioditem?.total_credit);

  useEffect(() => {
    if (count > 0) {
      if (expandall === true) {
        setSimpleOpen(true);
      } else if (expandall === false) {
        setSimpleOpen(false);
      }
    }
  }, [expandall, levelCheck]);

  if (subrows.family_code !== "0") {
    return (
      <>
        <tr
          className="border-bottom"
          style={{ backgroundColor: "rgba(96, 130, 182, 0.8)" }}
        >
          <td style={{ width: "5%" }}>
            <small>{subrows.family_code}</small>
          </td>
          <td style={{ paddingLeft: "1rem", width: "30%" }}>
            {subrows.level3.length > 0 ? (
              simpleOpen ? (
                <IoIosArrowDown
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_rows: false,
                      };
                    });
                  }}
                />
              ) : (
                <IoIosArrowForward
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_rows: true,
                      };
                    });
                  }}
                />
              )
            ) : null}
            &nbsp;
            {subrows.family_name}
          </td>

          <td align="right">
            {perioditem?.total_debit
              ? numberWithCommas(parseFloat(perioditem.total_debit).toFixed(2))
              : 0}
          </td>
          <td align="right">
            {perioditem?.total_credit
              ? numberWithCommas(parseFloat(perioditem.total_credit).toFixed(2))
              : 0}
          </td>

          <td align="right">
            {numberWithCommas(subrows.total_debit.toFixed(2))}
          </td>
          <td align="right">
            {numberWithCommas(subrows.total_credit.toFixed(2))}
          </td>

          <td align="right">{numberWithCommas(reportDebit)}</td>
          <td align="right">{numberWithCommas(reportCredit)}</td>
        </tr>
        {simpleOpen &&
          subrows?.level3.map((subSubItem: any, subSubIndex: number) => {
            let periodItemItem: any = "";
            if (perioditem.level3) {
              perioditem?.level3.map(
                (periodItemItemSend, periodItemItemIndex) => {
                  if (
                    periodItemItemSend.sub_family_code ===
                    subSubItem.sub_family_code
                  ) {
                    periodItemItem = periodItemItemSend;
                  }
                }
              );
            }
            return (
              <SubSubRow
                key={subSubIndex}
                subsubrows={subSubItem}
                perioditemitem={periodItemItem}
                count={count}
                setCount={setCount}
                expandall={expandall}
                setExpandAll={setExpandAll}
                setlevelCheck={setlevelCheck}
                levelCheck={levelCheck}
                setMainFamilyCode={setMainFamilyCode}
                mainFamilyCode={mainFamilyCode}
              />
            );
          })}
      </>
    );
  } else if (subrows?.level3) {
    return (
      <>
        {subrows?.level3.map((subSubItem: any, subSubIndex: number) => {
          let periodItemItem: any = "";
          if (perioditem.level3) {
            perioditem?.level3.map(
              (periodItemItemSend, periodItemItemIndex) => {
                if (
                  periodItemItemSend.sub_family_code ===
                  subSubItem.sub_family_code
                ) {
                  periodItemItem = periodItemItemSend;
                }
              }
            );
          }

          return (
            <SubSubRow
              key={subSubIndex}
              subsubrows={subSubItem}
              perioditemitem={periodItemItem}
              count={count}
              setCount={setCount}
              expandall={expandall}
              setExpandAll={setExpandAll}
              setlevelCheck={setlevelCheck}
              levelCheck={levelCheck}
              setMainFamilyCode={setMainFamilyCode}
              mainFamilyCode={mainFamilyCode}
            />
          );
        })}
      </>
    );
  } else {
    return null;
  }
}

function SubSubRow(props: {
  subsubrows: any;
  perioditemitem: any;
  count: number;
  setCount: any;
  expandall: boolean;
  setExpandAll: any;
  setlevelCheck: any;
  levelCheck: any;
  setMainFamilyCode: any;
  mainFamilyCode: any;
}) {
  const [simpleOpen, setSimpleOpen] = useState(false);

  const {
    subsubrows,
    perioditemitem,
    count,
    setCount,
    expandall,
    setExpandAll,
    setlevelCheck,
    levelCheck,
    setMainFamilyCode,
    mainFamilyCode,
  } = props;

  let calculation: any = "";

  if (perioditemitem) {
    calculation =
      parseFloat(subsubrows.total_debit) +
      parseFloat(perioditemitem.total_debit) -
      (parseFloat(subsubrows.total_credit) +
        parseFloat(perioditemitem.total_credit));
  } else {
    calculation =
      parseFloat(subsubrows.total_debit) +
      0 -
      (parseFloat(subsubrows.total_credit) + 0);
  }

  let reportDebit: any = "";
  let reportCredit: any = "";

  if (calculation > 0) {
    reportDebit = calculation.toFixed(2);
    reportCredit = 0;
  } else if (calculation < 0) {
    reportDebit = 0;
    reportCredit = (calculation * -1).toFixed(2);
  }

  // let reportDebit =
  //   parseFloat(subsubrows?.total_debit) +
  //   parseFloat(perioditemitem?.total_debit);
  // let reportCredit =
  //   parseFloat(subsubrows?.total_credit) +
  //   parseFloat(perioditemitem?.total_credit);

  useEffect(() => {
    if (count > 0) {
      if (expandall === true) {
        setSimpleOpen(true);
      } else if (expandall === false) {
        setSimpleOpen(false);
      }
    }
  }, [expandall, levelCheck]);
  if (subsubrows.sub_family_code !== "0") {
    return (
      <>
        <tr
          className="border-bottom"
          style={{ backgroundColor: "rgba(96, 130, 182, 0.6)" }}
        >
          <td style={{ width: "5%" }}>
            <small>{subsubrows.sub_family_code}</small>
          </td>

          <td style={{ paddingLeft: "1.5rem", width: "30%" }}>
            {subsubrows.level4.length > 0 ? (
              simpleOpen ? (
                <IoIosArrowDown
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_sub_rows: false,
                      };
                    });
                  }}
                />
              ) : (
                <IoIosArrowForward
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_sub_rows: true,
                      };
                    });
                  }}
                />
              )
            ) : null}
            &nbsp;
            {subsubrows.sub_family_name}
          </td>

          <td align="right">
            {perioditemitem?.total_debit
              ? numberWithCommas(
                  parseFloat(perioditemitem.total_debit).toFixed(2)
                )
              : 0}
          </td>
          <td align="right">
            {perioditemitem?.total_credit
              ? numberWithCommas(
                  parseFloat(perioditemitem.total_credit).toFixed(2)
                )
              : 0}
          </td>

          <td align="right">
            {numberWithCommas(subsubrows.total_debit.toFixed(2))}
          </td>
          <td align="right">
            {numberWithCommas(subsubrows.total_credit.toFixed(2))}
          </td>

          <td align="right">{numberWithCommas(reportDebit)}</td>
          <td align="right">{numberWithCommas(reportCredit)}</td>
        </tr>
        {simpleOpen &&
          subsubrows?.level4.map((subSubSubItem: any, subSubIndex: number) => {
            let periodItemItemItem: any = "";

            if (perioditemitem.level4) {
              perioditemitem?.level4.map(
                (periodItemItemItemSend, periodItemItemItemIndex) => {
                  if (
                    periodItemItemItemSend.child_family_code ===
                    subSubSubItem.child_family_code
                  ) {
                    periodItemItemItem = periodItemItemItemSend;
                  }
                }
              );
            }

            return (
              <SubSubSubRow
                key={subSubIndex}
                subsubsubrows={subSubSubItem}
                perioditemitemitem={periodItemItemItem}
                count={count}
                setCount={setCount}
                expandall={expandall}
                setExpandAll={setExpandAll}
                setlevelCheck={setlevelCheck}
                levelCheck={levelCheck}
                setMainFamilyCode={setMainFamilyCode}
                mainFamilyCode={mainFamilyCode}
              />
            );
          })}
      </>
    );
  } else if (subsubrows?.level4) {
    return (
      <>
        {subsubrows?.level4.map((subSubSubItem: any, subSubIndex: number) => {
          let periodItemItemItem: any = "";

          if (perioditemitem.level4) {
            perioditemitem?.level4.map(
              (periodItemItemItemSend, periodItemItemItemIndex) => {
                if (
                  periodItemItemItemSend.child_family_code ===
                  subSubSubItem.child_family_code
                ) {
                  periodItemItemItem = periodItemItemItemSend;
                }
              }
            );
          }

          return (
            <SubSubSubRow
              key={subSubIndex}
              subsubsubrows={subSubSubItem}
              perioditemitemitem={periodItemItemItem}
              count={count}
              setCount={setCount}
              expandall={expandall}
              setExpandAll={setExpandAll}
              setlevelCheck={setlevelCheck}
              levelCheck={levelCheck}
              setMainFamilyCode={setMainFamilyCode}
              mainFamilyCode={mainFamilyCode}
            />
          );
        })}
      </>
    );
  } else return null;
}

function SubSubSubRow(props: {
  subsubsubrows: any;
  perioditemitemitem: any;
  count: number;
  setCount: any;
  expandall: boolean;
  setExpandAll: any;
  setlevelCheck: any;
  levelCheck: any;
  setMainFamilyCode: any;
  mainFamilyCode: any;
}) {
  const [simpleOpen, setSimpleOpen] = useState(false);

  const {
    subsubsubrows,
    perioditemitemitem,
    count,
    setCount,
    expandall,
    setExpandAll,
    setlevelCheck,
    levelCheck,
    setMainFamilyCode,
    mainFamilyCode,
  } = props;

  let calculation: any = "";
  if (perioditemitemitem) {
    calculation =
      parseFloat(subsubsubrows.total_debit) +
      parseFloat(perioditemitemitem.total_debit) -
      (parseFloat(subsubsubrows.total_credit) +
        parseFloat(perioditemitemitem.total_credit));
  } else {
    calculation =
      parseFloat(subsubsubrows.total_debit) +
      0 -
      (parseFloat(subsubsubrows.total_credit) + 0);
  }

  let reportDebit: any = "";
  let reportCredit: any = "";

  if (calculation > 0) {
    reportDebit = calculation.toFixed(2);
    reportCredit = 0;
  } else if (calculation < 0) {
    reportDebit = 0;
    reportCredit = (calculation * -1).toFixed(2);
  }

  // let reportDebit =
  //   parseFloat(subsubsubrows?.total_debit) +
  //   parseFloat(perioditemitemitem?.total_debit);
  // let reportCredit =
  //   parseFloat(subsubsubrows?.total_credit) +
  //   parseFloat(perioditemitemitem?.total_credit);

  useEffect(() => {
    if (count > 0) {
      if (expandall === true) {
        setSimpleOpen(true);
      } else if (expandall === false) {
        setSimpleOpen(false);
      }
    }
  }, [expandall, levelCheck]);

  if (subsubsubrows.child_family_code !== "0") {
    return (
      <>
        <tr
          className="border-bottom"
          style={{ backgroundColor: "rgba(96, 130, 182, 0.4)" }}
        >
          <td style={{ width: "5%" }}>
            <small>{subsubsubrows.child_family_code}</small>
          </td>
          <td style={{ paddingLeft: "2rem", width: "30%" }}>
            {subsubsubrows.level5.length > 0 ? (
              simpleOpen ? (
                <IoIosArrowDown
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_sub_sub_rows: false,
                      };
                    });
                  }}
                />
              ) : (
                <IoIosArrowForward
                  onClick={() => {
                    setSimpleOpen(!simpleOpen);
                    setCount(0);
                    setExpandAll(false);
                    setlevelCheck((prev) => {
                      return {
                        ...prev,
                        sub_sub_sub_rows: true,
                      };
                    });
                  }}
                />
              )
            ) : null}
            &nbsp;
            {subsubsubrows.child_family_name}
          </td>

          <td align="right">
            {perioditemitemitem?.total_debit
              ? numberWithCommas(
                  parseFloat(perioditemitemitem.total_debit).toFixed(2)
                )
              : 0}
          </td>
          <td align="right">
            {perioditemitemitem?.total_credit
              ? numberWithCommas(
                  parseFloat(perioditemitemitem.total_credit).toFixed(2)
                )
              : 0}
          </td>

          <td align="right">
            {numberWithCommas(subsubsubrows.total_debit.toFixed(2))}
          </td>
          <td align="right">
            {numberWithCommas(subsubsubrows.total_credit.toFixed(2))}
          </td>
          <td align="right">{numberWithCommas(reportDebit)}</td>
          <td align="right">{numberWithCommas(reportCredit)}</td>
        </tr>
        {simpleOpen &&
          subsubsubrows?.level5.map(
            (subSubSubSubItem: any, subSubIndex: number) => {
              let periodItemItemItemItem: any = "";
              if (perioditemitemitem.level5) {
                perioditemitemitem?.level5.map(
                  (periodItemItemItemItemSend, periodItemItemItemItemIndex) => {
                    if (
                      periodItemItemItemItemSend.gl_code ===
                      subSubSubSubItem.gl_code
                    ) {
                      periodItemItemItemItem = periodItemItemItemItemSend;
                    }
                  }
                );
              }

              return (
                <SubSubSubSubRow
                  key={subSubIndex}
                  subsubsubsubrows={subSubSubSubItem}
                  perioditemitemitemitem={periodItemItemItemItem}
                />
              );
            }
          )}
      </>
    );
  } else if (subsubsubrows?.level5) {
    return (
      <>
        {subsubsubrows?.level5.map(
          (subSubSubSubItem: any, subSubIndex: number) => {
            let periodItemItemItemItem: any = "";
            if (perioditemitemitem.level5) {
              perioditemitemitem?.level5.map(
                (periodItemItemItemItemSend, periodItemItemItemItemIndex) => {
                  if (
                    periodItemItemItemItemSend.gl_code ===
                    subSubSubSubItem.gl_code
                  ) {
                    periodItemItemItemItem = periodItemItemItemItemSend;
                  }
                }
              );
            }

            return (
              <SubSubSubSubRow
                key={subSubIndex}
                subsubsubsubrows={subSubSubSubItem}
                perioditemitemitemitem={periodItemItemItemItem}
              />
            );
          }
        )}
      </>
    );
  } else {
    return null;
  }
}

function SubSubSubSubRow(props: {
  subsubsubsubrows: any;
  perioditemitemitemitem: any;
}) {
  const { subsubsubsubrows, perioditemitemitemitem } = props;
  let calculation: any = "";

  if (perioditemitemitemitem) {
    calculation =
      parseFloat(subsubsubsubrows.total_debit) +
      parseFloat(perioditemitemitemitem.total_debit) -
      (parseFloat(subsubsubsubrows.total_credit) +
        parseFloat(perioditemitemitemitem.total_credit));
  } else {
    calculation =
      parseFloat(subsubsubsubrows.total_debit) +
      0 -
      (parseFloat(subsubsubsubrows.total_credit) + 0);
  }

  let reportDebit: any = "";
  let reportCredit: any = "";

  if (calculation > 0) {
    reportDebit = calculation.toFixed(2);
    reportCredit = 0;
  } else if (calculation < 0) {
    reportDebit = 0;
    reportCredit = (calculation * -1).toFixed(2);
  }

  // let reportDebit =
  //   parseFloat(subsubsubsubrows?.total_debit) +
  //   parseFloat(perioditemitemitemitem?.total_debit);
  // let reportCredit =
  //   parseFloat(subsubsubsubrows?.total_credit) +
  //   parseFloat(perioditemitemitemitem?.total_credit);

  if (subsubsubsubrows.gl_code !== "0") {
    return (
      <>
        <tr className="border-bottom" style={{ backgroundColor: "white" }}>
          <td style={{ width: "5%" }}>
            <small>{subsubsubsubrows.gl_code}</small>
          </td>

          <td style={{ paddingLeft: "3rem", width: "30%" }}>
            {subsubsubsubrows.gl_name}
          </td>

          <td align="right">
            {perioditemitemitemitem?.total_debit
              ? numberWithCommas(
                  parseFloat(perioditemitemitemitem.total_debit).toFixed(2)
                )
              : 0}
          </td>
          <td align="right">
            {perioditemitemitemitem?.total_credit
              ? numberWithCommas(
                  parseFloat(perioditemitemitemitem.total_credit).toFixed(2)
                )
              : 0}
          </td>

          <td align="right">
            {numberWithCommas(subsubsubsubrows.total_debit.toFixed(2))}
          </td>
          <td align="right">
            {numberWithCommas(subsubsubsubrows.total_credit.toFixed(2))}
          </td>
          <td align="right">{numberWithCommas(reportDebit)}</td>
          <td align="right">{numberWithCommas(reportCredit)}</td>
        </tr>
      </>
    );
  } else return null;
}

const TrialBalanceStatement = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  let date = new Date();
  const [bankStatement, setBankStatement] = useState<any>([]);
  const [bankStatementDataCount, SetBankStatementDataCount] = useState(0);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [type, setType] = useState("");
  const [fundAccount, setFundAccount] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [openingBalance, setOpeningBalance] = useState("");
  const [closingBalance, setClosingBalance] = useState("");
  const [fromDate, setFromDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  const [resType, setResType] = useState("");
  const [resFundAccount, setResFundAccount] = useState("");
  const [resAccountTitle, setResAccountTitle] = useState("");
  const [resFromDate, setResFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resToDate, setResToDate] = useState(moment(date).format("YYYY-MM-DD"));

  const [legders, setLegders] = useState<any>([]);

  //ledger autocomplete
  const [valueSelectedLedger, setValueSelectedLedger] = React.useState<
    string | null
  >("");
  const [inputValueSelectedLedger, setInputValueSelectedLedger] =
    React.useState("");

  const [ledgerOption, setLedgerOption] = useState<any>([]);

  const [trialBalanceResponse, setTrialBalanceResponse] = useState<any>([]);
  const [periodResponse, setPeriodResponse] = useState<any>([]);
  const [expandAll, setExpandAll] = useState<any>(false);
  const [count, setCount] = useState<any>(0);
  const [levelCheck, setlevelCheck] = useState({
    simple_rows: false,
    sub_rows: false,
    sub_sub_rows: false,
    sub_sub_sub_rows: false,
  });
  const [mainFamilyCode, setMainFamilyCode] = useState<any>([]);

  const getAllAmcFunds = async () => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getAllAmcFunds();
  }, [fund_code]);

  // useEffect(() => {

  //   const getAllLegders = async () => {
  //     const response = await getChartOfAccounts(email);
  //     if (response.data.status == 200) {
  //       let ledgers = getAllLedgers(response.data.data.main_family);
  //       setLegders(ledgers);
  //     }
  //   };
  //   getAllAmcFunds();
  // }, []);

  // let txnTypes = JSON.parse(sessionStorage.getItem("txnType") || "");
  let txnTypes = useSelector((state: any) => state.txnTypes);

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  // const renderFundsDropDown = () => {
  //   return allFunds.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };
  const getFundAccounts = async (fund_code) => {
    try {
      allFundAccounts.length = 0;
      setAllFundsAccounts(allFundAccounts);
      const accResponse = await getAccountByFund(email, fund_code, amc_code);
      setAllFundsAccounts(accResponse.data.data);
    } catch (error) {}
  };

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return (
        <option key={index} value={item.account_no}>
          {item.bank_name} ({item.account_title})
        </option>
      );
    });
  };

  const renderLegdersDropDown = () => {
    return legders.map((item: any, index: number) => {
      return (
        <option key={index} value={item.code}>
          {item.code} - {item.name != undefined ? item.name : item.bank}
        </option>
      );
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    setLoading(true);

    try {
      const result = await getTrialBalanceReportData(fund, fromDate, toDate);

      if (result.data.status === 404) {
        setLoading(false);

        return toast.error("No Record Found");
        // } else if (result.data.period.status === 404) {
      } else if (result.data.status === 404) {
        setLoading(false);
        return toast.error("No Credit/Debit data found for this period");
      }

      // let myArray = Object.values(result.data);
      // setOpeningResponse(result.opening.data);
      // let periodUnsorted = result.data.period.data;
      let periodUnsorted = result.data.data;
      let periodSorted = periodUnsorted.sort((a, b) =>
        parseInt(a.main_family_code) > parseInt(b.main_family_code) ? 1 : -1
      );

      setPeriodResponse(periodSorted);

      let trialBalanceUnsorted = result.data.data;
      let trialBalanceSorted = trialBalanceUnsorted.sort((a, b) =>
        parseInt(a.main_family_code) > parseInt(b.main_family_code) ? 1 : -1
      );

      setTrialBalanceResponse(trialBalanceSorted);

      setLoading(false);
    } catch (error: any) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePrint = () => {
    setlevelCheck({
      simple_rows: true,
      sub_rows: true,
      sub_sub_rows: true,
      sub_sub_sub_rows: true,
    });
    setExpandAll(true);
    setCount(count + 1);
    setTimeout(() => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    }, 3000);
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund =
      allFunds && allFunds?.find((item) => item?.symbol_code === fund);
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} - ${selectedFund.symbol_code}`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Trial Balance</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="3">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <Select
                            value={selectedFundName}
                            label={"Select Fund"}
                            onChange={(e) => {
                              setLoading(true);
                              setTrialBalanceResponse([]);
                              setPeriodResponse([]);
                              setFund(e?.value);
                              getFundAccounts(e?.value);
                              setLoading(false);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            onChange={(e) => {
                              setLoading(true);
                              setTrialBalanceResponse([]);
                              setPeriodResponse([]);
                              setFund(e.target.value);
                              getFundAccounts(e.target.value);
                              setLoading(false);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={fromDate}
                            className="form-control py-1"
                            onChange={(e) => {
                              setLoading(true);
                              setTrialBalanceResponse([]);
                              setPeriodResponse([]);
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setFromDate(date);

                              setLoading(false);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={toDate}
                            className="form-control py-1"
                            onChange={(e) => {
                              setLoading(true);
                              setTrialBalanceResponse([]);
                              setPeriodResponse([]);
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setToDate(date);
                              setLoading(false);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading) || !fund}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-header">
                    <Row>
                      <Col md="6">
                        <Row>
                          {trialBalanceResponse.length > 0 &&
                          periodResponse.length > 0 ? (
                            <>
                              {(!expandAll ||
                                Object.keys(levelCheck).filter(
                                  (key) => levelCheck[key] === false
                                )?.length > 0) && (
                                <Col md="3" sm="12">
                                  <button
                                    className="btn btn-primary mt-4"
                                    onClick={() => {
                                      setExpandAll(true);
                                      setCount(count + 1);
                                      setlevelCheck({
                                        simple_rows: true,
                                        sub_rows: true,
                                        sub_sub_rows: true,
                                        sub_sub_sub_rows: true,
                                      });
                                      setMainFamilyCode(
                                        Array.from({
                                          length: trialBalanceResponse?.length,
                                        }).map((_, index) => index + 1)
                                      );
                                    }}
                                    disabled={expandAll}
                                  >
                                    <span>Expand All</span>
                                  </button>
                                </Col>
                              )}
                              {(expandAll ||
                                Object.keys(levelCheck).filter(
                                  (key) => levelCheck[key] === true
                                )?.length > 0) && (
                                <Col md="3" sm="12">
                                  <button
                                    className="btn btn-primary mt-4"
                                    onClick={() => {
                                      setExpandAll(false);
                                      setCount(count + 1);
                                      setlevelCheck({
                                        simple_rows: false,
                                        sub_rows: false,
                                        sub_sub_rows: false,
                                        sub_sub_sub_rows: false,
                                      });
                                      setMainFamilyCode([]);
                                    }}
                                    // disabled={!expandAll}
                                  >
                                    <span>Collapse All</span>
                                  </button>
                                </Col>
                              )}
                            </>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        landscape={true}
                        margin="1.0cm"
                        scale={0.5}
                        repeatHeaders={true}
                        fileName={"Bank-Statement-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {/* {bankStatementDataCount > 0 ? ( */}
                        {trialBalanceResponse.length > 0 &&
                        periodResponse.length > 0 ? (
                          <>
                            <ReportHeader title="Trial Balance" />

                            <Row>
                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(fromDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      To Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(toDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Accounts:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      <b>ALL</b>
                                    </span>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund Name:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">{fund}</span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              {/* <div className="float-right">
                              <b>Opening Balance</b> :{" "}
                              {numberWithCommas(openingBalance)}
                            </div> */}
                              <table
                                className="report"
                                style={{ color: "black" }}
                                id="table-borderless"
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th style={{ width: "5%" }}>Code</th>
                                    <th style={{ width: "30%" }}>
                                      Description
                                    </th>
                                    <th
                                      colSpan={2}
                                      id="center-align"
                                      style={{
                                        borderBottom: "0 !important",
                                        background: "#87ceeb",
                                      }}
                                    >
                                      Opening Balance
                                    </th>
                                    <th colSpan={2} id="center-align">
                                      For The Period Balance
                                    </th>
                                    <th
                                      style={{ background: "#87ceeb" }}
                                      colSpan={2}
                                      id="center-align"
                                    >
                                      Closing Balance
                                    </th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th
                                      style={{ background: "#87ceeb" }}
                                      id="right-align"
                                    >
                                      Debit
                                    </th>
                                    <th
                                      style={{ background: "#87ceeb" }}
                                      id="right-align"
                                    >
                                      Credit
                                    </th>
                                    <th id="right-align">Debit</th>
                                    <th id="right-align">Credit</th>
                                    <th
                                      style={{ background: "#87ceeb" }}
                                      id="right-align"
                                    >
                                      Debit
                                    </th>
                                    <th
                                      style={{ background: "#87ceeb" }}
                                      id="right-align"
                                    >
                                      Credit
                                    </th>
                                  </tr>
                                </thead>
                                {console.log(
                                  "trialBalanceResponse",
                                  trialBalanceResponse
                                )}
                                <tbody className="report/-table-body">
                                  {trialBalanceResponse
                                    ? trialBalanceResponse.map(
                                        (item: any, index: number) => {
                                          console.log("running", item);
                                          let period: any = "";
                                          periodResponse.map(
                                            (periodItem, periodIndex) => {
                                              if (
                                                periodItem.main_family_code ===
                                                item.main_family_code
                                              ) {
                                                period = periodItem;
                                              }
                                            }
                                          );

                                          return (
                                            <SimpleRows
                                              key={index}
                                              simplerows={item}
                                              period={period}
                                              count={count}
                                              setCount={setCount}
                                              expandall={expandAll}
                                              setExpandAll={setExpandAll}
                                              setlevelCheck={setlevelCheck}
                                              levelCheck={levelCheck}
                                              setMainFamilyCode={
                                                setMainFamilyCode
                                              }
                                              mainFamilyCode={mainFamilyCode}
                                            />
                                          );
                                        }
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>

                            {/* <div className="float-right">
                            <b>Closing Balance</b> :{" "}
                            {numberWithCommas(closingBalance)}
                          </div> */}
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center text-primary empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                        {/* ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text">
                              No record found
                            </p>
                          </div>
                        )} */}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        // setExpandAll(true);
                        handlePrint();
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TrialBalanceStatement;

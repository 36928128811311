import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { BsTable } from "react-icons/bs";
import {
  getDailyNavByFromDateToDate,
  getFundByAmc,
} from "../../stores/services/funds.service";
// import { getBankStatement,getBankStatementOpeningBalance } from '../../stores/services/report.service';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { numberWithCommas } from "../../utils/customFunction";
import { getPsxIndicesByFromToDate } from "../../stores/services/security.service";
import { useSelector } from "react-redux";
import Select from "react-select";

const AnalysisNavReport = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  let date = new Date();
  const [analysisNavData, setAnalysisNavData] = useState<any>([]);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [fundCode, setFundCode] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [navFromDate, setNavFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [navToDate, setNavToDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  const [resNavFromDate, setResNavFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resNavToDate, setResNavToDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );

  const [repotNavData, setReportNavData] = useState([]);
  const [reportIndiceData, setReportIndiceData] = useState<any>([]);
  const [kse100Data, setKse100Data] = useState<any>([]);
  const [kse30Data, setKse30Data] = useState<any>([]);
  const [kmi30Data, setKmi30Data] = useState<any>([]);

  const getAllAmcFunds = async () => {
    try {
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getAllAmcFunds();
  }, [fund_code]);

  // useEffect(() => {

  //   getAllAmcFunds();
  // }, []);

  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  // const CapTxnType = (type: any) => {
  //     let i, txType;
  //     for (i = 0; i < txnTypes.length; i++) {
  //     if (txnTypes[i].api_name === type) {
  //         txType = txnTypes[i].dis_name;
  //         break;
  //     }
  //     }
  //     return txType;
  // };

  // const renderFundsDropDown = () => {
  //   return allFunds.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      if (navFromDate === navToDate) {
        toast.error("Dates can't be same!");
        return;
      }
      setLoading(true);

      let kse100: any;
      let kse30: any;
      let kmi30: any;

      setResAmc(amc_name);
      setResFund(fund);
      setResNavFromDate(navFromDate);
      setResNavToDate(navToDate);

      try {
        const response = await getDailyNavByFromDateToDate(
          email,
          amc_code,
          fundCode,
          navFromDate,
          navToDate
        );
        if (
          response &&
          response.status === 200 &&
          response.data.status === 200
        ) {
          setReportNavData(response.data.data);
        } else {
          toast.error("No data found!");
          setLoading(false);
        }

        const response2 = await getPsxIndicesByFromToDate(
          email,
          amc_code,
          navFromDate,
          navToDate
        );
        if (
          response2 &&
          response2.status === 200 &&
          response2.data.status === 200
        ) {
          kse100 = response2.data.data
            .filter((item) => item.index_symbol.toLowerCase() === "kse100")
            .sort((a, b) => a.time - b.time);

          kse30 = response2.data.data
            .filter((item) => item.index_symbol.toLowerCase() === "kse30")
            .sort((a, b) => a.time - b.time);

          kmi30 = response2.data.data
            .filter((item) => item.index_symbol.toLowerCase() === "kmi30")
            .sort((a, b) => a.time - b.time);

          setKse100Data(kse100 || []);
          setKse30Data(kse30 || []);
          setKmi30Data(kmi30 || []);
          setReportIndiceData(response2.data.data);
        } else {
          toast.error("Error fetching index data!");
        }

        if (response && response2) {
          let allDatesWithinRange: any = [];
          let startDate: any = navFromDate;

          while (startDate !== navToDate) {
            let temp = moment(startDate).add(1, "day").format("YYYY-MM-DD");
            allDatesWithinRange.push({
              from: startDate,
              to: temp,
              // fromNAV: response?.data?.data?.find(
              //   (item) =>
              //     item.created_at_time >
              //       new Date(
              //         new Date(startDate).setHours(0, 0, 0, 0)
              //       ).getTime() &&
              //     item.created_at_time <
              //       new Date(new Date(startDate).setHours(23, 59, 59)).getTime()
              // ),
              // toNAV: response?.data?.data?.find(
              //   (item) =>
              //     item.created_at_time >
              //       new Date(new Date(temp).setHours(0, 0, 0, 0)).getTime() &&
              //     item.created_at_time <
              //       new Date(new Date(temp).setHours(23, 59, 59)).getTime()
              // ),
              fromNAV: response?.data?.data?.find(
                (item) =>
                  item.nav_time >
                    new Date(
                      new Date(startDate).setHours(0, 0, 0, 0)
                    ).getTime() &&
                  item.nav_time <
                    new Date(new Date(startDate).setHours(23, 59, 59)).getTime()
              ),
              toNAV: response?.data?.data?.find(
                (item) =>
                  item.nav_time >
                    new Date(new Date(temp).setHours(0, 0, 0, 0)).getTime() &&
                  item.nav_time <
                    new Date(new Date(temp).setHours(23, 59, 59)).getTime()
              ),
              fromKSE100: kse100?.find((item) => item.date === startDate),
              toKSE100: kse100?.find((item) => item.date === temp),
              fromKSE30: kse30?.find((item) => item.date === startDate),
              toKSE30: kse30?.find((item) => item.date === temp),
            });
            startDate = temp;
          }

          setAnalysisNavData(allDatesWithinRange);
        }

        setLoading(false);
      } catch (error) {
        console.log("Error while fetching nav data!");
        console.log(error);
        toast.error("Error fetching data!");
        setLoading(false);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response != undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  const calculatePercentage = (a, b) => {
    let percent;
    if (b !== 0) {
      if (a !== 0) {
        percent = ((b - a) / a) * 100;
      } else {
        percent = b * 100;
      }
    } else {
      percent = -a * 100;
    }
    // return Math.floor(percent);

    return percent;
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find(
      (item) => item?.symbol_code === fundCode
    );
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fundCode]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    {/* <h4 className="card-title">Voucher Report</h4> */}
                    <h4 className="card-title">NAV Analysis Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <Select
                            value={selectedFundName}
                            label={"Select Fund"}
                            onChange={(e) => {
                              setReportNavData([]);
                              setFund(e?.label);
                              setFundCode(e?.value);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            onChange={(e) => {
                              setReportNavData([]);
                              setFund(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text
                              );
                              setFundCode(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          {/* <label>Select from Date</label> */}
                          <label>From Date</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={navFromDate}
                            className="form-control"
                            onChange={(e) => {
                              setReportNavData([]);

                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setNavFromDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group  ">
                          {/* <label>Select to Date</label> */}
                          <label>To Date</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={navToDate}
                            className="form-control"
                            onChange={(e) => {
                              setReportNavData([]);
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setNavToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            disabled={
                              !fundCode ||
                              fundCode === "" ||
                              !navFromDate ||
                              !navToDate ||
                              loading
                            }
                            onClick={() => {
                              searchData();
                            }}
                            // disabled={Boolean(loading)}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={"Analysic-Nav-Report-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {!loading &&
                        fundCode &&
                        navFromDate &&
                        navToDate &&
                        analysisNavData.length > 0 ? (
                          <>
                            <ReportHeader
                              // title={`Analysis NAV Report As of ${moment(
                              title={`NAV Analysis Report`}
                            />

                            <Row>
                              {/* <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resAmc}
                                    </span>
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFund}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      NAV From:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resNavFromDate).format(
                                        "DD-MMM-YY"
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Report Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {/* {moment(date).format("DD-MM-YYYY")} */}
                                      {moment().format("DD-MMM-YY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      NAV To:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resNavToDate).format("DD-MMM-YY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th>Index</th>

                                    <th className="text-right">
                                      As on{" "}
                                      {moment(navToDate).format("DD-MMM-YYYY")}
                                    </th>
                                    <th className="text-right">
                                      As on{" "}
                                      {moment(navFromDate).format(
                                        "DD-MMM-YYYY"
                                      )}
                                    </th>
                                    <th className="text-right">Pts. Change</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {kse100Data && kse100Data.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>KSE-100 Index</td>

                                        <td className="text-right">
                                          {numberWithCommas(
                                            kse100Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(
                                            kse100Data?.find(
                                              (item) =>
                                                item.date === navFromDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        {(kse100Data?.find(
                                          (item) => item.date === navFromDate
                                        )?.price || 0) -
                                          (kse100Data?.find(
                                            (item) => item.date === navToDate
                                          )?.price || 0) >
                                        0 ? (
                                          <td className="text-right">
                                            <span className="text-success">
                                              {numberWithCommas(
                                                (kse100Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kse100Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (kse100Data?.find(
                                            (item) => item.date === navFromDate
                                          )?.price || 0) -
                                            (kse100Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0) <
                                          0 ? (
                                          <td className="text-right text-danger">
                                            <span className="text-danger">
                                              {numberWithCommas(
                                                (kse100Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kse100Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (kse100Data?.find(
                                                (item) =>
                                                  item.date === navFromDate
                                              )?.price || 0) -
                                                (kse100Data?.find(
                                                  (item) =>
                                                    item.date === navToDate
                                                )?.price || 0)
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ) : null}

                                  {kse30Data && kse30Data.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>KSE-30 Index</td>

                                        <td className="text-right">
                                          {numberWithCommas(
                                            kse30Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(
                                            kse30Data?.find(
                                              (item) =>
                                                item.date === navFromDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        {(kse30Data?.find(
                                          (item) => item.date === navFromDate
                                        )?.price || 0) -
                                          (kse30Data?.find(
                                            (item) => item.date === navToDate
                                          )?.price || 0) >
                                        0 ? (
                                          <td className="text-right">
                                            <span className="text-success">
                                              {numberWithCommas(
                                                (kse30Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kse30Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (kse30Data?.find(
                                            (item) => item.date === navFromDate
                                          )?.price || 0) -
                                            (kse30Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0) <
                                          0 ? (
                                          <td className="text-right text-danger">
                                            <span className="text-danger">
                                              {numberWithCommas(
                                                (kse30Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kse30Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (kse30Data?.find(
                                                (item) =>
                                                  item.date === navFromDate
                                              )?.price || 0) -
                                                (kse30Data?.find(
                                                  (item) =>
                                                    item.date === navToDate
                                                )?.price || 0)
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ) : null}

                                  {kmi30Data && kmi30Data.length > 0 ? (
                                    <>
                                      <tr>
                                        <td>KMI-30 Index</td>

                                        <td className="text-right">
                                          {numberWithCommas(
                                            kmi30Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {numberWithCommas(
                                            kmi30Data?.find(
                                              (item) =>
                                                item.date === navFromDate
                                            )?.price || 0
                                          )}
                                        </td>
                                        {(kmi30Data?.find(
                                          (item) => item.date === navFromDate
                                        )?.price || 0) -
                                          (kmi30Data?.find(
                                            (item) => item.date === navToDate
                                          )?.price || 0) >
                                        0 ? (
                                          <td className="text-right">
                                            <span className="text-success">
                                              {numberWithCommas(
                                                (kmi30Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kmi30Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (kmi30Data?.find(
                                            (item) => item.date === navFromDate
                                          )?.price || 0) -
                                            (kmi30Data?.find(
                                              (item) => item.date === navToDate
                                            )?.price || 0) <
                                          0 ? (
                                          <td className="text-right text-danger">
                                            <span className="text-danger">
                                              {numberWithCommas(
                                                (kmi30Data?.find(
                                                  (item) =>
                                                    item.date === navFromDate
                                                )?.price || 0) -
                                                  (kmi30Data?.find(
                                                    (item) =>
                                                      item.date === navToDate
                                                  )?.price || 0)
                                              )}
                                            </span>
                                          </td>
                                        ) : (
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (kmi30Data?.find(
                                                (item) =>
                                                  item.date === navFromDate
                                              )?.price || 0) -
                                                (kmi30Data?.find(
                                                  (item) =>
                                                    item.date === navToDate
                                                )?.price || 0)
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    </>
                                  ) : null}
                                </tbody>
                              </table>

                              <hr />

                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    {/* <th className="text-right">{`NAV As of ${moment( */}
                                    <th className="text-right">Date</th>
                                    <th className="text-right">NAV Current</th>
                                    <th className="text-right">NAV Previous</th>
                                    <th className="text-right">
                                      Change in NAV(Rs)
                                    </th>
                                    <th className="text-right">
                                      % Change in NAV
                                    </th>
                                    <th className="text-right">
                                      % Change in KSE-100
                                    </th>
                                    <th className="text-right">
                                      % Change in KSE-30
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {analysisNavData.map(
                                    (item: any, index: number) => {
                                      if (
                                        (!item?.toNAV ||
                                          item?.toNAV?.nav === 0) &&
                                        (!item?.fromNAV ||
                                          item?.fromNAV?.nav === 0)
                                      ) {
                                        return null;
                                      }

                                      return (
                                        <tr key={index}>
                                          <td>
                                            {
                                              moment(item.to).format(
                                                "DD-MM-YYYY"
                                              )
                                              // +
                                              //   " TO " +
                                              //   moment(item.from).format(
                                              //     "DD-MMM-YYYY"
                                              //   )
                                            }
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (item?.toNAV?.nav || 0).toFixed(4)
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (item?.fromNAV?.nav || 0).toFixed(
                                                4
                                              )
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              (
                                                (item?.toNAV?.nav || 0) -
                                                (item?.fromNAV?.nav || 0)
                                              ).toFixed(4)
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {numberWithCommas(
                                              calculatePercentage(
                                                item?.fromNAV?.nav || 0,
                                                item?.toNAV?.nav || 0
                                              ).toFixed(4)
                                            )}
                                          </td>
                                          <td className="text-right">
                                            {item?.toKSE100
                                              ? item?.toKSE100
                                                  .change_rate_yesterday
                                              : "-"}
                                          </td>
                                          <td className="text-right">
                                            {item?.toKSE30
                                              ? item?.toKSE30
                                                  .change_rate_yesterday
                                              : "-"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                  {/* {
                                  analysisNavData.map(
                                    (item: any, index: number) => {
                                      return item?.toNAV?.nav === 0 &&
                                        item?.fromNAV?.nav === 0 ? null : (
                                        <>
                                          <tr key={index}>
                                            <td>
                                              {moment(item.to).format(
                                                "DD-MM-YYYY"
                                              ) +
                                                " TO " +
                                                moment(item.from).format(
                                                  "DD-MMM-YYYY"
                                                )}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(
                                                item?.toNAV?.nav
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(
                                                item?.fromNAV?.nav
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(
                                                (
                                                  (item?.toNAV?.nav || 0) -
                                                  (item?.fromNAV?.nav || 0)
                                                ).toFixed(4)
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {numberWithCommas(
                                                calculatePercentage(
                                                  item?.fromNAV?.nav || 0,
                                                  item?.toNAV?.nav || 0
                                                ).toFixed(4)
                                              )}
                                            </td>
                                            <td className="text-right">
                                              {item?.toKSE100
                                                ? item?.toKSE100
                                                    ?.change_rate_yesterday
                                                : "-"}
                                            </td>
                                            <td className="text-right">
                                              {item?.toKSE30
                                                ? item?.toKSE30
                                                    ?.change_rate_yesterday
                                                : "-"}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )} */}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center empty-text text-primary">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AnalysisNavReport;

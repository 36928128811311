import { ActionTypes } from "../contants/action-types";

export const setStringValue = (value) => {
    return {
        type: ActionTypes.SET_STRING_VALUE,
        payload: value
    };
};
export const setThemeMode = (value) => {
    return {
        type: ActionTypes.SET_THEME_MODE,
        payload: value
    };
};

export const GetChartOfAccount = (chartOfAccounts)=>{
    return {
        type: ActionTypes.GET_CHART_OF_ACCOUNTS,
        payload: chartOfAccounts
    };
}

export const GetFunds = (funds)=>{
    return {
        type: ActionTypes.GET_FUNDS,
        payload: funds
    };
}

export const GetTxnTypeCategories = (txnCategories)=>{
    return {
        type: ActionTypes.GET_TXN_TYPE_CATEGORY,
        payload: txnCategories
    };
}

export const GetTxnTypes = (txnTypes)=>{
    return {
        type: ActionTypes.GET_TXN_TYPES,
        payload: txnTypes
    };
}

import React from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { isNumber, numberWithCommas } from "../../../utils/customFunction";
import { useHistory } from "react-router-dom";
import { set } from "mobx";

const ViewFund = () => {
  const history = useHistory();
  //hooks for getting all inputs from user
  const [fundName, setFundName] = useState("");
  const [code, setCode] = useState("");
  const [amcName, setAmcName] = useState("");
  const [fundType, setFundType] = useState("");
  const [fundDividendPeriod, setFundDividendPeriod] = useState("");
  const [incorporationDate, setIncorporationDate] = useState("");
  const [revocationDate, setRevocationDate] = useState("");
  const [psxListing, setPsxListing] = useState("");
  const [moneyMarket, setMoneyMarket] = useState<any>([]);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [nav, setNav] = useState("");
  const [faceValue, setFaceValue] = useState("");
  const [cdc_participant_id, setCDCParticipantID] = useState("");
  const [cdc_account_number, setCDCAccountNumber] = useState("");

  const [offerPrice, setOfferPrice] = useState("");
  const [redemptionPrice, setRedemptionPrice] = useState("");
  const [outstandingUnits, setOutstandingUnits] = useState("");
  const [managementFee, setManagementFee] = useState("");
  const [sstmanagementfee, setSstManagementFee] = useState("");
  const [amcReimbursementExp, setReinbursementExp] = useState("");
  const [sellingMarketingExp, setSellingMarketingExp] = useState("");
  const [secpFee, setSECPFee] = useState("");
  const [secpSupervisoryFee, setSECPSupervisoryFee] = useState("");
  const [sstTrusteeFee, setSSTTrusteeFee] = useState("");
  const [saleLoad, setSaleLoad] = useState("");
  const [backendLoad, setBackendLoad] = useState("");
  const [contingentLoad, setContingentLoad] = useState("");
  const [sstSales, setSSTSales] = useState("");
  const [accountOperation, setAccountOperation] = useState("");

  let [slabData, setSlabData] = useState<any>([]);

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem("fundObj") || "");
    setFundName(obj.fund_name);
    setCode(obj.symbol_code.replace("FUND_", ""));
    setNav(obj.nav);
    setFaceValue(obj.face_value);
    setAmcName(obj.amc_code);
    setFundType(obj.nature);
    setFundDividendPeriod(obj.dividend_period);
    setIncorporationDate(
      obj.date_of_incorporation?.toString()?.split("T")[0] || ""
    );
    setRevocationDate(obj.date_of_revocation?.toString()?.split("T")[0] || "");
    setSSTTrusteeFee(obj.sst_trustee_percentage || 0);
    setSlabData(JSON.parse(obj.fee_slab || "[]"));
    setPsxListing(obj.psx_listing);
    if (obj.money_market !== "" && obj.money_market !== "[]") {
      setMoneyMarket(JSON.parse(obj.money_market));
    }
    if (obj.stock_market !== "" && obj.stock_market !== "[]") {
      setStockMarket(JSON.parse(obj.stock_market));
    }
    if (obj.term_deposit !== "" && obj.term_deposit !== "[]") {
      setTermDeposit(JSON.parse(obj.term_deposit));
    }

    setCDCParticipantID(obj.cdc_participant_id);
    setCDCAccountNumber(obj.cdc_account_number);

    setOfferPrice(obj.offer_price);
    setRedemptionPrice(obj.redemption_price);
    setOutstandingUnits(obj.outstanding_units);
    setManagementFee(obj.management_fee_percentage);
    setSstManagementFee(obj.sst_on_management_fee_percentage);
    setReinbursementExp(obj.amc_reimbursement_exp_percentage);
    setSellingMarketingExp(obj.selling_and_marketing_exp_percentage);
    setSECPFee(obj.secp_fee_percentage);
    setSECPSupervisoryFee(obj.secp_supervisory_fee_percentage);
    setSaleLoad(obj.sale_load_percentage || 0);
    setBackendLoad(obj.backend_load_percentage || 0);
    setContingentLoad(obj.contingent_load_percentage || 0);
    setSSTSales(obj.sst_sale_backend_contingent_load_percentage || 0);
    setAccountOperation(obj.accounts_operations_percentage || 0);
  }, []);
  const [stockMarket, setStockMarket] = useState<any>([]);
  const renderFundDataInTable = () => {
    return moneyMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.security_type}</td>
          <td>{items.name}</td>
          <td>{items.issue_date}</td>
          <td>{items.maturity_date}</td>
          <td>{numberWithCommas(items.coupon_rate || "")}</td>
          <td>{numberWithCommas(items.yield || "")}</td>
          <td>{numberWithCommas(items.coupon_payment || "")}</td>
          <td>{numberWithCommas(items.coupon_days || "")}</td>
          <td>{numberWithCommas(items.mmface_value || "")}</td>
          <td>{numberWithCommas(items.total_face_value || "")}</td>
          {/* <td>{numberWithCommas(items.price || '')}</td> */}
          <td>{numberWithCommas(items.units || "")}</td>
          <td>{numberWithCommas(items.average_cost_per_unit || "")}</td>
          <td>
            {" "}
            {numberWithCommas(items.total_amount || "") &&
              numberWithCommas(parseFloat(items.total_amount).toFixed(2))}{" "}
          </td>
          <td> {numberWithCommas(items.market_rate_per_unit || "")} </td>
          <td> {numberWithCommas(items.market_value || "")} </td>
          <td> {numberWithCommas(items.unrealized_gain_loss || "")} </td>
          {/* <td> {numberWithCommas(items.unrealized_gain_loss || '')} </td> */}
          <td>
            {" "}
            {numberWithCommas(
              items.investment_percentage_market_value || ""
            )}{" "}
          </td>
          <td> {numberWithCommas(items.days_of_maturity || "")} </td>
          <td> {numberWithCommas(items.unredeem_value || "")} </td>
          <td> {numberWithCommas(items.principal_amount || "")} </td>
        </tr>
      );
    });
  };

  const renderEquityInTable = () => {
    return stockMarket.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.market}</td>
          {/* <td>{numberWithCommas(items.close_rate || '') && numberWithCommas(parseFloat(items.close_rate).toFixed(2))}</td> */}
          <td>
            {numberWithCommas(items.total_units || "") &&
              numberWithCommas(items.total_units || "")}
          </td>
          <td>
            {numberWithCommas(items.unit || "") &&
              numberWithCommas(items.unit || "")}
          </td>
          <td>{numberWithCommas(items.average_cost_per_unit)}</td>
          <td>
            {numberWithCommas(items.amount || "") &&
              numberWithCommas(parseFloat(items.amount).toFixed(2))}
          </td>
          <td>{numberWithCommas(items.market_rate_per_unit)}</td>
          <td>{numberWithCommas(items.market_value)}</td>
          <td>{numberWithCommas(items.unrealized_gain_loss)}</td>
          <td>{numberWithCommas(items.investment_percentage_market_value)}</td>
        </tr>
      );
    });
  };

  const renderTermDepositInTable = () => {
    return termDeposit.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.code}</td>
          <td>{items.name}</td>
          <td>{numberWithCommas(items.principal_amount || "")}</td>
          <td>{numberWithCommas(items.interest_rate || "")}</td>
          <td>{moment(items.from_date).format("DD-MM-YYYY")}</td>
          <td>{moment(items.to_date).format("DD-MM-YYYY")}</td>
          <td>{items.bank}</td>
          <td>{items.branch}</td>
        </tr>
      );
    });
  };

  const renderFeeDataInTable = () => {
    return slabData.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{numberWithCommas(isNumber(items.amountFrom))}</td>
          <td>{numberWithCommas(isNumber(items.amountTo))}</td>
          <td className="text-right">
            {parseFloat(isNumber(items.rate)).toFixed(4)}
          </td>
          <td className="text-right">
            {numberWithCommas(isNumber(items.fixedAmmount))}
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-1 pointer"
                    onClick={() => {
                      history.replace("/admin/funds");
                    }}
                  ></i>
                  View - Funds
                </h4>
                <Link
                  to="/admin/funds"
                  className="t-3 ml-auto btn btn-primary btn-sm"
                  replace
                >
                  <i className="fa fa-eye mr-2"></i> View All
                </Link>
              </div>

              <Row>
                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Fund Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Fund Code</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={code}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Fund Name</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={fundName}
                          />
                        </div>
                      </div>
                      {/* 
              <div className="form-group  ">
                <label>AMC Name</label>
                <div className="">
                  <select className="form-control" value={amcName}>
                    <option value={amcName} defaultChecked hidden>
                      {amcName}
                    </option>
                  </select>
                </div>
              </div> */}

                      <div className="form-group  ">
                        <label>Category / Type of Fund</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={fundType}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Dividend Period</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={fundDividendPeriod}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Incorporation</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group  ">
                        <label>Date of Incorporation</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={moment(incorporationDate).format(
                              "DD-MM-YYYY"
                            )}
                          />
                        </div>
                      </div>

                      <div className="form-group  ">
                        <label>Date of Revocation</label>
                        <div className="">
                          <input
                            type="text"
                            className="form-control"
                            value={moment(revocationDate).format("DD-MM-YYYY")}
                          />
                        </div>
                      </div>

                      <Row>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Opening NAV</label>
                            <div className="">
                              <input
                                type="text"
                                className="form-control"
                                value={nav}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group  ">
                            <label>Face Value</label>
                            <div className="">
                              <input
                                type="text"
                                className="form-control"
                                value={faceValue}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <div className="form-group">
                            <label>CDC Participant ID</label>
                            <div>
                              <input
                                type="text"
                                className={`form-control  w-100 `}
                                value={cdc_participant_id}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label htmlFor="">CDC Account Number </label>
                            <input
                              type="text"
                              className="form-control"
                              value={cdc_account_number}
                            />
                          </div>
                        </Col>
                      </Row>

                      <div className="">
                        <label>PSX Listing</label>
                        <div className=" ">
                          <div>
                            <FormGroup check className="form-check-radio">
                              <Label check>
                                <Input
                                  defaultChecked
                                  id="listed"
                                  name="listed"
                                  value="true"
                                  checked={true}
                                  type="radio"
                                />
                                <span className="form-check-sign" />
                                {psxListing === "true" ? "Listed" : "UnListed"}
                              </Label>
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col md>
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Detail</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <div className="form-group  ">
                            <label>Offer Price</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={offerPrice}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Redemption Price</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={redemptionPrice}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>OutStanding Units</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={outstandingUnits}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Management Fee%</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={managementFee}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group  ">
                            <label>SST on Management Fee %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={sstmanagementfee}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>AMC Reimbursement Exp % </label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={amcReimbursementExp}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Selling {"&"} Marketing Exp % </label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={sellingMarketingExp}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group  ">
                            <label>SECP Fee % </label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100`}
                                value={secpFee}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>SECP Supervisory Fee % </label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={secpSupervisoryFee}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group  ">
                            <label>SST on Trustee Fee %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={sstTrusteeFee}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Sales Load %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={saleLoad}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Backend Load %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={backendLoad}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Contingent Load %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={contingentLoad}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>
                              SST on Sales/Backend/Contingent Load %
                            </label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={sstSales}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="4">
                          <div className="form-group  ">
                            <label>Accounts & Operation Expense %</label>
                            <div>
                              <input
                                type="number"
                                className={`form-control  w-100 `}
                                value={accountOperation}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                {slabData.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Trustee Fee Slab</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="table-responsive">
                          {slabData.length > 0 && (
                            <div className="input-size-pos mb-3 mt-2 ">
                              <table className="table   ">
                                <thead>
                                  <tr>
                                    <th>Slab From</th>
                                    <th>Slab To</th>
                                    <th className="text-right">Rate %</th>
                                    <th className="text-right">Fix Amount</th>
                                  </tr>
                                </thead>
                                <tbody>{renderFeeDataInTable()}</tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {moneyMarket.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Money Market Portfolio</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="input-size-pos mb-3 ">
                          <h3></h3>
                          <div className="table-responsive ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Symbol</th>
                                  <th>Security Type</th>
                                  <th>Name</th>
                                  <th>Issue Date</th>
                                  <th>Maturity Date</th>
                                  <th>Coupon Rate %</th>
                                  <th>Yield %</th>
                                  <th>Coupon Payment/Year</th>
                                  <th>Coupon Days</th>
                                  <th>Money Market Face Value/Unit</th>
                                  <th>Total Face Value</th>
                                  <th>Units</th>
                                  <th>Avg Cost/Unit</th>
                                  <th>Total Cost / Amount</th>
                                  <th>Market Rate/Unit</th>
                                  <th>Market Value</th>
                                  <th>Unrealized Gain/(Loss)</th>
                                  <th>Investment as % of Market Value</th>
                                  <th>Maturity Days</th>
                                  <th>Unredeemed Value</th>
                                  <th>Principal Amount</th>
                                </tr>
                              </thead>
                              <tbody>{renderFundDataInTable()}</tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}

                {stockMarket.length > 0 && (
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Stock Market Portfolio</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="input-size-pos mb-3 ">
                          <h3></h3>
                          <div className="table-responsive ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Symbol</th>
                                  <th>Market</th>
                                  <th>Total Units</th>
                                  <th>Available Units</th>
                                  <th>Average Cost / Unit</th>
                                  <th>Total Cost / Amount</th>
                                  <th>Market Rate/Unit</th>
                                  <th>Market Value</th>
                                  <th>Unrealzed Gain/(Loss)</th>
                                  <th>Investment as % Market Value</th>
                                </tr>
                              </thead>
                              <tbody>{renderEquityInTable()}</tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>

              {termDeposit.length > 0 && (
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4">Term Deposit Portfolio</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="input-size-pos mb-3 ">
                          <h3></h3>
                          <div className="table-responsive ">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th>Code</th>
                                  <th>Name</th>
                                  <th>Principal Amount</th>
                                  <th>Interest Rate</th>
                                  <th>From Date</th>
                                  <th>To Date</th>
                                  <th>Bank</th>
                                  <th>Branch</th>
                                </tr>
                              </thead>
                              <tbody>{renderTermDepositInTable()}</tbody>
                            </table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewFund;

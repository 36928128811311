import {combineReducers} from "redux";
import { chartOfAccountsReducer } from "./chartOfAccountsReducers";
import { fundsReducer } from "./getFundsReducers";
import { txnTypeCategoryReducer } from "./getTxnCategoryReducers";
import { txnTypesReducer } from "./getTxnTypesReducers";
import { stringReducer } from "./getStringValueSidebar";
import { ThemeModeReducer } from "./ThemeMode";

const reducers = combineReducers({
    chartofAccounts: chartOfAccountsReducer,
    funds:fundsReducer,
    txnTypeCategories: txnTypeCategoryReducer,
    txnTypes: txnTypesReducer,
    stringReducer: stringReducer,
    ThemeModeReducer:ThemeModeReducer
})

export default reducers;
import React from "react";
import { useState } from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Container, Col, Row } from "reactstrap";
import {
  getAccounts,
  updateAccountStatus,
  searchBankAccAPI,
} from "../../../stores/services/account.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { FaCrown } from "react-icons/fa";
const AccountList = () => {
  const history = useHistory();
  const email = sessionStorage.getItem("email") || "";
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const amcCode = sessionStorage.getItem("amc_code") || "";
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isSearchActive, setSearchActive] = useState(false);
  let [activePage, setActivePage] = useState("");
  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");
  React.useEffect(() => {
    sessionStorage.removeItem("accountObj");
    activePage ? getAccountList("") : getAccountList("true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  React.useEffect(() => {
    getAccountList("true");
  }, [fund_code]);

  const getAccountList = async (resetData: string = "") => {
    setLoading(true);
    try {
      const response = await getAccounts(
        email,
        activePage,
        pageSize,
        fund_code?.length > 0 ? fund_code : "",
        amcCode
      );
      if (response.data.data.length > 0) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        // setData(response.data.data);
        setData(
          resetData == "true"
            ? [...response.data.data]
            : [...data, ...response.data.data]
        );
      } else {
        setData([]);

        toast.error(response.data.message);
      }
    } catch (err: any) {
      // toast.error(error.response.data.message[0]);
      if (err.response !== undefined) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Request Failed!");
      }
    }
    setLoading(false);
  };

  const getAccountListUpdated = async () => {
    setLoading(true);
    try {
      const response = await getAccounts(
        email,
        activePage,
        pageSize,
        fund_code?.length > 0 ? fund_code : ""
      );
      if (response.data.data.length > 0) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } else {
      }
    } catch (error: any) {
      toast.error(error.response.data.message[0]);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    if (data) {
      let i;
      res.length = 0;
      setRes(res);
      for (i = 0; i < data.length; i++) {
        res.push(data[i]);
        setRes(res);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const [res, setRes] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  function myFunction(e: any) {
    res.length = 0;
    setRes(res);
    var filter, td, i;
    filter = e.target.value;
    for (i = 0; i < data.length; i++) {
      const newText = data[i].account_no;
      td = newText.split(/\s/).join("");
      if (td) {
        if (td.toUpperCase().indexOf(filter.toUpperCase()) > -1) {
          res.push(data[i]);
          setRes(res);
        } else {
        }
      }
    }
  }
  const searchAccData = async () => {
    setLoading(true);
    try {
      const response = await searchBankAccAPI(
        email,
        searchValue,
        fund_code?.length > 0 ? fund_code : "",
        amcCode
      );
      // setData(response.data.data);
      // setSearchActive(true);
      // setPageSize('100');
      if (response.data.data.length > 0) {
        let pageArr = paginationArr;
        let existingPageIndex = paginationArr.findIndex(
          (x) => x === response.data.page_info.next_page_number
        );
        if (
          existingPageIndex === -1 &&
          response.data.data.length === 10
          // &&
          // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
        ) {
          pageArr.push(response.data.page_info.next_page_number);
        }
        setPaginationArr(pageArr);
        setData(response.data.data);
      } else {
        setData([]);
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      }
    }
    setLoading(false);
  };
  const handleSearchValue = (e: any) => {
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      getAccountListUpdated();
      setSearchActive(false);
      // setPageSize('10');
      setSearchValue(e.target.value);
    } else {
      setSearchValue(e.target.value);
    }
  };
  const [statusSetPopup, setStatusSetPopup] = useState(false);
  const [currentAccountStatus, setCurrentAccountStatus] = useState("");
  const [code, setCode] = useState("");
  const [accountLoading, setAccountLoading] = useState(false);
  const updateStatus = async () => {
    setAccountLoading(true);
    let status = "";
    if (currentAccountStatus === "active") {
      status = "deactive";
    } else {
      status = "active";
    }
    try {
      const response = await updateAccountStatus(email, code, status);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setStatusSetPopup(false);
        getAccountListUpdated();
      } else {
        toast.error(response.data.message);
      }
    } catch (error: any) {
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.response.data.message);
      }
    }
    setAccountLoading(false);
  };
  const renderUpdateAccountStatusPopup = () => {
    switch (statusSetPopup) {
      case true:
        return (
          <Modal className="" dialogClassName="modal60w" show={true}>
            <div className="modal-header justify-content-center">
              <button
                aria-hidden="true"
                className="close"
                onClick={() => {
                  setStatusSetPopup(false);
                }}
                data-dismiss="modal"
                type="button"
              >
                <i className="tim-icons icon-simple-remove"></i>
              </button>
              <h6 className="title title-up">Action</h6>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <Row>
                  <div className="mx-auto">
                    {currentAccountStatus === "active" ? (
                      <img src="assets/ban.svg" alt="" width="70" />
                    ) : (
                      <img src="assets/check.svg" alt="" width="70" />
                    )}
                    <h1 className="pb-3">Are You Sure?</h1>
                    Are you sure you want to{" "}
                    {currentAccountStatus === "active"
                      ? "Deactivate"
                      : "Activate"}{" "}
                    this Account?
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-primary"
                        disabled={Boolean(accountLoading)}
                        onClick={() => {
                          updateStatus();
                        }}
                      >
                        {accountLoading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          "Yes"
                        )}
                      </button>
                    </div>
                  </Col>
                  <Col>
                    <div className="">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          setStatusSetPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };
  const renderData = () => {
    return res.map((items: any, index: any) => {
      return (
        <tr key={index}>
          <td>
            {" "}
            {items.sma === "true" ? (
              <FaCrown data-tip="Special Account" className="crown-icon ml-1" />
            ) : (
              ""
            )}
            {items.account_code.replace("ACCOUNT_", "")}
          </td>
          {/* <td>
            <ReactTooltip
              textColor="white"
              backgroundColor="black"
              effect="float"
            />
            {items.sma === "true" ? (
              <FaCrown data-tip="Special Account" className="crown-icon ml-1" />
            ) : (
              ""
            )}
            <span className="ml-1">{items.amc_code} </span>
          </td> */}
          <td>{items.account_title}</td>
          <td>{items.fund_code}</td>
          <td>{items.bank_name}</td>
          <td>{items.nature_of_account}</td>
          <td>{items.account_no}</td>
          <td>
            {typeof items?.balance_amount === "number"
              ? numberWithCommas(
                  items?.balance_amount
                    ? items?.balance_amount?.toFixed(2)
                    : "0.00"
                )
              : numberWithCommas(
                  items?.balance_amount
                    ? parseFloat(items?.balance_amount || "0").toFixed(2)
                    : "0.00"
                )}
          </td>
          <td>{moment(items.created_at).format("DD-MM-YYYY")}</td>
          <td className="captilize">{items.status}</td>
          <td>
            <div
              className="mr-3 pointer"
              onClick={() => {
                setStatusSetPopup(true);
                setCurrentAccountStatus(items.status);
                setCode(items.account_code.replace("ACCOUNT_", ""));
              }}
            >
              {items.status !== "active" ? (
                <button
                  type="button"
                  className="btn-icon btn-link like btn btn-danger btn-sm"
                >
                  <i className="tim-icons icon-simple-remove"></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="btn-icon btn-link like btn btn-success btn-sm"
                >
                  <i className="fa fa-check"></i>
                </button>
              )}

              <button
                type="button"
                className="btn-icon btn-link like btn btn-primary btn-sm"
                onClick={() => {
                  sessionStorage.setItem("accountObj", JSON.stringify(items));
                  history.replace(`/admin/view-account`);
                }}
              >
                <i className="fa fa-eye"> </i>
              </button>

              <button
                type="button"
                className="btn-icon btn-link like btn btn-info btn-sm"
                onClick={() => {
                  sessionStorage.setItem("accountObj", JSON.stringify(items));
                  history.replace(`/admin/edit-account`);
                }}
              >
                <i className="tim-icons icon-pencil"> </i>
              </button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const numberWithCommas = (amount: any) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">
                    Bank Account Listing
                    <div
                      className="btn btn-primary btn-sm mt-a float-right"
                      onClick={() => {
                        history.replace("/admin/setup-accounts");
                      }}
                    >
                      <span>
                        <i className="fa fa-plus mr-2"></i>Add New
                      </span>
                    </div>
                  </h4>
                </div>
                {!Loading ? (
                  <div className="card-body">
                    <Row>
                      <Col md="4">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="form-group">
                          <input
                            type="search"
                            id="myInput"
                            placeholder="Enter Account No"
                            className="form-control"
                            data-tip="Account No"
                            value={searchValue}
                            onChange={(e) => {
                              // setSearchValue(e.target.value);
                              handleSearchValue(e);
                              // myFunction(e);
                            }}
                            onKeyPress={(e) => {
                              let key_board_keycode = e.which || e.keyCode;
                              if (key_board_keycode == 13) {
                                e.preventDefault();
                                searchAccData();
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="4">
                        <div
                          className="btn btn-primary mt-a"
                          onClick={searchAccData}
                        >
                          <i className="fa fa-search"></i> Search
                        </div>
                        <div
                          className="btn btn-default mt-a"
                          onClick={() => {
                            setSearchValue("");
                            getAccountList("true");
                          }}
                        >
                          <i className="fa fa-clear"></i> Clear
                        </div>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Account Code</th>
                            <th>Title</th>
                            <th>Fund Code</th>
                            <th>Bank Code</th>
                            <th>Nature</th>
                            <th>Account No</th>
                            {/* <th>Net Amount</th> */}
                            <th>Balance</th>
                            <th>Created At</th>
                            <th>status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{renderData()}</tbody>
                      </table>
                      {!isSearchActive && (
                        <div className="row justify-content-center pointer">
                          {/* PREVIOUS BUTTON */}
                          {/* {activePage !== paginationArr[0] ? (
                            <i
                              className="fa fa-arrow-left"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex(
                                  (x) => x === activePage
                                );
                                if (index !== -1) {
                                  setActivePage(paginationArr[index - 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ""
                          )} */}
                          {/* NEXT BUTTON */}
                          {/* {activePage !==
                          paginationArr[paginationArr.length - 1] ? (
                            <i
                              className="fa ml-3 fa-arrow-right"
                              aria-hidden="true"
                              onClick={() => {
                                let pageArr = paginationArr;
                                let index = pageArr.findIndex(
                                  (x) => x === activePage
                                );
                                if (index !== -1) {
                                  setActivePage(paginationArr[index + 1]);
                                }
                              }}
                            ></i>
                          ) : (
                            ""
                          )} */}

                          <button
                            aria-hidden={true}
                            className="btn btn-primary btn-sm mt-2 py-2 px-3"
                            disabled={
                              activePage ==
                                paginationArr[paginationArr.length - 1] ||
                              Loading
                            }
                            type="button"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex(
                                (x) => x === activePage
                              );
                              if (index !== -1) {
                                setActivePage(paginationArr[index + 1]);
                              }
                            }}
                          >
                            {Loading ? (
                              <span>
                                Loading...{" "}
                                <i
                                  className="fa fa-spinner fa-spin  "
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : (
                              "Show More"
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center my-4">
                    <i
                      className="fa fa-spinner fa-spin fa-3x"
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
                {renderUpdateAccountStatusPopup()}
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AccountList;

import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import {
  getFundByAmc,
  validateFundNav,
} from "../stores/services/funds.service";
import {
  getChartOfAccounts,
  getNewChartsOfAccounts,
  getNewChartsOfAccountsByFund,
} from "../stores/services/chatofaccounts.service";
import { getAllLedgers, getLedgerAccounts } from "../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../stores/services/daily-expense.service";
import { getLedgerInfoByCode } from "../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { getNetAssets } from "../stores/services/daily-trustee-fee.service";
import { numberWithCommas } from "./../utils/customFunction";
import { ThemeContext } from "../contexts/ThemeContext";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getNavDataForCalculation } from "../stores/services/nav.service";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyTrusteeFee = (props: any) => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const context = useContext(ThemeContext);
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  let [managementTableData, setManagementTableData] = useState<any>([]);

  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);
  const [settlementDate, setSettlementDate] = useState<any>(
    moment(today, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD")
  );
  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");
  const [deleting, setDeleting] = useState<any>(false);
  const [netAssets, setNetAssets] = useState<any>("");
  const [trusteeFeeLedgers, setTrusteeFeeLedgers] = useState<any>([]);
  const [trusteeSpecificLedger, setTrusteeSpecificLedger] = useState<any>([]);
  const [trusteeTaxLedger, setTrusteeTaxLedger] = useState<any>([]);

  const [managementSpecificLedgerDebit, setManagementSpecificLedgerDebit] =
    useState<any>([]);
  const [managementSpecificLedgerCredit, setManagementSpecificLedgerCredit] =
    useState<any>([]);
  const [managementTaxLedgerDebit, setManagementTaxLedgerDebit] = useState<any>(
    []
  );
  const [managementTaxLedgerCredit, setManagementTaxLedgerCredit] =
    useState<any>([]);

  const [
    accountingOperationalSpecificLedgerDebit,
    setAccountingOperationalSpecificLedgerDebit,
  ] = useState<any>([]);
  const [
    accountingOperationalSpecificLedgerCredit,
    setAccountingOperationalSpecificLedgerCredit,
  ] = useState<any>([]);

  const [accountingOperationalTableData, setAccountingOperationalTableData] =
    useState<any>([]);

  const [trusteeFee, setTrusteeFee] = useState<any>("");
  let [trusteeFeeTax, setTrusteeFeeTax] = useState<any>(0.0);

  const [managementFee, setManagementFee] = useState<any>("");
  let [managementFeeTax, setManagementFeeTax] = useState<any>(0.0);

  const [accountingOperationalFee, setAccountingOperationalFee] =
    useState<any>("");

  let [taxRate, setTaxRate] = useState<any>(0.0);
  let [managementTaxRate, setManagementTaxRate] = useState<any>(0.0);

  let [secpFee, setSecpFee] = useState<any>(0);
  const [secpLedgerDebit, setSecpLedgerDebit] = useState<any>([]);
  const [secpLedgerCredit, setSecpLedgerCredit] = useState<any>([]);
  const [secpTableData, setSecpTableData] = useState<any>([]);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "postnavpaymentsaccrual",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: "",
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(settlementDate).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  // React.useEffect(() => {
  //   setHeadLabel(props.match.params.type);
  //   fields["txnr_type"] = props.match.params.type;
  // }, [props.match.params.type]);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {
        console.log("ERROR => ", error);
      }
    };

    getFundByAMcCode(amc_code);
  }, []);

  const getBankAccounts = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      const response = await getNewChartsOfAccountsByFund(
        email,
        sessionStorage.getItem("amc_code") || "",
        fund
      );
      if (response.data.status == 200) {
        let ledgersacc = getLedgerAccounts(
          response.data.data.main_family[3].family[0].sub_family
        );
        let ledgersacc2 = getLedgerAccounts(
          response.data.data.main_family[2].family[1].sub_family
        );

        setManagementSpecificLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010101;
          })
        );

        setManagementSpecificLedgerCredit(
          ledgersacc2.find((item) => {
            return item.code === 301020101;
          })
        );

        setManagementTaxLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010102;
          })
        );

        setManagementTaxLedgerCredit(
          ledgersacc2.find((item) => {
            return item.code === 30102010101;
          })
        );

        setSecpLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401010501;
          })
        );

        setSecpLedgerCredit(
          ledgersacc2.find((item) => {
            return item.code === 301020501;
          })
        );

        setAccountingOperationalSpecificLedgerDebit(
          ledgersacc.find((item) => {
            return item.code === 401011001;
          })
        );

        setAccountingOperationalSpecificLedgerCredit(
          ledgersacc2.find((item) => {
            return item.code === 301021001;
          })
        );

        let trusteeLedger = ledgersacc.filter((item) => {
          return item.plsCode === 4010102;
        });

        // setTrusteeFeeLedgers(
        //   ledgersacc.filter((item) => {
        //     return item.plsCode === 4010102;
        //   })
        // );

        setTrusteeSpecificLedger(
          trusteeLedger.filter((item) => {
            return item.name === "TRUSTEE REMUNERATION EXPENSE";
          })
        );

        setTrusteeTaxLedger(
          trusteeLedger.filter((item) => {
            return item.name === "SALES TAX ON TRUSTEE REMUNERATION EXPENSE";
          })
        );

        setLegderAccounts(ledgersacc);

        let allLedgers = await getAllLedgers(response.data.data.main_family);
        setAllLedgers(allLedgers);
        // await ledgerDropDown(allLedgers);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  useEffect(() => {
    if (fund) {
      getBankAccounts();
    }
  }, [fund]);

  // const renderFundsDropdown = () => {
  //   return allFunds.map((item: any, index: string) => {
  //     if (item.nature === "Special Managed Account") {
  //       return (
  //         <>
  //           <option key={index} value={item.symbol_code}>
  //             {item.fund_name} ({item.symbol_code}) - {"SMA"}
  //           </option>
  //         </>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.symbol_code}>
  //           {item.fund_name} ({item.symbol_code})
  //         </option>
  //       );
  //     }
  //   });
  // };

  // Original function
  // const handleLoadSlabAsset1 = async () => {
  //   try {
  //     setTableData([]);
  //     setTrusteeFee(0);
  //     setTrusteeFeeTax(0);
  //     setTaxRate(0);
  //     const netAssetResponse = await getNetAssets(fund, date);
  //     console.log("Execution 1: ", netAssetResponse);
  //     if (netAssetResponse.data.status === 404) {
  //       setLoading(false);
  //       return toast.error("No net asset value found for selected date!");
  //     }

  //     trusteeSpecificLedger.map(async (ledger, index) => {
  //       const ledgerCodeResponse = await getLedgerInfoByCode(
  //         email,
  //         ledger.code
  //       );
  //       console.log("Execution 2: ", ledgerCodeResponse);

  //       setTableData((prevState) => [
  //         ...prevState,
  //         {
  //           gl_code: ledgerCodeResponse.data.data.gl_code,
  //           gl_name: ledgerCodeResponse.data.data.gl_name,
  //         },
  //       ]);
  //       if (ledger.related_ledgers_slabs) {
  //         ledger.related_ledgers_slabs.map(async (related, index) => {
  //           const relatedLedgerCodeResponse = await getLedgerInfoByCode(
  //             email,
  //             related
  //           );
  //           console.log("Execution 3: ", relatedLedgerCodeResponse);

  //           setTableData((prevState) => [
  //             ...prevState,
  //             {
  //               gl_code: relatedLedgerCodeResponse.data.data.gl_code,
  //               gl_name: relatedLedgerCodeResponse.data.data.gl_name,
  //             },
  //           ]);
  //         });
  //       }
  //       ledger.slabs.map((slab, indexSlab) => {
  //         if (
  //           parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) >=
  //             slab.amountFrom &&
  //           parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) <=
  //             slab.amountTo
  //         ) {
  //           // let trusteeFeeCalc =
  //           //   ((parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) -
  //           //     slab.amountFrom) *
  //           //     (slab.rate / 100) +
  //           //     slab.fixedAmmount) /
  //           //   365;

  //           let daysOfYear = moment(`${moment().year()}-12-31`).dayOfYear();
  //           const trusteeFeeCalc =
  //             // ((parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) -
  //             (parseFloat(
  //               netAssetResponse?.data?.data[0]?.total_net_asset || "0"
  //             ) *
  //               (slab.rate / 100) +
  //               slab.fixedAmmount) /
  //             daysOfYear;
  //           // 365;

  //           setTrusteeFee(trusteeFeeCalc.toFixed(2));
  //         }
  //       });
  //     });

  //     trusteeTaxLedger.map(async (ledger, index) => {
  //       const ledgerCodeResponse = await getLedgerInfoByCode(
  //         email,
  //         ledger.code
  //       );
  //       console.log("Execution 4: ", ledgerCodeResponse);

  //       setTableData((prevState) => [
  //         ...prevState,
  //         {
  //           gl_code: ledgerCodeResponse.data.data.gl_code,
  //           gl_name: ledgerCodeResponse.data.data.gl_name,
  //         },
  //       ]);
  //       if (ledger.related_ledgers_slabs) {
  //         ledger.related_ledgers_slabs.map(async (related, index) => {
  //           const relatedLedgerCodeResponse = await getLedgerInfoByCode(
  //             email,
  //             related
  //           );
  //           console.log("Execution 5: ", relatedLedgerCodeResponse);

  //           setTableData((prevState) => [
  //             ...prevState,
  //             {
  //               gl_code: relatedLedgerCodeResponse.data.data.gl_code,
  //               gl_name: relatedLedgerCodeResponse.data.data.gl_name,
  //             },
  //           ]);
  //         });
  //       }
  //     });

  //     setNetAssets(netAssetResponse?.data?.data[0]?.total_net_asset);
  //     setLoading(false);
  //   } catch (error: any) {
  //     toast.error(error.message);
  //     setTableData([]);
  //     setLoading(false);
  //   }
  // };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code}) - SMA`,
        };
      } else {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code})`,
        };
      }
    });
  };

  const handleLoadSlabAsset = async () => {
    try {
      setTableData([]);
      setTrusteeFee(0);
      setTrusteeFeeTax(0);
      setManagementFee(0);
      setManagementFeeTax(0);
      let selectedFund = allFunds.find((item) => item.symbol_code === fund);
      let slabs = JSON.parse(selectedFund.fee_slab || "[]");
      if (!slabs || slabs.length === 0) {
        toast.error("Slab data not found in fund!");
        setLoading(false);
        return;
      }
      setTaxRate(selectedFund?.sst_trustee_percentage);

      // const netAssetResponse = await getNetAssets(fund, date);

      const netAssetResponse = await getNavDataForCalculation(
        email,
        sessionStorage.getItem("amc_code") || "",
        fund,
        date
      );

      // let totalCurrentNetAssets =

      if (netAssetResponse.data.status === 404) {
        setLoading(false);
        return toast.error("No net asset value found for selected date!");
      }

      if (netAssetResponse?.data.status !== 200) {
        setLoading(false);
        return toast.error("Error loading net asset data!");
      }

      const ledgerPromises = trusteeSpecificLedger.map(async (ledger) => {
        const ledgerCodeResponse = await getLedgerInfoByCode(
          email,
          ledger.code,
          fund
        );

        const newTableData: { gl_code: any; gl_name: any }[] = [
          {
            gl_code: ledgerCodeResponse.data.data.gl_code,
            gl_name: ledgerCodeResponse.data.data.gl_name,
          },
        ];

        if (ledger.related_ledgers_slabs) {
          const relatedPromises: Promise<{ gl_code: any; gl_name: any }>[] =
            ledger.related_ledgers_slabs.map(async (related) => {
              const relatedLedgerCodeResponse = await getLedgerInfoByCode(
                email,
                related,
                fund
              );

              return {
                gl_code: relatedLedgerCodeResponse.data.data.gl_code,
                gl_name: relatedLedgerCodeResponse.data.data.gl_name,
              };
            });
          newTableData.push(...(await Promise.all(relatedPromises)));
        }

        slabs.forEach((slab) => {
          if (
            // parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) >=
            parseFloat(
              netAssetResponse?.data?.navData?.net_assets_for_fee_calculation
            ) >= slab.amountFrom &&
            // parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) <=
            parseFloat(
              netAssetResponse?.data?.navData?.net_assets_for_fee_calculation
            ) <= slab.amountTo
          ) {
            // let daysOfYear = moment(`${moment().year()}-12-31`).dayOfYear();

            const trusteeFeeCalc =
              // (parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) *
              (parseFloat(
                netAssetResponse?.data?.navData
                  ?.net_assets_for_fee_calculation || "0"
              ) *
                (parseFloat(slab.rate || "0") / 100) +
                parseFloat(slab.fixedAmmount || "0")) /
              // daysOfYear;
              365;

            setTrusteeFee(trusteeFeeCalc.toFixed(2));
          }
        });

        // ledger.slabs.forEach((slab) => {
        //   if (
        //     parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) >=
        //       slab.amountFrom &&
        //     parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) <=
        //       slab.amountTo
        //   ) {
        //     let daysOfYear = moment(`${moment().year()}-12-31`).dayOfYear();
        //     const trusteeFeeCalc =
        //       // ((parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) -
        //       (parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset) *
        //         (slab.rate / 100) +
        //         slab.fixedAmmount) /
        //       daysOfYear;
        //     // 365;

        //     setTrusteeFee(trusteeFeeCalc.toFixed(2));
        //   }
        // });

        return newTableData;
      });

      const trusteeTaxPromises = trusteeTaxLedger.map(async (ledger) => {
        const ledgerCodeResponse = await getLedgerInfoByCode(
          email,
          ledger.code,
          fund
        );

        const newTableData: { gl_code: any; gl_name: any }[] = [
          {
            gl_code: ledgerCodeResponse.data.data.gl_code,
            gl_name: ledgerCodeResponse.data.data.gl_name,
          },
        ];

        if (ledger.related_ledgers_slabs) {
          const relatedPromises: Promise<{ gl_code: any; gl_name: any }>[] =
            ledger.related_ledgers_slabs.map(async (related) => {
              const relatedLedgerCodeResponse = await getLedgerInfoByCode(
                email,
                related,
                fund
              );

              return {
                gl_code: relatedLedgerCodeResponse.data.data.gl_code,
                gl_name: relatedLedgerCodeResponse.data.data.gl_name,
              };
            });
          newTableData.push(...(await Promise.all(relatedPromises)));
        }

        return newTableData;
      });

      const tableDataResults = await Promise.all([
        ...ledgerPromises,
        ...trusteeTaxPromises,
      ]);
      setTableData(tableDataResults.flat());

      let managementCalc =
        // (parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset || "0") *
        (parseFloat(
          netAssetResponse?.data?.navData?.net_assets_for_fee_calculation || "0"
        ) *
          (selectedFund?.management_fee_percentage / 100)) /
        365;

      let managementTaxCalc =
        managementCalc * (selectedFund?.sst_on_management_fee_percentage / 100);

      setManagementFee(managementCalc);
      setManagementTaxRate(selectedFund?.sst_on_management_fee_percentage);
      setManagementFeeTax(managementTaxCalc);

      setManagementTableData([
        {
          gl_code: managementSpecificLedgerDebit.code,
          gl_name: managementSpecificLedgerDebit.name,
        },
        {
          gl_code: managementSpecificLedgerCredit.code,
          gl_name: managementSpecificLedgerCredit.name,
        },
        {
          gl_code: managementTaxLedgerDebit.code,
          gl_name: managementTaxLedgerDebit.name,
        },
        {
          gl_code: managementTaxLedgerCredit.code,
          gl_name: managementTaxLedgerCredit.name,
        },
      ]);

      let secpFeeCalc =
        // (parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset || "0") *
        (parseFloat(
          netAssetResponse?.data?.navData?.net_assets_for_fee_calculation || "0"
        ) *
          (selectedFund?.secp_fee_percentage / 100)) /
        365;
      setSecpFee(secpFeeCalc);

      setSecpTableData([
        {
          gl_code: secpLedgerDebit.code,
          gl_name: secpLedgerDebit.name,
        },
        {
          gl_code: secpLedgerCredit.code,
          gl_name: secpLedgerCredit.name,
        },
      ]);

      let accountingOperationalCalc =
        // (parseFloat(netAssetResponse?.data?.data[0]?.total_net_asset || "0") *
        (parseFloat(
          netAssetResponse?.data?.navData?.net_assets_for_fee_calculation || "0"
        ) *
          (selectedFund?.accounts_operations_percentage / 100)) /
        365;

      setAccountingOperationalFee(accountingOperationalCalc);

      setAccountingOperationalTableData([
        {
          gl_code: accountingOperationalSpecificLedgerDebit.code,
          gl_name: accountingOperationalSpecificLedgerDebit.name,
        },
        {
          gl_code: accountingOperationalSpecificLedgerCredit.code,
          gl_name: accountingOperationalSpecificLedgerCredit.name,
        },
      ]);

      // setNetAssets(netAssetResponse?.data?.data[0]?.total_net_asset);
      setNetAssets(
        netAssetResponse?.data?.navData?.net_assets_for_fee_calculation
      );
      setLoading(false);
    } catch (error: any) {
      console.log("ERROR => ", error);
      toast.error(error.message);
      setTableData([]);
      setLoading(false);
    }
  };

  const renderBanksDropdown = () => {
    return (
      ledgerAccounts &&
      ledgerAccounts.map((item: any, index: number) => {
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  // const handleAdd = () => {
  //   if (fund === "") {
  //     return toast.error("Fund is required");
  //   }
  //   // if (bank === "") {
  //   //   return toast.error("Bank is required");
  //   // }
  //   if (amc_code === "") {
  //     return toast.error("Invalid user. Login Again");
  //   }

  //   if (tableData.length === 0) {
  //     setTableData([
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   } else {
  //     setTableData((prevState) => [
  //       ...prevState,
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   }

  // };

  const handleGetAccounts = async () => {
    setLoading(true);
    setDisableProfit(false);
    setProfitRateConfirmed(false);

    if (fund === "") {
      setLoading(false);
      return toast.error("Fund is required");
    }
    // if (bank === "") {
    //   return toast.error("Bank is required");
    // }
    if (amc_code === "") {
      setLoading(false);
      return toast.error("Invalid user. Login Again");
    }

    // if (!date) {
    //   setLoading(false);
    //   return toast.error("Date is required");
    // }
    const response = await getDailyBankBalance(fund);
    let bankBalance = response.data.data;

    if (ledgerAccounts) {
      ledgerAccounts.map((item, index) => {
        let dailyQuarter = (parseFloat(item.quarterly_fee) / 90).toFixed(2);
        let dailyHalf = (parseFloat(item.half_yearly_fee) / 182).toFixed(2);
        let dailyYearly = (parseFloat(item.yearly_fee) / 365).toFixed(2);

        if (index === 0) {
          if (item.daily_expense === false) {
            return;
          } else if (
            parseInt(dailyQuarter) === 0 &&
            parseInt(dailyHalf) === 0 &&
            parseInt(dailyYearly) === 0
          ) {
            return;
          } else {
            let totalExpense =
              parseFloat(dailyQuarter) +
              parseFloat(dailyHalf) +
              parseFloat(dailyYearly);

            return setTableData([
              {
                id: index,
                fund_code: fund,
                amc_code: amc_code,
                gl_code: item.code,
                gl_name: item.name,
                quarterly_fee: item.quarterly_fee,
                half_yearly_fee: item.half_yearly_fee,
                yearly_fee: item.yearly_fee,
                // annual_payment: item.annual_payment,

                daily_quarterly_expense: dailyQuarter,
                daily_half_yearly_expense: dailyHalf,
                daily_yearly_expense: dailyYearly,
                total_expense: totalExpense.toFixed(2),

                associated_ledgers: item.associated_ledgers,
              },
            ]);
          }
        } else {
          if (item.daily_expense === false) {
            return;
          } else if (
            parseInt(dailyQuarter) === 0 &&
            parseInt(dailyHalf) === 0 &&
            parseInt(dailyYearly) === 0
          ) {
            return;
          } else {
            let totalExpense =
              parseFloat(dailyQuarter) +
              parseFloat(dailyHalf) +
              parseFloat(dailyYearly);

            return setTableData((prevState) => [
              ...prevState,
              {
                id: index,
                fund_code: fund,
                amc_code: amc_code,
                gl_code: item.code,
                gl_name: item.name,
                quarterly_fee: item.quarterly_fee,
                half_yearly_fee: item.half_yearly_fee,
                yearly_fee: item.yearly_fee,
                // annual_payment: item.annual_payment,

                daily_quarterly_expense: dailyQuarter,
                daily_half_yearly_expense: dailyHalf,
                daily_yearly_expense: dailyYearly,
                total_expense: totalExpense.toFixed(2),
                associated_ledgers: item.associated_ledgers,
              },
            ]);
          }
        }
      });
    }

    // if (tableData.length === 0) {
    //   setTableData([
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // } else {
    //   setTableData((prevState) => [
    //     ...prevState,
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // }
    setLoading(false);
  };

  // const handleCreate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setDisableProfit(false);
  //   setLoading(false);
  // };

  // const handleConfirmProfitRate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setProfitRateConfirmed(true);
  //   setLoading(false);
  // };

  //   const handleDailyExpense = async () => {
  //     setLoading(true);
  //     let email = sessionStorage.getItem("email");
  //     await new Promise((resolve) => {
  //       tableData.map(async (data, index) => {
  //         const responseMain = await getLedgerInfo(email, data.gl_code);

  //         if (data.associated_ledgers) {
  //           data.associated_ledgers.map(async (ledger, index) => {
  //             const responseAssociated = await getLedgerInfo(email, ledger);

  //             if (responseMain && responseAssociated) {
  //               if (sendingLedgers.length > 0) {
  //                 await new Promise((resolve) => {
  //                   sendingLedgers = [
  //                     ...sendingLedgers,
  //                     //debit
  //                     {
  //                       account_number: "",
  //                       account_title: "",
  //                       amc_code: amc_code,
  //                       balance: "",
  //                       bank_code: "",
  //                       branch: "",
  //                       child_family_code: responseMain.child_family_code,
  //                       child_family_name: responseMain.child_family_code,
  //                       counter_account_number: "",
  //                       counter_account_title: "",
  //                       counter_bank: "",
  //                       counter_branch: "",
  //                       created_at: "",
  //                       credit_amount: "0",
  //                       debit_amount: data.total_expense,
  //                       description: "",
  //                       doc_type: "",
  //                       entered_by: "",
  //                       evidence: "",
  //                       family_code: responseMain.family_code,
  //                       family_name: responseMain.family_name,
  //                       fund_code: fund,
  //                       gl_code: responseMain.gl_code,
  //                       gl_name: responseMain.gl_name,
  //                       instruction_date: "",
  //                       instrument_by: "",
  //                       instrument_date: "",
  //                       instrument_no: "",
  //                       instrument_type: "",
  //                       main_family_code: responseMain.main_family_code,
  //                       main_family_name: responseMain.main_family_name,
  //                       realized: "true",
  //                       realized_by: "",
  //                       realized_date: "",
  //                       sub_family_code: responseMain.sub_family_code,
  //                       sub_family_name: responseMain.sub_family_name,
  //                     },
  //                     //credit
  //                     {
  //                       account_number: "",
  //                       account_title: "",
  //                       amc_code: amc_code,
  //                       balance: "",
  //                       bank_code: "",
  //                       branch: "",
  //                       child_family_code: responseAssociated.child_family_code,
  //                       child_family_name: responseAssociated.child_family_code,
  //                       counter_account_number: "",
  //                       counter_account_title: "",
  //                       counter_bank: "",
  //                       counter_branch: "",
  //                       created_at: "",
  //                       credit_amount: data.total_expense,
  //                       debit_amount: "0",
  //                       description: "",
  //                       doc_type: "",
  //                       entered_by: "",
  //                       evidence: "",
  //                       family_code: responseAssociated.family_code,
  //                       family_name: responseAssociated.family_name,
  //                       fund_code: fund,
  //                       gl_code: responseAssociated.gl_code,
  //                       gl_name: responseAssociated.gl_name,
  //                       instruction_date: "",
  //                       instrument_by: "",
  //                       instrument_date: "",
  //                       instrument_no: "",
  //                       instrument_type: "",
  //                       main_family_code: responseAssociated.main_family_code,
  //                       main_family_name: responseAssociated.main_family_name,
  //                       realized: "true",
  //                       realized_by: "",
  //                       realized_date: "",
  //                       sub_family_code: responseAssociated.sub_family_code,
  //                       sub_family_name: responseAssociated.sub_family_name,
  //                     },
  //                   ];

  //                   setTimeout(() => {
  //                     resolve("resolved");
  //                   }, 2000);
  //                 });
  //               } else {
  //                 await new Promise((resolve) => {
  //                   sendingLedgers = [
  //                     //debit
  //                     {
  //                       account_number: "",
  //                       account_title: "",
  //                       amc_code: amc_code,
  //                       balance: "",
  //                       bank_code: "",
  //                       branch: "",
  //                       child_family_code: responseMain.child_family_code,
  //                       child_family_name: responseMain.child_family_code,
  //                       counter_account_number: "",
  //                       counter_account_title: "",
  //                       counter_bank: "",
  //                       counter_branch: "",
  //                       created_at: "",
  //                       credit_amount: "0",
  //                       debit_amount: data.total_expense,
  //                       description: "",
  //                       doc_type: "",
  //                       entered_by: "",
  //                       evidence: "",
  //                       family_code: responseMain.family_code,
  //                       family_name: responseMain.family_name,
  //                       fund_code: fund,
  //                       gl_code: responseMain.gl_code,
  //                       gl_name: responseMain.gl_name,
  //                       instruction_date: "",
  //                       instrument_by: "",
  //                       instrument_date: "",
  //                       instrument_no: "",
  //                       instrument_type: "",
  //                       main_family_code: responseMain.main_family_code,
  //                       main_family_name: responseMain.main_family_name,
  //                       realized: "true",
  //                       realized_by: "",
  //                       realized_date: "",
  //                       sub_family_code: responseMain.sub_family_code,
  //                       sub_family_name: responseMain.sub_family_name,
  //                     },
  //                     //credit
  //                     {
  //                       account_number: "",
  //                       account_title: "",
  //                       amc_code: amc_code,
  //                       balance: "",
  //                       bank_code: "",
  //                       branch: "",
  //                       child_family_code: responseAssociated.child_family_code,
  //                       child_family_name: responseAssociated.child_family_code,
  //                       counter_account_number: "",
  //                       counter_account_title: "",
  //                       counter_bank: "",
  //                       counter_branch: "",
  //                       created_at: "",
  //                       credit_amount: data.total_expense,
  //                       debit_amount: "0",
  //                       description: "",
  //                       doc_type: "",
  //                       entered_by: "",
  //                       evidence: "",
  //                       family_code: responseAssociated.family_code,
  //                       family_name: responseAssociated.family_name,
  //                       fund_code: fund,
  //                       gl_code: responseAssociated.gl_code,
  //                       gl_name: responseAssociated.gl_name,
  //                       instruction_date: "",
  //                       instrument_by: "",
  //                       instrument_date: "",
  //                       instrument_no: "",
  //                       instrument_type: "",
  //                       main_family_code: responseAssociated.main_family_code,
  //                       main_family_name: responseAssociated.main_family_name,
  //                       realized: "true",
  //                       realized_by: "",
  //                       realized_date: "",
  //                       sub_family_code: responseAssociated.sub_family_code,
  //                       sub_family_name: responseAssociated.sub_family_name,
  //                     },
  //                   ];

  //                   setTimeout(() => {
  //                     resolve("resolved");
  //                   }, 2000);
  //                 });
  //               }
  //             }
  //           });
  //         }
  //       });

  //       setTimeout(() => {
  //         resolve("resolved");
  //       }, 20000);
  //     });

  //     if (sendingLedgers.length > 0) {
  //       fields.ledgers = JSON.stringify(sendingLedgers);
  //       fields.fund_code = fund;
  //       if (fields.ledgers.length > 1) {
  //         try {
  //           const result = await addMegaTransaction(fields);

  //           if (result.data.status === 400) {
  //             toast.warning(result.data.message);
  //             setSendingLedgers([]);
  //           } else if (result.data.status === 417) {
  //             toast.warning(result.data.message);
  //             setSendingLedgers([]);
  //           } else {
  //             toast.success(`Data added. ID: ${result.data.txn_id}`);
  //             setSendingLedgers([]);

  //             setTimeout(() => {
  //               toast.success("");
  //               history.push("/admin/create-transaction");
  //             }, 1000);
  //             // toast.success(response.data.message);
  //           }
  //         } catch (error) {
  //         }
  //       }
  //     }

  //     setProfitRateConfirmed(true);
  //     setLoading(false);
  //   };

  const handleDailyTrusteeFee = async () => {
    setLoading(true);
    let email = sessionStorage.getItem("email");
    // await new Promise((resolve) => {
    //   trusteeSpecificLedger.map(async (ledger, index) => {
    //     const responseMain = await getLedgerInfo(email, ledger.code);

    //     if (ledger.related_ledgers_slabs) {
    //       ledger.related_ledgers_slabs.map(async (related, index) => {
    //         const responseRelated = await getLedgerInfo(email, related);

    //         if (responseMain && responseRelated) {
    //           let amountDebit =
    //             String(responseMain.gl_code)[0] === "4"
    //               ? responseMain.gl_name !==
    //                 "SALES TAX ON TRUSTEE REMUNERATION EXPENSE"
    //                 ? trusteeFee
    //                 : trusteeFeeTax.toFixed(2)
    //               : 0.0;
    //           let amountCredit =
    //             String(responseRelated.gl_code)[0] === "3"
    //               ? responseRelated.gl_name ===
    //                 "SALES TAX  ON TRUSTEE REMUNERATION PAYABLE"
    //                 ? trusteeFeeTax.toFixed(2)
    //                 : trusteeFee
    //               : 0.0;

    //           if (sendingLedgers.length > 0) {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 ...sendingLedgers,
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: amountDebit,
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseRelated.child_family_code,
    //                   child_family_name: responseRelated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: amountCredit,
    //                   debit_amount: "0",
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseRelated.family_code,
    //                   family_name: responseRelated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseRelated.gl_code,
    //                   gl_name: responseRelated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseRelated.main_family_code,
    //                   main_family_name: responseRelated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseRelated.sub_family_code,
    //                   sub_family_name: responseRelated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 2000);
    //             });
    //           } else {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: amountDebit,
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseRelated.child_family_code,
    //                   child_family_name: responseRelated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: amountCredit,
    //                   debit_amount: "0",
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseRelated.family_code,
    //                   family_name: responseRelated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseRelated.gl_code,
    //                   gl_name: responseRelated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseRelated.main_family_code,
    //                   main_family_name: responseRelated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseRelated.sub_family_code,
    //                   sub_family_name: responseRelated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 2000);
    //             });
    //           }
    //         }
    //       });
    //     }
    //   });

    //   trusteeTaxLedger.map(async (ledger, index) => {
    //     const responseMain = await getLedgerInfo(email, ledger.code);

    //     if (ledger.related_ledgers_slabs) {
    //       ledger.related_ledgers_slabs.map(async (related, index) => {
    //         const responseRelated = await getLedgerInfo(email, related);

    //         if (responseMain && responseRelated) {
    //           let amountDebit =
    //             String(responseMain.gl_code)[0] === "4"
    //               ? responseMain.gl_name !==
    //                 "SALES TAX ON TRUSTEE REMUNERATION EXPENSE"
    //                 ? trusteeFee
    //                 : trusteeFeeTax.toFixed(2)
    //               : 0.0;
    //           let amountCredit =
    //             String(responseRelated.gl_code)[0] === "3"
    //               ? responseRelated.gl_name ===
    //                 "SALES TAX  ON TRUSTEE REMUNERATION PAYABLE"
    //                 ? trusteeFeeTax.toFixed(2)
    //                 : trusteeFee
    //               : 0.0;

    //           if (sendingLedgers.length > 0) {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 ...sendingLedgers,
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: parseFloat(amountDebit),
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseRelated.child_family_code,
    //                   child_family_name: responseRelated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: parseFloat(amountCredit),
    //                   debit_amount: "0",
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseRelated.family_code,
    //                   family_name: responseRelated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseRelated.gl_code,
    //                   gl_name: responseRelated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseRelated.main_family_code,
    //                   main_family_name: responseRelated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseRelated.sub_family_code,
    //                   sub_family_name: responseRelated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 1000);
    //             });
    //           } else {
    //             await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 //debit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseMain.child_family_code,
    //                   child_family_name: responseMain.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: parseFloat(amountDebit),
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseMain.family_code,
    //                   family_name: responseMain.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseMain.gl_code,
    //                   gl_name: responseMain.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseMain.main_family_code,
    //                   main_family_name: responseMain.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseMain.sub_family_code,
    //                   sub_family_name: responseMain.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //                 //credit
    //                 {
    //                   account_number: "",
    //                   account_title: "",
    //                   amc_code: amc_code,
    //                   balance: "",
    //                   bank_code: "",
    //                   branch: "",
    //                   child_family_code: responseRelated.child_family_code,
    //                   child_family_name: responseRelated.child_family_code,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: parseFloat(amountCredit),
    //                   debit_amount: "0",
    //                   description: "Daily Trustee Fee",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: responseRelated.family_code,
    //                   family_name: responseRelated.family_name,
    //                   fund_code: fund,
    //                   gl_code: responseRelated.gl_code,
    //                   gl_name: responseRelated.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: responseRelated.main_family_code,
    //                   main_family_name: responseRelated.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: responseRelated.sub_family_code,
    //                   sub_family_name: responseRelated.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               setTimeout(() => {
    //                 resolve("resolved");
    //               }, 1000);
    //             });
    //           }
    //         }
    //       });
    //     }
    //   });

    //   setTimeout(() => {
    //     resolve("resolved");
    //   }, 2000);
    // });

    // await new Promise(async (resolve) => {
    //   await Promise.all(
    //     trusteeSpecificLedger.map(async (ledger, index) => {
    //       const responseMain = await getLedgerInfo(email, ledger.code);

    //       if (ledger.related_ledgers_slabs) {
    //         await Promise.all(
    //           ledger.related_ledgers_slabs.map(async (related, index) => {
    //             const responseRelated = await getLedgerInfo(email, related);

    //             if (responseMain && responseRelated) {
    //               let amountDebit =
    //                 String(responseMain.gl_code)[0] === "4"
    //                   ? responseMain.gl_name !==
    //                     "SALES TAX ON TRUSTEE REMUNERATION EXPENSE"
    //                     ? trusteeFee
    //                     : trusteeFeeTax.toFixed(2)
    //                   : 0.0;
    //               let amountCredit =
    //                 String(responseRelated.gl_code)[0] === "3"
    //                   ? responseRelated.gl_name ===
    //                     "SALES TAX  ON TRUSTEE REMUNERATION PAYABLE"
    //                     ? trusteeFeeTax.toFixed(2)
    //                     : trusteeFee
    //                   : 0.0;

    //               if (
    //                 amountDebit === 0 ||
    //                 amountCredit === 0 ||
    //                 amountDebit === 0.0 ||
    //                 amountCredit === 0.0 ||
    //                 amountDebit === "0" ||
    //                 amountCredit === "0" ||
    //                 amountDebit === "0.00" ||
    //                 amountCredit === "0.00"
    //               ) {
    //                 return toast.error("No value to save in ledgers!");
    //               }

    //               await new Promise((resolve) => {
    //                 sendingLedgers.push(
    //                   {
    //                     account_number: "",
    //                     account_title: "",
    //                     amc_code: amc_code,
    //                     balance: "",
    //                     bank_code: "",
    //                     branch: "",
    //                     child_family_code: responseMain.child_family_code,
    //                     child_family_name: responseMain.child_family_code,
    //                     counter_account_number: "",
    //                     counter_account_title: "",
    //                     counter_bank: "",
    //                     counter_branch: "",
    //                     created_at: "",
    //                     credit_amount: "0",
    //                     debit_amount: amountDebit,
    //                     description: "Daily Trustee Fee",
    //                     doc_type: "",
    //                     entered_by: "",
    //                     evidence: "",
    //                     family_code: responseMain.family_code,
    //                     family_name: responseMain.family_name,
    //                     fund_code: fund,
    //                     gl_code: responseMain.gl_code,
    //                     gl_name: responseMain.gl_name,
    //                     instruction_date: "",
    //                     instrument_by: "",
    //                     instrument_date: "",
    //                     instrument_no: "",
    //                     instrument_type: "",
    //                     main_family_code: responseMain.main_family_code,
    //                     main_family_name: responseMain.main_family_name,
    //                     realized: "true",
    //                     realized_by: "",
    //                     realized_date: "",
    //                     sub_family_code: responseMain.sub_family_code,
    //                     sub_family_name: responseMain.sub_family_name,
    //                     period: sessionStorage.getItem("active_period") || "",
    //                   },
    //                   {
    //                     account_number: "",
    //                     account_title: "",
    //                     amc_code: amc_code,
    //                     balance: "",
    //                     bank_code: "",
    //                     branch: "",
    //                     child_family_code: responseRelated.child_family_code,
    //                     child_family_name: responseRelated.child_family_code,
    //                     counter_account_number: "",
    //                     counter_account_title: "",
    //                     counter_bank: "",
    //                     counter_branch: "",
    //                     created_at: "",
    //                     credit_amount: amountCredit,
    //                     debit_amount: "0",
    //                     description: "Daily Trustee Fee",
    //                     doc_type: "",
    //                     entered_by: "",
    //                     evidence: "",
    //                     family_code: responseRelated.family_code,
    //                     family_name: responseRelated.family_name,
    //                     fund_code: fund,
    //                     gl_code: responseRelated.gl_code,
    //                     gl_name: responseRelated.gl_name,
    //                     instruction_date: "",
    //                     instrument_by: "",
    //                     instrument_date: "",
    //                     instrument_no: "",
    //                     instrument_type: "",
    //                     main_family_code: responseRelated.main_family_code,
    //                     main_family_name: responseRelated.main_family_name,
    //                     realized: "true",
    //                     realized_by: "",
    //                     realized_date: "",
    //                     sub_family_code: responseRelated.sub_family_code,
    //                     sub_family_name: responseRelated.sub_family_name,
    //                     period: sessionStorage.getItem("active_period") || "",
    //                   }
    //                 );

    //                 setTimeout(() => {
    //                   resolve("resolved");
    //                 }, 2000);
    //               });
    //             }
    //           })
    //         );
    //       }
    //     })
    //   );

    //   //for tax

    //   await Promise.all(
    //     trusteeTaxLedger.map(async (ledger, index) => {
    //       const responseMain = await getLedgerInfo(email, ledger.code);

    //       if (ledger.related_ledgers_slabs) {
    //         await Promise.all(
    //           ledger.related_ledgers_slabs.map(async (related, index) => {
    //             const responseRelated = await getLedgerInfo(email, related);

    //             if (responseMain && responseRelated) {
    //               let amountDebit =
    //                 String(responseMain.gl_code)[0] === "4"
    //                   ? responseMain.gl_name !==
    //                     "SALES TAX ON TRUSTEE REMUNERATION EXPENSE"
    //                     ? trusteeFee
    //                     : trusteeFeeTax.toFixed(2)
    //                   : 0.0;
    //               let amountCredit =
    //                 String(responseRelated.gl_code)[0] === "3"
    //                   ? responseRelated.gl_name ===
    //                     "SALES TAX  ON TRUSTEE REMUNERATION PAYABLE"
    //                     ? trusteeFeeTax.toFixed(2)
    //                     : trusteeFee
    //                   : 0.0;

    //               if (
    //                 amountDebit === 0 ||
    //                 amountCredit === 0 ||
    //                 amountDebit === 0.0 ||
    //                 amountCredit === 0.0 ||
    //                 amountDebit === "0" ||
    //                 amountCredit === "0" ||
    //                 amountDebit === "0.00" ||
    //                 amountCredit === "0.00"
    //               ) {
    //                 toast.error("No tax to debit/credit in ledgers!");
    //                 return;
    //               }

    //               await new Promise((resolve) => {
    //                 sendingLedgers.push(
    //                   {
    //                     account_number: "",
    //                     account_title: "",
    //                     amc_code: amc_code,
    //                     balance: "",
    //                     bank_code: "",
    //                     branch: "",
    //                     child_family_code: responseMain.child_family_code,
    //                     child_family_name: responseMain.child_family_code,
    //                     counter_account_number: "",
    //                     counter_account_title: "",
    //                     counter_bank: "",
    //                     counter_branch: "",
    //                     created_at: "",
    //                     credit_amount: "0",
    //                     debit_amount: parseFloat(amountDebit),
    //                     description: "Daily Trustee Fee",
    //                     doc_type: "",
    //                     entered_by: "",
    //                     evidence: "",
    //                     family_code: responseMain.family_code,
    //                     family_name: responseMain.family_name,
    //                     fund_code: fund,
    //                     gl_code: responseMain.gl_code,
    //                     gl_name: responseMain.gl_name,
    //                     instruction_date: "",
    //                     instrument_by: "",
    //                     instrument_date: "",
    //                     instrument_no: "",
    //                     instrument_type: "",
    //                     main_family_code: responseMain.main_family_code,
    //                     main_family_name: responseMain.main_family_name,
    //                     realized: "true",
    //                     realized_by: "",
    //                     realized_date: "",
    //                     sub_family_code: responseMain.sub_family_code,
    //                     sub_family_name: responseMain.sub_family_name,
    //                     period: sessionStorage.getItem("active_period") || "",
    //                   },
    //                   {
    //                     account_number: "",
    //                     account_title: "",
    //                     amc_code: amc_code,
    //                     balance: "",
    //                     bank_code: "",
    //                     branch: "",
    //                     child_family_code: responseRelated.child_family_code,
    //                     child_family_name: responseRelated.child_family_code,
    //                     counter_account_number: "",
    //                     counter_account_title: "",
    //                     counter_bank: "",
    //                     counter_branch: "",
    //                     created_at: "",
    //                     credit_amount: parseFloat(amountCredit),
    //                     debit_amount: "0",
    //                     description: "Daily Trustee Fee",
    //                     doc_type: "",
    //                     entered_by: "",
    //                     evidence: "",
    //                     family_code: responseRelated.family_code,
    //                     family_name: responseRelated.family_name,
    //                     fund_code: fund,
    //                     gl_code: responseRelated.gl_code,
    //                     gl_name: responseRelated.gl_name,
    //                     instruction_date: "",
    //                     instrument_by: "",
    //                     instrument_date: "",
    //                     instrument_no: "",
    //                     instrument_type: "",
    //                     main_family_code: responseRelated.main_family_code,
    //                     main_family_name: responseRelated.main_family_name,
    //                     realized: "true",
    //                     realized_by: "",
    //                     realized_date: "",
    //                     sub_family_code: responseRelated.sub_family_code,
    //                     sub_family_name: responseRelated.sub_family_name,
    //                     period: sessionStorage.getItem("active_period") || "",
    //                   }
    //                 );

    //                 setTimeout(() => {
    //                   resolve("resolved");
    //                 }, 500);
    //               });
    //             }
    //           })
    //         );
    //       }
    //     })
    //   );

    //   setTimeout(() => {
    //     resolve("resolved");
    //   }, 2000);
    // });

    let trusteePassed = true;
    let taxPassed = true;

    let netAmount = 0;

    for (let i = 0; i < trusteeSpecificLedger.length; i++) {
      let ledger = trusteeSpecificLedger[i];

      const responseMain = await getLedgerInfo(email, ledger.code, fund);

      if (ledger.related_ledgers_slabs) {
        for (let j = 0; j < ledger.related_ledgers_slabs.length; j++) {
          let related = ledger.related_ledgers_slabs[j];
          const responseRelated = await getLedgerInfo(email, related, fund);

          if (responseMain && responseRelated) {
            // let amountDebit = responseMain?.gl_code[0]
            //   ?.toString()
            //   .startsWith("4")
            //   ? !responseMain?.gl_name.includes("TAX")
            //     ? trusteeFee
            //     : trusteeFeeTax.toFixed(2)
            //   : 0.0;
            let amountDebit = trusteeFee || 0.0;
            let amountCredit = trusteeFee || 0.0;

            if (
              amountDebit === 0 ||
              amountCredit === 0 ||
              amountDebit === 0.0 ||
              amountCredit === 0.0 ||
              amountDebit === "0" ||
              amountCredit === "0" ||
              amountDebit === "0.00" ||
              amountCredit === "0.00"
            ) {
              toast.error("No value to save in ledgers!");
              trusteePassed = false;
              break;
            }

            netAmount += +amountDebit;
            sendingLedgers.push(
              {
                account_number: "",
                account_title: "",
                amc_code: amc_code,
                balance: "",
                bank_code: "",
                branch: "",
                child_family_code: responseMain.child_family_code,
                child_family_name: responseMain.child_family_code,
                counter_account_number: "",
                counter_account_title: "",
                counter_bank: "",
                counter_branch: "",
                created_at: "",
                credit_amount: "0",
                debit_amount: amountDebit,
                description: "Daily Trustee Fee",
                doc_type: "",
                entered_by: "",
                evidence: "",
                family_code: responseMain.family_code,
                family_name: responseMain.family_name,
                fund_code: fund,
                gl_code: responseMain.gl_code,
                gl_name: responseMain.gl_name,
                instruction_date: "",
                instrument_by: "",
                instrument_date: "",
                instrument_no: "",
                instrument_type: "",
                main_family_code: responseMain.main_family_code,
                main_family_name: responseMain.main_family_name,
                realized: "true",
                realized_by: "",
                realized_date: "",
                sub_family_code: responseMain.sub_family_code,
                sub_family_name: responseMain.sub_family_name,
                period: sessionStorage.getItem("active_period") || "",
              },
              {
                account_number: "",
                account_title: "",
                amc_code: amc_code,
                balance: "",
                bank_code: "",
                branch: "",
                child_family_code: responseRelated.child_family_code,
                child_family_name: responseRelated.child_family_code,
                counter_account_number: "",
                counter_account_title: "",
                counter_bank: "",
                counter_branch: "",
                created_at: "",
                credit_amount: amountCredit,
                debit_amount: "0",
                description: "Daily Trustee Fee",
                doc_type: "",
                entered_by: "",
                evidence: "",
                family_code: responseRelated.family_code,
                family_name: responseRelated.family_name,
                fund_code: fund,
                gl_code: responseRelated.gl_code,
                gl_name: responseRelated.gl_name,
                instruction_date: "",
                instrument_by: "",
                instrument_date: "",
                instrument_no: "",
                instrument_type: "",
                main_family_code: responseRelated.main_family_code,
                main_family_name: responseRelated.main_family_name,
                realized: "true",
                realized_by: "",
                realized_date: "",
                sub_family_code: responseRelated.sub_family_code,
                sub_family_name: responseRelated.sub_family_name,
                period: sessionStorage.getItem("active_period") || "",
              }
            );
          }
        }
      }
    }

    if (!trusteePassed) {
      setLoading(false);
      return;
    }

    //for tax

    for (let i = 0; i < trusteeTaxLedger.length; i++) {
      let ledger = trusteeTaxLedger[i];

      const responseMain = await getLedgerInfo(email, ledger.code, fund);

      if (ledger.related_ledgers_slabs) {
        for (let j = 0; j < ledger.related_ledgers_slabs.length; j++) {
          let related = ledger.related_ledgers_slabs[j];
          const responseRelated = await getLedgerInfo(email, related, fund);

          if (responseMain && responseRelated) {
            // let amountDebit = responseMain?.gl_code[0]
            //   ?.toString()
            //   .startsWith("4")
            //   ? !responseMain.gl_name.includes("TAX")
            //     ? trusteeFee
            //     : trusteeFeeTax.toFixed(2)
            //   : 0.0;
            // let amountCredit = responseRelated?.gl_code[0]
            //   ?.toString()
            //   .startsWith("3")
            //   ? responseRelated?.gl_name.includes("TAX")
            //     ? trusteeFeeTax.toFixed(2)
            //     : trusteeFee
            //   : 0.0;

            let amountDebit = trusteeFeeTax.toFixed(2) || 0.0;
            let amountCredit = trusteeFeeTax.toFixed(2) || 0.0;

            if (
              amountDebit === 0 ||
              amountCredit === 0 ||
              amountDebit === 0.0 ||
              amountCredit === 0.0 ||
              amountDebit === "0" ||
              amountCredit === "0" ||
              amountDebit === "0.00" ||
              amountCredit === "0.00"
            ) {
              toast.error("No tax to debit/credit in ledgers!");
              taxPassed = false;
              break;
            }
            netAmount += +amountDebit;

            sendingLedgers.push(
              {
                account_number: "",
                account_title: "",
                amc_code: amc_code,
                balance: "",
                bank_code: "",
                branch: "",
                child_family_code: responseMain.child_family_code,
                child_family_name: responseMain.child_family_code,
                counter_account_number: "",
                counter_account_title: "",
                counter_bank: "",
                counter_branch: "",
                created_at: "",
                credit_amount: "0",
                debit_amount: parseFloat(amountDebit),
                description: "Daily Trustee Fee",
                doc_type: "",
                entered_by: "",
                evidence: "",
                family_code: responseMain.family_code,
                family_name: responseMain.family_name,
                fund_code: fund,
                gl_code: responseMain.gl_code,
                gl_name: responseMain.gl_name,
                instruction_date: "",
                instrument_by: "",
                instrument_date: "",
                instrument_no: "",
                instrument_type: "",
                main_family_code: responseMain.main_family_code,
                main_family_name: responseMain.main_family_name,
                realized: "true",
                realized_by: "",
                realized_date: "",
                sub_family_code: responseMain.sub_family_code,
                sub_family_name: responseMain.sub_family_name,
                period: sessionStorage.getItem("active_period") || "",
              },
              {
                account_number: "",
                account_title: "",
                amc_code: amc_code,
                balance: "",
                bank_code: "",
                branch: "",
                child_family_code: responseRelated.child_family_code,
                child_family_name: responseRelated.child_family_code,
                counter_account_number: "",
                counter_account_title: "",
                counter_bank: "",
                counter_branch: "",
                created_at: "",
                credit_amount: parseFloat(amountCredit),
                debit_amount: "0",
                description: "Daily Trustee Fee",
                doc_type: "",
                entered_by: "",
                evidence: "",
                family_code: responseRelated.family_code,
                family_name: responseRelated.family_name,
                fund_code: fund,
                gl_code: responseRelated.gl_code,
                gl_name: responseRelated.gl_name,
                instruction_date: "",
                instrument_by: "",
                instrument_date: "",
                instrument_no: "",
                instrument_type: "",
                main_family_code: responseRelated.main_family_code,
                main_family_name: responseRelated.main_family_name,
                realized: "true",
                realized_by: "",
                realized_date: "",
                sub_family_code: responseRelated.sub_family_code,
                sub_family_name: responseRelated.sub_family_name,
                period: sessionStorage.getItem("active_period") || "",
              }
            );
          }
        }
      }
    }

    if (!taxPassed) {
      setLoading(false);
      return;
    }

    if (accountingOperationalFee) {
      const responseDebit = await getLedgerInfo(
        email,
        accountingOperationalSpecificLedgerDebit.code,
        fund
      );

      const responseCredit = await getLedgerInfo(
        email,
        accountingOperationalSpecificLedgerCredit.code,
        fund
      );

      netAmount += +accountingOperationalFee;

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseDebit.child_family_code,
          child_family_name: responseDebit.child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: parseFloat(accountingOperationalFee),
          description: "Daily Accounting Operational Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseDebit.family_code,
          family_name: responseDebit.family_name,
          fund_code: fund,
          gl_code: responseDebit.gl_code,
          gl_name: responseDebit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseDebit.main_family_code,
          main_family_name: responseDebit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseDebit.sub_family_code,
          sub_family_name: responseDebit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseCredit.child_family_code,
          child_family_name: responseCredit.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: parseFloat(accountingOperationalFee),
          debit_amount: "0",
          description: "Daily Accounting Operational Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseCredit.family_code,
          family_name: responseCredit.family_name,
          fund_code: fund,
          gl_code: responseCredit.gl_code,
          gl_name: responseCredit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseCredit.main_family_code,
          main_family_name: responseCredit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseCredit.sub_family_code,
          sub_family_name: responseCredit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
    }

    if (secpFee) {
      const responseDebit = await getLedgerInfo(
        email,
        secpLedgerDebit.code,
        fund
      );

      const responseCredit = await getLedgerInfo(
        email,
        secpLedgerCredit.code,
        fund
      );
      netAmount += +secpFee;

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseDebit.child_family_code,
          child_family_name: responseDebit.child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: parseFloat(secpFee),
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseDebit.family_code,
          family_name: responseDebit.family_name,
          fund_code: fund,
          gl_code: responseDebit.gl_code,
          gl_name: responseDebit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseDebit.main_family_code,
          main_family_name: responseDebit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseDebit.sub_family_code,
          sub_family_name: responseDebit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseCredit.child_family_code,
          child_family_name: responseCredit.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: parseFloat(secpFee),
          debit_amount: "0",
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseCredit.family_code,
          family_name: responseCredit.family_name,
          fund_code: fund,
          gl_code: responseCredit.gl_code,
          gl_name: responseCredit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseCredit.main_family_code,
          main_family_name: responseCredit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseCredit.sub_family_code,
          sub_family_name: responseCredit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
    }

    if (managementFee) {
      const responseDebit = await getLedgerInfo(
        email,
        managementSpecificLedgerDebit.code,
        fund
      );
      const responseCredit = await getLedgerInfo(
        email,
        managementSpecificLedgerCredit.code,
        fund
      );
      netAmount += +managementFee;

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseDebit.child_family_code,
          child_family_name: responseDebit.child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: parseFloat(managementFee),
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseDebit.family_code,
          family_name: responseDebit.family_name,
          fund_code: fund,
          gl_code: responseDebit.gl_code,
          gl_name: responseDebit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseDebit.main_family_code,
          main_family_name: responseDebit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseDebit.sub_family_code,
          sub_family_name: responseDebit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseCredit.child_family_code,
          child_family_name: responseCredit.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: parseFloat(managementFee),
          debit_amount: "0",
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseCredit.family_code,
          family_name: responseCredit.family_name,
          fund_code: fund,
          gl_code: responseCredit.gl_code,
          gl_name: responseCredit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseCredit.main_family_code,
          main_family_name: responseCredit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseCredit.sub_family_code,
          sub_family_name: responseCredit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
    }

    if (managementFeeTax) {
      const responseDebit = await getLedgerInfo(
        email,
        managementTaxLedgerDebit.code,
        fund
      );
      const responseCredit = await getLedgerInfo(
        email,
        managementTaxLedgerCredit.code,
        fund
      );
      netAmount += +managementFeeTax;

      sendingLedgers.push(
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseDebit.child_family_code,
          child_family_name: responseDebit.child_family_name,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: "0",
          debit_amount: parseFloat(managementFeeTax),
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseDebit.family_code,
          family_name: responseDebit.family_name,
          fund_code: fund,
          gl_code: responseDebit.gl_code,
          gl_name: responseDebit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseDebit.main_family_code,
          main_family_name: responseDebit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseDebit.sub_family_code,
          sub_family_name: responseDebit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        },
        {
          account_number: "",
          account_title: "",
          amc_code: amc_code,
          balance: "",
          bank_code: "",
          branch: "",
          child_family_code: responseCredit.child_family_code,
          child_family_name: responseCredit.child_family_code,
          counter_account_number: "",
          counter_account_title: "",
          counter_bank: "",
          counter_branch: "",
          created_at: "",
          credit_amount: parseFloat(managementFeeTax),
          debit_amount: "0",
          description: "Daily SECP Fee",
          doc_type: "",
          entered_by: "",
          evidence: "",
          family_code: responseCredit.family_code,
          family_name: responseCredit.family_name,
          fund_code: fund,
          gl_code: responseCredit.gl_code,
          gl_name: responseCredit.gl_name,
          instruction_date: "",
          instrument_by: "",
          instrument_date: "",
          instrument_no: "",
          instrument_type: "",
          main_family_code: responseCredit.main_family_code,
          main_family_name: responseCredit.main_family_name,
          realized: "true",
          realized_by: "",
          realized_date: "",
          sub_family_code: responseCredit.sub_family_code,
          sub_family_name: responseCredit.sub_family_name,
          period: sessionStorage.getItem("active_period") || "",
        }
      );
    }

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.fund_code = fund;
      fields.net_amount = netAmount.toString();
      fields.payment_type = "Payment";

      fields.instruction_date = moment(date).format("YYYY-MM-DD");
      fields.execution_date = moment(date).format("YYYY-MM-DD");
      fields.executed_date = moment(date).format("YYYY-MM-DD");
      fields.instrument_date = moment(date).format("YYYY-MM-DD");
      fields.realized_date = moment(date).format("YYYY-MM-DD");
      fields.sale_date = moment(date).format("YYYY-MM-DD");
      fields.issue_date = moment(date).format("YYYY-MM-DD");
      fields.conversion_date = moment(date).format("YYYY-MM-DD");
      fields.maturity_date = moment(date).format("YYYY-MM-DD");
      fields.redemption_date = moment(date).format("YYYY-MM-DD");
      fields.settlement_date = moment(settlementDate).format("YYYY-MM-DD");
      fields.last_coupon_date = moment(date).format("YYYY-MM-DD");
      fields.next_coupon_date = moment(date).format("YYYY-MM-DD");

      if (fields.ledgers.length > 1) {
        try {
          // const navCheck = await validateFundNav(
          //   sessionStorage.getItem("email") || "",
          //   amc_code,
          //   fields?.fund_code,
          //   fields?.settlement_date
          // );

          // if (navCheck?.status === 200 && navCheck?.data?.status !== 200) {
          //   toast.error(navCheck?.data?.message);
          //   setLoading(false);
          //   return;
          // }
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            toast.success(`Transaction added. ID: ${result.data.txn_id}`);
            setSendingLedgers([]);

            setTimeout(() => {
              history.goBack();
            }, 1000);
          }
        } catch (error) {
          console.log("ERROR => ", error);
          setLoading(false);
        }
      }
    }

    setProfitRateConfirmed(true);
    // setLoading(false);
  };

  const handleDelete = (id) => {
    setDeleting(true);
    setTableData(
      tableData.filter((data) => {
        return data.id !== id;
      })
    );

    toast.info("Expense Removed!!!");
    setTimeout(() => setDeleting(false), 100);
  };

  const getLedgerInfo = async (email, ledger_code, fund_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code, fund_code);
    return result.data.data;
  };

  const handleTaxChange = () => {
    //calculation in let, value in set

    trusteeFeeTax = parseFloat(trusteeFee) * (parseFloat(taxRate) / 100);
    setTrusteeFeeTax(trusteeFeeTax);
  };

  const handleManagementTaxChange = () => {
    //calculation in let, value in set

    let manFeeTax =
      parseFloat(managementFee) * (parseFloat(managementTaxRate) / 100);
    setManagementFeeTax(manFeeTax);
  };

  useEffect(() => {
    if (trusteeFee && taxRate) {
      handleTaxChange();
    } else {
      setTrusteeFeeTax(0);
    }
  }, [taxRate, trusteeFee]);

  useEffect(() => {
    if (managementFee && managementTaxRate) {
      handleTaxChange();
    } else {
      setManagementFeeTax(0);
    }
  }, [managementTaxRate, managementFee]);

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund && selectedFund.nature === "Special Managed Account"
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code}) - SMA`,
        }
      : selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <ToastContainer limit={1} />
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        // history.replace("/admin/create-transaction");
                        //history.replace(`/admin/transactions-accounts`);
                        history.goBack();
                      }}
                    ></i>
                    {/* Daily Trustee Fee */}
                    Post NAV payments accrual
                  </h4>
                </div>
              </div>
              <Row>
                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Fund Details</h4>
                    </div>
                    <div className="card-body">
                      <div className="form-group" data-tip="Select Fund">
                        Fund Name*
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <Select
                            disabled={false}
                            value={selectedFundName}
                            label={fundCheck ? "No Fund Found" : "Select Fund"}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setFund(e?.value);
                              setLoading(false);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropdown()}
                            styles={customStyles}
                          />
                          // <select
                          //   className={"form-control w-100 "}
                          //   value={fund}
                          //   disabled={false}
                          //   onChange={(e) => {
                          //     setLoading(true);
                          //     setTableData([]);
                          //     setFund(e.target.value);

                          //     setLoading(false);
                          //   }}
                          // >
                          //   <option value="" defaultChecked hidden>
                          //     {fundCheck ? "No Fund Found" : "Select Fund"}
                          //   </option>
                          //   {renderFundsDropdown()}
                          // </select>
                        )}
                      </div>

                      <div className="form-group" data-tip="Select Date">
                        {/* Select NAV Date */}
                        Select Net Assets Date
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <input
                            id="input_Data"
                            className="form-control input_date"
                            type="date"
                            value={date}
                            defaultValue={date}
                            onChange={(e) => {
                              setLoading(true);
                              setTableData([]);
                              setDate(e.target.value);
                              setSettlementDate(e.target.value);
                              // setSettlementDate(
                              //   moment(e.target.value, "YYYY-MM-DD")
                              //     .add(1, "days")
                              //     .format("YYYY-MM-DD")
                              // );
                              setLoading(false);
                            }}
                            disabled={!fund}
                          />
                        )}
                      </div>

                      {/* <div className="form-group" data-tip="Select Date">
                        Settlement Date
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <input
                            id="input_Data"
                            className="form-control input_date"
                            type="date"
                            min={date}
                            value={settlementDate}
                            defaultValue={settlementDate}
                            onChange={(e) => {
                              setSettlementDate(e.target.value);
                            }}
                            disabled={Boolean(Loading) || !fund || !date}
                          />
                        )}
                      </div> */}

                      <div className="form-group">
                        Current Net Assets
                        {accFundLoading ? (
                          <div className="form-control w-100">
                            <i className="fa fa-spinner fa-spin fa-1x"></i>
                          </div>
                        ) : (
                          <input
                            type="text"
                            readOnly
                            value={numberWithCommas(
                              netAssets ? netAssets?.toFixed(4) : 0
                            )}
                            className="form-control text-right"
                          />
                        )}
                      </div>

                      <Row>
                        <div className="col-md-6">
                          <button
                            className="btn btn-default"
                            disabled={Boolean(Loading) || !fund || !date}
                            onClick={() => {
                              setLoading(true);
                              handleLoadSlabAsset();
                            }}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Load</span>
                            )}
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Trustee Fee Details</h4>
                    </div>
                    <div className="card-body">
                      {
                        // trusteeSpecificLedger.map((ledger, indexMain) => {
                        //   return ledger.slabs.map((slab, indexSub) => {

                        JSON.parse(
                          allFunds.find((item) => item.symbol_code == fund)
                            ?.fee_slab || "[]"
                        )?.map((slab, index) => {
                          if (
                            parseFloat(netAssets) > slab.amountFrom &&
                            parseFloat(netAssets) < slab.amountTo
                          ) {
                            // let trusteeFeeCal =
                            //   (parseFloat(netAssets) * (slab.rate / 100) +
                            //     slab.fixedAmmount) /
                            //   365;

                            // let trusteeFeeTax = trusteeFee * (taxRate / 100);

                            return (
                              <>
                                <Row>
                                  <Col md="6">
                                    <div
                                      className="form-group"
                                      key={slab.amountTo}
                                    >
                                      Slab From:
                                      <input
                                        type="text"
                                        readOnly
                                        value={numberWithCommas(
                                          slab.amountFrom
                                        )}
                                        className="form-control text-right"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div
                                      className="form-group"
                                      key={slab.amountTo}
                                    >
                                      Slab To: &nbsp;
                                      <input
                                        type="text"
                                        readOnly
                                        value={numberWithCommas(slab.amountTo)}
                                        className="form-control text-right"
                                      />
                                      {/* </span> */}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col md="6">
                                    <div className="form-group">
                                      Applicable Rate
                                      <input
                                        type="text"
                                        readOnly
                                        value={numberWithCommas(slab.rate)}
                                        className="form-control text-right"
                                      />
                                    </div>
                                  </Col>
                                  <Col md="6">
                                    <div className="form-group">
                                      Fixed Amount
                                      <input
                                        type="text"
                                        readOnly
                                        value={numberWithCommas(
                                          slab.fixedAmmount
                                        )}
                                        className="form-control text-right"
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="form-group ">
                                  Trustee Fee
                                  <input
                                    type="text"
                                    readOnly
                                    value={numberWithCommas(
                                      parseFloat(trusteeFee).toFixed(2)
                                    )}
                                    className="form-control text-right"
                                  />
                                </div>

                                <div className="form-group ">
                                  Trustee Fee Tax Rate
                                  <input
                                    type="number"
                                    min="1"
                                    max="100"
                                    value={taxRate}
                                    className="form-control text-right"
                                    disabled={disableProfit}
                                    onChange={(e) => {
                                      if (e.target.value.length > 5) {
                                        return;
                                      }
                                      if (parseFloat(e.target.value) > 100.0) {
                                        return;
                                      }
                                      if (parseFloat(e.target.value) < 0.0) {
                                        return;
                                      }
                                      taxRate = parseFloat(e.target.value);
                                      setTaxRate(taxRate);
                                      // handleTaxChange();
                                    }}
                                  />
                                </div>

                                <div className="form-group ">
                                  Trustee Fee Tax
                                  <input
                                    type="text"
                                    readOnly
                                    value={numberWithCommas(
                                      trusteeFeeTax.toFixed(2)
                                    )}
                                    className="form-control text-right"
                                  />
                                </div>
                              </>
                            );
                          } else {
                            return;
                          }
                        })
                        //})
                      }
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Management Fee Details</h4>
                    </div>
                    <div className="card-body">
                      <>
                        <div className="form-group ">
                          Management Fee
                          <input
                            type="text"
                            readOnly
                            value={numberWithCommas(
                              parseFloat(managementFee).toFixed(2)
                            )}
                            className="form-control text-right"
                          />
                        </div>

                        <div className="form-group ">
                          Management Fee Tax Rate
                          <input
                            type="number"
                            min="1"
                            max="100"
                            value={managementTaxRate}
                            className="form-control text-right"
                            disabled={disableProfit}
                            onChange={(e) => {
                              if (e.target.value.length > 5) {
                                return;
                              }
                              if (parseFloat(e.target.value) > 100.0) {
                                return;
                              }
                              if (parseFloat(e.target.value) < 0.0) {
                                return;
                              }
                              let rate = parseFloat(e.target.value);
                              setManagementTaxRate(rate);
                              // handleTaxChange();
                            }}
                          />
                        </div>

                        <div className="form-group ">
                          Management Fee Tax
                          <input
                            type="text"
                            readOnly
                            value={numberWithCommas(
                              managementFeeTax.toFixed(2)
                            )}
                            className="form-control text-right"
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">SECP Fee Details</h4>
                    </div>
                    <div className="card-body">
                      <>
                        <div className="form-group ">
                          SECP Fee
                          <input
                            type="text"
                            readOnly
                            value={numberWithCommas(
                              parseFloat(secpFee).toFixed(2)
                            )}
                            className="form-control text-right"
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </Col>

                <Col md="4">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">
                        Accounting & Operational Fee Details
                      </h4>
                    </div>
                    <div className="card-body">
                      <>
                        <div className="form-group ">
                          Accounting & Operational Fee
                          <input
                            type="text"
                            readOnly
                            value={numberWithCommas(
                              parseFloat(accountingOperationalFee).toFixed(2)
                            )}
                            className="form-control text-right"
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : (tableData && tableData.length !== 0) ||
                (managementTableData && managementTableData.length > 0) ||
                (secpTableData && secpTableData.length > 0) ||
                (accountingOperationalTableData &&
                  accountingOperationalTableData.length > 0) ? (
                <Row>
                  <Col>
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title text-primary">
                          Affected Ledgers
                        </h4>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              {/* <tr>
                        <td colSpan={4}>
                          Fund Code: &nbsp; &nbsp;
                          {tableData[0]?.fund_code}
                        </td>
                        <td colSpan={5}>
                          AMC Code: &nbsp; &nbsp; {tableData[0]?.amc_code}
                        </td>
                      </tr> */}
                              <tr>
                                {/* <th>Sr.</th> */}
                                <th>Code</th>
                                <th>Name</th>
                                <th className="text-center">Debit Amount</th>
                                <th className="text-center">Credit Amount</th>
                                {/* <th>Half Yearly Fee</th>
                        <th>Daily Expense (Half Yearly)</th>
                        <th>Annual Fee</th>
                        <th>Daily Expense (Annual)</th>
                        <th>Total Expense</th>
                        <th>Action</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((data, index) => {
                                // let totalExpense = parseFloat(
                                //   data.daily_quarterly_expense +
                                //     data.half_yearly_expense +
                                //     data.yearly_expense
                                // ).toFixed(2);
                                return (
                                  <tr
                                    key={index}
                                    style={
                                      deleting
                                        ? {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(76,88,119,0.1)",
                                          }
                                        : {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(37,41,61,1)",
                                          }
                                    }
                                  >
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "4"
                                        ? !data.gl_name.includes("TAX")
                                          ? numberWithCommas(
                                              Number(trusteeFee || 0).toFixed(4)
                                            )
                                          : numberWithCommas(
                                              Number(
                                                trusteeFeeTax || 0
                                              ).toFixed(4)
                                            )
                                        : 0.0}
                                    </td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "3"
                                        ? data.gl_name.includes("TAX")
                                          ? numberWithCommas(
                                              Number(
                                                trusteeFeeTax || 0
                                              ).toFixed(4)
                                            )
                                          : numberWithCommas(
                                              Number(trusteeFee || 0).toFixed(4)
                                            )
                                        : 0.0}
                                    </td>

                                    {/* <td>{data.daily_quarterly_expense}</td>
                            <td>{data.half_yearly_fee}</td> */}
                                    {/* <td>{data.daily_half_yearly_expense}</td>

                            <td>{data.yearly_fee}</td>
                            <td>{data.daily_yearly_expense}</td>
                            <td>{data.total_expense}</td>
                            <td>
                              <i
                                className="tim-icons icon-simple-remove"
                                style={{ color: "red", cursor: "pointer" }}
                                // onClick={() => handleDelete(data.id)}
                              ></i>
                            </td> */}
                                  </tr>
                                );
                              })}

                              {managementTableData.map((data, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={
                                      deleting
                                        ? {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(76,88,119,0.1)",
                                          }
                                        : {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(37,41,61,1)",
                                          }
                                    }
                                  >
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "4"
                                        ? !data.gl_name.includes("SST")
                                          ? numberWithCommas(
                                              Number(
                                                managementFee || 0
                                              ).toFixed(4)
                                            )
                                          : numberWithCommas(
                                              Number(
                                                managementFeeTax || 0
                                              ).toFixed(4)
                                            )
                                        : 0.0}
                                    </td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "3"
                                        ? data.gl_name.includes("SST")
                                          ? numberWithCommas(
                                              Number(
                                                managementFeeTax || 0
                                              ).toFixed(4)
                                            )
                                          : numberWithCommas(
                                              Number(
                                                managementFee || 0
                                              ).toFixed(4)
                                            )
                                        : 0.0}
                                    </td>
                                  </tr>
                                );
                              })}

                              {secpTableData.map((data, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={
                                      deleting
                                        ? {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(76,88,119,0.1)",
                                          }
                                        : {
                                            background:
                                              theme == "light"
                                                ? ""
                                                : "rgba(37,41,61,1)",
                                          }
                                    }
                                  >
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "4"
                                        ? numberWithCommas(
                                            Number(secpFee || 0).toFixed(4)
                                          )
                                        : 0.0}
                                    </td>
                                    <td className="text-center">
                                      {String(data.gl_code)[0] === "3"
                                        ? numberWithCommas(
                                            Number(secpFee || 0).toFixed(4)
                                          )
                                        : 0.0}
                                    </td>
                                  </tr>
                                );
                              })}

                              {accountingOperationalTableData.map(
                                (data, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      style={
                                        deleting
                                          ? {
                                              background:
                                                theme == "light"
                                                  ? ""
                                                  : "rgba(76,88,119,0.1)",
                                            }
                                          : {
                                              background:
                                                theme == "light"
                                                  ? ""
                                                  : "rgba(37,41,61,1)",
                                            }
                                      }
                                    >
                                      {/* <td>{data.id}</td> */}
                                      <td>{data.gl_code}</td>
                                      <td>{data.gl_name}</td>
                                      <td className="text-center">
                                        {String(data.gl_code)[0] === "4"
                                          ? numberWithCommas(
                                              Number(
                                                accountingOperationalFee || 0
                                              ).toFixed(4)
                                            )
                                          : 0.0}
                                      </td>
                                      <td className="text-center">
                                        {String(data.gl_code)[0] === "3"
                                          ? numberWithCommas(
                                              Number(
                                                accountingOperationalFee || 0
                                              ).toFixed(4)
                                            )
                                          : 0.0}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                            {/* <tfoot>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total Amount</th>
                        <th>{totalBalance}</th>
                      </tr>
                    </tfoot> */}
                          </table>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              className="btn btn-primary"
                              disabled={Boolean(Loading)}
                              // onClick={() => handleCreate()}
                              onClick={() => handleDailyTrusteeFee()}
                            >
                              {Loading ? (
                                <>
                                  <span
                                    className="spinner-border login-txt spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="login-txt"> Loading...</span>
                                </>
                              ) : (
                                // <span>Save Trustee Fee</span>
                                <span>Save Fees</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyTrusteeFee;

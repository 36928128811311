import React from 'react';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { editChecklistData } from '../../../stores/services/checklist.service';
import { getAllRoles } from '../../../stores/services/role.service';
import Select from "react-select";
import { useSelector } from 'react-redux';

const EditCheckList = () => {

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const [serialNo, setSerialNo] = useState('');
  const [title, setTitle] = useState('');
  const email = sessionStorage.getItem('email') || '';
  const amc_code = sessionStorage.getItem('amc_code') || '';
  const [serialNoError, setSerialNoError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [Loading, setLoading] = useState(false);
  const [roleError, setRoleError] = useState('');
  const [role, setRole] = useState('');
  const [roleData, setRoleData] = useState<any>([]);

  React.useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await getAllRoles(email, amc_code);
        setRoleData(response.data.data);
      } catch (error) { }
    };
    fetchRole();
  }, []);

  // const renderRoleDropdown = () => {
  //   return (
  //     roleData &&
  //     roleData.map((item, index) => {
  //       return (
  //         <option key={index} value={item.role_name.replace('ROLE_','')}>
  //           {item.role_name.replace('ROLE_','')}
  //         </option>
  //       );
  //     })
  //   );
  // };

  const renderRoleDropdown = () => {
    return (
      roleData &&
      roleData.map((item, index) => {
        return {
          value: item.role_name.replace('ROLE_', ''),
          label: item.role_name.replace('ROLE_', '')
        }
      })
    );
  };

  const [code, setCode] = useState('');
  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('checklistObj') || '');
    setSerialNo(obj.serial_no);
    setTitle(obj.message);
    setRole(obj.role);
    setCode(obj.code);
  }, []);

  const validate = () => {
    let serialErr = '';
    let titleErr,
      roleErr = '';
    serialNo.trim() === '' ? (serialErr = 'Required') : (serialErr = '');
    title.trim() === '' ? (titleErr = 'Required') : (titleErr = '');
    role.trim() === '' ? (roleErr = 'Required') : (roleErr = '');
    if (serialErr || titleErr || roleErr) {
      setSerialNoError(serialErr);
      setTitleError(titleErr);
      setRoleError(roleErr);
      return false;
    } else {
      return true;
    }
  };
  const AddChecklist = async () => {
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      try {
        const response = await editChecklistData(email, serialNo, title, role, code, amc_code);
        if (response.data.status === 200) {
          setTitle('');
          setRole('');
          setSerialNo('');
          toast.success(response.data.message);
          sessionStorage.removeItem('checklistObj');
          setTimeout(() => {
            history.replace(`/admin/check-list`);
          }, 2000);
        } else {
          toast.error(response.data.message);
        }
      } catch (error: any) {
        if (error.response.data.statusCode) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response.data.message);
        }
      }
      setLoading(false);
    }
  };

  const selectedRole = React.useMemo(() => {
    return role
      ? {
        value: role,
        label: role
      }
      :
      null;
  }, [role]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="card">
                <div className="card-header">
                  <div className="title-row">
                    <h4 className="card-title"> <i className="fa fa-angle-left mr-1 pointer" onClick={() => {
                      sessionStorage.removeItem('checklistObj');
                      history.replace('/admin/check-list')
                    }}></i>Edit - Check List</h4>
                    <Link
                      to="/admin/check-list"
                      className="btn btn-primary btn-sm ml-auto"
                      replace
                    >
                      <i className="fa fa-eye"></i>&nbsp;View All
                    </Link>
                  </div>
                </div>
                <div className="card-body">

                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          placeholder="Title"
                          className="form-control"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            setTitleError('');
                          }}
                        />
                        {titleError ? (
                          <p className="error-labels error-message2">
                            {titleError}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Role</label>
                        <Select
                          value={selectedRole}
                          label={"Select Role"}
                          onChange={(e) => {
                            setRole(e?.value);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldBlockScroll={true}
                          isClearable={true}
                          isSearchable={true}
                          name="color"
                          options={renderRoleDropdown()}
                          styles={customStyles}
                        />
                        {/* <select
                        className="form-control w-100"
                        value={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {' '}
                          Select Role
                        </option>
                        {renderRoleDropdown()}
                      </select> */}
                        {roleError ? (
                          <p className="error-labels error-message">
                            {roleError}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <div className="form-group">
                        <label>Serial.# / Sequence # (Number)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Serial.# / Sequence # (Number)"
                          value={serialNo}
                          onChange={(e) => {
                            setSerialNo(e.target.value);
                            setSerialNoError('');
                          }}
                        />
                        {serialNoError ? (
                          <p className="error-labels error-message2">
                            {serialNoError}
                          </p>
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="d-flex mt-3">
                    <div className="form-group">
                      <button
                        className="btn btn-default"
                        onClick={() => {
                          sessionStorage.removeItem('checklistObj');
                          history.replace('/admin/check-list');
                        }}
                      >
                        <span>Cancel</span>
                      </button>

                      <button
                        className="btn btn-primary ml-3"
                        onClick={AddChecklist}
                        disabled={Boolean(Loading)}
                      >
                        {Loading ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Update</span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditCheckList;

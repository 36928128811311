import axios from "axios";
import Config from "../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getNavDataForCalculation = async (
  email: string,
  amc_code: string,
  fund_code: string,
  date: string
) => {
  const url = `${Config.baseUrl}/dailynavgeneration/?email=${email}&amc=${amc_code}&fund=${fund_code}&date=${date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getNavDataForCalculation(email, amc_code, fund_code, date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const getNavReport = async (email: string, fund_code: string, date: string) => {
  // http://124.109.39.158:5223/api/fund/get-daily-nav/?email=amc.editor99@yopmail.com&fund=DEMOFUND&nav_date=2022-06-23
  const url = `${Config.baseUrl}/fund/get-daily-nav/?email=${email}&fund=${fund_code}&nav_date=${date}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getNavReport(email, fund_code, date);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export { getNavDataForCalculation, getNavReport };

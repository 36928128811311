import React, { useEffect, useState, useContext } from "react";
// import Header from './../components/Header';
import { Container, Row, Col, FormGroup, Label, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import Menu from "@material-ui/core/Menu";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { BsTable } from "react-icons/bs";
import {
  getAllPendingTransactions,
  getCaptype,
  getTxnCatagory,
  searchTransactions,
} from "./../stores/services/transactions.service";
import {
  getFundByAmc,
  validateFundNav,
} from "../stores/services/funds.service";
import { getAllBanks } from "../stores/services/bank.service";
import moment from "moment";
import { numberWithCommas } from "../utils/customFunction";
import ViewTransaction from "./view-beta-txn";
import { addMegaTransaction } from "../stores/services/beta-transaction.service";
import { getCounterAccount } from "./field";
import { ThemeContext } from "../contexts/ThemeContext";
import Select from "react-select";

const BetaTxnType = (props) => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );

  const context = useContext(ThemeContext);
  const StatusOptions = [
    {
      value: "CREATED",
      label: "Created",
    },
    {
      value: "COMPLETED",
      label: "Completed",
    },
    {
      value: "REJECTED",
      label: "Rejected",
    },
    {
      value: "REJECTED-AMC",
      label: "Rejected-Amc",
    },
  ];
  const clearTransactiionHistory = () => {
    sessionStorage.removeItem("rejectedTxObj");
    sessionStorage.removeItem("rejectedTxName");
    sessionStorage.removeItem("rejectedFlag");
    sessionStorage.removeItem("rejectedAmc");
  };
  // let txnTypeCatagory = JSON.parse(sessionStorage.getItem("txnTypeCatagory") || "");
  let txnTypeCatagory: any = [];
  txnTypeCatagory = useSelector((state: any) => state.txnTypeCategories);
  const PATH =
    window.location.href?.split("/")[
      window.location.href?.split("/")?.length - 1
    ];
  const [loading, setLoading] = useState(false);
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  let [transactions, setTransactions] = useState<any>([]);
  const [totalDocs, setTotalDocs] = useState("");
  let [status, setStatus] = useState("");
  const [trx_history, setTrxHistory] = useState([]);
  const [viewChecklist, setViewCheckList] = useState(false);
  const [trx_checklist, setTrxCheckList] = useState([]);
  let [paginationArr, setPaginationArr] = useState([""]);
  let [currentPage, setCurrentPage] = useState("");
  let [activePage, setActivePage] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [resLoading, setResLoading] = useState(false);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [fund, setFund] = useState("");

  const [togglePagination, setTogglePagination] = useState(false);
  let [searchValue, setSearchValue] = useState("");
  const [bankData, setBankData] = useState<any>([]);
  const [note, setNote] = useState("");
  const [flagForSecutities, setFlagForSecutities] = useState(false);
  const [securites, setSecurites] = useState<any>([]);
  const [viewTaxModal, setTaxModal] = useState(false);
  const [totalTax, setTotalTax] = useState<any>([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewTxnModal, setViewTxnModal] = useState(false);
  const [data, setData] = useState<any>([]);
  const [currentTxnTypes, setCurrentTxnTypes] = useState("");
  const [createTransactionModal, setCreateTransactionModal] = useState(false);
  const [view, setView] = useState(false);
  const [fields, setFields] = useState<any>({});
  const [viewBankBalanceModal, setViewBankBalanceModal] = useState(false);
  const [underStand, setUnderStand] = useState(false);
  const [bankBalance, setBankBalance] = useState("");
  const [realizedCheck, setRealizedCheck] = useState(false);
  const [headLabel, setHeadLabel] = React.useState("");
  let counterAccounter = getCounterAccount(headLabel);
  const [termDeposit, setTermDeposit] = useState<any>([]);
  const [ledgerData, setLedgerData] = useState<any>([]);
  const [LoadingReverseButton, setLoadingReverseButton] = useState(false);
  const [
    reverseTransactionConfirmationModal,
    setReverseTransactionConfirmationModal,
  ] = useState(false);

  let txnTypes = useSelector((state: any) => state.txnTypes);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  useEffect(() => {
    getFunds();
    getTransactions("true");
  }, [fund_code]);

  useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    //  setLoading(true)
    if (txnTypeCatagory?.length > 0) {
      transactions.length = 0;
      setTransactions([]);
      setTotalDocs("");
      setStatus("");
      setTogglePagination(false);
      setSearchValue("");
      setActivePage("");
      setFund("");
      getTransactions("true");
    }

    //  setTimeout(()=>{
    //    setLoading(false)

    //  },500)
  }, [PATH, txnTypeCatagory]);

  React.useEffect(() => {
    activePage != "" && getTransactions();
  }, [activePage]);

  const txnTypesFilter = () => {
    let current_txn_types = "";
    if (PATH == "transactions-funds-management") {
      txnTypeCatagory[1].fundManagementTxn
        .concat(txnTypeCatagory[5].portfolio)
        .concat(txnTypeCatagory[3].feePaymentTxn)
        .map((item) => {
          current_txn_types += item.api_name + ",";
        });
    } else if (PATH == "transactions-unit-management") {
      txnTypeCatagory?.[0].unitManagementTxn?.map((item) => {
        current_txn_types += item.api_name + ",";
      });
    } else if (PATH == "transactions-trade-settlement") {
      txnTypeCatagory[2].investmentTxn.map((item) => {
        current_txn_types += item.api_name + ",";
      });
    } else if (PATH == "transactions-accounts") {
      txnTypeCatagory[4].vouchersTxn.map((item) => {
        current_txn_types += item.api_name + ",";
      });
    }

    setCurrentTxnTypes(
      current_txn_types?.slice(0, current_txn_types?.length - 1)
    );

    return current_txn_types?.slice(0, current_txn_types?.length - 1);
  };

  async function getTransactions(resetData: string = "") {
    if (togglePagination != true || resetData == "true") {
      setResLoading(true);
      console.log("FUND CODE => ", fund_code);

      await getAllPendingTransactions(
        sessionStorage.getItem("email") || "",
        resetData == "true" ? "" : status,
        resetData == "true" ? "" : searchValue,
        "",
        resetData == "true" ? "1" : activePage,
        txnTypesFilter(),
        fund_code?.length > 0 ? fund_code : "",
        amc_code
      )
        .then((response) => {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response?.data?.page_info?.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response?.data?.data?.length === 10
            // &&
            // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
          ) {
            pageArr.push(response?.data?.page_info?.next_page_number);
          }
          setPaginationArr(pageArr);
          setTransactions(
            resetData == "true"
              ? [...response.data.data]
              : [...transactions, ...response.data.data]
          );
          setTotalDocs(response?.data?.page_info?.totalDocs);
          setResLoading(false);
        })
        .catch((err) => {
          setResLoading(false);
        });
      setResLoading(false);
    } else {
      if (searchValue !== "") {
        searchValue = "TXN_" + searchValue;
      }
      setResLoading(true);
      await searchTransactions(
        sessionStorage.getItem("email") || "",
        amc_code,
        fund ? fund : fund_code?.length > 0 ? fund_code : "",
        status === "-1" ? "" : status,
        searchValue,
        activePage,
        txnTypesFilter()
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions([...transactions, ...response.data.data]);
            setTotalDocs(response.data.page_info.totalDocs);
          } else {
            setTotalDocs("");
            setTransactions([]);
          }
          setResLoading(false);
        })
        .catch((err) => {
          setResLoading(false);
        });
      setResLoading(false);
    }
  }

  const getFunds = async () => {
    try {
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  // useEffect(() => {

  //   getFunds();
  // }, []);

  // Fund Type Drop Down
  // const renderFundsDropDown = () => {
  //   return allFunds.map((item: any, index) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index) => {
      return {
        value: item?.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  const searchTransactionsByAMCAndFund = async (
    statusVar = "",
    searchVar = "",
    amcCodeVar = "",
    fundCodeVar = ""
  ) => {
    setTogglePagination(true);
    return new Promise(async (resolve, reject) => {
      if (searchVar !== "") {
        searchVar = "TXN_" + searchVar;
      }
      setResLoading(true);
      setSearchLoading(true);
      await searchTransactions(
        sessionStorage.getItem("email") || "",
        amc_code,
        fundCodeVar ? fundCodeVar : fund_code?.length > 0 ? fund_code : "",
        statusVar === "-1" ? "" : statusVar,
        searchVar,
        activePage,
        currentTxnTypes ? currentTxnTypes : txnTypesFilter()
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            // let pageArr = paginationArr

            paginationArr = paginationArr.filter(function (item) {
              return item == "";
            });
            let pageArr = paginationArr;
            let existingPageIndex = paginationArr.findIndex(
              (x) => x === response.data.page_info.next_page_number
            );
            if (
              existingPageIndex === -1 &&
              response.data.data.length === 10
              // &&
              // 10 * pageArr.length !== parseInt(response.data.page_info.total_count)
            ) {
              pageArr.push(response.data.page_info.next_page_number);
            }
            setPaginationArr(pageArr);
            setTransactions([...response.data.data]);
            setTotalDocs(response.data.page_info.totalDocs);
          } else {
            setTotalDocs("");
            setTransactions([]);
          }
          setResLoading(false);
          setSearchLoading(false);

          resolve("Success");
        })
        .catch((err) => {
          setResLoading(false);
          setSearchLoading(false);
          reject("Error");
        });
      setResLoading(false);
      setSearchLoading(false);
    });
  };

  const clearFilters = async (
    statusVar = undefined as any,
    searchVar = undefined as any,
    pageNumber: string = ""
  ) => {
    return new Promise(async (resolve, reject) => {
      // setLoading(true);
      setResLoading(true);
      setClearLoading(true);
      await getAllPendingTransactions(
        sessionStorage.getItem("email") || "",
        statusVar === undefined ? status : statusVar,
        searchVar === undefined ? searchValue : searchVar,
        "",
        activePage,
        currentTxnTypes ? currentTxnTypes : txnTypesFilter(),
        fund_code?.length > 0 ? fund_code : "",
        amc_code
      )
        .then((response) => {
          let pageArr = paginationArr;
          let existingPageIndex = paginationArr.findIndex(
            (x) => x === response.data.page_info.next_page_number
          );
          if (
            existingPageIndex === -1 &&
            response.data.data.length === 10 &&
            10 * pageArr.length !==
              parseInt(response.data.page_info.total_count)
          ) {
            pageArr.push(response.data.page_info.next_page_number);
          }
          setPaginationArr(pageArr);
          setTransactions(response.data.data);
          setTotalDocs(response.data.page_info.totalDocs);
          setResLoading(false);
          setClearLoading(false);
          resolve("Success");
          setTogglePagination(false); // false search pagination.
        })
        .catch((err) => {
          setResLoading(false);
          setClearLoading(false);
          if (err.response !== undefined) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Request Failed!");
          }
          reject("Error");
        });
      setResLoading(false);
      setClearLoading(false);
    });
  };
  React.useEffect(() => {
    const fetchBank = async () => {
      try {
        const bankResponse = await getAllBanks(email, "", "", amc_code);
        setBankData(bankResponse.data.data);
      } catch (error) {}
    };
    fetchBank();
  }, []);

  const bankName = (bankcode) => {
    const bankdata = bankData.filter((item: any, index: string) => {
      return item.bank_code === bankcode;
    });
    if (bankdata.length > 0) return bankdata[0].bank_name;
    else return bankcode;
  };

  const titleCase = (value: string) => {
    let sentence = value.toLowerCase().split("_");
    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");
  };

  const viewCheckListPopup = () => {
    switch (viewChecklist) {
      case true:
        return (
          <Modal className="" dialogClassName="modal90w" show={true} size="xl">
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewCheckList(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Approval Check List </h6>
            </div>
            <div className="modal-body">
              <div>
                <div className={"checlist-scroll"}>
                  {trx_checklist.map((val: any, ind: any) => {
                    return (
                      <Row key={ind} className="pt-3">
                        <Col md="4">
                          <text className="text-primary">{val.serial_no}.</text>{" "}
                          <text>{val.message}</text>
                        </Col>
                        <Col md="8">
                          <div className="row">
                            <div className="col-sm-3">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    checked={val.value == "compliant"}
                                    type="checkbox"
                                    value="compliant"
                                    readOnly
                                  />
                                  <span className="form-check-sign" />
                                  Compliant
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="col-sm-3">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    id="unlisted"
                                    checked={val.value == "na"}
                                    value="na"
                                    readOnly
                                  />
                                  <span className="form-check-sign" />N /A
                                </Label>
                              </FormGroup>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  placeholder="Comments"
                                  value={val.comment}
                                  className="form-control"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </div>

                {note !== "" && (
                  <Row>
                    <div className="col-md-12">
                      <label>Reason of Reject</label>
                      <div className="form-group">
                        <textarea
                          placeholder="Reason"
                          className={"form-control w-100"}
                          value={note}
                          readOnly
                        ></textarea>
                      </div>
                    </div>
                  </Row>
                )}

                <Row>
                  <Col>
                    <div className=" ">
                      <button
                        className="btn btn-primary mt-3"
                        onClick={() => {
                          setViewCheckList(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderSecuritiesModalPopup = () => {
    switch (flagForSecutities) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setFlagForSecutities(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setFlagForSecutities(false);
                  // clearTaxobject();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Security </h6>
            </div>

            <div className="modal-body">
              <div className="">
                {securites.length > 0 && (
                  <div className="">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Market</th>
                          <th>Symbol</th>
                          <th>Quantity</th>
                          <th>Avg Rate</th>
                          <th>Gross Amount</th>
                          <th>Deduction</th>
                          <th>Addition</th>
                          <th>Net Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderPsxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const renderPsxData = () => {
    return securites.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.type}</td>
          <td>{items.market}</td>
          <td>{items.symbol}</td>
          <td>{items.quantity}</td>
          <td>{items.avg_rate}</td>
          <td>{items.gross_amount}</td>
          <td>{items.deductions}</td>
          <td>{items.additions}</td>
          <td>{items.net_amount}</td>
        </tr>
      );
    });
  };

  const renderTaxData = () => {
    return totalTax.map((items: any, index: number) => {
      return (
        <tr key={index}>
          <td>{items.name}</td>
          <td>{items.amount}</td>
          <td>{items.calculatedAmount}</td>
        </tr>
      );
    });
  };
  const renderTaxPopup = () => {
    switch (viewTaxModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setTaxModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setTaxModal(false);
                  // clearTaxobject();
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Txn Charges </h6>
            </div>

            <div className="modal-body">
              <div className="">
                {totalTax.length > 0 && (
                  <div className="">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Tax Name</th>
                          <th>Tax Amount</th>
                          <th>Calculated Amount</th>
                        </tr>
                      </thead>
                      <tbody>{renderTaxData()}</tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const CapTxnType = (type: any) => {
    let i, txType;
    for (i = 0; i < txnTypes.length; i++) {
      if (txnTypes[i].api_name === type) {
        txType = txnTypes[i].dis_name;
        break;
      }
    }
    return txType;
  };

  const fetchTxnTypeCategory = async () => {
    try {
      const response = await getTxnCatagory("");
      txnTypeCatagory = response.data.txn_catagory;
      // dispatch(GetTxnTypeCategories(response.data.txn_catagory));
    } catch (error) {}
  };
  const renderTransactionTypes = () => {
    switch (createTransactionModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            onHide={() => {
              setCreateTransactionModal(false);
            }}
          >
            <div className={context.theme}>
              <div className="modal-header  ">
                <button
                  aria-hidden={true}
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => {
                    setCreateTransactionModal(false);
                  }}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
                <h6 className="title title-up">Create new transaction</h6>
              </div>
              <Row className="pt-4  ">
                <Col md="12">
                  <Container fluid>
                    {txnTypeCatagory.length > 0 ? (
                      PATH == "transactions-unit-management" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                                  Unit Management
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[0].unitManagementTxn
                                  ?.filter(
                                    (item) =>
                                      item.dis_name != "Conversion In" &&
                                      item.dis_name != "Conversion Out"
                                  )
                                  .map((item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                          if (
                                            item.api_name === "unitconversion"
                                          ) {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              "Payment"
                                            );
                                          } else {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                          }
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/unit-management-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-accounts" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                                  Accounting
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[4].vouchersTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/vouchers/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-trade-settlement" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                                  Investments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[2].investmentTxn.map(
                                  (item: any, index: number) => {
                                    if (
                                      item.api_name ==
                                      "right_share_subscription"
                                    ) {
                                      return (
                                        <MenuItem
                                          style={{
                                            minHeight: "0px", // mobile view
                                          }}
                                          key={index}
                                          className="box-element"
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                            clearTransactiionHistory();
                                            history.push(
                                              `/admin/right-share-subscription/${item.api_name}`
                                            );
                                          }}
                                        >
                                          <span className="text-hover text-silver">
                                            <span
                                              className={item.api_name + " ico"}
                                            ></span>
                                            <i className="fa fa-angle-right"></i>{" "}
                                            {item.dis_name}
                                          </span>
                                        </MenuItem>
                                      );
                                    } else {
                                      return (
                                        <MenuItem
                                          style={{
                                            minHeight: "0px", // mobile view
                                          }}
                                          key={index}
                                          className="box-element"
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                            clearTransactiionHistory();
                                            history.push(
                                              `/admin/investment-transaction/${item.api_name}`
                                            );
                                          }}
                                        >
                                          <span className="text-hover text-silver">
                                            <span
                                              className={item.api_name + " ico"}
                                            ></span>
                                            <i className="fa fa-angle-right"></i>{" "}
                                            {item.dis_name}
                                          </span>
                                        </MenuItem>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "transactions-funds-management" ? (
                        <Row>
                          <Col>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                                      Fund Management
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[1].fundManagementTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/fund-management-transaction/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    {/* <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.push(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                                      Portfolio
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[5].portfolio.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/portfolio/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                                  Fee Payments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[3].feePaymentTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/fee-payment-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : PATH == "create-transaction" ? (
                        <Row>
                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-chart-pie-36 mr-2 text-primary"></i>{" "}
                                  Unit Management
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[0].unitManagementTxn
                                  ?.filter(
                                    (item) =>
                                      item.dis_name != "Conversion In" &&
                                      item.dis_name != "Conversion Out"
                                  )
                                  .map((item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        key={index}
                                        className="box-element"
                                        onClick={() => {
                                          if (
                                            item.api_name === "unitconversion"
                                          ) {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              "Payment"
                                            );
                                          } else {
                                            sessionStorage.setItem(
                                              "payment_type",
                                              `${item.payment_type}`
                                            );
                                          }
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/unit-management-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  })}
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-chart-bar-32 mr-2 text-primary"></i>{" "}
                                      Investments
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[2].investmentTxn.map(
                                      (item: any, index: number) => {
                                        if (
                                          item.api_name ==
                                          "right_share_subscription"
                                        ) {
                                          return (
                                            <MenuItem
                                              style={{
                                                minHeight: "0px", // mobile view
                                              }}
                                              key={index}
                                              className="box-element"
                                              onClick={() => {
                                                sessionStorage.setItem(
                                                  "payment_type",
                                                  `${item.payment_type}`
                                                );
                                                clearTransactiionHistory();
                                                history.push(
                                                  `/admin/right-share-subscription/${item.api_name}`
                                                );
                                              }}
                                            >
                                              <span className="text-hover text-silver">
                                                <span
                                                  className={
                                                    item.api_name + " ico"
                                                  }
                                                ></span>
                                                <i className="fa fa-angle-right"></i>{" "}
                                                {item.dis_name}
                                              </span>
                                            </MenuItem>
                                          );
                                        } else {
                                          return (
                                            <MenuItem
                                              style={{
                                                minHeight: "0px", // mobile view
                                              }}
                                              key={index}
                                              className="box-element"
                                              onClick={() => {
                                                sessionStorage.setItem(
                                                  "payment_type",
                                                  `${item.payment_type}`
                                                );
                                                clearTransactiionHistory();
                                                history.push(
                                                  `/admin/investment-transaction/${item.api_name}`
                                                );
                                              }}
                                            >
                                              <span className="text-hover text-silver">
                                                <span
                                                  className={
                                                    item.api_name + " ico"
                                                  }
                                                ></span>
                                                <i className="fa fa-angle-right"></i>{" "}
                                                {item.dis_name}
                                              </span>
                                            </MenuItem>
                                          );
                                        }
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      {" "}
                                      <i className="tim-icons icon-credit-card mr-2 text-primary"></i>{" "}
                                      Fund Management
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[1].fundManagementTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/fund-management-transaction/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    {/* <MenuItem
                        style={{
                          minHeight: "0px", // mobile view
                        }}
                        className="box-element"
                        key="4"
                        onClick={() => {
                          history.push(`/admin/fund-dividend-payout`);
                          // sessionStorage.setItem('payment_type', `${item.payment_type}`);
                          // clearTransactiionHistory();
                        }}
                      >
                        <span className="text-hover text-silver">
                          <span className={" ico"}></span>
                          <i className="fa fa-angle-right"></i>{" "}
                          {"Fund Dividend Payout"}
                        </span>
                      </MenuItem> */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <div className="card">
                              <div className="card-header">
                                <h4 className="card-title">
                                  {" "}
                                  <i className="tim-icons icon-money-coins mr-2 text-primary"></i>{" "}
                                  Fee Payments
                                </h4>
                              </div>
                              <div className="card-body">
                                {txnTypeCatagory[3].feePaymentTxn.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        style={{
                                          minHeight: "0px", // mobile view
                                        }}
                                        className="box-element"
                                        key={index}
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "payment_type",
                                            `${item.payment_type}`
                                          );
                                          clearTransactiionHistory();
                                          history.push(
                                            `/admin/fee-payment-transaction/${item.api_name}`
                                          );
                                        }}
                                      >
                                        <span className="text-hover text-silver">
                                          <span
                                            className={item.api_name + " ico"}
                                          ></span>
                                          <i className="fa fa-angle-right"></i>{" "}
                                          {item.dis_name}
                                        </span>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-components mr-2 text-primary"></i>{" "}
                                      Accounting
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[4].vouchersTxn.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/vouchers/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="card">
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      <i className="tim-icons icon-paper mr-2 text-primary"></i>{" "}
                                      Portfolio
                                    </h4>
                                  </div>
                                  <div className="card-body">
                                    {txnTypeCatagory[5].portfolio.map(
                                      (item: any, index: number) => {
                                        return (
                                          <MenuItem
                                            style={{
                                              minHeight: "0px", // mobile view
                                            }}
                                            className="box-element"
                                            key={index}
                                            onClick={() => {
                                              sessionStorage.setItem(
                                                "payment_type",
                                                `${item.payment_type}`
                                              );
                                              clearTransactiionHistory();
                                              history.push(
                                                `/admin/portfolio/${item.api_name}`
                                              );
                                            }}
                                          >
                                            <span className="text-hover text-silver">
                                              <span
                                                className={
                                                  item.api_name + " ico"
                                                }
                                              ></span>
                                              <i className="fa fa-angle-right"></i>{" "}
                                              {item.dis_name}
                                            </span>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <Row></Row>
                      )
                    ) : (
                      ""
                    )}
                  </Container>
                </Col>
              </Row>
            </div>
          </Modal>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (viewTxnModal) {
      fields["realized"] === "true"
        ? setRealizedCheck(true)
        : setRealizedCheck(false);
      setHeadLabel(data.txnr_type);
      counterAccounter = getCounterAccount(data.txnr_type);
      // setFields(data);
      if (data.txnr_type === "termdeposit") {
        // debugger;
        setTermDeposit(JSON.parse(fields.securities));
      }
      if (
        data.ledgers == undefined ||
        data.ledgers == "" ||
        data.ledgers == "[]"
      ) {
        setLedgerData([]);
      } else {
        setLedgerData(reverseLedgers(JSON.parse(data.ledgers)));
      }
    }
  }, [viewTxnModal]);

  const reverseLedgers = (ledgers) => {
    const ledgersData = ledgers.map((item: any, index: number) => {
      if (item.type == "Debit") {
        // debit ledger will be credit
        item.type = "Credit";
        item.credit_amount = item.debit_amount;
        item.debit_amount = "0";
      } else {
        // credit ledger will be debit
        item.type = "Debit";
        item.debit_amount = item.credit_amount;
        item.credit_amount = "0";
      }
      return item;
    });

    return ledgersData;
  };

  const createTransaction = async () => {
    let id = fields.txn_id;
    fields.detail = fields.detail.replaceAll("(Reversed)", "") + " (Reversed)";
    fields.associated_transaction = id;
    fields.ledgers = JSON.stringify(ledgerData);
    fields.txn_id = "";
    fields["account_no"] = fields["account_number"]
      ? fields["account_number"]
      : "";
    fields["email"] = sessionStorage.getItem("email") || "";
    fields["credit_date"] =
      fields["credit_date"] == "Invalid date" ? "" : fields["credit_date"];
    fields["dividend_date"] =
      fields["dividend_date"] == "Invalid date" ? "" : fields["dividend_date"];
    fields["return_date"] =
      fields["return_date"] == "Invalid date" ? "" : fields["return_date"];
    fields["right_share_credit_date"] =
      fields["right_share_credit_date"] == "Invalid date"
        ? ""
        : fields["right_share_credit_date"];
    fields["announcement_date"] =
      fields["announcement_date"] == "Invalid date"
        ? ""
        : fields["announcement_date"];
    fields["bonus_credit_date"] =
      fields["bonus_credit_date"] == "Invalid date"
        ? ""
        : fields["bonus_credit_date"];

    delete fields["_id"];
    delete fields["_rev"];
    delete fields["~version"];
    delete fields["doc_type"];
    delete fields["created_at"];
    delete fields["created_by"];
    delete fields["assigned_to"];
    delete fields["assign_time"];
    delete fields["txn_status"];
    delete fields["account_number"];

    try {
      setLoadingReverseButton(true);
      Object.keys(fields).forEach((key) => {
        if (fields[key] === null) {
          fields[key] = "";
        }
        if (typeof fields[key] !== "string") {
          fields[key] = fields[key].toString();
        }
      });
      fields["face_value"] = fields["face_value"]
        ? parseFloat(fields["face_value"])
        : 0;
      // console.log("FIELDS DATA => ", fields);

      const navCheck = await validateFundNav(
        email,
        amc_code,
        fields?.fund_code,
        fields?.settlement_date
      );

      if (navCheck?.status === 200 && navCheck?.data?.status !== 200) {
        toast.error(navCheck?.data?.message);
        setLoadingReverseButton(false);
        setLoading(false);
        return;
      }

      const response = await addMegaTransaction(fields); // for add new transaction
      if (response.data.status === 400) {
        setViewBankBalanceModal(true);
        toast.warning(response.data.message);
      } else if (response.data.status === 417) {
        toast.warning(response.data.message);
      } else {
        setUnderStand(false);
        setViewBankBalanceModal(false);
        setReverseTransactionConfirmationModal(false);
        sessionStorage.setItem("last_id", response.data.txn_id);
        toast.success(`${response.data.message} - ${response.data.txn_id}`);
        // toast.success(response.data.message);
        setLoadingReverseButton(false);
        setViewTxnModal(false);
        // clearFilters()
        getTransactions("true");
        // setTimeout(() => {
        //   history.goBack();
        // }, 3000);
      }
    } catch (error: any) {
      setLoadingReverseButton(false);
      if (error.response !== undefined) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.messgae);
      }
    }
    setLoadingReverseButton(false);
  };

  const renderViewTxnModalPopup = () => {
    switch (viewTxnModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="xl"
            backdrop={true}
            onHide={() => {
              setViewTxnModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">View Transaction </h6>
            </div>
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setViewTxnModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up"></h6>
            </div>
            <div className="modal-body">
              <div className="">
                <h4 className="card-title">
                  <i
                    className="fa fa-angle-left mr-2 pointer"
                    onClick={() => {
                      setView(false);
                      setViewTxnModal(false);
                    }}
                  ></i>
                  View Transaction
                </h4>
                <ViewTransaction data={data} />

                {(data.txn_status == "COMPLETED" ||
                  data.txn_status == "REJECTED-AMC") && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          setReverseTransactionConfirmationModal(true);
                          // createTransaction();
                        }}
                        disabled={Boolean(LoadingReverseButton)}
                      >
                        {LoadingReverseButton ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Reverse</span>
                        )}
                      </button>
                    </div>
                  </div>
                )}

                <div className="line"></div>

                <Row className=" pb-5">
                  {trx_history.map((hist: any, index: number) => {
                    return (
                      <Col
                        md="3"
                        key={index}
                        onClick={() => {
                          if (hist.action === "REJECTED-AMC") {
                            setNote(hist.note);
                          } else {
                            setNote("");
                          }
                          if (hist.action === "CREATED") {
                            return;
                          }
                          if (!hist.check_list) return;
                          setTrxCheckList(hist.check_list);
                          setViewCheckList(true);
                        }}
                      >
                        <div className="card process-card pointer">
                          <div className="card-header">
                            <h4 className="card-title">
                              <i className="tim-icons icon-single-02 mr-1"></i>{" "}
                              <span className="card-number">{index + 1}</span>
                              {hist.updated_by_role}{" "}
                            </h4>
                          </div>
                          <div className="card-body text-center">
                            <p className="small mt-2">Status:</p>
                            <h3 className="accepted text-primary">
                              {" "}
                              {hist.action}{" "}
                            </h3>
                            {hist.updated_at !== "" ? (
                              <>
                                <p className="small mt-2">by</p>
                                <h4 className="comment text-primary">
                                  {hist.updated_by}
                                </h4>
                                <p className="small mt-2">at</p>
                                <h4 className="comment text-primary">
                                  {moment(hist.updated_at).format(
                                    "DD-MM-YYYY, h:mm a"
                                  )}
                                </h4>
                              </>
                            ) : (
                              ""
                            )}
                            {hist.note !== "" ? (
                              <>
                                <p className="small mt-2">Comment</p>
                                <small
                                  className="comment dot-text"
                                  title={hist.note}
                                >
                                  {hist.note}
                                </small>
                              </>
                            ) : (
                              ""
                            )}
                            {hist.created_by !== "" ? (
                              <>
                                <p className="small mt-2">by</p>
                                <h4 className="text-primary">
                                  {hist.created_by}
                                </h4>
                                <p className="small mt-2"> at</p>
                                <h4 className="comment text-primary">
                                  {" "}
                                  {moment(hist.created_at).format(
                                    "DD-MM-YYYY, h:mm a"
                                  )}{" "}
                                </h4>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const reverseTransactionModal = () => {
    switch (reverseTransactionConfirmationModal) {
      case true:
        return (
          <Modal
            backdrop={true}
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setReverseTransactionConfirmationModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">
                Confirmation Reverse Tranaction{" "}
              </h6>
            </div>
            <div className="modal-body">
              <div>Are you sure do you want reverse this transaction?</div>

              <button
                type="button"
                className="btn btn-primary"
                disabled={Boolean(LoadingReverseButton)}
                onClick={() => {
                  createTransaction();
                }}
              >
                Proceed
              </button>
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  let last_id = sessionStorage.getItem("last_id") || "";
  const renderMenuPopup = () => {
    return (
      <Menu
        className=" shadow"
        id="simple-menu"
        anchorEl={anchorEl}
        // PaperProps={{
        //   style: {
        //     width: '70',
        //     outline: 'none',
        //     background: '#5ad2d2',
        //   },
        // }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className="  p-2 ">{last_id}</div>
      </Menu>
    );
  };

  // popup for bank balance
  const renderModalForBankBalance = () => {
    switch (viewBankBalanceModal) {
      case true:
        return (
          <Modal
            className=""
            dialogClassName="modal90w"
            show={true}
            size="lg"
            backdrop={true}
            onHide={() => {
              setUnderStand(false);
              setViewBankBalanceModal(false);
            }}
          >
            <div className="modal-header ">
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setUnderStand(false);
                  setViewBankBalanceModal(false);
                }}
              >
                <i className="tim-icons icon-simple-remove" />
              </button>
              <h6 className="title title-up">Bank Balance</h6>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div></div> Balance
                    <input
                      type="number"
                      value={bankBalance || "0"}
                      readOnly
                      className="form-control w-100"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    Detail*
                    <input
                      type="text"
                      defaultValue={fields["detail"]}
                      className={"form-control w-100 "}
                      onChange={(e) => {
                        setFields({
                          ...fields,
                          detail: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-inline">
                    <div className="form-group mr-2">
                      <input
                        type="checkbox"
                        // defaultValue={underStand}
                        // className={
                        //   'form-control w-100 '
                        // }
                        onChange={(e) => {
                          setUnderStand(!underStand);
                        }}
                      />
                    </div>
                    <span>I understand</span>
                  </div>
                </div>
              </div>
              {underStand === true ? (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-primary btn-block"
                        disabled={Boolean(LoadingReverseButton)}
                        onClick={() => {
                          setFields({
                            ...fields,
                            low_balance_agree: "Yes",
                          });
                          createTransaction();
                        }}
                      >
                        {LoadingReverseButton ? (
                          <>
                            <span
                              className="spinner-border login-txt spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="login-txt"> Loading...</span>
                          </>
                        ) : (
                          <span>Proceed</span>
                        )}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-info btn-block"
                        onClick={() => {
                          setUnderStand(false);
                          setViewBankBalanceModal(false);
                        }}
                      >
                        <span>Cancel</span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {/* </div> */}
            </div>
          </Modal>
        );
      default:
        return "";
    }
  };

  const selectedOption = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} - ${selectedFund.symbol_code}`,
        }
      : null;
  }, [allFunds, fund]);

  const selectedStatus = React.useMemo(() => {
    const selectedFund = StatusOptions.find((item) => item?.value === status);
    return selectedFund
      ? {
          value: selectedFund.value,
          label: selectedFund.label,
        }
      : null;
  }, [StatusOptions, status]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        {loading && transactions?.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <i className="fa fa-spinner fa-spin fa-4x " aria-hidden="true"></i>
          </div>
        ) : (
          <Row>
            <Col md="12">
              <Container fluid>
                <ToastContainer limit={1} />
                {/* <Header /> */}
                <div className="card ">
                  <div className="card-header row mx-1">
                    <h4 className="card-title ">Transactions</h4>
                    <button
                      onClick={() => {
                        sessionStorage.removeItem("Txn1");
                        sessionStorage.removeItem("Txn2");
                        setCreateTransactionModal(true);
                      }}
                      className="btn btn-primary btn-sm ml-auto py-2 px-3"
                    >
                      <i className="fa fa-plus mr-2"></i>Create
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-2">
                        <ReactTooltip
                          textColor="white"
                          backgroundColor="black"
                          effect="float"
                        />
                        <div className="form-group">
                          <label>Transaction ID</label>
                          <input
                            style={{ paddingTop: "6px", paddingBottom: "6px" }}
                            type="search"
                            id="myInput"
                            placeholder="Transaction Id"
                            data-tip="Search Transaction"
                            className="form-control"
                            value={searchValue}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {!fund_code && (
                        <div className="col-md-2">
                          <div className="form-group ml-2 mr-2">
                            <label>Select Fund</label>
                            <Select
                              value={selectedOption}
                              label={"Select"}
                              onChange={(e) => {
                                if (e?.value) {
                                  setFund(e.value);
                                } else {
                                  setFund("");
                                }
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderFundsDropDown()}
                              styles={customStyles}
                            />
                            {/* <select
                            className={"form-control"}
                            value={fund}
                            onChange={(e) => {
                              setFund(e.target.value);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                          </div>
                        </div>
                      )}

                      <div className="col-md-2 ">
                        <div className="form-group">
                          <label>Select Status</label>
                          <Select
                            value={selectedStatus}
                            label={"Select"}
                            onChange={(e) => {
                              if (e?.value) {
                                setStatus(e.value);
                              } else {
                                setStatus("");
                              }
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={StatusOptions}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control ml-2"
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                            value={status}
                          >
                            <option value="-1">Select type</option>
                            <option value="CREATED">Created</option>
                            <option value="COMPLETED">Completed</option>
                            <option value="REJECTED">Rejected</option>
                            <option value="REJECTED-AMC">Rejected-Amc</option>
                          </select> */}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className=" inline-box">
                          <button
                            className="btn btn-primary mt-4 ml-2"
                            disabled={Boolean(searchLoading)}
                            onClick={() => {
                              if (
                                (searchValue || status !== "-1" || fund) !== ""
                              ) {
                                searchTransactionsByAMCAndFund(
                                  status,
                                  searchValue,
                                  "",
                                  fund
                                ).then(() => {
                                  setActivePage("");
                                });
                              }
                            }}
                          >
                            {searchLoading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Searching...</span>
                              </>
                            ) : (
                              "Search"
                            )}
                          </button>

                          <button
                            className="btn btn-default ml-2 mt-4 ml-2"
                            disabled={Boolean(clearLoading)}
                            style={{
                              width: "148px",
                            }}
                            onClick={() => {
                              clearFilters("", "").then(() => {
                                setStatus("");
                                setSearchValue("");
                                setActivePage("");
                                setFund("");
                              });
                            }}
                          >
                            {clearLoading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Clear</span>
                              </>
                            ) : (
                              "Clear"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      {transactions.length == 0 && resLoading ? (
                        <div className="d-flex justify-content-center my-5">
                          <i
                            className="fa fa-spinner fa-spin fa-3x"
                            aria-hidden="true"
                          ></i>
                        </div>
                      ) : transactions.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table mt-2">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Transaction</th>
                                <th>Fund</th>
                                <th>Amount</th>
                                <th>Bank</th>
                                <th>Counter Acc</th>
                                <th>Instrument</th>
                                <th>Created</th>
                                <th>Status</th>
                                <th>Act</th>
                              </tr>
                            </thead>
                            <tbody>
                              {transactions.map(
                                (transaction: any, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {moment(
                                          transaction.instruction_date
                                        ).format("DD-MM-YYYY")}{" "}
                                        <br />
                                        <span className="sub-content">
                                          Exe:{" "}
                                          {moment(
                                            transaction.execution_date
                                          ).format("DD-MM-YYYY")}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.txnr_type
                                          ? `${CapTxnType(
                                              transaction.txnr_type
                                            )}	${
                                              transaction.detail?.includes(
                                                "Reversed"
                                              )
                                                ? " (Reversed)"
                                                : ""
                                            }`
                                          : "N/A"}
                                        <br />{" "}
                                        <span className="sub-content">
                                          {transaction.txn_id.replace(
                                            "TXN_",
                                            ""
                                          )}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.fund_code} <br />
                                        {/* <span className="sub-content">
                                          {transaction.amc_code
                                            ? transaction.amc_code
                                            : "N/A"}{" "}
                                        </span> */}
                                      </td>

                                      <td className="text-left pr-3">
                                        {numberWithCommas(
                                          transaction.net_amount
                                        )}{" "}
                                        <br />
                                        <span className="sub-content">
                                          {numberWithCommas(
                                            transaction.gross_amount
                                          )}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.bank}
                                        <br />
                                        <span className="sub-content">
                                          {transaction.account_number}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.counter_bank} <br />
                                        <span className="sub-content">
                                          {transaction.counter_account_number}
                                        </span>
                                      </td>

                                      <td>
                                        {transaction.mode_of_payment} <br />
                                        <span className="sub-content">
                                          {transaction.instrument_type
                                            ? transaction.instrument_type
                                            : ""}{" "}
                                          {transaction.instrument_no !== ""
                                            ? transaction.instrument_no + ","
                                            : ""}{" "}
                                          {moment(
                                            transaction.instrument_date
                                          ).format("DD-MM-YYYY")}
                                        </span>
                                      </td>

                                      <td>
                                        {moment(transaction.created_at).format(
                                          "DD-MM-YYYY"
                                        )}{" "}
                                        <br />
                                        <span className="sub-content">
                                          {
                                            transaction.created_by?.split(
                                              "@"
                                            )[0]
                                          }
                                        </span>
                                      </td>
                                      <td>{transaction.txn_status}</td>
                                      <td className=" ">
                                        <div className="multi-icons pointer">
                                          {/* <button type="button" className="btn-icon btn-link like btn btn-info btn-sm" onClick={() => {	
                                let objArr = Object.entries(transaction);	
                                let bankname=bankName(transaction.bank.toString())	
                                transaction.bank=bankname;
                                let counterbankname=bankName(transaction.counter_bank.toString())	
                                transaction.counter_bank=counterbankname;
                                setTrxHistory(transaction.txn_history);	 
                                setData(transaction);
                                toast.success(transaction.txn_history)	
                                setView(true);	
                              }}>
                                <i className="fa fa-eye">
                            </i>
                          </button>  */}

                                          <button
                                            type="button"
                                            className="btn-icon btn-link like btn btn-info btn-sm"
                                            onClick={() => {
                                              let objArr =
                                                Object.entries(transaction);
                                              let bankname = bankName(
                                                transaction.bank.toString()
                                              );
                                              transaction.bank = bankname;
                                              let counterbankname = bankName(
                                                transaction.counter_bank.toString()
                                              );
                                              transaction.counter_bank =
                                                counterbankname;
                                              setTrxHistory(
                                                transaction.txn_history
                                              );
                                              setData({ ...transaction });
                                              setFields({ ...transaction });
                                              // toast.success(transaction.txn_history)
                                              // setView(true);
                                              setViewTxnModal(true);
                                            }}
                                          >
                                            <i className="fa fa-eye"></i>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="table-empty">
                          <BsTable className="icon" />
                          <p className="text-center empty-text">
                            No record found
                          </p>
                        </div>
                      )}
                      {totalDocs && (
                        <div className="row pointer justify-content-end pr-4">
                          {`${transactions.length} OF ${totalDocs}`}
                        </div>
                      )}

                      {/* {togglePagination===true ? ( */}
                      <div className="row pointer justify-content-center">
                        {/* PREVIOUS BUTTON */}
                        {/* {activePage !== paginationArr[0] ? (
                          <i
                            className="fa fa-arrow-left"
                            aria-hidden="true"
                            onClick={() => {
                              let pageArr = paginationArr;
                              let index = pageArr.findIndex(
                                (x) => x === activePage
                              );
                              if (index !== -1) {
                                setActivePage(paginationArr[index - 1]);
                              }
                            }}
                          ></i>
                        ) : (
                          ""
                        )} */}
                        {/* NEXT BUTTON */}
                        {/* {activePage !==
                        paginationArr[paginationArr.length - 1] ? ( */}
                        <button
                          aria-hidden={true}
                          className="btn btn-primary btn-sm mt-2 py-2 px-3"
                          disabled={
                            activePage ==
                              paginationArr[paginationArr.length - 1] ||
                            resLoading
                          }
                          type="button"
                          onClick={() => {
                            let pageArr = paginationArr;
                            let index = pageArr.findIndex(
                              (x) => x === activePage
                            );
                            if (index !== -1) {
                              setActivePage(paginationArr[index + 1]);
                            }
                          }}
                        >
                          {resLoading ? (
                            <span>
                              Loading...{" "}
                              <i
                                className="fa fa-spinner fa-spin  "
                                aria-hidden="true"
                              ></i>
                            </span>
                          ) : (
                            "Show More"
                          )}
                        </button>

                        {/* // <i
                          //   className="fa ml-3 fa-arrow-right"
                          //   aria-hidden="true"
                          //   onClick={() => {
                          //     let pageArr = paginationArr;
                          //     let index = pageArr.findIndex(
                          //       (x) => x === activePage
                          //     );
                          //     if (index !== -1) {
                          //       setActivePage(paginationArr[index + 1]);
                          //     }
                          //   }}
                          // ></i>
                        ) : (
                          ""
                        )} */}
                      </div>
                      {/* ):(
                  ''
                )}	 */}
                    </div>
                  </div>
                </div>
                {last_id !== "" && (
                  <>
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    <AiOutlineInfoCircle
                      data-tip="Last Transaction"
                      className={"float-right mt-3"}
                      onClick={(e) => {
                        handleClick(e);
                      }}
                    />
                  </>
                )}
                {renderMenuPopup()}
                {renderTransactionTypes()}
                {viewCheckListPopup()}
                {renderSecuritiesModalPopup()}
                {renderTaxPopup()}
                {renderViewTxnModalPopup()}
                {renderModalForBankBalance()}
                {reverseTransactionModal()}
              </Container>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default BetaTxnType;

import axios from "axios";
import Config from "../../config/index";
import RefreshTokenHandler from "./refresh-token";

const getAllDividendAnnouncements = async (
  email: string,
  amc_code: string,
  fund_code?: string
) => {
  const url = `${Config.baseUrl}/corporateannouncements?email=${email}&amc_code=${amc_code}&fund_code=${fund_code}`;
  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllDividendAnnouncements(email, amc_code, fund_code);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const postToDividendAnnouncement = async (
  email: string,
  amc_code: any,
  fund: any,
  announcementDescription: any,
  date: any,
  redemptionDate: any,
  openingExNav: any,
  closingNavBeforeDistribution: any,
  payoutRatio: any,
  dividendPerUnit: any,
  exNavAfterDistribution: any,
  fromDate: any,
  toDate: any
) => {
  const url = `${Config.baseUrl}/corporateannouncements`;
  const token = sessionStorage.getItem("token");
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        amc_code: amc_code,
        fund_code: fund,
        description: announcementDescription,
        redemption_date: redemptionDate,
        opening_ex_nav: parseFloat(openingExNav).toFixed(4).toString(),
        closing_nav_before_dist: parseFloat(closingNavBeforeDistribution)
          .toFixed(4)
          .toString(),
        payout_ratio_percent: payoutRatio,
        dividend_per_unit: parseFloat(dividendPerUnit).toFixed(4).toString(),
        ex_nav_after_dist: parseFloat(exNavAfterDistribution)
          .toFixed(4)
          .toString(),
        paid: "false",
        from_date: fromDate,
        to_date: toDate,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await postToDividendAnnouncement(
          email,
          amc_code,
          fund,
          announcementDescription,
          date,
          redemptionDate,
          openingExNav,
          closingNavBeforeDistribution,
          payoutRatio,
          dividendPerUnit,
          exNavAfterDistribution,
          fromDate,
          toDate
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const changeActiveStatus = async (
  email: string,
  announcement_id: any,
  status: any
) => {
  const url = `${Config.baseUrl}/corporateannouncements/update-status`;
  const token = sessionStorage.getItem("token") || "";

  try {
    const result = await axios.post(
      url,
      { email, announcement_id, status },
      { headers: { Authorization: token } }
    );

    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await changeActiveStatus(email, announcement_id, status);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const changePaidStatus = async (
  email: string,
  announcement_id: any,
  paid: any
) => {
  const url = `${Config.baseUrl}/corporateannouncements/update-paid-status`;
  const token = sessionStorage.getItem("token") || "";

  try {
    const result = await axios.post(
      url,
      { email, announcement_id, paid },
      { headers: { Authorization: token } }
    );

    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await changePaidStatus(email, announcement_id, paid);
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

const updateDividendAnnouncement = async (
  email: string,
  announementId: any,
  amc_code: any,
  fund: any,
  announcementDescription: any,
  date: any,
  redemptionDate: any,
  openingExNav: any,
  closingNavBeforeDistribution: any,
  payoutRatio: any,
  dividendPerUnit: any,
  exNavAfterDistribution: any,
  fromDate: any,
  toDate: any
) => {
  const url = `${Config.baseUrl}/corporateannouncements/update`;
  const token = sessionStorage.getItem("token");
  try {
    const result: any = await axios.post(
      url,
      {
        email,
        announcement_id: announementId,
        amc_code: amc_code,
        fund_code: fund,
        description: announcementDescription,
        redemption_date: redemptionDate,
        opening_ex_nav: parseFloat(openingExNav).toFixed(4).toString(),
        closing_nav_before_dist: parseFloat(closingNavBeforeDistribution)
          .toFixed(4)
          .toString(),
        payout_ratio_percent: payoutRatio,
        dividend_per_unit: parseFloat(dividendPerUnit).toFixed(4).toString(),
        ex_nav_after_dist: parseFloat(exNavAfterDistribution)
          .toFixed(4)
          .toString(),
        paid: "false",
        from_date: fromDate,
        to_date: toDate,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await updateDividendAnnouncement(
          email,
          announementId,
          amc_code,
          fund,
          announcementDescription,
          date,
          redemptionDate,
          openingExNav,
          closingNavBeforeDistribution,
          payoutRatio,
          dividendPerUnit,
          exNavAfterDistribution,
          fromDate,
          toDate
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

//api for getting dividend announcements in dividend disbursement page

const getAllActiveUnpaidDividendAnnouncementsByFund = async (
  email: string,
  fund: any,
  paid: any,
  amc_code: string
) => {
  const url = `${Config.baseUrl}/corporateannouncements/get-announcements-by-paid?email=${email}&paid=${paid}&fund_code=${fund}&amc_code=${amc_code}`;

  try {
    const result: any = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("token") || "",
      },
    });
    return result;
  } catch (err: any) {
    if (err.response.data.status == 401) {
      let responseToHandle = err.response.data;
      let result = await RefreshTokenHandler.handleIt(responseToHandle);
      if (result.status) {
        return await getAllActiveUnpaidDividendAnnouncementsByFund(
          email,
          fund,
          paid,
          amc_code
        );
      } else {
        throw err;
        // return result
      }
    } else {
      throw err;
    }
  }
};

export {
  getAllDividendAnnouncements,
  getAllActiveUnpaidDividendAnnouncementsByFund,
  postToDividendAnnouncement,
  updateDividendAnnouncement,
  changeActiveStatus,
  changePaidStatus,
};

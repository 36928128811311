import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { editSecurity } from '../../../stores/services/security.service';
import { useHistory, Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import NumberFormat from "react-number-format";
import moment from 'moment';
import { numberWithCommas } from '../../../utils/customFunction';
import { set } from 'mobx';
import { useSelector } from 'react-redux';
import Select from "react-select";


const codeRegex = new RegExp('^[A-Z]{1,5}$');
const EditSecurity = () => {

  const moneyMarket = [
    { label: "Pakistan Investment Bonds", value: "PIB" },
    { label: "Treasury Bill", value: "TBILL" }
  ]
  const debtMarket = [
    { label: "Sukuk Certificates", value: "SUKUK" },
    { label: "Term Finance Certificates", value: "TFC" },
    { label: "Commercial Papers", value: "CP" }
  ]
  const termDeposits = [
    { label: "Term Deposit Receipts", value: "TDR" },
    { label: "Certificate Of Investment", value: "CDR" },
    { label: "Letter of Placement", value: "LOP" }
  ]
  const baiMujjalPlacement = [
    { label: "Bai Mujjal Placement", value: "Bai Mujjal Placement" }
  ]

  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const amc_code = sessionStorage.getItem('amc_code') || "";
  const [securityType, setSecurityType] = useState('');
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [maturityDate, setMaturityDate] = useState('');
  const [faceValue, setFaceValue] = useState('');
  const [price, setPrice] = useState('');

  const [couponPaymentPerYear, setCouponPaymentPerYear] = useState('');
  const [noOfCoupon, setNoOfCoupon] = useState('');
  const [faceValuePerUnit, setFaceValuePerUnit] = useState('');
  const [kiborRateBenchmark, setKiborRateBenchmark] = useState('');
  const [systemExecutionDate, setSystemExecutionDate] = useState('');

  const [couponDuration, setCouponDuration] = useState('');

  const [coupons, setCoupons] = useState<any>([]);
  const [copyCoupons, setCopyCoupons] = useState<any>([]);
  const [state, setState] = useState('');
  const [kiborRate, setKiborRate] = useState("");
  const [spreadRate, setSpreadRate] = useState("");

  const [securityTypeError, setSecurityTypeError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [nameError, setNameError] = useState('');
  const [couponPaymentPerYearError, setCouponPaymentPerYearError] = useState('');
  const [noOfCouponError, setNoOfCouponError] = useState('');
  const [faceValuePerUnitError, setFaceValuePerUnitError] = useState('');
  const [kiborRateBenchmarkError, setKiborRateBenchmarkError] = useState('');
  const [kiborRatError, setKiborRateError] = useState("");
  const [spreadRateError, setSpreadRateError] = useState("");
  const [systemExecutionDateError, setSystemExecutionDateError] = useState('');

  const [Loading, setLoading] = useState(false);

  const [yieldText, setYieldText] = useState('');

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('securityObj') || '');
    setSecurityType(obj.security_type);
    setCode(obj.code.replace('SECURITY_', ''));
    setName(obj.name);
    setIssueDate(obj.issue_date ? moment(obj.issue_date).format('YYYY-MM-DD') : '');
    setMaturityDate(obj.maturity_date ? moment(obj.maturity_date).format('YYYY-MM-DD') : '');
    setFaceValue(obj.face_value);
    setPrice(obj.price);
    setCouponPaymentPerYear(obj.coupon_payment_per_year);
    setNoOfCoupon(obj.no_of_coupons);
    setFaceValuePerUnit(obj.face_value_per_unit);
    setKiborRateBenchmark(obj.kibor_rate_benchmark);
    setKiborRate(obj.kibor_rate?.toString() || "");
    setSpreadRate(obj.spread_rate?.toString() || "");

    setYieldText(obj.yield_percentage?.toString() || "");
    setSystemExecutionDate(obj.system_execution_date ? moment(obj.system_execution_date).format('YYYY-MM-DD') : '');
    setCopyCoupons(JSON.parse(obj.coupons || '[]'));
    // if(JSON.parse(obj.coupons).length>0){
    // setCoupons(JSON.parse(obj.coupons));
    // }
  }, []);

  React.useEffect(() => {
    const obj = JSON.parse(sessionStorage.getItem('securityObj') || '');
    setCoupons(JSON.parse(obj.coupons || '[]'));
  }, [copyCoupons]);

  const renderSelectSecurity = () => {
    return [
      {
        label: "Money Market",
        options: moneyMarket
      },
      {
        label: "Debt Market",
        options: debtMarket
      },
      {
        label: "Term Deposits",
        options: termDeposits
      },
      {
        label: "Bai Mujjal Placement",
        options: baiMujjalPlacement
      }
    ];
  }


  const validate = () => {
    let securityTypeErr,
      codeErr,
      nameErr,
      couponPaymentPerYearErr,
      noOfCouponsErr,
      faceValuePerUnitErr,
      kiborRateBenchmarkErr,
      kiberRateErr,
      spreadRateErr,
      systemExecutionDateErr = '';
    securityType?.trim() === '' ? (securityTypeErr = 'required-border') : (securityTypeErr = '');
    code?.trim() === '' ? (codeErr = 'required-border') : (codeErr = '');
    name?.trim() === '' ? (nameErr = 'required-border') : (nameErr = '');
    couponPaymentPerYear?.toString()?.trim() === '' ? (couponPaymentPerYearErr = 'required-border') : (couponPaymentPerYearErr = '');
    noOfCoupon?.toString()?.trim() === '' ? (noOfCouponsErr = 'required-border') : (noOfCouponsErr = '');
    faceValuePerUnit?.toString()?.trim() === '' ? (faceValuePerUnitErr = 'required-border') : (faceValuePerUnitErr = '');
    // kiborRateBenchmark.trim() === '' ? (kiborRateBenchmarkErr = 'required-border') : (kiborRateBenchmarkErr = '');
    kiborRate?.toString()?.trim() === '' ? (kiberRateErr = 'required-border') : (kiberRateErr = '');
    spreadRate?.toString()?.trim() === '' ? (spreadRateErr = 'required-border') : (spreadRateErr = '');
    systemExecutionDate?.trim() === '' ? (systemExecutionDateErr = 'required-border') : (systemExecutionDateErr = '');
    if (
      securityTypeErr ||
      codeErr ||
      nameErr ||
      couponPaymentPerYearErr ||
      noOfCouponsErr ||
      faceValuePerUnitErr ||
      kiborRateBenchmarkErr ||
      systemExecutionDateErr ||
      kiberRateErr ||
      spreadRateErr
    ) {
      setSecurityTypeError(securityTypeErr);
      setCodeError(codeErr);
      setNameError(nameErr);
      setCouponPaymentPerYearError(couponPaymentPerYearErr);
      setNoOfCouponError(noOfCouponsErr);
      setFaceValuePerUnitError(faceValuePerUnitErr);
      // setKiborRateBenchmarkError(kiborRateBenchmarkErr);
      setSpreadRateError(spreadRateErr);
      setKiborRateError(kiberRateErr);
      setSystemExecutionDateError(systemExecutionDateErr);
      return false;
    } else {
      return true;
    }
  };

  const updateSecurity = async () => {
    if (validate()) {
      if (couponsValidations()) {
        setLoading(true)
        await editSecurity(sessionStorage.getItem('email') || '', name, code, securityType, issueDate, maturityDate, faceValue?.toString(), price?.toString(),
          couponPaymentPerYear?.toString(), noOfCoupon?.toString(), faceValuePerUnit?.toString(), kiborRateBenchmark?.toString(), systemExecutionDate, JSON.stringify(coupons), (securityType == "TBILL" || securityType == "PIB") ? yieldText : "", kiborRate, spreadRate, amc_code)
          .then((response) => {
            // toast.success(response.data.message);	
            // setTimeout(function () {	
            //   history.replace('/admin/securities');	
            // }, 3000);	
            if (response.data.status == 200) {
              toast.success(response.data.message);
              sessionStorage.removeItem('securityObj');
              setTimeout(function () {
                history.replace('/admin/securities');
              }, 3000);
            } else if (response.data.status == 500) {
              setLoading(false)
              toast.error(response.data.message);
            } else {
              setLoading(false)
              toast.error(response.data.message);
            }
          })
          .catch((err: any) => {
            setLoading(false)
            if (err.response !== undefined) {
              toast.error(err.response.data.message?.toString());
            } else {
              toast.error("Something went wrong!");
            }
          })

      } // end of coupons validation
    }
  }

  const decimalValue = (value, len) => {
    let startingValue = value.split('.')[0];
    let decimalVal = value.split('.')[1];
    let temp = '';
    if (decimalVal !== undefined) {
      let decimalString = decimalVal.toString();
      if (decimalString.length <= len) {
        temp = startingValue + '.' + decimalString;
      } else {
        temp = startingValue + '.' + decimalString.substr(0, len);
      }
    } else {
      temp = startingValue;
    }
    return temp;
  };

  const getCouponDate = (coupon_paymentPer_year) => {
    // setKiborRate('');
    // setSpreadRate('');
    let issue_date = new Date(issueDate);
    let coupon_date = new Date(issueDate);
    let m_date = new Date(maturityDate);

    var diff = (issue_date.getTime() - m_date.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    let years = Math.abs(Math.round(diff / 365.25));
    let totalCoupons = (years * +coupon_paymentPer_year).toString();
    setNoOfCoupon(totalCoupons);
    let durationOfMonths = (12 / +coupon_paymentPer_year).toString();
    setCouponDuration(durationOfMonths);

    let array: any = [];
    for (let i = 1; i <= +totalCoupons; i++) {
      coupon_date = new Date(coupon_date.setMonth(coupon_date.getMonth() + +durationOfMonths));
      let obj = {
        coupon_no: i.toString(),
        coupon_date: moment(coupon_date).format('YYYY-MM-DD'),
        kibor_rate: '',
        spread_rate: '',
        coupon_rate: '',
        principal_redemption: '',
        remaining_face_value: faceValuePerUnit,
        paid: 'No',
        isKibor_empty: '',
        isSpread_empty: '',
        isPrincipal_Redemp_empty: '',
        coupon_payment: ""
      }
      array.push(obj);
    }
    setCoupons(array);

  }
  const getCouponDateByIssueDate = (issueDate) => {
    if (couponPaymentPerYear == '') {
      setCouponPaymentPerYearError('required-border');
      return;
    }
    // setKiborRate('');
    // setSpreadRate('');
    let issue_date = new Date(issueDate);
    let coupon_date = new Date(issueDate);
    let m_date = new Date(maturityDate);

    var diff = (issue_date.getTime() - m_date.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    let years = Math.abs(Math.round(diff / 365.25));
    let totalCoupons = (years * +couponPaymentPerYear).toString();
    setNoOfCoupon(totalCoupons);
    let durationOfMonths = (12 / +couponPaymentPerYear).toString();
    setCouponDuration(durationOfMonths);

    let array: any = [];
    for (let i = 1; i <= +totalCoupons; i++) {
      coupon_date = new Date(coupon_date.setMonth(coupon_date.getMonth() + +durationOfMonths));
      let obj = {
        coupon_no: i.toString(),
        coupon_date: moment(coupon_date).format('YYYY-MM-DD'),
        kibor_rate: '',
        spread_rate: '',
        coupon_rate: '',
        principal_redemption: '',
        remaining_face_value: faceValuePerUnit,
        paid: 'No',
        isKibor_empty: '',
        isSpread_empty: '',
        isPrincipal_Redemp_empty: '',
        coupon_payment: ""
      }
      array.push(obj);
    }
    setCoupons(array);

  }

  const getCouponDateByMaturityDate = (maturity_date) => {

    setKiborRate('');
    setSpreadRate('');
    let issue_date = new Date(issueDate);
    let coupon_date = new Date(issueDate);
    let m_date = new Date(maturity_date);

    var diff = (issue_date.getTime() - m_date.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    let years = Math.abs(Math.round(diff / 365.25));
    let totalCoupons = (years * +couponPaymentPerYear).toString();
    setNoOfCoupon(totalCoupons);
    let durationOfMonths = (12 / +couponPaymentPerYear).toString();
    setCouponDuration(durationOfMonths);

    let array: any = [];
    for (let i = 1; i <= +totalCoupons; i++) {
      coupon_date = new Date(coupon_date.setMonth(coupon_date.getMonth() + +durationOfMonths));
      let obj = {
        coupon_no: i.toString(),
        coupon_date: moment(coupon_date).format('YYYY-MM-DD'),
        kibor_rate: '',
        spread_rate: '',
        coupon_rate: '',
        principal_redemption: '',
        remaining_face_value: faceValuePerUnit,
        paid: 'No',
        isKibor_empty: '',
        isSpread_empty: '',
        isPrincipal_Redemp_empty: '',
        coupon_payment: ""
      }
      array.push(obj);
    }
    setCoupons(array);

  }


  const couponsValidations = () => {
    let flag = true;
    coupons.map((item: any, index: number) => {
      if (item.kibor_rate == '') {
        item.isKibor_empty = 'required-border';
        flag = false;
        setState('required-border');
      }
      if (item.spread_rate == '') {
        item.isSpread_empty = 'required-border';
        flag = false;
        setState('required-border');
      }
      if (item.principal_redemption == '') {
        item.isPrincipal_Redemp_empty = 'required-border';
        flag = false;
        setState('required-border');
      }
    })
    if (flag) { // when true.
      coupons.map((item: any, index: number) => {
        delete item.isKibor_empty;
        delete item.isSpread_empty;
        delete item.isPrincipal_Redemp_empty;
        setState('');
      })
    }
    return flag;
  }


  const selectedSecuritytype = React.useMemo(() => {
    const selectedFund = moneyMarket?.concat(debtMarket, termDeposits, baiMujjalPlacement)?.flat(1)?.find(item => item?.value === securityType);
    return selectedFund
      ? {
        value: selectedFund.value,
        label: selectedFund.label
      }
      :
      null;
  }, [securityType]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? 'hsla(291, 64%, 42%, 0.5)'
        : isSelected
          ? 'hsla(291, 64%, 42%, 1)'
          : undefined,
      zIndex: 1
    }),
    menuList: styles => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : ""
    }),
    menu: base => ({
      ...base,
      zIndex: 100
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused ? "#1d8cf8" : theme !== "light" ? "#2b3553" : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5"
      },
      '& .react-select__input input[type="color"]': {
        color: theme === 'dark' ? 'red' : 'black'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: base => ({
      ...base,
      color: theme == "dark" ? "white" : "black"
    })
  };

  return (
    <>
      <div className="content">
        <ToastContainer limit={1} />
        <Row>
          <Col md="12 input-holder">
            <h4 className="card-title ml-3">
              <i className="fa fa-angle-left pointer" onClick={() => {
                history.replace('/admin/securities')
              }}></i>{' '}Edit - Investment Securities
            </h4>
            <Link to="/admin/securities" className="btn btn-primary btn-sm mr-3" replace> <i className="fa fa-eye mr-2"></i> View All</Link>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Container fluid>
              {/* <ToastContainer limit={1} />	 */}
              <div className="card ">
                <div className="">
                  <div className="card-header title-row">
                    <h4 className="card-title text-primary">Security</h4>
                  </div>
                  <div className="card-body row">

                    <Col md="6">
                      <div className="form-group">
                        <label>Security Type: <span className="color-amber">*</span></label>
                        <div>
                          <div className="">
                            <Select
                              className={
                                securityTypeError
                              }
                              value={selectedSecuritytype}
                              label={"Select Security"}
                              onChange={(e) => {
                                setSecurityType(e?.value);
                                setSecurityTypeError("");
                                setYieldText("");
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderSelectSecurity()}
                              styles={customStyles}
                            />
                            {/* <select
                              className={"form-control  w-100 " +
                                securityTypeError}
                              value={securityType}
                              onChange={(e) => {
                                setSecurityType(e.target.value);
                                setSecurityTypeError('');
                                setYieldText("")
                              }}>
                              <option value='' hidden>Select Security</option>
                              <optgroup>Money Market</optgroup>
                              <option value='PIB'>Pakistan Investment Bonds</option>
                              <option value='TBILL'>Treasuary bill</option>

                              <optgroup>Debt Market</optgroup>
                              <option value='SUKUK'>Sukuk Certificates</option>
                              <option value='TFC'>Term Finance Certificates</option>
                              <option value='CP'>Commercial Papers</option>

                              <optgroup>Term Deposits</optgroup>
                              <option value='TDR'>Term Deposit Receipts</option>
                              <option value="CDR">
                                Certificate Of Investment
                              </option>
                              <option value='LOP'>Letter of Placement</option>

                              <optgroup>Bai Mujjal Placement</optgroup>
                              <option value='Bai Mujjal Placement'>Bai Mujjal Placement</option>
                            </select> */}


                          </div>

                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Symbol: <span className="color-amber">*</span></label>
                        <div>
                          <div className="">
                            <input
                              type="text"
                              className={"form-control  w-100 " +
                                codeError}
                              value={code}
                              readOnly
                              onChange={(e) => {
                                // setCode(e.target.value.toUpperCase());
                                // setCodeError('');
                              }} />

                          </div>

                        </div>
                      </div>
                    </Col>


                    <Col md="6">
                      <div className="form-group">
                        <label>Name: <span className="color-amber">*</span></label>
                        <div className="">
                          <input className={"form-control  w-100 " + nameError} value={name} onChange={(e) => {
                            setName(e.target.value);
                            setNameError('');
                          }} />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Issue Date</label>
                        <div>
                          <div className="">
                            <input
                              style={{
                                colorScheme: theme == "light" ? "light" : "dark",
                              }}
                              type="date"
                              className='form-control'
                              value={issueDate}
                              onChange={(e) => {
                                setIssueDate(e.target.value);
                                getCouponDateByIssueDate(e.target.value);
                              }} />


                          </div>

                        </div>
                      </div>
                    </Col>


                    <Col md="6">
                      <div className="form-group">
                        <label>Maturity Date</label>
                        <div className="">
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type='date' value={maturityDate} className='form-control' onChange={(e) => {
                              setMaturityDate(e.target.value);
                              getCouponDateByMaturityDate(e.target.value)
                            }} />
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>Face Value {(securityType == "PIB" || securityType == "TBILL") && " Per Unit"}</label>
                        <div>
                          <div className="">
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              className="form-control w-100 text-right"
                              value={faceValue}
                              onValueChange={(e) => {
                                let value = decimalValue(e.value, 2);
                                setFaceValue(value);
                              }} />


                          </div>

                        </div>
                      </div>
                    </Col>


                    <Col md="6">
                      <div className="form-group">
                        <label>Price</label>
                        <div>
                          <div className="">
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              className="form-control w-100 text-right"
                              value={price}
                              onValueChange={(e) => {
                                let value = decimalValue(e.value, 4);
                                setPrice(value);
                              }} />


                          </div>

                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label>System Execution Date: <span className="color-amber">*</span></label>
                        <div className="">
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type='date' value={systemExecutionDate} className={'form-control ' + systemExecutionDateError}
                            onChange={(e) => {
                              // let value = moment(e.target.value).format('YYYY-MM-DD');
                              setSystemExecutionDate(e.target.value);
                              setSystemExecutionDateError('');
                            }} />
                        </div>
                      </div>
                    </Col>

                    {(securityType == "PIB" || securityType == "TBILL") &&
                      <Col md="6">
                        <div className="form-group">
                          <label>Yield %</label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className="form-control w-100 text-right"
                                value={yieldText}
                                decimalScale={2}
                                onBlur={(e) => {
                                  if (e.target.value && +e.target.value > 0) {
                                    setYieldText((+e.target.value / 100)?.toFixed(2));
                                  }
                                }}
                                onValueChange={(e) => setYieldText(e.value)}
                              />

                            </div>

                          </div>
                        </div>
                      </Col>}

                  </div>

                </div>
              </div>
            </Container>
          </Col>
          {(securityType == "PIB" || securityType == "SUKUK" || securityType == "TFC") &&
            <Col md="6">
              <Container fluid>
                {/* <ToastContainer limit={1} />	 */}
                <div className="card ">
                  <div className="">
                    <div className="card-header title-row">
                      <h4 className="card-title text-primary">Coupon Detail</h4>
                    </div>
                    <div className="card-body row">


                      <Col md="6">
                        <div className="form-group">
                          <label>Coupon Payment/Year: <span className="color-amber">*</span></label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className={"form-control w-100 text-right " + couponPaymentPerYearError}
                                value={couponPaymentPerYear}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  setCouponPaymentPerYear(value);
                                  setCouponPaymentPerYearError('');
                                  getCouponDate(value);
                                }} />
                            </div>

                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>No of Coupons: <span className="color-amber">*</span></label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className={"form-control w-100 text-right " + noOfCouponError}
                                value={noOfCoupon}
                                readOnly
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  setNoOfCoupon(value);
                                  setNoOfCouponError('');

                                }} />
                            </div>

                          </div>
                        </div>
                      </Col>


                      <Col md="6">
                        <div className="form-group">
                          <label>Face Value/Unit: <span className="color-amber">*</span></label>
                          <div className="">
                            <NumberFormat
                              thousandSeparator={true}
                              inputMode="numeric"
                              className={"form-control w-100 text-right " + faceValuePerUnitError}
                              value={faceValuePerUnit}
                              onValueChange={(e) => {
                                let value = decimalValue(e.value, 2);
                                setFaceValuePerUnit(value);

                                setFaceValuePerUnitError('');
                                coupons.map((item: any) => {
                                  item.remaining_face_value = value;
                                })
                              }} />
                          </div>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-group">
                          <label>Kibor Rate Benchmark: </label>
                          <div>
                            <div className="">
                              <NumberFormat
                                thousandSeparator={true}
                                inputMode="numeric"
                                className={"form-control w-100 text-right " + kiborRateBenchmarkError}
                                value={kiborRateBenchmark}
                                onValueChange={(e) => {
                                  let value = decimalValue(e.value, 2);
                                  setKiborRateBenchmark(value);
                                  setKiborRateBenchmarkError('');
                                }} />
                            </div>

                          </div>
                        </div>
                      </Col>

                      <Col md='6'>
                        <div className="form-group">
                          <label>Kibor Rate <span className="color-amber">*</span></label>

                          <input type='number'
                            className={"form-control w-100 text-right " + kiborRatError}
                            value={kiborRate || ""}
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              let srate = spreadRate == '' ? '0' : spreadRate;
                              coupons.map((item: any) => {
                                item.kibor_rate = value;
                                item.spread_rate = srate;
                                item.coupon_rate = (+value + +srate).toString();
                              })
                              setKiborRate(value);
                              setKiborRateError("")
                              setState('done');
                            }} />
                        </div>
                      </Col>
                      <Col md='6'>
                        <div className="form-group">
                          <label>Spread Rate <span className="color-amber">*</span></label>

                          <input type='number'
                            className={"form-control w-100 text-right " + spreadRateError}
                            value={spreadRate}
                            onChange={(e) => {
                              let value = decimalValue(e.target.value, 2);
                              let kRate = kiborRate == '' ? '0' : kiborRate;
                              coupons.map((item: any) => {
                                item.kibor_rate = kRate;
                                item.spread_rate = value;
                                item.coupon_rate = (+kRate + +value).toString();
                              })
                              setSpreadRate(value);
                              setState('done');
                              setSpreadRateError("")
                            }} />
                        </div>
                      </Col>




                    </div>

                  </div>
                </div>
              </Container>
            </Col>}
        </Row>
        <Row>
          <Col md="12">
            <Container fluid>
              {(securityType == "PIB" || securityType == "SUKUK" || securityType == "TFC") &&
                <div className='card'>
                  <div className="card-header title-row">
                    <h4 className="card-title text-primary">Coupons</h4>
                  </div>
                  <div className="card-body">
                    {coupons.length > 0 && (
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th>Coupon no.</th>
                              <th>Coupon Date</th>
                              <th className='text-right'>Coupon Payment</th>
                              <th className='text-right'>Kibor Rate%</th>
                              <th className='text-right'>Spread Rate%</th>
                              <th className='text-right'>Coupon Rate%</th>
                              <th className='text-right'>Principal Redemption</th>
                              <th className='text-right'>Remaining Face Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {coupons.map((item: any, index: number) => {
                              return (
                                <tr>
                                  <td>{item.coupon_no}</td>
                                  <td>{item.coupon_date}</td>
                                  <td>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      decimalScale={2}
                                      className={'form-control text-right '} value={item.coupon_payment}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                        item.coupon_payment = value;

                                        setState(value);
                                      }} /></td>
                                  <td>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      decimalScale={2}
                                      className={'form-control text-right ' + item.isKibor_empty} value={item.kibor_rate}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                        let spreadRate = item.spread_rate == '' ? '0' : item.spread_rate;
                                        let couponRate = +value + +spreadRate;
                                        item.kibor_rate = value;
                                        item.coupon_rate = couponRate.toString();
                                        item.isKibor_empty = '';
                                        setState(value);
                                      }} /></td>
                                  <td>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      decimalScale={2}
                                      className={'form-control text-right ' + item.isSpread_empty} value={item.spread_rate}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                        item.spread_rate = value;
                                        let kiborRate = item.kibor_rate == '' ? '0' : item.kibor_rate;
                                        let couponRate = +value + +kiborRate;
                                        item.coupon_rate = couponRate.toString();
                                        item.isSpread_empty = '';
                                        setState(value);
                                      }} /></td>
                                  <td className='text-right'>{numberWithCommas(item.coupon_rate)}</td>
                                  <td>
                                    <NumberFormat
                                      thousandSeparator={true}
                                      inputMode="numeric"
                                      decimalScale={2}
                                      className={"form-control text-right " + item.isPrincipal_Redemp_empty}
                                      value={item.principal_redemption}
                                      onValueChange={(e) => {
                                        let value = decimalValue(e.value, 2);
                                        let remainFaceValue: any;
                                        if (index == 0) {
                                          remainFaceValue = (+faceValuePerUnit - +value).toString();
                                        } else {
                                          remainFaceValue = (+coupons[index - 1].remaining_face_value - +value).toString();
                                        }
                                        item.principal_redemption = value;
                                        //item.remaining_face_value = remainFaceValue;
                                        coupons.forEach((data: any, i: number) => {
                                          if (!data.principal_redemption || +data.principal_redemption <= 0 || i == index) {
                                            data.remaining_face_value = remainFaceValue

                                          }

                                        })

                                        item.isPrincipal_Redemp_empty = '';
                                        setState(value);
                                      }} /></td>
                                  <td className='text-right'>{numberWithCommas(item.remaining_face_value)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}

                  </div>
                </div>}
              <div className="">
                <button className="btn btn-primary" onClick={updateSecurity} disabled={Boolean(Loading)}>
                  {Loading ? <><span className="spinner-border login-txt spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span className="login-txt"> Loading...</span></> : <span>Update</span>}
                </button>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditSecurity;

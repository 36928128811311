import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  getAllBrokers,
  searchBrokerAPI,
} from "../../../stores/services/broker.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import {
  getFundByAmc,
  validateFundNav,
} from "../../../stores/services/funds.service";
import {
  getChartOfAccounts,
  getNewChartsOfAccountsByFund,
} from "../../../stores/services/chatofaccounts.service";
import {
  getAllLedgers,
  getLedgerAccounts,
  numberWithCommas,
} from "../../../utils/customFunction";
import InputMask from "react-input-mask";

import { getDailyBankBalance } from "../../../stores/services/daily-bank-profit-form.service";
import { getLedgerInfoByCode } from "../../../stores/services/chatofaccounts.service";
import moment from "moment";
import { addMegaTransaction } from "../../../stores/services/beta-transaction.service";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import {
  getAllSavingsAccounts,
  updateAccountProfit,
} from "../../../stores/services/account.service";
import Select from "react-select";

interface FieldValues {
  email: any;
  txnr_type: any;
  amc_code: any;
  fund_code: any;
  instruction_date: any;
  execution_date: any;
  executed_date: any;
  fund_account: any;
  reinvest: any;
  account_title: any;
  period: any;
  account_no: any;
  bank: any;
  branch: any;
  counter_account_type: any;
  counter_type: any;
  counter_account_title: any;
  counter_account_number: any;
  counter_bank: any;
  counter_branch: any;
  mode_of_payment: any;
  payment_type: any;
  instrument_no: any;
  instrument_type: any;
  instrument_date: any;
  realized_date: any;
  realized: any;
  gross_amount: any;
  net_amount: any;
  dr_amount: any;
  cr_amount: any;
  balance: any;
  folio_no: any;
  unitholder_name: any;
  units: any;
  nav: any;
  dividend_date: any;
  dividend_rate: any;
  sale_date: any;
  current_holding: any;
  total_holding: any;
  symbol: any;
  dividend_percentage: any;
  credit_date: any;
  maturity_type: any;
  security_type: any;
  issue_date: any;
  conversion_date: any;
  associated_transaction: any;
  maturity_date: any;
  coupon_rate: any;
  price: any;
  face_value: any;
  money_market_face_value: any;
  detail: any;
  type: any;
  redemption_date: any;
  remain_holding: any;
  settlement_by: any;
  tax_type: any;
  settlement_date: any;
  total_charges: any;
  txn_charges: any;
  sale_txn_no: any;
  return_date: any;
  txn_id: any;
  tenor: any;
  yield: any;
  last_coupon_date: any;
  next_coupon_date: any;
  fund_ips_account: any;
  counter_party_ips_account: any;
  system_tax: any;
  primary_dealer: any;
  broker_commission: any;
  tax_sst: any;
  tax_wht_it: any;
  tax_wht_sst: any;
  tax_cgt: any;
  sale_load: any;
  redemption_load: any;
  low_balance_agree: any;
  offer_price: any;
  load_per_unit: any;
  percentage_of_discount: any;
  applicable_offer_price: any;
  sales_tax_collector: any;
  volume: any;
  par_value: any;
  announcement_date: any;
  bonus_percentage: any;
  bonus_credit_date: any;
  bonus_gross_volume: any;
  tax_it_bonus_amount: any;
  net_bonus_volume: any;
  right_share_percentage: any;
  right_share_credit_date: any;
  right_share_gross_volume: any;
  tax_it_right_share_amount: any;
  net_right_share_volume: any;
  tax_on_dvidend: any;
  tax_on_bonus: any;
  tax_on_right: any;
  zakat_amount: any;
  ledgers: any;
}

const DailyBankProfit = (props: any) => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const history = useHistory();
  const fund_code = JSON.parse(sessionStorage.getItem("fund_code") || "[]");
  const email = sessionStorage.getItem("email") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const loggedInUser = JSON.parse(sessionStorage.getItem("loggedInUser") || "");

  const [Loading, setLoading] = useState(false);

  // const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [chartOfAccounts, setChartOfAccounts] = useState<any>(undefined);

  const [allFunds, setAllFunds] = useState<any>([]);
  const [allLedgers, setAllLedgers] = useState<any>([]);
  const [allBanks, setAllBanks] = useState<any>([]);
  const [fund, setFund] = useState("");
  const [fundCheck, setFundCheck] = useState(false);
  const [bank, setBank] = useState("");
  const [bankName, setBankName] = useState<any>("");
  const [profit, setProfit] = useState<any>(0.0);

  const [bankCheck, setBankCheck] = useState(false);
  const [accFundLoading, setAccFundLoading] = useState<any>(false);
  const flag = sessionStorage.getItem("rejectedFlag") || false;

  let [paginationArr, setPaginationArr] = useState([""]);
  const [pageSize, setPageSize] = useState("10");

  const [bankLoading, setBankLoading] = useState<any>(false);
  const [ledgerAccounts, setLegderAccounts] = useState<any>([]);

  let [tableData, setTableData] = useState<any>([]);
  let [ledgerTableData, setLedgerTableData] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState<any>(false);
  const [sendingData, setSendingData] = useState<any>([]);
  const [disableProfit, setDisableProfit] = useState<any>(false);
  const [profitRateConfirmed, setProfitRateConfirmed] = useState<any>(false);
  const [bankBalanceData, setBankBalanceData] = useState<any>(false);
  const [totalBalance, setTotalBalance] = useState<any>(0);

  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  const [date, setDate] = useState<any>(today);

  let [sendingLedgers, setSendingLedgers] = useState<any>([]);
  const [headLabel, setHeadLabel] = useState<any>("");

  const myRef = React.useRef<any>(null);

  const [fields, setFields] = useState<FieldValues>({
    email: email,
    txnr_type: "dailybankprofitaccrual",
    amc_code: loggedInUser.amc_code,
    dividend_date: "",
    dividend_rate: "",
    fund_code: "",
    reinvest: "false",
    instruction_date: moment(date).format("YYYY-MM-DD"),
    execution_date: moment(date).format("YYYY-MM-DD"),
    executed_date: moment(date).format("YYYY-MM-DD"),
    fund_account: "",
    account_title: "",
    account_no: "",
    bank: "",
    branch: "",
    counter_account_type: "",
    counter_type: "",
    counter_account_title: "",
    counter_account_number: "",
    counter_bank: "",
    counter_branch: "",
    mode_of_payment: "",
    payment_type: "",
    instrument_no: "",
    instrument_type: "",
    instrument_date: moment(date).format("YYYY-MM-DD"),
    // realized_date: moment(date).format('YYYY-MM-DD'),
    realized_date: moment(date).format("YYYY-MM-DD"),
    realized: "true",
    gross_amount: "",
    net_amount: 0,
    dr_amount: "",
    period: sessionStorage.getItem("active_period") || "",
    cr_amount: "",
    balance: "",
    folio_no: "",
    unitholder_name: "",
    units: "",
    nav: "",
    sale_date: moment(date).format("YYYY-MM-DD"),
    current_holding: "0",
    total_holding: "",
    symbol: "",
    dividend_percentage: "",
    credit_date: "",
    maturity_type: "",
    security_type: "",
    issue_date: moment(date).format("YYYY-MM-DD"),
    conversion_date: moment(date).format("YYYY-MM-DD"),
    associated_transaction: "",
    maturity_date: moment(date).format("YYYY-MM-DD"),
    coupon_rate: "",
    price: "",
    face_value: 0,
    money_market_face_value: "",
    detail: "",
    type: "",
    redemption_date: moment(date).format("YYYY-MM-DD"),
    remain_holding: "0",
    settlement_by: "",
    tax_type: "",
    settlement_date: moment(date).format("YYYY-MM-DD"),
    total_charges: "0",
    txn_charges: "",
    sale_txn_no: "",
    return_date: "",
    txn_id: "",
    last_coupon_date: moment(date).format("YYYY-MM-DD"),
    next_coupon_date: moment(date).format("YYYY-MM-DD"),
    yield: "",
    tenor: "",
    fund_ips_account: "",
    counter_party_ips_account: "",
    system_tax: "",
    primary_dealer: "",
    broker_commission: "",
    tax_sst: "",
    tax_wht_it: "",
    tax_wht_sst: "",
    tax_cgt: "0",
    sale_load: "",
    redemption_load: "0",
    low_balance_agree: "",
    offer_price: "",
    load_per_unit: "",
    percentage_of_discount: "0",
    applicable_offer_price: "",
    sales_tax_collector: "SRB", // default value SRB
    volume: "",
    par_value: "",
    announcement_date: "", // Dividend on Investment
    bonus_percentage: "",
    bonus_credit_date: "",
    bonus_gross_volume: "",
    tax_it_bonus_amount: "",
    net_bonus_volume: "",
    right_share_percentage: "",
    right_share_credit_date: "",
    right_share_gross_volume: "",
    tax_it_right_share_amount: "",
    net_right_share_volume: "",
    tax_on_dvidend: "",
    tax_on_bonus: "",
    tax_on_right: "",
    zakat_amount: "0",
    ledgers: "",
  });

  // React.useEffect(() => {
  //   setHeadLabel(props.match.params.type);
  //   fields["txnr_type"] = props.match.params.type;
  // }, [props.match.params.type]);

  useEffect(() => {
    setLoading(true);
    const getFundByAMcCode = async (code: string) => {
      setAccFundLoading(true);
      allFunds.length = 0;
      setAllFunds(allFunds);

      //get funds by amc for dropdown
      try {
        const response = await getFundByAmc(
          email,
          code,
          fund_code?.length > 0 ? fund_code : ""
        );
        // sort data
        let sortedData: any = [];
        sortedData = response.data.data.sort(function (a, b) {
          let x = a.nature.toLowerCase();
          let y = b.nature.toLowerCase();
          if (x > y) {
            return 1;
          }
          if (x < y) {
            return -1;
          }
          return 0;
        });
        // setAllFunds(response.data.data);
        setAllFunds(sortedData);
        if (response.data.data.length < 1) {
          setFundCheck(true);
        } else {
          setFundCheck(false);
        }
        setLoading(false);

        setAccFundLoading(false);
      } catch (error) {}
    };

    getFundByAMcCode(amc_code);
  }, []);

  const getBankAccounts = async () => {
    try {
      // const response = await getChartOfAccounts(email);
      // if (response.data.status == 200) {
      //   let ledgersacc = getLedgerAccounts(
      //     response.data.data.main_family[0].family[3].sub_family
      //   );
      //   setLegderAccounts(ledgersacc);
      //   let allLedgers = await getAllLedgers(response.data.data.main_family);
      //   setAllLedgers(allLedgers);
      //   // await ledgerDropDown(allLedgers);
      // } else {
      //   toast.error(response.data.message);
      // }
      let tempArr = chartOfAccounts[0].family[3].sub_family[0];
      tempArr = [tempArr];
      // let ledgersacc = getLedgerAccounts(
      //   chartOfAccounts[0].family[3].sub_family
      // );
      let ledgersacc = getLedgerAccounts(tempArr);
      setLegderAccounts(ledgersacc);
      let allLedgers = await getAllLedgers(chartOfAccounts);
      setAllLedgers(allLedgers);
      // await ledgerDropDown(allLedgers);
    } catch (error) {}
  };

  useEffect(() => {
    if (chartOfAccounts && chartOfAccounts.length > 0) {
      getBankAccounts();
    }
  }, [chartOfAccounts]);

  const getChartOfAccountsFromBackend = async () => {
    try {
      setLoading(true);
      let response = await getNewChartsOfAccountsByFund(email, amc_code, fund);
      if (response.data.status === 200) {
        setChartOfAccounts(response.data.data?.main_family);
        setLoading(false);
      } else {
        toast.error("No associated chart of account!");

        setChartOfAccounts(undefined);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  React.useEffect(() => {
    if (fund) {
      getChartOfAccountsFromBackend();
    }
  }, [fund]);

  // const renderFundsDropdown = () => {
  //   return allFunds.map((item: any, index: string) => {
  //     if (item.nature === "Special Managed Account") {
  //       return (
  //         <>
  //           <option key={index} value={item.symbol_code}>
  //             {item.fund_name} ({item.symbol_code}) - {"SMA"}
  //           </option>
  //         </>
  //       );
  //     } else {
  //       return (
  //         <option key={index} value={item.symbol_code}>
  //           {item.fund_name} ({item.symbol_code})
  //         </option>
  //       );
  //     }
  //   });
  // };

  const renderFundsDropdown = () => {
    return allFunds.map((item: any, index: string) => {
      if (item.nature === "Special Managed Account") {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code}) - SMA`,
        };
      } else {
        return {
          value: item.symbol_code,
          label: `${item.fund_name} (${item.symbol_code})`,
        };
      }
    });
  };

  const renderBanksDropdown = () => {
    return (
      ledgerAccounts &&
      ledgerAccounts.map((item: any, index: number) => {
        return (
          <option key={index} value={item.code}>
            {item.account_title}
          </option>
        );
      })
    );
  };

  // const handleAdd = () => {
  //   if (fund === "") {
  //     return toast.error("Fund is required");
  //   }
  //   // if (bank === "") {
  //   //   return toast.error("Bank is required");
  //   // }
  //   if (amc_code === "") {
  //     return toast.error("Invalid user. Login Again");
  //   }

  //   if (tableData.length === 0) {
  //     setTableData([
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   } else {
  //     setTableData((prevState) => [
  //       ...prevState,
  //       {
  //         fund_code: fund,
  //         amc_code: amc_code,
  //         gl_code: bank,
  //         gl_name: bankName,
  //         profit: profit,
  //       },
  //     ]);
  //   }
  // };

  //function to get bank accounts from backend

  const handleGetAccounts = async () => {
    setLoading(true);
    setTableData([]);
    setLedgerTableData([]);
    setTotalBalance(0);
    setDisableProfit(false);
    setProfitRateConfirmed(false);

    let temp: any = [];

    // if (fund === "") {
    //   setLoading(false);
    //   return toast.error("Fund is required");
    // }
    // if (bank === "") {
    //   return toast.error("Bank is required");
    // }
    if (amc_code === "") {
      setLoading(false);
      return toast.error("Invalid user. Login Again");
    }

    if (!date) {
      setLoading(false);
      return toast.error("Date is required");
    }

    // const response = await getDailyBankBalance(fund, date);
    // let bankBalance = response.data.data;

    const response = await getAllSavingsAccounts(email, amc_code, fund);

    if (response.status === 200 && response.data.status === 200) {
      if (ledgerAccounts) {
        let savingAccounts = response.data.data;

        for (let i = 0; i < savingAccounts.length; i++) {
          let item = ledgerAccounts.find((item) => {
            return item?.code === savingAccounts[i].gl_code;
          });

          if (!item) {
            continue;
          }

          let currentProfit = 0;
          if (
            item?.interest_rate === tableData[i]?.profit ||
            !tableData[i]?.profit
          ) {
            currentProfit = item.interest_rate;
          } else {
            currentProfit = tableData[i].profit;
          }

          setTotalBalance(
            totalBalance + parseInt(item.balance) * (parseInt(profit) / 100)
          );
          let calculatedBalance: any = 0;

          calculatedBalance = parseFloat(
            savingAccounts.find((acc) => acc.gl_code === item.code)
              ?.balance_amount || "0"
          );
          // let daysInYear = moment(`${date.split("-")[0]}-12-31`).dayOfYear();

          temp.push({
            key: item.code,
            account_code: savingAccounts[i].account_code,
            fund_code: fund,
            amc_code: amc_code,
            gl_code: item.code,
            gl_name: item.bank,
            profit: currentProfit,
            branch: item.branch,
            account_title: item.account_title,
            account_number: item.account_no,
            balance: calculatedBalance.toFixed(4),

            daily_profit: (
              (parseFloat(calculatedBalance.toFixed(4) || "0") *
                (currentProfit / 100)) /
              365
            ).toFixed(4),
            related_ledgers: item.related_ledgers,
          });
        }

        let ledgerTemp: any = [];

        if (temp.length > 0) {
          for (let i = 0; i < temp.length; i++) {
            let data = temp[i];
            if (parseFloat(data.profit) < 0 || parseFloat(data.profit) > 100) {
              continue;
            }
            if (data.profit === "") {
              continue;
            }

            // if (data.daily_profit <= 0) {
            //   continue;
            // }

            if (data.related_ledgers) {
              for (let j = 0; j < data.related_ledgers.length; j++) {
                let ledger = data.related_ledgers[j];
                const response = await getLedgerInfo(email, ledger, fund);
                if (response) {
                  if (response?.family_code.toString().startsWith("1")) {
                    ledgerTemp.push({
                      account_number: data.account_number,
                      account_title: data.account_title,
                      amc_code: data.amc_code,
                      balance: data.balance,
                      bank_code: data.gl_code,
                      branch: data.branch,
                      child_family_code: response.child_family_code,
                      child_family_name: response.child_family_name,
                      counter_account_number: "",
                      counter_account_title: "",
                      counter_bank: "",
                      counter_branch: "",
                      created_at: "",
                      credit_amount: "0",
                      debit_amount: data.daily_profit,
                      description: "Daily Bank Profit Accrual",
                      doc_type: "",
                      entered_by: "",
                      evidence: "",
                      family_code: response.family_code,
                      family_name: response.family_name,
                      fund_code: data.fund_code,
                      gl_code: response.gl_code,
                      gl_name: response.gl_name,
                      instruction_date: "",
                      instrument_by: "",
                      instrument_date: "",
                      instrument_no: "",
                      instrument_type: "",
                      main_family_code: response.main_family_code,
                      main_family_name: response.main_family_name,
                      realized: "true",
                      realized_by: "",
                      realized_date: "",
                      sub_family_code: response.sub_family_code,
                      sub_family_name: response.sub_family_name,
                      period: sessionStorage.getItem("active_period") || "",
                      main_bank_account: data.gl_code,
                    });
                  } else if (response?.family_code.toString().startsWith("5")) {
                    ledgerTemp.push({
                      account_number: data.account_number,
                      account_title: data.account_title,
                      amc_code: data.amc_code,
                      balance: data.balance,
                      bank_code: data.gl_code,
                      branch: data.branch,
                      child_family_code: response.child_family_code,
                      child_family_name: response.child_family_name,
                      counter_account_number: "",
                      counter_account_title: "",
                      counter_bank: "",
                      counter_branch: "",
                      created_at: "",
                      credit_amount: data.daily_profit,
                      debit_amount: "0",
                      description: "Daily Bank Profit Accrual",
                      doc_type: "",
                      entered_by: "",
                      evidence: "",
                      family_code: response.family_code,
                      family_name: response.family_name,
                      fund_code: data.fund_code,
                      gl_code: response.gl_code,
                      gl_name: response.gl_name,
                      instruction_date: "",
                      instrument_by: "",
                      instrument_date: "",
                      instrument_no: "",
                      instrument_type: "",
                      main_family_code: response.main_family_code,
                      main_family_name: response.main_family_name,
                      realized: "true",
                      realized_by: "",
                      realized_date: "",
                      sub_family_code: response.sub_family_code,
                      sub_family_name: response.sub_family_name,
                      period: sessionStorage.getItem("active_period") || "",
                      main_bank_account: data.gl_code,
                    });
                  }
                }
              }
            }
          }
        }

        setLedgerTableData(ledgerTemp);
        setTableData(temp);
        // ledgerAccounts.map((item, index) => {
        //   let currentProfit = 0;
        //   if (
        //     item.interest_rate === tableData[index]?.profit ||
        //     !tableData[index]?.profit
        //   ) {
        //     currentProfit = item.interest_rate;
        //   } else {
        //     currentProfit = tableData[index].profit;
        //   }

        //   setTotalBalance(
        //     totalBalance + parseInt(item.balance) * (parseInt(profit) / 100)
        //   );
        //   let calculatedBalance: any = 0;
        //   // if (bankBalance) {
        //   //   bankBalance.map((balance, index) => {
        //   //     if (parseInt(balance.gl_code) === item.code) {
        //   //       calculatedBalance = parseFloat(
        //   //         balance.realized_opening_balance
        //   //       ).toFixed(2);
        //   //     }
        //   //   });
        //   // }
        //   // calculatedBalance = parseFloat(item.balance);
        //   calculatedBalance = parseFloat(
        //     savingAccounts.find((acc) => acc.gl_code === item.code)
        //       ?.balance_amount || "0"
        //   );
        //   let daysInYear = moment(`${date.split("-")[0]}-12-31`).dayOfYear();
        //   // if (index === 0) {
        //   // if (calculatedBalance === 0) {
        //   //   return;
        //   // } else {
        //   return tableData.push({
        //     fund_code: fund,
        //     amc_code: amc_code,
        //     gl_code: item.code,
        //     gl_name: item.bank,
        //     profit: currentProfit,
        //     branch: item.branch,
        //     account_title: item.account_title,
        //     account_number: item.account_no,
        //     balance: calculatedBalance,
        //     // daily_profit: (
        //     //   (calculatedBalance * (currentProfit / 100)) /
        //     //   365
        //     // ).toFixed(2),
        //     daily_profit: (
        //       (calculatedBalance * (currentProfit / 100)) /
        //       // daysInYear
        //       365
        //     ).toFixed(2),
        //     // after_profit_balance: (
        //     //   parseFloat(calculatedBalance) +
        //     //   (calculatedBalance * (currentProfit / 100)) / 365
        //     // ).toFixed(2),
        //     related_ledgers: item.related_ledgers,
        //   });
        //   // }
        //   // } else {
        //   //   // if (calculatedBalance === 0) {
        //   //   //   return;
        //   //   // } else {
        //   //   return setTableData((prevState) => [
        //   //     ...prevState,
        //   //     {
        //   //       fund_code: fund,
        //   //       amc_code: amc_code,
        //   //       gl_code: item.code,
        //   //       gl_name: item.bank,
        //   //       profit: currentProfit,
        //   //       branch: item.branch,
        //   //       account_title: item.account_title,
        //   //       account_number: item.account_no,
        //   //       balance: calculatedBalance,
        //   //       daily_profit: (
        //   //         (calculatedBalance * (currentProfit / 100)) /
        //   //         365
        //   //       ).toFixed(2),
        //   //       // after_profit_balance:
        //   //       //   calculatedBalance +
        //   //       //   ((calculatedBalance * (currentProfit / 100)) / 365).toFixed(2),
        //   //       related_ledgers: item.related_ledgers,
        //   //     },
        //   //   ]);
        //   //   // }
        //   // }
        // });
      }
      setLoading(false);
    } else {
      toast.error("Error fetching data! Try Again!");
      setLoading(false);
      return;
    }

    // if (tableData.length === 0) {
    //   setTableData([
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // } else {
    //   setTableData((prevState) => [
    //     ...prevState,
    //     {
    //       fund_code: fund,
    //       amc_code: amc_code,
    //       gl_code: bank,
    //       gl_name: bankName,
    //       profit: profit,
    //     },
    //   ]);
    // }
    setLoading(false);
  };

  // const handleCreate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setDisableProfit(false);
  //   setLoading(false);
  // };

  // const handleConfirmProfitRate = () => {
  //   setDisableProfit(true);
  //   setLoading(true);
  //   tableData.map((data, index) => {
  //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can only be 1% to 100%");
  //     }

  //     if (data.profit === "") {
  //       setDisableProfit(false);
  //       setLoading(false);
  //       toast.error("Profit can't be empty");
  //     }
  //   });

  //   setProfitRateConfirmed(true);
  //   setLoading(false);
  // };

  //function that is called on profit submission

  const scrollToTop = () => {
    return myRef?.current?.scrollIntoView();
  };

  const handleDailyProfit = async () => {
    setLoading(true);
    let email = sessionStorage.getItem("email");
    // await new Promise((resolve) => {
    //   tableData.map(async (data, index) => {
    //     if (parseFloat(data.profit) <= 0 || parseFloat(data.profit) > 100) {
    //       setDisableProfit(false);
    //       setLoading(false);
    //       return toast.error("Profit can only be 1% to 100%");
    //     }
    //     if (data.profit === "") {
    //       setDisableProfit(false);
    //       setLoading(false);
    //       return toast.error("Profit can't be empty");
    //     }
    //     if (data.related_ledgers) {
    //       data.related_ledgers.map(async (ledger, index) => {
    //         const response = await getLedgerInfo(email, ledger);

    //         // await new Promise((resolve) => {
    //         if (response) {
    //           // await new Promise((resolve) => {
    //           if (sendingLedgers.length > 0) {
    //             // await new Promise((resolve) => {
    //             if (response.family_code === 10206) {
    //               sendingLedgers = [
    //                 ...sendingLedgers,
    //                 {
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: data.daily_profit,
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //             } else if (response.family_code === 50107) {
    //               // await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 ...sendingLedgers,
    //                 {
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: data.daily_profit,
    //                   debit_amount: "0",
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               //   setTimeout(() => {
    //               //     resolve("resolved");
    //               //   }, 1000);
    //               // });
    //             }
    //           } else {
    //             // await new Promise((resolve) => {
    //             if (response.family_code === 10206) {
    //               // await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 {
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: data.daily_profit,
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               //   setTimeout(() => {
    //               //     resolve("resolved");
    //               //   }, 1000);
    //               // });
    //             } else if (response.family_code === 50107) {
    //               // await new Promise((resolve) => {
    //               sendingLedgers = [
    //                 {
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: data.daily_profit,
    //                   debit_amount: "0",
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 },
    //               ];

    //               //   setTimeout(() => {
    //               //     resolve("resolved");
    //               //   }, 1000);
    //               // });
    //             }
    //             //   setTimeout(() => {
    //             //     resolve("resolved");
    //             //   }, 1000);
    //             // });
    //           }
    //         }
    //         //   setTimeout(() => {
    //         //     resolve("resolved");
    //         //   }, 1000);
    //         // });
    //       });
    //     }
    //   });

    //   setTimeout(() => {
    //     resolve("resolved");
    //   }, 10000);
    // });

    if (ledgerTableData.length > 0) {
      for (let i = 0; i < ledgerTableData.length; i++) {
        if (
          parseFloat(ledgerTableData[i].credit_amount) > 0 ||
          parseFloat(ledgerTableData[i].debit_amount) > 0
        ) {
          sendingLedgers.push(ledgerTableData[i]);
        }
        if (ledgerTableData[i]?.gl_code.toString().startsWith("1")) {
          fields["net_amount"] = (
            parseFloat(fields.net_amount) +
            parseFloat(ledgerTableData[i].credit_amount) +
            parseFloat(ledgerTableData[i].debit_amount)
          ).toFixed(4);
        }
      }
    }

    // if (tableData.length > 0) {
    //   for (let i = 0; i < tableData.length; i++) {
    //     let data = tableData[i];
    //     if (parseFloat(data.profit) < 0 || parseFloat(data.profit) > 100) {
    //       setDisableProfit(false);
    //       setLoading(false);
    //       toast.error("Profit can only be 0% to 100%");
    //       return;
    //     }
    //     if (data.profit === "") {
    //       setDisableProfit(false);
    //       setLoading(false);
    //       toast.error("Profit can't be empty");
    //       return;
    //     }

    //     if (data.related_ledgers) {
    //       for (let j = 0; j < data.related_ledgers.length; j++) {
    //         let ledger = data.related_ledgers[j];
    //         const response = await getLedgerInfo(email, ledger, fund);
    //         if (response) {
    //           if (response?.family_code.toString().startsWith("1")) {
    //             sendingLedgers.push({
    //               account_number: data.account_number,
    //               account_title: data.account_title,
    //               amc_code: data.amc_code,
    //               balance: data.balance,
    //               bank_code: data.gl_code,
    //               branch: data.branch,
    //               child_family_code: response.child_family_code,
    //               child_family_name: response.child_family_name,
    //               counter_account_number: "",
    //               counter_account_title: "",
    //               counter_bank: "",
    //               counter_branch: "",
    //               created_at: "",
    //               credit_amount: "0",
    //               debit_amount: data.daily_profit,
    //               description: "Daily Bank Profit Accrual",
    //               doc_type: "",
    //               entered_by: "",
    //               evidence: "",
    //               family_code: response.family_code,
    //               family_name: response.family_name,
    //               fund_code: data.fund_code,
    //               gl_code: response.gl_code,
    //               gl_name: response.gl_name,
    //               instruction_date: "",
    //               instrument_by: "",
    //               instrument_date: "",
    //               instrument_no: "",
    //               instrument_type: "",
    //               main_family_code: response.main_family_code,
    //               main_family_name: response.main_family_name,
    //               realized: "true",
    //               realized_by: "",
    //               realized_date: "",
    //               sub_family_code: response.sub_family_code,
    //               sub_family_name: response.sub_family_name,
    //               period: sessionStorage.getItem("active_period") || "",
    //             });
    //             fields["net_amount"] = (
    //               parseFloat(fields.net_amount) + parseFloat(data.daily_profit)
    //             ).toFixed(4);
    //             // } else if (response.family_code === 50107) {
    //           } else if (response?.family_code.toString().startsWith("5")) {
    //             sendingLedgers.push({
    //               account_number: data.account_number,
    //               account_title: data.account_title,
    //               amc_code: data.amc_code,
    //               balance: data.balance,
    //               bank_code: data.gl_code,
    //               branch: data.branch,
    //               child_family_code: response.child_family_code,
    //               child_family_name: response.child_family_name,
    //               counter_account_number: "",
    //               counter_account_title: "",
    //               counter_bank: "",
    //               counter_branch: "",
    //               created_at: "",
    //               credit_amount: data.daily_profit,
    //               debit_amount: "0",
    //               description: "Daily Bank Profit Accrual",
    //               doc_type: "",
    //               entered_by: "",
    //               evidence: "",
    //               family_code: response.family_code,
    //               family_name: response.family_name,
    //               fund_code: data.fund_code,
    //               gl_code: response.gl_code,
    //               gl_name: response.gl_name,
    //               instruction_date: "",
    //               instrument_by: "",
    //               instrument_date: "",
    //               instrument_no: "",
    //               instrument_type: "",
    //               main_family_code: response.main_family_code,
    //               main_family_name: response.main_family_name,
    //               realized: "true",
    //               realized_by: "",
    //               realized_date: "",
    //               sub_family_code: response.sub_family_code,
    //               sub_family_name: response.sub_family_name,
    //               period: sessionStorage.getItem("active_period") || "",
    //             });
    //           }

    //           // fields["net_amount"] = (
    //           //   parseFloat(fields.net_amount) + parseFloat(data.daily_profit)
    //           // ).toFixed(2);
    //         }
    //       }
    //     }
    //   }
    // }

    // await new Promise(async (resolve, reject) => {
    //   await Promise.all(
    //     tableData.map(async (data, index) => {
    //       if (parseFloat(data.profit) < 0 || parseFloat(data.profit) > 100) {
    //         setDisableProfit(false);
    //         setLoading(false);
    //         toast.error("Profit can only be 0% to 100%");
    //         reject("rejected");
    //         return;
    //       }
    //       if (data.profit === "") {
    //         setDisableProfit(false);
    //         setLoading(false);
    //         toast.error("Profit can't be empty");
    //         reject("rejected");
    //         return;
    //       }
    //       if (data.related_ledgers) {
    //         await Promise.all(
    //           data.related_ledgers.map(async (ledger, index) => {
    //             const response = await getLedgerInfo(email, ledger);

    //             // await new Promise((resolve) => {
    //             if (response) {
    //               // await new Promise((resolve) => {
    //               // await new Promise((resolve) => {
    //               if (response.family_code === 10206) {
    //                 sendingLedgers.push({
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: "0",
    //                   debit_amount: data.daily_profit,
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 });
    //               } else if (response.family_code === 50107) {
    //                 // await new Promise((resolve) => {
    //                 sendingLedgers.push({
    //                   account_number: data.account_number,
    //                   account_title: data.account_title,
    //                   amc_code: data.amc_code,
    //                   balance: data.balance,
    //                   bank_code: data.gl_code,
    //                   branch: data.branch,
    //                   child_family_code: response.child_family_code,
    //                   child_family_name: response.child_family_name,
    //                   counter_account_number: "",
    //                   counter_account_title: "",
    //                   counter_bank: "",
    //                   counter_branch: "",
    //                   created_at: "",
    //                   credit_amount: data.daily_profit,
    //                   debit_amount: "0",
    //                   description: "Daily Bank Profit",
    //                   doc_type: "",
    //                   entered_by: "",
    //                   evidence: "",
    //                   family_code: response.family_code,
    //                   family_name: response.family_name,
    //                   fund_code: data.fund_code,
    //                   gl_code: response.gl_code,
    //                   gl_name: response.gl_name,
    //                   instruction_date: "",
    //                   instrument_by: "",
    //                   instrument_date: "",
    //                   instrument_no: "",
    //                   instrument_type: "",
    //                   main_family_code: response.main_family_code,
    //                   main_family_name: response.main_family_name,
    //                   realized: "true",
    //                   realized_by: "",
    //                   realized_date: "",
    //                   sub_family_code: response.sub_family_code,
    //                   sub_family_name: response.sub_family_name,
    //                   period: sessionStorage.getItem("active_period") || "",
    //                 });
    //               }

    //               fields["net_amount"] = (
    //                 parseFloat(fields.net_amount) +
    //                 parseFloat(data.daily_profit)
    //               ).toFixed(2);
    //             }
    //           })
    //         );
    //       }
    //     })
    //   );

    //   // setTimeout(() => {
    //   resolve("resolved");
    //   // }, 15000);
    // });

    if (sendingLedgers.length > 0) {
      fields.ledgers = JSON.stringify(sendingLedgers);
      fields.net_amount = fields.net_amount.toString();
      fields.fund_code = fund;
      fields.payment_type = "Receipt";
      fields.instruction_date = moment(date).format("YYYY-MM-DD");
      fields.execution_date = moment(date).format("YYYY-MM-DD");
      fields.executed_date = moment(date).format("YYYY-MM-DD");
      fields.instrument_date = moment(date).format("YYYY-MM-DD");
      fields.realized_date = moment(date).format("YYYY-MM-DD");
      fields.sale_date = moment(date).format("YYYY-MM-DD");
      fields.issue_date = moment(date).format("YYYY-MM-DD");
      fields.conversion_date = moment(date).format("YYYY-MM-DD");
      fields.maturity_date = moment(date).format("YYYY-MM-DD");
      fields.redemption_date = moment(date).format("YYYY-MM-DD");
      fields.settlement_date = moment(date).format("YYYY-MM-DD");
      fields.last_coupon_date = moment(date).format("YYYY-MM-DD");
      fields.next_coupon_date = moment(date).format("YYYY-MM-DD");

      if (fields.ledgers.length > 1) {
        try {
          const navCheck = await validateFundNav(
            sessionStorage.getItem("email") || "",
            amc_code,
            fields?.fund_code,
            fields?.settlement_date
          );

          if (navCheck?.status === 200 && navCheck?.data?.status !== 200) {
            toast.error(navCheck?.data?.message);
            setLoading(false);
            return;
          }
          const result = await addMegaTransaction(fields);

          if (result.data.status === 400) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else if (result.data.status === 417) {
            toast.warning(result.data.message);
            setSendingLedgers([]);
          } else {
            sessionStorage.setItem("last_id", result.data.txn_id);
            toast.success(`${result.data.message}: ${result.data.txn_id}`);
            setSendingLedgers([]);

            // for (let i = 0; i < tableData.length; i++) {
            //   const updateAccount = await updateAccountProfit(
            //     sessionStorage.getItem("email") || "",
            //     tableData[i].amc_code,
            //     tableData[i].account_code,
            //     true,
            //     parseFloat(tableData[i].daily_profit)
            //   );
            // }
            setTableData([]);
            setLedgerTableData([]);

            setTimeout(() => {
              // history.push("/admin/create-transaction");
              // history.replace(`/admin/transactions-accounts`);
              history.goBack();
            }, 3000);
            // toast.success(response.data.message);
          }
        } catch (error) {}
      }
    }

    setProfitRateConfirmed(true);
    setLoading(false);
  };

  const getLedgerInfo = async (email, ledger_code, fund_code) => {
    const result = await getLedgerInfoByCode(email, ledger_code, fund_code);
    return result.data.data;
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find((item) => item?.symbol_code === fund);
    return selectedFund && selectedFund.nature === "Special Managed Account"
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code}) - SMA`,
        }
      : selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} (${selectedFund.symbol_code})`,
        }
      : null;
  }, [allFunds, fund]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <ToastContainer limit={1} />

      <div className="content" ref={myRef}>
        <Row>
          <Col md="12">
            <Container fluid>
              <div className="input-holder">
                <div className="row">
                  <h4 className=" card-title">
                    <i
                      className="fa fa-angle-left mr-2 ml-3 mt-1 pointer"
                      onClick={() => {
                        // history.replace("/admin/create-transaction");
                        // history.replace(`/admin/transactions-accounts`);
                        history.goBack();
                      }}
                    ></i>
                    Daily Bank Profit Accrual
                  </h4>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title">Daily Bank Profit Accrual</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div
                          className="form-group col-md-4"
                          data-tip="Select Fund"
                        >
                          Fund Name*
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          {accFundLoading ? (
                            <div className="form-control w-100">
                              <i className="fa fa-spinner fa-spin fa-1x"></i>
                            </div>
                          ) : (
                            <Select
                              disabled={false}
                              value={selectedFundName}
                              label={
                                fundCheck ? "No Fund Found" : "Select Fund"
                              }
                              onChange={(e) => {
                                setLoading(true);
                                setTableData([]);
                                setFund(e?.value);
                                setSendingLedgers([]);
                                fields.ledgers = "";
                                fields.fund_code = e?.value;
                                fields.net_amount = 0;
                                fields.instruction_date =
                                  moment().format("YYYY-MM-DD");
                                fields.execution_date =
                                  moment().format("YYYY-MM-DD");
                                fields.executed_date =
                                  moment().format("YYYY-MM-DD");
                                fields.instrument_date =
                                  moment().format("YYYY-MM-DD");
                                fields.realized_date =
                                  moment().format("YYYY-MM-DD");
                                fields.sale_date =
                                  moment().format("YYYY-MM-DD");
                                fields.issue_date =
                                  moment().format("YYYY-MM-DD");
                                fields.conversion_date =
                                  moment().format("YYYY-MM-DD");
                                fields.maturity_date =
                                  moment().format("YYYY-MM-DD");
                                fields.redemption_date =
                                  moment().format("YYYY-MM-DD");
                                fields.settlement_date =
                                  moment().format("YYYY-MM-DD");
                                fields.last_coupon_date =
                                  moment().format("YYYY-MM-DD");
                                fields.next_coupon_date =
                                  moment().format("YYYY-MM-DD");
                                setLoading(false);
                              }}
                              menuPortalTarget={document.body}
                              menuShouldBlockScroll={true}
                              isClearable={true}
                              isSearchable={true}
                              name="color"
                              options={renderFundsDropdown()}
                              styles={customStyles}
                            />
                            // <select
                            //   className={"form-control w-100 "}
                            //   value={fund}
                            //   disabled={false}
                            //   onChange={(e) => {
                            //     setLoading(true);
                            //     setTableData([]);
                            //     setFund(e.target.value);
                            //     setLoading(false);
                            //   }}
                            // >
                            //   <option value="" defaultChecked hidden>
                            //     {fundCheck ? "No Fund Found" : "Select Fund"}
                            //   </option>
                            //   {renderFundsDropdown()}
                            // </select>
                          )}
                        </div>

                        <div
                          className="form-group col-md-4"
                          data-tip="Select Date"
                        >
                          Select Transaction Date
                          <ReactTooltip
                            textColor="white"
                            backgroundColor="black"
                            effect="float"
                          />
                          {accFundLoading ? (
                            <div className="form-control w-100">
                              <i className="fa fa-spinner fa-spin fa-1x"></i>
                            </div>
                          ) : (
                            <input
                              id="input_Data"
                              className="form-control input_date"
                              type="date"
                              value={date}
                              defaultValue={date}
                              onChange={(e) => {
                                setLoading(true);
                                setTableData([]);
                                setSendingLedgers([]);
                                setDate(e.target.value);
                                fields.ledgers = "";
                                fields.net_amount = 0;
                                fields.instruction_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.execution_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.executed_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.instrument_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.realized_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.sale_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.issue_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.conversion_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.maturity_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.redemption_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.settlement_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.last_coupon_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                fields.next_coupon_date = moment(
                                  e.target.value
                                ).format("YYYY-MM-DD");
                                setFields(fields);
                                setLoading(false);
                              }}
                              // disabled={!fund}
                            />
                          )}
                        </div>

                        {/* <div className="form-group col-md-4" data-tip="Select Bank">
                    Bank Name*
                    <ReactTooltip
                      textColor="white"
                      backgroundColor="black"
                      effect="float"
                    />
                    {fund && bankLoading ? (
                      <div className="form-control w-100">
                        <i className="fa fa-spinner fa-spin fa-1x"></i>
                      </div>
                    ) : (
                      <select
                        className={"form-control w-100 "}
                        value={bank}
                        disabled={!fund}
                        onChange={(e) => {
                          let selectedIndex = e.target.selectedIndex;
                          setBankName(e.target[selectedIndex].textContent);
                          setBank(e.target.value);
                        }}
                      >
                        <option value="" defaultChecked hidden>
                          {!fund
                            ? "Select fund first"
                            : bankCheck
                            ? "No Bank Found"
                            : "Select Bank Account"}
                        </option>

                        {renderBanksDropdown()}
                      </select>
                    )}
                  </div> */}
                      </div>

                      <Row>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary"
                            disabled={Boolean(Loading) || !date || !fund}
                            onClick={() => handleGetAccounts()}
                          >
                            {Loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Calculate Accrual</span>
                            )}
                          </button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>

              {Loading ? (
                <Row>
                  <Col>
                    <div className="d-flex justify-content-center my-5">
                      <i
                        className="fa fa-spinner fa-spin fa-3x"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </Col>
                </Row>
              ) : tableData && tableData.length !== 0 ? (
                <>
                  <Row>
                    <Col>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="card-title text-primary">Profit</h4>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  {/* <td colSpan={3}>
                                  Fund Code: &nbsp; &nbsp;
                                  {tableData[0]?.fund_code}
                                </td> */}
                                  <td colSpan={2}>
                                    AMC Code: &nbsp; &nbsp;{" "}
                                    {tableData[0]?.amc_code}
                                  </td>

                                  <td colSpan={3}>
                                    Date: &nbsp; &nbsp;{" "}
                                    {moment(date).format("YYYY-MM-DD")}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Account #</th>
                                  <th>Account Title</th>
                                  <th>Bank Code</th>
                                  <th>Bank Name</th>
                                  <th className="text-right"> Balance</th>
                                  <th className="text-right">Profit%</th>
                                  <th className="text-right">Daily Profit</th>
                                  {/* <th>Balance After Profit </th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((data, index) => (
                                  <tr key={data.key}>
                                    <td>{data.account_number}</td>
                                    <td>{data.account_title}</td>
                                    <td>{data.gl_code}</td>
                                    <td>{data.gl_name}</td>
                                    <td align="right">
                                      <NumberFormat
                                        disabled
                                        thousandSeparator={true}
                                        inputMode="numeric"
                                        placeholder="Slab From"
                                        value={data.balance}
                                        className="form-control w-75"
                                        style={{ textAlign: "right" }}
                                        onValueChange={(e) => {
                                          data.balance = parseFloat(
                                            e.value || "0"
                                          );
                                          data.daily_profit = (
                                            (parseInt(e.value || "0") *
                                              (parseFloat(data.profit || "0") /
                                                100)) /
                                            365
                                          )
                                            // (moment().isLeapYear() ? 366 : 365)
                                            .toFixed(4);

                                          let ledgerData = [...ledgerTableData];

                                          for (
                                            let i = 0;
                                            i < ledgerData.length;
                                            i++
                                          ) {
                                            if (
                                              ledgerData[i].gl_code ===
                                                data.related_ledgers[0] ||
                                              ledgerData[i].gl_code ===
                                                data.related_ledgers[1]
                                            ) {
                                              if (
                                                ledgerData[i].gl_code
                                                  .toString()
                                                  .startsWith("1")
                                              ) {
                                                ledgerData[i].debit_amount =
                                                  data.daily_profit;
                                              } else {
                                                ledgerData[i].credit_amount =
                                                  data.daily_profit;
                                              }
                                            }
                                          }

                                          setLedgerTableData([...ledgerData]);

                                          setTableData([...tableData]);
                                        }}
                                      />
                                    </td>
                                    <td align="right">
                                      <div>
                                        <input
                                          // disabled
                                          type="number"
                                          min="1"
                                          max="100"
                                          value={numberWithCommas(
                                            data.profit || 0
                                          )}
                                          className="form-control w-75"
                                          disabled={disableProfit}
                                          onChange={(e) => {
                                            const rawValue = e.target.value;

                                            if (
                                              !/^(\d+(\.\d{0,4})?)?$/.test(
                                                rawValue
                                              )
                                            ) {
                                              return;
                                            }

                                            const numericValue =
                                              parseFloat(rawValue);
                                            if (
                                              numericValue > 100 ||
                                              numericValue < 0
                                            ) {
                                              return;
                                            }

                                            setProfit(rawValue);
                                            data.profit = rawValue;
                                            data.daily_profit = (
                                              (parseFloat(data.balance || "0") *
                                                ((numericValue || 0) / 100)) /
                                              365
                                            ).toFixed(4);

                                            let ledgerData = [
                                              ...ledgerTableData,
                                            ];

                                            for (
                                              let i = 0;
                                              i < ledgerData.length;
                                              i++
                                            ) {
                                              if (
                                                ledgerData[i].gl_code ===
                                                  data.related_ledgers[0] ||
                                                ledgerData[i].gl_code ===
                                                  data.related_ledgers[1]
                                              ) {
                                                if (
                                                  ledgerData[i].gl_code
                                                    .toString()
                                                    .startsWith("1")
                                                ) {
                                                  ledgerData[i].debit_amount =
                                                    data.daily_profit;
                                                } else {
                                                  ledgerData[i].credit_amount =
                                                    data.daily_profit;
                                                }
                                              }
                                            }

                                            setLedgerTableData([...ledgerData]);
                                          }}
                                          onBlur={() => {
                                            const numericValue = parseFloat(
                                              data.profit || 0
                                            );
                                            data.profit =
                                              numericValue.toFixed(4);
                                            setProfit(numericValue.toFixed(4));
                                          }}
                                          // onChange={(e) => {
                                          //   if (
                                          //     e.target.value?.replaceAll(",", "")
                                          //       .length > 5
                                          //   ) {
                                          //     return;
                                          //   }

                                          //   if (
                                          //     parseFloat(
                                          //       e.target.value?.replaceAll(
                                          //         ",",
                                          //         ""
                                          //       )
                                          //     ) > 100.0
                                          //   ) {
                                          //     return;
                                          //   }
                                          //   if (
                                          //     parseFloat(
                                          //       e.target.value?.replaceAll(
                                          //         ",",
                                          //         ""
                                          //       )
                                          //     ) < 0.0
                                          //   ) {
                                          //     return;
                                          //   }
                                          //   setProfit(
                                          //     parseFloat(
                                          //       e.target.value?.replaceAll(
                                          //         ",",
                                          //         ""
                                          //       )
                                          //     )
                                          //   );
                                          //   data.profit = parseFloat(
                                          //     e.target.value?.replaceAll(",", "")
                                          //   );
                                          //   data.daily_profit = (
                                          //     (parseInt(data.balance || "0") *
                                          //       (parseFloat(
                                          //         e.target.value?.replaceAll(
                                          //           ",",
                                          //           ""
                                          //         ) || "0"
                                          //       ) /
                                          //         100)) /
                                          //     365
                                          //   )
                                          //     // (moment().isLeapYear() ? 366 : 365)
                                          //     .toFixed(4);
                                          //   // data.after_profit_balance = (
                                          //   //   parseFloat(data.balance) +
                                          //   //   (parseInt(data.balance) *
                                          //   //     (parseFloat(e.target.value) / 100)) /
                                          //   //     365
                                          //   // ).toFixed(2);
                                          // }}
                                        />
                                      </div>
                                    </td>

                                    <td align="right">
                                      {numberWithCommas(data.daily_profit)}
                                    </td>
                                    {/* <td>{data.after_profit_balance}</td> */}
                                  </tr>
                                ))}
                              </tbody>
                              {/* <tfoot>
                              <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Total Amount</th>
                                <th align="right">{totalBalance}</th>
                              </tr>
                            </tfoot> */}
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {ledgerTableData && ledgerTableData.length > 0 ? (
                    <Row>
                      <Col>
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title text-primary">Ledgers</h4>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Code</th>
                                    <th>Ledger</th>
                                    <th className="text-right">Debit Amount</th>
                                    <th className="text-right">
                                      Credit Amount
                                    </th>
                                    <th>Type</th>
                                    <th>Ledger Family</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {ledgerTableData.map((data, index) => (
                                    <tr key={data.gl_code}>
                                      <td>{data.gl_code}</td>

                                      <td>{data.gl_name}</td>
                                      <td align="right">
                                        {numberWithCommas(data.debit_amount)}
                                      </td>
                                      <td align="right">
                                        {numberWithCommas(data.credit_amount)}
                                      </td>
                                      <td>
                                        {data.gl_code.toString().startsWith("1")
                                          ? "Debit"
                                          : "Credit"}
                                      </td>

                                      <td>{data.main_family_name}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}
                </>
              ) : null}
              {tableData &&
              tableData.length > 0 &&
              ledgerTableData &&
              ledgerTableData.length > 0 ? (
                <div className="row">
                  <div className="col-md-6">
                    <button
                      className="btn btn-primary"
                      disabled={Boolean(Loading)}
                      // onClick={() => handleCreate()}
                      onClick={() => {
                        scrollToTop();
                        handleDailyProfit();
                      }}
                    >
                      {Loading ? (
                        <>
                          <span
                            className="spinner-border login-txt spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="login-txt"> Loading...</span>
                        </>
                      ) : (
                        <span>Save Profit Accrual</span>
                      )}
                    </button>
                  </div>
                </div>
              ) : null}
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyBankProfit;

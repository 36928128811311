import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import { BsTable } from "react-icons/bs";
import { getFunds } from "./../../stores/services/funds.service";
import html2pdf from "html2pdf.js";
import { getAmc } from "../../stores/services/amc.service";
import { getFundByAmc } from "../../stores/services/funds.service";
import {
  getBankStatement,
  getBankStatementByLedgerId,
  getBankStatementOpeningBalance,
} from "../../stores/services/report.service";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import ReportHeader from "./report-header";
import { useSelector } from "react-redux";
import {
  numberWithCommas,
  getLedgerAccounts,
  validDate,
  isNumber,
} from "../../utils/customFunction";
import Select from "react-select";
import { getNewChartsOfAccountsByFund } from "../../stores/services/chatofaccounts.service";

const BankStatement = () => {
  const theme = useSelector((state: any) => state.ThemeModeReducer.Theme);
  const pdfExportComponent = React.useRef<PDFExport>(null);
  const [loading, setLoading] = useState(false);
  const [fund_code, setFund_code] = useState(
    JSON.parse(sessionStorage.getItem("fund_code") || "[]")
  );
  const email = sessionStorage.getItem("email") || "";
  const amc_code = sessionStorage.getItem("amc_code") || "";
  const amc_name = sessionStorage.getItem("amc_name") || "";
  let date = new Date();
  const [bankStatement, setBankStatement] = useState<any>([]);
  const [bankStatementDataCount, SetBankStatementDataCount] = useState(0);
  const [allFunds, setAllFunds] = useState<any>([]);
  const [allFundAccounts, setAllFundsAccounts] = useState<any>([]);
  const [fund, setFund] = useState("");
  // const [type, setType] = useState("");
  const [fundAccount, setFundAccount] = useState("");
  const [accountTitle, setAccountTitle] = useState("");
  const [openingBalance, setOpeningBalance] = useState<any>("");
  const [closingBalance, setClosingBalance] = useState<any>("");
  const [fromDate, setFromDate] = useState(moment(date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment(date).format("YYYY-MM-DD"));

  // const [resAmc, setResAmc] = useState("");
  const [resFund, setResFund] = useState("");
  // const [resType, setResType] = useState("");
  const [resFundAccount, setResFundAccount] = useState("");
  const [resAccountTitle, setResAccountTitle] = useState("");
  // const [resAccountNo, setResAccountNo] = useState("");
  const [resFromDate, setResFromDate] = useState(
    moment(date).format("YYYY-MM-DD")
  );
  const [resToDate, setResToDate] = useState(moment(date).format("YYYY-MM-DD"));

  // const chartOfAccounts = useSelector((state: any) => state.chartofAccounts);
  const [chartOfAccounts, setChartOfAccounts] = useState<any>(undefined);

  const [hasActivity, setHasActivity] = useState(false);
  const [accountGlCode, setAccountGlCode] = useState("");

  const getAllAmcFunds = async () => {
    try {
      // allFundAccounts.length=0;
      // setAllFundsAccounts(allFundAccounts);
      allFunds.length = 0;
      setAllFunds(allFunds);
      const response = await getFundByAmc(
        email,
        amc_code,
        fund_code?.length > 0 ? fund_code : ""
      );
      setAllFunds(response.data.data);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("fund_code")) {
      setFund_code(JSON.parse(sessionStorage.getItem("fund_code") || "[]"));
    } else {
      setFund_code(undefined);
    }
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      console.log("Storage Change Detected");
      const fundCode = sessionStorage.getItem("fund_code");
      setFund_code(fundCode ? JSON.parse(fundCode) : undefined);
    };

    handleStorageChange();

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // useEffect(() => {

  //   getAllAmcFunds();
  // }, []);

  React.useEffect(() => {
    getAllAmcFunds();
  }, [fund_code]);

  const getChartOfAccountsFromBackend = async () => {
    try {
      setLoading(true);
      let response = await getNewChartsOfAccountsByFund(
        email,
        amc_code,
        fund?.split("-")[fund?.split("-").length - 1].trim()
      );
      if (response.data.status === 200) {
        setChartOfAccounts(response.data.data?.main_family);
        setLoading(false);
      } else {
        toast.error("No associated chart of account!");

        setChartOfAccounts(undefined);
      }
    } catch (err: any) {
      if (err.response !== undefined) {
        toast.error(err.response.data.message?.toString());
      } else {
        toast.error(err.message || "Request Failed!");
      }
    }
  };

  React.useEffect(() => {
    if (fund) {
      getChartOfAccountsFromBackend();
    }
  }, [fund]);

  const getLedgerAccountsByChart = async () => {
    try {
      let ledgersacc = await getLedgerAccounts(
        chartOfAccounts[0].family[3].sub_family
      );

      setAllFundsAccounts(ledgersacc);
    } catch (error) {}
  };

  React.useEffect(() => {
    if (chartOfAccounts && chartOfAccounts.length > 0) {
      getLedgerAccountsByChart();
    }
  }, [chartOfAccounts]);

  // let txnTypes = JSON.parse(sessionStorage.getItem('txnType') || '');

  // const CapTxnType = (type: any) => {
  //     let i, txType;
  //     for (i = 0; i < txnTypes.length; i++) {
  //     if (txnTypes[i].api_name === type) {
  //         txType = txnTypes[i].dis_name;
  //         break;
  //     }
  //     }
  //     return txType;
  // };

  // const renderFundsDropDown = () => {
  //   return allFunds.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.symbol_code}>
  //         {item.fund_name} - {item.symbol_code}
  //       </option>
  //     );
  //   });
  // };

  const renderFundsDropDown = () => {
    return allFunds.map((item: any, index: number) => {
      return {
        value: item.symbol_code,
        label: `${item.fund_name} - ${item.symbol_code}`,
      };
    });
  };

  // const renderFundAccountsDropDown = () => {
  //   return allFundAccounts.map((item: any, index: number) => {
  //     return (
  //       <option key={index} value={item.code}>
  //         {item.account_title}
  //       </option>
  //     );
  //   });
  // };

  const renderFundAccountsDropDown = () => {
    return allFundAccounts.map((item: any, index: number) => {
      return {
        value: item.code,
        label: item.account_title,
      };
    });
  };

  //code for search data for Reports

  const searchData = async () => {
    try {
      // bankStatement.length = 0;
      // setBankStatement(bankStatement);
      setLoading(true);
      setBankStatement([]);
      setOpeningBalance("0");
      setClosingBalance("0");
      // let fromMonths = new Date(fromDate);
      // let toMonths = new Date(toDate);
      // var premonths;
      // premonths = (toMonths.getFullYear() - fromMonths.getFullYear()) * 12;
      // premonths -= fromMonths.getMonth();
      // premonths += toMonths.getMonth();
      // if(premonths<6){
      // const response = await getBankStatement(email, fundAccount, fromDate,toDate,type);
      const response = await getBankStatementByLedgerId(
        email,
        // fundAccount,
        accountGlCode,
        fromDate,
        toDate,
        "Realized"
      );
      // const openingResponse = await getBankStatementOpeningBalance(
      //   email,
      //   fundAccount,
      //   fromDate
      // );
      // let sortedData = response?.data?.data.sort(
      //   (a, b) => b.created_at_time - a.created_at_time
      // );

      if (response.status === 200 && response.data.status === 200) {
        // let sortedData = response?.data?.data.sort(
        //   (a, b) => b.created_at_time - a.created_at_time
        // );

        let sortedData = response?.data?.data.sort(
          (a, b) => a.created_at_time - b.created_at_time
        );

        if (sortedData && sortedData.length > 0) {
          let opening = sortedData[0].previous_balance;

          setOpeningBalance(sortedData[0].previous_balance);

          // setClosingBalance(sortedData[sortedData.length - 1].balance);

          sortedData = sortedData.map((item) => {
            opening =
              opening +
              parseFloat(item.debit_amount || "0") -
              parseFloat(item.credit_amount || "0");
            return {
              ...item,
              calculatedBalance: opening,
            };
          });

          setClosingBalance(
            sortedData[sortedData.length - 1].calculatedBalance
          );
        }

        // let array = Object.entries(response.data.data);
        // let array = Object.entries(sortedData);
        // let array1 = Object.entries(openingResponse.data.data);
        // let data: any = [];
        // let balance = 0;
        // if (array1.length > 0) {
        //   let temp = [] as any;
        //   // temp=array1[0][1];
        //   temp = array1[0];

        //   if (type == "Transaction" || type == "") {
        //     setOpeningBalance(
        //       temp.processed_opening_balance == "0"
        //         ? "0"
        //         : temp.processed_opening_balance
        //     );
        //     // setOpeningBalance(
        //     //   temp.Record.processed_opening_balance == "0"
        //     //     ? "0"
        //     //     : temp.Record.processed_opening_balance
        //     // );
        //     balance = parseFloat(
        //       temp.processed_opening_balance == "0"
        //         ? "0"
        //         : temp.processed_opening_balance
        //     );
        //     // balance = parseFloat(
        //     //   temp.Record.processed_opening_balance == "0"
        //     //     ? "0"
        //     //     : temp.Record.processed_opening_balance
        //     // );
        //   } else {
        //     setOpeningBalance("0");
        //     balance = parseFloat("0");
        //     setOpeningBalance(
        //       temp.realized_opening_balance == "0"
        //         ? "0"
        //         : temp.realized_opening_balance
        //     );
        //     // setOpeningBalance(
        //     //   temp.Record.realized_opening_balance == "0"
        //     //     ? "0"
        //     //     : temp.Record.realized_opening_balance
        //     // );
        //     balance = parseFloat(
        //       temp.realized_opening_balance == "0"
        //         ? "0"
        //         : temp.realized_opening_balance
        //     );
        //     // balance = parseFloat(
        //     //   temp.Record.realized_opening_balance == "0"
        //     //     ? "0"
        //     //     : temp.Record.realized_opening_balance
        //     // );
        //   }
        // }
        // setResAmc(amc_name);
        setResFund(fund);
        // setResType(type);
        setResFundAccount(fundAccount);
        setResAccountTitle(accountTitle);
        setResFromDate(fromDate);
        setResToDate(toDate);
        // array.forEach(([key, value]) => {
        //   data = value;
        //   let record = [];
        //   record["Record"] = data;
        //   record["Record"] = data;
        //   record["Record"]["ledger_id"] = data.Key;
        //   balance +=
        //     parseFloat(data.debit_amount) - parseFloat(data.credit_amount);
        //   record["Record"]["balance"] = balance.toFixed(2);
        //   // record["Record"] = data.Record;
        //   // record["Record"] = data.Record;
        //   // record["Record"]["ledger_id"] = data.Key;
        //   // balance +=
        //   //   parseFloat(data.Record.debit_amount) -
        //   //   parseFloat(data.Record.credit_amount);
        //   // record["Record"]["balance"] = balance.toFixed(2);
        //   bankStatement.push(record);
        // });
        // setClosingBalance(balance.toFixed(2));
        SetBankStatementDataCount(sortedData.length);
        setBankStatement(sortedData);
        setLoading(false);
        // }else{
        //   toast.error('You cannot generate report upto 6 months')
        //   setLoading(false);
        // }
      } else {
        toast.warning("No activity for selected period!");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching data!");
      setLoading(false);
    }
  };

  const selectedFundName = React.useMemo(() => {
    const selectedFund = allFunds.find(
      (item) => `${item.fund_name} - ${item.symbol_code}` == fund
    );
    return selectedFund
      ? {
          value: selectedFund.symbol_code,
          label: `${selectedFund.fund_name} - ${selectedFund.symbol_code}`,
        }
      : null;
  }, [allFunds, fund]);

  const selectedFundAmount = React.useMemo(() => {
    const selectedFund = allFundAccounts?.find(
      (item) => item?.account_title === accountTitle
    );
    return selectedFund
      ? {
          value: selectedFund.code,
          label: selectedFund.account_title,
        }
      : null;
  }, [allFundAccounts, accountTitle]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? "hsla(291, 64%, 42%, 0.5)"
        : isSelected
        ? "hsla(291, 64%, 42%, 1)"
        : undefined,
      zIndex: 1,
    }),
    menuList: (styles) => ({
      ...styles,
      background: theme == "dark" ? "#3b3b3b" : "#ffffff",
      color: theme == "dark" ? "white" : "",
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base, state) => ({
      ...base,
      background: theme == "dark" ? "#25273a" : "#f2f2f2",
      borderRadius: "0.4285rem",
      borderColor: state.isFocused
        ? "#1d8cf8"
        : theme !== "light"
        ? "#2b3553"
        : "",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "" : "#ba54f5",
      },
      '& .react-select__input input[type="color"]': {
        color: theme === "dark" ? "red" : "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme == "dark" ? "white" : "black",
    }),
    input: (base) => ({
      ...base,
      color: theme == "dark" ? "white" : "black",
    }),
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Container fluid>
              <ToastContainer limit={1} />
              <div className="">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Bank Ledger Report</h4>
                  </div>
                  <div className="card-body">
                    <Row>
                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund</label>
                          <Select
                            value={selectedFundName}
                            label={"Select Fund"}
                            onChange={(e) => {
                              setFund(e?.label);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            onChange={(e) => {
                              setFund(
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text
                              );
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund
                            </option>
                            {renderFundsDropDown()}
                          </select> */}
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="form-group">
                          <label>Select Fund Account</label>
                          <Select
                            value={selectedFundAmount}
                            label={"Select Fund Account"}
                            onChange={(e) => {
                              var AccountTitle = e?.label;
                              setAccountTitle(AccountTitle);
                              const accountNo = allFundAccounts?.filter(
                                (item: any) => {
                                  return item?.code == e?.value;
                                }
                              );
                              setFundAccount(accountNo[0]?.account_no);
                              setAccountGlCode(accountNo[0]?.code);
                            }}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={true}
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={renderFundAccountsDropDown()}
                            styles={customStyles}
                          />
                          {/* <select
                            className="form-control"
                            onChange={(e) => {
                              var AccountTitle =
                                e.nativeEvent.target == null
                                  ? ""
                                  : e.nativeEvent.target[e.target.selectedIndex]
                                    .text;
                              setAccountTitle(AccountTitle);
                              const accountNo = allFundAccounts.filter(
                                (item: any) => {
                                  return item.code == e.target.value;
                                }
                              );
                              console.log("ACCOUNT => ", accountNo);
                              setFundAccount(accountNo[0].account_no);
                              setAccountGlCode(accountNo[0].code);
                            }}
                          >
                            <option value="" defaultChecked hidden>
                              Select Fund Account
                            </option>
                            {renderFundAccountsDropDown()}
                          </select> */}
                        </div>
                      </Col>

                      {/* <Col md="2">
                <div className="form-group">
                  <label>Select Type</label>
                  <select
                    className="form-control"
                    onChange={(e) => {
                        setType(e.target.value);
                    }}>
                      <option value="" defaultChecked hidden>
                      Select Type
                    </option>
                    <option value="Transaction">Transaction</option>
                    <option value="Realized">Realized</option>
                  </select>
                </div>
              </Col> */}

                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date From</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={fromDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setFromDate(date);
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="form-group  ">
                          <label>Date To</label>
                          <input
                            style={{
                              colorScheme: theme == "light" ? "light" : "dark",
                            }}
                            type="date"
                            defaultValue={toDate}
                            className="form-control"
                            onChange={(e) => {
                              let date = moment(e.target.value).format(
                                "YYYY-MM-DD"
                              );
                              setToDate(date);
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className=" ">
                          {/* here Search Button */}
                          <button
                            className="btn btn-primary mt-4"
                            onClick={() => {
                              searchData();
                            }}
                            disabled={Boolean(loading) || !fund || !fundAccount}
                          >
                            {loading ? (
                              <>
                                <span
                                  className="spinner-border login-txt spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="login-txt"> Loading...</span>
                              </>
                            ) : (
                              <span>Search</span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>
                    {/* End */}
                  </div>
                </div>

                {/* here Search results for report Data */}
                <div className="card mt-3">
                  <div className="card-body">
                    <div
                      id="printDiv"
                      className="p-4"
                      style={{ background: "white", color: "black" }}
                    >
                      <PDFExport
                        paperSize="A4"
                        margin="1.5cm"
                        scale={0.6}
                        repeatHeaders={true}
                        fileName={"Bank-Statement-" + date + ".pdf"}
                        ref={pdfExportComponent}
                      >
                        {!loading && bankStatementDataCount > 0 ? (
                          <>
                            <ReportHeader title="Bank Statement" />
                            {/* <div className="title-row d-flex justify-content-center">
                 <img src={DCClogo} alt="MACS-DCC" height="150" />
                 </div>
                  <div className="title-row m-4 d-flex justify-content-center">
                    <h3 className="text-black">Bank Statement</h3>
                  </div> */}

                            <Row>
                              {/* <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      AMC:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resAmc}
                                    </span>
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Fund:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFund}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Account Title:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resAccountTitle}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Account No:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {resFundAccount}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      From Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resFromDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      To Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(resToDate).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md="4" style={{ marginLeft: "70px" }}>
                                <Row>
                                  <Col md="5">
                                    <span
                                      className="text-nowrap"
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Report Date:
                                    </span>
                                  </Col>
                                  <Col md="6">
                                    <span className="text-nowrap">
                                      {moment(date).format("DD-MM-YYYY")}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <div
                              style={{
                                overflowX: "auto",
                                overflowY: "hidden",
                              }}
                            >
                              <div className="float-right">
                                <b>Opening Balance</b> :{" "}
                                {numberWithCommas(openingBalance?.toFixed(4))}
                              </div>
                              <table
                                className="report"
                                style={{ color: "black" }}
                              >
                                <thead style={{ alignSelf: "center" }}>
                                  <tr>
                                    <th>Create Date</th>
                                    <th>Settlement Date</th>
                                    <th>Transaction ID</th>
                                    <th>Description</th>
                                    <th>Debit Amount</th>
                                    <th>Credit Amount</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                                <tbody className="report-table-body">
                                  {/* {type == "Transaction" || type == ""
                                    ?  */}
                                  {!loading &&
                                  bankStatement &&
                                  bankStatement.length > 0
                                    ? bankStatement.map(
                                        (item: any, index: number) => {
                                          return (
                                            <>
                                              <tr key={index}>
                                                <td>
                                                  {validDate(
                                                    moment(
                                                      item.created_at
                                                    ).format("DD-MMM-YYYY")
                                                  )}
                                                </td>
                                                <td>
                                                  {validDate(
                                                    moment(
                                                      item?.txn_data
                                                        ?.settlement_date
                                                    ).format("DD-MMM-YYYY")
                                                  )}
                                                </td>
                                                <td>
                                                  {item.txn_id.replace(
                                                    "TXN_",
                                                    ""
                                                  )}
                                                </td>
                                                <td>
                                                  {item.description?.slice(
                                                    0,
                                                    400
                                                  )}{" "}
                                                  {item.description.length >
                                                    400 && "..."}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithCommas(
                                                    item?.debit_amount?.toFixed(
                                                      4
                                                    )
                                                  )}
                                                </td>
                                                <td className="text-right">
                                                  {numberWithCommas(
                                                    item?.credit_amount?.toFixed(
                                                      4
                                                    )
                                                  )}
                                                </td>
                                                {/* <td className="text-right">
                                                  {numberWithCommas(
                                                    item.balance || 0
                                                  )}
                                                </td> */}
                                                <td className="text-right">
                                                  {numberWithCommas(
                                                    item?.calculatedBalance?.toFixed(
                                                      4
                                                    ) || 0
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )
                                    : null}
                                </tbody>
                              </table>
                            </div>
                            <div className="float-right">
                              <b>Closing Balance</b> :{" "}
                              {numberWithCommas(closingBalance?.toFixed(4))}
                            </div>
                          </>
                        ) : (
                          <div className="table-empty">
                            <BsTable className="icon" />
                            <p className="text-center text-primary empty-text">
                              No record found
                            </p>
                          </div>
                        )}
                      </PDFExport>
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={() => {
                        // printReport();
                        if (pdfExportComponent.current) {
                          pdfExportComponent.current.save();
                        }
                      }}
                    >
                      Print
                    </button>

                    {/* <button
              className="btn btn-info mt-3"
              onClick={() => {
                var printContents = document.getElementById("printDiv")!.innerHTML;
                var originalContents = document.body.innerHTML;
           
                document.body.innerHTML = printContents;
           
                window.print();
           
                document.body.innerHTML = originalContents;

              }}
            >
              Print
            </button> */}
                  </div>
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BankStatement;
